import dayjs from 'dayjs';

import { DATE_SETTINGS } from './config';

export const DATETIME_FORMAT_LIST = () => {
    const now = dayjs();

    return [
        { label: now.format(DATE_SETTINGS.longFormatUS), id: '%Y/%m/%d - %H:%M' },
        { label: now.format(DATE_SETTINGS.longFormatUSDot), id: '%Y.%m.%d - %H:%M' }
    ];
};

export const FILE_FORMAT_LIST = [
    { id: 'Csv', label: '.CSV' },
    { id: 'Excel', label: '.XLSX' }
];

export const WIND_ERROR_MEASURMENTS_OF_INTEREST = ['NMAE', 'MAE', 'MAPE', 'NRMSE', 'RMSE'];

export const WIND_FORECAST_DELIVERY_PARAMETERS = [
    'POWER',
    'TEMPERATURE',
    'WIND_SPEED',
    'WIND_DIRECTION',
    'AIR_PRESSURE'
];

export const SOLAR_FORECAST_DELIVERY_PARAMETERS = ['POWER', 'TEMPERATURE', 'RADIATION', 'CLOUD_COVER', 'AIR_PRESSURE'];

export const DELIVERY_TIMES_HOUR = [1, 2, 4, 6, 12];

export const DELIVERY_TIMES_DAY = [1, 2, 3, 4, 6, 8, 12, 24];
