import * as yup from 'yup';
import { TFunction } from 'i18next';

import { COUNTRIES_LIST } from '../../utils/countriesList';
import { PatchClient, PostClient, UserPermissions } from '../../interfaces/apiv2';
import { getPermissionsSchema, transformPermissionsBEtoFE } from '../../utils/user';
import { ClientFE, ClientFormFields, UserPermissionsFE } from '../../interfaces/uiv2';

export const getClientSchema = (t: TFunction, permissions?: UserPermissions) => {
    const permissionsSchema = permissions ? getPermissionsSchema(permissions) : {};

    return yup
        .object<Record<keyof ClientFormFields, yup.AnySchema>>({
            name: yup.string().required(t('validations.required')),
            country: yup
                .object()
                .shape({ code: yup.string(), label: yup.string(), phone: yup.string() })
                .required(t('validations.required')),
            contact: yup.object({
                first_name: yup.string().required(t('validations.required')),
                last_name: yup.string().required(t('validations.required')),
                email: yup.string().required(t('validations.required')),
                phone_number: yup.string().required(t('validations.required'))
            }),
            permissions: yup.object(permissionsSchema)
        })
        .required();
};

export const transformClientFormToBody = (form: ClientFormFields, mask: number): PostClient => {
    // just destructuring the permissions so it won't be copied to the new object.
    // eslint-disable-next-line
    const { country, permissions, ...rest } = form;

    const base = {
        ...rest,
        mask,
        country_code: country?.code || ''
    };

    return base;
};

export const transformClientBodyToClientPatch = (body: PostClient, client: ClientFE): PatchClient => {
    return {
        body: {
            ...body,
            id: client.id
        },
        id: client.id
    };
};

export const transformToClientSchema = (client: ClientFE): ClientFormFields => {
    const { contact, country_code } = client;

    const country = COUNTRIES_LIST.find((item) => item.code === country_code);

    // FIXME: Permissions bullshit typings. Functionally it works, but I don't like the typing situation here.
    const permissions = client.permissions ? transformPermissionsBEtoFE(client.permissions) : ({} as UserPermissionsFE);

    return {
        contact,
        country,
        name: client.name,
        permissions
    };
};
