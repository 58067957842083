import classnames from 'classnames';
import { LineData } from 'lightweight-charts';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import Loading from '../../layout/Loading';
import { styleguide } from '../../containers/Theme';
import { BarData, TradingViewBarChart, TradingViewChart } from '../TradingViewChart';
import NoValueTable from '../NoValueTable/NoValueTable';

import style from './TradingView.module.scss';

interface TradingViewProps {
    data: any;
    rawData?: any;
    error: boolean;
    loading: boolean;
    legendOpts?: {
        measured?: boolean;
        measuredRo?: boolean;
        forecasted?: boolean;
        forecastedRo?: boolean;
        demand?: boolean;
        generation?: boolean;
        historical?: boolean;
        aggregated?: boolean;
        forecastedCluster?: boolean;
        measuredProfiled?: boolean;
    };
    fetchMoreFn: (toString: string) => void;
    assetMeasureUnit: string;
    limits?: boolean[];
    barChart?: boolean;
}

export const TradingView = ({
    error,
    loading,
    data,
    rawData,
    /* TODO: refactor this to pass label, color in props */
    legendOpts = {
        measured: true,
        forecasted: true,
        forecastedCluster: false,
        forecastedRo: false,
        measuredRo: false,
        measuredProfiled: false,
        historical: false,
        demand: false,
        generation: false,
        aggregated: false
    },
    fetchMoreFn,
    assetMeasureUnit,
    limits,
    barChart
}: TradingViewProps) => {
    const { t } = useTranslation('assets/generation');
    const { t: generalTranslation } = useTranslation();

    const hasData = barChart ? true : data.length ? data.some((a: Array<number>) => a.length) : false;

    const chartProps = {
        colors: [styleguide.defaultSuccessColor, styleguide.defaultLabelColor, styleguide.defaultAvatarColor],
        data,
        fetchMore: fetchMoreFn,
        measureUnit: assetMeasureUnit,
        limits
    };

    return (
        <>
            <Box className={style.chartContainer}>
                {loading ? (
                    <Loading />
                ) : error || !hasData ? (
                    <NoValueTable label={generalTranslation(loading ? 'loading' : 'noDataAvailable')} />
                ) : (
                    <>
                        <Box className={classnames(style.chartHeader, (error || loading) && style.noBottomBorder)}>
                            {legendOpts.demand ? (
                                <Box className={style.legendItem} sx={legendOpts.demand ? { mr: 3 } : {}}>
                                    <Box className={classnames(style.circle, style.demand)}></Box>
                                    <Typography variant="small4">{t('Demand')}</Typography>
                                </Box>
                            ) : null}
                            {legendOpts.generation ? (
                                <Box className={style.legendItem} sx={legendOpts.generation ? { mr: 3 } : {}}>
                                    <Box className={classnames(style.circle, style.forecast)}></Box>
                                    <Typography variant="small4">{t('Generation')}</Typography>
                                </Box>
                            ) : null}
                            {legendOpts.measured ? (
                                <Box className={style.legendItem} sx={legendOpts.measured ? { mr: 3 } : {}}>
                                    <Box className={classnames(style.circle, style.actual)}></Box>
                                    <Typography variant="small4">{t('measured')}</Typography>
                                </Box>
                            ) : null}
                            {legendOpts.measuredRo ? (
                                <Box className={style.legendItem} sx={legendOpts.measuredRo ? { mr: 3 } : {}}>
                                    <Box className={classnames(style.circle, style.actual)}></Box>
                                    <Typography variant="small4">{'Consum (profilare zilnică)'}</Typography>
                                </Box>
                            ) : null}
                            {legendOpts.historical ? (
                                <Box className={style.legendItem} sx={legendOpts.historical ? { mr: 3 } : {}}>
                                    <Box className={classnames(style.circle, style.actual)}></Box>
                                    <Typography variant="small4">{t('historical')}</Typography>
                                </Box>
                            ) : null}
                            {legendOpts.forecasted ? (
                                <Box className={style.legendItem} sx={legendOpts.forecasted ? { mr: 3 } : {}}>
                                    <Box className={classnames(style.circle, style.forecast)}></Box>
                                    <Typography variant="small4">{t('forecasted')}</Typography>
                                </Box>
                            ) : null}
                            {legendOpts.forecastedRo ? (
                                <Box className={style.legendItem} sx={legendOpts.forecastedRo ? { mr: 3 } : {}}>
                                    <Box className={classnames(style.circle, style.forecast)}></Box>
                                    <Typography variant="small4">
                                        {'Predicție (profilare zilnică / intervale)'}
                                    </Typography>
                                </Box>
                            ) : null}
                            {legendOpts.measuredProfiled ? (
                                <Box className={style.legendItem} sx={legendOpts.measuredProfiled ? { mr: 3 } : {}}>
                                    <Box className={classnames(style.circle, style.measuredProfiled)}></Box>
                                    <Typography variant="small4">{'Consum (profilare zilnică meteo)'}</Typography>
                                </Box>
                            ) : null}
                            {legendOpts.forecastedCluster ? (
                                <Box className={style.legendItem} sx={legendOpts.forecastedCluster ? { mr: 3 } : {}}>
                                    <Box className={classnames(style.circle, style.forecastedCluster)}></Box>
                                    <Typography variant="small4">
                                        {'Predicție (profilare zilnică meteo / intrată)'}
                                    </Typography>
                                </Box>
                            ) : null}
                            {legendOpts.aggregated ? (
                                <Box className={style.legendItem} sx={legendOpts.aggregated ? { mr: 3 } : {}}>
                                    <Box className={classnames(style.circle, style.actual)}></Box>
                                    <Typography variant="small4">{t('aggregated')}</Typography>
                                </Box>
                            ) : null}
                        </Box>

                        <Box className={style.chart}>
                            {barChart ? (
                                <TradingViewBarChart {...chartProps} data={data as BarData[]} rawData={rawData} />
                            ) : (
                                <TradingViewChart {...chartProps} data={data as LineData[][]} />
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};
