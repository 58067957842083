import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Calendar, DateLocalizer, EventProps, ToolbarProps } from 'react-big-calendar';

import { localizer } from '../../../utils/config';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { getAssetAvailabilityAsync } from '../../../stores/Assets';
// import { ReactComponent as ShowMore } from '../../../assets/icons/calendar_show_more.svg';

import style from './style.module.scss';
import 'react-big-calendar/lib/sass/styles.scss';
import { AssetAvailabilityEvent } from '../../../interfaces/uiv2';

const calendarFormats: any = {
    weekdayFormat: (date: Date, culture: string, localizer: DateLocalizer) => {
        return localizer.format(date, 'iiiii', culture);
    }
};

const CalendarEvent = ({ event }: EventProps<AssetAvailabilityEvent>) => (
    <Box className={style.calendarEventContainer}>
        <Box className={style.calendarEventLeft}>
            <Box className={style.bar} />
            <Typography variant="small1">
                {dayjs(event.start).format('HH:mm')} - {dayjs(event.end).format('HH:mm')}
            </Typography>
        </Box>
        <Typography variant="small1" className={style.label}>
            {event.title}
        </Typography>
    </Box>
);

const CalendarToolbar = ({ label, onNavigate }: ToolbarProps<AssetAvailabilityEvent>) => {
    const { t } = useTranslation();

    return (
        <Box className={style.calendarToolbarContainer}>
            <Typography variant="h6">{label}</Typography>
            <Box className={style.calendarToolbarActions}>
                <Button onClick={() => onNavigate('TODAY')}>
                    <Typography variant="small3">{t('today')}</Typography>
                </Button>

                <IconButton onClick={() => onNavigate('PREV')} disableRipple>
                    <BiChevronLeft />
                </IconButton>

                <IconButton onClick={() => onNavigate('NEXT')} disableRipple>
                    <BiChevronRight />
                </IconButton>
            </Box>
        </Box>
    );
};

const MaintenanceContainer = ({ assetId }: { assetId: string }) => {
    const dispatch = useAppDispatch();

    const containerRef = useRef<HTMLElement | null>(null);

    const handlers = useSwipeable({
        onSwipedUp: () => {
            setDate(dayjs(date).add(1, 'month').toDate());
        },
        onSwipedDown: () => {
            setDate(dayjs(date).subtract(1, 'month').toDate());
        },
        swipeDuration: 500,
        preventScrollOnSwipe: true
    });

    const refPassthrough = (el: HTMLElement) => {
        handlers.ref(el);

        containerRef.current = el;
    };

    const [date, setDate] = useState(new Date());
    const { events } = useAppSelector((state) => state.listAssetAvailability);

    useEffect(() => {
        const from = dayjs(date).startOf('month').startOf('isoWeek').valueOf();
        const to = dayjs(date).endOf('month').endOf('isoWeek').valueOf();
        dispatch(getAssetAvailabilityAsync({ publicId: assetId, from, to }));

        console.log('[Calendar]', dayjs(from).format('dd DD.MM.YYYY'));
        console.log('[Calendar]', dayjs(to).format('dd DD.MM.YYYY'));
    }, [date]);

    const onNavigate = useCallback(
        (newDate: Date) => {
            setDate(newDate);
        },
        [setDate]
    );

    return (
        <Box {...handlers} ref={refPassthrough} className={style.container}>
            <Calendar
                popup
                date={date}
                events={events}
                endAccessor="end"
                views={['month']}
                startAccessor="start"
                localizer={localizer}
                formats={calendarFormats}
                tooltipAccessor={null as any}
                onNavigate={onNavigate}
                components={{
                    event: CalendarEvent,
                    toolbar: CalendarToolbar
                }}
            />
        </Box>
    );
};

export { MaintenanceContainer };
