import dayjs from 'dayjs';
import classNames from 'classnames';
import classnames from 'classnames';
import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import {
    loadMoreGasClusterData,
    getGasClusterAsync,
    resetGasClusterPage,
    getGasClusterForecastAsync,
    getGasClusterMeasuredAsync,
    resetGasClusterForecastData,
    getGasClusterMoreForecastAsync,
    getGasClusterMoreMeasuredAsync,
    getGasClusterGenerationForecastAsync,
    deleteGasClustersListAsync
} from '../../../stores/Demand';
import { MODAL_IDS } from '../../../modals';
import Loading from '../../../layout/Loading';
import { TAB } from '../../../interfaces/general';
import { DATE_SETTINGS } from '../../../utils/config';
import { ForecastType } from '../../../interfaces/apiv2';
import { getFormattedDate } from '../../../utils/helpers';
import { ModalContext } from '../../../hooks/modalContext';
import { TradingView } from '../../../components/TradingView';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { AssetLocationModal } from '../../../modals/AssetLocationModal';
import { ReactComponent as GraphIcon } from '../../../assets/icons/timeline.svg';
import { ReactComponent as ViewList } from '../../../assets/icons/view_list.svg';
import { ReactComponent as LocationSvg } from '../../../assets/icons/location.svg';
import { RemoveForecastContainer } from '../../../components/RemoveEntityContainer';
import { GenerationTableForecastDataPayload, UNIT } from '../../../interfaces/uiv2';
import { ReactComponent as ClustersIcon } from '../../../assets/icons/demand/clusters.svg';
import { TableActionsEntityContainer } from '../../../components/TableActionsEntityContainer';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../../components/EntityPageToolbar';

import common from './../../../common.module.scss';
import style from '../../../features/asset-page/asset-page.module.scss';
import { MemoizedGasGenerationTable } from '../../../components/GenerationTable/GasGenerationTable';

const GasClustersPage = () => {
    const query = useParams();
    const dispatch = useAppDispatch();
    const { t: generalTranslation } = useTranslation();

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;

    const {
        clusterFE: asset,
        pager,
        forecasts,
        getParams,
        errorForecast,
        errorMeasured,
        generationForecasts,
        loadingForecast,
        successForecast,
        loadingMeasured,
        limitLeftForecastBoundary,
        limitLeftMeasuredBoundary
    } = useAppSelector((state) => state.gasClusterPage);

    const { handleClickOpen } = useContext(ModalContext);

    useEffect(() => {
        return () => {
            dispatch(resetGasClusterPage());
            dispatch(resetGasClusterForecastData());
        };
    }, []);

    useEffect(() => {
        if (!asset && query?.id) {
            dispatch(getGasClusterAsync({ id: query.id, projectId: query.projectId! }));
        }
    }, [query?.id, asset]);

    useEffect(() => {
        if (asset && tab === 'chart') {
            const from = dayjs().subtract(2, 'year').utcOffset(0).startOf('date').valueOf();
            const to = dayjs().add(10, 'days').utcOffset(0).endOf('date').valueOf();
            dispatch(getGasClusterForecastAsync({ id: asset.id, projectId: query.projectId!, from, to }));
            dispatch(getGasClusterMeasuredAsync({ id: asset.id, projectId: query.projectId!, from, to }));
        }
    }, [tab, asset]);

    useEffect(() => {
        if (tab === 'table' && pager?.page !== getParams.page) {
            dispatch(loadMoreGasClusterData());
        }
    }, [getParams, tab, pager]);

    useEffect(() => {
        if (asset) {
            fetchGenerationForecast({
                to: dayjs().valueOf(),
                from: dayjs().subtract(2, 'month').valueOf(),
                forecast_type: ForecastType.Intraday,
                time_increment: 60
            });
        }
    }, [asset]);

    if (!asset) {
        return <Loading className={style.loading} />;
    }

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    const fetchMore = async (toString: string) => {
        const toDayJs = dayjs(toString);
        const timezone = 'UTC';
        const offset = toDayJs.tz(timezone).utcOffset();
        const to = toDayJs.add(offset, 'minutes').valueOf();
        const from = dayjs.tz(to, 'UTC').subtract(1, 'week').valueOf();

        if (!limitLeftForecastBoundary) {
            dispatch(
                getGasClusterMoreForecastAsync({
                    id: asset.id,
                    projectId: query.projectId,
                    from,
                    to
                })
            );
        }

        if (!limitLeftMeasuredBoundary) {
            dispatch(
                getGasClusterMoreMeasuredAsync({
                    id: asset.id,
                    projectId: query.projectId,
                    from,
                    to
                })
            );
        }
    };

    const remove = () => {
        dispatch(deleteGasClustersListAsync({ ids: [asset!.id], projectId: query.projectId! }));
    };

    const fetchGenerationForecast = async (payload: GenerationTableForecastDataPayload) => {
        dispatch(
            getGasClusterGenerationForecastAsync({
                ...payload,
                id: asset.id,
                projectId: query.projectId,
                to: payload.to,
                from: payload.from
            })
        );
    };

    return (
        <>
            <Box className={classNames(style.pageContainer)}>
                <Box className={style.headerContainer}>
                    <Box className={style.header}>
                        <PageHeader
                            className={style.title}
                            id={asset.id}
                            icon={<ClustersIcon />}
                            title={generalTranslation('title.clusters')}
                            subtitle={asset.name}
                        />

                        <Box className={style.subtitle} sx={{ ml: 2 }}>
                            <Box className={style.dot} />
                            <Typography variant="small1"> {generalTranslation(pageTitle)}</Typography>
                        </Box>
                    </Box>
                    <Box className={style.toolbarContainer}>
                        <EntityPageToolbar>
                            <EntityPageToolbarButton
                                tabId={TAB.CHART}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.chart')}
                            >
                                <GraphIcon className={style.icon} />
                            </EntityPageToolbarButton>
                            <EntityPageToolbarButton
                                tabId={TAB.TABLE}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.table')}
                            >
                                <ViewList />
                            </EntityPageToolbarButton>
                        </EntityPageToolbar>
                        <TableActionsEntityContainer
                            entityType="gas-cluster"
                            id={''}
                            className={style.actionsWrapper}
                        />
                    </Box>
                </Box>

                <Box className={common.subHeaderContainer}>
                    <Box className={classnames(common.subHeaderElement, common.border)}>
                        <Typography variant="small4">{generalTranslation('tableHead.pods')}</Typography>
                        <Typography variant="small3" className={common.ml}>
                            {'94136'}
                        </Typography>
                    </Box>
                    <Box className={classnames(common.subHeaderElement, common.border)}>
                        <Typography variant="small4">{generalTranslation('tableHead.country')}</Typography>
                        {asset.country && (
                            <Flag
                                height="24"
                                width="24"
                                className={classnames('flagIcon', common.ml)}
                                code={asset.country}
                            />
                        )}
                        <Typography variant="small3" className={common.ml}>
                            {asset.country?.toUpperCase() || '-'}
                        </Typography>
                    </Box>
                    <Box className={classnames(common.subHeaderElement, common.border)}>
                        <Typography variant="small4">{generalTranslation('tableHead.location')}</Typography>
                        <Tooltip title={asset.location} placement="top" enterDelay={25} enterTouchDelay={25}>
                            <Typography
                                variant="small3"
                                className={classnames(common.ml, common.link, common.locationText)}
                                onClick={() => handleClickOpen(MODAL_IDS.ASSET_LOCATION_MODAL)}
                            >
                                <LocationSvg className={classnames(common.locationSVG, common.mr)} />
                                {asset.location}
                            </Typography>
                        </Tooltip>
                    </Box>
                    <Box className={classnames(common.subHeaderElement, common.border)}>
                        <Typography variant="small4" className={common.ml}>
                            {generalTranslation('tableHead.createdAt')}
                        </Typography>
                        <Typography variant="small3" className={common.ml}>
                            {getFormattedDate({
                                value: asset.created_at,
                                type: DATE_SETTINGS.shortV2FormatHoursIncluded
                            })}
                        </Typography>
                    </Box>
                </Box>

                {tab === TAB.CHART && (
                    <TradingView
                        data={forecasts}
                        fetchMoreFn={fetchMore}
                        error={errorForecast || errorMeasured}
                        loading={loadingForecast || loadingMeasured}
                        assetMeasureUnit={UNIT.ENERGY}
                        limits={[limitLeftForecastBoundary, limitLeftMeasuredBoundary]}
                    />
                )}
                {tab === TAB.TABLE && (
                    <MemoizedGasGenerationTable
                        data={generationForecasts}
                        error={errorForecast}
                        loading={loadingForecast}
                        success={successForecast}
                    />
                )}
            </Box>
            {asset && (
                <AssetLocationModal
                    payload={{
                        longitude: asset.longitude || 0,
                        latitude: asset.latitude || 0,
                        title: asset.name,
                        location: asset.location || ''
                    }}
                />
            )}
            <RemoveForecastContainer cb={remove} stateAccessor="deleteGasClusters" />
        </>
    );
};

export default GasClustersPage;
