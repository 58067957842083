import classnames from 'classnames';
import { TFunction } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { TableActions } from '../BaseTable';
import { TableCellRenderer } from '../../interfaces/general';

import style from './style.module.scss';
import { AssetLocationFE } from '../../interfaces/uiv2';

export const getAssetLocationsTableHeadCells = (t: TFunction): TableCellRenderer<AssetLocationFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {row.index < 10 ? `0${row.index}` : `${row.index}`}
                    </Typography>
                );
            },
            sort: false
        },
        {
            id: 'name',
            justifyContent: 'center',
            maxWidth: 150,
            label: t('tableHead.name'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {row.name}
                    </Typography>
                );
            },
            sort: false
        },
        {
            id: 'latitude',
            justifyContent: 'center',
            maxWidth: 250,
            label: t('tableHead.latitude'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {row.latitude}
                    </Typography>
                );
            },
            sort: false
        },
        {
            id: 'longitude',
            justifyContent: 'center',
            maxWidth: 250,
            label: t('tableHead.longitude'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {row.longitude}
                    </Typography>
                );
            },
            sort: false
        },
        {
            id: 'actions',
            maxWidth: '100%',
            justifyContent: 'end',
            label: '',
            value: (row, searchValue, onClick, handlers) => {
                const edit = handlers?.['edit'];
                const deleteRow = handlers?.['delete'];
                const ingestData = handlers?.['ingest'];
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'edit',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {
                                        edit(row)();
                                    }
                                },
                                {
                                    id: 'delete',
                                    label: 'table.general.delete',
                                    disabled: false,
                                    callback: () => {
                                        deleteRow(row)();
                                    }
                                },
                                {
                                    id: 'ingest',
                                    label: 'table.general.ingest',
                                    disabled: false,
                                    callback: () => {
                                        ingestData(row)();
                                    }
                                }
                            ]}
                        />
                    </Box>
                );
            },
            sort: false
        }
    ];
};
