import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import SimplifiedAssetList from './SimplifiedAssetList';
import { TableSearch } from '../../components/BaseTable';
import useStickyHeader from '../../hooks/useStickyHeader';
import { PageContainer } from '../../layout/PageContainer';
import { AddToVppModal } from '../../modals/AddToVppModal';
import { useAppDispatch, useAppSelector } from '../../stores';
import {
    getAssetsAsync,
    resetListAssetsState,
    setGetExplicitParams,
    setGetParams,
    setSelectedRows
} from '../../stores/Assets';

import style from '../vpp-list/style.module.scss';
import AssetTypeFiltering from '../../components/AssetTypeFiltering/AssetTypeFiltering';

const AssetList = ({ tl = false }: { tl?: boolean }) => {
    const dispatch = useAppDispatch();
    const { t: generalTranslation } = useTranslation();
    useStickyHeader('asset-list-header');

    const { assetsFE } = useAppSelector((state) => state.listAssets);

    const getParams = useAppSelector((state) => state.listAssets.getParams, isEqual);

    useEffect(() => {
        dispatch(getAssetsAsync({ ...getParams, tl }));
    }, [getParams]);

    useEffect(() => {
        return () => {
            dispatch(resetListAssetsState());
        };
    }, []);

    return (
        <PageContainer>
            <Box id="asset-list-header" className={style.headerRow}>
                <Typography variant="h6" className={style.title}>
                    {generalTranslation('assets')}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    <AssetTypeFiltering getParams={getParams} setGetParams={setGetExplicitParams} tl={tl} />
                    <TableSearch setGetParams={setGetParams} />
                </Box>
            </Box>
            <SimplifiedAssetList
                assets={assetsFE}
                tl={tl}
                getParams={getParams}
                setGetParams={setGetParams}
                setSelectedRows={setSelectedRows}
                options={{ addToVpp: true }}
            />
            <AddToVppModal />
        </PageContainer>
    );
};

export default AssetList;
