import { DATE_SETTINGS } from '../../utils/config';
import { getFormattedDate } from '../../utils/helpers';
import { DeliveredForecast, GasDeliveredForecast } from '../../interfaces/apiv2';
import { DeliveredForecastFE, GasDeliveredForecastFE } from '../../interfaces/uiv2';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../utils/table';

export const transformDeliveredForecastsAPItoFE = (data: DeliveredForecast[]): DeliveredForecastFE[] => {
    return data.map((item, index) => ({
        ...item,
        index: index + 1,
        from_date: getFormattedDate({
            value: item.from_date,
            type: DATE_SETTINGS.shortV2FormatHoursIncluded,
            timezone: item.forecast_settings?.asset?.timezone,
            fallback: DEFAULT_EMPTY_CELL_VALUE
        }),
        to_date: getFormattedDate({
            value: item.to_date,
            type: DATE_SETTINGS.shortV2FormatHoursIncluded,
            timezone: item.forecast_settings?.asset?.timezone,
            fallback: DEFAULT_EMPTY_CELL_VALUE
        })
    }));
};

export const transformGasDeliveredForecastsAPItoFE = (data: GasDeliveredForecast[]): GasDeliveredForecastFE[] => {
    return data.map((item, index) => ({
        ...item,
        index: index + 1
    }));
};
