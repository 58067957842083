import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Box, Typography } from '@mui/material';

import { DATE_SETTINGS } from '../../utils/config';
import { NOTIFICATION_TYPE } from '../../interfaces/apiv2';
import { TableCellRenderer } from '../../interfaces/general';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../utils/table';
import { DeliveredNotificationsFE } from '../../interfaces/uiv2';
import { DefaultSearchableCell } from '../../components/BaseTable';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as GenerationIcon } from '../../assets/icons/navigation-items/menu-item-generation.svg';

import style from '../Demand/style.module.scss';
import notificationStyle from './style.module.scss';

export const getNotificationIcon = (type: NOTIFICATION_TYPE) => {
    switch (type) {
        default:
            return <GenerationIcon />;
    }
};

export const getNotificationColor = (type: NOTIFICATION_TYPE | null) => {
    switch (type) {
        case NOTIFICATION_TYPE.INFO:
            return notificationStyle.info;
        case NOTIFICATION_TYPE.WARNING:
            return notificationStyle.warning;
        case NOTIFICATION_TYPE.ERROR:
            return notificationStyle.error;
        case NOTIFICATION_TYPE.SUCCESS:
            return notificationStyle.success;
        default:
            return notificationStyle.disabled;
    }
};

export const getNotificationsTableHeadCells = (tList: TFunction): TableCellRenderer<DeliveredNotificationsFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 80,
            justifyContent: 'center',
            label: tList('tableHead.type'),
            value: (row) => {
                return (
                    <Box className={getNotificationColor(row.acknowledged ? null : row.type)}>
                        {getNotificationIcon(row.type)}
                    </Box>
                );
            },
            sort: true
        },
        {
            id: 'title',
            label: tList('tableHead.notification'),
            value: (row, searchValue) => {
                return (
                    <DefaultSearchableCell
                        value={row.content.message}
                        className={getNotificationColor(row.acknowledged ? null : row.type)}
                        searchValue={searchValue}
                    />
                );
            },
            //TODO: ADD SORT WHEN WE HAVE TITLE ON BE or something we can use. content.message doesnt work.
            sort: false
        },
        {
            id: 'created_at',
            maxWidth: 140,
            justifyContent: 'center',
            label: tList('tableHead.date'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.created_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'accordion',
            sort: false,
            maxWidth: 50,
            label: '',
            value: (row, searchValue, onClick, handlers) => {
                const downloadReport = handlers?.['downloadReport'];
                const handleDownloadGasReport = handlers?.['downloadGasReport'];
                return (
                    <Box className={style.detailsContainer}>
                        <Typography variant="small4">{row.content.message}</Typography>
                        {row.report_id && (
                            <Typography
                                variant="small4"
                                className={style.downloadWrapper}
                                onClick={downloadReport(row.report_id)}
                            >
                                <DownloadIcon />
                                {tList('table.general.downloadReport')}
                            </Typography>
                        )}

                        {row.demand_report && (
                            <Typography
                                variant="small4"
                                className={style.downloadWrapper}
                                onClick={handleDownloadGasReport(row)}
                            >
                                <DownloadIcon />
                                {tList('table.general.downloadReport')}
                            </Typography>
                        )}
                    </Box>
                );
            }
        }
    ];
};
