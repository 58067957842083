import { CustomSeriesOptions, customSeriesDefaultOptions } from 'lightweight-charts';

export interface GroupedBarsSeriesOptions extends CustomSeriesOptions {
    colors: readonly string[];
}

export const defaultOptions: GroupedBarsSeriesOptions = {
    ...customSeriesDefaultOptions,
    colors: ['#6a9bff', '#6fd8b2', 'rgba(106,155,255,0.77)', 'rgba(111,216,178,0.63)']
} as const;
