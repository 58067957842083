import generationAssetForecastSettingsV2 from './GenerationAssetForecastSettingsV2';
import { api } from '../api';
import { ForecastSettingsV2 } from '../../interfaces/apiv2';

export * from './GenerationAssetForecastSettingsV2';

export const forecastSettingsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAssetForecastSettings: build.query({
            query: (params) => ({
                url: `/electricity/generation/assets/${params.assetId}/forecast-settings`,
                method: 'get'
            }),
            transformResponse: (rawData: ForecastSettingsV2[]): ForecastSettingsV2[] => {
                return rawData;
            },
            keepUnusedDataFor: 0,
            providesTags: ['AssetForecastSettings']
        })
    })
});

export const { useGetAssetForecastSettingsQuery } = forecastSettingsApi;

export default {
    generationAssetForecastSettingsV2
};
