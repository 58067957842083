import { Dispatch } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Autocomplete, Box, InputLabel, TextField } from '@mui/material';

import style from '../style.module.scss';
import Loading from '../../../layout/Loading';
import { useHideInput } from '../../../hooks/useHideInput';
import { ForecastSettingsFEV2 } from '../../../interfaces/uiv2';
import { AutocompletesAction, AutocompletesState } from './interfaces';
import { useGetAssetForecastSettingsQuery } from '../../../stores/GenerationAssetForecastSettingsV2';

export const GenerationReportForecastSettingAutocomplete = ({
    state,
    dispatch
}: {
    state: AutocompletesState;
    dispatch: Dispatch<AutocompletesAction>;
}) => {
    const { t } = useTranslation();
    const { data: forecastSettings, isLoading } = useGetAssetForecastSettingsQuery(
        {
            assetId: state.asset?.id
        },
        { skip: !state.asset }
    );

    const handleChange = () => {
        dispatch({ type: 'SET_FORECAST_SETTING', payload: null });
        dispatch({ type: 'SET_DELIVERY_SETTING', payload: null });
        dispatch({ type: 'SET_DELIVERY_GROUP', payload: null });
        dispatch({ type: 'SET_DELIVERY_INTERVAL', payload: null });
        dispatch({ type: 'SET_OPEN', payload: { ...state.open, forecastSetting: false } });
    };

    const callback = (e: ForecastSettingsFEV2) => {
        dispatch({ type: 'SET_FORECAST_SETTING', payload: e });
        dispatch({ type: 'SET_DELIVERY_SETTING', payload: null });
        dispatch({ type: 'SET_DELIVERY_GROUP', payload: null });
        dispatch({ type: 'SET_DELIVERY_INTERVAL', payload: null });
        dispatch({ type: 'SET_OPEN', payload: { ...state.open, forecastSetting: false } });
    };

    const handleIdChange = (e: ForecastSettingsFEV2) => () => callback(e);

    const { hideInput } = useHideInput({ data: forecastSettings, callback });

    if (hideInput) {
        return <></>;
    }

    return (
        <>
            <Box className={classNames(style.formRow, style.fullWidth)}>
                <InputLabel variant="standard" required shrink htmlFor={t('inputModelLabel')}>
                    {t('inputModelLabel')}
                </InputLabel>
                <Autocomplete
                    id="asynchronous-forecast-settings"
                    value={state.forecastSetting}
                    options={forecastSettings || []}
                    className={state.open.forecastSetting ? style.autocompleteOpen : ''}
                    loading={isLoading}
                    onChange={handleChange}
                    noOptionsText={t('select.noOptions')}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    popupIcon={
                        isLoading ? <Loading className={style.loading} /> : <MdKeyboardArrowDown color="#6A9BFF" />
                    }
                    renderOption={(props: any, e: ForecastSettingsFEV2) => (
                        <Box component="li" {...props} key={Math.random()} onClick={handleIdChange(e)}>
                            {e.name}
                        </Box>
                    )}
                    ListboxProps={{ style: { maxHeight: '180px' } }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: t('inputModelLabel')
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isLoading ? <Loading /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                    open={state.open.forecastSetting}
                    onOpen={() => {
                        dispatch({ type: 'SET_OPEN', payload: { ...state.open, forecastSetting: true } });
                    }}
                    onClose={() => {
                        dispatch({ type: 'SET_OPEN', payload: { ...state.open, forecastSetting: false } });
                    }}
                    clearOnEscape={true}
                    clearOnBlur={true}
                    filterOptions={(x) => x}
                />
            </Box>
        </>
    );
};
