import { debounce } from 'lodash';
import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';

import { getValueFromTime } from './helpers';
import { useAppDispatch } from '../../stores';
import { ForecastType } from '../../interfaces/apiv2';
import { DateRangeInput } from '../DateRangeInput/DateRangeInput';

import style from './style.module.scss';
import { msInAMinute } from '../../utils/helpers';
import { GenerationTableForecastDataPayload } from '../../interfaces/uiv2';

interface GenerationTableToolbarProps {
    fetchMoreFn: (payload: GenerationTableForecastDataPayload) => void;
    setTablePayload?: any;
    timezone?: string;
}

const disabledDays = [
    {
        from: dayjs().subtract(31, 'day').toDate(),
        to: dayjs().subtract(1, 'day').toDate()
    }
];

const datePickerConfig = {
    fromMonth: dayjs().toDate(),
    disabled: disabledDays,
    componentDisabled: false
};

const GenerationTableToolbar = ({ fetchMoreFn, setTablePayload, timezone }: GenerationTableToolbarProps) => {
    const dispatch = useAppDispatch();
    const { t: generalTranslation } = useTranslation();

    const [endTimeError, setEndTimeError] = useState(null);
    const [startTimeError, setStartTimeError] = useState(null);
    const [endValue, setEndValue] = useState<Dayjs | null>(null);
    const [dateRange, setDateRange] = useState<DateRange | null>(null);
    const [timeIncrementValue, setTimeIncrementValue] = React.useState<number>(15);
    const [startValue, setStartValue] = useState<Dayjs | null>(dayjs().startOf('hour'));
    const [longTermStartValue, setLongTermStartValue] = useState<Dayjs | null>(dayjs().startOf('day'));
    const [longTermEndValue, setLongTermEndValue] = useState<Dayjs | null>(dayjs().add(1, 'day').startOf('day'));
    const [forecastType, setForecastType] = React.useState<ForecastType>(ForecastType.Intraday);

    const getStartValue = () => {
        return forecastType === ForecastType.LongTerm ? longTermStartValue : startValue;
    };

    const getEndValue = () => {
        return forecastType === ForecastType.LongTerm ? longTermEndValue : endValue;
    };

    const getStartValueSet = () => {
        return forecastType === ForecastType.LongTerm ? debounceSetLongTermStartValue : debounceSetStartValue;
    };

    const getEndValueSet = () => {
        return forecastType === ForecastType.LongTerm ? debounceSetLongTermEndValue : debounceSetEndValue;
    };

    useEffect(() => {
        setTime();
    }, [getStartValue(), getEndValue(), timeIncrementValue, forecastType, dateRange]);

    const setTime = () => {
        //default value to avoid linter warnings
        let fromValue = dayjs().startOf('hour');
        let toValue = dayjs().add(1, 'day').startOf('date');
        let wrongTimeValue = false;
        if (forecastType === ForecastType.Intraday) {
            fromValue = dayjs().startOf('hour');
            [fromValue, wrongTimeValue] = getValueFromTime({
                defaultValue: fromValue,
                compareValue: fromValue,
                timeValue: getStartValue(),
                timeDefaultVerb: 'startOf',
                timeDefaultValue: 'hour',
                reset: wrongTimeValue
            });

            toValue = dayjs().endOf('date');
            [toValue, wrongTimeValue] = getValueFromTime({
                defaultValue: toValue,
                compareValue: fromValue,
                timeValue: getEndValue(),
                timeDefaultVerb: 'endOf',
                timeDefaultValue: 'date',
                reset: wrongTimeValue,
                includesEndInterval: true
            });
        }
        if (forecastType === ForecastType.DayAhead) {
            fromValue = dayjs().add(1, 'day').startOf('date');
            [fromValue, wrongTimeValue] = getValueFromTime({
                defaultValue: fromValue,
                compareValue: fromValue,
                timeValue: fromValue,
                timeDefaultVerb: 'startOf',
                timeDefaultValue: 'date',
                reset: wrongTimeValue
            });

            toValue = fromValue.endOf('date');
            [toValue, wrongTimeValue] = getValueFromTime({
                defaultValue: toValue,
                compareValue: fromValue,
                timeValue: getEndValue(),
                timeDefaultVerb: 'endOf',
                timeDefaultValue: 'date',
                reset: wrongTimeValue,
                includesEndInterval: true
            });
        }
        if (forecastType === ForecastType.LongTerm) {
            if (dateRange) {
                if (!dateRange.from || !dateRange.to) {
                    // don't get any new data if we dont have a complete interval
                    return;
                }
                fromValue = dayjs(dateRange.from).startOf('date');
                [fromValue, wrongTimeValue] = getValueFromTime({
                    defaultValue: fromValue,
                    compareValue: fromValue,
                    timeValue: getStartValue(),
                    timeDefaultVerb: 'startOf',
                    timeDefaultValue: 'date',
                    reset: wrongTimeValue
                });

                toValue = dayjs(dateRange.to).add(1, 'day').startOf('date');
                [toValue, wrongTimeValue] = getValueFromTime({
                    defaultValue: toValue,
                    compareValue: toValue,
                    timeValue: getEndValue(),
                    timeDefaultVerb: 'endOf',
                    timeDefaultValue: 'date',
                    reset: wrongTimeValue,
                    compareVerb: 'isAfter',
                    includesEndInterval: true
                });
            } else {
                fromValue = dayjs().startOf('hour');
                [fromValue, wrongTimeValue] = getValueFromTime({
                    defaultValue: fromValue,
                    compareValue: fromValue,
                    timeValue: getStartValue(),
                    timeDefaultVerb: 'startOf',
                    timeDefaultValue: 'hour',
                    reset: wrongTimeValue
                });

                toValue = fromValue.add(10, 'days');
                [toValue, wrongTimeValue] = getValueFromTime({
                    defaultValue: toValue,
                    compareValue: toValue,
                    timeValue: getEndValue(),
                    timeDefaultVerb: 'endOf',
                    timeDefaultValue: 'hour',
                    reset: wrongTimeValue,
                    compareVerb: 'isAfter',
                    includesEndInterval: true
                });
            }
        }
        if (wrongTimeValue) {
            return;
        }

        const now = dayjs().locale('en').tz(timezone);
        const diffInMilliseconds = now.valueOf() - now.utc().valueOf();
        const offset = timezone
            ? timezone === 'utc'
                ? // numbers of minutes * milliseconds in a minute ex: -120 for bucharest & 60k
                  new Date().getTimezoneOffset() * msInAMinute
                : diffInMilliseconds /* calculate a new offset for that timezone */
            : 0;

        const payload = {
            from: fromValue.valueOf() - offset,
            to: toValue.valueOf() - offset,
            forecast_type: forecastType,
            time_increment: timeIncrementValue
        };

        // here to add the offset so the utc matches the local time
        if (setTablePayload) {
            dispatch(
                setTablePayload({
                    ...payload /*
                    from: fromValue.valueOf() - offset,
                    to: toValue.valueOf() - offset*/
                })
            );
        }

        fetchMoreFn(payload);
    };

    const handleSelectChange = (cb: any) => (event: SelectChangeEvent<number | ForecastType>) => {
        cb(event.target.value);
    };

    datePickerConfig.componentDisabled = forecastType !== ForecastType.LongTerm;

    const debounceSetEndValue = useMemo(() => debounce(setEndValue, 500), []);
    const debounceSetStartValue = useMemo(() => debounce(setStartValue, 500), []);
    const debounceSetLongTermEndValue = useMemo(() => debounce(setLongTermEndValue, 500), []);
    const debounceSetLongTermStartValue = useMemo(() => debounce(setLongTermStartValue, 500), []);

    return (
        <Box className={style.genericHeader}>
            <Box className={style.toolbarContainer}>
                <DateRangeInput
                    className={style.dateRange}
                    label={generalTranslation('table.general.selectDateRange')}
                    datePickerConfig={datePickerConfig}
                    range={dateRange}
                    setRange={setDateRange}
                />
                <Box className={classnames(style.timePicker, startTimeError && style.timeError)}>
                    <DesktopTimePicker
                        value={getStartValue()}
                        onChange={(newValue) => {
                            getStartValueSet()(newValue);
                        }}
                        onError={(error) => {
                            setStartTimeError(error as any);
                        }}
                        ampm={false}
                        inputFormat="HH:mm"
                        mask="__:__"
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Box>
                <Box className={classnames(style.timePicker, endTimeError && style.timeError)}>
                    <DesktopTimePicker
                        value={getEndValue()}
                        onChange={(newValue) => {
                            getEndValueSet()(newValue);
                        }}
                        onError={(error) => {
                            setEndTimeError(error as any);
                        }}
                        ampm={false}
                        inputFormat="HH:mm"
                        mask="__:__"
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Box>
                <FormControl fullWidth className={style.timeSelect}>
                    <Select
                        id="forecast-type-dropdown"
                        value={forecastType}
                        onChange={handleSelectChange(setForecastType)}
                    >
                        <MenuItem value={ForecastType.Intraday}>
                            <Typography variant="small4">{generalTranslation(ForecastType.Intraday)}</Typography>
                        </MenuItem>
                        <MenuItem value={ForecastType.DayAhead}>
                            <Typography variant="small4">{generalTranslation(ForecastType.DayAhead)}</Typography>
                        </MenuItem>
                        <MenuItem value={ForecastType.LongTerm}>
                            <Typography variant="small4">{generalTranslation(ForecastType.LongTerm)}</Typography>
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth className={style.timeSelect}>
                    <Select
                        id="time-increment-dropdown"
                        value={timeIncrementValue}
                        onChange={handleSelectChange(setTimeIncrementValue)}
                    >
                        <MenuItem value={5} disabled>
                            <Typography variant="small4">{'5 min'}</Typography>
                        </MenuItem>
                        <MenuItem value={10} disabled>
                            <Typography variant="small4">{'10 min'}</Typography>
                        </MenuItem>
                        <MenuItem value={15}>
                            <Typography variant="small4">{'15 min'}</Typography>
                        </MenuItem>
                        <MenuItem value={60} disabled>
                            <Typography variant="small4">{'60 min'}</Typography>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};

export default GenerationTableToolbar;
