import {
    Autocomplete,
    Box,
    Divider,
    FormHelperText,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    SvgIcon,
    TextField,
    Typography
} from '@mui/material';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import {
    ASSET_STATUS,
    ASSET_TYPE,
    ASSET_TYPES,
    GenerationAssetFE,
    GenerationAssetFormFields,
    INSTALLATION_TYPE_SOLAR,
    INSTALLATION_TYPE_WIND,
    UNIT,
    UserFE
} from '../../interfaces/uiv2';
import { FormModalActions } from '../FormUtils';
import { MODAL_IDS } from '../../modals';
import { getClientsAsync, useGetClientsQuery } from '../../stores/Clients';
import { useFormLogic } from '../../hooks/useFormLogic';
import {
    getGenerationAssetFormDataDefaultValues,
    getGenerationAssetFormSchema,
    transformGenerationAssetBodyToPatch,
    transformGenerationAssetFormToBody,
    transformGenerationAssetToFormData
} from './helpers';
import { hasAccessTo } from '../../utils/user';
import { getAssetIcon } from '../../utils/getAssetIcon';
import { useAppDispatch, useAppSelector } from '../../stores';
import AssetStatusLabel from '../AssetStatusLabel/AssetStatusLabel';
import { createAssetAsync, resetUpdateAssetState, updateAssetAsync } from '../../stores/Assets';
import { ReactComponent as locationIcon } from '../../assets/icons/add_location.svg';

import style from './index.module.scss';

interface GenerationAssetFormProps {
    asset?: GenerationAssetFE;
    type: ASSET_TYPE;
}

const GenerationAssetForm = ({ asset, type }: GenerationAssetFormProps) => {
    const dispatch = useAppDispatch();
    const { t: generalTranslation } = useTranslation();
    const { t } = useTranslation('assets/generation');

    const { user } = useAppSelector((state) => state.login);
    const { loading } = useAppSelector((state) => state.createAsset);
    const { success } = useAppSelector((state) => state.updateAsset);
    const getParams = useAppSelector((state) => state.listClients.getParams, isEqual);
    const hasClient = !!user!.client_id;
    const schema = getGenerationAssetFormSchema(t, hasClient);
    const { data: clients } = useGetClientsQuery(
        { page_size: 50, page: 1 },
        { skip: !hasAccessTo(user!, ['Admin.Clients.CREATE']) }
    );

    const {
        watch,
        control,
        handleSubmit,
        formState: { errors, isDirty },
        reset
    } = useFormLogic<GenerationAssetFE, GenerationAssetFormFields, any, GenerationAssetFormFields>({
        schema,
        data: asset || null,
        transformFn: transformGenerationAssetToFormData,
        defaultValues: getGenerationAssetFormDataDefaultValues(type)
    });

    const assetType = watch('type');

    const init = useCallback(() => dispatch(getClientsAsync(getParams)), [getParams]);

    const onSubmit = (formData: GenerationAssetFormFields) => {
        const body = transformGenerationAssetFormToBody(formData, user as UserFE);
        if (asset) {
            const patchBody = transformGenerationAssetBodyToPatch(asset, body);
            dispatch(updateAssetAsync({ asset, body: patchBody }));
        } else {
            dispatch(createAssetAsync(body));
        }
    };

    const onError = (err: any) => {
        console.error('Error submitting form', err);
    };

    useEffect(() => {
        init();
        return reset;
    }, [getParams]);

    useEffect(() => {
        if (success) {
            reset({}, { keepValues: true });
            dispatch(resetUpdateAssetState());
        }
    }, [success]);

    const installationTypes = useMemo(() => {
        if (assetType === ASSET_TYPE.SOLAR) {
            return Object.values(INSTALLATION_TYPE_SOLAR);
        }

        if (assetType === ASSET_TYPE.WIND) {
            return Object.values(INSTALLATION_TYPE_WIND);
        }

        return [];
    }, [assetType]);

    return (
        <Box className={style.container} component="form" noValidate onSubmit={handleSubmit(onSubmit, onError)}>
            {!asset && (
                <>
                    <Typography variant="h6">{t('createAssetTitle')}</Typography>

                    <Typography variant="body1" sx={{ mt: 3, mb: 2.5 }}>
                        {t('createAssetSubtitle')}
                    </Typography>
                </>
            )}

            {/* name & type */}
            <Stack direction="row" justifyContent="space-between" spacing={4} width="100%">
                <Box className={style.formInput}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.assetName')}>
                                    {t('form.assetName')}
                                </InputLabel>
                                <Input
                                    {...field}
                                    fullWidth
                                    id={t('form.assetName')}
                                    placeholder={t('form.assetName')}
                                />
                                {errors.name && <FormHelperText error>{t('form.errors.generic')}</FormHelperText>}
                            </>
                        )}
                    />
                </Box>
                <Box className={style.formInput}>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.type')}>
                                    {t('form.type')}
                                </InputLabel>

                                <Select
                                    {...field}
                                    fullWidth
                                    labelId="asset-type-label"
                                    id="asset-type-id"
                                    label={t('form.type')}
                                    disabled={!!asset}
                                >
                                    {ASSET_TYPES.map((asset: any) => (
                                        <MenuItem
                                            className={style.dropdownMenuItem}
                                            value={asset.name}
                                            key={asset.name}
                                        >
                                            {getAssetIcon(asset.name, style.svgImg)}
                                            <Typography variant="small1" className={style.ml1}>
                                                {t(`parkTypes.${asset.name}`)}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    ></Controller>
                </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between" spacing={4} width="100%">
                <Stack direction="row" justifyContent="space-between" spacing={2} width="calc(50% - 16px)">
                    <Box className={classnames(style.formInput, style.small)}>
                        <Controller
                            name="manufacturer"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" shrink htmlFor={t('form.manufacturer')}>
                                        {t('form.manufacturer')}
                                    </InputLabel>
                                    <Input
                                        {...field}
                                        fullWidth
                                        id={t('form.manufacturer')}
                                        placeholder={t('form.manufacturer')}
                                    />
                                    {errors.manufacturer && (
                                        <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                    <Box className={classnames(style.formInput, style.small)}>
                        <Controller
                            name="capacity_value"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink htmlFor={t('form.totalCapacity')}>
                                        {t('form.totalCapacity')}
                                    </InputLabel>
                                    <Input
                                        {...field}
                                        type="number"
                                        fullWidth
                                        id={t('form.totalCapacity')}
                                        placeholder={t('form.totalCapacity')}
                                    />
                                    {errors.capacity_value && (
                                        <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                </Stack>
                {!hasClient && (
                    <Stack direction="row" justifyContent="space-between" spacing={2} width="calc(50% - 16px)">
                        <Box className={style.formInput}>
                            <Controller
                                name="client"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputLabel variant="standard" required shrink htmlFor={t('form.clientId')}>
                                            {t('form.clientId')}
                                        </InputLabel>

                                        <Autocomplete
                                            {...field}
                                            id="client"
                                            value={field.value}
                                            onChange={(e, value) => {
                                                field.onChange(value);
                                                return value;
                                            }}
                                            noOptionsText={generalTranslation('select.noOptions')}
                                            options={clients || []}
                                            autoHighlight
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderOption={(props, client) => (
                                                <Typography variant="small1" {...props}>
                                                    {client.name}
                                                </Typography>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="client"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: t('form.clientId')
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.client && (
                                            <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                        )}
                                    </>
                                )}
                            ></Controller>
                        </Box>
                    </Stack>
                )}
            </Stack>

            {/* manufacturer capacity latitude longitude */}
            <Stack direction="row" justifyContent="space-between" spacing={4}>
                <Stack direction="row" justifyContent="space-between" spacing={2} width="calc(50% - 16px)">
                    <Box className={classnames(style.formInput, style.small)}>
                        <Controller
                            name="has_realtime_data"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink htmlFor={t('form.realtimeData')}>
                                        {t('form.realtimeData')}
                                    </InputLabel>
                                    <Select {...field} fullWidth label={t('form.realtimeData')}>
                                        <MenuItem value={false as any}>
                                            <Typography variant="small1">{generalTranslation('no')}</Typography>
                                        </MenuItem>
                                        <MenuItem value={true as any}>
                                            <Typography variant="small1">{generalTranslation('yes')}</Typography>
                                        </MenuItem>
                                    </Select>
                                    {errors.has_realtime_data && (
                                        <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                    <Box className={classnames(style.formInput, style.small)}>
                        <Controller
                            name="measure_unit"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink htmlFor={t('form.measuredUnit')}>
                                        {t('form.measuredUnit')}
                                    </InputLabel>
                                    <Select {...field} fullWidth label={t('form.measuredUnit')}>
                                        <MenuItem value={UNIT.ENERGY}>
                                            <Typography variant="small1">{generalTranslation(UNIT.ENERGY)}</Typography>
                                        </MenuItem>
                                        <MenuItem value={UNIT.POWER}>
                                            <Typography variant="small1">{generalTranslation(UNIT.POWER)}</Typography>
                                        </MenuItem>
                                    </Select>
                                    {errors.measure_unit && (
                                        <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={2} width="calc(50% - 16px)">
                    <Box className={classnames(style.formInput, style.small)}>
                        <Controller
                            name="latitude"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink htmlFor={t('form.latitude')}>
                                        {t('form.latitude')}
                                    </InputLabel>
                                    <Input
                                        type="number"
                                        {...field}
                                        fullWidth
                                        id={t('form.latitude')}
                                        placeholder={t('form.latitude')}
                                    />
                                    <SvgIcon
                                        color="primary"
                                        className={style.pinIcon}
                                        fontSize="small"
                                        component={locationIcon}
                                    />
                                    {errors.latitude && (
                                        <FormHelperText error>
                                            {errors.latitude.type === 'customTest'
                                                ? t('form.errors.invalidCoordinate')
                                                : t('form.errors.generic')}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                    <Box className={classnames(style.formInput, style.small)}>
                        <Controller
                            name="longitude"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink htmlFor={t('form.longitude')}>
                                        {t('form.longitude')}
                                    </InputLabel>
                                    <Input
                                        type="number"
                                        {...field}
                                        fullWidth
                                        id={t('form.longitude')}
                                        placeholder={t('form.longitude')}
                                    />
                                    <SvgIcon
                                        color="primary"
                                        className={style.pinIcon}
                                        fontSize="small"
                                        component={locationIcon}
                                    />
                                    {errors.longitude && (
                                        <FormHelperText error>
                                            {errors.longitude.type === 'customTest'
                                                ? t('form.errors.invalidCoordinate')
                                                : t('form.errors.generic')}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                </Stack>
            </Stack>

            <Divider sx={{ mt: 3, mb: 3 }} />

            {/* installationType tracking secondary1 secondary2 */}
            <Stack direction="row" justifyContent="space-between" spacing={4}>
                <Stack direction="row" justifyContent="space-between" spacing={2} width="calc(50% - 16px)">
                    <Box className={classnames(style.formInput, style.small)}>
                        <Controller
                            name="installation_type"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink htmlFor={t('form.installationType')}>
                                        {t('form.installationType')}
                                    </InputLabel>

                                    <Autocomplete
                                        {...field}
                                        id="installation_type"
                                        onChange={(e, value) => {
                                            field.onChange(value);
                                            return value;
                                        }}
                                        noOptionsText={generalTranslation('select.noOptions')}
                                        options={installationTypes}
                                        autoHighlight
                                        getOptionLabel={(option) => {
                                            return option;
                                        }}
                                        renderOption={(props, type) => (
                                            <Typography variant="small1" {...props}>
                                                {type}
                                            </Typography>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="installation_type"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: t('form.installationType')
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.installation_type && (
                                        <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                    {assetType === ASSET_TYPE.WIND && (
                        <Box className={classnames(style.formInput, style.small)}>
                            <Controller
                                name="windTracking"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputLabel variant="standard" required shrink htmlFor={t('form.windTracking')}>
                                            {t('form.windTracking')}
                                        </InputLabel>
                                        <Select {...field} fullWidth label={t('form.windTracking')}>
                                            <MenuItem value={false as any}>
                                                <Typography variant="small1">{generalTranslation('no')}</Typography>
                                            </MenuItem>
                                            <MenuItem value={true as any}>
                                                <Typography variant="small1">{generalTranslation('yes')}</Typography>
                                            </MenuItem>
                                        </Select>
                                        {errors.windTracking && (
                                            <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Box>
                    )}
                    {assetType === ASSET_TYPE.SOLAR && (
                        <Box className={classnames(style.formInput, style.small)}>
                            <Controller
                                name="solarTracking"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputLabel
                                            variant="standard"
                                            required
                                            shrink
                                            htmlFor={t('form.solarTracking')}
                                        >
                                            {t('form.solarTracking')}
                                        </InputLabel>
                                        <Select {...field} fullWidth label={t('form.solarTracking')}>
                                            <MenuItem value={false as any}>
                                                <Typography variant="small1">{generalTranslation('no')}</Typography>
                                            </MenuItem>
                                            <MenuItem value={true as any}>
                                                <Typography variant="small1">{generalTranslation('yes')}</Typography>
                                            </MenuItem>
                                        </Select>
                                        {errors.solarTracking && (
                                            <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Box>
                    )}
                </Stack>
                {/* secondary input */}
                {assetType === ASSET_TYPE.WIND && (
                    <Stack direction="row" justifyContent="space-between" spacing={2} width="calc(50% - 16px)">
                        <Box className={classnames(style.formInput, style.small)}>
                            <Controller
                                name="hub_height"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputLabel variant="standard" required shrink htmlFor={t('form.hubHeight')}>
                                            {t('form.hubHeight')}
                                        </InputLabel>
                                        <Input
                                            {...field}
                                            type="number"
                                            fullWidth
                                            id={t('form.hubHeight')}
                                            placeholder={t('form.hubHeight')}
                                        />
                                        {errors.hub_height && (
                                            <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Box>
                        <Box className={classnames(style.formInput, style.small)}>
                            <Controller
                                name="turbines"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputLabel variant="standard" required shrink htmlFor={t('form.turbines')}>
                                            {t('form.turbines')}
                                        </InputLabel>
                                        <Input
                                            type="number"
                                            {...field}
                                            fullWidth
                                            id={t('form.turbines')}
                                            placeholder={t('form.turbines')}
                                        />
                                        {errors.turbines && (
                                            <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Box>
                    </Stack>
                )}

                {assetType === ASSET_TYPE.SOLAR && (
                    <Stack direction="row" justifyContent="space-between" spacing={2} width="calc(50% - 16px)">
                        <Box className={classnames(style.formInput, style.small)}>
                            <Controller
                                name="azimuth"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputLabel variant="standard" required shrink htmlFor={t('form.azimuth')}>
                                            {t('form.azimuth')}
                                        </InputLabel>
                                        <Input
                                            {...field}
                                            type="number"
                                            fullWidth
                                            id={t('form.azimuth')}
                                            placeholder={t('form.azimuth')}
                                        />
                                        {errors.azimuth && (
                                            <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Box>
                        <Box className={classnames(style.formInput, style.small)}>
                            <Controller
                                name="tilt_angle"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputLabel variant="standard" required shrink htmlFor={t('form.tiltAngle')}>
                                            {t('form.tiltAngle')}
                                        </InputLabel>
                                        <Input
                                            {...field}
                                            type="number"
                                            fullWidth
                                            id={t('form.tiltAngle')}
                                            placeholder={t('form.tiltAngle')}
                                        />
                                        {errors.tilt_angle && (
                                            <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Box>
                    </Stack>
                )}
            </Stack>

            {/* installationDate expiration status */}
            <Stack direction="row" spacing={4} justifyContent="space-between" width="100%">
                <Stack direction="row" justifyContent="space-between" spacing={2} width="calc(50% - 16px)">
                    <Box
                        className={classnames(style.formInput, style.small, style.noCalendarIcon, style.calendarInput)}
                    >
                        <Controller
                            name="installation_date"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink htmlFor={t('form.installationDate')}>
                                        {t('form.installationDate')}
                                    </InputLabel>

                                    <DesktopDatePicker
                                        {...field}
                                        inputFormat="DD|MM|YYYY"
                                        renderInput={(params) => (
                                            <TextField
                                                id="installation_date"
                                                variant="standard"
                                                color="primary"
                                                fullWidth
                                                {...params}
                                            />
                                        )}
                                    />
                                    {errors.installation_date && (
                                        <FormHelperText error>{t('form.errors.invalidDate')}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                    <Box
                        className={classnames(style.formInput, style.small, style.noCalendarIcon, style.calendarInput)}
                    >
                        <Controller
                            name="expiry_date"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink htmlFor={t('form.expiryDate')}>
                                        {t('form.expiryDate')}
                                    </InputLabel>

                                    <DesktopDatePicker
                                        {...field}
                                        inputFormat="DD|MM|YYYY"
                                        renderInput={(params) => (
                                            <TextField
                                                id="expiry_date"
                                                variant="standard"
                                                color="primary"
                                                fullWidth
                                                {...params}
                                            />
                                        )}
                                    />
                                    {errors.expiry_date && (
                                        <FormHelperText error>{t('form.errors.invalidDate')}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={2} width="calc(50% - 16px)">
                    <Box className={classnames(style.formInput, style.extraSmall)}>
                        <InputLabel variant="standard" required shrink htmlFor={t('form.status')}>
                            {t('form.status')}
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="asset-status-label"
                            id="asset-status-id"
                            value={asset ? asset.status : ASSET_STATUS.PENDING}
                            label={t('form.status')}
                            disabled
                        >
                            <MenuItem value={ASSET_STATUS.PENDING}>
                                <AssetStatusLabel status={ASSET_STATUS.PENDING}></AssetStatusLabel>
                            </MenuItem>
                            <MenuItem value={ASSET_STATUS.ACTIVE}>
                                <AssetStatusLabel status={ASSET_STATUS.ACTIVE}></AssetStatusLabel>
                            </MenuItem>
                            <MenuItem value={ASSET_STATUS.DISABLED}>
                                <AssetStatusLabel status={ASSET_STATUS.DISABLED}></AssetStatusLabel>
                            </MenuItem>
                        </Select>
                    </Box>
                </Stack>
            </Stack>

            <Divider sx={{ mt: 4 }} />

            <FormModalActions
                loading={loading}
                disabled={!isDirty}
                modalId={MODAL_IDS.CREATE_ASSET_MODAL}
                note={generalTranslation('mandatoryFields')}
                cancelLabel={generalTranslation('nevermind')}
                saveLabel={t('form.submit')}
            />
        </Box>
    );
};

export default GenerationAssetForm;
