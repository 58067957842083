import { RootState } from '../index';
import axios from '../../utils/axios';
import { Forecast } from '../../interfaces/apiv2';
import { DefaultInitialState } from '../../interfaces/redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { commonReduxErrorHandler } from '../../utils/error-handling';

interface IListForecast extends DefaultInitialState {
    forecasts: Forecast[];
    errorForecast: boolean;
    loadingForecast: boolean;
    successForecast: boolean;
}

const initialState: IListForecast = {
    loading: false,
    error: false,
    success: false,
    forecasts: [],
    errorForecast: false,
    loadingForecast: false,
    successForecast: false
};

export const listAssetForecastAsync = createAsyncThunk(
    'asset_forecast/forecast',
    async (values: { id: string; from: number; to: number }, { rejectWithValue }) => {
        try {
            const response = await axios.get<Forecast[]>(`/parks/${values.id}/forecast`, {
                cache: {
                    ttl: 1000 * 60 * 15
                },
                params: {
                    from_datetime: values.from,
                    to_datetime: values.to
                }
            });

            return response.data as Forecast[];
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    },
    {
        condition: (
            values: { id: string; from: number; to: number },
            { getState }: { getState: () => RootState; extra: any }
        ) => {
            const { loadingForecast } = getState().assetPage;

            return !loadingForecast;
        }
    }
);

export const assetForecast = createSlice({
    name: 'assetForecast',
    initialState,
    reducers: {
        resetAssetForecast: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(listAssetForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(listAssetForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            state.forecasts = payload;
        });
        builder.addCase(listAssetForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
    }
});

export const { resetAssetForecast } = assetForecast.actions;

export default assetForecast.reducer;
