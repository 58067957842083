import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { MdDeleteOutline, MdRefresh } from 'react-icons/md';

import { ROUTES } from '../../utils/config';
import { MODAL_IDS } from '../../modals';
import { getProsumersHeadCells } from './helpers';
import { ModalContext } from '../../hooks/modalContext';
import { ProsumersDataFE } from '../../interfaces/uiv2';
import { PageContainer } from '../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../stores';
import { AssetLocationModal } from '../../modals/AssetLocationModal';
import { GenericTable, TableSearch } from '../../components/BaseTable';
import { getProsumersListAsync, resetListProsumersState, setGetParams } from '../../stores/Prosumers';
import { transformOrderByToSort } from '../../components/BaseTable/GenericTable/helpers';

import style from '../../features/forecast-list/forecast-list.module.scss';
import useStickyHeader from '../../hooks/useStickyHeader';

const ProsumersList = () => {
    const dispatch = useAppDispatch();
    const { handleClickOpen } = useContext(ModalContext);
    const [selectedRows, setSelectedRows] = useState([]);
    const [activeRow, setActiveRow] = useState<ProsumersDataFE>();

    const { prosumersFE, loading, getParams, success } = useAppSelector((state) => state.listProsumers);

    useEffect(() => {
        dispatch(getProsumersListAsync(getParams));
        return () => {
            dispatch(resetListProsumersState());
        };
    }, []);

    const { t } = useTranslation();
    const headCells = getProsumersHeadCells(t);

    const clearSelection = () => {
        setSelectedRows([]);
    };

    const openConfirmationModal = () => {
        handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    };

    const openModal = (modalId: MODAL_IDS) => (row: ProsumersDataFE) => () => {
        setActiveRow(row);
        handleClickOpen(modalId);
    };

    const getProsumersPageUrl = (row: ProsumersDataFE) => () => {
        return `${ROUTES.AI_PROSUMERS_PAGE.replace(':id', row.id)}`;
    };

    useStickyHeader('prosumers-list-header');
    return (
        <PageContainer>
            <Box className={style.header} id={'prosumers-list-header'}>
                <Typography variant="h6" className={style.title}>
                    {t('header.prosumers')}
                </Typography>
                {selectedRows.length ? (
                    <Box className={style.toolbar}>
                        {/* selected items toolbar */}

                        <Typography variant="small4" className={style.label}>
                            {selectedRows.length} {t('table.general.selected')}
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <MdRefresh />
                            <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                {t('table.general.clearSelection')}
                            </Box>
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <MdDeleteOutline />
                            <Box
                                component="span"
                                className={style.primary}
                                onClick={openConfirmationModal}
                                sx={{ ml: 1 }}
                            >
                                {t('table.general.removeAll')}
                            </Box>
                        </Typography>
                        <TableSearch setGetParams={setGetParams} getParams={getParams} />
                    </Box>
                ) : (
                    <>
                        {/* Non selected toolbar */}
                        <Box className={style.toolbar}>
                            <TableSearch setGetParams={(() => {}) as any} getParams={getParams} />
                        </Box>
                    </>
                )}
            </Box>

            <GenericTable
                rows={prosumersFE}
                loading={loading}
                success={success}
                pager={null}
                isCheckboxEnabled
                columns={headCells}
                rowUniqueId={'id'}
                selectedRows={selectedRows}
                setGetParams={setGetParams}
                searchParam={getParams.search}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getProsumersPageUrl,
                    location: openModal(MODAL_IDS.ASSET_LOCATION_MODAL)
                }}
            />
            {activeRow && (
                <AssetLocationModal
                    payload={{
                        longitude: activeRow.longitude,
                        latitude: activeRow.latitude,
                        title: activeRow.id,
                        location: activeRow.country
                    }}
                />
            )}
        </PageContainer>
    );
};

export default ProsumersList;
