import classNames from 'classnames';
import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Box, DialogContent, Divider, Typography } from '@mui/material';
import { CSSProperties, FC, useEffect, useRef, useState, Children, isValidElement, cloneElement } from 'react';

import { MODAL_IDS } from '..';
import { ddToDms } from '../../utils/table';
import { ASSET_TYPE } from '../../interfaces/uiv2';
import { BaseModal } from '../../components/BaseModal';
import { getAssetIcon } from '../../utils/getAssetIcon';
import { CloseModalButton } from '../../components/BaseModal/CloseModalButton';
import { ReactComponent as LocationSvg } from '../../assets/icons/location.svg';
import { ReactComponent as FullScreenSvg } from '../../assets/icons/fullscreen-button.svg';
import { ReactComponent as FullScreenCloseSvg } from '../../assets/icons/fullscreen-close-button.svg';

import style from './style.module.scss';

interface MapPayload {
    title: string;
    location: string;
    latitude: number;
    longitude: number;
    type?: ASSET_TYPE;
}

/* eslint-disable */
// TODO: Rewrite using 'google-map-react', maybe
const Map: FC<{ payload: MapPayload; style: CSSProperties }> = ({ payload, style, children }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }

        if (map) {
            map.setOptions({
                center: {
                    lat: payload.latitude,
                    lng: payload.longitude
                },
                zoom: 10,
                disableDefaultUI: true
                // draggable: false
            });
        }
    }, [ref, map]);

    return (
        <>
            <div ref={ref} style={style} />
            {Children.map(children, (child) => {
                if (isValidElement(child)) {
                    // set the map prop on the child component
                    return cloneElement(child, { map });
                }
            })}
        </>
    );
};

const Marker: FC<google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};

/* eslint-enable */
const ContentDefault = ({ payload, handleFullSize }: { payload: MapPayload; handleFullSize: () => void }) => {
    const { t: tList } = useTranslation('assets/generation');
    const MAP_STYLE = { flexGrow: '1', height: '100%', borderRadius: '16px' };

    const country = payload.location.toUpperCase();

    return (
        <DialogContent className={style.modalContainer}>
            <Box className={style.header}>
                {payload.type && getAssetIcon(payload.type, 'm-r-2')}
                <Typography variant="h6">{payload.title}</Typography>
            </Box>
            <Divider />
            <Box className={style.body}>
                <Box>
                    <Typography variant="small4">{tList('tableHead.country')}</Typography>
                    <Box className={classNames(style.center, style.mt10)}>
                        <Flag
                            height="20"
                            width="20"
                            code={country}
                            style={{ marginRight: '8px' }}
                            className="flagIcon"
                        />
                        <Typography variant="small3">{country}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography variant="small4">{tList('tableHead.location')}</Typography>
                    <Box className={classNames(style.center, style.primary, style.mt10)}>
                        <LocationSvg />
                        <Typography variant="small4">{ddToDms(payload.latitude, payload.longitude)}</Typography>
                        {/* <Typography variant="small1">44° 29′ 32.28″ N, 26° 4′ 18.84″ E</Typography> */}
                    </Box>
                </Box>
            </Box>
            <Box className={style.mapContainer}>
                <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
                    <Map payload={payload} style={MAP_STYLE}>
                        <Marker position={{ lat: payload.latitude, lng: payload.longitude }} />
                    </Map>
                </Wrapper>
                <Box className={style.btnWrapper} onClick={handleFullSize}>
                    <FullScreenSvg />
                </Box>
            </Box>
        </DialogContent>
    );
};

const ContentFullscreen = ({ payload, reset }: { payload: MapPayload; reset: () => void }) => {
    const MAP_STYLE = { flexGrow: '1', height: '100%', borderRadius: '16px' };

    return (
        <DialogContent>
            <Box className={style.fullScreenMapContainer}>
                <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
                    <Map payload={payload} style={MAP_STYLE}>
                        <Marker position={{ lat: payload.latitude, lng: payload.longitude }} />
                    </Map>
                </Wrapper>
                <Box className={style.btnWrapper} onClick={reset}>
                    <FullScreenCloseSvg />
                </Box>
            </Box>
        </DialogContent>
    );
};

const AssetLocationModal: FC<{ payload?: MapPayload }> = ({ payload }) => {
    const [fullscreen, setFullscreen] = useState(false);

    const handleFullSize = () => {
        setFullscreen(true);
    };

    const reset = () => {
        // 200 = modal closing animation duration
        setTimeout(() => setFullscreen(false), 200);
    };

    if (!payload) {
        return null;
    }

    return (
        <BaseModal
            maxWidth="lg"
            callback={reset}
            fullWidth={false}
            closeOnBackdropClick
            fullScreen={fullscreen}
            id={MODAL_IDS.ASSET_LOCATION_MODAL}
        >
            <CloseModalButton
                id={MODAL_IDS.ASSET_LOCATION_MODAL}
                className={style.closeButton}
                callback={reset}
                top={fullscreen ? 8 : 16}
            />
            {fullscreen ? (
                <ContentFullscreen payload={payload} reset={reset} />
            ) : (
                <ContentDefault payload={payload} handleFullSize={handleFullSize} />
            )}
        </BaseModal>
    );
};

export { AssetLocationModal };
