import { toast, ToastOptions } from 'react-toastify';

import { ReactComponent as OgreBadge } from '../assets/icons/notification-icon.svg';

const defaultOptions = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    closeButton: false,
    progress: undefined,
    icon: () => (
        <>
            <OgreBadge className={'toastIcon'} />
            <div className={'toastSquare'}></div>
        </>
    )
};

const ToastifyType = {
    default: defaultOptions as ToastOptions,
    success: {
        ...defaultOptions
    },
    error: {
        ...defaultOptions
    } as ToastOptions,
    warning: {
        ...defaultOptions
    } as ToastOptions,
    info: {
        ...defaultOptions
    } as ToastOptions
};

export default ToastifyType;
