import store from 'store';
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import i18next from '../../../utils/i18next';
import { LoginBody } from '../../../interfaces/uiv2';
import { ROUTES, storage } from '../../../utils/config';
import ToastifyType from '../../../utils/toastify-config';
import axios, { resetInterceptors } from '../../../utils/axios';
import { User, LoginResponse } from '../../../interfaces/apiv2';
import { DefaultInitialState } from '../../../interfaces/redux';
import { getActivePathsFromBEPermissions } from '../../../utils/user';
import { commonReduxErrorHandler } from '../../../utils/error-handling';
import { redirect } from 'react-router-dom';

export interface LoginState extends DefaultInitialState {
    user: User | null;
    /** Indicates if the user has access to the ADMIN view of the app and NOT IF IT IS AN ADMIN*/
    hasAdminAccess: boolean;
    /** Indicates if the user has access to the MANAGER view of the app */
    hasManagerAccess: boolean;
}

const initialState: LoginState = {
    loading: false,
    error: false,
    success: false,
    user: null,
    hasAdminAccess: false,
    hasManagerAccess: false
};

// used on login when we have a token, refreshes the page
export const loginAsync = createAsyncThunk('auth/login', async (values: LoginBody, { rejectWithValue }) => {
    try {
        const response = await axios.post<LoginResponse>('/login', values);
        commonOnLoginSuccess(response.data);
        return response.data;
    } catch (e) {
        commonOnLoginError();
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

// used for account settings, doesn't need to refresh the app to update the user info
export const getSelfAsync = createAsyncThunk('auth/me', async (values, { rejectWithValue }) => {
    try {
        const response = await axios.get<User>(`/me`);

        return response.data;
    } catch (e) {
        commonOnLoginError();
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const updateSelfAsync = createAsyncThunk('auth/update-me', async (values, { rejectWithValue }) => {
    try {
        const response = await axios.get<User>(`/me`);

        return response.data;
    } catch (e) {
        commonOnLoginError();
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

const commonOnLoginSuccess = ({ authorization }: LoginResponse) => {
    store.set(storage.token, authorization);
    redirect(ROUTES.INDEX);
};

const commonOnLoginError = () => {
    store.clearAll();
    resetInterceptors(axios);
    toast.error(i18next.t('login.loginError'), ToastifyType.error);
};

// State<LoginState>
const updateUserRelatedState = (state: any, user: User) => {
    state.hasAdminAccess = !!getActivePathsFromBEPermissions(user.permissions.Admin).length;
    state.hasManagerAccess = !!getActivePathsFromBEPermissions(user.permissions.Electricity).length;
};

export const login = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loginAsync.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(loginAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.user = payload.user;

            updateUserRelatedState(state, payload.user);
        });
        builder.addCase(loginAsync.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });

        builder.addCase(getSelfAsync.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(getSelfAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.user = payload;

            updateUserRelatedState(state, payload);
        });
        builder.addCase(getSelfAsync.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });

        builder.addCase(updateSelfAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.user = payload;
        });
    }
});

export default login.reducer;
