import classnames from 'classnames';
import { Typography } from '@mui/material';
import { ReactComponent as ErrorIcon } from '../../assets/icons/happy-toast-icon.svg';

import style from './style.module.scss';
import { useTranslation } from 'react-i18next';

const NoValueTable = ({ label, className }: { label?: string; className?: string }) => {
    const { t: generalTranslation } = useTranslation();
    return (
        <>
            <Typography variant="small1" className={classnames(style.noValueContainer, className)}>
                <ErrorIcon />
                {label || generalTranslation('noDataFound')}
            </Typography>
        </>
    );
};

export default NoValueTable;
