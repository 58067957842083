// prettier-ignore

import dayjs from 'dayjs';
import { Forecast } from '../../interfaces/apiv2';

export const mockedConsumersForecast = [
    {
        timestamp: 1668211200000,
        value: 68.7742390743
    },
    {
        timestamp: 1668212100000,
        value: 68.9823195352
    },
    {
        timestamp: 1668213000000,
        value: 69.2257387587
    },
    {
        timestamp: 1668213900000,
        value: 69.660560394
    },
    {
        timestamp: 1668214800000,
        value: 69.3714640545
    },
    {
        timestamp: 1668215700000,
        value: 68.5606275679
    },
    {
        timestamp: 1668216600000,
        value: 68.489290381
    },
    {
        timestamp: 1668217500000,
        value: 68.714968677
    },
    {
        timestamp: 1668218400000,
        value: 68.8775067266
    },
    {
        timestamp: 1668219300000,
        value: 68.5565070075
    },
    {
        timestamp: 1668220200000,
        value: 68.463594885
    },
    {
        timestamp: 1668221100000,
        value: 69.1305846585
    },
    {
        timestamp: 1668222000000,
        value: 69.061620412
    },
    {
        timestamp: 1668222900000,
        value: 68.7801598197
    },
    {
        timestamp: 1668223800000,
        value: 68.2827033077
    },
    {
        timestamp: 1668224700000,
        value: 68.2658519895
    },
    {
        timestamp: 1668225600000,
        value: 68.0372361959
    },
    {
        timestamp: 1668226500000,
        value: 67.148036335
    },
    {
        timestamp: 1668227400000,
        value: 67.2448540597
    },
    {
        timestamp: 1668228300000,
        value: 67.9262872965
    },
    {
        timestamp: 1668229200000,
        value: 67.9418546413
    },
    {
        timestamp: 1668230100000,
        value: 66.8060852724
    },
    {
        timestamp: 1668231000000,
        value: 66.2121675494
    },
    {
        timestamp: 1668231900000,
        value: 66.4259515863
    },
    {
        timestamp: 1668232800000,
        value: 66.5889222474
    },
    {
        timestamp: 1668233700000,
        value: 65.8457283962
    },
    {
        timestamp: 1668234600000,
        value: 65.8721669712
    },
    {
        timestamp: 1668235500000,
        value: 66.3662048691
    },
    {
        timestamp: 1668236400000,
        value: 65.9248636258
    },
    {
        timestamp: 1668237300000,
        value: 65.8994688668
    },
    {
        timestamp: 1668238200000,
        value: 66.0381479793
    },
    {
        timestamp: 1668239100000,
        value: 66.449642835
    },
    {
        timestamp: 1668240000000,
        value: 66.0985978934
    },
    {
        timestamp: 1668240900000,
        value: 66.9987454664
    },
    {
        timestamp: 1668241800000,
        value: 66.6987367952
    },
    {
        timestamp: 1668242700000,
        value: 67.112842303
    },
    {
        timestamp: 1668243600000,
        value: 67.107328744
    },
    {
        timestamp: 1668244500000,
        value: 67.3923163675
    },
    {
        timestamp: 1668245400000,
        value: 67.0093806638
    },
    {
        timestamp: 1668246300000,
        value: 67.1170343032
    },
    {
        timestamp: 1668247200000,
        value: 66.5574833891
    },
    {
        timestamp: 1668248100000,
        value: 66.3977158966
    },
    {
        timestamp: 1668249000000,
        value: 66.3243921359
    },
    {
        timestamp: 1668249900000,
        value: 66.5520354867
    },
    {
        timestamp: 1668250800000,
        value: 66.0149971242
    },
    {
        timestamp: 1668251700000,
        value: 65.9199407387
    },
    {
        timestamp: 1668252600000,
        value: 65.345532176
    },
    {
        timestamp: 1668253500000,
        value: 65.5152975965
    },
    {
        timestamp: 1668254400000,
        value: 65.4785439487
    },
    {
        timestamp: 1668255300000,
        value: 65.142272317
    },
    {
        timestamp: 1668256200000,
        value: 65.2199966992
    },
    {
        timestamp: 1668257100000,
        value: 65.2204690431
    },
    {
        timestamp: 1668258000000,
        value: 64.983562054
    },
    {
        timestamp: 1668258900000,
        value: 64.4017132347
    },
    {
        timestamp: 1668259800000,
        value: 64.3851695747
    },
    {
        timestamp: 1668260700000,
        value: 64.3766068135
    },
    {
        timestamp: 1668261600000,
        value: 63.1983460293
    },
    {
        timestamp: 1668262500000,
        value: 62.9701133104
    },
    {
        timestamp: 1668263400000,
        value: 62.2986833936
    },
    {
        timestamp: 1668264300000,
        value: 62.062592535
    },
    {
        timestamp: 1668265200000,
        value: 61.4959621438
    },
    {
        timestamp: 1668266100000,
        value: 61.8098493305
    },
    {
        timestamp: 1668267000000,
        value: 61.0977799693
    },
    {
        timestamp: 1668267900000,
        value: 61.2008807818
    },
    {
        timestamp: 1668268800000,
        value: 61.6189500262
    },
    {
        timestamp: 1668269700000,
        value: 61.4759063414
    },
    {
        timestamp: 1668270600000,
        value: 61.6472976989
    },
    {
        timestamp: 1668271500000,
        value: 62.108776021
    },
    {
        timestamp: 1668272400000,
        value: 61.3950797774
    },
    {
        timestamp: 1668273300000,
        value: 61.7292820294
    },
    {
        timestamp: 1668274200000,
        value: 62.0913027992
    },
    {
        timestamp: 1668275100000,
        value: 61.7919601923
    },
    {
        timestamp: 1668276000000,
        value: 62.1678488026
    },
    {
        timestamp: 1668276900000,
        value: 61.8002428021
    },
    {
        timestamp: 1668277800000,
        value: 61.166908326
    },
    {
        timestamp: 1668278700000,
        value: 61.1342555812
    },
    {
        timestamp: 1668279600000,
        value: 61.2923539608
    },
    {
        timestamp: 1668280500000,
        value: 61.2054642933
    },
    {
        timestamp: 1668281400000,
        value: 60.8262902739
    },
    {
        timestamp: 1668282300000,
        value: 60.8742200985
    },
    {
        timestamp: 1668283200000,
        value: 60.5727562429
    },
    {
        timestamp: 1668284100000,
        value: 60.4409277723
    },
    {
        timestamp: 1668285000000,
        value: 60.2514967519
    },
    {
        timestamp: 1668285900000,
        value: 60.4736138039
    },
    {
        timestamp: 1668286800000,
        value: 60.5150876659
    },
    {
        timestamp: 1668287700000,
        value: 60.9449539306
    },
    {
        timestamp: 1668288600000,
        value: 60.1888519353
    },
    {
        timestamp: 1668289500000,
        value: 59.8626976755
    },
    {
        timestamp: 1668290400000,
        value: 59.6029674162
    },
    {
        timestamp: 1668291300000,
        value: 59.2173365765
    },
    {
        timestamp: 1668292200000,
        value: 57.9011045305
    },
    {
        timestamp: 1668293100000,
        value: 58.172275337
    },
    {
        timestamp: 1668294000000,
        value: 58.4062740135
    },
    {
        timestamp: 1668294900000,
        value: 58.0681509977
    },
    {
        timestamp: 1668295800000,
        value: 58.0731109238
    },
    {
        timestamp: 1668296700000,
        value: 57.5863579425
    },
    {
        timestamp: 1668297600000,
        value: 56.7036419219
    },
    {
        timestamp: 1668298500000,
        value: 56.7696517794
    },
    {
        timestamp: 1668299400000,
        value: 56.7248342479
    },
    {
        timestamp: 1668300300000,
        value: 57.0447014736
    },
    {
        timestamp: 1668301200000,
        value: 56.880663317
    },
    {
        timestamp: 1668302100000,
        value: 57.1014351294
    },
    {
        timestamp: 1668303000000,
        value: 57.0035245066
    },
    {
        timestamp: 1668303900000,
        value: 57.070409445
    },
    {
        timestamp: 1668304800000,
        value: 56.9900411694
    },
    {
        timestamp: 1668305700000,
        value: 57.3128626775
    },
    {
        timestamp: 1668306600000,
        value: 57.2349412183
    },
    {
        timestamp: 1668307500000,
        value: 57.4189559405
    },
    {
        timestamp: 1668308400000,
        value: 58.1339418214
    },
    {
        timestamp: 1668309300000,
        value: 57.8351772494
    },
    {
        timestamp: 1668310200000,
        value: 57.7914709953
    },
    {
        timestamp: 1668311100000,
        value: 57.147921819
    },
    {
        timestamp: 1668312000000,
        value: 59.0285168079
    },
    {
        timestamp: 1668312900000,
        value: 58.8460279607
    },
    {
        timestamp: 1668313800000,
        value: 58.5897219938
    },
    {
        timestamp: 1668314700000,
        value: 58.0340788464
    },
    {
        timestamp: 1668315600000,
        value: 57.9835219577
    },
    {
        timestamp: 1668316500000,
        value: 57.7252515376
    },
    {
        timestamp: 1668317400000,
        value: 57.1108500085
    },
    {
        timestamp: 1668318300000,
        value: 57.1328165256
    },
    {
        timestamp: 1668319200000,
        value: 57.8289217307
    },
    {
        timestamp: 1668320100000,
        value: 58.0031878195
    },
    {
        timestamp: 1668321000000,
        value: 58.1820939014
    },
    {
        timestamp: 1668321900000,
        value: 58.3000680166
    },
    {
        timestamp: 1668322800000,
        value: 58.0634555421
    },
    {
        timestamp: 1668323700000,
        value: 57.5641002281
    },
    {
        timestamp: 1668324600000,
        value: 57.4738724704
    },
    {
        timestamp: 1668325500000,
        value: 57.4632275065
    },
    {
        timestamp: 1668326400000,
        value: 58.0286414813
    },
    {
        timestamp: 1668327300000,
        value: 57.9567905588
    },
    {
        timestamp: 1668328200000,
        value: 58.0520985674
    },
    {
        timestamp: 1668329100000,
        value: 57.9259922552
    },
    {
        timestamp: 1668330000000,
        value: 58.6546653118
    },
    {
        timestamp: 1668330900000,
        value: 58.8219487358
    },
    {
        timestamp: 1668331800000,
        value: 58.8653408239
    },
    {
        timestamp: 1668332700000,
        value: 58.9601138244
    },
    {
        timestamp: 1668333600000,
        value: 59.553559674
    },
    {
        timestamp: 1668334500000,
        value: 59.5684089175
    },
    {
        timestamp: 1668335400000,
        value: 59.3843000097
    },
    {
        timestamp: 1668336300000,
        value: 59.4571462574
    },
    {
        timestamp: 1668337200000,
        value: 59.5663370063
    },
    {
        timestamp: 1668338100000,
        value: 59.3843791495
    },
    {
        timestamp: 1668339000000,
        value: 59.1222447709
    },
    {
        timestamp: 1668339900000,
        value: 59.2688786091
    },
    {
        timestamp: 1668340800000,
        value: 59.2377660444
    },
    {
        timestamp: 1668341700000,
        value: 59.277194772
    },
    {
        timestamp: 1668342600000,
        value: 59.2748394356
    },
    {
        timestamp: 1668343500000,
        value: 58.981215708
    },
    {
        timestamp: 1668344400000,
        value: 58.3104441583
    },
    {
        timestamp: 1668345300000,
        value: 58.2603852246
    },
    {
        timestamp: 1668346200000,
        value: 58.043565315
    },
    {
        timestamp: 1668347100000,
        value: 57.7447990946
    },
    {
        timestamp: 1668348000000,
        value: 57.2005716661
    },
    {
        timestamp: 1668348900000,
        value: 56.8496902414
    },
    {
        timestamp: 1668349800000,
        value: 56.8180391444
    },
    {
        timestamp: 1668350700000,
        value: 56.8177518202
    },
    {
        timestamp: 1668351600000,
        value: 56.8837060701
    },
    {
        timestamp: 1668352500000,
        value: 56.807649796
    },
    {
        timestamp: 1668353400000,
        value: 56.6009863605
    },
    {
        timestamp: 1668354300000,
        value: 56.7194216297
    },
    {
        timestamp: 1668355200000,
        value: 57.1878460178
    },
    {
        timestamp: 1668356100000,
        value: 57.2888749515
    },
    {
        timestamp: 1668357000000,
        value: 56.8117684145
    },
    {
        timestamp: 1668357900000,
        value: 57.1744976128
    },
    {
        timestamp: 1668358800000,
        value: 57.3711409777
    },
    {
        timestamp: 1668359700000,
        value: 57.5330616195
    },
    {
        timestamp: 1668360600000,
        value: 57.2018700152
    },
    {
        timestamp: 1668361500000,
        value: 57.0859898526
    },
    {
        timestamp: 1668362400000,
        value: 57.5417762896
    },
    {
        timestamp: 1668363300000,
        value: 57.5533914957
    },
    {
        timestamp: 1668364200000,
        value: 57.4458443699
    },
    {
        timestamp: 1668365100000,
        value: 57.4770817886
    },
    {
        timestamp: 1668366000000,
        value: 57.5156729562
    },
    {
        timestamp: 1668366900000,
        value: 57.5212662067
    },
    {
        timestamp: 1668367800000,
        value: 57.5623488567
    },
    {
        timestamp: 1668368700000,
        value: 57.3479978235
    },
    {
        timestamp: 1668369600000,
        value: 57.2667804976
    },
    {
        timestamp: 1668370500000,
        value: 56.9985906397
    },
    {
        timestamp: 1668371400000,
        value: 56.7136639244
    },
    {
        timestamp: 1668372300000,
        value: 57.0725260373
    },
    {
        timestamp: 1668373200000,
        value: 56.6072000395
    },
    {
        timestamp: 1668374100000,
        value: 56.8146571718
    },
    {
        timestamp: 1668375000000,
        value: 57.1718381129
    },
    {
        timestamp: 1668375900000,
        value: 57.1130934282
    },
    {
        timestamp: 1668376800000,
        value: 57.0341997919
    },
    {
        timestamp: 1668377700000,
        value: 56.899286763
    },
    {
        timestamp: 1668378600000,
        value: 57.2723721783
    },
    {
        timestamp: 1668379500000,
        value: 57.3518619617
    },
    {
        timestamp: 1668380400000,
        value: 57.8664341566
    },
    {
        timestamp: 1668381300000,
        value: 58.2013954325
    },
    {
        timestamp: 1668382200000,
        value: 57.9025948812
    },
    {
        timestamp: 1668383100000,
        value: 58.1869772746
    },
    {
        timestamp: 1668384000000,
        value: 56.0458640189
    },
    {
        timestamp: 1668384900000,
        value: 56.24527523
    },
    {
        timestamp: 1668385800000,
        value: 56.3059586065
    },
    {
        timestamp: 1668386700000,
        value: 56.6091518972
    },
    {
        timestamp: 1668387600000,
        value: 57.2110816441
    },
    {
        timestamp: 1668388500000,
        value: 57.4708064298
    },
    {
        timestamp: 1668389400000,
        value: 57.5693764762
    },
    {
        timestamp: 1668390300000,
        value: 57.9271200289
    },
    {
        timestamp: 1668391200000,
        value: 57.7431298032
    },
    {
        timestamp: 1668392100000,
        value: 57.842363796
    },
    {
        timestamp: 1668393000000,
        value: 58.0335379861
    },
    {
        timestamp: 1668393900000,
        value: 58.2306524333
    },
    {
        timestamp: 1668394800000,
        value: 58.1160735223
    },
    {
        timestamp: 1668395700000,
        value: 58.6583872716
    },
    {
        timestamp: 1668396600000,
        value: 58.8412930885
    },
    {
        timestamp: 1668397500000,
        value: 59.3317525527
    },
    {
        timestamp: 1668398400000,
        value: 59.5492681014
    },
    {
        timestamp: 1668399300000,
        value: 59.7785837111
    },
    {
        timestamp: 1668400200000,
        value: 60.1817430341
    },
    {
        timestamp: 1668401100000,
        value: 60.7474151053
    },
    {
        timestamp: 1668402000000,
        value: 61.1846337151
    },
    {
        timestamp: 1668402900000,
        value: 61.8911943145
    },
    {
        timestamp: 1668403800000,
        value: 61.9111499581
    },
    {
        timestamp: 1668404700000,
        value: 62.3145062224
    },
    {
        timestamp: 1668405600000,
        value: 63.8228716
    },
    {
        timestamp: 1668406500000,
        value: 65.1125575853
    },
    {
        timestamp: 1668407400000,
        value: 66.5721811411
    },
    {
        timestamp: 1668408300000,
        value: 66.9455625748
    },
    {
        timestamp: 1668409200000,
        value: 69.5972038689
    },
    {
        timestamp: 1668410100000,
        value: 70.4676733104
    },
    {
        timestamp: 1668411000000,
        value: 71.3331905289
    },
    {
        timestamp: 1668411900000,
        value: 71.7724379026
    },
    {
        timestamp: 1668412800000,
        value: 73.3279718928
    },
    {
        timestamp: 1668413700000,
        value: 73.4081945594
    },
    {
        timestamp: 1668414600000,
        value: 73.3310630558
    },
    {
        timestamp: 1668415500000,
        value: 73.8938263012
    },
    {
        timestamp: 1668416400000,
        value: 74.0602172694
    },
    {
        timestamp: 1668417300000,
        value: 74.0307326529
    },
    {
        timestamp: 1668418200000,
        value: 73.8334797674
    },
    {
        timestamp: 1668419100000,
        value: 73.7538608631
    },
    {
        timestamp: 1668420000000,
        value: 74.6168115608
    },
    {
        timestamp: 1668420900000,
        value: 74.7554016789
    },
    {
        timestamp: 1668421800000,
        value: 74.5960321215
    },
    {
        timestamp: 1668422700000,
        value: 74.2461287682
    },
    {
        timestamp: 1668423600000,
        value: 75.8390670592
    },
    {
        timestamp: 1668424500000,
        value: 75.6964206959
    },
    {
        timestamp: 1668425400000,
        value: 76.0427296569
    },
    {
        timestamp: 1668426300000,
        value: 75.7837118787
    },
    {
        timestamp: 1668427200000,
        value: 76.0022464138
    },
    {
        timestamp: 1668428100000,
        value: 76.0508116654
    },
    {
        timestamp: 1668429000000,
        value: 76.3077156184
    },
    {
        timestamp: 1668429900000,
        value: 76.7896530044
    },
    {
        timestamp: 1668430800000,
        value: 76.9730642777
    },
    {
        timestamp: 1668431700000,
        value: 77.1980922816
    },
    {
        timestamp: 1668432600000,
        value: 77.1670041525
    },
    {
        timestamp: 1668433500000,
        value: 76.8676152919
    },
    {
        timestamp: 1668434400000,
        value: 76.5983980178
    },
    {
        timestamp: 1668435300000,
        value: 76.8091247649
    },
    {
        timestamp: 1668436200000,
        value: 76.6149803147
    },
    {
        timestamp: 1668437100000,
        value: 76.1118617328
    },
    {
        timestamp: 1668438000000,
        value: 75.9846249635
    },
    {
        timestamp: 1668438900000,
        value: 76.1673048009
    },
    {
        timestamp: 1668439800000,
        value: 75.8341818088
    },
    {
        timestamp: 1668440700000,
        value: 75.8668749214
    },
    {
        timestamp: 1668441600000,
        value: 75.983054794
    },
    {
        timestamp: 1668442500000,
        value: 76.04570812
    },
    {
        timestamp: 1668443400000,
        value: 75.5286975065
    },
    {
        timestamp: 1668444300000,
        value: 75.7633449179
    },
    {
        timestamp: 1668445200000,
        value: 75.6869452893
    },
    {
        timestamp: 1668446100000,
        value: 75.6458718356
    },
    {
        timestamp: 1668447000000,
        value: 75.6972269236
    },
    {
        timestamp: 1668447900000,
        value: 75.3395339249
    },
    {
        timestamp: 1668448800000,
        value: 75.0931070785
    },
    {
        timestamp: 1668449700000,
        value: 75.341952661
    },
    {
        timestamp: 1668450600000,
        value: 75.3086355135
    },
    {
        timestamp: 1668451500000,
        value: 75.4437419616
    },
    {
        timestamp: 1668452400000,
        value: 75.4992819945
    },
    {
        timestamp: 1668453300000,
        value: 75.6483064914
    },
    {
        timestamp: 1668454200000,
        value: 76.6450447765
    },
    {
        timestamp: 1668455100000,
        value: 76.8206383584
    },
    {
        timestamp: 1668456000000,
        value: 76.1520947474
    },
    {
        timestamp: 1668456900000,
        value: 76.3615285849
    },
    {
        timestamp: 1668457800000,
        value: 76.120092878
    },
    {
        timestamp: 1668458700000,
        value: 75.6466104848
    },
    {
        timestamp: 1668459600000,
        value: 74.1811768155
    },
    {
        timestamp: 1668460500000,
        value: 74.2626994653
    },
    {
        timestamp: 1668461400000,
        value: 73.3444077804
    },
    {
        timestamp: 1668462300000,
        value: 72.6897688066
    },
    {
        timestamp: 1668463200000,
        value: 71.1541125042
    },
    {
        timestamp: 1668464100000,
        value: 71.1076286944
    },
    {
        timestamp: 1668465000000,
        value: 70.7052855888
    },
    {
        timestamp: 1668465900000,
        value: 70.3673247926
    },
    {
        timestamp: 1668466800000,
        value: 69.7612161124
    },
    {
        timestamp: 1668467700000,
        value: 69.8251933801
    },
    {
        timestamp: 1668468600000,
        value: 70.1059692712
    },
    {
        timestamp: 1668469500000,
        value: 69.9486988682
    },
    {
        timestamp: 1668470400000,
        value: 70.2748638745
    },
    {
        timestamp: 1668471300000,
        value: 70.4026951135
    },
    {
        timestamp: 1668472200000,
        value: 70.1682965752
    },
    {
        timestamp: 1668473100000,
        value: 70.4347895944
    },
    {
        timestamp: 1668474000000,
        value: 70.2997556092
    },
    {
        timestamp: 1668474900000,
        value: 69.6656627623
    },
    {
        timestamp: 1668475800000,
        value: 69.6233313698
    },
    {
        timestamp: 1668476700000,
        value: 69.7189405026
    },
    {
        timestamp: 1668477600000,
        value: 70.0639998532
    },
    {
        timestamp: 1668478500000,
        value: 69.3014550055
    },
    {
        timestamp: 1668479400000,
        value: 69.4876323731
    },
    {
        timestamp: 1668480300000,
        value: 69.2953935225
    },
    {
        timestamp: 1668481200000,
        value: 69.8586697195
    },
    {
        timestamp: 1668482100000,
        value: 69.5812034696
    },
    {
        timestamp: 1668483000000,
        value: 69.579711042
    },
    {
        timestamp: 1668483900000,
        value: 69.2071908981
    },
    {
        timestamp: 1668484800000,
        value: 70.3034876156
    },
    {
        timestamp: 1668485700000,
        value: 70.672304577
    },
    {
        timestamp: 1668486600000,
        value: 70.6290642713
    },
    {
        timestamp: 1668487500000,
        value: 70.6758485275
    },
    {
        timestamp: 1668488400000,
        value: 69.855117302
    },
    {
        timestamp: 1668489300000,
        value: 70.0653928596
    },
    {
        timestamp: 1668490200000,
        value: 70.4634659418
    },
    {
        timestamp: 1668491100000,
        value: 70.8185706814
    },
    {
        timestamp: 1668492000000,
        value: 71.0894062464
    },
    {
        timestamp: 1668492900000,
        value: 71.7955344025
    },
    {
        timestamp: 1668493800000,
        value: 72.3141433268
    },
    {
        timestamp: 1668494700000,
        value: 73.4209932726
    },
    {
        timestamp: 1668495600000,
        value: 75.7126483526
    },
    {
        timestamp: 1668496500000,
        value: 75.8235323489
    },
    {
        timestamp: 1668497400000,
        value: 76.5563150449
    },
    {
        timestamp: 1668498300000,
        value: 76.8129054932
    },
    {
        timestamp: 1668499200000,
        value: 77.5199239365
    },
    {
        timestamp: 1668500100000,
        value: 77.4140241311
    },
    {
        timestamp: 1668501000000,
        value: 77.7220547308
    },
    {
        timestamp: 1668501900000,
        value: 77.8288971598
    },
    {
        timestamp: 1668502800000,
        value: 77.8931394152
    },
    {
        timestamp: 1668503700000,
        value: 77.7330690947
    },
    {
        timestamp: 1668504600000,
        value: 77.7403923316
    },
    {
        timestamp: 1668505500000,
        value: 77.6270780428
    },
    {
        timestamp: 1668506400000,
        value: 78.5244958208
    },
    {
        timestamp: 1668507300000,
        value: 78.6564314062
    },
    {
        timestamp: 1668508200000,
        value: 78.6233602351
    },
    {
        timestamp: 1668509100000,
        value: 78.7484550265
    },
    {
        timestamp: 1668510000000,
        value: 79.4818187567
    },
    {
        timestamp: 1668510900000,
        value: 79.4268425353
    },
    {
        timestamp: 1668511800000,
        value: 79.471731849
    },
    {
        timestamp: 1668512700000,
        value: 79.4421873583
    },
    {
        timestamp: 1668513600000,
        value: 79.3349038181
    },
    {
        timestamp: 1668514500000,
        value: 79.2145073844
    },
    {
        timestamp: 1668515400000,
        value: 79.1217987274
    },
    {
        timestamp: 1668516300000,
        value: 79.1949465595
    },
    {
        timestamp: 1668517200000,
        value: 79.3235599231
    },
    {
        timestamp: 1668518100000,
        value: 79.5025685857
    },
    {
        timestamp: 1668519000000,
        value: 79.120603023
    },
    {
        timestamp: 1668519900000,
        value: 78.9784834732
    },
    {
        timestamp: 1668520800000,
        value: 78.6569639135
    },
    {
        timestamp: 1668521700000,
        value: 78.174235405
    },
    {
        timestamp: 1668522600000,
        value: 78.4991787552
    },
    {
        timestamp: 1668523500000,
        value: 78.1874873223
    },
    {
        timestamp: 1668524400000,
        value: 78.2900268715
    },
    {
        timestamp: 1668525300000,
        value: 78.1874091989
    },
    {
        timestamp: 1668526200000,
        value: 77.9785223677
    },
    {
        timestamp: 1668527100000,
        value: 77.8508470262
    },
    {
        timestamp: 1668528000000,
        value: 77.2351704846
    },
    {
        timestamp: 1668528900000,
        value: 77.6782738644
    },
    {
        timestamp: 1668529800000,
        value: 78.1359658363
    },
    {
        timestamp: 1668530700000,
        value: 77.5744550339
    },
    {
        timestamp: 1668531600000,
        value: 76.9257521209
    },
    {
        timestamp: 1668532500000,
        value: 76.7698917725
    },
    {
        timestamp: 1668533400000,
        value: 76.7205436084
    },
    {
        timestamp: 1668534300000,
        value: 76.7879920556
    },
    {
        timestamp: 1668535200000,
        value: 76.3837240391
    },
    {
        timestamp: 1668536100000,
        value: 76.4921125713
    },
    {
        timestamp: 1668537000000,
        value: 76.4523278326
    },
    {
        timestamp: 1668537900000,
        value: 76.1546643261
    },
    {
        timestamp: 1668538800000,
        value: 76.1576075555
    },
    {
        timestamp: 1668539700000,
        value: 76.2872219569
    },
    {
        timestamp: 1668540600000,
        value: 76.6017921134
    },
    {
        timestamp: 1668541500000,
        value: 76.4136348322
    },
    {
        timestamp: 1668542400000,
        value: 76.5696342701
    },
    {
        timestamp: 1668543300000,
        value: 76.663115514
    },
    {
        timestamp: 1668544200000,
        value: 76.6280449688
    },
    {
        timestamp: 1668545100000,
        value: 76.0514927079
    },
    {
        timestamp: 1668546000000,
        value: 75.6878971951
    },
    {
        timestamp: 1668546900000,
        value: 75.4484004675
    },
    {
        timestamp: 1668547800000,
        value: 75.2870414693
    },
    {
        timestamp: 1668548700000,
        value: 73.8821894044
    },
    {
        timestamp: 1668549600000,
        value: 72.2411172926
    },
    {
        timestamp: 1668550500000,
        value: 71.7287483514
    },
    {
        timestamp: 1668551400000,
        value: 71.7377228082
    },
    {
        timestamp: 1668552300000,
        value: 71.2608144048
    },
    {
        timestamp: 1668553200000,
        value: 70.7127952228
    },
    {
        timestamp: 1668554100000,
        value: 71.2476622599
    },
    {
        timestamp: 1668555000000,
        value: 71.0901664949
    },
    {
        timestamp: 1668555900000,
        value: 70.6539628711
    },
    {
        timestamp: 1668556800000,
        value: 70.159606059
    },
    {
        timestamp: 1668557700000,
        value: 70.1043650478
    },
    {
        timestamp: 1668558600000,
        value: 69.9866328306
    },
    {
        timestamp: 1668559500000,
        value: 69.9471538737
    },
    {
        timestamp: 1668560400000,
        value: 69.9634072082
    },
    {
        timestamp: 1668561300000,
        value: 69.8889553153
    },
    {
        timestamp: 1668562200000,
        value: 70.043047101
    },
    {
        timestamp: 1668563100000,
        value: 69.8862697633
    },
    {
        timestamp: 1668564000000,
        value: 69.6239702671
    },
    {
        timestamp: 1668564900000,
        value: 69.7531226369
    },
    {
        timestamp: 1668565800000,
        value: 69.8091097201
    },
    {
        timestamp: 1668566700000,
        value: 69.9242250702
    },
    {
        timestamp: 1668567600000,
        value: 69.6936638548
    },
    {
        timestamp: 1668568500000,
        value: 69.9759784912
    },
    {
        timestamp: 1668569400000,
        value: 69.9464755068
    },
    {
        timestamp: 1668570300000,
        value: 69.8781879725
    },
    {
        timestamp: 1668571200000,
        value: 70.5909997811
    },
    {
        timestamp: 1668572100000,
        value: 70.3954699125
    },
    {
        timestamp: 1668573000000,
        value: 70.5001471495
    },
    {
        timestamp: 1668573900000,
        value: 70.0394980025
    },
    {
        timestamp: 1668574800000,
        value: 70.7811716693
    },
    {
        timestamp: 1668575700000,
        value: 71.0515142459
    },
    {
        timestamp: 1668576600000,
        value: 70.9606825345
    },
    {
        timestamp: 1668577500000,
        value: 71.1604812091
    },
    {
        timestamp: 1668578400000,
        value: 72.7180796724
    },
    {
        timestamp: 1668579300000,
        value: 73.2652557374
    },
    {
        timestamp: 1668580200000,
        value: 73.7406332815
    },
    {
        timestamp: 1668581100000,
        value: 74.3942909691
    },
    {
        timestamp: 1668582000000,
        value: 77.2717323179
    },
    {
        timestamp: 1668582900000,
        value: 77.3368461435
    },
    {
        timestamp: 1668583800000,
        value: 77.5440381254
    },
    {
        timestamp: 1668584700000,
        value: 77.5893370058
    },
    {
        timestamp: 1668585600000,
        value: 78.7004031955
    },
    {
        timestamp: 1668586500000,
        value: 79.0350053231
    },
    {
        timestamp: 1668587400000,
        value: 79.0055090688
    },
    {
        timestamp: 1668588300000,
        value: 78.4905362622
    },
    {
        timestamp: 1668589200000,
        value: 78.3624953274
    },
    {
        timestamp: 1668590100000,
        value: 78.7909035009
    },
    {
        timestamp: 1668591000000,
        value: 79.0148934276
    },
    {
        timestamp: 1668591900000,
        value: 79.1440356745
    },
    {
        timestamp: 1668592800000,
        value: 79.418408555
    },
    {
        timestamp: 1668593700000,
        value: 79.6703248412
    },
    {
        timestamp: 1668594600000,
        value: 79.323939998
    },
    {
        timestamp: 1668595500000,
        value: 78.7718741253
    },
    {
        timestamp: 1668596400000,
        value: 78.5812424833
    },
    {
        timestamp: 1668597300000,
        value: 79.3373209286
    },
    {
        timestamp: 1668598200000,
        value: 79.3665522996
    },
    {
        timestamp: 1668599100000,
        value: 79.6898515117
    },
    {
        timestamp: 1668600000000,
        value: 79.8154341065
    },
    {
        timestamp: 1668600900000,
        value: 79.9927617288
    },
    {
        timestamp: 1668601800000,
        value: 79.6699173404
    },
    {
        timestamp: 1668602700000,
        value: 79.7910666754
    },
    {
        timestamp: 1668603600000,
        value: 78.9751845759
    },
    {
        timestamp: 1668604500000,
        value: 78.7939100552
    },
    {
        timestamp: 1668605400000,
        value: 78.5478890388
    },
    {
        timestamp: 1668606300000,
        value: 78.2602434816
    },
    {
        timestamp: 1668607200000,
        value: 78.4231930279
    },
    {
        timestamp: 1668608100000,
        value: 78.0256657281
    },
    {
        timestamp: 1668609000000,
        value: 77.5232576553
    },
    {
        timestamp: 1668609900000,
        value: 77.3315513922
    },
    {
        timestamp: 1668610800000,
        value: 78.8335312441
    },
    {
        timestamp: 1668611700000,
        value: 78.7815247815
    },
    {
        timestamp: 1668612600000,
        value: 78.6289005111
    },
    {
        timestamp: 1668613500000,
        value: 78.5299725559
    },
    {
        timestamp: 1668614400000,
        value: 78.8768438451
    },
    {
        timestamp: 1668615300000,
        value: 78.9143627462
    },
    {
        timestamp: 1668616200000,
        value: 79.0283005021
    },
    {
        timestamp: 1668617100000,
        value: 78.9212686338
    },
    {
        timestamp: 1668618000000,
        value: 78.7596959707
    },
    {
        timestamp: 1668618900000,
        value: 79.0847650586
    },
    {
        timestamp: 1668619800000,
        value: 78.2884787901
    },
    {
        timestamp: 1668620700000,
        value: 78.1950578484
    },
    {
        timestamp: 1668621600000,
        value: 78.0936430558
    },
    {
        timestamp: 1668622500000,
        value: 78.0768359109
    },
    {
        timestamp: 1668623400000,
        value: 77.5318264005
    },
    {
        timestamp: 1668624300000,
        value: 77.6370546698
    },
    {
        timestamp: 1668625200000,
        value: 77.7247930188
    },
    {
        timestamp: 1668626100000,
        value: 77.9260740477
    },
    {
        timestamp: 1668627000000,
        value: 77.3490194162
    },
    {
        timestamp: 1668627900000,
        value: 77.8255149847
    },
    {
        timestamp: 1668628800000,
        value: 77.6580231602
    },
    {
        timestamp: 1668629700000,
        value: 77.5121095927
    },
    {
        timestamp: 1668630600000,
        value: 77.2045523416
    },
    {
        timestamp: 1668631500000,
        value: 77.1641671076
    },
    {
        timestamp: 1668632400000,
        value: 76.5263344909
    },
    {
        timestamp: 1668633300000,
        value: 76.6642608928
    },
    {
        timestamp: 1668634200000,
        value: 76.1156723107
    },
    {
        timestamp: 1668635100000,
        value: 75.7351297536
    },
    {
        timestamp: 1668636000000,
        value: 74.5960018366
    },
    {
        timestamp: 1668636900000,
        value: 73.1747069929
    },
    {
        timestamp: 1668637800000,
        value: 72.4925656859
    },
    {
        timestamp: 1668638700000,
        value: 71.9892666862
    },
    {
        timestamp: 1668639600000,
        value: 71.7798111245
    },
    {
        timestamp: 1668640500000,
        value: 71.7217471422
    },
    {
        timestamp: 1668641400000,
        value: 71.5574975261
    },
    {
        timestamp: 1668642300000,
        value: 71.4667709285
    },
    {
        timestamp: 1668643200000,
        value: 70.3425257363
    },
    {
        timestamp: 1668644100000,
        value: 70.4755921662
    },
    {
        timestamp: 1668645000000,
        value: 70.2425216811
    },
    {
        timestamp: 1668645900000,
        value: 70.5074614788
    },
    {
        timestamp: 1668646800000,
        value: 70.396329485
    },
    {
        timestamp: 1668647700000,
        value: 70.2062303393
    },
    {
        timestamp: 1668648600000,
        value: 70.2149417695
    },
    {
        timestamp: 1668649500000,
        value: 70.3650029321
    },
    {
        timestamp: 1668650400000,
        value: 70.1577210757
    },
    {
        timestamp: 1668651300000,
        value: 70.0481305223
    },
    {
        timestamp: 1668652200000,
        value: 70.130513971
    },
    {
        timestamp: 1668653100000,
        value: 70.1620710501
    },
    {
        timestamp: 1668654000000,
        value: 70.2935635623
    },
    {
        timestamp: 1668654900000,
        value: 70.2383219626
    },
    {
        timestamp: 1668655800000,
        value: 70.4560538911
    },
    {
        timestamp: 1668656700000,
        value: 70.2943011602
    },
    {
        timestamp: 1668657600000,
        value: 70.5713389508
    },
    {
        timestamp: 1668658500000,
        value: 70.4449525953
    },
    {
        timestamp: 1668659400000,
        value: 70.1296387138
    },
    {
        timestamp: 1668660300000,
        value: 70.4878445602
    },
    {
        timestamp: 1668661200000,
        value: 70.6513494636
    },
    {
        timestamp: 1668662100000,
        value: 70.9423331245
    },
    {
        timestamp: 1668663000000,
        value: 71.2218221787
    },
    {
        timestamp: 1668663900000,
        value: 70.9638203392
    },
    {
        timestamp: 1668664800000,
        value: 72.3212830459
    },
    {
        timestamp: 1668665700000,
        value: 72.5671424028
    },
    {
        timestamp: 1668666600000,
        value: 73.0514546772
    },
    {
        timestamp: 1668667500000,
        value: 73.9816666229
    },
    {
        timestamp: 1668668400000,
        value: 77.3247321851
    },
    {
        timestamp: 1668669300000,
        value: 77.8705664157
    },
    {
        timestamp: 1668670200000,
        value: 77.8039016639
    },
    {
        timestamp: 1668671100000,
        value: 77.9954557539
    },
    {
        timestamp: 1668672000000,
        value: 78.6981624074
    },
    {
        timestamp: 1668672900000,
        value: 79.3715391752
    },
    {
        timestamp: 1668673800000,
        value: 79.7978792802
    },
    {
        timestamp: 1668674700000,
        value: 79.9700790154
    },
    {
        timestamp: 1668675600000,
        value: 80.1110021109
    },
    {
        timestamp: 1668676500000,
        value: 79.9644169423
    },
    {
        timestamp: 1668677400000,
        value: 80.2585465752
    },
    {
        timestamp: 1668678300000,
        value: 80.0592692933
    },
    {
        timestamp: 1668679200000,
        value: 80.3741654927
    },
    {
        timestamp: 1668680100000,
        value: 80.4428864345
    },
    {
        timestamp: 1668681000000,
        value: 80.9095181793
    },
    {
        timestamp: 1668681900000,
        value: 81.0337862803
    },
    {
        timestamp: 1668682800000,
        value: 81.1695181501
    },
    {
        timestamp: 1668683700000,
        value: 80.8010040158
    },
    {
        timestamp: 1668684600000,
        value: 81.0387107393
    },
    {
        timestamp: 1668685500000,
        value: 80.66384223
    },
    {
        timestamp: 1668686400000,
        value: 81.0095514349
    },
    {
        timestamp: 1668687300000,
        value: 81.1810294918
    },
    {
        timestamp: 1668688200000,
        value: 80.811113021
    },
    {
        timestamp: 1668689100000,
        value: 80.3763730532
    },
    {
        timestamp: 1668690000000,
        value: 80.2953846909
    },
    {
        timestamp: 1668690900000,
        value: 80.5722973758
    },
    {
        timestamp: 1668691800000,
        value: 80.5623855741
    },
    {
        timestamp: 1668692700000,
        value: 80.1037522892
    },
    {
        timestamp: 1668693600000,
        value: 79.5954433198
    },
    {
        timestamp: 1668694500000,
        value: 79.9641584649
    },
    {
        timestamp: 1668695400000,
        value: 79.7506542789
    },
    {
        timestamp: 1668696300000,
        value: 79.2703314722
    },
    {
        timestamp: 1668697200000,
        value: 79.8085201929
    },
    {
        timestamp: 1668698100000,
        value: 79.650711863
    },
    {
        timestamp: 1668699000000,
        value: 80.2163044301
    },
    {
        timestamp: 1668699900000,
        value: 79.6505662052
    },
    {
        timestamp: 1668700800000,
        value: 79.7365180669
    },
    {
        timestamp: 1668701700000,
        value: 80.3669487946
    },
    {
        timestamp: 1668702600000,
        value: 80.4783788014
    },
    {
        timestamp: 1668703500000,
        value: 80.7573812115
    },
    {
        timestamp: 1668704400000,
        value: 79.8477148235
    },
    {
        timestamp: 1668705300000,
        value: 79.2251586247
    },
    {
        timestamp: 1668706200000,
        value: 79.0548379178
    },
    {
        timestamp: 1668707100000,
        value: 78.7995744724
    },
    {
        timestamp: 1668708000000,
        value: 79.0138638536
    },
    {
        timestamp: 1668708900000,
        value: 78.8965748967
    },
    {
        timestamp: 1668709800000,
        value: 78.6210093379
    },
    {
        timestamp: 1668710700000,
        value: 78.3889018799
    },
    {
        timestamp: 1668711600000,
        value: 79.0323244765
    },
    {
        timestamp: 1668712500000,
        value: 79.1203988169
    },
    {
        timestamp: 1668713400000,
        value: 79.0015546056
    },
    {
        timestamp: 1668714300000,
        value: 78.6936625885
    },
    {
        timestamp: 1668715200000,
        value: 78.4212312771
    },
    {
        timestamp: 1668716100000,
        value: 78.8098309045
    },
    {
        timestamp: 1668717000000,
        value: 78.271122474
    },
    {
        timestamp: 1668717900000,
        value: 78.0212847594
    },
    {
        timestamp: 1668718800000,
        value: 76.5230669516
    },
    {
        timestamp: 1668719700000,
        value: 76.0941899333
    },
    {
        timestamp: 1668720600000,
        value: 75.2396879571
    },
    {
        timestamp: 1668721500000,
        value: 75.0528038052
    },
    {
        timestamp: 1668722400000,
        value: 73.7977094473
    },
    {
        timestamp: 1668723300000,
        value: 73.7824555502
    },
    {
        timestamp: 1668724200000,
        value: 73.3621564236
    },
    {
        timestamp: 1668725100000,
        value: 72.4600367842
    },
    {
        timestamp: 1668726000000,
        value: 71.8547110749
    },
    {
        timestamp: 1668726900000,
        value: 71.742877133
    },
    {
        timestamp: 1668727800000,
        value: 71.8501076391
    },
    {
        timestamp: 1668728700000,
        value: 72.0976725028
    },
    {
        timestamp: 1668729600000,
        value: 70.7904983499
    },
    {
        timestamp: 1668730500000,
        value: 70.8777075246
    },
    {
        timestamp: 1668731400000,
        value: 71.1227406885
    },
    {
        timestamp: 1668732300000,
        value: 71.0135856746
    },
    {
        timestamp: 1668733200000,
        value: 70.5958315721
    },
    {
        timestamp: 1668734100000,
        value: 70.786067404
    },
    {
        timestamp: 1668735000000,
        value: 71.1303427793
    },
    {
        timestamp: 1668735900000,
        value: 70.9967009361
    },
    {
        timestamp: 1668736800000,
        value: 70.61344058
    },
    {
        timestamp: 1668737700000,
        value: 70.6355662465
    },
    {
        timestamp: 1668738600000,
        value: 71.0375012488
    },
    {
        timestamp: 1668739500000,
        value: 70.6215450727
    },
    {
        timestamp: 1668740400000,
        value: 70.7067648174
    },
    {
        timestamp: 1668741300000,
        value: 70.5626200709
    },
    {
        timestamp: 1668742200000,
        value: 70.9598715253
    },
    {
        timestamp: 1668743100000,
        value: 70.5986639288
    },
    {
        timestamp: 1668744000000,
        value: 71.5639214524
    },
    {
        timestamp: 1668744900000,
        value: 71.1582610114
    },
    {
        timestamp: 1668745800000,
        value: 71.2811673024
    },
    {
        timestamp: 1668746700000,
        value: 71.5021740889
    },
    {
        timestamp: 1668747600000,
        value: 71.8845561175
    },
    {
        timestamp: 1668748500000,
        value: 71.7676662894
    },
    {
        timestamp: 1668749400000,
        value: 72.0056122902
    },
    {
        timestamp: 1668750300000,
        value: 71.7360607365
    },
    {
        timestamp: 1668751200000,
        value: 73.6226402309
    },
    {
        timestamp: 1668752100000,
        value: 74.4877799261
    },
    {
        timestamp: 1668753000000,
        value: 75.0148295392
    },
    {
        timestamp: 1668753900000,
        value: 75.7537600145
    },
    {
        timestamp: 1668754800000,
        value: 77.5351549748
    },
    {
        timestamp: 1668755700000,
        value: 78.0854681875
    },
    {
        timestamp: 1668756600000,
        value: 78.2093426141
    },
    {
        timestamp: 1668757500000,
        value: 78.2494550244
    },
    {
        timestamp: 1668758400000,
        value: 78.6070230993
    },
    {
        timestamp: 1668759300000,
        value: 78.6067704421
    },
    {
        timestamp: 1668760200000,
        value: 78.5667559051
    },
    {
        timestamp: 1668761100000,
        value: 78.5715513563
    },
    {
        timestamp: 1668762000000,
        value: 78.7819407419
    },
    {
        timestamp: 1668762900000,
        value: 78.7836101687
    },
    {
        timestamp: 1668763800000,
        value: 78.8197511194
    },
    {
        timestamp: 1668764700000,
        value: 78.9018876702
    },
    {
        timestamp: 1668765600000,
        value: 78.1562148107
    },
    {
        timestamp: 1668766500000,
        value: 78.2836750652
    },
    {
        timestamp: 1668767400000,
        value: 78.2593263981
    },
    {
        timestamp: 1668768300000,
        value: 78.3921998528
    },
    {
        timestamp: 1668769200000,
        value: 77.9767099026
    },
    {
        timestamp: 1668770100000,
        value: 77.5786710993
    },
    {
        timestamp: 1668771000000,
        value: 77.3943692562
    },
    {
        timestamp: 1668771900000,
        value: 77.8988920352
    },
    {
        timestamp: 1668772800000,
        value: 78.1039260354
    },
    {
        timestamp: 1668773700000,
        value: 78.0278047675
    },
    {
        timestamp: 1668774600000,
        value: 77.6454669906
    },
    {
        timestamp: 1668775500000,
        value: 77.9637353646
    },
    {
        timestamp: 1668776400000,
        value: 78.6412667887
    },
    {
        timestamp: 1668777300000,
        value: 78.2161885246
    },
    {
        timestamp: 1668778200000,
        value: 78.0514012487
    },
    {
        timestamp: 1668779100000,
        value: 78.0358524381
    },
    {
        timestamp: 1668780000000,
        value: 77.6525136477
    },
    {
        timestamp: 1668780900000,
        value: 77.2918746078
    },
    {
        timestamp: 1668781800000,
        value: 77.0399814901
    },
    {
        timestamp: 1668782700000,
        value: 76.8328090494
    },
    {
        timestamp: 1668783600000,
        value: 77.1118484799
    },
    {
        timestamp: 1668784500000,
        value: 77.0424933199
    },
    {
        timestamp: 1668785400000,
        value: 77.2674098077
    },
    {
        timestamp: 1668786300000,
        value: 77.2327829235
    },
    {
        timestamp: 1668787200000,
        value: 77.2699269118
    },
    {
        timestamp: 1668788100000,
        value: 77.1997459601
    },
    {
        timestamp: 1668789000000,
        value: 77.2092070287
    },
    {
        timestamp: 1668789900000,
        value: 77.5735181432
    },
    {
        timestamp: 1668790800000,
        value: 77.226230124
    },
    {
        timestamp: 1668791700000,
        value: 77.076020425
    },
    {
        timestamp: 1668792600000,
        value: 77.2368827961
    },
    {
        timestamp: 1668793500000,
        value: 76.7295061084
    },
    {
        timestamp: 1668794400000,
        value: 77.0128387639
    },
    {
        timestamp: 1668795300000,
        value: 76.889661739
    },
    {
        timestamp: 1668796200000,
        value: 76.7799400013
    },
    {
        timestamp: 1668797100000,
        value: 76.8221833182
    },
    {
        timestamp: 1668798000000,
        value: 76.6175621878
    },
    {
        timestamp: 1668798900000,
        value: 76.593895796
    },
    {
        timestamp: 1668799800000,
        value: 76.6557311861
    },
    {
        timestamp: 1668800700000,
        value: 76.1759200443
    },
    {
        timestamp: 1668801600000,
        value: 75.234038786
    },
    {
        timestamp: 1668802500000,
        value: 75.4461690932
    },
    {
        timestamp: 1668803400000,
        value: 75.0771671512
    },
    {
        timestamp: 1668804300000,
        value: 75.2896619582
    },
    {
        timestamp: 1668805200000,
        value: 73.7852987826
    },
    {
        timestamp: 1668806100000,
        value: 73.471446579
    },
    {
        timestamp: 1668807000000,
        value: 73.4270095178
    },
    {
        timestamp: 1668807900000,
        value: 73.0478034088
    },
    {
        timestamp: 1668808800000,
        value: 70.2202897977
    },
    {
        timestamp: 1668809700000,
        value: 69.6124232516
    },
    {
        timestamp: 1668810600000,
        value: 69.1213980078
    },
    {
        timestamp: 1668811500000,
        value: 68.724427795
    },
    {
        timestamp: 1668812400000,
        value: 68.6361813535
    },
    {
        timestamp: 1668813300000,
        value: 68.5640239866
    },
    {
        timestamp: 1668814200000,
        value: 68.3479002944
    },
    {
        timestamp: 1668815100000,
        value: 68.2477633029
    },
    {
        timestamp: 1668816000000,
        value: 67.9476123856
    },
    {
        timestamp: 1668816900000,
        value: 67.8266172807
    },
    {
        timestamp: 1668817800000,
        value: 67.8618700832
    },
    {
        timestamp: 1668818700000,
        value: 67.8352742468
    },
    {
        timestamp: 1668819600000,
        value: 66.9258388657
    },
    {
        timestamp: 1668820500000,
        value: 66.8915290257
    },
    {
        timestamp: 1668821400000,
        value: 66.5551023427
    },
    {
        timestamp: 1668822300000,
        value: 66.705895959
    },
    {
        timestamp: 1668823200000,
        value: 66.2452664968
    },
    {
        timestamp: 1668824100000,
        value: 66.2290953522
    },
    {
        timestamp: 1668825000000,
        value: 66.3146264835
    },
    {
        timestamp: 1668825900000,
        value: 66.3538185959
    },
    {
        timestamp: 1668826800000,
        value: 66.2024773261
    },
    {
        timestamp: 1668827700000,
        value: 66.4677183745
    },
    {
        timestamp: 1668828600000,
        value: 66.3470546021
    },
    {
        timestamp: 1668829500000,
        value: 66.3714201764
    },
    {
        timestamp: 1668830400000,
        value: 66.0363979113
    },
    {
        timestamp: 1668831300000,
        value: 66.1308181625
    },
    {
        timestamp: 1668832200000,
        value: 65.8639256202
    },
    {
        timestamp: 1668833100000,
        value: 65.9408739309
    },
    {
        timestamp: 1668834000000,
        value: 64.9024409949
    },
    {
        timestamp: 1668834900000,
        value: 64.812517928
    },
    {
        timestamp: 1668835800000,
        value: 64.8508648331
    },
    {
        timestamp: 1668836700000,
        value: 64.508519804
    },
    {
        timestamp: 1668837600000,
        value: 63.6016743517
    },
    {
        timestamp: 1668838500000,
        value: 63.5974438587
    },
    {
        timestamp: 1668839400000,
        value: 63.5669602499
    },
    {
        timestamp: 1668840300000,
        value: 63.3913739178
    },
    {
        timestamp: 1668841200000,
        value: 63.7667049314
    },
    {
        timestamp: 1668842100000,
        value: 63.7498982413
    },
    {
        timestamp: 1668843000000,
        value: 63.8358006398
    },
    {
        timestamp: 1668843900000,
        value: 63.7051125088
    },
    {
        timestamp: 1668844800000,
        value: 63.6076288405
    },
    {
        timestamp: 1668845700000,
        value: 63.8737475292
    },
    {
        timestamp: 1668846600000,
        value: 63.7902161415
    },
    {
        timestamp: 1668847500000,
        value: 63.7220978372
    },
    {
        timestamp: 1668848400000,
        value: 64.226179102
    },
    {
        timestamp: 1668849300000,
        value: 64.451146257
    },
    {
        timestamp: 1668850200000,
        value: 64.2861369664
    },
    {
        timestamp: 1668851100000,
        value: 64.4054506856
    },
    {
        timestamp: 1668852000000,
        value: 63.6258467047
    },
    {
        timestamp: 1668852900000,
        value: 63.7548715845
    },
    {
        timestamp: 1668853800000,
        value: 63.5807731049
    },
    {
        timestamp: 1668854700000,
        value: 63.6790624073
    },
    {
        timestamp: 1668855600000,
        value: 63.7692626012
    },
    {
        timestamp: 1668856500000,
        value: 64.0326384051
    },
    {
        timestamp: 1668857400000,
        value: 63.821194144
    },
    {
        timestamp: 1668858300000,
        value: 63.4826613492
    },
    {
        timestamp: 1668859200000,
        value: 63.0785180124
    },
    {
        timestamp: 1668860100000,
        value: 63.1332733214
    },
    {
        timestamp: 1668861000000,
        value: 63.0843268839
    },
    {
        timestamp: 1668861900000,
        value: 63.6243539044
    },
    {
        timestamp: 1668862800000,
        value: 62.946605482
    },
    {
        timestamp: 1668863700000,
        value: 63.2375606616
    },
    {
        timestamp: 1668864600000,
        value: 63.4208741206
    },
    {
        timestamp: 1668865500000,
        value: 62.922741282
    },
    {
        timestamp: 1668866400000,
        value: 61.6396360987
    },
    {
        timestamp: 1668867300000,
        value: 61.6930530956
    },
    {
        timestamp: 1668868200000,
        value: 61.7328752802
    },
    {
        timestamp: 1668869100000,
        value: 60.9229767738
    },
    {
        timestamp: 1668870000000,
        value: 61.5774144115
    },
    {
        timestamp: 1668870900000,
        value: 61.039941346
    },
    {
        timestamp: 1668871800000,
        value: 60.8678769875
    },
    {
        timestamp: 1668872700000,
        value: 61.0360958472
    },
    {
        timestamp: 1668873600000,
        value: 61.0762611159
    },
    {
        timestamp: 1668874500000,
        value: 60.6502529419
    },
    {
        timestamp: 1668875400000,
        value: 60.9059698921
    },
    {
        timestamp: 1668876300000,
        value: 60.8238327208
    },
    {
        timestamp: 1668877200000,
        value: 60.8448678539
    },
    {
        timestamp: 1668878100000,
        value: 60.3419665469
    },
    {
        timestamp: 1668879000000,
        value: 60.3613068937
    },
    {
        timestamp: 1668879900000,
        value: 60.4177089053
    },
    {
        timestamp: 1668880800000,
        value: 60.1714732993
    },
    {
        timestamp: 1668881700000,
        value: 60.4528830653
    },
    {
        timestamp: 1668882600000,
        value: 60.4361886432
    },
    {
        timestamp: 1668883500000,
        value: 60.4551345638
    },
    {
        timestamp: 1668884400000,
        value: 60.4449490462
    },
    {
        timestamp: 1668885300000,
        value: 60.7932472675
    },
    {
        timestamp: 1668886200000,
        value: 60.7486557392
    },
    {
        timestamp: 1668887100000,
        value: 60.5885823958
    },
    {
        timestamp: 1668888000000,
        value: 60.1501473514
    },
    {
        timestamp: 1668888900000,
        value: 60.0002171318
    },
    {
        timestamp: 1668889800000,
        value: 60.0070610132
    },
    {
        timestamp: 1668890700000,
        value: 59.9554082329
    },
    {
        timestamp: 1668891600000,
        value: 59.050552454
    },
    {
        timestamp: 1668892500000,
        value: 58.7599156613
    },
    {
        timestamp: 1668893400000,
        value: 58.5172887663
    },
    {
        timestamp: 1668894300000,
        value: 58.5723521559
    },
    {
        timestamp: 1668895200000,
        value: 57.6417798818
    },
    {
        timestamp: 1668896100000,
        value: 57.6098727066
    },
    {
        timestamp: 1668897000000,
        value: 57.8077019129
    },
    {
        timestamp: 1668897900000,
        value: 57.4690167769
    },
    {
        timestamp: 1668898800000,
        value: 56.761342886
    },
    {
        timestamp: 1668899700000,
        value: 56.8501885045
    },
    {
        timestamp: 1668900600000,
        value: 56.9407598302
    },
    {
        timestamp: 1668901500000,
        value: 56.6812267786
    },
    {
        timestamp: 1668902400000,
        value: 54.2502193738
    },
    {
        timestamp: 1668903300000,
        value: 54.3613589803
    },
    {
        timestamp: 1668904200000,
        value: 54.378462322
    },
    {
        timestamp: 1668905100000,
        value: 54.5835651138
    },
    {
        timestamp: 1668906000000,
        value: 55.1873684133
    },
    {
        timestamp: 1668906900000,
        value: 55.0315096434
    },
    {
        timestamp: 1668907800000,
        value: 55.1562268668
    },
    {
        timestamp: 1668908700000,
        value: 56.709250626
    },
    {
        timestamp: 1668909600000,
        value: 64.3230544551
    },
    {
        timestamp: 1668910500000,
        value: 65.6535582403
    },
    {
        timestamp: 1668911400000,
        value: 63.7568659261
    },
    {
        timestamp: 1668912300000,
        value: 68.5046920794
    },
    {
        timestamp: 1668913200000,
        value: 65.4070776252
    },
    {
        timestamp: 1668914100000,
        value: 65.9164827363
    },
    {
        timestamp: 1668915000000,
        value: 65.095557855
    },
    {
        timestamp: 1668915900000,
        value: 65.9988881974
    },
    {
        timestamp: 1668916800000,
        value: 57.299760185
    },
    {
        timestamp: 1668917700000,
        value: 56.7776426137
    },
    {
        timestamp: 1668918600000,
        value: 56.02164082
    },
    {
        timestamp: 1668919500000,
        value: 54.8325122889
    },
    {
        timestamp: 1668920400000,
        value: 54.0127673189
    },
    {
        timestamp: 1668921300000,
        value: 54.0938923314
    },
    {
        timestamp: 1668922200000,
        value: 54.0326449927
    },
    {
        timestamp: 1668923100000,
        value: 53.9454174703
    },
    {
        timestamp: 1668924000000,
        value: 53.5706021781
    },
    {
        timestamp: 1668924900000,
        value: 53.6375978457
    },
    {
        timestamp: 1668925800000,
        value: 53.645372382
    },
    {
        timestamp: 1668926700000,
        value: 53.5670667624
    },
    {
        timestamp: 1668927600000,
        value: 54.1373767368
    },
    {
        timestamp: 1668928500000,
        value: 54.5066310977
    },
    {
        timestamp: 1668929400000,
        value: 54.6842829503
    },
    {
        timestamp: 1668930300000,
        value: 54.6795317652
    },
    {
        timestamp: 1668931200000,
        value: 55.4938184326
    },
    {
        timestamp: 1668932100000,
        value: 55.4948227516
    },
    {
        timestamp: 1668933000000,
        value: 55.7220080925
    },
    {
        timestamp: 1668933900000,
        value: 55.8542382538
    },
    {
        timestamp: 1668934800000,
        value: 56.216288729
    },
    {
        timestamp: 1668935700000,
        value: 56.3391483172
    },
    {
        timestamp: 1668936600000,
        value: 56.1250758839
    },
    {
        timestamp: 1668937500000,
        value: 56.1235652813
    },
    {
        timestamp: 1668938400000,
        value: 56.4481430451
    },
    {
        timestamp: 1668939300000,
        value: 56.4123931735
    },
    {
        timestamp: 1668940200000,
        value: 56.3090546839
    },
    {
        timestamp: 1668941100000,
        value: 56.3558878066
    },
    {
        timestamp: 1668942000000,
        value: 56.389540246
    },
    {
        timestamp: 1668942900000,
        value: 56.2339504176
    },
    {
        timestamp: 1668943800000,
        value: 56.2693404913
    },
    {
        timestamp: 1668944700000,
        value: 56.2515026151
    },
    {
        timestamp: 1668945600000,
        value: 55.9476446627
    },
    {
        timestamp: 1668946500000,
        value: 55.9246670741
    },
    {
        timestamp: 1668947400000,
        value: 55.8877683398
    },
    {
        timestamp: 1668948300000,
        value: 55.9407644128
    },
    {
        timestamp: 1668949200000,
        value: 56.073186976
    },
    {
        timestamp: 1668950100000,
        value: 55.8842923785
    },
    {
        timestamp: 1668951000000,
        value: 55.8521443372
    },
    {
        timestamp: 1668951900000,
        value: 55.9152615311
    },
    {
        timestamp: 1668952800000,
        value: 55.9919772217
    },
    {
        timestamp: 1668953700000,
        value: 55.606461988
    },
    {
        timestamp: 1668954600000,
        value: 55.6904486138
    },
    {
        timestamp: 1668955500000,
        value: 55.7084732218
    },
    {
        timestamp: 1668956400000,
        value: 55.7541726971
    },
    {
        timestamp: 1668957300000,
        value: 55.8842802856
    },
    {
        timestamp: 1668958200000,
        value: 55.719338433
    },
    {
        timestamp: 1668959100000,
        value: 55.835791168
    },
    {
        timestamp: 1668960000000,
        value: 55.3838281323
    },
    {
        timestamp: 1668960900000,
        value: 55.6776150582
    },
    {
        timestamp: 1668961800000,
        value: 55.475632456
    },
    {
        timestamp: 1668962700000,
        value: 55.472715655
    },
    {
        timestamp: 1668963600000,
        value: 55.7745173303
    },
    {
        timestamp: 1668964500000,
        value: 55.7346785758
    },
    {
        timestamp: 1668965400000,
        value: 55.6367121344
    },
    {
        timestamp: 1668966300000,
        value: 55.3238585654
    },
    {
        timestamp: 1668967200000,
        value: 55.222628321
    },
    {
        timestamp: 1668968100000,
        value: 55.3262399908
    },
    {
        timestamp: 1668969000000,
        value: 55.1962966661
    },
    {
        timestamp: 1668969900000,
        value: 55.1809076729
    },
    {
        timestamp: 1668970800000,
        value: 55.553201467
    },
    {
        timestamp: 1668971700000,
        value: 55.6372347425
    },
    {
        timestamp: 1668972600000,
        value: 55.4594214281
    },
    {
        timestamp: 1668973500000,
        value: 55.3615312646
    },
    {
        timestamp: 1668974400000,
        value: 55.5118625496
    },
    {
        timestamp: 1668975300000,
        value: 55.5331576636
    },
    {
        timestamp: 1668976200000,
        value: 55.5517493856
    },
    {
        timestamp: 1668977100000,
        value: 55.4712319743
    },
    {
        timestamp: 1668978000000,
        value: 55.1711705746
    },
    {
        timestamp: 1668978900000,
        value: 55.1320559736
    },
    {
        timestamp: 1668979800000,
        value: 55.1992445544
    },
    {
        timestamp: 1668980700000,
        value: 55.1118044379
    },
    {
        timestamp: 1668981600000,
        value: 55.5701309983
    },
    {
        timestamp: 1668982500000,
        value: 55.6324758167
    },
    {
        timestamp: 1668983400000,
        value: 55.6356374702
    },
    {
        timestamp: 1668984300000,
        value: 55.494581106
    },
    {
        timestamp: 1668985200000,
        value: 55.5213748437
    },
    {
        timestamp: 1668986100000,
        value: 55.6953670801
    },
    {
        timestamp: 1668987000000,
        value: 55.8790414431
    },
    {
        timestamp: 1668987900000,
        value: 55.913710882
    },
    {
        timestamp: 1668988800000,
        value: 54.4243185016
    },
    {
        timestamp: 1668989700000,
        value: 54.9725088861
    },
    {
        timestamp: 1668990600000,
        value: 54.7822510539
    },
    {
        timestamp: 1668991500000,
        value: 55.2077879088
    },
    {
        timestamp: 1668992400000,
        value: 56.1898898041
    },
    {
        timestamp: 1668993300000,
        value: 56.5789061385
    },
    {
        timestamp: 1668994200000,
        value: 56.3068974692
    },
    {
        timestamp: 1668995100000,
        value: 56.479087953
    },
    {
        timestamp: 1668996000000,
        value: 57.276079206
    },
    {
        timestamp: 1668996900000,
        value: 57.2500015364
    },
    {
        timestamp: 1668997800000,
        value: 57.1214434245
    },
    {
        timestamp: 1668998700000,
        value: 57.0577934918
    },
    {
        timestamp: 1668999600000,
        value: 57.8819298033
    },
    {
        timestamp: 1669000500000,
        value: 57.7610977446
    },
    {
        timestamp: 1669001400000,
        value: 57.7436660753
    },
    {
        timestamp: 1669002300000,
        value: 57.5226240872
    },
    {
        timestamp: 1669003200000,
        value: 58.5806420639
    },
    {
        timestamp: 1669004100000,
        value: 58.3367578332
    },
    {
        timestamp: 1669005000000,
        value: 59.0872145074
    },
    {
        timestamp: 1669005900000,
        value: 58.84770243
    },
    {
        timestamp: 1669006800000,
        value: 59.7277010009
    },
    {
        timestamp: 1669007700000,
        value: 60.6290616239
    },
    {
        timestamp: 1669008600000,
        value: 60.5237944727
    },
    {
        timestamp: 1669009500000,
        value: 60.5226132748
    },
    {
        timestamp: 1669010400000,
        value: 64.3206422572
    },
    {
        timestamp: 1669011300000,
        value: 65.2297587392
    },
    {
        timestamp: 1669012200000,
        value: 66.5313088221
    },
    {
        timestamp: 1669013100000,
        value: 68.3071057972
    },
    {
        timestamp: 1669014000000,
        value: 73.4325320153
    },
    {
        timestamp: 1669014900000,
        value: 74.0012729095
    },
    {
        timestamp: 1669015800000,
        value: 74.2443243808
    },
    {
        timestamp: 1669016700000,
        value: 74.8100246889
    },
    {
        timestamp: 1669017600000,
        value: 75.3197404384
    },
    {
        timestamp: 1669018500000,
        value: 75.0383328832
    },
    {
        timestamp: 1669019400000,
        value: 74.8876985415
    },
    {
        timestamp: 1669020300000,
        value: 75.0676832596
    },
    {
        timestamp: 1669021200000,
        value: 75.6382352211
    },
    {
        timestamp: 1669022100000,
        value: 75.810593473
    },
    {
        timestamp: 1669023000000,
        value: 75.8373341576
    },
    {
        timestamp: 1669023900000,
        value: 76.0095501825
    },
    {
        timestamp: 1669024800000,
        value: 75.93626887
    },
    {
        timestamp: 1669025700000,
        value: 75.7681486861
    },
    {
        timestamp: 1669026600000,
        value: 75.5854729937
    },
    {
        timestamp: 1669027500000,
        value: 75.2256729957
    },
    {
        timestamp: 1669028400000,
        value: 76.5579341152
    },
    {
        timestamp: 1669029300000,
        value: 76.6415486252
    },
    {
        timestamp: 1669030200000,
        value: 76.8408097002
    },
    {
        timestamp: 1669031100000,
        value: 77.086089516
    },
    {
        timestamp: 1669032000000,
        value: 76.953842376
    },
    {
        timestamp: 1669032900000,
        value: 76.7924635925
    },
    {
        timestamp: 1669033800000,
        value: 76.9270462454
    },
    {
        timestamp: 1669034700000,
        value: 76.663256023
    },
    {
        timestamp: 1669035600000,
        value: 77.0237082241
    },
    {
        timestamp: 1669036500000,
        value: 76.6175005384
    },
    {
        timestamp: 1669037400000,
        value: 76.873333907
    },
    {
        timestamp: 1669038300000,
        value: 76.8640386844
    },
    {
        timestamp: 1669039200000,
        value: 75.836755913
    },
    {
        timestamp: 1669040100000,
        value: 75.9076903305
    },
    {
        timestamp: 1669041000000,
        value: 75.5564418604
    },
    {
        timestamp: 1669041900000,
        value: 75.4890669753
    },
    {
        timestamp: 1669042800000,
        value: 75.4850530759
    },
    {
        timestamp: 1669043700000,
        value: 75.5220414883
    },
    {
        timestamp: 1669044600000,
        value: 75.4405336974
    },
    {
        timestamp: 1669045500000,
        value: 75.6157600184
    },
    {
        timestamp: 1669046400000,
        value: 75.916849979
    },
    {
        timestamp: 1669047300000,
        value: 75.3552839348
    },
    {
        timestamp: 1669048200000,
        value: 74.808343731
    },
    {
        timestamp: 1669049100000,
        value: 75.5668044865
    },
    {
        timestamp: 1669050000000,
        value: 76.2326614079
    },
    {
        timestamp: 1669050900000,
        value: 76.067140632
    },
    {
        timestamp: 1669051800000,
        value: 75.9221804207
    },
    {
        timestamp: 1669052700000,
        value: 76.2788151164
    },
    {
        timestamp: 1669053600000,
        value: 75.7799310372
    },
    {
        timestamp: 1669054500000,
        value: 75.8781461942
    },
    {
        timestamp: 1669055400000,
        value: 75.5046715259
    },
    {
        timestamp: 1669056300000,
        value: 75.5880974054
    },
    {
        timestamp: 1669057200000,
        value: 75.7952447216
    },
    {
        timestamp: 1669058100000,
        value: 75.8903249677
    },
    {
        timestamp: 1669059000000,
        value: 75.9134123064
    },
    {
        timestamp: 1669059900000,
        value: 76.1564101161
    },
    {
        timestamp: 1669060800000,
        value: 75.2273757302
    },
    {
        timestamp: 1669061700000,
        value: 75.6503038078
    },
    {
        timestamp: 1669062600000,
        value: 75.458334504
    },
    {
        timestamp: 1669063500000,
        value: 75.3084060031
    },
    {
        timestamp: 1669064400000,
        value: 74.2644190238
    },
    {
        timestamp: 1669065300000,
        value: 74.3186595745
    },
    {
        timestamp: 1669066200000,
        value: 73.8514139735
    },
    {
        timestamp: 1669067100000,
        value: 73.0419676023
    },
    {
        timestamp: 1669068000000,
        value: 70.9081003199
    },
    {
        timestamp: 1669068900000,
        value: 70.1860728921
    },
    {
        timestamp: 1669069800000,
        value: 69.7227656392
    },
    {
        timestamp: 1669070700000,
        value: 69.1296577786
    },
    {
        timestamp: 1669071600000,
        value: 67.7337867523
    },
    {
        timestamp: 1669072500000,
        value: 67.6829644691
    },
    {
        timestamp: 1669073400000,
        value: 67.4179876493
    },
    {
        timestamp: 1669074300000,
        value: 67.1210513487
    }
].map((i) => ({ ...i, P10: i.value, P25: i.value, P50: i.value, P75: i.value, P90: i.value })) as unknown as Forecast[];

export const mockedDemandHistorical = [
    {
        timestamp: 1668211200000,
        value: 68.803
    },
    {
        timestamp: 1668212100000,
        value: 68.978
    },
    {
        timestamp: 1668213000000,
        value: 68.899
    },
    {
        timestamp: 1668213900000,
        value: 68.475
    },
    {
        timestamp: 1668214800000,
        value: 69.178
    },
    {
        timestamp: 1668215700000,
        value: 69.306
    },
    {
        timestamp: 1668216600000,
        value: 69.504
    },
    {
        timestamp: 1668217500000,
        value: 68.762
    },
    {
        timestamp: 1668218400000,
        value: 68.433
    },
    {
        timestamp: 1668219300000,
        value: 68.159
    },
    {
        timestamp: 1668220200000,
        value: 68.123
    },
    {
        timestamp: 1668221100000,
        value: 68.158
    },
    {
        timestamp: 1668222000000,
        value: 66.41
    },
    {
        timestamp: 1668222900000,
        value: 66.162
    },
    {
        timestamp: 1668223800000,
        value: 66.62
    },
    {
        timestamp: 1668224700000,
        value: 66.75
    },
    {
        timestamp: 1668225600000,
        value: 67.241
    },
    {
        timestamp: 1668226500000,
        value: 67.312
    },
    {
        timestamp: 1668227400000,
        value: 67.74
    },
    {
        timestamp: 1668228300000,
        value: 67.379
    },
    {
        timestamp: 1668229200000,
        value: 66.61
    },
    {
        timestamp: 1668230100000,
        value: 65.999
    },
    {
        timestamp: 1668231000000,
        value: 65.42
    },
    {
        timestamp: 1668231900000,
        value: 65.357
    },
    {
        timestamp: 1668232800000,
        value: 63.747
    },
    {
        timestamp: 1668233700000,
        value: 63.769
    },
    {
        timestamp: 1668234600000,
        value: 63.519
    },
    {
        timestamp: 1668235500000,
        value: 63.31
    },
    {
        timestamp: 1668236400000,
        value: 63.629
    },
    {
        timestamp: 1668237300000,
        value: 63.711
    },
    {
        timestamp: 1668238200000,
        value: 64.048
    },
    {
        timestamp: 1668239100000,
        value: 64.497
    },
    {
        timestamp: 1668240000000,
        value: 63.115
    },
    {
        timestamp: 1668240900000,
        value: 61.933
    },
    {
        timestamp: 1668241800000,
        value: 63.103
    },
    {
        timestamp: 1668242700000,
        value: 64.053
    },
    {
        timestamp: 1668243600000,
        value: 65.79
    },
    {
        timestamp: 1668244500000,
        value: 65.304
    },
    {
        timestamp: 1668245400000,
        value: 65.102
    },
    {
        timestamp: 1668246300000,
        value: 64.837
    },
    {
        timestamp: 1668247200000,
        value: 64.188
    },
    {
        timestamp: 1668248100000,
        value: 64.317
    },
    {
        timestamp: 1668249000000,
        value: 64.967
    },
    {
        timestamp: 1668249900000,
        value: 64.712
    },
    {
        timestamp: 1668250800000,
        value: 65.18
    },
    {
        timestamp: 1668251700000,
        value: 64.906
    },
    {
        timestamp: 1668252600000,
        value: 64.717
    },
    {
        timestamp: 1668253500000,
        value: 64.548
    },
    {
        timestamp: 1668254400000,
        value: 65.354
    },
    {
        timestamp: 1668255300000,
        value: 65.9
    },
    {
        timestamp: 1668256200000,
        value: 65.888
    },
    {
        timestamp: 1668257100000,
        value: 65.457
    },
    {
        timestamp: 1668258000000,
        value: 64.916
    },
    {
        timestamp: 1668258900000,
        value: 65.025
    },
    {
        timestamp: 1668259800000,
        value: 64.922
    },
    {
        timestamp: 1668260700000,
        value: 64.319
    },
    {
        timestamp: 1668261600000,
        value: 63.26
    },
    {
        timestamp: 1668262500000,
        value: 62.862
    },
    {
        timestamp: 1668263400000,
        value: 62.796
    },
    {
        timestamp: 1668264300000,
        value: 62.281
    },
    {
        timestamp: 1668265200000,
        value: 61.288
    },
    {
        timestamp: 1668266100000,
        value: 61.239
    },
    {
        timestamp: 1668267000000,
        value: 61.068
    },
    {
        timestamp: 1668267900000,
        value: 61.617
    },
    {
        timestamp: 1668268800000,
        value: 62.224
    },
    {
        timestamp: 1668269700000,
        value: 62.447
    },
    {
        timestamp: 1668270600000,
        value: 62.542
    },
    {
        timestamp: 1668271500000,
        value: 62.916
    },
    {
        timestamp: 1668272400000,
        value: 62.81
    },
    {
        timestamp: 1668273300000,
        value: 62.72
    },
    {
        timestamp: 1668274200000,
        value: 62.213
    },
    {
        timestamp: 1668275100000,
        value: 61.941
    },
    {
        timestamp: 1668276000000,
        value: 61.851
    },
    {
        timestamp: 1668276900000,
        value: 62.409
    },
    {
        timestamp: 1668277800000,
        value: 61.589
    },
    {
        timestamp: 1668278700000,
        value: 61.796
    },
    {
        timestamp: 1668279600000,
        value: 62.823
    },
    {
        timestamp: 1668280500000,
        value: 62.62
    },
    {
        timestamp: 1668281400000,
        value: 62.528
    },
    {
        timestamp: 1668282300000,
        value: 62.663
    },
    {
        timestamp: 1668283200000,
        value: 62.315
    },
    {
        timestamp: 1668284100000,
        value: 62.356
    },
    {
        timestamp: 1668285000000,
        value: 62.75
    },
    {
        timestamp: 1668285900000,
        value: 62.792
    },
    {
        timestamp: 1668286800000,
        value: 61.052
    },
    {
        timestamp: 1668287700000,
        value: 61.309
    },
    {
        timestamp: 1668288600000,
        value: 60.914
    },
    {
        timestamp: 1668289500000,
        value: 60.526
    },
    {
        timestamp: 1668290400000,
        value: 58.899
    },
    {
        timestamp: 1668291300000,
        value: 58.471
    },
    {
        timestamp: 1668292200000,
        value: 58.159
    },
    {
        timestamp: 1668293100000,
        value: 57.623
    },
    {
        timestamp: 1668294000000,
        value: 56.295
    },
    {
        timestamp: 1668294900000,
        value: 56.239
    },
    {
        timestamp: 1668295800000,
        value: 56.247
    },
    {
        timestamp: 1668296700000,
        value: 56.121
    },
    {
        timestamp: 1668297600000,
        value: 56.023
    },
    {
        timestamp: 1668298500000,
        value: 56.101
    },
    {
        timestamp: 1668299400000,
        value: 56.104
    },
    {
        timestamp: 1668300300000,
        value: 56.538
    },
    {
        timestamp: 1668301200000,
        value: 56.335
    },
    {
        timestamp: 1668302100000,
        value: 56.535
    },
    {
        timestamp: 1668303000000,
        value: 56.272
    },
    {
        timestamp: 1668303900000,
        value: 55.956
    },
    {
        timestamp: 1668304800000,
        value: 56.022
    },
    {
        timestamp: 1668305700000,
        value: 56.195
    },
    {
        timestamp: 1668306600000,
        value: 56.263
    },
    {
        timestamp: 1668307500000,
        value: 56.101
    },
    {
        timestamp: 1668308400000,
        value: 54.618
    },
    {
        timestamp: 1668309300000,
        value: 54.378
    },
    {
        timestamp: 1668310200000,
        value: 54.76
    },
    {
        timestamp: 1668311100000,
        value: 54.626
    },
    {
        timestamp: 1668312000000,
        value: 56.334
    },
    {
        timestamp: 1668312900000,
        value: 56.179
    },
    {
        timestamp: 1668313800000,
        value: 56.627
    },
    {
        timestamp: 1668314700000,
        value: 56.507
    },
    {
        timestamp: 1668315600000,
        value: 57.006
    },
    {
        timestamp: 1668316500000,
        value: 56.62
    },
    {
        timestamp: 1668317400000,
        value: 56.505
    },
    {
        timestamp: 1668318300000,
        value: 56.611
    },
    {
        timestamp: 1668319200000,
        value: 54.866
    },
    {
        timestamp: 1668320100000,
        value: 54.776
    },
    {
        timestamp: 1668321000000,
        value: 54.774
    },
    {
        timestamp: 1668321900000,
        value: 55.176
    },
    {
        timestamp: 1668322800000,
        value: 53.851
    },
    {
        timestamp: 1668323700000,
        value: 53.975
    },
    {
        timestamp: 1668324600000,
        value: 54.321
    },
    {
        timestamp: 1668325500000,
        value: 54.257
    },
    {
        timestamp: 1668326400000,
        value: 54.147
    },
    {
        timestamp: 1668327300000,
        value: 54.256
    },
    {
        timestamp: 1668328200000,
        value: 54.259
    },
    {
        timestamp: 1668329100000,
        value: 54.967
    },
    {
        timestamp: 1668330000000,
        value: 54.799
    },
    {
        timestamp: 1668330900000,
        value: 53.975
    },
    {
        timestamp: 1668331800000,
        value: 54.306
    },
    {
        timestamp: 1668332700000,
        value: 54.722
    },
    {
        timestamp: 1668333600000,
        value: 55.737
    },
    {
        timestamp: 1668334500000,
        value: 55.938
    },
    {
        timestamp: 1668335400000,
        value: 56.114
    },
    {
        timestamp: 1668336300000,
        value: 55.287
    },
    {
        timestamp: 1668337200000,
        value: 56.235
    },
    {
        timestamp: 1668338100000,
        value: 56.326
    },
    {
        timestamp: 1668339000000,
        value: 56.644
    },
    {
        timestamp: 1668339900000,
        value: 56.546
    },
    {
        timestamp: 1668340800000,
        value: 56.016
    },
    {
        timestamp: 1668341700000,
        value: 56.029
    },
    {
        timestamp: 1668342600000,
        value: 56.331
    },
    {
        timestamp: 1668343500000,
        value: 56.637
    },
    {
        timestamp: 1668344400000,
        value: 56.708
    },
    {
        timestamp: 1668345300000,
        value: 56.748
    },
    {
        timestamp: 1668346200000,
        value: 57.394
    },
    {
        timestamp: 1668347100000,
        value: 57.082
    },
    {
        timestamp: 1668348000000,
        value: 56.619
    },
    {
        timestamp: 1668348900000,
        value: 55.619
    },
    {
        timestamp: 1668349800000,
        value: 56.002
    },
    {
        timestamp: 1668350700000,
        value: 55.743
    },
    {
        timestamp: 1668351600000,
        value: 56.291
    },
    {
        timestamp: 1668352500000,
        value: 56.136
    },
    {
        timestamp: 1668353400000,
        value: 56.56
    },
    {
        timestamp: 1668354300000,
        value: 57.195
    },
    {
        timestamp: 1668355200000,
        value: 56.903
    },
    {
        timestamp: 1668356100000,
        value: 57.194
    },
    {
        timestamp: 1668357000000,
        value: 57.239
    },
    {
        timestamp: 1668357900000,
        value: 57.362
    },
    {
        timestamp: 1668358800000,
        value: 57.383
    },
    {
        timestamp: 1668359700000,
        value: 58.1
    },
    {
        timestamp: 1668360600000,
        value: 57.08
    },
    {
        timestamp: 1668361500000,
        value: 56.458
    },
    {
        timestamp: 1668362400000,
        value: 56.709
    },
    {
        timestamp: 1668363300000,
        value: 56.819
    },
    {
        timestamp: 1668364200000,
        value: 57.234
    },
    {
        timestamp: 1668365100000,
        value: 56.369
    },
    {
        timestamp: 1668366000000,
        value: 55.856
    },
    {
        timestamp: 1668366900000,
        value: 56.239
    },
    {
        timestamp: 1668367800000,
        value: 56.581
    },
    {
        timestamp: 1668368700000,
        value: 56.925
    },
    {
        timestamp: 1668369600000,
        value: 56.41
    },
    {
        timestamp: 1668370500000,
        value: 56.883
    },
    {
        timestamp: 1668371400000,
        value: 56.876
    },
    {
        timestamp: 1668372300000,
        value: 57.378
    },
    {
        timestamp: 1668373200000,
        value: 55.905
    },
    {
        timestamp: 1668374100000,
        value: 56.012
    },
    {
        timestamp: 1668375000000,
        value: 55.466
    },
    {
        timestamp: 1668375900000,
        value: 55.124
    },
    {
        timestamp: 1668376800000,
        value: 54.099
    },
    {
        timestamp: 1668377700000,
        value: 53.709
    },
    {
        timestamp: 1668378600000,
        value: 53.939
    },
    {
        timestamp: 1668379500000,
        value: 54.283
    },
    {
        timestamp: 1668380400000,
        value: 54.682
    },
    {
        timestamp: 1668381300000,
        value: 55.4
    },
    {
        timestamp: 1668382200000,
        value: 55.043
    },
    {
        timestamp: 1668383100000,
        value: 54.871
    },
    {
        timestamp: 1668384000000,
        value: 54.744
    },
    {
        timestamp: 1668384900000,
        value: 54.705
    },
    {
        timestamp: 1668385800000,
        value: 54.981
    },
    {
        timestamp: 1668386700000,
        value: 55.295
    },
    {
        timestamp: 1668387600000,
        value: 55.502
    },
    {
        timestamp: 1668388500000,
        value: 55.029
    },
    {
        timestamp: 1668389400000,
        value: 55.596
    },
    {
        timestamp: 1668390300000,
        value: 55.398
    },
    {
        timestamp: 1668391200000,
        value: 55.81
    },
    {
        timestamp: 1668392100000,
        value: 55.435
    },
    {
        timestamp: 1668393000000,
        value: 55.654
    },
    {
        timestamp: 1668393900000,
        value: 55.75
    },
    {
        timestamp: 1668394800000,
        value: 55.902
    },
    {
        timestamp: 1668395700000,
        value: 56.115
    },
    {
        timestamp: 1668396600000,
        value: 55.936
    },
    {
        timestamp: 1668397500000,
        value: 56.066
    },
    {
        timestamp: 1668398400000,
        value: 56.749
    },
    {
        timestamp: 1668399300000,
        value: 55.91
    },
    {
        timestamp: 1668400200000,
        value: 56.024
    },
    {
        timestamp: 1668401100000,
        value: 55.671
    },
    {
        timestamp: 1668402000000,
        value: 56.689
    },
    {
        timestamp: 1668402900000,
        value: 55.896
    },
    {
        timestamp: 1668403800000,
        value: 55.848
    },
    {
        timestamp: 1668404700000,
        value: 56.606
    },
    {
        timestamp: 1668405600000,
        value: 60.959
    },
    {
        timestamp: 1668406500000,
        value: 63.107
    },
    {
        timestamp: 1668407400000,
        value: 64.61
    },
    {
        timestamp: 1668408300000,
        value: 66.109
    },
    {
        timestamp: 1668409200000,
        value: 72.99
    },
    {
        timestamp: 1668410100000,
        value: 73.739
    },
    {
        timestamp: 1668411000000,
        value: 74.803
    },
    {
        timestamp: 1668411900000,
        value: 75.976
    },
    {
        timestamp: 1668412800000,
        value: 76.595
    },
    {
        timestamp: 1668413700000,
        value: 77.592
    },
    {
        timestamp: 1668414600000,
        value: 78.515
    },
    {
        timestamp: 1668415500000,
        value: 77.798
    },
    {
        timestamp: 1668416400000,
        value: 79.295
    },
    {
        timestamp: 1668417300000,
        value: 79.673
    },
    {
        timestamp: 1668418200000,
        value: 78.791
    },
    {
        timestamp: 1668419100000,
        value: 79.108
    },
    {
        timestamp: 1668420000000,
        value: 78.268
    },
    {
        timestamp: 1668420900000,
        value: 78.88
    },
    {
        timestamp: 1668421800000,
        value: 78.48
    },
    {
        timestamp: 1668422700000,
        value: 77.996
    },
    {
        timestamp: 1668423600000,
        value: 77.279
    },
    {
        timestamp: 1668424500000,
        value: 77.529
    },
    {
        timestamp: 1668425400000,
        value: 78.68
    },
    {
        timestamp: 1668426300000,
        value: 78.678
    },
    {
        timestamp: 1668427200000,
        value: 79.203
    },
    {
        timestamp: 1668428100000,
        value: 79.162
    },
    {
        timestamp: 1668429000000,
        value: 80.667
    },
    {
        timestamp: 1668429900000,
        value: 80.668
    },
    {
        timestamp: 1668430800000,
        value: 81.644
    },
    {
        timestamp: 1668431700000,
        value: 81.647
    },
    {
        timestamp: 1668432600000,
        value: 81.37
    },
    {
        timestamp: 1668433500000,
        value: 81.121
    },
    {
        timestamp: 1668434400000,
        value: 78.909
    },
    {
        timestamp: 1668435300000,
        value: 78.556
    },
    {
        timestamp: 1668436200000,
        value: 78.624
    },
    {
        timestamp: 1668437100000,
        value: 77.736
    },
    {
        timestamp: 1668438000000,
        value: 77.131
    },
    {
        timestamp: 1668438900000,
        value: 77.031
    },
    {
        timestamp: 1668439800000,
        value: 76.603
    },
    {
        timestamp: 1668440700000,
        value: 77.489
    },
    {
        timestamp: 1668441600000,
        value: 77.642
    },
    {
        timestamp: 1668442500000,
        value: 78.061
    },
    {
        timestamp: 1668443400000,
        value: 78.365
    },
    {
        timestamp: 1668444300000,
        value: 78.313
    },
    {
        timestamp: 1668445200000,
        value: 78.836
    },
    {
        timestamp: 1668446100000,
        value: 78.747
    },
    {
        timestamp: 1668447000000,
        value: 78.735
    },
    {
        timestamp: 1668447900000,
        value: 78.871
    },
    {
        timestamp: 1668448800000,
        value: 77.136
    },
    {
        timestamp: 1668449700000,
        value: 77.512
    },
    {
        timestamp: 1668450600000,
        value: 77.19
    },
    {
        timestamp: 1668451500000,
        value: 76.188
    },
    {
        timestamp: 1668452400000,
        value: 76.097
    },
    {
        timestamp: 1668453300000,
        value: 76.18
    },
    {
        timestamp: 1668454200000,
        value: 76.898
    },
    {
        timestamp: 1668455100000,
        value: 77.068
    },
    {
        timestamp: 1668456000000,
        value: 76.892
    },
    {
        timestamp: 1668456900000,
        value: 76.535
    },
    {
        timestamp: 1668457800000,
        value: 76.317
    },
    {
        timestamp: 1668458700000,
        value: 76.858
    },
    {
        timestamp: 1668459600000,
        value: 76.012
    },
    {
        timestamp: 1668460500000,
        value: 75.743
    },
    {
        timestamp: 1668461400000,
        value: 74.876
    },
    {
        timestamp: 1668462300000,
        value: 74.47
    },
    {
        timestamp: 1668463200000,
        value: 71.735
    },
    {
        timestamp: 1668464100000,
        value: 70.633
    },
    {
        timestamp: 1668465000000,
        value: 69.812
    },
    {
        timestamp: 1668465900000,
        value: 68.505
    },
    {
        timestamp: 1668466800000,
        value: 68.274
    },
    {
        timestamp: 1668467700000,
        value: 68.714
    },
    {
        timestamp: 1668468600000,
        value: 69.233
    },
    {
        timestamp: 1668469500000,
        value: 69.042
    },
    {
        timestamp: 1668470400000,
        value: 69.061
    },
    {
        timestamp: 1668471300000,
        value: 68.925
    },
    {
        timestamp: 1668472200000,
        value: 69
    },
    {
        timestamp: 1668473100000,
        value: 69.447
    },
    {
        timestamp: 1668474000000,
        value: 69.518
    },
    {
        timestamp: 1668474900000,
        value: 69.221
    },
    {
        timestamp: 1668475800000,
        value: 69.205
    },
    {
        timestamp: 1668476700000,
        value: 68.694
    },
    {
        timestamp: 1668477600000,
        value: 67.748
    },
    {
        timestamp: 1668478500000,
        value: 67.958
    },
    {
        timestamp: 1668479400000,
        value: 67.818
    },
    {
        timestamp: 1668480300000,
        value: 67.728
    },
    {
        timestamp: 1668481200000,
        value: 67.248
    },
    {
        timestamp: 1668482100000,
        value: 67.176
    },
    {
        timestamp: 1668483000000,
        value: 67.593
    },
    {
        timestamp: 1668483900000,
        value: 67.302
    },
    {
        timestamp: 1668484800000,
        value: 68.336
    },
    {
        timestamp: 1668485700000,
        value: 68.431
    },
    {
        timestamp: 1668486600000,
        value: 68.192
    },
    {
        timestamp: 1668487500000,
        value: 67.697
    },
    {
        timestamp: 1668488400000,
        value: 68.08
    },
    {
        timestamp: 1668489300000,
        value: 67.938
    },
    {
        timestamp: 1668490200000,
        value: 67.711
    },
    {
        timestamp: 1668491100000,
        value: 67.977
    },
    {
        timestamp: 1668492000000,
        value: 71.042
    },
    {
        timestamp: 1668492900000,
        value: 72.365
    },
    {
        timestamp: 1668493800000,
        value: 72.793
    },
    {
        timestamp: 1668494700000,
        value: 73.726
    },
    {
        timestamp: 1668495600000,
        value: 77.513
    },
    {
        timestamp: 1668496500000,
        value: 79.688
    },
    {
        timestamp: 1668497400000,
        value: 80.186
    },
    {
        timestamp: 1668498300000,
        value: 80.449
    },
    {
        timestamp: 1668499200000,
        value: 81.724
    },
    {
        timestamp: 1668500100000,
        value: 82.083
    },
    {
        timestamp: 1668501000000,
        value: 81.984
    },
    {
        timestamp: 1668501900000,
        value: 82.317
    },
    {
        timestamp: 1668502800000,
        value: 82.364
    },
    {
        timestamp: 1668503700000,
        value: 82.189
    },
    {
        timestamp: 1668504600000,
        value: 82.414
    },
    {
        timestamp: 1668505500000,
        value: 81.947
    },
    {
        timestamp: 1668506400000,
        value: 81.523
    },
    {
        timestamp: 1668507300000,
        value: 81.645
    },
    {
        timestamp: 1668508200000,
        value: 81.982
    },
    {
        timestamp: 1668509100000,
        value: 81.339
    },
    {
        timestamp: 1668510000000,
        value: 81.891
    },
    {
        timestamp: 1668510900000,
        value: 81.762
    },
    {
        timestamp: 1668511800000,
        value: 81.46
    },
    {
        timestamp: 1668512700000,
        value: 81.649
    },
    {
        timestamp: 1668513600000,
        value: 81.557
    },
    {
        timestamp: 1668514500000,
        value: 81.774
    },
    {
        timestamp: 1668515400000,
        value: 82.184
    },
    {
        timestamp: 1668516300000,
        value: 81.701
    },
    {
        timestamp: 1668517200000,
        value: 81.326
    },
    {
        timestamp: 1668518100000,
        value: 81.139
    },
    {
        timestamp: 1668519000000,
        value: 81.437
    },
    {
        timestamp: 1668519900000,
        value: 80.903
    },
    {
        timestamp: 1668520800000,
        value: 79.171
    },
    {
        timestamp: 1668521700000,
        value: 78.37
    },
    {
        timestamp: 1668522600000,
        value: 78.63
    },
    {
        timestamp: 1668523500000,
        value: 78.492
    },
    {
        timestamp: 1668524400000,
        value: 79.104
    },
    {
        timestamp: 1668525300000,
        value: 78.779
    },
    {
        timestamp: 1668526200000,
        value: 77.64
    },
    {
        timestamp: 1668527100000,
        value: 78.048
    },
    {
        timestamp: 1668528000000,
        value: 79.687
    },
    {
        timestamp: 1668528900000,
        value: 80.409
    },
    {
        timestamp: 1668529800000,
        value: 80.009
    },
    {
        timestamp: 1668530700000,
        value: 80.073
    },
    {
        timestamp: 1668531600000,
        value: 80.463
    },
    {
        timestamp: 1668532500000,
        value: 80.111
    },
    {
        timestamp: 1668533400000,
        value: 79.001
    },
    {
        timestamp: 1668534300000,
        value: 78.609
    },
    {
        timestamp: 1668535200000,
        value: 76.968
    },
    {
        timestamp: 1668536100000,
        value: 77.924
    },
    {
        timestamp: 1668537000000,
        value: 77.543
    },
    {
        timestamp: 1668537900000,
        value: 77.258
    },
    {
        timestamp: 1668538800000,
        value: 77.117
    },
    {
        timestamp: 1668539700000,
        value: 77.001
    },
    {
        timestamp: 1668540600000,
        value: 77.711
    },
    {
        timestamp: 1668541500000,
        value: 78.351
    },
    {
        timestamp: 1668542400000,
        value: 77.939
    },
    {
        timestamp: 1668543300000,
        value: 77.911
    },
    {
        timestamp: 1668544200000,
        value: 78.621
    },
    {
        timestamp: 1668545100000,
        value: 78.469
    },
    {
        timestamp: 1668546000000,
        value: 76.713
    },
    {
        timestamp: 1668546900000,
        value: 76.446
    },
    {
        timestamp: 1668547800000,
        value: 76.042
    },
    {
        timestamp: 1668548700000,
        value: 75.854
    },
    {
        timestamp: 1668549600000,
        value: 72.313
    },
    {
        timestamp: 1668550500000,
        value: 71.578
    },
    {
        timestamp: 1668551400000,
        value: 70.784
    },
    {
        timestamp: 1668552300000,
        value: 69.328
    },
    {
        timestamp: 1668553200000,
        value: 69.215
    },
    {
        timestamp: 1668554100000,
        value: 70.464
    },
    {
        timestamp: 1668555000000,
        value: 70.052
    },
    {
        timestamp: 1668555900000,
        value: 70.179
    },
    {
        timestamp: 1668556800000,
        value: 70.233
    },
    {
        timestamp: 1668557700000,
        value: 70.11
    },
    {
        timestamp: 1668558600000,
        value: 69.964
    },
    {
        timestamp: 1668559500000,
        value: 70.288
    },
    {
        timestamp: 1668560400000,
        value: 70.124
    },
    {
        timestamp: 1668561300000,
        value: 70.18
    },
    {
        timestamp: 1668562200000,
        value: 70.454
    },
    {
        timestamp: 1668563100000,
        value: 69.988
    },
    {
        timestamp: 1668564000000,
        value: 68.384
    },
    {
        timestamp: 1668564900000,
        value: 68.907
    },
    {
        timestamp: 1668565800000,
        value: 68.957
    },
    {
        timestamp: 1668566700000,
        value: 68.589
    },
    {
        timestamp: 1668567600000,
        value: 68.087
    },
    {
        timestamp: 1668568500000,
        value: 67.703
    },
    {
        timestamp: 1668569400000,
        value: 68.072
    },
    {
        timestamp: 1668570300000,
        value: 67.945
    },
    {
        timestamp: 1668571200000,
        value: 69.121
    },
    {
        timestamp: 1668572100000,
        value: 69.487
    },
    {
        timestamp: 1668573000000,
        value: 69.378
    },
    {
        timestamp: 1668573900000,
        value: 69.138
    },
    {
        timestamp: 1668574800000,
        value: 68.972
    },
    {
        timestamp: 1668575700000,
        value: 68.749
    },
    {
        timestamp: 1668576600000,
        value: 68.486
    },
    {
        timestamp: 1668577500000,
        value: 68.938
    },
    {
        timestamp: 1668578400000,
        value: 70.2
    },
    {
        timestamp: 1668579300000,
        value: 70.991
    },
    {
        timestamp: 1668580200000,
        value: 71.468
    },
    {
        timestamp: 1668581100000,
        value: 71.716
    },
    {
        timestamp: 1668582000000,
        value: 76.333
    },
    {
        timestamp: 1668582900000,
        value: 77.527
    },
    {
        timestamp: 1668583800000,
        value: 78.482
    },
    {
        timestamp: 1668584700000,
        value: 78.553
    },
    {
        timestamp: 1668585600000,
        value: 78.814
    },
    {
        timestamp: 1668586500000,
        value: 79.169
    },
    {
        timestamp: 1668587400000,
        value: 79.656
    },
    {
        timestamp: 1668588300000,
        value: 79.41
    },
    {
        timestamp: 1668589200000,
        value: 79.106
    },
    {
        timestamp: 1668590100000,
        value: 78.924
    },
    {
        timestamp: 1668591000000,
        value: 78.723
    },
    {
        timestamp: 1668591900000,
        value: 78.528
    },
    {
        timestamp: 1668592800000,
        value: 77.344
    },
    {
        timestamp: 1668593700000,
        value: 77.817
    },
    {
        timestamp: 1668594600000,
        value: 77.582
    },
    {
        timestamp: 1668595500000,
        value: 76.937
    },
    {
        timestamp: 1668596400000,
        value: 77.4
    },
    {
        timestamp: 1668597300000,
        value: 78.05
    },
    {
        timestamp: 1668598200000,
        value: 77.608
    },
    {
        timestamp: 1668599100000,
        value: 78.164
    },
    {
        timestamp: 1668600000000,
        value: 79.976
    },
    {
        timestamp: 1668600900000,
        value: 80.677
    },
    {
        timestamp: 1668601800000,
        value: 81.329
    },
    {
        timestamp: 1668602700000,
        value: 81.079
    },
    {
        timestamp: 1668603600000,
        value: 81.314
    },
    {
        timestamp: 1668604500000,
        value: 81.411
    },
    {
        timestamp: 1668605400000,
        value: 81.84
    },
    {
        timestamp: 1668606300000,
        value: 81.268
    },
    {
        timestamp: 1668607200000,
        value: 79.056
    },
    {
        timestamp: 1668608100000,
        value: 78.982
    },
    {
        timestamp: 1668609000000,
        value: 78.647
    },
    {
        timestamp: 1668609900000,
        value: 78.032
    },
    {
        timestamp: 1668610800000,
        value: 77.942
    },
    {
        timestamp: 1668611700000,
        value: 77.338
    },
    {
        timestamp: 1668612600000,
        value: 76.736
    },
    {
        timestamp: 1668613500000,
        value: 77.375
    },
    {
        timestamp: 1668614400000,
        value: 78.426
    },
    {
        timestamp: 1668615300000,
        value: 79.418
    },
    {
        timestamp: 1668616200000,
        value: 79.664
    },
    {
        timestamp: 1668617100000,
        value: 79.654
    },
    {
        timestamp: 1668618000000,
        value: 79.081
    },
    {
        timestamp: 1668618900000,
        value: 79.02
    },
    {
        timestamp: 1668619800000,
        value: 78.266
    },
    {
        timestamp: 1668620700000,
        value: 78.234
    },
    {
        timestamp: 1668621600000,
        value: 77.058
    },
    {
        timestamp: 1668622500000,
        value: 76.265
    },
    {
        timestamp: 1668623400000,
        value: 76.303
    },
    {
        timestamp: 1668624300000,
        value: 76.467
    },
    {
        timestamp: 1668625200000,
        value: 75.938
    },
    {
        timestamp: 1668626100000,
        value: 76.036
    },
    {
        timestamp: 1668627000000,
        value: 76.542
    },
    {
        timestamp: 1668627900000,
        value: 76.847
    },
    {
        timestamp: 1668628800000,
        value: 77.294
    },
    {
        timestamp: 1668629700000,
        value: 77.176
    },
    {
        timestamp: 1668630600000,
        value: 77.237
    },
    {
        timestamp: 1668631500000,
        value: 77.361
    },
    {
        timestamp: 1668632400000,
        value: 74.659
    },
    {
        timestamp: 1668633300000,
        value: 74.41
    },
    {
        timestamp: 1668634200000,
        value: 73.654
    },
    {
        timestamp: 1668635100000,
        value: 73.601
    },
    {
        timestamp: 1668636000000,
        value: 72.349
    },
    {
        timestamp: 1668636900000,
        value: 71.473
    },
    {
        timestamp: 1668637800000,
        value: 70.562
    },
    {
        timestamp: 1668638700000,
        value: 69.975
    },
    {
        timestamp: 1668639600000,
        value: 71.208
    },
    {
        timestamp: 1668640500000,
        value: 70.779
    },
    {
        timestamp: 1668641400000,
        value: 71.119
    },
    {
        timestamp: 1668642300000,
        value: 71.267
    },
    {
        timestamp: 1668643200000,
        value: 70.668
    },
    {
        timestamp: 1668644100000,
        value: 70.122
    },
    {
        timestamp: 1668645000000,
        value: 70.546
    },
    {
        timestamp: 1668645900000,
        value: 71.003
    },
    {
        timestamp: 1668646800000,
        value: 70.37
    },
    {
        timestamp: 1668647700000,
        value: 69.864
    },
    {
        timestamp: 1668648600000,
        value: 69.732
    },
    {
        timestamp: 1668649500000,
        value: 68.884
    },
    {
        timestamp: 1668650400000,
        value: 68.649
    },
    {
        timestamp: 1668651300000,
        value: 68.164
    },
    {
        timestamp: 1668652200000,
        value: 67.75
    },
    {
        timestamp: 1668653100000,
        value: 67.768
    },
    {
        timestamp: 1668654000000,
        value: 67.432
    },
    {
        timestamp: 1668654900000,
        value: 67.771
    },
    {
        timestamp: 1668655800000,
        value: 67.892
    },
    {
        timestamp: 1668656700000,
        value: 68.17
    },
    {
        timestamp: 1668657600000,
        value: 69.268
    },
    {
        timestamp: 1668658500000,
        value: 69.432
    },
    {
        timestamp: 1668659400000,
        value: 69.347
    },
    {
        timestamp: 1668660300000,
        value: 69.008
    },
    {
        timestamp: 1668661200000,
        value: 68.276
    },
    {
        timestamp: 1668662100000,
        value: 68.413
    },
    {
        timestamp: 1668663000000,
        value: 68.247
    },
    {
        timestamp: 1668663900000,
        value: 68.392
    },
    {
        timestamp: 1668664800000,
        value: 66.137
    },
    {
        timestamp: 1668665700000,
        value: 67.797
    },
    {
        timestamp: 1668666600000,
        value: 67.838
    },
    {
        timestamp: 1668667500000,
        value: 68.464
    },
    {
        timestamp: 1668668400000,
        value: 71.743
    },
    {
        timestamp: 1668669300000,
        value: 73.666
    },
    {
        timestamp: 1668670200000,
        value: 74.528
    },
    {
        timestamp: 1668671100000,
        value: 74.658
    },
    {
        timestamp: 1668672000000,
        value: 73.427
    },
    {
        timestamp: 1668672900000,
        value: 73.674
    },
    {
        timestamp: 1668673800000,
        value: 74.06
    },
    {
        timestamp: 1668674700000,
        value: 74.279
    },
    {
        timestamp: 1668675600000,
        value: 74.794
    },
    {
        timestamp: 1668676500000,
        value: 75.204
    },
    {
        timestamp: 1668677400000,
        value: 75.585
    },
    {
        timestamp: 1668678300000,
        value: 75.771
    },
    {
        timestamp: 1668679200000,
        value: 75.74
    },
    {
        timestamp: 1668680100000,
        value: 75.553
    },
    {
        timestamp: 1668681000000,
        value: 75.84
    },
    {
        timestamp: 1668681900000,
        value: 75.103
    },
    {
        timestamp: 1668682800000,
        value: 75.677
    },
    {
        timestamp: 1668683700000,
        value: 75.316
    },
    {
        timestamp: 1668684600000,
        value: 75.091
    },
    {
        timestamp: 1668685500000,
        value: 74.673
    },
    {
        timestamp: 1668686400000,
        value: 74.634
    },
    {
        timestamp: 1668687300000,
        value: 74.768
    },
    {
        timestamp: 1668688200000,
        value: 75.081
    },
    {
        timestamp: 1668689100000,
        value: 76.023
    },
    {
        timestamp: 1668690000000,
        value: 75.637
    },
    {
        timestamp: 1668690900000,
        value: 75.505
    },
    {
        timestamp: 1668691800000,
        value: 75.446
    },
    {
        timestamp: 1668692700000,
        value: 75.776
    },
    {
        timestamp: 1668693600000,
        value: 77.294
    },
    {
        timestamp: 1668694500000,
        value: 76.161
    },
    {
        timestamp: 1668695400000,
        value: 75.302
    },
    {
        timestamp: 1668696300000,
        value: 74.163
    },
    {
        timestamp: 1668697200000,
        value: 76.357
    },
    {
        timestamp: 1668698100000,
        value: 77.098
    },
    {
        timestamp: 1668699000000,
        value: 76.536
    },
    {
        timestamp: 1668699900000,
        value: 76.945
    },
    {
        timestamp: 1668700800000,
        value: 76.723
    },
    {
        timestamp: 1668701700000,
        value: 77.157
    },
    {
        timestamp: 1668702600000,
        value: 77.573
    },
    {
        timestamp: 1668703500000,
        value: 77.533
    },
    {
        timestamp: 1668704400000,
        value: 77.361
    },
    {
        timestamp: 1668705300000,
        value: 75.901
    },
    {
        timestamp: 1668706200000,
        value: 76.097
    },
    {
        timestamp: 1668707100000,
        value: 75.874
    },
    {
        timestamp: 1668708000000,
        value: 76.954
    },
    {
        timestamp: 1668708900000,
        value: 76.999
    },
    {
        timestamp: 1668709800000,
        value: 76.379
    },
    {
        timestamp: 1668710700000,
        value: 77.958
    },
    {
        timestamp: 1668711600000,
        value: 77.884
    },
    {
        timestamp: 1668712500000,
        value: 77.688
    },
    {
        timestamp: 1668713400000,
        value: 78.567
    },
    {
        timestamp: 1668714300000,
        value: 79.003
    },
    {
        timestamp: 1668715200000,
        value: 80.204
    },
    {
        timestamp: 1668716100000,
        value: 79.157
    },
    {
        timestamp: 1668717000000,
        value: 78.848
    },
    {
        timestamp: 1668717900000,
        value: 78.856
    },
    {
        timestamp: 1668718800000,
        value: 77.445
    },
    {
        timestamp: 1668719700000,
        value: 76.677
    },
    {
        timestamp: 1668720600000,
        value: 75.801
    },
    {
        timestamp: 1668721500000,
        value: 75.9
    },
    {
        timestamp: 1668722400000,
        value: 74.631
    },
    {
        timestamp: 1668723300000,
        value: 73.749
    },
    {
        timestamp: 1668724200000,
        value: 72.275
    },
    {
        timestamp: 1668725100000,
        value: 70.607
    },
    {
        timestamp: 1668726000000,
        value: 69.719
    },
    {
        timestamp: 1668726900000,
        value: 69.915
    },
    {
        timestamp: 1668727800000,
        value: 70.076
    },
    {
        timestamp: 1668728700000,
        value: 70.536
    },
    {
        timestamp: 1668729600000,
        value: 69.556
    },
    {
        timestamp: 1668730500000,
        value: 70.007
    },
    {
        timestamp: 1668731400000,
        value: 70.041
    },
    {
        timestamp: 1668732300000,
        value: 70.643
    },
    {
        timestamp: 1668733200000,
        value: 71.824
    },
    {
        timestamp: 1668734100000,
        value: 71.485
    },
    {
        timestamp: 1668735000000,
        value: 71.732
    },
    {
        timestamp: 1668735900000,
        value: 71.389
    },
    {
        timestamp: 1668736800000,
        value: 69.693
    },
    {
        timestamp: 1668737700000,
        value: 69.402
    },
    {
        timestamp: 1668738600000,
        value: 69.973
    },
    {
        timestamp: 1668739500000,
        value: 68.975
    },
    {
        timestamp: 1668740400000,
        value: 68.262
    },
    {
        timestamp: 1668741300000,
        value: 68.189
    },
    {
        timestamp: 1668742200000,
        value: 68.244
    },
    {
        timestamp: 1668743100000,
        value: 68.022
    },
    {
        timestamp: 1668744000000,
        value: 69.52
    },
    {
        timestamp: 1668744900000,
        value: 69.276
    },
    {
        timestamp: 1668745800000,
        value: 69.53
    },
    {
        timestamp: 1668746700000,
        value: 69.573
    },
    {
        timestamp: 1668747600000,
        value: 70.146
    },
    {
        timestamp: 1668748500000,
        value: 70.042
    },
    {
        timestamp: 1668749400000,
        value: 70.044
    },
    {
        timestamp: 1668750300000,
        value: 70.241
    },
    {
        timestamp: 1668751200000,
        value: 71.282
    },
    {
        timestamp: 1668752100000,
        value: 72.157
    },
    {
        timestamp: 1668753000000,
        value: 73.613
    },
    {
        timestamp: 1668753900000,
        value: 74.588
    },
    {
        timestamp: 1668754800000,
        value: 78.555
    },
    {
        timestamp: 1668755700000,
        value: 79.465
    },
    {
        timestamp: 1668756600000,
        value: 80.675
    },
    {
        timestamp: 1668757500000,
        value: 81.405
    },
    {
        timestamp: 1668758400000,
        value: 81.035
    },
    {
        timestamp: 1668759300000,
        value: 81.279
    },
    {
        timestamp: 1668760200000,
        value: 80.663
    },
    {
        timestamp: 1668761100000,
        value: 81.092
    },
    {
        timestamp: 1668762000000,
        value: 82.162
    },
    {
        timestamp: 1668762900000,
        value: 81.897
    },
    {
        timestamp: 1668763800000,
        value: 82.772
    },
    {
        timestamp: 1668764700000,
        value: 82.674
    },
    {
        timestamp: 1668765600000,
        value: 81.482
    },
    {
        timestamp: 1668766500000,
        value: 80.833
    },
    {
        timestamp: 1668767400000,
        value: 81.075
    },
    {
        timestamp: 1668768300000,
        value: 81.201
    },
    {
        timestamp: 1668769200000,
        value: 81.137
    },
    {
        timestamp: 1668770100000,
        value: 81.354
    },
    {
        timestamp: 1668771000000,
        value: 81.824
    },
    {
        timestamp: 1668771900000,
        value: 82.052
    },
    {
        timestamp: 1668772800000,
        value: 81.683
    },
    {
        timestamp: 1668773700000,
        value: 81.912
    },
    {
        timestamp: 1668774600000,
        value: 81.173
    },
    {
        timestamp: 1668775500000,
        value: 81.813
    },
    {
        timestamp: 1668776400000,
        value: 80.937
    },
    {
        timestamp: 1668777300000,
        value: 80.668
    },
    {
        timestamp: 1668778200000,
        value: 80.421
    },
    {
        timestamp: 1668779100000,
        value: 80.216
    },
    {
        timestamp: 1668780000000,
        value: 79.839
    },
    {
        timestamp: 1668780900000,
        value: 79.812
    },
    {
        timestamp: 1668781800000,
        value: 77.83
    },
    {
        timestamp: 1668782700000,
        value: 77.85
    },
    {
        timestamp: 1668783600000,
        value: 76.885
    },
    {
        timestamp: 1668784500000,
        value: 77.486
    },
    {
        timestamp: 1668785400000,
        value: 77.648
    },
    {
        timestamp: 1668786300000,
        value: 77.526
    },
    {
        timestamp: 1668787200000,
        value: 76.864
    },
    {
        timestamp: 1668788100000,
        value: 77.46
    },
    {
        timestamp: 1668789000000,
        value: 77.415
    },
    {
        timestamp: 1668789900000,
        value: 77.801
    },
    {
        timestamp: 1668790800000,
        value: 77.588
    },
    {
        timestamp: 1668791700000,
        value: 77.03
    },
    {
        timestamp: 1668792600000,
        value: 77.303
    },
    {
        timestamp: 1668793500000,
        value: 76.843
    },
    {
        timestamp: 1668794400000,
        value: 74.719
    },
    {
        timestamp: 1668795300000,
        value: 74.567
    },
    {
        timestamp: 1668796200000,
        value: 74.529
    },
    {
        timestamp: 1668797100000,
        value: 74.326
    },
    {
        timestamp: 1668798000000,
        value: 75.112
    },
    {
        timestamp: 1668798900000,
        value: 74.849
    },
    {
        timestamp: 1668799800000,
        value: 75.802
    },
    {
        timestamp: 1668800700000,
        value: 76.647
    },
    {
        timestamp: 1668801600000,
        value: 75.27
    },
    {
        timestamp: 1668802500000,
        value: 74.383
    },
    {
        timestamp: 1668803400000,
        value: 73.444
    },
    {
        timestamp: 1668804300000,
        value: 73.86
    },
    {
        timestamp: 1668805200000,
        value: 71.765
    },
    {
        timestamp: 1668806100000,
        value: 71.256
    },
    {
        timestamp: 1668807000000,
        value: 70.818
    },
    {
        timestamp: 1668807900000,
        value: 71.78
    },
    {
        timestamp: 1668808800000,
        value: 69.076
    },
    {
        timestamp: 1668809700000,
        value: 67.998
    },
    {
        timestamp: 1668810600000,
        value: 67.359
    },
    {
        timestamp: 1668811500000,
        value: 66.748
    },
    {
        timestamp: 1668812400000,
        value: 66.599
    },
    {
        timestamp: 1668813300000,
        value: 66.587
    },
    {
        timestamp: 1668814200000,
        value: 66.981
    },
    {
        timestamp: 1668815100000,
        value: 66.876
    },
    {
        timestamp: 1668816000000,
        value: 66.476
    },
    {
        timestamp: 1668816900000,
        value: 66.204
    },
    {
        timestamp: 1668817800000,
        value: 65.906
    },
    {
        timestamp: 1668818700000,
        value: 66.263
    },
    {
        timestamp: 1668819600000,
        value: 65.891
    },
    {
        timestamp: 1668820500000,
        value: 65.597
    },
    {
        timestamp: 1668821400000,
        value: 65.589
    },
    {
        timestamp: 1668822300000,
        value: 65.665
    },
    {
        timestamp: 1668823200000,
        value: 64.736
    },
    {
        timestamp: 1668824100000,
        value: 64.834
    },
    {
        timestamp: 1668825000000,
        value: 64.215
    },
    {
        timestamp: 1668825900000,
        value: 63.836
    },
    {
        timestamp: 1668826800000,
        value: 63.359
    },
    {
        timestamp: 1668827700000,
        value: 63.261
    },
    {
        timestamp: 1668828600000,
        value: 63.408
    },
    {
        timestamp: 1668829500000,
        value: 63.409
    },
    {
        timestamp: 1668830400000,
        value: 64.466
    },
    {
        timestamp: 1668831300000,
        value: 64.852
    },
    {
        timestamp: 1668832200000,
        value: 64.758
    },
    {
        timestamp: 1668833100000,
        value: 64.368
    },
    {
        timestamp: 1668834000000,
        value: 63.897
    },
    {
        timestamp: 1668834900000,
        value: 63.829
    },
    {
        timestamp: 1668835800000,
        value: 63.054
    },
    {
        timestamp: 1668836700000,
        value: 62.452
    },
    {
        timestamp: 1668837600000,
        value: 60.649
    },
    {
        timestamp: 1668838500000,
        value: 60.524
    },
    {
        timestamp: 1668839400000,
        value: 59.652
    },
    {
        timestamp: 1668840300000,
        value: 60.203
    },
    {
        timestamp: 1668841200000,
        value: 61.518
    },
    {
        timestamp: 1668842100000,
        value: 61.713
    },
    {
        timestamp: 1668843000000,
        value: 62.326
    },
    {
        timestamp: 1668843900000,
        value: 62.098
    },
    {
        timestamp: 1668844800000,
        value: 62.029
    },
    {
        timestamp: 1668845700000,
        value: 62.261
    },
    {
        timestamp: 1668846600000,
        value: 62.034
    },
    {
        timestamp: 1668847500000,
        value: 62.175
    },
    {
        timestamp: 1668848400000,
        value: 63.011
    },
    {
        timestamp: 1668849300000,
        value: 63.104
    },
    {
        timestamp: 1668850200000,
        value: 63.022
    },
    {
        timestamp: 1668851100000,
        value: 62.846
    },
    {
        timestamp: 1668852000000,
        value: 61.612
    },
    {
        timestamp: 1668852900000,
        value: 61.733
    },
    {
        timestamp: 1668853800000,
        value: 61.599
    },
    {
        timestamp: 1668854700000,
        value: 61.347
    },
    {
        timestamp: 1668855600000,
        value: 60.889
    },
    {
        timestamp: 1668856500000,
        value: 61.166
    },
    {
        timestamp: 1668857400000,
        value: 61.4
    },
    {
        timestamp: 1668858300000,
        value: 60.834
    },
    {
        timestamp: 1668859200000,
        value: 60.776
    },
    {
        timestamp: 1668860100000,
        value: 61.384
    },
    {
        timestamp: 1668861000000,
        value: 61.329
    },
    {
        timestamp: 1668861900000,
        value: 61.01
    },
    {
        timestamp: 1668862800000,
        value: 59.884
    },
    {
        timestamp: 1668863700000,
        value: 59.868
    },
    {
        timestamp: 1668864600000,
        value: 60.213
    },
    {
        timestamp: 1668865500000,
        value: 59.925
    },
    {
        timestamp: 1668866400000,
        value: 58.396
    },
    {
        timestamp: 1668867300000,
        value: 57.436
    },
    {
        timestamp: 1668868200000,
        value: 57.127
    },
    {
        timestamp: 1668869100000,
        value: 56.881
    },
    {
        timestamp: 1668870000000,
        value: 54.986
    },
    {
        timestamp: 1668870900000,
        value: 54.916
    },
    {
        timestamp: 1668871800000,
        value: 54.788
    },
    {
        timestamp: 1668872700000,
        value: 55.156
    },
    {
        timestamp: 1668873600000,
        value: 57.449
    },
    {
        timestamp: 1668874500000,
        value: 58.444
    },
    {
        timestamp: 1668875400000,
        value: 58.964
    },
    {
        timestamp: 1668876300000,
        value: 58.637
    },
    {
        timestamp: 1668877200000,
        value: 58.601
    },
    {
        timestamp: 1668878100000,
        value: 58.639
    },
    {
        timestamp: 1668879000000,
        value: 58.277
    },
    {
        timestamp: 1668879900000,
        value: 57.872
    },
    {
        timestamp: 1668880800000,
        value: 57.916
    },
    {
        timestamp: 1668881700000,
        value: 57.765
    },
    {
        timestamp: 1668882600000,
        value: 57.916
    },
    {
        timestamp: 1668883500000,
        value: 57.905
    },
    {
        timestamp: 1668884400000,
        value: 58.692
    },
    {
        timestamp: 1668885300000,
        value: 58.525
    },
    {
        timestamp: 1668886200000,
        value: 58.324
    },
    {
        timestamp: 1668887100000,
        value: 58.614
    },
    {
        timestamp: 1668888000000,
        value: 57.557
    },
    {
        timestamp: 1668888900000,
        value: 57.219
    },
    {
        timestamp: 1668889800000,
        value: 57.737
    },
    {
        timestamp: 1668890700000,
        value: 58.077
    },
    {
        timestamp: 1668891600000,
        value: 58.237
    },
    {
        timestamp: 1668892500000,
        value: 58.179
    },
    {
        timestamp: 1668893400000,
        value: 57.45
    },
    {
        timestamp: 1668894300000,
        value: 56.445
    },
    {
        timestamp: 1668895200000,
        value: 54.977
    },
    {
        timestamp: 1668896100000,
        value: 54.618
    },
    {
        timestamp: 1668897000000,
        value: 53.924
    },
    {
        timestamp: 1668897900000,
        value: 54.318
    },
    {
        timestamp: 1668898800000,
        value: 52.75
    },
    {
        timestamp: 1668899700000,
        value: 53.164
    },
    {
        timestamp: 1668900600000,
        value: 52.502
    },
    {
        timestamp: 1668901500000,
        value: 52.636
    },
    {
        timestamp: 1668902400000,
        value: 52.437
    },
    {
        timestamp: 1668903300000,
        value: 52.539
    },
    {
        timestamp: 1668904200000,
        value: 52.11
    },
    {
        timestamp: 1668905100000,
        value: 52.359
    },
    {
        timestamp: 1668906000000,
        value: 52.451
    },
    {
        timestamp: 1668906900000,
        value: 52.614
    },
    {
        timestamp: 1668907800000,
        value: 52.976
    },
    {
        timestamp: 1668908700000,
        value: 52.887
    },
    {
        timestamp: 1668909600000,
        value: 51.864
    },
    {
        timestamp: 1668910500000,
        value: 51.888
    },
    {
        timestamp: 1668911400000,
        value: 51.807
    },
    {
        timestamp: 1668912300000,
        value: 51.88
    },
    {
        timestamp: 1668913200000,
        value: 51.901
    },
    {
        timestamp: 1668914100000,
        value: 51.903
    },
    {
        timestamp: 1668915000000,
        value: 52.528
    },
    {
        timestamp: 1668915900000,
        value: 52.304
    },
    {
        timestamp: 1668916800000,
        value: 52.326
    },
    {
        timestamp: 1668917700000,
        value: 52.266
    },
    {
        timestamp: 1668918600000,
        value: 52.553
    },
    {
        timestamp: 1668919500000,
        value: 52.053
    },
    {
        timestamp: 1668920400000,
        value: 52.928
    },
    {
        timestamp: 1668921300000,
        value: 52.854
    },
    {
        timestamp: 1668922200000,
        value: 52.75
    },
    {
        timestamp: 1668923100000,
        value: 52.516
    },
    {
        timestamp: 1668924000000,
        value: 50.791
    },
    {
        timestamp: 1668924900000,
        value: 50.887
    },
    {
        timestamp: 1668925800000,
        value: 50.775
    },
    {
        timestamp: 1668926700000,
        value: 50.878
    },
    {
        timestamp: 1668927600000,
        value: 51.282
    },
    {
        timestamp: 1668928500000,
        value: 51.194
    },
    {
        timestamp: 1668929400000,
        value: 51.575
    },
    {
        timestamp: 1668930300000,
        value: 51.857
    },
    {
        timestamp: 1668931200000,
        value: 50.751
    },
    {
        timestamp: 1668932100000,
        value: 50.901
    },
    {
        timestamp: 1668933000000,
        value: 50.797
    },
    {
        timestamp: 1668933900000,
        value: 50.843
    },
    {
        timestamp: 1668934800000,
        value: 52.08
    },
    {
        timestamp: 1668935700000,
        value: 51.872
    },
    {
        timestamp: 1668936600000,
        value: 51.874
    },
    {
        timestamp: 1668937500000,
        value: 52.28
    },
    {
        timestamp: 1668938400000,
        value: 52.653
    },
    {
        timestamp: 1668939300000,
        value: 52.697
    },
    {
        timestamp: 1668940200000,
        value: 52.362
    },
    {
        timestamp: 1668941100000,
        value: 52.65
    },
    {
        timestamp: 1668942000000,
        value: 53.057
    },
    {
        timestamp: 1668942900000,
        value: 52.743
    },
    {
        timestamp: 1668943800000,
        value: 52.908
    },
    {
        timestamp: 1668944700000,
        value: 53.015
    },
    {
        timestamp: 1668945600000,
        value: 53.088
    },
    {
        timestamp: 1668946500000,
        value: 53.308
    },
    {
        timestamp: 1668947400000,
        value: 53.138
    },
    {
        timestamp: 1668948300000,
        value: 53.207
    },
    {
        timestamp: 1668949200000,
        value: 52.762
    },
    {
        timestamp: 1668950100000,
        value: 52.894
    },
    {
        timestamp: 1668951000000,
        value: 52.979
    },
    {
        timestamp: 1668951900000,
        value: 53.122
    },
    {
        timestamp: 1668952800000,
        value: 53.107
    },
    {
        timestamp: 1668953700000,
        value: 52.992
    },
    {
        timestamp: 1668954600000,
        value: 53.213
    },
    {
        timestamp: 1668955500000,
        value: 52.744
    },
    {
        timestamp: 1668956400000,
        value: 51.729
    },
    {
        timestamp: 1668957300000,
        value: 52.318
    },
    {
        timestamp: 1668958200000,
        value: 52.12
    },
    {
        timestamp: 1668959100000,
        value: 52.128
    },
    {
        timestamp: 1668960000000,
        value: 52.274
    },
    {
        timestamp: 1668960900000,
        value: 53.355
    },
    {
        timestamp: 1668961800000,
        value: 53.213
    },
    {
        timestamp: 1668962700000,
        value: 53.325
    },
    {
        timestamp: 1668963600000,
        value: 53.096
    },
    {
        timestamp: 1668964500000,
        value: 52.836
    },
    {
        timestamp: 1668965400000,
        value: 52.977
    },
    {
        timestamp: 1668966300000,
        value: 52.816
    },
    {
        timestamp: 1668967200000,
        value: 54.107
    },
    {
        timestamp: 1668968100000,
        value: 53.263
    },
    {
        timestamp: 1668969000000,
        value: 53.438
    },
    {
        timestamp: 1668969900000,
        value: 53.05
    },
    {
        timestamp: 1668970800000,
        value: 52.915
    },
    {
        timestamp: 1668971700000,
        value: 53.027
    },
    {
        timestamp: 1668972600000,
        value: 54.15
    },
    {
        timestamp: 1668973500000,
        value: 54.665
    },
    {
        timestamp: 1668974400000,
        value: 54.725
    },
    {
        timestamp: 1668975300000,
        value: 53.902
    },
    {
        timestamp: 1668976200000,
        value: 53.73
    },
    {
        timestamp: 1668977100000,
        value: 53.583
    },
    {
        timestamp: 1668978000000,
        value: 53.996
    },
    {
        timestamp: 1668978900000,
        value: 54.479
    },
    {
        timestamp: 1668979800000,
        value: 53.585
    },
    {
        timestamp: 1668980700000,
        value: 53.298
    },
    {
        timestamp: 1668981600000,
        value: 52.235
    },
    {
        timestamp: 1668982500000,
        value: 52.324
    },
    {
        timestamp: 1668983400000,
        value: 51.426
    },
    {
        timestamp: 1668984300000,
        value: 51.829
    },
    {
        timestamp: 1668985200000,
        value: 53.255
    },
    {
        timestamp: 1668986100000,
        value: 52.838
    },
    {
        timestamp: 1668987000000,
        value: 53.188
    },
    {
        timestamp: 1668987900000,
        value: 52.093
    },
    {
        timestamp: 1668988800000,
        value: 51.337
    },
    {
        timestamp: 1668989700000,
        value: 51.53
    },
    {
        timestamp: 1668990600000,
        value: 51.858
    },
    {
        timestamp: 1668991500000,
        value: 51.912
    },
    {
        timestamp: 1668992400000,
        value: 52.265
    },
    {
        timestamp: 1668993300000,
        value: 52.197
    },
    {
        timestamp: 1668994200000,
        value: 52.844
    },
    {
        timestamp: 1668995100000,
        value: 52.317
    },
    {
        timestamp: 1668996000000,
        value: 52.273
    },
    {
        timestamp: 1668996900000,
        value: 52.54
    },
    {
        timestamp: 1668997800000,
        value: 53.226
    },
    {
        timestamp: 1668998700000,
        value: 53.319
    },
    {
        timestamp: 1668999600000,
        value: 53.304
    },
    {
        timestamp: 1669000500000,
        value: 52.736
    },
    {
        timestamp: 1669001400000,
        value: 52.912
    },
    {
        timestamp: 1669002300000,
        value: 53.14
    },
    {
        timestamp: 1669003200000,
        value: 52.815
    },
    {
        timestamp: 1669004100000,
        value: 52.802
    },
    {
        timestamp: 1669005000000,
        value: 53.405
    },
    {
        timestamp: 1669005900000,
        value: 53.611
    },
    {
        timestamp: 1669006800000,
        value: 54.827
    },
    {
        timestamp: 1669007700000,
        value: 54.694
    },
    {
        timestamp: 1669008600000,
        value: 54.476
    },
    {
        timestamp: 1669009500000,
        value: 55.109
    },
    {
        timestamp: 1669010400000,
        value: 59.231
    },
    {
        timestamp: 1669011300000,
        value: 61.317
    },
    {
        timestamp: 1669012200000,
        value: 63.147
    },
    {
        timestamp: 1669013100000,
        value: 63.944
    },
    {
        timestamp: 1669014000000,
        value: 69.561
    },
    {
        timestamp: 1669014900000,
        value: 70.908
    },
    {
        timestamp: 1669015800000,
        value: 71.906
    },
    {
        timestamp: 1669016700000,
        value: 72.981
    },
    {
        timestamp: 1669017600000,
        value: 73.702
    },
    {
        timestamp: 1669018500000,
        value: 74.253
    },
    {
        timestamp: 1669019400000,
        value: 74.856
    },
    {
        timestamp: 1669020300000,
        value: 74.202
    },
    {
        timestamp: 1669021200000,
        value: 76.96
    },
    {
        timestamp: 1669022100000,
        value: 77.72
    },
    {
        timestamp: 1669023000000,
        value: 77.577
    },
    {
        timestamp: 1669023900000,
        value: 77.888
    },
    {
        timestamp: 1669024800000,
        value: 79.168
    },
    {
        timestamp: 1669025700000,
        value: 79.146
    },
    {
        timestamp: 1669026600000,
        value: 78.763
    },
    {
        timestamp: 1669027500000,
        value: 77.829
    },
    {
        timestamp: 1669028400000,
        value: 78.538
    },
    {
        timestamp: 1669029300000,
        value: 78.885
    },
    {
        timestamp: 1669030200000,
        value: 79.786
    },
    {
        timestamp: 1669031100000,
        value: 79.748
    },
    {
        timestamp: 1669032000000,
        value: 79.754
    },
    {
        timestamp: 1669032900000,
        value: 81.023
    },
    {
        timestamp: 1669033800000,
        value: 80.84
    },
    {
        timestamp: 1669034700000,
        value: 79.533
    },
    {
        timestamp: 1669035600000,
        value: 79.791
    },
    {
        timestamp: 1669036500000,
        value: 80.184
    },
    {
        timestamp: 1669037400000,
        value: 79.877
    },
    {
        timestamp: 1669038300000,
        value: 79.324
    },
    {
        timestamp: 1669039200000,
        value: 77.661
    },
    {
        timestamp: 1669040100000,
        value: 76.849
    },
    {
        timestamp: 1669041000000,
        value: 77.937
    },
    {
        timestamp: 1669041900000,
        value: 77.64
    },
    {
        timestamp: 1669042800000,
        value: 77.095
    },
    {
        timestamp: 1669043700000,
        value: 77.061
    },
    {
        timestamp: 1669044600000,
        value: 78.244
    },
    {
        timestamp: 1669045500000,
        value: 77.574
    },
    {
        timestamp: 1669046400000,
        value: 77.22
    },
    {
        timestamp: 1669047300000,
        value: 78.532
    },
    {
        timestamp: 1669048200000,
        value: 78.677
    },
    {
        timestamp: 1669049100000,
        value: 78.61
    },
    {
        timestamp: 1669050000000,
        value: 79.228
    },
    {
        timestamp: 1669050900000,
        value: 79.207
    },
    {
        timestamp: 1669051800000,
        value: 78.948
    },
    {
        timestamp: 1669052700000,
        value: 78.481
    },
    {
        timestamp: 1669053600000,
        value: 76.677
    },
    {
        timestamp: 1669054500000,
        value: 76.886
    },
    {
        timestamp: 1669055400000,
        value: 77.028
    },
    {
        timestamp: 1669056300000,
        value: 76.702
    },
    {
        timestamp: 1669057200000,
        value: 76.856
    },
    {
        timestamp: 1669058100000,
        value: 77.209
    },
    {
        timestamp: 1669059000000,
        value: 77.817
    },
    {
        timestamp: 1669059900000,
        value: 78.3
    },
    {
        timestamp: 1669060800000,
        value: 79.636
    },
    {
        timestamp: 1669061700000,
        value: 79.264
    },
    {
        timestamp: 1669062600000,
        value: 78.97
    },
    {
        timestamp: 1669063500000,
        value: 79.268
    },
    {
        timestamp: 1669064400000,
        value: 77.879
    },
    {
        timestamp: 1669065300000,
        value: 77.963
    },
    {
        timestamp: 1669066200000,
        value: 77.054
    },
    {
        timestamp: 1669067100000,
        value: 76.27
    },
    {
        timestamp: 1669068000000,
        value: 72.41
    },
    {
        timestamp: 1669068900000,
        value: 71.128
    },
    {
        timestamp: 1669069800000,
        value: 70.908
    },
    {
        timestamp: 1669070700000,
        value: 69.686
    },
    {
        timestamp: 1669071600000,
        value: 68.859
    },
    {
        timestamp: 1669072500000,
        value: 68.907
    },
    {
        timestamp: 1669073400000,
        value: 68.955
    },
    {
        timestamp: 1669074300000,
        value: 68.726
    }
] as unknown as Forecast[];

export const mockedConsumersChartData = [
    mockedConsumersForecast.map((i) => ({ time: dayjs(i.timestamp).unix(), value: i.value })),
    mockedDemandHistorical.map((i) => ({ time: dayjs(i.timestamp).unix(), value: i.value }))
];
