import deletePermissions from './deletePermissions';
import listPermissions from './listPermissions';
import updatePermissions from './updatePermissions';

export * from './deletePermissions';
export * from './listPermissions';
export * from './updatePermissions';

export default {
    deletePermissions,
    updatePermissions,
    listPermissions
};
