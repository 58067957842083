import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore, combineReducers, ThunkAction, AnyAction } from '@reduxjs/toolkit';

import { api } from './api';
import rootReducer from './rootReducer';

const appReducer = combineReducers(rootReducer);

const reducer = (state: any, action: any) => {
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
// export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
