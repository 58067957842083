import { api } from '../api';

import listClients from './listClients';
import createClient from './createClient';
import { Client, PaginatedApiResponse } from '../../interfaces/apiv2';

export const clientsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getClients: build.query({
            query: (params) => {
                if (params.search?.trim()) {
                    return {
                        url: '/clients',
                        method: 'get',
                        params: { ...params, search: params.search },
                        data: undefined
                    };
                }

                return { url: '/clients', method: 'get', params, data: undefined };
            },
            transformResponse: (rawData: PaginatedApiResponse<Client>) => {
                return rawData.items;
            }
        })
    })
});

export const { useGetClientsQuery } = clientsApi;

export * from './createClient';
export * from './listClients';

export default { listClients, createClient };
