import axios from '../../utils/axios';
import { DefaultInitialState } from '../../interfaces/redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ToastifyType from '../../utils/toastify-config';
import i18next from '../../utils/i18next';
import { PostIngestMeteoAssetBody } from '../../interfaces/uiv2';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const ingestMeteoDataAssetAsync = createAsyncThunk(
    'assets/ingestMeteoDataAssetAsync',
    async (values: PostIngestMeteoAssetBody, { rejectWithValue }) => {
        try {
            const response = await axios.post(`/electricity/generation/assets/${values.id}/ingest-meteo`, {
                start_date: values.start_date,
                end_date: values.end_date,
                sources: values.sources
            });

            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
    }
);

export const ingestMeteoDataAsset = createSlice({
    name: 'ingestMeteoDataAsset',
    initialState,
    reducers: {
        resetIngestMeteoDataAssetState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(ingestMeteoDataAssetAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(ingestMeteoDataAssetAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('ingestMeteoDataStarted'), ToastifyType.success);
        });
        builder.addCase(ingestMeteoDataAssetAsync.rejected, (state, error: any) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(`${i18next.t('ingestMeteoDataFailed')}: ${error.payload.detail}`, ToastifyType.error);
        });
    }
});

export const { resetIngestMeteoDataAssetState } = ingestMeteoDataAsset.actions;

export default ingestMeteoDataAsset.reducer;
