import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { PaginatedApiResponse, VPP } from '../../interfaces/apiv2';
import { VPPFE, VPPListGetParams } from '../../interfaces/uiv2';
import ToastifyType from '../../utils/toastify-config';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { getQueryParamsFromObject } from '../../utils/table';
import { ApiPaginationInfo } from '../../interfaces/apiv2';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { transformVPPAPIToFE } from './helpers';

interface listVPPState extends DefaultInitialState {
    vpp: VPP[];
    vppFE: VPPFE[];
    searchParam: string | null;
    pager: ApiPaginationInfo | null;
    getParams: VPPListGetParams;
    selectedVPPs: VPPFE[];
}

const initialState: listVPPState = {
    loading: false,
    error: false,
    success: false,
    vpp: [],
    vppFE: [],
    searchParam: null,
    pager: null,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    selectedVPPs: []
};

export const getListVPPAssetsAsync = createAsyncThunk(
    'vpp/list',
    async (values: VPPListGetParams = {}, { rejectWithValue }) => {
        try {
            const endpoint = '/electricity/generation/assets/vpp';
            const queryParams = getQueryParamsFromObject({ ...values });

            const response = await axios.get<PaginatedApiResponse<VPP>>(`${endpoint}${queryParams}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listVPP = createSlice({
    name: 'listVPP',
    initialState,
    reducers: {
        resetlistVPPState: () => initialState,
        setGetParamsVPPAssets: (state, { payload }: PayloadAction<VPPListGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedVPPs: (state, { payload }: PayloadAction<VPPFE[]>) => {
            state.selectedVPPs = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getListVPPAssetsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getListVPPAssetsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.vpp = items;
            } else {
                state.vpp = [...current(state).vpp, ...items];
            }

            state.vppFE = transformVPPAPIToFE(current(state).vpp);
        });
        builder.addCase(getListVPPAssetsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetlistVPPState, setGetParamsVPPAssets, setSelectedVPPs } = listVPP.actions;

export default listVPP.reducer;
