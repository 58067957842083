import { debounce } from 'lodash';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useState, memo, ChangeEvent } from 'react';
import { Box, Input, Divider, InputLabel, Typography, Autocomplete, TextField } from '@mui/material';

import { MODAL_IDS } from '..';
import Loading from '../../layout/Loading';
import { GasDSO } from '../../interfaces/apiv2';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { useAppDispatch, useAppSelector } from '../../stores';
import { FormErrorStep, FormModalActions, FormSuccessStep } from '../../components/FormUtils';
import { postGasProfileAsync, resetCreateProfilesState, useGetGasDsoQuery } from '../../stores/Demand';

import style from './style.module.scss';

const CreateGasProfileForm = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { projectId = '' } = useParams();

    const [open, setOpen] = useState(false);
    const [profileName, setProfileName] = useState('');
    const [description, setDescription] = useState('');
    const [externalId, setId] = useState('');
    const [dso, setDso] = useState<GasDSO | null>(null);
    const [search, setSearch] = useState('');

    const { error, loading, success } = useAppSelector((state) => state.createGasProfiles);

    const handleChange = (cb: any) => (event: ChangeEvent<HTMLInputElement>) => {
        cb(event.target.value);
    };

    const handleAutoSelectChange = () => {
        setDso(null);
        setOpen(false);
    };

    const { data: dsos, isLoading } = useGetGasDsoQuery({
        size: 100,
        page: PAGINATION_SETTINGS.default_start_page,
        search: search,
        projectId
    });

    const onChangeInput = debounce((e: any) => {
        const value = e.target.value;
        setSearch(value);
    }, 450);

    const handleIdChange = (e: GasDSO) => () => {
        setDso(e);
        setOpen(false);
    };

    const handleSubmit = () => {
        if (profileName && description && dso) {
            const payload = {
                name: profileName,
                description,
                external_id: externalId,
                dso_id: dso.id
            };

            dispatch(postGasProfileAsync({ projectId, payload }));
        }
    };

    useEffect(() => {
        return () => {
            dispatch(resetCreateProfilesState());
        };
    }, []);

    const isValid = description && profileName && dso;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className={style.box}>
                {!(success || error) ? (
                    <>
                        <Typography variant="h6">{t('createProfileTitle')}</Typography>
                        <Box className={style.formRow}>
                            <InputLabel variant="standard" required shrink htmlFor={t('inputProfileName')}>
                                {t('inputProfileName')}
                            </InputLabel>
                            <Input
                                fullWidth
                                id={t('inputProfileName')}
                                placeholder={t('placeholder.profileName')}
                                onChange={handleChange(setProfileName)}
                                value={profileName}
                            />
                        </Box>
                        <Box className={style.formRow}>
                            <InputLabel variant="standard" required shrink htmlFor={t('inputProfileDescription')}>
                                {t('inputProfileDescription')}
                            </InputLabel>
                            <Input
                                fullWidth
                                id={t('inputProfileDescription')}
                                placeholder={t('placeholder.description')}
                                onChange={handleChange(setDescription)}
                                value={description}
                            />
                        </Box>
                        <Box className={classNames(style.formRow, style.fullWidth)}>
                            <InputLabel variant="standard" shrink htmlFor={t('dsoId')}>
                                {t('dsoId')}
                            </InputLabel>
                            <Autocomplete
                                id="asynchronous-assets"
                                value={dso}
                                options={dsos || []}
                                className={open ? style.autocompleteOpen : ''}
                                loading={isLoading}
                                onChange={handleAutoSelectChange}
                                noOptionsText={t('select.noOptions')}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.name}
                                popupIcon={
                                    isLoading ? (
                                        <Loading className={style.loading} />
                                    ) : (
                                        <MdKeyboardArrowDown color="#6A9BFF" />
                                    )
                                }
                                renderOption={(props: any, e: GasDSO) => (
                                    <Box component="li" {...props} key={Math.random()} onClick={handleIdChange(e)}>
                                        {e.name}
                                    </Box>
                                )}
                                ListboxProps={{ style: { maxHeight: '180px' } }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={onChangeInput}
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: t('placeholder.dso')
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {isLoading ? <Loading /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            )
                                        }}
                                    />
                                )}
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                clearOnEscape={true}
                                clearOnBlur={true}
                                filterOptions={(x) => x}
                            />
                        </Box>
                        <Box className={style.formRow}>
                            <InputLabel variant="standard" shrink htmlFor={t('inputExternalId')}>
                                {t('inputExternalId')}
                            </InputLabel>
                            <Input
                                fullWidth
                                id={t('inputExternalId')}
                                placeholder={t('placeholder.inputExternalId')}
                                onChange={handleChange(setId)}
                                value={externalId}
                            />
                        </Box>
                        <Box className={style.formRow}>
                            <Typography className={style.black} variant="small4">
                                {t('mandatory')}
                            </Typography>
                        </Box>
                        <Divider />
                        <FormModalActions
                            loading={loading}
                            note={' '}
                            disabled={!isValid}
                            modalId={MODAL_IDS.ADD_GAS_PROFILE_MODAL}
                            cancelLabel={t('nevermind')}
                            saveLabel={t('ctaLabel')}
                            onSubmit={handleSubmit}
                        />
                    </>
                ) : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
            </Box>
        </LocalizationProvider>
    );
};

export const MemoizedCreateGasProfileForm = memo(CreateGasProfileForm);

export default CreateGasProfileForm;
