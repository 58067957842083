import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo, EVSCluster } from '../../interfaces/apiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { transformForecastToTable, transformForecastToView } from '../../utils/forecast';
import { FORECAST_TYPE, ForecastDataGetParams, ForecastFE } from '../../interfaces/uiv2';

interface IEVSClusterPage extends DefaultInitialState {
    cluster: EVSCluster | null;
    data: ForecastFE[];
    paginatedData: ForecastFE[];
    chartData: LineData[][];
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
}

const initialState: IEVSClusterPage = {
    cluster: null,
    data: [],
    paginatedData: [],
    chartData: [[], []],
    loading: false,
    error: false,
    success: false,
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        // order_by: ['-created_on']
        order_by: undefined
    },
    pager: null
};

export const getEVSClusterAsync = createAsyncThunk(
    'evs/cluster/show',
    async (values: { id: string }, { rejectWithValue }) => {
        try {
            const endpoint = `/electricity/evs/clusters`;
            const response = await axios.get<EVSCluster>(`${endpoint}/${values.id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getEVSClusterForecastDataAsync = createAsyncThunk(
    'evs/cluster_forecast_data/get',
    async ({ cluster }: { cluster: EVSCluster }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ForecastFE[]>(`/electricity/evs/clusters/${cluster.id}/forecast`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getEVSClusternHistoricalAsync = createAsyncThunk(
    'evs/cluster_historical_data/get',
    async ({ cluster }: { cluster: EVSCluster }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ForecastFE[]>(`/electricity/evs/clusters/${cluster.id}/historical`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const evsClusterPage = createSlice({
    name: 'evsClusterPage',
    initialState,
    reducers: {
        resetEVSClusterPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setActiveEVSCluster: (state, { payload }: PayloadAction<EVSCluster | null>) => {
            state.cluster = payload;
        },
        loadMore: (state) => {
            const { data, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: data.length
            };
            state.paginatedData = data.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEVSClusterForecastDataAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSClusterForecastDataAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.data = transformForecastToTable(payload as any);
            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).data.length
            };
            state.paginatedData = current(state).data.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
            state.chartData = [
                transformForecastToView(payload as any, FORECAST_TYPE.ELECTRICITY).map((x) => ({
                    ...x,
                    value: x.value * 1000
                })),
                []
            ];
        });
        builder.addCase(getEVSClusternHistoricalAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSClusternHistoricalAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            if (payload.length) {
                state.chartData[1] = transformForecastToView(payload as any, FORECAST_TYPE.ELECTRICITY).map((x) => ({
                    ...x,
                    value: x.value * 1000
                }));
            }
        });
        builder.addCase(getEVSClusternHistoricalAsync.rejected, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSClusterForecastDataAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
        builder.addCase(getEVSClusterAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSClusterAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.cluster = payload;
        });
        builder.addCase(getEVSClusterAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
    }
});

export const {
    resetEVSClusterPage,
    setActiveEVSCluster,
    setGetParams: setGetEVSClusterParams,
    loadMore: loadMoreEVSClusterData
} = evsClusterPage.actions;

export default evsClusterPage.reducer;
