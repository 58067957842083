import { FC } from 'react';
import style from './style.module.scss';
import classNames from 'classnames';

import { ReactComponent as IconBase } from '../../assets/icons/map/icon-base.svg';
import { icons } from './helpers';
import { ReactComponent as ChargingStationsIcon } from '../../assets/icons/navigation-items/menu-item-charging-stations.svg';

interface MarkerIconProps {
    entityType: string;
    isActive: boolean;
}

const MarkerIcon: FC<MarkerIconProps> = ({ entityType, isActive }) => {
    const CurrentIcon = entityType ? icons[entityType] : ChargingStationsIcon;
    const layersButton = entityType === 'Layers';

    return (
        <div className={classNames(style.markerIcon, isActive && style.markerIconActive)}>
            <IconBase className={classNames(style.iconBase, layersButton && style.noTip)} />
            <CurrentIcon className={style.currentIcon} />
        </div>
    );
};

export default MarkerIcon;
