import dayjs from 'dayjs';
import { FC, useEffect, useRef } from 'react';
import {
    ColorType,
    createChart,
    IChartApi,
    ISeriesApi,
    ITimeScaleApi,
    LineData,
    LineStyle,
    Time
} from 'lightweight-charts';

import { downloadChart } from '../../stores/Assets';
import { useAppDispatch, useAppSelector } from '../../stores';
import { debounce, secondsInADay, toFixed } from '../../utils/helpers';

interface TradingViewChartProps {
    data: LineData[][];
    colors: string[];
    fetchMore: (from: string) => void;
    measureUnit: string;
    limits?: boolean[];
}

export const TradingViewChart: FC<TradingViewChartProps> = (props) => {
    const { data, colors, measureUnit, limits = [] } = props;

    const { backgroundColor, textColor } = {
        backgroundColor: '#fbfcff',
        textColor: 'rgba(0, 0, 0, 0.6)'
    };

    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<IChartApi | null>(null);
    const seriesRefs = useRef<ISeriesApi<'Line'>[]>([]);
    const dispatch = useAppDispatch();
    const { download } = useAppSelector((state) => state.assetPage);

    useEffect(() => {
        if (chartContainerRef.current) {
            const handleResize = () => {
                chart.applyOptions({ width: chartContainerRef?.current?.clientWidth });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: { type: ColorType.Solid, color: backgroundColor },
                    textColor,
                    fontFamily: 'Montserrat'
                },
                width: chartContainerRef?.current?.clientWidth,
                height: chartContainerRef?.current?.parentElement?.clientHeight || 380,
                timeScale: {
                    timeVisible: true,
                    secondsVisible: true,
                    shiftVisibleRangeOnNewBar: false,
                    borderColor: '#D5E3FF'
                },
                grid: {
                    vertLines: {
                        color: '#D5E3FF'
                    },
                    horzLines: {
                        color: '#D5E3FF'
                    }
                },
                rightPriceScale: {
                    borderColor: '#D5E3FF'
                },
                crosshair: {
                    vertLine: {
                        color: '#979797',
                        width: 1,
                        style: LineStyle.Dashed
                    },
                    horzLine: {
                        color: '#979797',
                        width: 1,
                        style: LineStyle.Dashed
                    }
                },
                localization: {
                    priceFormatter: (priceValue: any) => {
                        return `${toFixed(priceValue)} ${measureUnit}`;
                    }
                }
            });

            chartRef.current = chart;

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);

                chartRef?.current?.remove();
            };
        }

        return () => {
            chartContainerRef.current?.remove();
        };
    }, []);

    // Case Init -> We don't have series: create them and setData for each serie
    useEffect(() => {
        if (chartRef.current !== null && seriesRefs.current.length === 0) {
            data.forEach((item, index) => {
                const series = chartRef.current?.addLineSeries({
                    color: colors[index],
                    baseLineColor: colors[index],

                    lineStyle: LineStyle.Solid,
                    lineWidth: 2
                }) as ISeriesApi<'Line'>;

                const compatibleItem = item;

                if (compatibleItem.length > 0) {
                    series.setData(compatibleItem.map((e) => ({ ...e, _internal_originalTime: e.time })));
                }

                seriesRefs.current.push(series);
            });

            fitGraphOnInit();
        }
    }, [data]);

    // Case Update -> We already have series, just setData for each serie
    useEffect(() => {
        if (seriesRefs.current !== null && seriesRefs.current.length > 0) {
            data.forEach((item, index) => {
                const compatibleItem = item;

                if (compatibleItem.length > 0) {
                    seriesRefs.current[index].setData(
                        compatibleItem.map((e) => ({ ...e, _internal_originalTime: e.time }))
                    );
                }
            });
        }
    }, [data]);

    // Update trigger based on timeScale
    useEffect(() => {
        const timeScale = chartRef?.current?.timeScale();

        if (chartRef.current) {
            const timeHandler = debounce(() => {
                let from = null;
                const logicalRange = timeScale?.getVisibleLogicalRange();

                if (logicalRange) {
                    for (const [index, s] of seriesRefs.current.entries()) {
                        const barsInfo = s?.barsInLogicalRange(logicalRange);

                        if (barsInfo && barsInfo.barsBefore < 20 && !limits[index]) {
                            const compatibleItem = data[index];
                            const f = (compatibleItem[0].time as number) * 1000;

                            const newFrom = new Date(f).toDateString();
                            if (!dayjs(from).isValid() || !dayjs(from).isSame(dayjs(newFrom))) {
                                from = newFrom;
                            }
                        }
                    }
                }

                if (from) {
                    props.fetchMore(from);
                }
            }, 250);

            timeScale?.subscribeVisibleLogicalRangeChange(timeHandler);

            return () => {
                timeScale?.unsubscribeVisibleLogicalRangeChange(timeHandler);
            };
        }
    }, [data]);

    useEffect(() => {
        if (download) {
            takeScreenshot();
        }
    }, [download]);

    const shouldFitChart = (timeScale: ITimeScaleApi<Time> | undefined) => {
        let shouldFit = false;

        for (const serie of seriesRefs.current) {
            const logicalRange = timeScale?.getVisibleLogicalRange();

            if (logicalRange) {
                const barsInfo = serie?.barsInLogicalRange(logicalRange);

                if (barsInfo && barsInfo.barsBefore < 0) {
                    shouldFit = true;
                    break;
                }
            }
        }

        return shouldFit;
    };

    const fitGraphOnInit = () => {
        const timeScale = chartRef?.current?.timeScale();

        if (shouldFitChart(timeScale)) {
            timeScale?.fitContent();
        } else {
            const currentTime = dayjs();
            const timeRange = secondsInADay * 1000;

            const hasCurrentTimeData = data.some((dataset) => {
                const compatibleItem = dataset;

                return compatibleItem.some((x) => {
                    return (
                        dayjs.unix(x.time as number).isAfter(currentTime.subtract(timeRange)) &&
                        dayjs.unix(x.time as number).isBefore(currentTime.add(timeRange))
                    );
                });
            });

            if (hasCurrentTimeData) {
                const nextDayTime = currentTime.add(1, 'day').unix();
                timeScale?.setVisibleRange({ from: currentTime.unix() as Time, to: nextDayTime as Time });
            }
        }
    };

    const takeScreenshot = () => {
        const content = chartRef.current?.takeScreenshot();
        function blobCallback(iconName: string) {
            return (b: Blob | null) => {
                if (b) {
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style.display = 'none';
                    a.download = `${iconName}.png`;
                    a.href = window.URL.createObjectURL(b);
                    a.click();
                    a.remove();
                    dispatch(downloadChart(false));
                }
            };
        }
        content?.toBlob(blobCallback('chart'), 'image/png');
    };

    return <div ref={chartContainerRef} />;
};
