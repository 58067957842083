import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { MdDeleteOutline, MdRefresh } from 'react-icons/md';

import {
    importProfilesAsync,
    setGetProfilesParams,
    getProfilesListAsync,
    resetListProfilesState,
    setSelectedRowsProfiles,
    importProfileFormulaAsync,
    deleteProfilesListAsync
} from '../../../stores/Demand';
import { MODAL_IDS } from '../../../modals';
import { ROUTES } from '../../../utils/config';
import { getProfilesHeadCells } from '../helpers';
import { ProfilesDataFE } from '../../../interfaces/uiv2';
import { ModalContext } from '../../../hooks/modalContext';
import { PageContainer } from '../../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { GenericTable, TableSearch } from '../../../components/BaseTable';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { ImportFileModal } from '../../../modals/ImportFileModal/ImportFileModal';
import { RemoveForecastContainer } from '../../../components/RemoveEntityContainer';
import { transformOrderByToSort } from '../../../components/BaseTable/GenericTable/helpers';

import style from '../../../features/forecast-list/forecast-list.module.scss';
import useStickyHeader from '../../../hooks/useStickyHeader';

const ProfilesList = () => {
    const dispatch = useAppDispatch();
    const { projectId = '' } = useParams();
    const { handleClickOpen } = useContext(ModalContext);

    const { user } = useAppSelector((state) => state.login);
    const { profilesFE, loading, getParams, selectedRows, pager, success } = useAppSelector(
        (state) => state.listProfiles
    );
    const { deleted } = useAppSelector((state) => state.deleteProfiles);

    const { t } = useTranslation();
    const headCells = getProfilesHeadCells(t);

    const [importOptions, setImportOptions] = useState({
        titleLabel: t('imports.importProfilesTitle'),
        subtitleLabel: t('imports.importProfilesSubtitle'),
        callback: (file: File) => dispatch(importProfilesAsync({ projectId, file }))
    });

    useEffect(() => {
        dispatch(getProfilesListAsync({ getParams, projectId }));
        return () => {
            dispatch(resetListProfilesState());
        };
    }, []);

    useEffect(() => {
        dispatch(getProfilesListAsync({ getParams, projectId }));
    }, [deleted, getParams, projectId]);

    const clearSelection = () => {
        dispatch(setSelectedRowsProfiles([]));
    };

    const openConfirmationModal = () => {
        handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    };

    const getProfilesPageUrl = (row: ProfilesDataFE) => () => {
        return `${ROUTES.AI_DEMAND_PROFILES_PAGE.replace(':projectId', projectId).replace(':id', row.id)}`;
    };

    const getFormulaPageUrl = (row: ProfilesDataFE) => () => {
        return `${ROUTES.AI_DEMAND_PROFILES_FORMULA_PAGE.replace(':projectId', projectId).replace(':id', row.id)}`;
    };

    const openImportModal = () => {
        handleClickOpen(MODAL_IDS.IMPORT_FILE_MODAL);
    };

    const removeProfiles = () => {
        dispatch(deleteProfilesListAsync({ ids: selectedRows.map((a) => a.id), projectId }));
        clearSelection();
    };

    const importProfileFormula = (profileId: string) => {
        setImportOptions({
            titleLabel: t('imports.importProfileFormulaTitle'),
            subtitleLabel: t('imports.importProfileFormulaSubtitle'),
            callback: (file: File) => dispatch(importProfileFormulaAsync({ projectId, profileId, file }))
        });

        openImportModal();
    };

    useStickyHeader('demand-profiles-list-header');

    return (
        <PageContainer>
            <Box id="demand-profiles-list-header" className={style.header}>
                <Typography variant="h6" className={style.title}>
                    {t('header.profiles')}
                </Typography>

                <Box className={style.toolbar}>
                    {!!selectedRows.length && (
                        <>
                            <Typography variant="small4" className={style.label}>
                                {selectedRows.length} {t('table.general.selected')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdRefresh />
                                <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                    {t('table.general.clearSelection')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdDeleteOutline />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={openConfirmationModal}
                                    sx={{ ml: 1 }}
                                >
                                    {t('table.general.removeAll')}
                                </Box>
                            </Typography>
                        </>
                    )}
                    <TableSearch setGetParams={setGetProfilesParams} getParams={getParams} />
                    {user?.superadmin ? (
                        <Button disableRipple variant="cta" onClick={openImportModal} sx={{ ml: 1 }}>
                            <UploadIcon />
                        </Button>
                    ) : null}
                </Box>
            </Box>

            <GenericTable
                pager={pager}
                rows={profilesFE}
                loading={loading}
                success={success}
                isCheckboxEnabled
                rowUniqueId={'id'}
                columns={headCells}
                selectedRows={selectedRows}
                searchParam={getParams.search}
                setGetParams={setGetProfilesParams}
                setSelectedRows={setSelectedRowsProfiles}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getProfilesPageUrl,
                    formula: getFormulaPageUrl,
                    importProfileFormula
                }}
            />

            <ImportFileModal {...importOptions} />
            <RemoveForecastContainer cb={removeProfiles} stateAccessor="deleteProfiles" />
        </PageContainer>
    );
};

export default ProfilesList;
