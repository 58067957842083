import { MdSave as DataManagementIcon } from 'react-icons/md';

import { ROUTES } from '../../utils/config';
import { hasAccessTo } from '../../utils/user';
import { PROJECT_MODULE, PROJECT_TYPE, User } from '../../interfaces/apiv2';

import { ReactComponent as VPPSVG } from '../../assets/icons/vpp.svg';
import { ReactComponent as ProfilesSVG } from '../../assets/icons/demand/profiles.svg';
import { ReactComponent as ClustersSVG } from '../../assets/icons/demand/clusters.svg';
import { ReactComponent as SegmentsSVG } from '../../assets/icons/demand/segments.svg';
import { ReactComponent as ConsumersSVG } from '../../assets/icons/demand/consumers.svg';
import { ReactComponent as EVSSVG } from '../../assets/icons/navigation-items/menu-item-evs.svg';
import { ReactComponent as NTLSVG } from '../../assets/icons/navigation-items/menu-item-ntl.svg';
import { ReactComponent as DashboardSVG } from '../../assets/icons/navigation-items/dashboard.svg';
import { ReactComponent as DemandSVG } from '../../assets/icons/navigation-items/menu-item-demand.svg';
import { ReactComponent as AnomalySVG } from '../../assets/icons/navigation-items/menu-item-anomaly.svg';
import { ReactComponent as ReportsIcon } from '../../assets/icons/navigation-items/dots-menu-reports.svg';
import { ReactComponent as UsersSVG } from '../../assets/icons/navigation-items/menu-item-admin-users.svg';
import { ReactComponent as ProsumersSVG } from '../../assets/icons/navigation-items/menu-item-prosumers.svg';
import { ReactComponent as ForecastsIcon } from '../../assets/icons/navigation-items/dots-menu-forecasts.svg';
import { ReactComponent as ClientsSVG } from '../../assets/icons/navigation-items/menu-item-admin-clients.svg';
import { ReactComponent as AccountsSVG } from '../../assets/icons/navigation-items/menu-item-admin-accounts.svg';
import { ReactComponent as EntitiesSVG } from '../../assets/icons/navigation-items/menu-item-admin-entities.svg';
import { ReactComponent as SettingsSVG } from '../../assets/icons/navigation-items/menu-item-admin-settings.svg';
import { ReactComponent as ProjectsSVG } from '../../assets/icons/navigation-items/menu-item-admin-projects.svg';
import { ReactComponent as TransmissionSVG } from '../../assets/icons/navigation-items/menu-item-transmission.svg';
import { ReactComponent as CirculationSVG } from '../../assets/icons/navigation-items/menu-item-tl-circulations.svg';
import { ReactComponent as NotificationIcon } from '../../assets/icons/navigation-items/dots-menu-notifications.svg';
import { ReactComponent as AdminAssetsSVG } from '../../assets/icons/navigation-items/menu-item-admin-generation.svg';
import { ReactComponent as ForecastingSVG } from '../../assets/icons/navigation-items/menu-item-admin-forecasting.svg';
import { ReactComponent as GenerationSVG } from '../../assets/icons/navigation-items/menu-item-generation-primary.svg';
import { ReactComponent as ChargingStationsSVG } from '../../assets/icons/navigation-items/menu-item-charging-stations.svg';

import style from './style.module.scss';

export type MenuItemProjectMetadata = {
    module: PROJECT_MODULE;
    type: PROJECT_TYPE;
};

export type MenuItems = {
    label: string;
    path: ROUTES;
    icon: any;
    disabled: boolean;
    requiredPermissions: string[];
    method: 'some' | 'every';
    // dictates if the item should be hidden in case there are no permissions
    shouldHide: boolean;
    // not implemented yet
    notReady?: boolean;
    children?: MenuItems[];
    projectMetadata?: MenuItemProjectMetadata;
};

export const MENU_ITEMS_AI_ELECTRICITY: MenuItems[] = [
    {
        label: 'dashboard',
        path: ROUTES.AI_DASHBOARD,
        icon: <DashboardSVG />,
        disabled: false,
        requiredPermissions: [
            'Electricity.Generation.Assets.READ',
            'EVS.ChargingStations.READ',
            'Electricity.Demand.DSO.READ'
        ],
        shouldHide: true,
        method: 'some'
    },
    {
        label: 'generation',
        path: ROUTES.AI_GENERATION_ASSETS,
        icon: <GenerationSVG />,
        disabled: false,
        requiredPermissions: ['Electricity.Generation.Assets.READ'],
        shouldHide: true,
        method: 'every'
    },
    {
        label: 'demand',
        path: ROUTES.AI_DEMAND_CONSUMERS,
        icon: <DemandSVG />,
        disabled: false,
        requiredPermissions: ['Electricity.Demand.DSO.READ'],
        shouldHide: true,
        method: 'every',
        projectMetadata: {
            module: PROJECT_MODULE.ELECTRICITY,
            type: PROJECT_TYPE.DEMAND_FORECAST
        },
        children: [
            {
                label: 'consumers',
                path: ROUTES.AI_DEMAND_CONSUMERS,
                icon: <ConsumersSVG />,
                disabled: false,
                requiredPermissions: ['Electricity.Demand.DSO.READ'],
                shouldHide: true,
                method: 'every',
                projectMetadata: {
                    module: PROJECT_MODULE.ELECTRICITY,
                    type: PROJECT_TYPE.DEMAND_FORECAST
                }
            },
            {
                label: 'prosumers',
                path: ROUTES.AI_PROSUMERS_LIST,
                icon: <ProsumersSVG />,
                disabled: false,
                requiredPermissions: ['Electricity.Demand.DSO.READ'],
                shouldHide: true,
                method: 'every',
                projectMetadata: {
                    module: PROJECT_MODULE.ELECTRICITY,
                    type: PROJECT_TYPE.DEMAND_FORECAST
                }
            },
            {
                label: 'profiles',
                path: ROUTES.AI_DEMAND_PROFILES,
                icon: <ProfilesSVG />,
                disabled: false,
                requiredPermissions: ['Electricity.Demand.DSO.READ'],
                shouldHide: true,
                method: 'every',
                projectMetadata: {
                    module: PROJECT_MODULE.ELECTRICITY,
                    type: PROJECT_TYPE.DEMAND_FORECAST
                }
            },
            {
                label: 'clusters',
                path: ROUTES.AI_DEMAND_CLUSTERS,
                icon: <ClustersSVG />,
                disabled: false,
                requiredPermissions: ['Electricity.Demand.DSO.READ'],
                shouldHide: true,
                method: 'every',
                projectMetadata: {
                    module: PROJECT_MODULE.ELECTRICITY,
                    type: PROJECT_TYPE.DEMAND_FORECAST
                }
            },
            {
                label: 'segments',
                path: ROUTES.AI_DEMAND_SEGMENTS,
                icon: <SegmentsSVG />,
                disabled: false,
                requiredPermissions: ['Electricity.Demand.DSO.READ'],
                shouldHide: true,
                method: 'every',
                projectMetadata: {
                    module: PROJECT_MODULE.ELECTRICITY,
                    type: PROJECT_TYPE.DEMAND_FORECAST
                }
            }
        ]
    },
    {
        label: 'evs',
        path: ROUTES.AI_EVS_CHARGHING_STATIONS,
        icon: <EVSSVG />,
        disabled: false,
        requiredPermissions: ['EVS.ChargingStations.READ', 'EVS.ChargingPoints.READ'],
        shouldHide: true,
        method: 'every',
        projectMetadata: {
            module: PROJECT_MODULE.ELECTRICITY,
            type: PROJECT_TYPE.EVS
        },
        children: [
            {
                label: 'charging-stations',
                path: ROUTES.AI_EVS_CHARGHING_STATIONS,
                icon: <ChargingStationsSVG />,
                disabled: false,
                requiredPermissions: ['EVS.ChargingStations.READ', 'EVS.ChargingPoints.READ'],
                shouldHide: true,
                method: 'every',
                projectMetadata: {
                    module: PROJECT_MODULE.ELECTRICITY,
                    type: PROJECT_TYPE.EVS
                }
            },
            {
                label: 'clusters',
                path: ROUTES.AI_EVS_CLUSTERS,
                icon: <ClustersSVG />,
                disabled: false,
                requiredPermissions: ['EVS.ChargingStations.READ', 'EVS.ChargingPoints.READ'],
                shouldHide: true,
                method: 'every',
                projectMetadata: {
                    module: PROJECT_MODULE.ELECTRICITY,
                    type: PROJECT_TYPE.EVS
                }
            }
        ]
    },
    {
        label: 'tl',
        path: ROUTES.AI_TL_GENERATION_ASSETS,
        icon: <TransmissionSVG />,
        disabled: true,
        requiredPermissions: ['Electricity.TL'],
        shouldHide: true,
        method: 'every',
        children: [
            {
                label: 'generation',
                path: ROUTES.AI_TL_GENERATION_ASSETS,
                icon: <GenerationSVG />,
                disabled: false,
                requiredPermissions: ['Electricity.TL'],
                shouldHide: true,
                method: 'every'
            },
            {
                label: 'consumers',
                path: ROUTES.AI_TL_CONSUMERS,
                icon: <ConsumersSVG />,
                disabled: false,
                requiredPermissions: ['Electricity.TL'],
                shouldHide: true,
                method: 'every'
            },
            {
                label: 'clusters',
                path: ROUTES.AI_TL_CLUSTERS,
                icon: <ClustersSVG />,
                disabled: false,
                requiredPermissions: ['Electricity.TL'],
                shouldHide: true,
                method: 'every'
            },
            {
                label: 'circulations',
                path: ROUTES.AI_TL_CIRCULATIONS,
                icon: <CirculationSVG />,
                disabled: false,
                requiredPermissions: ['Electricity.TL'],
                shouldHide: true,
                method: 'every'
            }
        ]
    },
    {
        label: 'anomaly',
        path: ROUTES.AI_ANOMALY,
        icon: <AnomalySVG />,
        requiredPermissions: ['Electricity.Anomaly'],
        shouldHide: true,
        method: 'every',
        disabled: true,
        notReady: true
    },
    {
        label: 'ntl',
        path: ROUTES.AI_NTL,
        icon: <NTLSVG />,
        requiredPermissions: ['Electricity.NTL'],
        shouldHide: true,
        method: 'every',
        disabled: true,
        notReady: true
    }
];

export const DOTS_MENU_ITEMS_AI_ELECTRICITY: MenuItems[] = [
    {
        label: 'header.vpp',
        icon: <VPPSVG />,
        path: ROUTES.AI_GENERATION_VPP,
        disabled: false,
        requiredPermissions: ['Electricity.Generation.VPPs.READ'],
        method: 'every',
        shouldHide: false
    },
    {
        label: 'header.forecasts',
        icon: <ForecastsIcon />,
        path: ROUTES.FORECASTS,
        disabled: false,
        requiredPermissions: ['Electricity.Generation.DeliveredForecasts.READ'],
        method: 'every',
        shouldHide: false
    },
    {
        label: 'header.evs_forecasts',
        icon: <ForecastsIcon />,
        path: ROUTES.EVS_FORECASTS,
        disabled: false,
        requiredPermissions: ['EVS.ChargingStations.READ', 'EVS.ChargingPoints.READ'],
        method: 'every',
        shouldHide: false
    },
    {
        label: 'header.reports',
        icon: <ReportsIcon />,
        path: ROUTES.REPORTS,
        disabled: false,
        requiredPermissions: ['Electricity.Generation.Reports.READ'],
        method: 'every',
        shouldHide: false
    },
    {
        label: 'header.evs_reports',
        icon: <ReportsIcon />,
        path: ROUTES.EVS_REPORTS,
        disabled: false,
        requiredPermissions: ['EVS.ChargingStations.READ', 'EVS.ChargingPoints.READ'],
        method: 'every',
        shouldHide: false,
        projectMetadata: {
            module: PROJECT_MODULE.ELECTRICITY,
            type: PROJECT_TYPE.EVS
        }
    },
    {
        label: 'header.notifications',
        icon: <NotificationIcon />,
        path: ROUTES.NOTIFICATIONS,
        disabled: false,
        requiredPermissions: [],
        method: 'every',
        shouldHide: false
    },
    {
        label: 'header.dataManagement',
        icon: <DataManagementIcon className={style.mdIcon} />,
        path: ROUTES.DATA_MANAGEMENT,
        disabled: false,
        requiredPermissions: [],
        method: 'every',
        shouldHide: false
    }
];

export const MENU_ITEMS_ADMIN_ELECTRICITY: MenuItems[] = [
    {
        label: 'dashboard',
        path: ROUTES.ADMIN_GENERATION,
        icon: <DashboardSVG />,
        disabled: true,
        notReady: true,
        requiredPermissions: [
            'Electricity.Generation.Assets.READ',
            'EVS.ChargingStations.READ',
            'Electricity.Demand.DSO.READ'
        ],
        shouldHide: false,
        method: 'some'
    },
    {
        label: 'accounts',
        path: ROUTES.ADMIN_ACCOUNTS_USERS,
        icon: <AccountsSVG />,
        disabled: false,
        requiredPermissions: [],
        shouldHide: false,
        method: 'some',
        children: [
            {
                label: 'users',
                path: ROUTES.ADMIN_ACCOUNTS_USERS,
                icon: <UsersSVG />,
                disabled: false,
                requiredPermissions: [],
                shouldHide: false,
                method: 'some'
            },
            {
                label: 'clients',
                path: ROUTES.ADMIN_ACCOUNTS_CLIENTS,
                icon: <ClientsSVG />,
                disabled: false,

                requiredPermissions: [],
                shouldHide: false,
                method: 'some'
            }
        ]
    },
    {
        label: 'entities',
        path: ROUTES.ADMIN_GENERATION_ASSETS,
        icon: <EntitiesSVG />,
        disabled: false,
        requiredPermissions: [],
        shouldHide: false,
        method: 'some',
        children: [
            {
                label: 'assets',
                path: ROUTES.ADMIN_GENERATION_ASSETS,
                icon: <AdminAssetsSVG />,
                disabled: false,
                requiredPermissions: [],
                shouldHide: false,
                method: 'some'
            },
            {
                label: 'projects',
                path: ROUTES.ADMIN_FORECASTING_PROJECTS,
                icon: <ProjectsSVG />,
                disabled: false,
                requiredPermissions: [],
                shouldHide: false,
                method: 'some'
            }
        ]
    },
    {
        label: 'settings',
        path: ROUTES.ADMIN_FORECASTING,
        icon: <SettingsSVG />,
        disabled: false,
        requiredPermissions: [],
        shouldHide: false,
        method: 'some',
        children: [
            {
                label: 'forecasting',
                path: ROUTES.ADMIN_FORECASTING_ASSETS,
                icon: <ForecastingSVG />,
                disabled: false,

                requiredPermissions: [],
                shouldHide: false,
                method: 'some'
            },
            {
                label: 'delivery',
                path: ROUTES.ADMIN_DELIVERY_ASSETS,
                icon: <ForecastingSVG />,
                disabled: false,

                requiredPermissions: [],
                shouldHide: false,
                method: 'some'
            }
        ]
    }
];

export const buildUserMenu = (user: User, menuItems: MenuItems[]) => {
    return menuItems.filter((menuItem) => {
        const hasAccess = hasAccessTo(user, menuItem.requiredPermissions, menuItem.method);
        menuItem.disabled = menuItem.notReady || !hasAccess;

        if (menuItem.children) {
            menuItem.children = buildUserMenu(user, menuItem.children);
        }

        return menuItem.shouldHide ? true : hasAccess;
    });
};

export const pathMatch = (basePath: string, variablePath: string) => {
    // eslint-disable-next-line
    const regex = new RegExp('^' + variablePath.replace(/\/:[^\/]+/g, '/[^/]+') + '$');
    return regex.test(basePath);
};
