import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { ForecastSettings, ForecastType, PatchForecastSettings, PostForecastSettings } from '../../interfaces/apiv2';

interface GenerationAssetForecastSettingsState extends DefaultInitialState {
    settings: {
        [ForecastType.Intraday]: ForecastSettings | null;
        [ForecastType.DayAhead]: ForecastSettings | null;
        [ForecastType.LongTerm]: ForecastSettings | null;
    };
}

const initialState: GenerationAssetForecastSettingsState = {
    loading: false,
    error: false,
    success: false,
    settings: {
        [ForecastType.Intraday]: null,
        [ForecastType.DayAhead]: null,
        [ForecastType.LongTerm]: null
    }
};

export const createGenerationForecastSettingsAsync = createAsyncThunk<
    ForecastSettings,
    { assetId: string; body: PostForecastSettings }
>('generationAssetForecastSettings/create', async ({ assetId, body }, { rejectWithValue }) => {
    try {
        const response = await axios.post<ForecastSettings>(
            `/electricity/generation/assets/${assetId}/forecast-settings?forecast_type=${body.forecast_type}`,
            body
        );

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const updateGenerationForecastSettingsAsync = createAsyncThunk<
    ForecastSettings,
    { assetId: string; body: PatchForecastSettings }
>('generationAssetForecastSettings/update', async ({ assetId, body }, { rejectWithValue }) => {
    try {
        const response = await axios.patch(
            `/electricity/generation/assets/${assetId}/forecast-settings?forecast_type=${body.forecast_type}`,
            body
        );

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const getGenerationForecastSettingsAsync = createAsyncThunk(
    'generationAssetForecastSettings/show',
    async ({ assetId, forecastType }: { assetId: string; forecastType: ForecastType }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ForecastSettings>(
                `/electricity/generation/assets/${assetId}/forecast-settings?forecast_type=${forecastType}`
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const generationAssetForecastSettings = createSlice({
    name: 'generationAssetForecastSettings',
    initialState,
    reducers: {
        resetGenerationAssetForecastSettingsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createGenerationForecastSettingsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(createGenerationForecastSettingsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.settings[payload.forecast_type] = payload;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(createGenerationForecastSettingsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(updateGenerationForecastSettingsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(updateGenerationForecastSettingsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.settings[payload.forecast_type] = payload;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(updateGenerationForecastSettingsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(getGenerationForecastSettingsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getGenerationForecastSettingsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.settings[payload.forecast_type] = payload;
        });
        builder.addCase(getGenerationForecastSettingsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
    }
});

export const { resetGenerationAssetForecastSettingsState } = generationAssetForecastSettings.actions;

export default generationAssetForecastSettings.reducer;
