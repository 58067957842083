import { isEqual } from 'lodash';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { MdAdd, MdDeleteOutline, MdOutlineRefresh } from 'react-icons/md';

import {
    getMarketsListAsync,
    resetListMarketState,
    setGetParamsMarkets,
    setSelectedMarkets
} from '../../stores/Markets';
import { MODAL_IDS } from '../../modals';
import { ROUTES } from '../../utils/config';
import { getTableHeadCells } from './helpers';
import { styleguide } from '../../containers/Theme';
import { ModalContext } from '../../hooks/modalContext';
import { PageContainer } from '../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../stores';
import { GenericTable, TableSearch } from '../../components/BaseTable';
import { transformOrderByToSort } from '../../components/BaseTable/GenericTable/helpers';

import style from '../../features/forecast-list/forecast-list.module.scss';
import useStickyHeader from '../../hooks/useStickyHeader';

export const MarketsList = () => {
    const { t } = useTranslation();
    const { t: tPricing } = useTranslation('pricing');
    const dispatch = useAppDispatch();
    const { handleClickOpen } = useContext(ModalContext);

    const getParams = useAppSelector((state) => state.listMarkets.getParams, isEqual);
    const { marketsFE, selectedMarkets, loading, pager, success } = useAppSelector((state) => state.listMarkets);

    const headCells = getTableHeadCells(t);

    useEffect(() => {
        dispatch(getMarketsListAsync(getParams));

        return () => {
            dispatch(resetListMarketState());
        };
    }, []);

    useEffect(() => {
        dispatch(getMarketsListAsync(getParams));
    }, [getParams]);

    const handleClearSelection = () => {};
    const handleDeleteSelected = () => {};

    const getMarketsPricingPageUrl = (row: any) => () => {
        return `${ROUTES.PRICE_FORECAST_PAGE.replace(':id', row.id)}`;
    };

    useStickyHeader('price-list-header');

    return (
        <PageContainer>
            <Box className={style.header} id="price-list-header">
                <Typography variant="h6" className={style.title}>
                    {tPricing('tableTitle')}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {!!selectedMarkets.length && (
                        <>
                            <Typography variant="small4" className={style.selectedLabel}>
                                {selectedMarkets.length} {t('selected')}
                            </Typography>
                            <Button
                                disableRipple
                                className={style.button}
                                variant="action"
                                onClick={handleClearSelection}
                                startIcon={
                                    <SvgIcon component={MdOutlineRefresh} sx={{ color: styleguide.primaryColor }} />
                                }
                            >
                                {t('clearSelection')}
                            </Button>
                            <Button
                                disableRipple
                                className={style.button}
                                variant="action"
                                onClick={handleDeleteSelected}
                                startIcon={
                                    <SvgIcon component={MdDeleteOutline} sx={{ color: styleguide.primaryColor }} />
                                }
                            >
                                {t('deleteSelected')}
                            </Button>
                        </>
                    )}
                    <TableSearch setGetParams={setGetParamsMarkets} />
                    <Button
                        disableRipple
                        variant="cta"
                        sx={{ ml: 1 }}
                        onClick={() => handleClickOpen(MODAL_IDS.CREATE_VPP)}
                    >
                        <MdAdd />
                    </Button>
                </Box>
            </Box>

            <GenericTable
                rows={marketsFE}
                pager={pager}
                loading={loading}
                success={success}
                isCheckboxEnabled
                columns={headCells}
                rowUniqueId={'id'}
                selectedRows={selectedMarkets}
                setGetParams={setGetParamsMarkets}
                searchParam={getParams.search}
                setSelectedRows={setSelectedMarkets}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getMarketsPricingPageUrl
                }}
            />
        </PageContainer>
    );
};
