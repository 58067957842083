import './utils/i18next';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import utc from 'dayjs/plugin/utc';
import React, { StrictMode } from 'react';
import { HeadProvider } from 'react-head';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import minMax from 'dayjs/plugin/minMax';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import App from './App';
import { store } from './stores';
import pkg from '../package.json';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import axios, { setResponseInterceptors } from './utils/axios';

import './assets/style/index.scss';
import 'react-toastify/dist/ReactToastify.css';

dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(isoWeek);
dayjs.extend(timezone);

if (process.env.REACT_APP_BUGSNAG) {
    Bugsnag.start({
        apiKey: process.env.REACT_APP_BUGSNAG,
        plugins: [new BugsnagPluginReact()],
        appVersion: pkg.version,
        releaseStage: process.env.REACT_APP_ENV
    });
}

class BaseErrorBoundary extends React.Component {
    render() {
        return this.props.children;
    }
}

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || BaseErrorBoundary;

setResponseInterceptors(axios);

ReactDOM.render(
    <StrictMode>
        <BrowserRouter>
            <ErrorBoundary>
                <Provider store={store}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <HeadProvider>
                            <ToastContainer />
                            <App />
                        </HeadProvider>
                    </LocalizationProvider>
                </Provider>
            </ErrorBoundary>
        </BrowserRouter>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
