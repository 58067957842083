import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { ROUTES } from '../../utils/config';
import { getTableHeadCells } from './helpers';
import { ImportedFileFE } from '../../interfaces/uiv2';
import { PageContainer } from '../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../stores';
import { GenericTable, TableSearch } from '../../components/BaseTable';
import { transformOrderByToSort } from '../../components/BaseTable/GenericTable/helpers';
import { downloadImportedFileData, getImportedFilesListAsync, setGetImportedFilesParams } from '../../stores/Projects';

import style from '../vpp-list/style.module.scss';
import useStickyHeader from '../../hooks/useStickyHeader';
import { PROJECT_MODULE } from '../../interfaces/apiv2';

const DataManagement = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { loading, importedFilesFE, pager, success, getParams } = useAppSelector((state) => state.listImportedFiles);

    useEffect(() => {
        dispatch(getImportedFilesListAsync(getParams));
    }, [getParams]);

    const headCells = getTableHeadCells({ t });

    const handleDownload = (type: 'output' | 'input') => (row: ImportedFileFE) => {
        const action = downloadImportedFileData({
            filename: row.file_name,
            id: row.id,
            project_id: row.project.id,
            type
        });
        dispatch(action({ ids: [row.id], type: row.file_type }));
    };

    const goToPage = (row: ImportedFileFE) => () => {
        if (row.project.module === PROJECT_MODULE.GAS) {
            navigate(`${ROUTES.AI_GAS_DEMAND_CONSUMERS.replace(':projectId', row.project.id)}`);
        }

        if (row.project.module === PROJECT_MODULE.ELECTRICITY) {
            navigate(`${ROUTES.AI_DEMAND_CONSUMERS.replace(':projectId', row.project.id)}`);
        }
    };

    useStickyHeader('data-management-list-header');

    return (
        <PageContainer>
            <Box className={style.headerRow} id={'data-management-list-header'}>
                <Typography variant="h6" className={style.title}>
                    {t('header.dataManagement')}
                </Typography>
                <TableSearch setGetParams={setGetImportedFilesParams} />
            </Box>
            <GenericTable
                rows={importedFilesFE}
                pager={pager}
                success={success}
                loading={loading}
                isCheckboxEnabled={false}
                selectableRows={false}
                columns={headCells}
                rowUniqueId={'id'}
                setGetParams={setGetImportedFilesParams}
                searchParam={getParams.search}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    downloadInput: handleDownload('input'),
                    downloadOutput: handleDownload('output'),
                    goToProject: goToPage
                }}
            />
        </PageContainer>
    );
};

export default DataManagement;
