import { Box } from '@mui/system';
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MdDeleteOutline } from 'react-icons/md';
import { MODAL_IDS } from '..';
import { FormErrorStep, FormModalActions, FormSuccessStep } from '../../components/FormUtils';

import style from './style.module.scss';

export interface ConfirmationFormProps {
    error: boolean;
    loading: boolean;
    success: boolean;
    label: string;
    cb: () => void;
    cleanup?: () => void;
}

const ConfirmationForm = ({ error, loading, success, label, cb, cleanup }: ConfirmationFormProps) => {
    const { t: generalTranslation } = useTranslation();

    useEffect(() => {
        return () => {
            cleanup && cleanup();
        };
    }, []);

    return (
        <>
            {!(success || error) ? (
                <Box className={style.box}>
                    <Box className={style.deleteIcon}>
                        <MdDeleteOutline />
                    </Box>
                    <Typography className={style.title} variant="h6">
                        {generalTranslation('table.general.delete')}
                    </Typography>
                    <Typography className={style.label} variant="body1">
                        {label}
                    </Typography>
                    <FormModalActions
                        loading={loading}
                        disabled={false}
                        onSubmit={cb}
                        modalId={MODAL_IDS.CONFIRMATION_MODAL}
                        cancelLabel={generalTranslation('nevermind')}
                        saveLabel={generalTranslation('form.doit')}
                    />
                </Box>
            ) : null}
            {success ? <FormSuccessStep /> : null}
            {error ? <FormErrorStep /> : null}
        </>
    );
};

export default ConfirmationForm;
