import { FC, useEffect, useRef } from 'react';
import { ColorType, createChart, IChartApi, ISeriesApi, LineStyle } from 'lightweight-charts';

import { downloadChart } from '../../stores/Assets';
import { useAppDispatch, useAppSelector } from '../../stores';
import { debounce, toFixed } from '../../utils/helpers';
import { GroupedBarsSeries } from './GroupedBar/grouped-bars-series';

export interface BarData {
    time: number;
    values: number[];
}

interface TradingViewChartProps {
    data: BarData[];
    colors: string[];
    fetchMore: (from: string) => void;
    measureUnit: string;
    limits?: boolean[];
    rawData?: any;
}

export const TradingViewBarChart: FC<TradingViewChartProps> = (props) => {
    const { data, colors, measureUnit, rawData } = props;

    const { backgroundColor, textColor } = {
        backgroundColor: '#fbfcff',
        textColor: 'rgba(0, 0, 0, 0.6)'
    };

    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<IChartApi | null>(null);
    const customSeries = useRef<any>(null);
    const dispatch = useAppDispatch();
    const { download } = useAppSelector((state) => state.assetPage);

    useEffect(() => {
        if (chartContainerRef.current) {
            const handleResize = () => {
                chart.applyOptions({ width: chartContainerRef?.current?.clientWidth });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: { type: ColorType.Solid, color: backgroundColor },
                    textColor,
                    fontFamily: 'Montserrat'
                },
                width: chartContainerRef?.current?.clientWidth,
                height: chartContainerRef?.current?.parentElement?.clientHeight || 380,
                timeScale: {
                    barSpacing: 16,
                    minBarSpacing: 8,
                    shiftVisibleRangeOnNewBar: false,
                    borderColor: '#D5E3FF',
                    visible: true,
                    timeVisible: false,
                    ticksVisible: false,
                    tickMarkFormatter: () => ''
                },
                grid: {
                    vertLines: {
                        color: '#D5E3FF'
                    },
                    horzLines: {
                        color: '#D5E3FF'
                    }
                },
                rightPriceScale: {
                    borderColor: '#D5E3FF'
                },
                crosshair: {
                    mode: 1,
                    vertLine: {
                        color: '#979797',
                        width: undefined,
                        style: LineStyle.Dashed
                    },
                    horzLine: {
                        color: '#979797',
                        width: undefined,
                        style: LineStyle.Dashed
                    }
                },
                localization: {
                    timeFormatter: () => '',
                    priceFormatter: (priceValue: any) => {
                        return `${toFixed(priceValue)} ${measureUnit}`;
                    }
                }
            });
            const customSeriesView = new GroupedBarsSeries();
            const myCustomSeries = chart.addCustomSeries(customSeriesView, {
                /* Options */
                priceLineVisible: false,
                lastValueVisible: false
            });
            myCustomSeries.setData(data as any);
            chart.clearCrosshairPosition();
            //myCustomSeries.attachPrimitive(new CrosshairHighlightPrimitive({ color: 'rgba(0, 100, 200, 0.2)' }));
            chartRef.current = chart;
            customSeries.current = myCustomSeries;
            const timeScale = chartRef?.current?.timeScale();
            timeScale?.fitContent();

            const toolTipWidth = 140;
            const toolTipHeight = 80;
            const toolTipMargin = 15;

            // Create and style the tooltip html element
            const toolTip = document.createElement('div');
            (
                toolTip as any
            ).style = `width: 200px; height: auto; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
            toolTip.style.background = 'white';
            toolTip.style.color = 'black';
            toolTip.style.borderColor = '#d5e3ff';
            chartContainerRef.current.appendChild(toolTip);

            // update tooltip
            chart.subscribeCrosshairMove((param) => {
                if (
                    param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.x > chartContainerRef.current!.clientWidth ||
                    param.point.y < 0 ||
                    param.point.y > chartContainerRef.current!.clientHeight
                ) {
                    toolTip.style.display = 'none';
                } else {
                    toolTip.style.display = 'block';
                    const element: any = param.seriesData.get(myCustomSeries);

                    toolTip.innerHTML = `<div style="color: #3745f2">${element.name}</div>
                      <div style="font-size: 12px; margin: 4px 0px; color: #6a9bff;">
                          Consum (profilare zilnică): ${element.values[0].toFixed(2)} m³
                      </div>
                      <div style="font-size: 12px; margin: 4px 0px; color: #6fd8b2 ;">
                      Predicție (profilare zilnică / intervale): ${element.values[1].toFixed(2)} m³
                      </div>
<div style="font-size: 12px; margin: 4px 0px; color: rgba(106,155,255,0.77);">
                      Consum (profilare zilnică meteo): ${element.values[2].toFixed(2)} m³
                      </div>
<div style="font-size: 12px; margin: 4px 0px; color:rgba(111,216,178,0.63)">
                      Predicție (profilare zilnică meteo / intrată): ${element.values[3].toFixed(2)} m³
                      </div>`;
                    const y = param.point.y;
                    let left = param.point.x + toolTipMargin;
                    if (left > chartContainerRef.current!.clientWidth - toolTipWidth) {
                        left = param.point.x - toolTipMargin - toolTipWidth;
                    }

                    let top = y + toolTipMargin;
                    if (top > chartContainerRef.current!.clientHeight - toolTipHeight) {
                        top = y - toolTipHeight - toolTipMargin;
                    }
                    toolTip.style.left = left + 'px';
                    toolTip.style.top = top + 'px';
                }
            });

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);

                chartRef?.current?.remove();
            };
        }

        return () => {
            chartContainerRef.current?.remove();
        };
    }, []);

    useEffect(() => {
        const timeScale = chartRef?.current?.timeScale();
        if (chartRef.current) {
            chartRef.current.applyOptions({
                timeScale: {
                    tickMarkFormatter: () => ''
                },
                localization: {
                    timeFormatter: () => ''
                }
            });

            const series = data.map((item, index) => {
                const s = chartRef?.current?.addBarSeries({
                    baseLineColor: colors[index]
                }) as ISeriesApi<'Bar'>;

                customSeries.current.setData(data);

                return s;
            });

            const timeHandler = debounce(() => {
                const logicalRange = timeScale?.getVisibleLogicalRange();

                if (logicalRange) {
                    if (logicalRange?.from < 0) {
                        props.fetchMore('');
                    }
                }
            }, 250);

            const logicalRange = timeScale?.getVisibleLogicalRange();

            if (logicalRange) {
                // if there are no bars before the logical range, it means the chart has a few data points and we need to fit the content.
                if (logicalRange?.from < 0) {
                    //timeScale?.fitContent();
                }
            }

            timeScale?.subscribeVisibleLogicalRangeChange(timeHandler);

            return () => {
                for (const s of series) {
                    if (s) {
                        chartRef?.current?.removeSeries(s);
                    }
                }

                timeScale?.unsubscribeVisibleLogicalRangeChange(timeHandler);
            };
        }
    }, [data, rawData, colors, chartRef]);

    useEffect(() => {
        if (download) {
            takeScreenshot();
        }
    }, [download]);

    const takeScreenshot = () => {
        const content = chartRef.current?.takeScreenshot();
        function blobCallback(iconName: string) {
            return (b: Blob | null) => {
                if (b) {
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style.display = 'none';
                    a.download = `${iconName}.png`;
                    a.href = window.URL.createObjectURL(b);
                    a.click();
                    a.remove();
                    dispatch(downloadChart(false));
                }
            };
        }
        content?.toBlob(blobCallback('chart'), 'image/png');
    };

    return <div ref={chartContainerRef} />;
};
