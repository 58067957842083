import i18next from 'i18next';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { PayloadAction, createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { GetParams } from '../../interfaces/general';
import ToastifyType from '../../utils/toastify-config';
import { getFormattedDate } from '../../utils/helpers';
import { BarData } from '../../components/TradingViewChart';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { ApiPaginationInfo, PaginatedApiResponse } from '../../interfaces/apiv2';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';

interface GasReportPageState extends DefaultInitialState {
    chartData: BarData[];
    data: ReportingData[];
    paginatedData: ReportFE[];
    pager: ApiPaginationInfo | null;
    getParams: GetParams;
}

export interface Report {
    from_timestamp: string;
    forecasted_division_profiled: number; // forecasted
    measured_division_profiled: number; // measured
    cluster_forecasted: number; // forecasted cluster
    volume_profiled_smart: number; // measured profiled
}

export interface ReportFE extends Report {
    index: number;
    name: string;
}

export interface ReportingData {
    consumer: {
        id: string;
        name: string;
    };
    report: Report[];
}

const initialState: GasReportPageState = {
    chartData: [],
    data: [],
    paginatedData: [],
    loading: false,
    error: false,
    success: false,
    pager: null,
    getParams: DEFAULT_GET_PARAMS_VALUE
};

export const getGasReportingAsync = createAsyncThunk(
    'gas/reporting/show',
    async (
        { from_datetime, to_datetime, project_id }: { from_datetime: string; to_datetime: string; project_id: string },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.get<PaginatedApiResponse<any>>(
                `/gas/demand/${project_id}/consumers/reporting`,
                {
                    params: {
                        project_id,
                        from_timestamp: from_datetime,
                        to_timestamp: to_datetime,
                        size: 100
                    }
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getGasReportingMoreAsync = createAsyncThunk(
    'gas/reporting/more-show',
    async (
        {
            from_datetime,
            to_datetime,
            project_id,
            page
        }: { from_datetime: string; to_datetime: string; project_id: string; page: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.get<PaginatedApiResponse<any>>(
                `/gas/demand/${project_id}/consumers/reporting`,
                {
                    params: {
                        project_id,
                        from_timestamp: from_datetime,
                        to_timestamp: to_datetime,
                        page,
                        size: 100
                    }
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const downloadGasReportAsync = createAsyncThunk(
    'gas_report_page/download',
    async (
        {
            id,
            projectId,
            to_date,
            from_date
        }: {
            id: string;
            projectId: string;
            to_date: string;
            from_date: string;
        },
        { rejectWithValue }
    ) => {
        toast.success(i18next.t('downloadStarted'), ToastifyType.success);
        try {
            const url = `/gas/demand/${projectId}/consumers/reporting/${id}/download`;
            const response = await axios.get(url, {
                responseType: 'blob'
            });

            fileDownload(
                response.data,
                `Ogre_Report_${getFormattedDate({ value: +from_date })}__${getFormattedDate({ value: +to_date })}.xlsx`
            );
            toast.success(i18next.t('downloadSuccess'), ToastifyType.success);
        } catch (e) {
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

const emptyData = {
    consumer: { name: '' },
    report: [{ measured: 0, forecasted: 0 }]
};

export const gasReportPage = createSlice({
    name: 'gasReportPage',
    initialState,
    reducers: {
        resetGasReportPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<any>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getGasReportingAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getGasReportingAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            const newData = [emptyData, ...payload.items];
            state.data = newData;
            state.paginatedData = payload.items.map((e: ReportingData, index) => {
                return {
                    index: index + 1,
                    name: e.consumer.name,
                    ...e.report[0]
                };
            });
            state.chartData = newData.map((e: ReportingData, index) => {
                return {
                    time: index,
                    name: e.consumer.name,
                    values: [
                        e.report[0].measured_division_profiled || 0,
                        e.report[0].forecasted_division_profiled || 0,
                        e.report[0].volume_profiled_smart,
                        e.report[0].cluster_forecasted
                    ]
                };
            });
            state.pager = {
                total: payload.total,
                page: payload.page,
                size: payload.size,
                pages: payload.pages
            };
        });
        builder.addCase(getGasReportingAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(getGasReportingMoreAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getGasReportingMoreAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            const stateData = [...current(state).data];
            stateData.shift();
            const newData = [emptyData, ...payload.items, ...stateData];
            state.data = newData;
            state.paginatedData = [...payload.items, ...stateData].map((e: ReportingData, index) => {
                return {
                    index: index + 1,
                    name: e.consumer.name,
                    ...e.report[0]
                };
            });
            state.chartData = newData.map((e: ReportingData, index) => {
                return {
                    time: index,
                    name: e.consumer.name,
                    values: [
                        e.report[0].measured_division_profiled || 0,
                        e.report[0].forecasted_division_profiled || 0,
                        e.report[0].volume_profiled_smart,
                        e.report[0].cluster_forecasted
                    ]
                };
            });
            state.pager = {
                total: payload.total,
                page: payload.page,
                size: payload.size,
                pages: payload.pages
            };
        });
        builder.addCase(getGasReportingMoreAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetGasReportPage, setGetParams: setGetParamsGasReportPage } = gasReportPage.actions;

export default gasReportPage.reducer;
