import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { generateStaticCard } from '../../utils/dashboard';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DashboardStaticItem, GenerationAssetFE, VPPFE } from '../../interfaces/uiv2';
import {
    Dashboard,
    DashboardItem,
    DeleteDashboardItem,
    GenerationAsset,
    PaginatedApiResponse,
    PatchDashboard,
    PostDashboard,
    PostDashboardItem
} from '../../interfaces/apiv2';

interface DashboardState extends DefaultInitialState {
    loadingCreate: boolean;
    errorCreate: boolean;
    successCreate: boolean;

    loadingDelete: boolean;
    errorDelete: boolean;
    successDelete: boolean;

    loadingLayout: boolean;
    errorLayout: boolean;
    successLayout: boolean;

    items: DashboardItem[];
    dashboards: Dashboard[];

    itemAsset: GenerationAssetFE | null;
    itemVPP: VPPFE | null;

    staticCard: DashboardStaticItem | null;

    loadingStaticCard: boolean;
    errorStaticCard: boolean;
    successStaticCard: boolean;
}

const initialState: DashboardState = {
    loading: false,
    error: false,
    success: false,

    loadingCreate: false,
    errorCreate: false,
    successCreate: false,

    loadingDelete: false,
    errorDelete: false,
    successDelete: false,

    loadingLayout: false,
    errorLayout: false,
    successLayout: false,

    items: [],
    dashboards: [],

    itemAsset: null,
    itemVPP: null,

    staticCard: null,

    loadingStaticCard: false,
    errorStaticCard: false,
    successStaticCard: false
};

export const getDashboardItemsAsync = createAsyncThunk(
    'dashboard_item/list',
    async (dashboardId: string, { rejectWithValue }) => {
        try {
            const response = await axios.get<DashboardItem[]>(`/dashboards/${dashboardId}/items`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const createDashboardItemAsync = createAsyncThunk(
    'dashboard_item/create',
    async ({ body, dashboardId }: { body: PostDashboardItem; dashboardId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post<DashboardItem, AxiosResponse<DashboardItem>, PostDashboardItem>(
                `/dashboards/${dashboardId}/items`,
                body
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const deleteDashboardItemAsync = createAsyncThunk(
    'dashboard_item/delete',
    async ({ id, dashboard_id }: DeleteDashboardItem, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`/dashboards/${dashboard_id}/items/${id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getDashboardLayoutAsync = createAsyncThunk<Dashboard[]>(
    'dashboard_layout/list',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get<Dashboard[]>('/dashboards');

            return data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getDashboardStaticItem = createAsyncThunk('dashboard_item/static_card', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axios.get<PaginatedApiResponse<GenerationAsset>>('/electricity/generation/assets');

        return data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const createDashboardLayoutAsync = createAsyncThunk(
    'dashboard_layout/create',
    async (values: PostDashboard, { rejectWithValue }) => {
        try {
            // FIXME: need a transform function
            const response = await axios.post<Dashboard, AxiosResponse<Dashboard>, PostDashboard>('/dashboards', {
                ...values,
                layout: JSON.stringify(values.layout) as unknown as PostDashboard['layout']
            });

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const updateDashboardLayoutAsync = createAsyncThunk(
    'dashboard_layout/update',
    async (values: PatchDashboard, { rejectWithValue }) => {
        try {
            const response = await axios.patch<Dashboard, AxiosResponse<Dashboard>, PatchDashboard['body']>(
                `/dashboards/${values.id}`,
                {
                    ...values.body,
                    layout: JSON.stringify(values.body.layout) as unknown as PostDashboard['layout']
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const dashboard = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        resetDashboardState: () => initialState,
        setItemAsset: (state, { payload }) => {
            state.itemAsset = payload;
        },
        setItemVPP: (state, { payload }) => {
            state.itemVPP = payload;
        },
        resetDashboardItems: (state) => {
            state.items = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDashboardItemsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getDashboardItemsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.items = payload;
        });
        builder.addCase(getDashboardItemsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(createDashboardItemAsync.pending, (state) => {
            state.loadingCreate = true;
            state.errorCreate = false;
            state.successCreate = false;
        });
        builder.addCase(createDashboardItemAsync.fulfilled, (state) => {
            state.loadingCreate = false;
            state.errorCreate = false;
            state.successCreate = true;
        });
        builder.addCase(createDashboardItemAsync.rejected, (state) => {
            state.loadingCreate = false;
            state.errorCreate = true;
            state.successCreate = false;
        });

        builder.addCase(deleteDashboardItemAsync.pending, (state) => {
            state.loadingDelete = true;
            state.errorDelete = false;
            state.successDelete = false;
        });
        builder.addCase(deleteDashboardItemAsync.fulfilled, (state) => {
            state.loadingDelete = false;
            state.errorDelete = false;
            state.successDelete = true;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(deleteDashboardItemAsync.rejected, (state) => {
            state.loadingDelete = false;
            state.errorDelete = true;
            state.successDelete = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(getDashboardStaticItem.pending, (state) => {
            state.loadingStaticCard = true;
            state.errorStaticCard = false;
            state.successStaticCard = false;
        });
        builder.addCase(getDashboardStaticItem.fulfilled, (state, { payload }) => {
            state.loadingStaticCard = false;
            state.errorStaticCard = false;
            state.successStaticCard = true;
            state.staticCard = generateStaticCard(payload.items);
        });
        builder.addCase(getDashboardStaticItem.rejected, (state) => {
            state.loadingStaticCard = false;
            state.errorStaticCard = true;
            state.successStaticCard = false;
        });

        builder.addCase(getDashboardLayoutAsync.pending, (state) => {
            state.loadingLayout = true;
            state.errorLayout = false;
            state.successLayout = false;
        });
        builder.addCase(getDashboardLayoutAsync.fulfilled, (state, { payload }) => {
            state.loadingLayout = false;
            state.errorLayout = false;
            state.successLayout = true;
            state.dashboards = payload;
        });
        builder.addCase(getDashboardLayoutAsync.rejected, (state) => {
            state.loadingLayout = false;
            state.errorLayout = true;
            state.successLayout = false;
        });

        builder.addCase(createDashboardLayoutAsync.pending, (state) => {
            state.loadingCreate = true;
            state.errorCreate = false;
            state.successCreate = false;
        });
        builder.addCase(createDashboardLayoutAsync.fulfilled, (state, { payload }) => {
            state.loadingCreate = false;
            state.errorCreate = false;
            state.successCreate = true;
            state.dashboards = [payload, ...current(state).dashboards].filter(
                (value, index, self) => index === self.findIndex((t) => t.id === value.id)
            );
        });
        builder.addCase(createDashboardLayoutAsync.rejected, (state) => {
            state.loadingCreate = false;
            state.errorCreate = true;
            state.successCreate = false;
        });

        builder.addCase(updateDashboardLayoutAsync.pending, (state) => {
            state.loadingCreate = true;
            state.errorCreate = false;
            state.successCreate = false;
        });
        builder.addCase(updateDashboardLayoutAsync.fulfilled, (state, { payload }) => {
            state.loadingCreate = false;
            state.errorCreate = false;
            state.successCreate = true;
            state.dashboards = [payload, ...current(state).dashboards].filter(
                (value, index, self) => index === self.findIndex((t) => t.id === value.id)
            );
        });
        builder.addCase(updateDashboardLayoutAsync.rejected, (state) => {
            state.loadingCreate = false;
            state.errorCreate = true;
            state.successCreate = false;
        });
    }
});

export const { resetDashboardState, setItemAsset, setItemVPP, resetDashboardItems } = dashboard.actions;

export default dashboard.reducer;
