import i18next from 'i18next';
import { toast } from 'react-toastify';
// import fileDownload from 'js-file-download';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import {
    ApiPaginationInfo,
    DeliveredForecast,
    GasDeliveredForecast,
    PaginatedApiResponse
} from '../../interfaces/apiv2';
import axios from '../../utils/axios';
import { GetParams } from '../../interfaces/general';
import ToastifyType from '../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { getQueryParamsFromObject } from '../../utils/table';
import { getDownloadThunk } from '../../utils/common-thunks';
// import { DownloadExtension } from '../../components/DownloadButton';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DeliveredForecastFE, GasDeliveredForecastFE } from '../../interfaces/uiv2';
import { DefaultInitialState, DEFAULT_GET_PARAMS_VALUE } from '../../interfaces/redux';
import { transformDeliveredForecastsAPItoFE } from './helpers';

export interface ForecastListGetParams extends GetParams {
    start_date?: string;
    end_date?: string;
    from_date__gte?: string;
    to_date__lte?: string;
}

interface ForecastListStore extends DefaultInitialState {
    forecasts: DeliveredForecast[];
    gasForecasts: GasDeliveredForecast[];
    forecastsFE: DeliveredForecastFE[];
    gasForecastsFE: GasDeliveredForecastFE[];
    searchParam: string | null;
    pager: ApiPaginationInfo | null;
    getParams: ForecastListGetParams;
    selectedRows: DeliveredForecastFE[];

    loadingDownload: boolean;
    activeDownloadMeta: { ids: string[]; type: string } | null;
}

export const getForecastsListAsync = createAsyncThunk(
    'evs_forecasts_list/get',
    async (values: ForecastListGetParams, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...values });
            const response = await axios.get<PaginatedApiResponse<DeliveredForecast>>(
                `/evs/delivered-forecasts${queryParams}`
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const downloadForecast = getDownloadThunk({
    thunkName: 'forecast_list/download',
    endpoint: '/evs/delivered-forecasts/files/download',
    bodyPropName: 'forecast_ids',
    filename: 'forecast'
});

const initialState: ForecastListStore = {
    loading: false,
    error: false,
    success: false,
    forecasts: [],
    gasForecasts: [],
    forecastsFE: [],
    gasForecastsFE: [],
    searchParam: null,
    pager: null,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    selectedRows: [],

    loadingDownload: false,
    activeDownloadMeta: null
};

export const listEVSForecasts = createSlice({
    name: 'listEVSForecasts',
    initialState,
    reducers: {
        resetForecastsList: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastListGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<DeliveredForecastFE[]>) => {
            state.selectedRows = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getForecastsListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getForecastsListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.forecasts = items;
            } else {
                state.forecasts = [...current(state).forecasts, ...items];
            }

            state.forecastsFE = transformDeliveredForecastsAPItoFE(current(state).forecasts);
        });
        builder.addCase(getForecastsListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(downloadForecast.pending, (state, { meta }) => {
            state.loadingDownload = true;
            state.activeDownloadMeta = meta.arg;
        });
        builder.addCase(downloadForecast.fulfilled, (state) => {
            state.loadingDownload = false;
            state.activeDownloadMeta = null;
        });
        builder.addCase(downloadForecast.rejected, (state) => {
            state.loadingDownload = false;
            state.activeDownloadMeta = null;
        });
    }
});

export const { resetForecastsList, setSelectedRows, setGetParams } = listEVSForecasts.actions;

export default listEVSForecasts.reducer;
