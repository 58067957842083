import { Box } from '@mui/material';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../../layout/Loading';
import { GenericTable } from '../BaseTable';
import { ApiPaginationInfo } from '../../interfaces/apiv2';
import GenerationTableToolbar from '../GenerationTable/GenerationTableToolbar';

import style from '../GenerationTable/style.module.scss';

const MemoTable = ({
    data,
    loading,
    pager,
    success,
    headCells,
    renderFooterContent,
    fetchMoreFn,
    setTablePayload,
    timezone,
    setGetParams,
    className
}: {
    className?: string;
    data: any[];
    loading: boolean;
    success: boolean;
    error: any;
    pager: ApiPaginationInfo | null;
    headCells: any;
    renderFooterContent: any;
    setTablePayload?: any;
    timezone?: string;
    fetchMoreFn?: (payload: any) => void;
    setGetParams: any;
}) => {
    const { t } = useTranslation();
    const tableFooter = useMemo(() => renderFooterContent(data, t), [data]);

    // TODO: implement sort on table when api is working or only client side
    const sort = {};

    return (
        <Box className={classNames(style.wrapper, className)}>
            {fetchMoreFn ? (
                <GenerationTableToolbar
                    fetchMoreFn={fetchMoreFn}
                    setTablePayload={setTablePayload}
                    timezone={timezone}
                />
            ) : null}

            <Box className={style.generationTableContainer}>
                {loading ? (
                    <Loading />
                ) : (
                    <GenericTable
                        pager={pager}
                        className={classNames(style.generationTable, fetchMoreFn ? '' : style.smallTable)}
                        rows={data}
                        loading={loading}
                        success={success}
                        setGetParams={setGetParams}
                        columns={headCells}
                        rowUniqueId={'index'}
                        storeSort={sort}
                        customFooter={tableFooter}
                        pagination={false}
                    />
                )}
            </Box>
        </Box>
    );
};

export const MemoizedGenericTable = React.memo(MemoTable);

export default MemoizedGenericTable;
