import { TFunction } from 'i18next';
import Flag from 'react-world-flags';
import ct from 'countries-and-timezones';
import { Typography } from '@mui/material';
import { DefaultSearchableCell } from '../BaseTable';
import { getAssetIcon } from '../../utils/getAssetIcon';
import { GenerationAsset } from '../../interfaces/apiv2';
import { TableCellRenderer } from '../../interfaces/general';
import { getFormattedCapacityValue } from '../../utils/helpers';

export const transformer = (apiData: GenerationAsset[], vppRows: GenerationAsset[]): GenerationAsset[] => {
    return apiData.filter((row) => {
        return !vppRows.some((asset) => asset.id === row.id);
    });
};

enum COLUMN_ID {
    INDEX = 'index',
    TYPE = 'type',
    NAME = 'name',
    COUNTRY = 'country',
    TOTAL_CAPACITY = 'capacity_value'
}

interface AssetListHeadCellsProps {
    tList: TFunction;
    t: TFunction;
    isAdmin?: boolean;
    disabledSort?: boolean;
}
export const getTableHeadCells = ({
    tList,
    disabledSort
}: AssetListHeadCellsProps): TableCellRenderer<GenerationAsset>[] => {
    return [
        {
            id: COLUMN_ID.INDEX,
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row, a, b, c, i) => {
                const index = i! + 1;
                return <Typography variant="small4">{index! < 10 ? `0${index}` : `${index}`}</Typography>;
            },
            sort: false
        },
        {
            id: COLUMN_ID.TYPE,
            maxWidth: 48,

            label: tList('tableHead.type'),
            value: (row) => {
                return getAssetIcon(row.type);
            },
            sort: false
        },
        {
            id: COLUMN_ID.NAME,
            label: tList('tableHead.name'),
            sort: disabledSort ? false : true,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.name;
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;

                return <DefaultSearchableCell value={value} searchValue={searchValue} onClick={callback} />;
            }
        },
        {
            id: COLUMN_ID.COUNTRY,
            justifyContent: 'center',
            label: tList('tableHead.country'),
            value: (row, searchValue) => {
                const country = row.country_code ? ct.getCountry(row.country_code.toUpperCase()) : '';
                const name = country ? country.name : '';
                return (
                    <DefaultSearchableCell value={name} searchValue={searchValue}>
                        <Flag height="24" width="24" code={name} style={{ marginRight: '8px' }} className="flagIcon" />
                    </DefaultSearchableCell>
                );
            },
            sort: false
        },
        {
            id: COLUMN_ID.TOTAL_CAPACITY,
            label: tList('tableHead.totalCapacity'),
            justifyContent: 'center',
            sort: false,
            value: (row, searchValue) => {
                const value = getFormattedCapacityValue(row.capacity_value);
                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            }
        }
    ];
};
