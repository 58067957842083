export interface IngestLocationFormFields {
    longitude?: number;
    latitude?: number;
    startDate: string;
    endDate: string;
    model: INGEST_MODEL;
    type: INGEST_TYPE;
}

export interface Coordinates {
    latitude: number;
    longitude: number;
}

export interface PostIngestLocationsBody {
    historical: boolean;
    model: INGEST_MODEL;
    location_type: string;
    coordinates: Coordinates[];
    start_date: string;
    end_date: string;
    table_name: string;
    dataset_name: string;
}

export enum INGEST_MODEL {
    ECMWF = 'ecmwf-ifs',
    MIX = 'mix'
}

export enum INGEST_TYPE {
    FORECAST = 'forecast',
    HISTORICAL = 'historical'
}
