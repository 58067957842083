import { ProjectsDataFE } from './projects';
import { GenerationAssetFE } from './generation_asset';

import { ImportedFile } from '../apiv2';

export enum APP_MODULES {
    ADMIN = 'ADMIN',
    EMS = 'EMS',
    AI = 'AI',
    AI_GAS = 'AI-GAS'
}

export type PermissionItem = ProjectsDataFE | GenerationAssetFE;

export interface ImportedFileFE extends ImportedFile {
    index: number;
}
