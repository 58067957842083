import { DialogContent } from '@mui/material';
import { FC } from 'react';

import { ASSET_TYPE } from '../../interfaces/uiv2';
import { MODAL_IDS } from '..';
import Cleanup from '../../components/Cleanup/Cleanup';
import { resetCreateAssetState } from '../../stores/Assets';
import { useAppDispatch, useAppSelector } from '../../stores';
import { FormModal } from '../../components/BaseModal/FormModal';
import { FormErrorStep, FormSuccessStep } from '../../components/FormUtils';
import GenerationAssetForm from '../../components/GenerationAssetForm/GenerationAssetForm';

import style from './index.module.scss';

const CreateAssetModal: FC<{ type: ASSET_TYPE }> = ({ type }) => {
    const dispatch = useAppDispatch();
    const { success, error } = useAppSelector((state) => state.createAsset);

    const handleCleanup = () => {
        dispatch(resetCreateAssetState());
    };

    return (
        <FormModal id={MODAL_IDS.CREATE_ASSET_MODAL} fullWidth={false} maxWidth="lg">
            <DialogContent className={style.modalContainer}>
                {!(success || error) ? <GenerationAssetForm type={type} /> : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
                {/* needed because create asset modal never unmounts but what is inside of dialog content yes */}
                <Cleanup cb={handleCleanup} />
            </DialogContent>
        </FormModal>
    );
};

export { CreateAssetModal };
