import i18next from 'i18next';
import { toast } from 'react-toastify';
import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import ToastifyType from '../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo, Report } from '../../interfaces/apiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { ForecastDataGetParams, ForecastFE, ReportDataFE } from '../../interfaces/uiv2';
import { transformGenerationReportToChart, transformGenerationReportToView } from '../../utils/timeseries-data';

interface IForecastPage extends DefaultInitialState {
    data: ForecastFE[];
    paginatedData: ReportDataFE[];
    chartData: LineData[][];
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
    report: Report | null;
    reportData: any[];
    loadingData: boolean;
    errorGettingData: boolean;
}

const initialState: IForecastPage = {
    data: [],
    paginatedData: [],
    chartData: [[], []],
    pager: null,

    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        order_by: undefined
    },
    report: null,
    reportData: [],
    loadingData: false,
    errorGettingData: false,
    loading: false,
    error: false,
    success: false
};

export const getReportAsync = createAsyncThunk('reports/page', async (id: string, { rejectWithValue }) => {
    try {
        const endpoint = `/electricity/generation/assets/reports/${id}`;
        const response = await axios.get<Report>(endpoint);

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const getReportDataAsync = createAsyncThunk('reports/pageData', async (id: string, { rejectWithValue }) => {
    try {
        const endpoint = `/electricity/generation/assets/reports/${id}/data`;
        const response = await axios.get<any>(endpoint, {
            cache: {
                ttl: 1000 * 60 * 15
            }
        });

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const reportPage = createSlice({
    name: 'reportPage',
    initialState,
    reducers: {
        resetReportPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setActiveReport: (state, { payload }: PayloadAction<Report | null>) => {
            state.report = payload;
        },
        loadMore: (state) => {
            const { reportData, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: reportData.length
            };
            state.paginatedData = reportData.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getReportAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getReportAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.report = payload;
        });
        builder.addCase(getReportAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getReportDataAsync.pending, (state) => {
            state.loadingData = true;
            state.errorGettingData = false;
            state.success = false;
        });
        builder.addCase(getReportDataAsync.fulfilled, (state, { payload }) => {
            state.loadingData = false;
            state.errorGettingData = false;
            state.success = true;

            // const reportData = transformReportDataToFE(payload);
            // state.reportData = reportData;
            // /* FIXME: fix the type here */
            // state.chartData = transformReportDataToChart(reportData, state.report!.asset as unknown as any);
            const reportData = transformGenerationReportToView({
                data: payload,
                totalCapacity: current(state).report?.asset.capacity_value || 0
            });
            state.reportData = reportData;
            state.chartData = transformGenerationReportToChart({
                data: reportData,
                totalCapacity: current(state).report?.asset.capacity_value || 0,
                country: current(state).report?.asset.country_code
            });

            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).reportData.length
            };
            state.paginatedData = current(state).reportData.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
        });
        builder.addCase(getReportDataAsync.rejected, (state) => {
            state.loadingData = false;
            state.errorGettingData = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetReportPage, setActiveReport, setGetParams: setGetParamsReportPage, loadMore } = reportPage.actions;

export default reportPage.reducer;
