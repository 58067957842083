import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo, ChargingPoints } from '../../interfaces/apiv2';
import { transformChargingPointsDataToFE } from './helpers';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { sortForecastData, transformForecastToTable, transformForecastToView } from '../../utils/forecast';
import { ChargingPointsFE, FORECAST_TYPE, ForecastDataGetParams, ForecastFE } from '../../interfaces/uiv2';
import { DownloadExtension } from '../../components/DownloadButton';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import ToastifyType from '../../utils/toastify-config';
import { getQueryParamsFromObject } from '../../utils/table';
import fileDownload from 'js-file-download';

interface IEVSChargingPointPage extends DefaultInitialState {
    chargingPoint: ChargingPointsFE | null;
    forecastSettings: any;
    data: ForecastFE[];
    paginatedData: ForecastFE[];
    chartData: LineData[][];
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
}

const initialState: IEVSChargingPointPage = {
    chargingPoint: null,
    forecastSettings: null,
    data: [],
    paginatedData: [],
    chartData: [[], []],
    loading: false,
    error: false,
    success: false,
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        order_by: undefined
    },
    pager: null
};

export const getEVSChargingPointAsync = createAsyncThunk(
    'chargingPoint/show',
    async ({ id, projectId, stationId }: { id: string; projectId: string; stationId: string }, { rejectWithValue }) => {
        try {
            const endpoint = `/evs/${projectId}/charging-stations/${stationId}/charging-points`;
            const response = await axios.get<ChargingPoints>(`${endpoint}/${id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getEVSForecastSettingsAsync = createAsyncThunk(
    'chargingPoint_forecast_settings/show',
    async ({ chargingPoint, projectId }: { chargingPoint: string; projectId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<any>(
                `/evs/${projectId}/charging-points/${chargingPoint}/forecast-settings`
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getEVSChargingPointForecastAsync = createAsyncThunk(
    'chargingPoint_forecast/get',
    async (
        {
            projectId,
            chargingPoint,
            forecastSettingId,
            from,
            to
        }: { chargingPoint: string; projectId: string; forecastSettingId: string; from: number; to: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.get<ForecastFE[]>(
                `/evs/${projectId}/charging-points/${chargingPoint}/forecast-settings/${forecastSettingId}/forecast`,
                {
                    params: {
                        from_datetime: from,
                        to_datetime: to,
                        zerofill: true
                    }
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getEVSChargingPointHistoricalAsync = createAsyncThunk(
    'chargingPoint_historical/get',
    async (
        {
            projectId,
            chargingPoint,
            forecastSettingId,
            from,
            to
        }: { chargingPoint: string; projectId: string; forecastSettingId: string; from: number; to: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.get<ForecastFE[]>(
                `/evs/${projectId}/charging-points/${chargingPoint}/forecast-settings/${forecastSettingId}/historical`,
                {
                    params: {
                        from_datetime: from,
                        to_datetime: to,
                        zerofill: true
                    }
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const downloadEVSForecast = createAsyncThunk(
    'evs_cp_page/download',
    async (
        {
            chargingPoint,
            forecast_settings_id,
            charging_point_id,
            project_id,
            file_type,
            from_datetime,
            to_datetime
        }: {
            chargingPoint: ChargingPointsFE;
            forecast_settings_id: string;
            charging_point_id: string;
            project_id: string;
            file_type: DownloadExtension;
            from_datetime: number;
            to_datetime: number;
        },
        { rejectWithValue }
    ) => {
        toast.success(i18next.t('downloadStarted'), ToastifyType.success);
        try {
            const url = `/evs/${project_id}/charging-points/${charging_point_id}/forecast-settings/${forecast_settings_id}/forecast/download${getQueryParamsFromObject(
                {
                    file_type,
                    from_datetime,
                    to_datetime
                }
            )}`;
            const response = await axios.get(url, {
                responseType: 'blob'
            });

            const extension = file_type === DownloadExtension.Excel ? 'xlsx' : file_type.toLowerCase();

            fileDownload(
                response.data,
                `${chargingPoint.name}__${new Date(Number(from_datetime)).toISOString()}__${new Date(
                    Number(to_datetime)
                ).toISOString()}.${extension}`
            );
            toast.success(i18next.t('downloadSuccess'), ToastifyType.success);
        } catch (e) {
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const chargingPointPage = createSlice({
    name: 'chargingPointPage',
    initialState,
    reducers: {
        resetEVSChargingPointPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setActiveEVSChargingPoint: (state, { payload }: PayloadAction<ChargingPointsFE | null>) => {
            state.chargingPoint = payload;
        },
        loadMore: (state) => {
            const { data, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: data.length
            };
            state.paginatedData = data.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEVSChargingPointForecastAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSChargingPointForecastAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.data = transformForecastToTable(
                payload.map((x) => ({
                    ...x,
                    value: x.value * 1000
                })) as any
            );
            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).data.length
            };
            state.paginatedData = current(state).data.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
            state.chartData = [
                transformForecastToView(
                    payload.map((x) => ({
                        ...x,
                        value: x.value * 1000
                    })) as any,
                    FORECAST_TYPE.ELECTRICITY
                ),
                []
            ];
        });
        builder.addCase(getEVSChargingPointForecastAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });

        builder.addCase(getEVSForecastSettingsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSForecastSettingsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.forecastSettings = payload;
        });
        builder.addCase(getEVSForecastSettingsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });

        builder.addCase(getEVSChargingPointHistoricalAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSChargingPointHistoricalAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const actualData = (current(state).chartData || [])[1] || [];

            if (payload.length) {
                state.chartData[1] = sortForecastData([
                    ...transformForecastToView(
                        payload.map((x) => ({
                            ...x,
                            value: x.value * 1000
                        })) as any,
                        FORECAST_TYPE.ELECTRICITY
                    ),
                    ...actualData
                ]);
            }
        });
        builder.addCase(getEVSChargingPointHistoricalAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });

        builder.addCase(getEVSChargingPointAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSChargingPointAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.chargingPoint = transformChargingPointsDataToFE([payload])[0];
        });
        builder.addCase(getEVSChargingPointAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
    }
});

export const {
    resetEVSChargingPointPage,
    setActiveEVSChargingPoint,
    setGetParams: setGetEVSChargingPointParams,
    loadMore: loadMoreEVSChargingPointData
} = chargingPointPage.actions;

export default chargingPointPage.reducer;
