import { toast } from 'react-toastify';
import axios from '../../../../utils/axios';
import i18next from '../../../../utils/i18next';
import ToastifyType from '../../../../utils/toastify-config';
import { DefaultInitialState } from '../../../../interfaces/redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { PostGasProfile } from '../../../../interfaces/apiv2';

interface createGasProfile extends DefaultInitialState {
    added: 0;
}

const initialState: createGasProfile = {
    loading: false,
    error: false,
    success: false,
    added: 0
};

export const postGasProfileAsync = createAsyncThunk(
    'gas-profiles/post',
    async ({ projectId, payload }: { payload: PostGasProfile; projectId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`/gas/demand/${projectId}/profiles`, payload);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const createProfiles = createSlice({
    name: 'createProfiles',
    initialState,
    reducers: {
        resetCreateProfilesState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(postGasProfileAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(postGasProfileAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.added += 1;
        });
        builder.addCase(postGasProfileAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetCreateProfilesState } = createProfiles.actions;

export default createProfiles.reducer;
