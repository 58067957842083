import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { JobTitle, PaginatedApiResponse } from '../../interfaces/apiv2';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';

interface JobTitleState extends DefaultInitialState {
    jobTitles: JobTitle[];
}

const initialState: JobTitleState = {
    loading: false,
    error: false,
    success: false,
    jobTitles: []
};

export const getJobTitlesAsync = createAsyncThunk('jobTitles/list', async (values, { rejectWithValue }) => {
    try {
        // FIXME:
        const response = await axios.get<PaginatedApiResponse<JobTitle>>('/job-titles?page=1&size=100');

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const jobTitles = createSlice({
    name: 'jobTitles',
    initialState,
    reducers: {
        resetJobTitlesState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getJobTitlesAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getJobTitlesAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.jobTitles = payload.items;
        });
        builder.addCase(getJobTitlesAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetJobTitlesState } = jobTitles.actions;

export default jobTitles.reducer;
