import classNames from 'classnames';
import Flag from 'react-world-flags';
import { MdAdd } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { setGetParamsAssetPage, setGenerationTablePayloadTemp } from '../../stores/Assets';
import { MODAL_IDS } from '../../modals';
import Loading from '../../layout/Loading';
import { NO_VALUE, ROUTES } from '../../utils/config';
import { TAB } from '../../interfaces/general';
import { ModalContext } from '../../hooks/modalContext';
import { TableAction, TableActions } from '../../components/BaseTable';
import { TradingView } from '../../components/TradingView';
import { useAppSelector } from '../../stores';
import { AssetLocationModal } from '../../modals/AssetLocationModal';
import { ReactComponent as GraphIcon } from '../../assets/icons/timeline.svg';
import { ReactComponent as ViewList } from '../../assets/icons/view_list.svg';
import { ReactComponent as LocationSvg } from '../../assets/icons/location.svg';
import AssetStatusLabel from '../../components/AssetStatusLabel/AssetStatusLabel';
import { MemoizedGenerationTable } from '../../components/GenerationTable/GenerationTable';
import { getFormattedCapacityValue, getFormattedDate } from '../../utils/helpers';
import { getAssetIcon } from '../../utils/getAssetIcon';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../components/EntityPageToolbar';
import { mockedDemandChartData as chartData, mockedDemandForecast } from '../../stores/Demand/mocked-demand-chart';

import style from './asset-page.module.scss';
import { staticVPPAssets } from '../vpp-list/helpers';
import { transformForecastToTable } from '../../utils/forecast';

const MockAssetPage = () => {
    const query = useParams();
    const { t: generalTranslation } = useTranslation();

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;

    const paginatedData = transformForecastToTable(mockedDemandForecast.slice(0, 100), 'RO');

    const { pager, errorForecast, errorMeasured, loadingForecast, loadingMeasured, successForecast } = useAppSelector(
        (state) => state.assetPage
    );
    const { user } = useAppSelector((state) => state.login);
    const { selectedRows: selectedLocations } = useAppSelector((state) => state.listAssetLocations);

    const { handleClickOpen } = useContext(ModalContext);

    const asset = staticVPPAssets.find((e) => e.id === query!.id);

    if (!asset) {
        return <Loading className={style.loading} />;
    }

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    const handleLocationOpen = () => {
        handleClickOpen(MODAL_IDS.ASSET_LOCATION_MODAL);
    };

    const getForecastSettingsUrl = () => {
        return ROUTES.ADMIN_FORECASTING_ASSETS.replace(':assetId', asset.id);
    };

    return (
        <>
            <Box className={classNames(style.pageContainer)}>
                <Box className={style.headerContainer}>
                    <Box className={style.header}>
                        {getAssetIcon(asset.type as any)}
                        <Typography className={style.title} variant="h6">
                            {asset.name}
                        </Typography>
                        <Box className={style.subtitle} sx={{ ml: 2 }}>
                            <Box className={style.dot} />
                            <Typography variant="small1"> {generalTranslation(pageTitle)}</Typography>
                        </Box>
                    </Box>
                    <Box className={style.toolbarContainer}>
                        {!!selectedLocations.length && (
                            <Button
                                disableRipple
                                className={style.button}
                                variant="cta"
                                sx={{ mr: 1 }}
                                onClick={() => handleClickOpen(MODAL_IDS.INGEST_MULTIPLE_LOCATION_COORDINATES)}
                            >
                                <Typography variant="small4">{generalTranslation('table.general.ingest')}</Typography>
                            </Button>
                        )}
                        {tab === TAB.ASSET_LOCATIONS && user?.superadmin && (
                            <Button
                                disableRipple
                                className={style.button}
                                variant="cta"
                                sx={{ mr: 1 }}
                                onClick={() => handleClickOpen(MODAL_IDS.ADD_LOCATION_COORDINATES)}
                            >
                                <MdAdd />
                            </Button>
                        )}
                        <EntityPageToolbar>
                            <EntityPageToolbarButton
                                tabId={TAB.CHART}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.chart')}
                            >
                                <GraphIcon className={style.icon} />
                            </EntityPageToolbarButton>
                            <EntityPageToolbarButton
                                tabId={TAB.TABLE}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.table')}
                            >
                                <ViewList />
                            </EntityPageToolbarButton>
                            {/* <EntityPageToolbarButton
                                tabId={TAB.MAINTENANCE}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.maintenance')}
                            >
                                <MaintenanceIcon />
                            </EntityPageToolbarButton>
                            {user?.superadmin && (
                                <EntityPageToolbarButton
                                    tabId={TAB.ASSET_LOCATIONS}
                                    activeTabId={tab}
                                    handleTabChange={handleTabChange}
                                    label={generalTranslation('assetPageTitle.locations')}
                                >
                                    <AssetLocationsIcon className={style.icon} />
                                </EntityPageToolbarButton>
                            )}*/}
                        </EntityPageToolbar>

                        <TableActions
                            actions={
                                [
                                    // {
                                    //     id: 'download',
                                    //     label: generalTranslation('toolbar.download'),
                                    //     callback: handleDownload
                                    // },
                                    user?.superadmin
                                        ? {
                                              id: 'go-to-forecast-settings',
                                              link: true,
                                              url: getForecastSettingsUrl(),
                                              callback: () => getForecastSettingsUrl,
                                              label: generalTranslation('goToForecastSettings')
                                          }
                                        : null
                                ].filter((x) => x) as TableAction[]
                            }
                            className={style.actionsWrapper}
                        />
                    </Box>
                </Box>

                <Box className={style.subHeaderContainer}>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">{generalTranslation('assetInfo.type')}</Typography>
                        <Typography variant="small3" sx={{ ml: 1.5 }}>
                            {generalTranslation(`assetTypes.${asset.type}`)}
                        </Typography>
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">{generalTranslation('assetInfo.country')}</Typography>
                        {asset.country_code ? (
                            <>
                                <Flag
                                    height="24"
                                    width="24"
                                    code={asset.country_code}
                                    style={{ margin: '0 8px' }}
                                    className="flagIcon"
                                />
                                <Typography variant="small3">{asset.country_code!.toUpperCase()}</Typography>
                            </>
                        ) : (
                            NO_VALUE
                        )}
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">{generalTranslation('assetInfo.location')}</Typography>

                        <Box className={style.location} sx={{ ml: 1.5 }} onClick={handleLocationOpen}>
                            <LocationSvg style={{ marginRight: '4px', minHeight: '14px', minWidth: '14px' }} />
                            <Typography variant="small4">{asset?.location || NO_VALUE}</Typography>
                        </Box>
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">{generalTranslation('assetInfo.totalCapacity')}</Typography>
                        <Typography variant="small3" sx={{ ml: 1.5 }}>
                            {getFormattedCapacityValue(asset.capacity_value)}
                        </Typography>
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">{generalTranslation('assetInfo.status')}</Typography>
                        <Box sx={{ ml: 1.5 }}>
                            <AssetStatusLabel status={asset.status as any} variant="small3" />
                        </Box>
                    </Box>
                    <Box className={style.subHeaderElement}>
                        <Typography variant="small4">{generalTranslation('assetInfo.expiryDate')}</Typography>
                        <Typography variant="small3" sx={{ ml: 1.5 }}>
                            {getFormattedDate({ value: asset.expiry_date, fallback: NO_VALUE })}
                        </Typography>
                    </Box>
                </Box>

                {tab === TAB.CHART && (
                    <TradingView
                        data={chartData as any}
                        fetchMoreFn={() => {}}
                        error={errorForecast || errorMeasured}
                        loading={loadingForecast || loadingMeasured}
                        assetMeasureUnit={asset.measure_unit as any}
                    />
                )}
                {tab === TAB.TABLE && (
                    <MemoizedGenerationTable
                        pager={pager}
                        setGetParams={setGetParamsAssetPage}
                        data={paginatedData}
                        fetchMoreFn={() => {}}
                        setTablePayload={setGenerationTablePayloadTemp}
                        error={errorForecast}
                        loading={loadingForecast}
                        success={successForecast}
                        assetMeasureUnit={asset.measure_unit as any}
                        timezone={asset.timezone}
                    />
                )}
                {/* {tab === TAB.MAINTENANCE && <MaintenanceContainer assetId={asset.id} />}
                {tab === TAB.ASSET_LOCATIONS && <LocationsTable asset={asset} />}*/}
            </Box>
            {asset && (
                <AssetLocationModal
                    payload={
                        {
                            longitude: asset.longitude,
                            latitude: asset.latitude,
                            title: asset.name,
                            location: asset.location || '',
                            type: asset.type
                        } as any
                    }
                />
            )}
            {/*{asset && <AddAssetLocationsModal assetId={asset.id} />}
            {!!selectedLocations.length && <IngestMultipleLocationsModal data={selectedLocations} asset={asset} />}*/}
        </>
    );
};

export default MockAssetPage;
