import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import useStickyHeader from '../../../hooks/useStickyHeader';

import style from './style.module.scss';

interface GenericTableToolbarProps {
    tableTitle: string;
}

export const GenericTableToolbar: FC<GenericTableToolbarProps> = ({ tableTitle, children }) => {
    useStickyHeader('generic-table-toolbar');
    return (
        <Box id="generic-table-toolbar" className={style.tableHeader}>
            <Typography variant="h6" className={style.title}>
                {tableTitle}
            </Typography>

            <Box className={style.actions}>{children}</Box>
        </Box>
    );
};
