import { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Input, Typography } from '@mui/material';

import { MODAL_IDS } from '..';
import { ImportFileModalProps } from '.';
import { FormModalActions } from '../../components/FormUtils';
import { ModalContext } from '../../hooks/modalContext';

import common from '../../common.module.scss';

export const ImportForm = ({ callback, titleLabel, subtitleLabel }: ImportFileModalProps) => {
    const [file, setFile] = useState<File>();
    const { t } = useTranslation();
    const { handleClose } = useContext(ModalContext);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUploadClick = (e: any) => {
        e.preventDefault();
        if (!file) {
            return;
        }

        callback(file);
        handleClose(MODAL_IDS.IMPORT_FILE_MODAL);
    };
    return (
        <>
            <>
                <Typography variant="h6">{titleLabel}</Typography>

                <Typography variant="body1" sx={{ mt: 3, mb: 2.5 }}>
                    {subtitleLabel}
                </Typography>
            </>
            <Box component="form" noValidate onSubmit={handleUploadClick}>
                <Input type="file" onChange={handleFileChange} className={common.fileInput} />

                <FormModalActions
                    loading={false}
                    disabled={!file}
                    modalId={MODAL_IDS.IMPORT_FILE_MODAL}
                    note={t('mandatoryFields')}
                    cancelLabel={t('nevermind')}
                    saveLabel={t('form.submit')}
                />
            </Box>
        </>
    );
};
