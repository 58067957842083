import { DialogContent } from '@mui/material';

import { MODAL_IDS } from '..';
import DemandForm from './DemandForm';
import { FormModal } from '../../components/BaseModal/FormModal';

import style from './style.module.scss';

const DemandModal = () => {
    return (
        <FormModal id={MODAL_IDS.DEMAND_MODAL} fullWidth={false} maxWidth="lg">
            <DialogContent className={style.modalContainer}>
                <DemandForm />
            </DialogContent>
        </FormModal>
    );
};

export { DemandModal };
