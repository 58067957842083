import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Input, Stack, FormHelperText, Typography } from '@mui/material';

import { ROUTES } from '../../../utils/config';
import Logo from '../../../assets/images/logo.svg';
import { ResetPasswordBody } from '../../../interfaces/uiv2';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { resetPasswordAsync } from '../../../stores/Auth';
import Illustration from '../../../assets/images/login-illustration.jpg';

import style from './index.module.scss';

const ResetPassword = () => {
    const { t } = useTranslation('auth/resetPassword');
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector((state) => state.resetPassword);

    const schema = yup
        .object({
            email: yup.string().email(t('invalidEmail')).required(t('requiredEmail'))
        })
        .required();

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<ResetPasswordBody>({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data: ResetPasswordBody) => dispatch(resetPasswordAsync(data));

    return (
        <Box className={style.authWrapper}>
            <Box className={style.resetPassword}>
                <Box className={style.illustration}>
                    <img src={Illustration} alt={t('reset')} />
                </Box>
                <Box className={style.content}>
                    <Box className={style.logoContainer}>
                        <Link to={ROUTES.LOGIN}>
                            <img className={style.logo} src={Logo} />
                        </Link>
                    </Box>
                    <Box className={style.container}>
                        <Typography className={style.title} variant="h4">
                            {t('title')}
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name={'email'}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            fullWidth
                                            type="email"
                                            id={t('email')}
                                            placeholder={t('email')}
                                        />
                                        <FormHelperText error>{errors.email?.message}</FormHelperText>
                                    </>
                                )}
                            />
                            <Stack spacing={12} direction="row" className={style.options}>
                                <Link to={ROUTES.LOGIN}>
                                    <Typography className={style.primary} variant="small1">
                                        {t('remembered')}
                                    </Typography>
                                </Link>
                                <LoadingButton
                                    loading={loading}
                                    disabled={loading}
                                    type="submit"
                                    variant="primary"
                                    sx={{ flex: 1 }}
                                >
                                    {t('reset')}
                                </LoadingButton>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ResetPassword;
