import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';
import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { getEventsFromAvailability } from './availabilityHelpers';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { AssetAvailability } from '../../interfaces/apiv2';
import { AssetAvailabilityEvent, AssetAvailabilityPayload } from '../../interfaces/uiv2';

interface ListAssetAvailabilityState extends DefaultInitialState {
    events: AssetAvailabilityEvent[];
    periods: AssetAvailabilityPayload[];
    availability: AssetAvailability;
}

const initialState: ListAssetAvailabilityState = {
    loading: false,
    error: false,
    success: false,
    availability: {},
    events: [],
    periods: []
};

export const getAssetAvailabilityAsync = createAsyncThunk(
    'assets/availability-list',
    async ({ publicId, from, to }: AssetAvailabilityPayload, { rejectWithValue }) => {
        try {
            const response = await axios.get<AssetAvailability>(
                `/electricity/generation/assets/${publicId}/availability?from_datetime=${from}&to_datetime=${to}`,
                {
                    cache: {
                        ttl: 1000 * 60 * 15
                    }
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    },
    {
        condition: (values: AssetAvailabilityPayload, { getState }: { getState: () => RootState; extra: any }) => {
            const { periods } = getState().listAssetAvailability;
            return !periods.find((period) => period.from === values.from && period.to === values.to);
        }
    }
);

export const listAssetAvailability = createSlice({
    name: 'listAssetAvailability',
    initialState,
    reducers: {
        resetListAssetAvailabilityState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getAssetAvailabilityAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getAssetAvailabilityAsync.fulfilled, (state, { payload, meta }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            const availability = {
                ...state.availability,
                ...payload
            };
            state.availability = availability;
            state.events = getEventsFromAvailability(availability);
            state.periods.push(meta.arg);
        });
        builder.addCase(getAssetAvailabilityAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.info(i18next.t('noDataAvailable'), ToastifyType.warning);
        });
    }
});

export const { resetListAssetAvailabilityState } = listAssetAvailability.actions;

export default listAssetAvailability.reducer;
