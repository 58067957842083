import classnames from 'classnames';
import { TFunction } from 'react-i18next';
import { Tooltip, Typography } from '@mui/material';
import { UNIT } from '../../interfaces/uiv2';

import { ReportFE } from '../../stores/DeliveredForecast';
import { TableCellRenderer } from '../../interfaces/general';
import { DefaultSearchableCell } from '../../components/BaseTable';
import { convertMeasureUnitToUnitId, toFixed } from '../../utils/helpers';

import style from '../../components/GenerationTable/style.module.scss';

export const getTableHeadCells = ({
    t,
    assetMeasureUnit
}: {
    t: TFunction;
    assetMeasureUnit: UNIT;
    maxValue?: number;
}): TableCellRenderer<ReportFE>[] => {
    const unitId = convertMeasureUnitToUnitId(assetMeasureUnit);

    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {row.index < 10 ? `0${row.index}` : `${row.index}`}
                    </Typography>
                );
            },
            sort: false
        },
        {
            id: 'name',
            label: 'Nume',
            sort: false,
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.name} searchValue={searchValue} className={style.default} />;
            }
        },
        {
            id: 'measured',
            label: (
                <Tooltip placement="top" title={`Consum (profilare zilnică) (${unitId})`}>
                    <Typography variant="small4">{`Consum (profilare zilnică) (${unitId})`}</Typography>
                </Tooltip>
            ) as any,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.measured_division_profiled === null ? '-' : toFixed(row.measured_division_profiled, 1)}
                    </Typography>
                );
            }
        },
        {
            id: 'forecasted',
            label: (
                <Tooltip placement="top" title={`Predicție (profilare zilnică / intervale) (${unitId})`}>
                    <Typography variant="small4">{`Predicție (profilare zilnică / intervale) (${unitId})`}</Typography>
                </Tooltip>
            ) as any,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.forecasted_division_profiled === null ? '-' : toFixed(row.forecasted_division_profiled, 1)}
                    </Typography>
                );
            }
        },
        {
            id: 'volume-profiled-smart',
            label: (
                <Tooltip placement="top" title={`Consum (profilare zilnică meteo) (${unitId})`}>
                    <Typography variant="small4">{`Consum (profilare zilnică meteo) (${unitId})`}</Typography>
                </Tooltip>
            ) as any,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.volume_profiled_smart === null ? '-' : toFixed(row.volume_profiled_smart, 1)}
                    </Typography>
                );
            }
        },
        {
            id: 'cluster_forecasted',
            label: (
                <Tooltip placement="top" title={`Predicție (profilare zilnică meteo / intrată) (${unitId})`}>
                    <Typography variant="small4">
                        {`Predicție (profilare zilnică meteo / intrată) (${unitId})`}
                    </Typography>
                </Tooltip>
            ) as any,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.cluster_forecasted === null ? '-' : toFixed(row.cluster_forecasted, 1)}
                    </Typography>
                );
            }
        }
    ];
};

export const renderFooterContent = (forecast: ReportFE[], t: TFunction) => {
    return {
        _id: 'mockedFooter',
        customFooter: true,
        index: t('customFooter.total'),
        name: 'Consumers',
        measured_division_profiled: toFixed(
            forecast.reduce(
                (prev, crt) => prev + (crt.measured_division_profiled ? +crt.measured_division_profiled : 0),
                0
            )
        ),
        forecasted_division_profiled: toFixed(
            forecast.reduce(
                (prev, crt) => prev + (crt.forecasted_division_profiled ? +crt.forecasted_division_profiled : 0),
                0
            )
        ),
        volume_profiled_smart: toFixed(
            forecast.reduce((prev, crt) => prev + (crt.volume_profiled_smart ? +crt.volume_profiled_smart : 0), 0)
        ),
        cluster_forecasted: toFixed(
            forecast.reduce((prev, crt) => prev + (crt.cluster_forecasted ? +crt.cluster_forecasted : 0), 0)
        )
    };
};
