import i18next from 'i18next';
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { Prosumers } from '../../interfaces/apiv2';
import ToastifyType from '../../utils/toastify-config';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { mockedProsumers, mockedProsumersData } from './mocked-data';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { GetParams } from '../../interfaces/general';
import { mockRequest } from '../../utils/helpers';

interface ProsumersPage extends DefaultInitialState {
    prosumers: Prosumers | null;
    getParams: GetParams;
}

const initialState: ProsumersPage = {
    prosumers: null,
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE
};

export const getProsumersAsync = createAsyncThunk(
    'prosumerss/show',
    async (values: { id: string }, { rejectWithValue }) => {
        try {
            const prosumers = mockedProsumersData.find((x) => x.id === values.id) || mockedProsumers;
            const data = await mockRequest(250, prosumers);
            return data as Prosumers;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const prosumersPage = createSlice({
    name: 'prosumersPage',
    initialState,
    reducers: {
        resetProsumersPageState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProsumersAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getProsumersAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.prosumers = payload;
        });
        builder.addCase(getProsumersAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetProsumersPageState, setGetParams: setGetProsumerParams } = prosumersPage.actions;

export default prosumersPage.reducer;
