import { TFunction } from 'i18next';
import { Box } from '@mui/material';

import { getAssetIcon } from '../../../../utils/getAssetIcon';
import { DeliveredForecastFE } from '../../../../interfaces/uiv2';
import { TableCellRenderer } from '../../../../interfaces/general';
import { DefaultSearchableCell } from '../../../../components/BaseTable';
import { DownloadButton, DownloadExtension } from '../../../../components/DownloadButton';
import { PageHeader } from '../../../../components/PageHeader/PageHeader';

export const getTableHeadCells = (t: TFunction): TableCellRenderer<DeliveredForecastFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 75,
            label: t('tableHead.type'),
            value: () => {
                return getAssetIcon('ChargingPoint');
            },
            sort: true,
            sort_id: 'forecast_asset.type'
        },
        {
            id: 'forecast_type',
            label: t('tableHead.forecast_type'),
            sort: false,
            // value: (row, _searchValue, _onClick, handlers) => {
            value: () => {
                const value = 'EV Forecast';
                // const handler = handlers?.['name'];
                // const callback = handler ? handler(row) : undefined;

                return <DefaultSearchableCell value={value} searchValue={null} />;
            }
        },
        {
            id: 'delivery_group',
            label: t('tableHead.delivery_group'),
            sort: true,
            value: (row) => {
                const value = row.delivery_group.name;

                return <DefaultSearchableCell value={value} searchValue={null} />;
            },
            sort_id: 'forecast_asset.name'
        },
        {
            id: 'from',
            label: t('tableHead.from'),
            sort: false,
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.from_date} searchValue={searchValue} />;
            }
        },
        {
            id: 'to',
            label: t('tableHead.to'),
            sort: false,
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.to_date} searchValue={searchValue} />;
            }
        },
        {
            id: 'download',
            label: t('tableHead.download'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const filename = `Ogre_Forecast_${row.from_date}-${row.to_date}`;
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <DownloadButton
                            type={DownloadExtension.Txt}
                            id={row.id}
                            filename={filename}
                            value="evs-forecast"
                            row={row}
                        />
                    </Box>
                );
            }
        }
    ];
};
