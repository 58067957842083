import { Box, Input } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { EditableCellProps } from './interfaces';
import { debounce } from '../../../utils/helpers';
import { MdOutlineEditNote } from 'react-icons/md';

import style from './style.module.scss';

const ENTER_KEY = 13;
const ESC_KEY = 27;

export const EditableCell = ({ cb, value, autoSave, cancel, tableMode }: EditableCellProps) => {
    // add a null check to avoid warning of controlled components set with null
    const [editValue, setEditValue] = useState<any>(value === null ? '' : value);

    const handleKeyPress = (event: any) => {
        if (event.keyCode === ENTER_KEY) {
            cb(editValue)();
        }

        if (event.keyCode === ESC_KEY) {
            cancel();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditValue(event.target.value);
        if (tableMode) {
            debouncedEditHandler(event.target.value);
        } else {
            autoSave && debouncedEditHandler(event.target.value);
        }
    };

    const debouncedCbFn = (editValueDebounced: any) => {
        cb(editValueDebounced)();
    };

    const debouncedEditHandler = useCallback(debounce(debouncedCbFn, 1000), []);

    if (tableMode) {
        return (
            <Box className={style.editContainer}>
                <Input
                    key={'editable-cell'}
                    className={style.input}
                    autoFocus={false}
                    value={editValue}
                    onChange={handleChange}
                />
            </Box>
        );
    }

    return (
        <>
            <Box className={style.bg} onClick={cancel}></Box>
            <Box className={style.editContainer}>
                <Input
                    key={'editable-cell'}
                    autoFocus={true}
                    className={style.input}
                    value={editValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                />
                <MdOutlineEditNote onClick={cb(editValue)} className={style.icon} />
            </Box>
        </>
    );
};
