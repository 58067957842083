import { AxiosResponse } from 'axios';
import axios from '../../utils/axios';
import { DefaultInitialState } from '../../interfaces/redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { PatchVPP, PatchVPPBody, PostVPP, VPP } from '../../interfaces/apiv2';

interface CreateVPPState extends DefaultInitialState {
    VPPCreated: number;
}

const initialState: CreateVPPState = {
    loading: false,
    error: false,
    success: false,
    VPPCreated: 0
};

export const updateVPPAsync = createAsyncThunk('updateVPPAsync', async (values: PatchVPPBody, { rejectWithValue }) => {
    try {
        const response = await axios.patch<VPP, AxiosResponse<VPP>, PatchVPP>(
            `/electricity/generation/assets/vpp/${values.id}`,
            values.payload
        );

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const createVPPAsync = createAsyncThunk('createVPP', async (values: PostVPP, { rejectWithValue }) => {
    try {
        const response = await axios.post<VPP, AxiosResponse<VPP>, PostVPP>(
            '/electricity/generation/assets/vpp',
            values
        );

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const createVPP = createSlice({
    name: 'createVPP',
    initialState,
    reducers: {
        resetcreateVPPState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(updateVPPAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(updateVPPAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            // just to update the state and fetch the groups again
            state.VPPCreated = state.VPPCreated + 1;
        });
        builder.addCase(updateVPPAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
        builder.addCase(createVPPAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(createVPPAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.VPPCreated = state.VPPCreated + 1;
        });
        builder.addCase(createVPPAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
    }
});

export const { resetcreateVPPState } = createVPP.actions;

export default createVPP.reducer;
