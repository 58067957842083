import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { MdDeleteOutline, MdRefresh } from 'react-icons/md';

import {
    getGasClustersListAsync,
    setGetGasClustersParams,
    resetListGasClustersState,
    setSelectedRowsGasClusters,
    deleteGasClustersListAsync
} from '../../../stores/Demand';
import { MODAL_IDS } from '../../../modals';
import { ROUTES } from '../../../utils/config';
import { getGasClustersHeadCells } from '../helpers';
import { ClustersDataFE } from '../../../interfaces/uiv2';
import { ModalContext } from '../../../hooks/modalContext';
import { PageContainer } from '../../../layout/PageContainer';
import useStickyHeader from '../../../hooks/useStickyHeader';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { GenericTable, TableSearch } from '../../../components/BaseTable';
import { transformOrderByToSort } from '../../../components/BaseTable/GenericTable/helpers';
import { RemoveForecastContainer } from '../../../components/RemoveEntityContainer';

import style from '../../../features/forecast-list/forecast-list.module.scss';

const GasClustersList = () => {
    const dispatch = useAppDispatch();
    const { projectId = '' } = useParams();
    const { handleClickOpen } = useContext(ModalContext);
    const { clustersFE, loading, getParams, pager, success, selectedRows } = useAppSelector(
        (state) => state.listGasClusters
    );
    const { deleted } = useAppSelector((state) => state.deleteGasClusters);

    useEffect(() => {
        dispatch(getGasClustersListAsync({ getParams, projectId }));

        return () => {
            dispatch(resetListGasClustersState());
        };
    }, []);

    useEffect(() => {
        dispatch(getGasClustersListAsync({ getParams, projectId }));
    }, [deleted, getParams, projectId]);

    const { t } = useTranslation();
    const headCells = getGasClustersHeadCells(t);

    const clearSelection = () => {
        dispatch(setSelectedRowsGasClusters([]));
    };

    const openConfirmationModal = () => {
        handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    };

    const getClusterPageUrl = (row: ClustersDataFE) => () => {
        return `${ROUTES.AI_GAS_DEMAND_CLUSTERS_PAGE.replace(':projectId', projectId).replace(':id', row.id)}`;
    };

    const removeClusters = () => {
        dispatch(deleteGasClustersListAsync({ ids: selectedRows.map((a) => a.id), projectId }));
        clearSelection();
    };

    useStickyHeader('gas-Clusters-list');

    return (
        <PageContainer>
            <Box className={style.header} id={'gas-Clusters-list'}>
                <Typography variant="h6" className={style.title}>
                    {t('header.clusters')}
                </Typography>

                <Box className={style.toolbar}>
                    {/* selected items toolbar */}
                    {!!selectedRows.length && (
                        <>
                            <Typography variant="small4" className={style.label}>
                                {selectedRows.length} {t('table.general.selected')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdRefresh />
                                <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                    {t('table.general.clearSelection')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdDeleteOutline />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={openConfirmationModal}
                                    sx={{ ml: 1 }}
                                >
                                    {t('table.general.removeAll')}
                                </Box>
                            </Typography>
                        </>
                    )}

                    <TableSearch setGetParams={setGetGasClustersParams} getParams={getParams} />
                    {/* {user?.superadmin ? (
                        <Button disableRipple variant="cta" onClick={openImportModal(clusterDataProps)} sx={{ ml: 1 }}>
                            <OgreUploadIcon />
                        </Button>
                    ) : null}
                    {user?.superadmin ? (
                        <Button
                            disableRipple
                            variant="cta"
                            onClick={openImportModal(historicalDataProps)}
                            sx={{ ml: 1 }}
                        >
                            <UploadIcon className={style.historical} />
                        </Button>
                    ) : null}*/}
                </Box>
            </Box>

            <GenericTable
                rows={clustersFE}
                loading={loading}
                success={success}
                pager={pager}
                isCheckboxEnabled
                columns={headCells}
                rowUniqueId={'id'}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRowsGasClusters}
                setGetParams={setGetGasClustersParams}
                searchParam={getParams.search}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getClusterPageUrl
                }}
            />

            <RemoveForecastContainer cb={removeClusters} stateAccessor="deleteGasClusters" />
        </PageContainer>
    );
};

export default GasClustersList;
