import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';

import Loading from '../../layout/Loading';
import { PageContainer } from '../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../stores';
import { getGasDashboardAsync } from '../../stores/Demand/gas/dashboard/listGasDashboard';
import { GasDashboardPieCard } from '../../features/generation-dashboard-view/GasDashboardPieCard';
import { GasDashboardProfileCard } from '../../features/generation-dashboard-view/GasDashboardProfileCard';
import { DashboardGasDemandStaticCard } from '../../features/generation-dashboard-view/DashboardGasDemandStaticCard';

import style from '../../features/generation-dashboard-view/style.module.scss';
import { layout } from '../../stores/Demand/gas/dashboard/layout';
import { GasDashboardIntraCard } from '../../features/generation-dashboard-view/GasDashboardIntraCard';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GasDemandDashboard = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { projectId } = useParams();

    const { dashboard, loading: isLoading } = useAppSelector((state) => state.listGasDashboard);

    useEffect(() => {
        if (projectId && projectId !== ':projectId') {
            dispatch(getGasDashboardAsync({ projectId }));
        }
    }, [projectId]);

    const generateDOM = useCallback(() => {
        return _.map(layout.lg, function (l) {
            if (l.i === 'static') {
                return (
                    <Box key={l.i} className={style.itemWrapper}>
                        <DashboardGasDemandStaticCard item={dashboard!} />
                    </Box>
                );
            }

            if (l.i === 'profile') {
                return (
                    <Box key={l.i} className={style.itemWrapper}>
                        <GasDashboardProfileCard dashboard={dashboard!} l={layout.lg[2]} />
                    </Box>
                );
            }

            if (l.i === 'pie') {
                return (
                    <Box key={l.i} className={style.itemWrapper}>
                        <GasDashboardPieCard dashboard={dashboard!} l={layout.lg[1]} />
                    </Box>
                );
            }

            if (l.i === 'forecast') {
                return (
                    <Box key={l.i} className={style.itemWrapper}>
                        <GasDashboardIntraCard />
                    </Box>
                );
            }
        });
    }, [dashboard]);

    if (projectId === ':projectId') {
        return null;
    }

    const onLayoutChange = () => {};
    const onBreakpointChange = () => {};

    return (
        <PageContainer className={[style.container]}>
            <svg style={{ height: 0, width: 0, position: 'absolute', top: 0, left: 0 }}>
                <defs>
                    <linearGradient id="barGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#D5E3FF" />
                        <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                </defs>
            </svg>
            <Box className={style.header}>
                <Typography variant="h6" component="span">
                    {t('dashboard')}
                </Typography>
                <Typography variant="verySmallBold" component="span" className={style.beta}>
                    {t('beta')}
                </Typography>
            </Box>
            {isLoading ? (
                <Loading />
            ) : (
                <Box className={style.grid}>
                    <ResponsiveReactGridLayout
                        draggableCancel=".dragDisable"
                        rowHeight={110}
                        isBounded={true}
                        margin={[20, 20]}
                        resizeHandles={[]}
                        isResizable={false}
                        allowOverlap={false}
                        useCSSTransforms={true}
                        preventCollision={false}
                        compactType={null}
                        measureBeforeMount={false}
                        layouts={layout}
                        onLayoutChange={onLayoutChange}
                        onBreakpointChange={onBreakpointChange}
                        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                        breakpoints={{ lg: 1800, md: 1800, sm: 1800, xs: 1800, xxs: 1800 }}
                    >
                        {dashboard ? generateDOM() : null}
                    </ResponsiveReactGridLayout>
                </Box>
            )}
        </PageContainer>
    );
};

export default GasDemandDashboard;
