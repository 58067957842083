import classNames from 'classnames';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { MODAL_IDS } from '../../../modals';
import { MenuItems, pathMatch } from '../helpers';
import { ProjectsData } from '../../../interfaces/apiv2';
import { ModalContext } from '../../../hooks/modalContext';
import { ReactComponent as ExpandedSVG } from '../../../assets/icons/menu-item-expanded.svg';

import style from '../style.module.scss';

export const CenterLinks = ({ menuItems, projects }: { menuItems: MenuItems[]; projects: ProjectsData[] }) => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { handleClickOpen } = useContext(ModalContext);

    const [expand, setExpand] = useState('');
    const [activeProject, setActiveProject] = useState<ProjectsData | null>(null);

    useEffect(() => {
        if (params.projectId) {
            setActiveProject(projects.filter((x) => x.id === params.projectId)[0] || null);
        } else {
            setActiveProject(null);
        }
    }, [params, projects]);

    const goTo = ({ disabled, projectMetadata, path, children, label }: MenuItems) => {
        if (disabled) {
            return;
        }

        if (projectMetadata) {
            const wrongProject =
                projectMetadata.module !== activeProject?.module || projectMetadata.type !== activeProject?.type;

            const matchyProjects = projects.filter(
                (project) => project.module === projectMetadata.module && project.type === projectMetadata.type
            );

            if (activeProject && !wrongProject) {
                navigate(path.replace(':projectId', activeProject.id));
                return;
            }

            if (matchyProjects.length === 1) {
                navigate(path.replace(':projectId', matchyProjects[0].id));
                setActiveProject(matchyProjects[0]);

                if (children?.length) {
                    setExpand((prev) => (prev === label ? '' : label));
                }
            }

            if (matchyProjects.length > 1) {
                if (!activeProject || wrongProject) {
                    handleClickOpen(MODAL_IDS.DEMAND_MODAL, {
                        path,
                        projects: matchyProjects,
                        projectMetadata,
                        callback: (project: ProjectsData) => {
                            setExpand((prev) => (prev === label ? '' : label));
                            setActiveProject(project);
                        }
                    });
                    return;
                }
            }

            return;
        }

        if (children?.length) {
            setExpand((prev) => (prev === label ? '' : label));
        } else {
            setExpand('');
        }

        navigate(path);
    };

    return (
        <Box className={style.container}>
            {menuItems.map((item) => {
                const isChildrenActive = item.children?.some((item) => pathMatch(pathname, item.path));
                const isActive = pathname.includes(item.path) || pathMatch(pathname, item.path) || isChildrenActive;

                const isExpanded = expand === item.label || !!isChildrenActive;
                return (
                    <Box
                        key={`${item.label}-tooltip`}
                        className={classNames(style.rowContainer, isExpanded && style.margin12)}
                    >
                        <Tooltip title={t(`header.${item.label}`)} enterDelay={50} enterTouchDelay={50}>
                            <Box
                                key={item.label}
                                onClick={() => goTo(item)}
                                className={classNames(
                                    style.menuItem,
                                    item.disabled && style.disabled,
                                    isActive && style.active
                                )}
                            >
                                <Box className={style.menuIcon}>{item.icon}</Box>
                            </Box>
                        </Tooltip>

                        {item.children?.length ? (
                            <>
                                <ExpandedSVG className={classNames(style.expanded, isExpanded && style.rotate)} />
                                <CenterLinksChildren menuItems={item.children} isExpanded={isExpanded} goTo={goTo} />
                            </>
                        ) : null}
                    </Box>
                );
            })}
        </Box>
    );
};

const CenterLinksChildren = ({
    goTo,
    menuItems,
    isExpanded
}: {
    menuItems: MenuItems[];
    isExpanded: boolean;
    goTo: (item: MenuItems) => void;
}) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <Box className={classNames(style.childrenContainer, !isExpanded && style.nonExpanded)}>
            {menuItems.map((item) => {
                const isActive = pathname.includes(item.path) || pathMatch(pathname, item.path);
                return (
                    <Tooltip
                        key={item.label}
                        title={t(`header.${item.label}`)}
                        enterDelay={50}
                        enterTouchDelay={50}
                        className={classNames(style.childrenMenuItem, !isExpanded && style.margin0)}
                    >
                        <Box
                            key={item.label}
                            onClick={() => goTo(item)}
                            className={classNames(style.childrenMenuItem, item.disabled && style.disabled)}
                        >
                            <Box className={style.menuIcon}>
                                {item.icon}
                                {isActive ? <Box className={style.underscore} /> : null}
                            </Box>
                        </Box>
                    </Tooltip>
                );
            })}
        </Box>
    );
};
