// eslint-disable-next-line
import { Prosumers } from '../../interfaces/apiv2';
import { ProsumersDataFE } from '../../interfaces/uiv2';
import { ProsumerCluster, ProsumerClusterFE } from './listProsumerClusters';

export const transformProsumersDataToFE = (data: Prosumers[]): ProsumersDataFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};

export const transformClustersDataToFE = (data: ProsumerCluster[]): ProsumerClusterFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};
