import { isEqual } from 'lodash';
import classnames from 'classnames';
import Flag from 'react-world-flags';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import {
    getTLConsumerAsync,
    getTLConsumerDataAsync,
    loadMoreTLConsumerData,
    resetTLConsumerPage,
    setGetTLConsumerParams
} from '../../../stores/TL';
import { UNIT } from '../../../interfaces/uiv2';
import { TAB } from '../../../interfaces/general';
import { PageContainer } from '../../../layout/PageContainer';
import { TradingView } from '../../../components/TradingView';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { transformForecastToTable } from '../../../utils/forecast';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ReactComponent as ViewList } from '../../../assets/icons/view_list.svg';
import { ReactComponent as GraphIcon } from '../../../assets/icons/timeline.svg';
import { MemoizedGenericTable } from '../../../components/GenericTableWrapper/GenericTableWrapper';
import { TableActionsEntityContainer } from '../../../components/TableActionsEntityContainer';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../../components/EntityPageToolbar';
import { ReactComponent as ConsumerSVG } from '../../../assets/icons/demand/consumers.svg';
import { getTableHeadCells, renderFooterContent } from '../../../components/GenerationTable/helpers';
import { mockedDemandChartData as chartData, mockedDemandForecast } from '../../../stores/Demand/mocked-demand-chart';

import common from '../../../common.module.scss';
import style from '../../../features/asset-page/asset-page.module.scss';

const TLConsumerPagePage = () => {
    const query = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;

    const {
        pager,
        consumer,
        success,
        loading: loadingForecast,
        error: errorForecast
    } = useAppSelector((state) => state.tlConsumerPage);
    const getParams = useAppSelector((state) => state.tlConsumerPage.getParams, isEqual);

    const paginatedData = transformForecastToTable(mockedDemandForecast.slice(0, 100), 'RO');

    useEffect(() => {
        if (query?.id && !consumer) {
            dispatch(getTLConsumerAsync({ id: query.id }));
        }
    }, [query, consumer]);

    useEffect(() => {
        if (consumer) {
            dispatch(getTLConsumerDataAsync({ consumer }));
        }
    }, [consumer]);

    useEffect(() => {
        return () => {
            dispatch(resetTLConsumerPage());
        };
    }, []);

    useEffect(() => {
        if (tab === 'table' && pager?.page !== getParams.page) {
            dispatch(loadMoreTLConsumerData());
        }
    }, [getParams, tab, pager]);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    if (!consumer) {
        return null;
    }

    const headCells = getTableHeadCells({ t, assetMeasureUnit: UNIT.POWER });

    return (
        <PageContainer className={[common.pageContainer]}>
            {/* <Box className={common.wrapper}> */}
            <Box className={style.headerContainer}>
                <Box className={classnames(style.header)}>
                    <PageHeader
                        title={t('title.consumers')}
                        className={classnames(style.align)}
                        id={consumer.id}
                        icon={<ConsumerSVG />}
                        subtitle={consumer.id}
                    />

                    <Box className={style.subtitle} sx={{ ml: 2 }}>
                        <Box className={style.dot} />
                        <Typography variant="small1"> {t(pageTitle)}</Typography>
                    </Box>
                </Box>

                <Box className={style.toolbarContainer}>
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>
                    </EntityPageToolbar>
                    <TableActionsEntityContainer
                        entityType="forecast"
                        id={consumer?.id}
                        className={style.actionsWrapper}
                        remove={false}
                    />
                </Box>
            </Box>

            <Box className={common.subHeaderContainer}>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.type')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {'TL'}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.region')}</Typography>
                    <Typography variant="small3" className={classnames(common.ml, common.alignCenter)}>
                        <Flag height="24" width="24" code={'ro'} style={{ marginRight: '8px' }} className="flagIcon" />
                        {'RO'}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.dso')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {consumer.dso}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.createdAt')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {consumer.created_at}
                    </Typography>
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={chartData as any}
                    //error={errorForecast}
                    error={false}
                    fetchMoreFn={() => {}}
                    loading={loadingForecast}
                    legendOpts={{
                        forecasted: true,
                        measured: true
                    }}
                    assetMeasureUnit={UNIT.ENERGY}
                />
            )}
            {tab === 'table' && (
                <MemoizedGenericTable
                    renderFooterContent={renderFooterContent}
                    headCells={headCells}
                    pager={pager}
                    success={success}
                    data={paginatedData}
                    error={errorForecast}
                    loading={loadingForecast}
                    setGetParams={setGetTLConsumerParams}
                />
            )}
        </PageContainer>
    );
};

export default TLConsumerPagePage;
