import { ASSET_STATUS } from '../uiv2';
import { Client, DefaultEntityTimestamps } from './index';

export enum PROJECT_TYPE {
    DEMAND_FORECAST = 'Demand forecast',
    EVS = 'EVS',
    NTL = 'NTL',
    TL = 'TL',
    ANOMALY = 'Anomaly'
}

export enum PROJECT_MODULE {
    HEATING = 'HEATING',
    GAS = 'GAS',
    HYDRO = 'HYDRO',
    ELECTRICITY = 'ELECTRICITY'
}

export enum PROJECT_CURRENCY {
    EUR = 'EUR',
    USD = 'USD'
}

export interface ProjectsData extends DefaultEntityTimestamps {
    id: string;
    client_id: string;
    client?: Client;
    type: PROJECT_TYPE;
    country: string;
    name: string;
    status: ASSET_STATUS;
    default_currency: PROJECT_CURRENCY;
    module: PROJECT_MODULE;
}
