import _, { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';

import {
    resetDashboardItems,
    getDashboardItemsAsync,
    getDashboardStaticItem,
    getDashboardLayoutAsync,
    deleteDashboardItemAsync,
    createDashboardLayoutAsync,
    updateDashboardLayoutAsync
} from '../../stores/Dashboard';
import Loading from '../../layout/Loading';
import { DashboardVppCard } from './DashboardVppCard';
import { DashboardAssetCard } from './DashboardAssetCard';
import { DashboardStaticCard } from './DashboardStaticCard';
import { useAppDispatch, useAppSelector } from '../../stores';
import { Dashboard, DashboardType } from '../../interfaces/apiv2';
import { PageContainer } from '../../layout/PageContainer';
import { DeleteCardFromDashboardModal } from '../../modals/DeleteCardFromDashboardModal';
import { DASHBOARD_DEFAULT_LAYOUT, deleteFromDashboardLayout } from '../../utils/dashboard';

import style from './style.module.scss';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GenerationDashboardView = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const {
        dashboards,
        items: rawItems,
        loading,
        loadingLayout,
        staticCard,
        loadingStaticCard
    } = useAppSelector((state) => state.dashboard, isEqual);

    const dashboard = dashboards.find((dashboard) => dashboard.type === DashboardType.Generation);
    const items = rawItems.filter((dashboardItem) => dashboardItem.dashboard_id === dashboard?.id);

    useEffect(() => {
        dispatch(getDashboardStaticItem());

        dispatch(getDashboardLayoutAsync()).then((res) => {
            const response = res.payload as Dashboard[];
            const d = response.find((dashboard) => dashboard.type === DashboardType.Generation);
            if (!d) {
                dispatch(
                    createDashboardLayoutAsync({ layout: DASHBOARD_DEFAULT_LAYOUT, type: DashboardType.Generation })
                ).then((res) => {
                    if (res.type.endsWith('fulfilled')) {
                        const response = res.payload as Dashboard;
                        dispatch(getDashboardItemsAsync(response.id));
                    }
                });
            } else {
                dispatch(getDashboardItemsAsync(d.id));
            }
        });

        return () => {
            dispatch(resetDashboardItems());
        };
    }, []);

    const onBreakpointChange = () => {};

    const onLayoutChange = (newLayout: Layout[]) => {
        // console.log('[called][onLayoutChange]', newLayout);
        // don't update the layout if the layout or the items are loading
        if (loading || loadingLayout || loadingStaticCard) {
            return;
        }

        if (dashboard?.layout?.lg) {
            const isSame = newLayout.every((item, index) => {
                const counterItem = dashboard.layout.lg[index];

                return ['w', 'i', 'h', 'x', 'y'].every((key) => {
                    console.log(
                        '[called][onLayoutChange][every]',
                        key,
                        item[key as keyof Layout],
                        counterItem[key as keyof Layout]
                    );
                    return item[key as keyof Layout] === counterItem[key as keyof Layout];
                });
            });
            // console.log(
            //     '[called][onLayoutChange]',
            //     JSON.parse(JSON.stringify(dashboard?.layout?.lg)),
            //     JSON.parse(JSON.stringify(newLayout)),
            //     isSame
            // );

            // console.log('[called][onLayoutChange]', isSame);

            if (!isSame) {
                dispatch(
                    updateDashboardLayoutAsync({
                        id: dashboard.id,
                        body: { ...dashboard, layout: { ...dashboard.layout, lg: newLayout } }
                    })
                );
            }
        }
    };

    const changeHeight = (item: string) => {
        if (dashboard) {
            const newLayout = dashboard?.layout.lg.map((card) => {
                if (card.i === 'static') {
                    return card;
                }
                return card.i === item ? { ...card, h: card.h === 1 ? 3 : 1 } : card;
            });
            dispatch(
                updateDashboardLayoutAsync({
                    id: dashboard.id,
                    body: { ...dashboard, layout: { ...dashboard.layout, lg: newLayout } }
                })
            );
        }
    };

    const deleteDashboardItem = (item: string) => {
        if (dashboard) {
            dispatch(deleteDashboardItemAsync({ id: item, dashboard_id: dashboard.id }));

            // update layout by removing the element that was deleted.
            const dashboardItem = items.find((i) => i.id === item);
            if (dashboardItem) {
                const newLayout = deleteFromDashboardLayout(dashboardItem, dashboard);
                dispatch(
                    updateDashboardLayoutAsync({
                        id: dashboard.id,
                        body: { ...dashboard, layout: { ...dashboard.layout, lg: newLayout } }
                    })
                );
            }
        }
    };

    const generateDOM = useCallback(() => {
        return _.map(dashboard?.layout?.lg || [], function (l) {
            if (l.i === 'static' && staticCard) {
                return (
                    <Box key={l.i} className={style.itemWrapper}>
                        <DashboardStaticCard item={staticCard} />
                    </Box>
                );
            }

            const item = items.find((m) => m.id === l.i);

            if (!item) {
                return null;
            }

            if (item.asset && Object.keys(item.asset).length !== 0) {
                return (
                    <Box key={l.i} className={style.itemWrapper}>
                        <DashboardAssetCard
                            l={l}
                            item={item}
                            asset={item.asset}
                            changeHeight={changeHeight}
                            deleteDashboardItem={deleteDashboardItem}
                        />
                    </Box>
                );
            }

            if (item.vpp && Object.keys(item.vpp).length !== 0) {
                return (
                    <Box key={l.i} className={style.itemWrapper}>
                        <DashboardVppCard
                            l={l}
                            item={item}
                            vpp={item.vpp}
                            changeHeight={changeHeight}
                            deleteDashboardItem={deleteDashboardItem}
                        />
                    </Box>
                );
            }
        });
    }, [dashboard, items, staticCard]);

    const isLoading = loading || loadingLayout || loadingStaticCard;

    return (
        <PageContainer className={[style.container]}>
            <svg style={{ height: 0, width: 0, position: 'absolute', top: 0, left: 0 }}>
                <defs>
                    <linearGradient id="barGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#D5E3FF" />
                        <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                </defs>
            </svg>
            <Box className={style.header}>
                <Typography variant="h6" component="span">
                    {t('dashboard')}
                </Typography>
                <Typography variant="verySmallBold" component="span" className={style.beta}>
                    {t('beta')}
                </Typography>
            </Box>
            {isLoading ? (
                <Loading />
            ) : (
                <Box className={style.grid}>
                    {dashboard?.layout?.lg?.length ? (
                        <ResponsiveReactGridLayout
                            draggableCancel=".dragDisable"
                            rowHeight={110}
                            isBounded={true}
                            margin={[20, 20]}
                            resizeHandles={[]}
                            isResizable={false}
                            allowOverlap={false}
                            useCSSTransforms={true}
                            preventCollision={false}
                            compactType={null}
                            measureBeforeMount={false}
                            layouts={dashboard?.layout}
                            onLayoutChange={onLayoutChange}
                            onBreakpointChange={onBreakpointChange}
                            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                            breakpoints={{ lg: 1800, md: 1800, sm: 1800, xs: 1800, xxs: 1800 }}
                        >
                            {generateDOM()}
                        </ResponsiveReactGridLayout>
                    ) : null}
                </Box>
            )}

            <DeleteCardFromDashboardModal />
        </PageContainer>
    );
};

export { GenerationDashboardView };
