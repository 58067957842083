import {
    Box,
    Input,
    Stack,
    Radio,
    Select,
    Button,
    SvgIcon,
    MenuItem,
    Checkbox,
    Accordion,
    InputLabel,
    Typography,
    FormHelperText,
    FormControlLabel,
    AccordionDetails,
    AccordionSummary
} from '@mui/material';
import { get } from 'lodash';
import classNames from 'classnames';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import { Controller, FieldError } from 'react-hook-form';
import { MdAddCircleOutline, MdDeleteOutline, MdRemoveCircleOutline } from 'react-icons/md';

import { useAppDispatch } from '../../stores';
import { MeteoSources } from '../../interfaces/apiv2';
import {
    createGenerationForecastSettingsV2Async,
    updateGenerationForecastSettingsV2Async
} from '../../stores/GenerationAssetForecastSettingsV2';
import { useFormLogic } from '../../hooks/useFormLogic';
import { MANUAL_UUID_FOR_CREATE, getForecastSettingsSchema } from './helpers';
import { WIND_ERROR_MEASURMENTS_OF_INTEREST } from '../../utils/forecastDeliverySettings';
import { ASSET_TYPE, ForecastSettingsFEV2, ForecastSettingsV2FormFields } from '../../interfaces/uiv2';

import style from './style.module.scss';

const IndividualForecastSettings = ({
    forecastSettings,
    assetType,
    sources,
    assetId,
    deleteLocalSetting
}: {
    forecastSettings: Omit<ForecastSettingsFEV2, 'asset'>;
    assetType: ASSET_TYPE;
    assetId: string;
    sources: MeteoSources[];
    deleteLocalSetting: (id: string) => void;
}) => {
    const { ...defaultValues } = forecastSettings;
    const dispatch = useAppDispatch();
    const [expanded, setExpanded] = useState(true);
    const { t } = useTranslation('assets/forecast-settings');
    const { t: generalTranslation } = useTranslation();
    const [isInput, setIsInput] = useState(false);

    const isLocalSetting = forecastSettings.id.startsWith(MANUAL_UUID_FOR_CREATE);

    const schema = getForecastSettingsSchema(generalTranslation);

    const {
        control,
        trigger,
        formState: { errors, isDirty },
        handleSubmit,
        getValues
    } = useFormLogic<
        Omit<ForecastSettingsFEV2, 'asset'>,
        ForecastSettingsV2FormFields,
        any,
        ForecastSettingsV2FormFields
    >({
        schema,
        data: forecastSettings,
        defaultValues: defaultValues
    });

    const formValues = getValues();

    const onSubmit = (form: ForecastSettingsV2FormFields) => {
        if (isLocalSetting) {
            dispatch(createGenerationForecastSettingsV2Async({ assetId: assetId, body: form }));
            return;
        }

        dispatch(
            updateGenerationForecastSettingsV2Async({
                assetId: assetId,
                forecastSettingsId: forecastSettings.id,
                body: form
            })
        );
    };

    const handleCheck = (checkedId: string, fieldId: string) => {
        const values = getValues();

        const ids = get(values, fieldId);

        return ids?.includes(checkedId)
            ? ids?.filter((id: string) => id?.toLowerCase() !== checkedId?.toLowerCase())
            : [...(ids ?? []), checkedId];
    };

    const removeLocalSetting = (e: MouseEvent) => {
        e.stopPropagation();
        deleteLocalSetting(forecastSettings.id);
    };

    const toggleInputMode = (e: MouseEvent) => {
        e.stopPropagation();
        setIsInput(!isInput);
    };

    const updateName = (e: MouseEvent) => {
        e.stopPropagation();
        trigger('name').then((success) => {
            if (success) {
                setIsInput(!isInput);
            }
        });
    };

    return (
        <Accordion
            className={style.accordionWrapper}
            expanded={expanded}
            onChange={(event, value) => setExpanded(value)}
        >
            <AccordionSummary
                id={`${forecastSettings.id}-header`}
                aria-controls={`${forecastSettings.id}-content`}
                className={style.accordionSummary}
                expandIcon={
                    expanded ? (
                        <SvgIcon component={MdRemoveCircleOutline} />
                    ) : (
                        <SvgIcon component={MdAddCircleOutline} />
                    )
                }
            >
                <Box className={style.summary}>
                    {isInput ? (
                        <Box className={style.input} onClick={(e) => e.stopPropagation()}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            autoFocus
                                            autoComplete=""
                                            fullWidth
                                            id={t('form.name')}
                                            placeholder={t('form.name')}
                                            className={errors?.name ? style.inputError : ''}
                                            error={!!errors?.name}
                                        />
                                    </>
                                )}
                            />
                            <SvgIcon component={DoneIcon} onClick={updateName} />
                        </Box>
                    ) : (
                        <Typography variant="h6" className={classNames(style.title)} onClick={toggleInputMode}>
                            {formValues.name}
                        </Typography>
                    )}

                    {isLocalSetting ? <SvgIcon component={MdDeleteOutline} onClick={removeLocalSetting} /> : ''}
                </Box>
            </AccordionSummary>
            <AccordionDetails className={style.accordionDetails}>
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction="row" justifyContent="space-between" spacing={3}>
                        <Box className={style.formInput}>
                            <Controller
                                name="time_resolution"
                                control={control}
                                defaultValue={15}
                                render={({ field }) => (
                                    <>
                                        <InputLabel variant="standard" required shrink htmlFor="time_resolution">
                                            {t('form.time_resolution_mins')}
                                        </InputLabel>
                                        <Select
                                            {...field}
                                            fullWidth
                                            // defaultValue={15}
                                            id="time_resolution"
                                            className={classNames(style.selectInput, 'cici')}
                                        >
                                            <MenuItem value={5}>5 min.</MenuItem>
                                            <MenuItem value={10}>10 min.</MenuItem>
                                            <MenuItem value={15}>15 min.</MenuItem>
                                            <MenuItem value={60}>60 min.</MenuItem>
                                        </Select>

                                        {errors?.time_resolution?.message && (
                                            <FormHelperText error>{errors.time_resolution.message}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Box>

                        <Box className={style.formInput}>
                            <Controller
                                name="aggregation_level"
                                control={control}
                                defaultValue={'ASSET'}
                                render={({ field }) => (
                                    <>
                                        <InputLabel variant="standard" required shrink htmlFor={'aggregation_level'}>
                                            {t('form.aggregation_level')}
                                        </InputLabel>
                                        <Select
                                            {...field}
                                            fullWidth
                                            id="aggregation_level"
                                            className={style.selectInput}
                                        >
                                            <MenuItem value={'ASSET'}>
                                                {t('form.aggregation_level_options.asset')}
                                            </MenuItem>
                                            {assetType === ASSET_TYPE.SOLAR ? (
                                                <MenuItem value={'CELL'}>
                                                    {t('form.aggregation_level_options.power_cell')}
                                                </MenuItem>
                                            ) : null}
                                            {assetType === ASSET_TYPE.WIND ? (
                                                <MenuItem value={'TURBINE'}>
                                                    {t('form.aggregation_level_options.turbine')}
                                                </MenuItem>
                                            ) : null}
                                        </Select>

                                        {errors?.aggregation_level?.message && (
                                            <FormHelperText error>{errors.aggregation_level.message}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Box>

                        <Box className={style.formInput}>
                            <Controller
                                name="use_realtime_data"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <>
                                        <InputLabel variant="standard" required shrink htmlFor={'use_realtime_data'}>
                                            {t('form.use_realtime_data')}
                                        </InputLabel>
                                        <Select
                                            {...field}
                                            fullWidth
                                            id="use_realtime_data"
                                            className={style.selectInput}
                                            value={field?.value}
                                        >
                                            <MenuItem value={true as any}>{'Yes'}</MenuItem>
                                            <MenuItem value={false as any}>{'No'}</MenuItem>
                                        </Select>

                                        {errors?.use_realtime_data?.message && (
                                            <FormHelperText error>{errors.use_realtime_data.message}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Box>
                    </Stack>

                    <Box className={style.checkboxContainer}>
                        <Controller
                            name="error_measurements"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink>
                                        {t('form.error_measurements_of_interest')}
                                    </InputLabel>
                                    <Stack direction="row" justifyContent="space-between" spacing={3}>
                                        {WIND_ERROR_MEASURMENTS_OF_INTEREST.map((item) => (
                                            <FormControlLabel
                                                key={item}
                                                className={style.checkboxItem}
                                                componentsProps={{ typography: { variant: 'small1' } }}
                                                control={
                                                    <Checkbox
                                                        name={item}
                                                        checked={
                                                            !!field?.value?.find(
                                                                (i) => i?.toLowerCase() === item?.toLowerCase()
                                                            )
                                                        }
                                                        onChange={() =>
                                                            field.onChange(handleCheck(item, 'error_measurements'))
                                                        }
                                                    />
                                                }
                                                label={item}
                                            />
                                        ))}
                                    </Stack>

                                    {(errors.error_measurements as unknown as FieldError)?.message && (
                                        <FormHelperText error>
                                            {(errors.error_measurements as unknown as FieldError)?.message}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />{' '}
                    </Box>

                    <Box className={style.checkboxContainer}>
                        <Controller
                            name="percentiles"
                            control={control}
                            defaultValue={null as unknown as boolean}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink>
                                        {t('form.forecast_percentiles')}
                                    </InputLabel>

                                    <Stack direction="row" spacing={3}>
                                        <FormControlLabel
                                            className={style.small}
                                            componentsProps={{ typography: { variant: 'small1' } }}
                                            control={
                                                <Radio
                                                    {...field}
                                                    onChange={() => {
                                                        field.onChange(true);
                                                    }}
                                                    checked={field.value === true}
                                                    name={field.name}
                                                />
                                            }
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            className={style.small}
                                            componentsProps={{ typography: { variant: 'small1' } }}
                                            control={
                                                <Radio
                                                    {...field}
                                                    onChange={() => {
                                                        field.onChange(false);
                                                    }}
                                                    checked={field.value === false}
                                                    name={field.name}
                                                />
                                            }
                                            label="No"
                                        />
                                    </Stack>

                                    {errors?.percentiles?.message && (
                                        <FormHelperText error>{errors?.percentiles?.message}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>

                    <Box className={style.checkboxContainer}>
                        <Controller
                            name="meteo_sources"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" shrink required>
                                        {t('form.sources')}
                                    </InputLabel>
                                    <Stack direction="row" spacing={3} justifyContent="space-between">
                                        {sources.map((item: MeteoSources) => (
                                            <FormControlLabel
                                                key={item.id}
                                                className={style.checkboxItem}
                                                componentsProps={{ typography: { variant: 'small1' } }}
                                                control={
                                                    <Checkbox
                                                        disabled={!item.active}
                                                        name={item.id}
                                                        checked={
                                                            !!field?.value?.find(
                                                                (i) => i?.toLowerCase() === item?.id.toLowerCase()
                                                            )
                                                        }
                                                        onChange={() =>
                                                            field.onChange(handleCheck(item.id, 'meteo_sources'))
                                                        }
                                                    />
                                                }
                                                label={item.name}
                                            />
                                        ))}
                                    </Stack>

                                    {(errors.meteo_sources as unknown as FieldError)?.message && (
                                        <FormHelperText error>
                                            {(errors.meteo_sources as unknown as FieldError)?.message}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>

                    <Box className={style.saveBtnContainer}>
                        <Button type="submit" variant="primary" fullWidth disabled={!isDirty}>
                            {generalTranslation('save')}
                        </Button>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default IndividualForecastSettings;
