import { DialogContent } from '@mui/material';

import { BaseModal } from '../../components/BaseModal';
import { MODAL_IDS } from '..';
import { CloseModalButton } from '../../components/BaseModal/CloseModalButton';
import ConfirmationForm, { ConfirmationFormProps } from './ConfirmationForm';

import style from './style.module.scss';

const ConfirmationModal = (props: ConfirmationFormProps) => {
    const maxWidth = props.error || props.success ? 'lg' : 'xs';
    return (
        <BaseModal maxWidth={maxWidth} fullWidth={false} closeOnBackdropClick id={MODAL_IDS.CONFIRMATION_MODAL}>
            <CloseModalButton id={MODAL_IDS.CONFIRMATION_MODAL} />
            <DialogContent classes={{ root: style.modalContainer }}>
                <ConfirmationForm {...props} />
            </DialogContent>
        </BaseModal>
    );
};

export { ConfirmationModal };
