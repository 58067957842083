import i18next from 'i18next';
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { GetParams } from '../../interfaces/general';
import ToastifyType from '../../utils/toastify-config';
import { DeliveredNotificationsFE } from '../../interfaces/uiv2';
import { transformDeliveredNotificationsAPItoFE } from './helpers';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { ApiPaginationInfo, DeliveredNotifications, PaginatedApiResponse } from '../../interfaces/apiv2';
import { DefaultInitialState, DEFAULT_GET_PARAMS_VALUE } from '../../interfaces/redux';
import { getQueryParamsFromObject } from '../../utils/table';
import { PAGINATION_SETTINGS } from '../../utils/config';
import axios from '../../utils/axios';

export interface NotificationsListGetParams extends GetParams {
    start_date?: string;
    end_date?: string;
    created_at__lte?: string;
    created_at__gte?: string;
    acknowledged?: boolean;
}

interface NotificationsListStore extends DefaultInitialState {
    notifications: DeliveredNotifications[];
    notificationsFE: DeliveredNotificationsFE[];
    searchParam: string | null;
    pager: ApiPaginationInfo | null;
    getParams: NotificationsListGetParams;
    selectedRows: DeliveredNotificationsFE[];
    markNotifications: number;
    notificationsAcknowledged: number;
}

export const getNotificationsListAsync = createAsyncThunk(
    'notifications_list/get',
    async (values: NotificationsListGetParams, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...values });
            const response = await axios.get<PaginatedApiResponse<DeliveredNotifications>>(
                `/notifications${queryParams}`
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);
export const getNotificationsAcknowledgedListAsync = createAsyncThunk(
    'notifications_list/get_acknowledged',
    async (values: void, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ size: 100, acknowledged: false });
            const response = await axios.get<PaginatedApiResponse<DeliveredNotifications>>(
                `/notifications${queryParams}`
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const markReportsListAsync = createAsyncThunk(
    'notifications_list/mark',
    async (ids: string[], { rejectWithValue }) => {
        try {
            await Promise.all(
                ids.map((id) => {
                    return axios.patch(`/notifications/${id}/acknowledge`);
                })
            );
            return null;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

const initialState: NotificationsListStore = {
    loading: false,
    error: false,
    success: false,
    notifications: [],
    notificationsFE: [],
    searchParam: null,
    pager: null,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    selectedRows: [],
    markNotifications: 0,
    notificationsAcknowledged: 0
};

export const listNotifications = createSlice({
    name: 'listNotifications',
    initialState,
    reducers: {
        resetNotificationsList: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<NotificationsListGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<DeliveredNotificationsFE[]>) => {
            state.selectedRows = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getNotificationsListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getNotificationsAcknowledgedListAsync.fulfilled, (state, { payload }) => {
            state.notificationsAcknowledged = payload.total;
        });
        builder.addCase(getNotificationsListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.notifications = items;
            } else {
                state.notifications = [...current(state).notifications, ...items];
            }
            state.notificationsFE = transformDeliveredNotificationsAPItoFE(current(state).notifications);
        });
        builder.addCase(getNotificationsListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(markReportsListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(markReportsListAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.markNotifications += 1;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(markReportsListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetNotificationsList, setSelectedRows, setGetParams } = listNotifications.actions;

export default listNotifications.reducer;
