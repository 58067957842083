import { isEqual } from 'lodash';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { DateRange } from 'react-day-picker';
import { useEffect, useMemo, useState } from 'react';
import { MdSchedule, MdTrendingUp, MdRefresh } from 'react-icons/md';

import {
    setGetParams,
    setSelectedRows,
    resetForecastsList,
    getForecastsListAsync
} from '../../../../stores/EVS/listForecasts';
// import { MODAL_IDS } from '../../../../modals';
import { getTableHeadCells } from './helpers';
import { useIsMount } from '../../../../hooks/isMount';
// import { ModalContext } from '../../../../hooks/modalContext';
import useStickyHeader from '../../../../hooks/useStickyHeader';
import { PageContainer } from '../../../../layout/PageContainer';
import { DeliveredForecastFE } from '../../../../interfaces/uiv2';
import { useAppDispatch, useAppSelector } from '../../../../stores';
import { GenericTable, TableSearch } from '../../../../components/BaseTable';
import { DateRangeInput } from '../../../../components/DateRangeInput/DateRangeInput';
import { DownloadButton, DownloadExtension } from '../../../../components/DownloadButton';
import { transformOrderByToSort } from '../../../../components/BaseTable/GenericTable/helpers';
import { getForecastFilteringFormattedDate, PAGINATION_SETTINGS, ROUTES } from '../../../../utils/config';

import style from './ForecastList.module.scss';

const EVSForecastList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isFirstRender = useIsMount();
    // const { handleClickOpen } = useContext(ModalContext);
    const [dateRange, setDateRange] = useState<DateRange | null>(null);

    useStickyHeader('forecast-list-header');

    const headCells = getTableHeadCells(t);
    const getParams = useAppSelector((state) => state.listEVSForecasts.getParams, isEqual);
    const { success: deleteSuccess } = useAppSelector((state) => state.deleteForecasts);
    const {
        selectedRows,
        forecastsFE: rows,
        loading,
        pager,
        success
    } = useAppSelector((state) => state.listEVSForecasts);

    useEffect(() => {
        if (dateRange?.from && dateRange?.to) {
            dispatch(
                setGetParams({
                    from_date__gte: getForecastFilteringFormattedDate(dateRange.from),
                    to_date__lte: getForecastFilteringFormattedDate(dateRange.to),
                    page: PAGINATION_SETTINGS.default_start_page
                })
            );
        }

        if (!dateRange && !isFirstRender) {
            dispatch(
                setGetParams({
                    from_date__gte: undefined,
                    to_date__lte: undefined,
                    page: PAGINATION_SETTINGS.default_start_page
                })
            );
        }
    }, [dateRange]);

    useEffect(() => {
        dispatch(getForecastsListAsync({ ...getParams }));
    }, [getParams, deleteSuccess]);

    const handleSort = (type: string) => () => {
        dispatch(setGetParams({ order_by: [type], page: PAGINATION_SETTINGS.default_start_page }));
    };

    const clearSelection = () => {
        dispatch(setSelectedRows([]));
    };

    const getForecastPageUrl = (row: DeliveredForecastFE) => () => {
        return `${ROUTES.EVS_FORECASTS_PAGE.replace(':forecastId', row.id)}`;
    };

    // const openConfirmationModal = () => {
    //     handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    // };

    const ids = useMemo(() => {
        return selectedRows.map((e) => e.id);
    }, [selectedRows]);

    return (
        <PageContainer>
            <Box id="forecast-list-header" className={style.header}>
                <Typography variant="h6" className={style.title}>
                    {t('title.evsForecast')}
                </Typography>
                {selectedRows.length ? (
                    <Box className={style.toolbar}>
                        {/* selected items toolbar */}

                        <Typography variant="small4" className={style.label}>
                            {selectedRows.length} {t('table.general.selected')}
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <MdRefresh />
                            <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                {t('table.general.clearSelection')}
                            </Box>
                        </Typography>

                        <Typography variant="small4" className={style.element}>
                            <Box
                                component="span"
                                className={classnames(style.primary, style.nodecoration)}
                                sx={{ ml: 1, mr: 1 }}
                            >
                                {t('table.general.downloadAll')}
                            </Box>
                            <DownloadButton id={ids} type={DownloadExtension.Txt} />
                        </Typography>
                        <TableSearch setGetParams={setGetParams} getParams={getParams} />
                    </Box>
                ) : (
                    <>
                        {/* Non selected toolbar */}
                        <Box className={style.toolbar}>
                            <Typography variant="small4" className={style.label}>
                                {t('toolbar.sort')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdSchedule />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={handleSort('-created_at')}
                                    sx={{ ml: 1 }}
                                >
                                    {t('toolbar.mostRecent')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdTrendingUp />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={handleSort('created_at')}
                                    sx={{ ml: 1 }}
                                >
                                    {t('toolbar.oldestToNewest')}
                                </Box>
                            </Typography>
                            <DateRangeInput
                                range={dateRange}
                                setRange={setDateRange}
                                label={t('table.general.selectPeriod')}
                                className={style.selectPeriod}
                            />
                            <TableSearch setGetParams={setGetParams} getParams={getParams} />
                        </Box>
                    </>
                )}
            </Box>

            <GenericTable
                rows={rows}
                pager={pager}
                loading={loading}
                success={success}
                isCheckboxEnabled
                columns={headCells}
                cleanupCb={() => dispatch(resetForecastsList())}
                rowUniqueId={'id'}
                selectedRows={selectedRows}
                setGetParams={setGetParams}
                searchParam={getParams.search}
                setSelectedRows={setSelectedRows}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getForecastPageUrl
                }}
            />
        </PageContainer>
    );
};

export default EVSForecastList;
