import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Typography, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { MdDeleteOutline, MdRefresh } from 'react-icons/md';

import {
    importConsumersAsync,
    setGetConsumersParams,
    getConsumersListAsync,
    resetListConsumersState,
    setSelectedRowsConsumers,
    deleteConsumersListAsync
} from '../../../stores/Demand';
import { MODAL_IDS } from '../../../modals';
import { ROUTES } from '../../../utils/config';
import { getConsumersHeadCells } from '../helpers';
import { ModalContext } from '../../../hooks/modalContext';
import { ConsumersDataFE } from '../../../interfaces/uiv2';
import { PageContainer } from '../../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { ImportFileModalProps } from '../../../modals/ImportFileModal';
import { AssetLocationModal } from '../../../modals/AssetLocationModal';
import { GenericTable, TableSearch } from '../../../components/BaseTable';
import { ReactComponent as OgreUploadIcon } from '../../../assets/icons/upload.svg';
import { ImportFileModal } from '../../../modals/ImportFileModal/ImportFileModal';
import { transformOrderByToSort } from '../../../components/BaseTable/GenericTable/helpers';

import style from '../../../features/forecast-list/forecast-list.module.scss';
import { RemoveForecastContainer } from '../../../components/RemoveEntityContainer';
import useStickyHeader from '../../../hooks/useStickyHeader';

const ConsumersList = () => {
    const dispatch = useAppDispatch();
    const { projectId = '' } = useParams();
    const { handleClickOpen } = useContext(ModalContext);
    const [activeRow, setActiveRow] = useState<ConsumersDataFE>();

    const { user } = useAppSelector((state) => state.login);
    const { consumersFE, loading, getParams, pager, success, selectedRows } = useAppSelector(
        (state) => state.listConsumers
    );
    const { deleted } = useAppSelector((state) => state.deleteConsumers);

    useEffect(() => {
        dispatch(getConsumersListAsync({ getParams, projectId }));
        return () => {
            dispatch(resetListConsumersState());
        };
    }, []);

    useEffect(() => {
        dispatch(getConsumersListAsync({ getParams, projectId }));
    }, [deleted, getParams, projectId]);

    const { t } = useTranslation();
    const headCells = getConsumersHeadCells(t);

    const clearSelection = () => {
        dispatch(setSelectedRowsConsumers([]));
    };

    const openConfirmationModal = () => {
        handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    };

    const openModal = (modalId: MODAL_IDS) => (row: ConsumersDataFE) => () => {
        setActiveRow(row);
        handleClickOpen(modalId);
    };

    const getConsumersPageUrl = (row: ConsumersDataFE) => () => {
        return `${ROUTES.AI_DEMAND_CONSUMERS_PAGE.replace(':projectId', projectId).replace(':id', row.id)}`;
    };

    const getProfilePageUrl = (row: ConsumersDataFE) => () => {
        return `${ROUTES.AI_DEMAND_PROFILES_PAGE.replace(':projectId', projectId).replace(':id', row.profile?.id)}`;
    };

    const openImportModal = (modalProps: ImportFileModalProps) => () => {
        setImportModalProps(modalProps);
        handleClickOpen(MODAL_IDS.IMPORT_FILE_MODAL);
    };

    const removeConsumers = () => {
        dispatch(deleteConsumersListAsync({ ids: selectedRows.map((a) => a.id), projectId }));
        clearSelection();
    };

    const importConsumers = (type: 'import' | 'historical') => (file: File) => {
        dispatch(importConsumersAsync({ projectId, file, type }));
    };

    const consumerDataProps: ImportFileModalProps = {
        callback: importConsumers('import'),
        titleLabel: t('imports.importConsumersTitle'),
        subtitleLabel: t('imports.importClustersSubtitle')
    };
    const historicalDataProps: ImportFileModalProps = {
        callback: importConsumers('historical'),
        titleLabel: t('imports.importConsumersHistoricalTitle'),
        subtitleLabel: t('imports.importClustersSubtitle')
    };

    useStickyHeader('consumers-list-header');
    const [importModalProps, setImportModalProps] = useState<ImportFileModalProps>(consumerDataProps);

    return (
        <PageContainer>
            <Box className={style.header} id="consumers-list-header">
                <Typography variant="h6" className={style.title}>
                    {t('header.consumers')}
                </Typography>

                <Box className={style.toolbar}>
                    {!!selectedRows.length && (
                        <>
                            <Typography variant="small4" className={style.label}>
                                {selectedRows.length} {t('table.general.selected')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdRefresh />
                                <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                    {t('table.general.clearSelection')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdDeleteOutline />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={openConfirmationModal}
                                    sx={{ ml: 1 }}
                                >
                                    {t('table.general.removeAll')}
                                </Box>
                            </Typography>
                        </>
                    )}

                    <TableSearch setGetParams={setGetConsumersParams} getParams={getParams} />
                    {user?.superadmin ? (
                        <Button disableRipple variant="cta" onClick={openImportModal(consumerDataProps)} sx={{ ml: 1 }}>
                            <OgreUploadIcon />
                        </Button>
                    ) : null}
                    {user?.superadmin ? (
                        <Button
                            disableRipple
                            variant="cta"
                            onClick={openImportModal(historicalDataProps)}
                            sx={{ ml: 1 }}
                        >
                            <UploadIcon className={style.historical} />
                        </Button>
                    ) : null}
                </Box>
            </Box>

            <GenericTable
                pager={pager}
                loading={loading}
                success={success}
                rows={consumersFE}
                isCheckboxEnabled
                rowUniqueId={'id'}
                columns={headCells}
                selectedRows={selectedRows}
                searchParam={getParams.search}
                setSelectedRows={setSelectedRowsConsumers}
                setGetParams={setGetConsumersParams}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getConsumersPageUrl,
                    profile: getProfilePageUrl,
                    location: openModal(MODAL_IDS.ASSET_LOCATION_MODAL)
                }}
            />
            {activeRow && (
                <AssetLocationModal
                    payload={{
                        longitude: activeRow.longitude,
                        latitude: activeRow.latitude,
                        title: activeRow.name,
                        location: activeRow.country || ''
                    }}
                />
            )}

            <ImportFileModal {...importModalProps} />
            <RemoveForecastContainer cb={removeConsumers} stateAccessor="deleteConsumers" />
        </PageContainer>
    );
};

export default ConsumersList;
