import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';
import i18next from '../../../utils/i18next';
import ToastifyType from '../../../utils/toastify-config';
import { DefaultInitialState } from '../../../interfaces/redux';
import { commonReduxErrorHandler } from '../../../utils/error-handling';
import { AssetLocation } from '../../../interfaces/apiv2';

interface deleteAssetLocationsState extends DefaultInitialState {
    deleted: number;
}

const initialState: deleteAssetLocationsState = {
    loading: false,
    error: false,
    success: false,
    deleted: 0
};

export const deleteAssetLocationsAsync = createAsyncThunk(
    'assets/delete-locations',
    async (body: { assetId: string; id: string }, { rejectWithValue }) => {
        try {
            const endpoint = `/electricity/generation/assets/${body.assetId}/locations/${body.id}`;

            const response = await axios.delete<AssetLocation>(endpoint);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const deleteAssetLocations = createSlice({
    name: 'deleteAssetLocations',
    initialState,
    reducers: {
        resetDeleteAssetLocationsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(deleteAssetLocationsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(deleteAssetLocationsAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.deleted += 1;
        });
        builder.addCase(deleteAssetLocationsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetDeleteAssetLocationsState } = deleteAssetLocations.actions;

export default deleteAssetLocations.reducer;
