import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import successImg from '../../assets/images/success-visual.png';

import style from './style.module.scss';

const PageUnderDevelopment = ({ forbidden }: { forbidden?: boolean }) => {
    const { t: generalTranslation } = useTranslation();

    return (
        <Box className={style.box}>
            <Box className={style.content}>
                {!forbidden ? (
                    <>
                        <Typography variant="h1">{generalTranslation('underDevelopmentTitle')}</Typography>
                        <Typography variant="h4">{generalTranslation('underDevelopmentBody')}</Typography>
                    </>
                ) : (
                    <>
                        <Typography variant="h1">{generalTranslation('forbidden')}</Typography>
                    </>
                )}
            </Box>
            <img src={successImg} alt="success" />
        </Box>
    );
};

export default PageUnderDevelopment;
