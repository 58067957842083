import i18next from 'i18next';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import {
    FORECAST_TYPE,
    ForecastDataGetParams,
    GasForecastFE,
    GenerationTableForecastDataPayloadSerialized
} from '../../../../interfaces/uiv2';
import axios from '../../../../utils/axios';
import ToastifyType from '../../../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../../../utils/config';
import { getQueryParamsFromObject } from '../../../../utils/table';
import { getDemandChartThunk } from '../../../../utils/common-thunks';
import { DownloadExtension } from '../../../../components/DownloadButton';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { transformEntityToAPItoFE, transformGasForecastToFE } from '../../helpers';
import { sortForecastData, transformForecastToView } from '../../../../utils/forecast';
import { ApiPaginationInfo, GasConsumer, GasForecast } from '../../../../interfaces/apiv2';
import { DefaultInitialState, DEFAULT_GET_PARAMS_VALUE } from '../../../../interfaces/redux';

interface GasConsumerPage extends DefaultInitialState {
    consumer: GasConsumer | null;
    consumerFE: GasConsumer | null;
    download: boolean;
    forecasts: LineData[][];
    generationForecasts: GasForecastFE[];
    errorForecast: boolean;
    loadingForecast: boolean;
    successForecast: boolean;
    errorMeasured: boolean;
    loadingDownload: boolean;
    loadingMeasured: boolean;
    successMeasured: boolean;
    limitLeftForecastBoundary: boolean;
    limitLeftMeasuredBoundary: boolean;
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
    generationTablePayloadTemp: GenerationTableForecastDataPayloadSerialized | null;
}

export const getGasConsumerAsync = createAsyncThunk<GasConsumer, { id: string; projectId: string }>(
    'gas_consumer_page/show',
    async (values: { id: string; projectId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<GasConsumer>(`/gas/demand/${values.projectId}/consumers/${values.id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const downloadGasConsumerData = createAsyncThunk(
    'GasConsumerPage/download',
    async (
        {
            consumer,
            projectId,
            ...rest
        }: {
            consumer: GasConsumer;
            projectId: string;
            from_datetime: string;
            to_datetime: string;
            file_type: DownloadExtension;
        },
        { rejectWithValue }
    ) => {
        toast.success(i18next.t('downloadStarted'), ToastifyType.success);
        try {
            const url = `/gas/demand/${projectId}/consumers/${consumer.id}/forecast/download${getQueryParamsFromObject({
                ...rest
            })}`;
            const response = await axios.get(url, {
                responseType: 'blob'
            });

            const extension = rest.file_type === DownloadExtension.Excel ? 'xlsx' : rest.file_type.toLowerCase();

            fileDownload(
                response.data,
                `${consumer.name}__${new Date(Number(rest.from_datetime)).toISOString()}__${new Date(
                    Number(rest.to_datetime)
                ).toISOString()}.${extension}`
            );
            toast.success(i18next.t('downloadSuccess'), ToastifyType.success);
        } catch (e) {
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getGasConsumerForecastAsync = getDemandChartThunk({
    thunkName: 'gas_consumer/forecast',
    endpoint: 'forecast',
    module: 'gas',
    submodule: 'consumers'
});

export const getGasConsumerGenerationForecastAsync = getDemandChartThunk({
    thunkName: 'gas_consumer/generation_forecast',
    endpoint: 'reporting',
    module: 'gas',
    submodule: 'consumers'
});

export const getGasConsumerMeasuredAsync = getDemandChartThunk({
    thunkName: 'gas_consumer/measured',
    endpoint: 'historical',
    module: 'gas',
    submodule: 'consumers'
});

export const getGasConsumerMoreForecastAsync = getDemandChartThunk({
    thunkName: 'gas_consumer/forecast_more',
    endpoint: 'forecast',
    module: 'gas',
    submodule: 'consumers'
});

export const getGasConsumerMoreMeasuredAsync = getDemandChartThunk({
    thunkName: 'gas_consumer/measured_more',
    endpoint: 'historical',
    module: 'gas',
    submodule: 'consumers'
});

const initialState: GasConsumerPage = {
    loading: false,
    error: false,
    success: false,
    consumer: null,
    consumerFE: null,
    download: false,
    forecasts: [[], []],
    generationForecasts: [],
    errorForecast: false,
    loadingForecast: false,
    successForecast: false,
    loadingDownload: false,
    errorMeasured: false,
    loadingMeasured: false,
    successMeasured: false,
    limitLeftForecastBoundary: false,
    limitLeftMeasuredBoundary: false,
    pager: null,
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        // order_by: ['-created_on']
        order_by: undefined
    },
    generationTablePayloadTemp: null
};

export const gasConsumerPage = createSlice({
    name: 'gasConsumerPage',
    initialState,
    reducers: {
        resetGasConsumerPage: () => initialState,
        setActiveAsset: (state, { payload }: PayloadAction<GasConsumer | null>) => {
            state.consumer = payload;
        },
        downloadChart: (state, { payload }) => {
            state.download = payload;
        },
        resetForecastData: (state) => {
            state.forecasts = [[], []];
            state.errorForecast = false;
            state.loadingForecast = false;
            state.successForecast = false;
            state.errorMeasured = false;
            state.loadingMeasured = false;
            state.successMeasured = false;
            state.limitLeftForecastBoundary = false;
            state.limitLeftMeasuredBoundary = false;
        },
        loadMore: (state) => {
            const { generationForecasts, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: generationForecasts.length
            };
        },
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setGenerationTablePayloadTemp: (state, { payload }) => {
            state.generationTablePayloadTemp = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(downloadGasConsumerData.pending, (state) => {
            state.loadingDownload = true;
        });
        builder.addCase(downloadGasConsumerData.fulfilled, (state) => {
            state.loadingDownload = false;
        });
        builder.addCase(downloadGasConsumerData.rejected, (state) => {
            state.loadingDownload = false;
        });
        builder.addCase(getGasConsumerAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getGasConsumerAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.consumer = payload;
            state.consumerFE = transformEntityToAPItoFE([payload])[0];
        });
        builder.addCase(getGasConsumerAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getGasConsumerForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getGasConsumerForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            const actualData = (current(state).forecasts || [])[0] || [];

            state.forecasts[0] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.GAS, state.consumer?.country),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftForecastBoundary = true;
            }
        });
        builder.addCase(getGasConsumerForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
        builder.addCase(getGasConsumerGenerationForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getGasConsumerGenerationForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            state.generationForecasts = transformGasForecastToFE(payload as unknown as GasForecast[]);
        });
        builder.addCase(getGasConsumerGenerationForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
        builder.addCase(getGasConsumerMeasuredAsync.pending, (state) => {
            state.loadingMeasured = true;
            state.errorMeasured = false;
            state.successMeasured = false;
        });
        builder.addCase(getGasConsumerMeasuredAsync.fulfilled, (state, { payload }) => {
            state.loadingMeasured = false;
            state.errorMeasured = false;
            state.successMeasured = true;
            const actualData = (current(state).forecasts || [])[1] || [];
            state.forecasts[1] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.GAS, state.consumer?.country),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftMeasuredBoundary = true;
            }
        });
        builder.addCase(getGasConsumerMeasuredAsync.rejected, (state) => {
            state.loadingMeasured = false;
            state.errorMeasured = true;
            state.successMeasured = false;
        });

        builder.addCase(getGasConsumerMoreForecastAsync.fulfilled, (state, { payload }) => {
            const actualData = (current(state).forecasts || [])[0] || [];

            state.forecasts[0] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.GAS),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftForecastBoundary = true;
            }
        });

        builder.addCase(getGasConsumerMoreMeasuredAsync.fulfilled, (state, { payload }) => {
            const actualData = (current(state).forecasts || [])[1] || [];
            state.forecasts[1] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.GAS),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftMeasuredBoundary = true;
            }
        });
    }
});

export const {
    resetGasConsumerPage,
    setGenerationTablePayloadTemp: setGasConsumerPageGenerationTablePayloadTemp,
    resetForecastData: resetGasConsumerForecastData,
    loadMore: loadMoreGasConsumerData,
    setGetParams: setGetParamsGasConsumerPage
} = gasConsumerPage.actions;

export default gasConsumerPage.reducer;
