import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../../../utils/axios';
import i18next from '../../../../utils/i18next';
import ToastifyType from '../../../../utils/toastify-config';
import { DefaultInitialState } from '../../../../interfaces/redux';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { GasDashboardLayout } from '../../../../interfaces/uiv2';

interface ListGasDashboardState extends DefaultInitialState {
    dashboard?: GasDashboardLayout;
}

const initialState: ListGasDashboardState = {
    dashboard: undefined,
    loading: false,
    error: false,
    success: false
};

export const getGasDashboardAsync = createAsyncThunk(
    'dashboard-gas/list',
    async ({ projectId }: { projectId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<GasDashboardLayout>(`/gas/demand/${projectId}/stats`);
            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listGasDashboard = createSlice({
    name: 'listGasDashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getGasDashboardAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getGasDashboardAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.dashboard = payload;
        });
        builder.addCase(getGasDashboardAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export default listGasDashboard.reducer;
