import { Layouts } from 'react-grid-layout';

import { DefaultEntityTimestamps, GenerationAsset, User } from '.';

export enum DashboardType {
    Generation = 'Generation'
}

export const DASHBOARD_CHART_TYPE = ['Bar', 'Line', 'Pie', 'Donut'] as const;
export type DashboardChartType = typeof DASHBOARD_CHART_TYPE[number];

export const DASHBOARD_CHART_SIZE = [1, 2, 3] as const;
export type DashboardChartSize = typeof DASHBOARD_CHART_SIZE[number];

export interface Dashboard extends DefaultEntityTimestamps {
    id: string;
    type: DashboardType;
    layout: Layouts;
    user_id: string;

    user: User;
}

export interface PostDashboard {
    type: DashboardType;
    layout: Layouts;
}

export interface PatchDashboard {
    id: string;
    body: PostDashboard & { id: string };
}

export interface DashboardItem extends DefaultEntityTimestamps {
    id: string;
    size: number;
    chart_type: DashboardChartType;
    dashboard_id: string;
    asset_id: string;
    vpp_id: string;

    asset?: GenerationAsset;
    vpp?: any;
}

export interface PostDashboardItem {
    size: number;
    chart_type: DashboardChartType;
    asset_id?: string;
    vpp_id?: string;
}

export interface PatchDashboardItem {
    id: string;
    body: PostDashboardItem & { id: string };
}

export interface DeleteDashboardItem {
    id: string;
    dashboard_id: string;
}
