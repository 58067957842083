import axios from '../../utils/axios';
import { DefaultInitialState } from '../../interfaces/redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ToastifyType from '../../utils/toastify-config';
import i18next from '../../utils/i18next';
import { PostRunForecastBody } from '../../interfaces/uiv2';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const runForecastAsync = createAsyncThunk(
    'assets/runForecastAsync',
    async (values: PostRunForecastBody, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `/electricity/generation/assets/${values.assetId}/run-forecast`,
                {
                    start_date: values.start_date,
                    end_date: values.end_date
                },
                {
                    headers: {
                        ApiKey: values.apiKey
                    }
                }
            );

            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
    }
);

export const runForecast = createSlice({
    name: 'runForecast',
    initialState,
    reducers: {
        resetRunForecastState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(runForecastAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(runForecastAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('forecastStarted'), ToastifyType.success);
        });
        builder.addCase(runForecastAsync.rejected, (state, error: any) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(`${i18next.t('forecastFailed')}: ${error.payload.detail}`, ToastifyType.error);
        });
    }
});

export const { resetRunForecastState } = runForecast.actions;

export default runForecast.reducer;
