import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { PostGasReportBody } from '../../interfaces/uiv2';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';

interface CreateGasReport extends DefaultInitialState {
    created: number;
}

const initialState: CreateGasReport = {
    loading: false,
    error: false,
    success: false,
    created: 0
};

const defaultBody = {
    name: '',
    type: 'GAS_CONSUMERS',
    from_timestamp: 0,
    to_timestamp: 0,
    columns: {
        name: 'POD',
        measured_division_profiled: 'Consum (profilare zilnică)',
        forecasted_division_profiled: 'Predicție (profilare zilnică / intervale)',
        volume_profiled_smart: 'Consum (profilare zilnică meteo)',
        cluster_forecasted: 'Predicție (profilare zilnică meteo / intrată)'
    }
};

export const createReportAsync = createAsyncThunk(
    'reports/create',
    async ({ project_id, body }: { project_id: string; body: PostGasReportBody }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`/gas/demand/${project_id}/consumers/reporting/generate`, {
                ...defaultBody,
                ...body
            });

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const createGasReport = createSlice({
    name: 'createGasReport',
    initialState,
    reducers: {
        resetCreateGasReportState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createReportAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(createReportAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.created += 1;
        });
        builder.addCase(createReportAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
    }
});

export const { resetCreateGasReportState } = createGasReport.actions;

export default createGasReport.reducer;
