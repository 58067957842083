import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Theme } from '../../containers/Theme';
import { Box } from '@mui/material';
import { DATE_SETTINGS } from '../../utils/config';
import { TableCellRenderer } from '../../interfaces/general';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../utils/table';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { DefaultSearchableCell, TableActions } from '../../components/BaseTable';

import { ReactComponent as LocationSvg } from '../../assets/icons/location.svg';

import style from './style.module.scss';
import common from '../../common.module.scss';
import { ALL_TYPES, getAssetIcon } from '../../utils/getAssetIcon';
import Flag from 'react-world-flags';
import classNames from 'classnames';
import AssetStatusLabel from '../../components/AssetStatusLabel/AssetStatusLabel';
import { CircleProgressBar } from '../../components/BaseTable/CustomCells/CircleProgressBar';
import { ReactComponent as ClustersIcon } from '../../assets/icons/demand/clusters.svg';
import { ChargingPointsFE, ChargingStationsFE, EVSClusterFE, UNIT } from '../../interfaces/uiv2';
import { convertMeasureUnitToUnitId } from '../../utils/helpers';

export const getChargingStationsHeadCells = (tList: TFunction): TableCellRenderer<ChargingStationsFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 75,
            label: tList('tableHead.type'),
            value: (row) => {
                return getAssetIcon(row.type as ALL_TYPES);
            },
            sort: true,
            sort_id: 'asset.type'
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            sort: false,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.name;
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;

                return <DefaultSearchableCell value={value} searchValue={searchValue} isLink linkUrl={callback()} />;
            }
        },
        {
            id: 'charging_points',
            maxWidth: 110,
            label: tList('assetInfo.charging_points'),
            sort: false,
            justifyContent: 'center',
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['charging_points'];

                return (
                    <DefaultSearchableCell
                        className={style.chargingPointsSwitch}
                        value={`${row.charging_points.length}`}
                        searchValue={null}
                        onClick={() => handler(row)}
                    />
                );
            }
        },
        {
            id: 'country',
            justifyContent: 'center',
            label: tList('tableHead.country'),
            value: (row, searchValue) => {
                const value = row?.country.toUpperCase() || '';

                return (
                    <DefaultSearchableCell value={value} searchValue={searchValue}>
                        <Flag height="24" width="24" code={value} className={classNames(style.flagIcon, 'flagIcon')} />
                    </DefaultSearchableCell>
                );
            },
            sort: false
        },
        {
            id: 'location',
            label: tList('tableHead.location'),
            value: (row, searchValue, onClick, handlers) => {
                const value = row.city || '';
                const handler = handlers?.['location'];

                const callback = handler ? handler(row) : undefined;

                return (
                    <>
                        <DefaultSearchableCell
                            value={value}
                            searchValue={searchValue}
                            onClick={callback}
                            hyperlinkColor={`${Theme.palette.primary.main} !important`}
                        >
                            <Box>
                                <LocationSvg className={common.locationSVG} />
                            </Box>
                        </DefaultSearchableCell>
                    </>
                );
            },
            sort: false
        },
        // {
        //     id: 'latest_forecast',
        //     label: tList('assetInfo.latestForecasts'),
        //     sort: false,
        //     value: (row) => <ForecastBarGraph asset={row} />
        // },
        {
            id: 'status',
            label: tList('tableHead.status'),
            sort: false,
            value: (row) => {
                return <AssetStatusLabel status={row.status} />;
            }
        },
        {
            id: 'accuracy',
            label: tList('tableHead.accuracy'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const value = +(row.accuracy || 0) * 100;
                return <CircleProgressBar value={value} />;
            }
        }
        // {
        //     id: 'actions',
        //     sort: false,
        //     maxWidth: 70,
        //     justifyContent: 'center',
        //     label: tList('actions'),
        //     value: () => {
        //         return (
        //             <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        //                 <TableActions
        //                     className={style.editActionStyling}
        //                     actions={[
        //                         {
        //                             id: 'addToDashboard',
        //                             label: 'table.general.addToDashboard',
        //                             disabled: false,
        //                             callback: () => {}
        //                         },
        //                         {
        //                             id: 'editInfo',
        //                             label: 'table.general.editInfo',
        //                             disabled: false,
        //                             callback: () => {}
        //                         }
        //                     ]}
        //                 />
        //             </Box>
        //         );
        //     }
        // }
    ];
};

export const getChargingPointsHeadCells = (
    tList: TFunction,
    assetMeasureUnit: UNIT
): TableCellRenderer<ChargingPointsFE>[] => {
    const unitId = convertMeasureUnitToUnitId(assetMeasureUnit);

    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 75,
            label: tList('tableHead.type'),
            value: (row) => {
                return getAssetIcon(row.type as ALL_TYPES);
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            sort: false,
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;

                return <DefaultSearchableCell value={row.name} searchValue={null} isLink linkUrl={callback()} />;
            }
        },
        {
            id: 'connector',
            justifyContent: 'center',
            label: tList('tableHead.connector'),
            sort: false,
            value: (row) => {
                return <DefaultSearchableCell value={row.external_id} searchValue={null} />;
            }
        },
        {
            id: 'power',
            label: `${tList('tableHead.power')} (${unitId})`,
            sort: false,
            value: (row) => {
                return <DefaultSearchableCell value={row.power.toString()} searchValue={null} />;
            }
        },
        {
            id: 'connector_type',
            label: tList('tableHead.connector_type'),
            sort: false,
            value: (row) => {
                return <DefaultSearchableCell value={row.connector_type} searchValue={null} />;
            }
        },
        {
            id: 'electricity_type',
            label: tList('tableHead.electricity_type'),
            sort: false,
            value: (row) => {
                return <DefaultSearchableCell value={row.electricity_type} searchValue={null} />;
            }
        },
        {
            id: 'status',
            label: tList('tableHead.status'),
            sort: false,
            value: (row) => {
                return <AssetStatusLabel status={row.status} />;
            }
        },
        {
            id: 'accuracy',
            label: tList('tableHead.accuracy'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const value = +(row.accuracy || 0) * 100;
                return <CircleProgressBar value={value} />;
            }
        },
        {
            id: 'connectivity_ratio',
            label: tList('tableHead.connectivity_ratio'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const value = +(row.connectivity_ratio || 0) * 100;
                return <CircleProgressBar value={value} />;
            }
        }
    ];
};

export const getClustersHeadCells = (tList: TFunction): TableCellRenderer<EVSClusterFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 70,
            label: tList('tableHead.type'),
            value: () => {
                return <ClustersIcon />;
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name_id'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.name}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },
        {
            id: 'totalChargingStations',
            label: tList('tableHead.totalChargingStations'),
            justifyContent: 'center',
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={`${row.totalChargingStations}`} searchValue={searchValue} />;
            },
            sort: true
        },
        {
            id: 'totalLocations',
            label: tList('tableHead.totalLocations'),
            justifyContent: 'center',
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={`${row.totalLocations}`} searchValue={searchValue} />;
            },
            sort: true
        },
        {
            id: 'created_at',
            label: tList('tableHead.createdAt'),
            justifyContent: 'center',
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.created_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'addToVPP',
                                    label: 'table.general.addToVPP',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'addToDashboard',
                                    label: 'table.general.addToDashboard',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }
                            ]}
                        />
                    </Box>
                );
            }
        }
    ];
};
