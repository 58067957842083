import classnames from 'classnames';
import { Layout } from 'react-grid-layout';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, SvgIcon, Typography } from '@mui/material';

import { getDemandIcon } from '../../utils/helpers';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';

import style from './style.module.scss';
import { getProfileData } from './helpers';
import { GasDashboardLayout } from '../../interfaces/uiv2';

export const GasDashboardProfileCard = ({ dashboard, l }: { l: Layout; dashboard: GasDashboardLayout }) => {
    const { t } = useTranslation();
    const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });

    const graphRef = useCallback((node) => {
        if (node !== null) {
            setBoundingRect(node.getBoundingClientRect());
        }
    }, []);

    const totalConsumers = Object.values(dashboard.consumers_per_profile).reduce((a, c) => a + c, 0);

    return (
        <>
            <Box className={style.itemHeader}>
                <Box className={style.name}>
                    <SvgIcon component={getDemandIcon('profile')} />
                    <Typography variant="h7" className="dragDisable">
                        {t('header.profil')}
                    </Typography>
                </Box>
            </Box>

            <Box className={classnames(style.itemDetails)}>
                <Typography className={style.element} variant="small4">
                    {t('dashboardDemand.totalConsumatori')}
                    <Typography variant="small3" className={style.black} sx={{ ml: 1.5 }}>
                        {totalConsumers}
                    </Typography>
                </Typography>
            </Box>

            <Box className={style.itemChartContainer}>
                <Box
                    className={classnames(style.itemChart, l.w === 12 && style.p12, l.w === 8 && style.p12)}
                    ref={graphRef}
                >
                    <VictoryChart height={boundingRect.height} width={boundingRect.width}>
                        <VictoryBar
                            style={{
                                data: { fill: 'url(#barGradient)', strokeLinejoin: 'round' },
                                labels: { fill: '#3745F2' }
                            }}
                            barRatio={1.2}
                            cornerRadius={{ topLeft: 4, topRight: 4 }}
                            x={'date'}
                            y={'value'}
                            data={getProfileData(dashboard)}
                            labels={({ datum }) => datum.value}
                        />
                        <VictoryAxis
                            offsetY={50}
                            crossAxis={false}
                            style={{
                                axis: {
                                    stroke: '#D5E3FF'
                                },
                                tickLabels: {
                                    fill: '#828282'
                                }
                            }}
                        />
                    </VictoryChart>
                </Box>
            </Box>
        </>
    );
};
