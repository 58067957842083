import './index.scss';
import { useEffect } from 'react';
import { default as defaultNProgress } from 'nprogress';

const NProgress = () => {
    useEffect(() => {
        defaultNProgress.configure({ showSpinner: false });
        defaultNProgress.start();
        defaultNProgress.done();
    }, []);

    return null;
};

export default NProgress;
