import { Client } from '../../interfaces/apiv2';
import { ClientFE } from '../../interfaces/uiv2';
import { getActiveModulesFromPermissions } from '../../utils/permissions';

export const transformClientsAPIToFE = (clients: Client[]): ClientFE[] => {
    return clients.map((client, index) => {
        const activatedModules = client.permissions ? getActiveModulesFromPermissions(client.permissions) : [];

        return {
            ...client,
            index: index + 1,
            activatedModules
        };
    });
};
