import { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { DefaultInitialState } from '../../interfaces/redux';
import { User, PostUser, PatchUser } from '../../interfaces/apiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const createUserAsync = createAsyncThunk<User, PostUser>(
    'users/create',
    async (body: PostUser, { rejectWithValue }) => {
        try {
            const response = await axios.post<User, AxiosResponse<User>, PostUser>(`/users`, body);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const updateUserAsync = createAsyncThunk(
    'users/update',
    async ({ body, id }: PatchUser, { rejectWithValue }) => {
        try {
            const response = await axios.patch<User, AxiosResponse<User>, PatchUser['body']>(`/users/${id}`, body);

            // const [userFE] = transformer([response.data]);
            // dispatch(updateById(userFE));

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const createUser = createSlice({
    name: 'createUser',
    initialState,
    reducers: {
        resetCreateUserState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createUserAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(createUserAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(createUserAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });

        builder.addCase(updateUserAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(updateUserAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(updateUserAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
    }
});

export const { resetCreateUserState } = createUser.actions;

export default createUser.reducer;
