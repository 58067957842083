import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState, memo, ChangeEvent, useReducer } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    Box,
    Input,
    Divider,
    InputLabel,
    Typography,
    Stack,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem
} from '@mui/material';

import { MODAL_IDS } from '..';
import {
    SOLAR_FORECAST_DELIVERY_PARAMETERS,
    WIND_FORECAST_DELIVERY_PARAMETERS
} from '../../utils/forecastDeliverySettings';
import { ASSET_TYPE } from '../../interfaces/uiv2';
import { DATE_SETTINGS } from '../../utils/config';
import { useAppDispatch, useAppSelector } from '../../stores';
import { DateRangeInput } from '../../components/DateRangeInput/DateRangeInput';
import { postReportAsync, resetCreateReportsState } from '../../stores/Reports';
import { AutocompletesAction, AutocompletesState } from './FormAutocompletes/interfaces';
import { FormErrorStep, FormModalActions, FormSuccessStep } from '../../components/FormUtils';

import style from './style.module.scss';
import { GenerationReportAssetAutocomplete } from './FormAutocompletes/GenerationReportAssetAutocomplete';
import { GenerationReportForecastSettingAutocomplete } from './FormAutocompletes/GenerationReportForecastSettingAutocomplete';
import { GenerationReportDeliverySettings } from './FormAutocompletes/GenerationReportDeliverySettings';
import { GenerationReportDeliveryGroups } from './FormAutocompletes/GenerationReportDeliveryGroups';
import { GenerationReportDeliveryIntervals } from './FormAutocompletes/GenerationReportDeliveryIntervals';
import classNames from 'classnames';

const disabledDays = [
    {
        from: dayjs().subtract(31, 'day').toDate(),
        to: dayjs().subtract(1, 'day').toDate()
    }
];

const defaultDatepickerConfig = {
    disabled: disabledDays,
    componentDisabled: true
};

function reducer(state: AutocompletesState, action: AutocompletesAction): AutocompletesState {
    switch (action.type) {
        case 'SET_ASSET':
            return { ...state, asset: action.payload };
        case 'SET_FORECAST_SETTING':
            return { ...state, forecastSetting: action.payload };
        case 'SET_DELIVERY_SETTING':
            return { ...state, deliverySetting: action.payload };
        case 'SET_DELIVERY_INTERVAL':
            return { ...state, deliveryInterval: action.payload };
        case 'SET_DELIVERY_GROUP':
            return { ...state, deliveryGroup: action.payload };
        case 'SET_OPEN':
            return { ...state, open: action.payload };

        default:
            return state;
    }
}

const initialState: AutocompletesState = {
    asset: null,
    forecastSetting: null,
    deliverySetting: null,
    deliveryInterval: null,
    deliveryGroup: null,
    open: {
        asset: false,
        forecastSetting: false,
        deliverySetting: false,
        deliveryGroup: false,
        deliveryInterval: false
    }
};

const CreateReport = () => {
    const { t } = useTranslation();
    const { t: forecastSettingsTranslation } = useTranslation('assets/forecast-settings');
    const dispatch = useAppDispatch();

    const [state, dispatchLocalState] = useReducer(reducer, initialState);

    const [reportName, setReportName] = useState('');
    const [reportValueColumn, setReportValueColumn] = useState('value');
    const [reportTimezone, setReportTimezone] = useState<string | undefined>('CET');
    const [parameters, setParameters] = useState<string[]>([]);
    const [dateRange, setDateRange] = useState<DateRange | null>(null);
    const [datepickerConfig, setDatepickerConfig] = useState(defaultDatepickerConfig);

    const { error, loading, success } = useAppSelector((state) => state.createReports);

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setReportName(event.target.value);
    };

    const handleSubmit = () => {
        if (isValid) {
            const payload = {
                asset_id: state.asset!.id,
                name: reportName,
                from_date: dayjs(dateRange.from).startOf('date').utc().format(DATE_SETTINGS.longFormatUsDash),
                to_date: dayjs(dateRange.to)
                    .endOf('day')
                    .add(1, 'millisecond')
                    .utc()
                    .format(DATE_SETTINGS.longFormatUsDash),
                weather_parameters: parameters,
                forecast_delivery_interval_id: state?.deliveryInterval?.id,
                value_column: reportValueColumn,
                timezone: reportTimezone,
                duplicates_removal: 'first' // for DayAhead mainly
            };
            dispatch(postReportAsync(payload));
        }
    };

    const handleCheck = (item: string) => {
        const updatedParameters = parameters.includes(item)
            ? parameters.filter((param) => param !== item)
            : [...parameters, item];
        setParameters(updatedParameters);
    };

    useEffect(() => {
        if (
            state.asset &&
            state.asset.first_forecast_timestamp &&
            state.asset.first_generation_timestamp &&
            state.asset.last_forecast_timestamp &&
            state.asset.last_generation_timestamp
        ) {
            setDatepickerConfig({
                ...datepickerConfig,
                componentDisabled: false,
                disabled: [
                    {
                        to: dayjs
                            .max(
                                dayjs(state.asset.first_generation_timestamp),
                                dayjs(state.asset.first_forecast_timestamp)
                            )
                            .subtract(1, 'day')
                            .toDate(),
                        from: dayjs().subtract(100, 'year').toDate()
                    },
                    {
                        to: dayjs().add(100, 'year').toDate(),
                        from: dayjs
                            .min(
                                dayjs(state.asset.last_forecast_timestamp),
                                dayjs(state.asset.last_generation_timestamp)
                            )
                            .add(1, 'day')
                            .toDate()
                    }
                ]
            });
        } else {
            setDatepickerConfig({ ...datepickerConfig, componentDisabled: true });
        }

        setParameters([]);
    }, [state.asset]);

    useEffect(() => {
        return () => {
            dispatch(resetCreateReportsState());
        };
    }, []);

    const noAssetData =
        state.asset &&
        !state.asset.first_forecast_timestamp &&
        !state.asset.first_generation_timestamp &&
        !state.asset.last_forecast_timestamp &&
        !state.asset.last_generation_timestamp;

    const isValid =
        !!reportName &&
        !!state.asset &&
        // this field should be optional if there is no deliverySettings on either forecast_settings or delivery_group
        (state.deliverySetting ? !!state.deliveryInterval : true) &&
        !!reportValueColumn &&
        !!dateRange &&
        !!dateRange.from &&
        !!dateRange.to;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className={style.box}>
                {!(success || error) ? (
                    <>
                        <Typography variant="h6">{t('createTitle')}</Typography>
                        <Box className={style.formRow}>
                            <InputLabel variant="standard" required shrink htmlFor={t('reportForm.name')}>
                                {t('reportForm.name')}
                            </InputLabel>
                            <Input
                                fullWidth
                                id={t('reportForm.name')}
                                placeholder={t('reportForm.name')}
                                onChange={handleNameChange}
                                value={reportName}
                            />
                        </Box>

                        <GenerationReportAssetAutocomplete state={state} dispatch={dispatchLocalState} />

                        {noAssetData ? (
                            <Typography className={style.black} variant="small4">
                                {t('noForecastGeneration')}
                            </Typography>
                        ) : null}

                        {!noAssetData ? (
                            <>
                                {state.asset ? (
                                    <>
                                        <GenerationReportForecastSettingAutocomplete
                                            state={state}
                                            dispatch={dispatchLocalState}
                                        />

                                        {state.forecastSetting ? (
                                            <>
                                                {state.forecastSetting?.delivery_settings.length ? (
                                                    <GenerationReportDeliverySettings
                                                        state={state}
                                                        dispatch={dispatchLocalState}
                                                        deliverySettings={state.forecastSetting.delivery_settings}
                                                    />
                                                ) : (
                                                    <GenerationReportDeliveryGroups
                                                        state={state}
                                                        dispatch={dispatchLocalState}
                                                    />
                                                )}
                                            </>
                                        ) : null}

                                        {state.deliveryGroup ? (
                                            <GenerationReportDeliverySettings
                                                state={state}
                                                dispatch={dispatchLocalState}
                                                deliverySettings={state.deliveryGroup.delivery_settings}
                                            />
                                        ) : null}

                                        {state.deliverySetting ? (
                                            <GenerationReportDeliveryIntervals
                                                state={state}
                                                dispatch={dispatchLocalState}
                                            />
                                        ) : null}

                                        <Box className={classNames(style.formRow, style.fullWidth)}>
                                            <InputLabel
                                                variant="standard"
                                                required
                                                shrink
                                                htmlFor={t('reportForm.timezone')}
                                            >
                                                {t('reportForm.timezone')}
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label={t('reportForm.Value')}
                                                value={reportTimezone}
                                                onChange={(e: any) => setReportTimezone(e.target.value)}
                                            >
                                                <MenuItem value={'UTC'}>
                                                    <Typography variant="small1">UTC</Typography>
                                                </MenuItem>
                                                <MenuItem value={'CET'}>
                                                    <Typography variant="small1">CET</Typography>
                                                </MenuItem>
                                                <MenuItem value={state.asset?.timezone}>
                                                    <Typography variant="small1">Asset timezone</Typography>
                                                </MenuItem>
                                            </Select>
                                        </Box>
                                    </>
                                ) : null}

                                <Box className={style.formRow}>
                                    <InputLabel variant="standard" required shrink htmlFor={t('startTime')}>
                                        {t('startTime')}
                                    </InputLabel>
                                    <DateRangeInput
                                        className={style.dateRange}
                                        label={t('table.general.selectDateRange')}
                                        datePickerConfig={datepickerConfig}
                                        range={dateRange}
                                        setRange={setDateRange}
                                    />
                                </Box>

                                <Box className={classNames(style.formRow, style.fullWidth)}>
                                    <InputLabel
                                        variant="standard"
                                        required
                                        shrink
                                        htmlFor={t('reportForm.valueColumn')}
                                    >
                                        {t('reportForm.valueColumn')}
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label={t('reportForm.Value')}
                                        value={reportValueColumn}
                                        onChange={(e: any) => setReportValueColumn(e.target.value)}
                                    >
                                        <MenuItem value={'value'}>
                                            <Typography variant="small1">{t('reportForm.value')}</Typography>
                                        </MenuItem>
                                        <MenuItem value={'P10'}>
                                            <Typography variant="small1">P10</Typography>
                                        </MenuItem>
                                        <MenuItem value={'P25'}>
                                            <Typography variant="small1">P25</Typography>
                                        </MenuItem>
                                        <MenuItem value={'P75'}>
                                            <Typography variant="small1">P75</Typography>
                                        </MenuItem>
                                        <MenuItem value={'P90'}>
                                            <Typography variant="small1">P90</Typography>
                                        </MenuItem>
                                    </Select>
                                </Box>

                                <Box className={style.formRow}>
                                    {!state.asset ? null : (
                                        <>
                                            <InputLabel variant="standard" shrink>
                                                {t('weatherParams')}
                                            </InputLabel>

                                            <Stack direction="row" justifyContent="center" spacing={3} flexWrap="wrap">
                                                {state.asset.type === ASSET_TYPE.SOLAR
                                                    ? SOLAR_FORECAST_DELIVERY_PARAMETERS.map((item) => (
                                                          <FormControlLabel
                                                              key={item}
                                                              className={style.checkboxItem}
                                                              componentsProps={{ typography: { variant: 'small1' } }}
                                                              control={
                                                                  <Checkbox
                                                                      name={item}
                                                                      checked={
                                                                          !!parameters.find(
                                                                              (i: string) =>
                                                                                  i?.toLowerCase() ===
                                                                                  item?.toLowerCase()
                                                                          )
                                                                      }
                                                                      onChange={() => handleCheck(item)}
                                                                  />
                                                              }
                                                              label={forecastSettingsTranslation(`form.${item}`)}
                                                          />
                                                      ))
                                                    : null}
                                                {state.asset.type === ASSET_TYPE.WIND
                                                    ? WIND_FORECAST_DELIVERY_PARAMETERS.map((item) => (
                                                          <FormControlLabel
                                                              key={item}
                                                              className={style.checkboxItem}
                                                              componentsProps={{ typography: { variant: 'small1' } }}
                                                              control={
                                                                  <Checkbox
                                                                      name={item}
                                                                      checked={
                                                                          !!parameters.find(
                                                                              (i: string) =>
                                                                                  i?.toLowerCase() ===
                                                                                  item?.toLowerCase()
                                                                          )
                                                                      }
                                                                      onChange={() => handleCheck(item)}
                                                                  />
                                                              }
                                                              label={forecastSettingsTranslation(`form.${item}`)}
                                                          />
                                                      ))
                                                    : null}
                                            </Stack>
                                        </>
                                    )}
                                </Box>

                                <Box className={style.formRow}>
                                    <Typography className={style.black} variant="small4">
                                        {t('mandatory')}
                                    </Typography>
                                </Box>
                            </>
                        ) : null}

                        <Divider />

                        <FormModalActions
                            loading={loading}
                            note={' '}
                            disabled={!isValid}
                            modalId={MODAL_IDS.CREATE_REPORT_MODAL}
                            cancelLabel={t('nevermind')}
                            saveLabel={t('ctaLabel')}
                            onSubmit={handleSubmit}
                        />
                    </>
                ) : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
            </Box>
        </LocalizationProvider>
    );
};

export const MemoizedCreateReport = memo(CreateReport);

export default CreateReport;
