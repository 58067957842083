import { api } from '../api';

export const deliveryGroupsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDeliveryGroupsForAsset: build.query({
            query: (params) => {
                return {
                    url: `/electricity/generation/assets/${params.assetId}/groups`,
                    method: 'get'
                };
            },
            transformResponse: (rawData: any[]): any[] => {
                return rawData;
            },
            keepUnusedDataFor: 0
        })
    })
});

export const { useGetDeliveryGroupsForAssetQuery } = deliveryGroupsApi;
