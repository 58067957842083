import { TFunction } from 'i18next';
import classnames from 'classnames';
import Flag from 'react-world-flags';
import { Box } from '@mui/material';

import { ClientFE } from '../../../../interfaces/uiv2';
import { getModuleImage } from '../../../../utils/permissions';
import { TableCellRenderer } from '../../../../interfaces/general';
import { PageHeader } from '../../../../components/PageHeader/PageHeader';
import { DefaultSearchableCell, TableActions } from '../../../../components/BaseTable';

import style from './style.module.scss';

export const getTableHeadCells = (tList: TFunction, t: TFunction): TableCellRenderer<ClientFE>[] => {
    return [
        {
            id: 'index',
            label: tList('tableHead.no'),
            maxWidth: 50,
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.companyName'),
            sort: false,
            value: (row, searchValue, onClick) => {
                const callback = onClick ? onClick(row) : undefined;

                return <DefaultSearchableCell searchValue={searchValue} value={row?.name} onClick={callback} />;
            }
        },
        {
            id: 'country',
            // maxWidth: '10%',
            justifyContent: 'center',
            label: tList('tableHead.country'),
            value: (row, searchValue) => {
                const value = row?.country_code || '';

                return (
                    <DefaultSearchableCell value={value} searchValue={searchValue}>
                        <Flag height="24" width="24" code={value} className={classnames(style.flagIcon, 'flagIcon')} />
                    </DefaultSearchableCell>
                );
            },
            sort: false
        },
        {
            id: 'contactInfo',
            // maxWidth: '20%',
            justifyContent: 'center',
            label: tList('tableHead.contact'),
            value: (row, searchValue) => {
                const value = [row.contact.last_name, row.contact.first_name].join(' ') || '';
                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            },
            sort: false
        },
        {
            id: 'contactInfoPhoneNumber',
            // maxWidth: '20%',
            justifyContent: 'center',
            label: tList('tableHead.contactPhoneNumber'),
            value: (row, searchValue) => {
                const value = row.contact.phone_number || '';
                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            },
            sort: false
        },
        {
            id: 'activatedModules',
            maxWidth: '15%',
            justifyContent: 'center',
            label: tList('tableHead.modulesAvailable'),
            value: (row) => (
                <Box className={'modulesContainer'}>
                    {row.activatedModules
                        ?.map((module, index) => {
                            const img = getModuleImage(module);

                            return img ? <img key={index} alt={module} src={img} /> : null;
                        })
                        .filter((x) => x)}
                </Box>
            ),
            sort: false
        },
        {
            id: 'actions',
            maxWidth: 70,
            justifyContent: 'center',
            label: t('actions'),
            sort: false,
            value: (row, searchValue, onClick) => (
                <TableActions
                    actions={[
                        {
                            id: 'edit',
                            label: 'table.general.editInfo',
                            disabled: false,
                            callback: () => {
                                onClick(row)();
                            }
                        }
                    ]}
                />
            )
        }
    ];
};
