import i18next from 'i18next';
import { toast } from 'react-toastify';
import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../../../utils/axios';
import { Cluster } from '../../../../interfaces/apiv2';
import { getForecastDataChartThunk } from '../../helpers';
import { GetParams } from '../../../../interfaces/general';
import { FORECAST_TYPE } from '../../../../interfaces/uiv2';
import ToastifyType from '../../../../utils/toastify-config';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { sortForecastData, transformForecastToView } from '../../../../utils/forecast';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../../../interfaces/redux';

interface ClusterPage extends DefaultInitialState {
    cluster: Cluster | null;
    getParams: GetParams;
    loadingForecast: boolean;
    successForecast: boolean;
    errorForecast: boolean;
    loadingHistorical: boolean;
    successHistorical: boolean;
    errorHistorical: boolean;
    forecasts: LineData[][];
}

const initialState: ClusterPage = {
    cluster: null,
    loading: false,
    error: false,
    success: false,
    loadingForecast: false,
    successForecast: false,
    errorForecast: false,
    loadingHistorical: false,
    successHistorical: false,
    errorHistorical: false,
    forecasts: [[], []], // [forecast, historical]
    getParams: DEFAULT_GET_PARAMS_VALUE
};

export const getClusterForecastAsync = getForecastDataChartThunk({
    thunkName: 'clusters/forecast',
    endpoint: `/electricity/demand/{project_id}/clusters/forecast/{consumer_id}`
});

export const getClusterHistoricalAsync = getForecastDataChartThunk({
    thunkName: 'clusters/historical',
    endpoint: `/electricity/demand/{project_id}/clusters/historical/{consumer_id}`
});

export const getClusterAsync = createAsyncThunk(
    'Clusters/show',
    async (values: { id: string; projectId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<Cluster>(`/electricity/demand/${values.projectId}/clusters/${values.id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const clusterPage = createSlice({
    name: 'clusterPage',
    initialState,
    reducers: {
        resetClusterPageState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        resetClusterForecastData: (state) => {
            state.forecasts = [[], []];
            state.errorForecast = false;
            state.loadingForecast = false;
            state.successForecast = false;
            state.errorHistorical = false;
            state.loadingHistorical = false;
            state.successHistorical = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getClusterAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getClusterAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.cluster = payload;
        });
        builder.addCase(getClusterAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getClusterForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getClusterForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            const actualData = (current(state).forecasts || [])[0] || [];
            state.forecasts[0] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.ELECTRICITY),
                ...actualData
            ]);
        });
        builder.addCase(getClusterForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
        builder.addCase(getClusterHistoricalAsync.pending, (state) => {
            state.loadingHistorical = true;
            state.errorHistorical = false;
            state.successHistorical = false;
        });
        builder.addCase(getClusterHistoricalAsync.fulfilled, (state, { payload }) => {
            state.loadingHistorical = false;
            state.errorHistorical = false;
            state.successHistorical = true;
            const actualData = (current(state).forecasts || [])[1] || [];
            state.forecasts[1] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.ELECTRICITY),
                ...actualData
            ]);
        });
        builder.addCase(getClusterHistoricalAsync.rejected, (state) => {
            state.loadingHistorical = false;
            state.errorHistorical = true;
            state.successHistorical = false;
        });
    }
});

export const {
    resetClusterPageState,
    setGetParams: setGetClusterParams,
    resetClusterForecastData
} = clusterPage.actions;

export default clusterPage.reducer;
