import { DefaultEntityTimestampsDeletedIncluded } from '.';
import { Client } from './client';
import { JobTitle } from './job_title';

export enum UserStatus {
    ACTIVE = 'Active',
    DISABLED = 'Disabled',
    PENDING = 'Pending'
}

export interface User extends DefaultEntityTimestampsDeletedIncluded {
    id: string;
    superadmin: boolean;
    guest: boolean;
    email: string;
    last_name: string;
    first_name: string;
    status: UserStatus;
    first_login: string;

    client_id: string | null;
    client: Client | null;
    job_title: JobTitle | null;

    authentication: number;
    permissions: UserPermissions;

    // missing from the API model:
    // phone: string | null;
}

// export interface UserPermissions {
//     [module: string]: {
//         [resource: string]: {
//             [operation: string]: number[];
//         };
//     };
// }

export interface UserPermissions {
    Gas: {
        GasDemand: {
            GasDSO: { READ: number[]; WRITE: number[] };
            GasPROFILE: { READ: number[]; WRITE: number[] };
        };
    };
    Electricity: {
        CustomForecastDeliveryTemplate: { READ: number[]; WRITE: number[] };
        Generation: {
            Reports: { READ: number[]; CREATE: number[] };
            DeliveredForecasts: { READ: number[] };
            DeliveryClusters: { READ: number[]; WRITE: number[] };
            Assets: { READ: number[] };
            VPPs: { READ: number[]; CREATE: number[]; WRITE: number[] };
        };
        Demand: {
            DSO: { READ: number[]; WRITE: number[] };
            PROFILE: { READ: number[]; WRITE: number[] };
        };
    };
    Admin: {
        Users: { CREATE: number[]; WRITE: number[]; JobTitle: { CREATE: number[]; WRITE: number[] } };
        Clients: { CREATE: number[]; WRITE: number[] };
        TrainingAssets: { CREATE: number[] };
        Projects: { WRITE: number[] };
        Assets: { CREATE: number[]; WRITE: number[]; Delivery: { WRITE: number[] }; Forecast: { WRITE: number[] } };
        Support: { READ: number[]; WRITE: number[] };
    };
    EVS: {
        ChargingStations: { READ: number[]; CREATE: number[] };
        ChargingPoints: { READ: number[]; CREATE: number[] };
    };
}

export interface PostUser {
    email: string;
    first_name: string;
    last_name: string;
    superadmin: boolean;
    job_title: string | null;
    guest: boolean;
    authentication: number;
    client_id: string | null;
}

export interface PatchUser {
    id: string;
    body: PostUser & { id: string; status: string };
}
