import { api } from '../api';
import Supercluster from 'supercluster';
import { addOffsetToDuplicateCoordinates } from '../../pages/Map/helpers';
import { getQueryParamsFromObject } from '../../utils/table';

export const mapApi = api.injectEndpoints({
    endpoints: (build) => ({
        getMapMarkers: build.query({
            query: ({ radius, lat, long, entities }) => {
                const values = { radius, lat, long, entities };
                const queryParams = getQueryParamsFromObject({ ...values });
                return { url: `/map-markers${queryParams}`, method: 'get' };
            },
            transformResponse: (rawData: any[]): Supercluster.PointFeature<Supercluster.AnyProps>[] => {
                const items = rawData.map((item) => {
                    return {
                        type: 'Feature',
                        properties: {
                            id: item.id,
                            cluster: false,
                            data: {
                                ...item
                            }
                        },
                        geometry: { type: 'Point', coordinates: [item.longitude, item.latitude] }
                    };
                }) as Supercluster.PointFeature<Supercluster.AnyProps>[];
                return addOffsetToDuplicateCoordinates(items, 9000);
            }
        })
    })
});

export const { useGetMapMarkersQuery, useLazyGetMapMarkersQuery } = mapApi;
