import { TFunction } from 'i18next';
import * as yup from 'yup';

export const MANUAL_UUID_FOR_CREATE = 'UUID';

export const getForecastSettingsSchema = (t: TFunction) => {
    return yup.object({
        name: yup.string().required(t('validations.required')),
        time_resolution: yup.number().required(t('form.validations.required')),
        percentiles: yup.boolean().typeError(t('form.validations.required')).required(t('form.validations.required')),
        aggregation_level: yup.string().required(t('form.validations.required')),
        use_realtime_data: yup.boolean().required(t('validations.required')),
        error_measurements: yup
            .array()
            .min(2, t('form.validations.minim', { number: 2 }))
            .required(t('form.validations.required')),
        meteo_sources: yup
            .array()
            .min(1, t('form.validations.minim', { number: 1 }))
            .required(t('form.validations.required'))
    });
};
