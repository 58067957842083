// import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

// import i18next from '../../utils/i18next';
import { GetParams } from '../../interfaces/general';
import { transformClustersDataToFE } from './helpers';
// import ToastifyType from '../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../utils/config';
// import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { PaginatedApiResponse, ApiPaginationInfo } from '../../interfaces/apiv2';

import { EVSCluster } from '../../interfaces/apiv2';
import { EVSClusterFE } from '../../interfaces/uiv2';
import { getQueryParamsFromObject } from '../../utils/table';
import axios from '../../utils/axios';

interface ListClustersState extends DefaultInitialState {
    clusters: EVSCluster[];
    clustersFE: EVSClusterFE[];
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
}

const initialState: ListClustersState = {
    clusters: [],
    clustersFE: [],
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    pager: null
};

export const getEVSClustersListAsync = createAsyncThunk(
    'tl-Clusters/list',
    // eslint-disable-next-line
    async ({ getParams }: { getParams: GetParams }, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...getParams });
            const response = await axios.get<PaginatedApiResponse<EVSCluster>>(
                `/electricity/evs/clusters${queryParams}`
            );

            return response.data;
        } catch (e) {
            return {
                items: [],
                total: 0,
                page: 1,
                size: 100,
                pages: 1
            };
            // return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listEVSClusters = createSlice({
    name: 'listEVSClusters',
    initialState,
    reducers: {
        resetListEVSClustersState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEVSClustersListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSClustersListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.clusters = items;
            } else {
                state.clusters = [...current(state).clusters, ...items];
            }
            state.clustersFE = transformClustersDataToFE(current(state).clusters);
        });
        builder.addCase(getEVSClustersListAsync.rejected, (state) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            // toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetListEVSClustersState, setGetParams: setGetEVSClustersParams } = listEVSClusters.actions;

export default listEVSClusters.reducer;
