import dayjs from 'dayjs';
import { GasConsumerType, GasDashboardLayout, GasProfileName } from '../../interfaces/uiv2';
import { ReactComponent as DefaultIcon } from '../../assets/icons/navigation-items/menu-item-prosumers.svg';
import { ReactComponent as IndustrialIcon } from '../../assets/icons/demand/factory.svg';
import { ReactComponent as CommercialIcon } from '../../assets/icons/demand/commercial.svg';
import { ReactComponent as HouseholdIcon } from '../../assets/icons/demand/household.svg';

export const mockLatest = Array.from({ length: 7 }).map((_, i) => ({
    date: dayjs().add(i, 'd').format('ddd'),
    value: (Math.random() * 100).toFixed(0)
}));

export const transformLatestForecastData = (latestForecast: number[]) => {
    return latestForecast.slice(0, 7).map((forecastValue, i) => ({
        date: dayjs()
            .add(i + 1, 'days')
            .format('ddd'),
        value: +forecastValue.toFixed(0)
    }));
};

export const getConsumersTypeData = (dashboard: GasDashboardLayout, legend: boolean) => {
    if (legend) {
        return Object.keys(dashboard.consumers_per_type).map((type) => {
            return { name: `${type} / ${dashboard.consumers_per_type[type as GasConsumerType]}` };
        });
    }
    return Object.keys(dashboard.consumers_per_type).map((type) => {
        return {
            x: dashboard.consumers_per_type[type as GasConsumerType],
            y: dashboard.consumers_per_type[type as GasConsumerType]
        };
    });
};

export const getProfileData = (dashboard: GasDashboardLayout) => {
    return Object.keys(dashboard.consumers_per_profile).map((profile) => {
        return { date: profile, value: dashboard.consumers_per_profile[profile as GasProfileName] };
    });
};

const getLast7DaysOfMonth = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    const last7Days = [];

    for (let i = lastDayOfMonth; i > Math.max(lastDayOfMonth - 7, 0); i--) {
        const currentDate = new Date(currentYear, currentMonth, i);
        last7Days.unshift(currentDate);
    }

    return last7Days;
};

export const formatForecast = (data: number[]) => {
    const days = getLast7DaysOfMonth();
    return days.map((d, index) => ({ date: dayjs(d).format('D MMMM'), value: data[index] || 0 }));
};

// should de refactored intro get assetIcon to have all the icons in one place
export const getIconByType = (type: GasConsumerType) => {
    switch (type) {
        case 'ASOS':
            return <CommercialIcon />;
        case 'CAS':
            return <HouseholdIcon />;
        case 'NCAS':
            return <IndustrialIcon />;

        default:
            return <DefaultIcon />;
    }
};
