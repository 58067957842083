import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../../layout/Loading';
import { GenericTable } from '../BaseTable';
import { getGasTableHeadCells } from './helpers';
import { GasForecastFE, UNIT } from '../../interfaces/uiv2';

import style from './style.module.scss';
import classNames from 'classnames';

interface GenerationTableProps {
    data: GasForecastFE[];
    error: boolean;
    loading: boolean;
    success: boolean;
}

const GenerationTable = ({ data: forecasts, loading, success }: GenerationTableProps) => {
    const { t } = useTranslation();
    const maxValue = useMemo(() => {
        let m = 0;
        forecasts.forEach((f) => {
            if (f.forecasted && +f.forecasted > m) {
                m = +f.forecasted;
            }

            if (f.measured && +f.measured > m) {
                m = +f.measured;
            }
        });

        return m;
    }, [forecasts]);

    const headCells = getGasTableHeadCells({ t, assetMeasureUnit: UNIT.M3, maxValue });

    // TODO: implement sort on table when api is working or only client side
    const sort = {};
    return (
        <Box className={style.wrapper}>
            <Box className={style.generationTableContainer}>
                {loading ? (
                    <Loading />
                ) : (
                    <GenericTable
                        pager={null}
                        className={classNames(style.generationTable, style.bigTable)}
                        rows={forecasts}
                        loading={loading}
                        success={success}
                        setGetParams={(() => {}) as any}
                        columns={headCells}
                        rowUniqueId={'index'}
                        storeSort={sort}
                    />
                )}
            </Box>
        </Box>
    );
};

export const MemoizedGasGenerationTable = React.memo(GenerationTable);

export default GenerationTable;
