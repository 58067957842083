import dayjs, { Dayjs } from 'dayjs';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState, memo, ChangeEvent } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Input, Divider, InputLabel, Typography, Autocomplete, TextField } from '@mui/material';

import { MODAL_IDS } from '..';
import { useAppDispatch, useAppSelector } from '../../stores';
import { createReportAsync, resetCreateGasReportState } from '../../stores/GasReports';
import { FormErrorStep, FormModalActions, FormSuccessStep } from '../../components/FormUtils';

import style from './style.module.scss';

const inputOptions = [
    {
        name: 'Cluster Pitești',
        id: '1'
    }
];

const CreateGasReport = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { projectId: project_id = '' } = useParams();

    const [reportName, setReportName] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [month, setMonth] = useState<{ id: string; name: string; date: Dayjs } | null>(null);

    const { error, loading, success } = useAppSelector((state) => state.createGasReport);

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setReportName(event.target.value);
    };

    const handleSubmit = () => {
        if (month && reportName) {
            const body = {
                name: reportName,
                from_timestamp: month.date.valueOf(),
                to_timestamp: month.date.endOf('month').valueOf()
            };

            dispatch(createReportAsync({ project_id, body }));
        }
    };

    useEffect(() => {
        return () => {
            dispatch(resetCreateGasReportState());
        };
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className={style.box}>
                {!(success || error) ? (
                    <>
                        <Typography variant="h6">{t('createTitle')}</Typography>
                        <Box className={style.formRow}>
                            <InputLabel variant="standard" required shrink htmlFor={t('gasReport.name')}>
                                {t('gasReport.name')}
                            </InputLabel>
                            <Input
                                fullWidth
                                id={t('inputNamePlaceholder')}
                                placeholder={t('gasReport.name')}
                                onChange={handleNameChange}
                                value={reportName}
                            />
                        </Box>

                        <Box className={classNames(style.formRow, style.fullWidth)}>
                            <InputLabel variant="standard" required shrink htmlFor={t('gasReport.cluster')}>
                                {t('gasReport.cluster')}
                            </InputLabel>
                            <Autocomplete
                                id="cluster"
                                disableClearable
                                defaultValue={inputOptions[0]}
                                onChange={(e, value) => {
                                    return value;
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                noOptionsText={t('select.noOptions')}
                                options={inputOptions}
                                autoHighlight
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                renderOption={(props, option) => (
                                    <Typography variant="small1" {...props}>
                                        {option.name}
                                    </Typography>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="cluster"
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: t('gasReport.cluster')
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        <Box className={classNames(style.formRow, style.fullWidth)}>
                            <InputLabel variant="standard" required shrink htmlFor={t('gasReport.month')}>
                                {t('gasReport.month')}
                            </InputLabel>
                            <Autocomplete
                                id="month"
                                onChange={(e, value) => {
                                    setMonth(value);
                                    return value;
                                }}
                                noOptionsText={t('select.noOptions')}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                options={[
                                    {
                                        name: 'August 2023',
                                        id: '1',
                                        date: dayjs.tz('2023-08-01', 'UTC')
                                    },
                                    {
                                        name: 'Septembrie 2023',
                                        id: '2',
                                        date: dayjs.tz('2023-09-01', 'UTC')
                                    },
                                    {
                                        name: 'Octombrie 2023',
                                        id: '3',
                                        date: dayjs.tz('2023-10-01', 'UTC')
                                    }
                                ]}
                                autoHighlight
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                renderOption={(props, option) => (
                                    <Typography variant="small1" {...props}>
                                        {option.name}
                                    </Typography>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="month"
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: t('gasReport.month')
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        <Box className={style.formRow}>
                            <Typography className={style.black} variant="small4">
                                {t('mandatory')}
                            </Typography>
                        </Box>

                        <Divider />

                        <FormModalActions
                            loading={loading}
                            note={' '}
                            disabled={!month || !reportName}
                            modalId={MODAL_IDS.CREATE_REPORT_MODAL}
                            cancelLabel={t('nevermind')}
                            saveLabel={t('ctaLabel')}
                            onSubmit={handleSubmit}
                        />
                    </>
                ) : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
            </Box>
        </LocalizationProvider>
    );
};

export const MemoizedCreateGasReport = memo(CreateGasReport);

export default CreateGasReport;
