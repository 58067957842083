import googleMapReact from 'google-map-react';
import Supercluster from 'supercluster';

// Icons
import { ReactComponent as WindIcon } from '../../assets/icons/map/wind.svg';
import { ReactComponent as SolarIcon } from '../../assets/icons/map/solar.svg';
import { ReactComponent as LayersIcon } from '../../assets/icons/map/layers.svg';
import { ReactComponent as ConsumersIcon } from '../../assets/icons/demand/consumers.svg';
import { ReactComponent as ProsumersIcon } from '../../assets/icons/navigation-items/menu-item-prosumers.svg';
import { ReactComponent as ChargingStationsIcon } from '../../assets/icons/navigation-items/menu-item-charging-stations.svg';

enum Location {
    Romania = 'Romania',
    Viena = 'Viena',
    Amsterdam = 'Amsterdam'
}

export const MAP_LOCATIONS: Record<Location, googleMapReact.Coords> = {
    [Location.Romania]: {
        lat: 45.9431,
        lng: 24.9667
    },
    [Location.Viena]: {
        lat: 48.2082,
        lng: 16.3719
    },
    [Location.Amsterdam]: {
        lat: 52.3718,
        lng: 4.896
    }
};

export const icons: any = {
    Wind: WindIcon,
    Solar: SolarIcon,
    Consumer: ConsumersIcon,
    Prosumer: ProsumersIcon,
    ChargingStation: ChargingStationsIcon,
    Layers: LayersIcon
};

export const addOffsetToDuplicateCoordinates = (
    points: Supercluster.PointFeature<Supercluster.AnyProps>[],
    factor: number
): Supercluster.PointFeature<Supercluster.AnyProps>[] => {
    const seenCoordinates = new Map<string, number>();

    // Step 1: Find duplicates and count them
    points.forEach((point) => {
        const coordinatesString = point.geometry.coordinates.join(',');
        if (seenCoordinates.has(coordinatesString)) {
            seenCoordinates.set(coordinatesString, seenCoordinates.get(coordinatesString)! + 1);
        } else {
            seenCoordinates.set(coordinatesString, 1);
        }
    });

    // Step 2: Iterate through points and adjust coordinates
    const duplicateIndices = new Map<string, number>();
    return points.map((point) => {
        const coordinatesString = point.geometry.coordinates.join(',');
        const duplicateCount = seenCoordinates.get(coordinatesString)!;

        if (duplicateCount > 1) {
            const currentIndex = duplicateIndices.get(coordinatesString) || 0;
            const angleIncrement = (2 * Math.PI) / duplicateCount;
            const angle = currentIndex * angleIncrement;
            const newLng = point.geometry.coordinates[0] + (duplicateCount / factor) * Math.cos(angle);
            const newLat = point.geometry.coordinates[1] + (duplicateCount / factor) * Math.sin(angle);

            point.geometry.coordinates = [newLng, newLat];
            duplicateIndices.set(coordinatesString, currentIndex + 1);
        }

        return point;
    });
};

export const MAP_STYLES = [
    {
        stylers: [
            {
                hue: '#eef5ff'
            },
            {
                saturation: 50
            }
        ]
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.business',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                lightness: 50
            },
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    }
];
