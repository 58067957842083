import { api } from '../api';
import { MeteoSources } from '../../interfaces/apiv2';

export const meteoApi = api.injectEndpoints({
    endpoints: (build) => ({
        getMeteoSources: build.query({
            query: () => {
                return { url: `/meteo/sources`, method: 'get', data: [] };
            },
            transformResponse: (rawData: MeteoSources[]): MeteoSources[] => {
                return rawData;
            },
            keepUnusedDataFor: 10 * 60 // 10 minutes
        })
    })
});

export const { useGetMeteoSourcesQuery } = meteoApi;
