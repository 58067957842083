import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Theme } from '../../containers/Theme';
import { Box } from '@mui/material';
import { DATE_SETTINGS } from '../../utils/config';
import { TableCellRenderer } from '../../interfaces/general';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../utils/table';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { TLClusterFE, TLConsumerFE, TLCirculationFE } from '../../stores/TL/mock-data';
import { DefaultSearchableCell, TableActions } from '../../components/BaseTable';

import { ReactComponent as LocationSvg } from '../../assets/icons/location.svg';

import style from './style.module.scss';
import common from '../../common.module.scss';

export const getClustersHeadCells = (tList: TFunction): TableCellRenderer<TLClusterFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name_id'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.name}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },
        {
            id: 'pods',
            label: tList('tableHead.pods'),
            justifyContent: 'center',
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={`${row.pods}`} searchValue={searchValue} />;
            },
            sort: true
        },
        {
            id: 'created_at',
            label: tList('tableHead.createdAt'),
            justifyContent: 'center',
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.created_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'addToVPP',
                                    label: 'table.general.addToVPP',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }
                            ]}
                        />
                    </Box>
                );
            }
        }
    ];
};

export const getConsumersHeadCells = (tList: TFunction): TableCellRenderer<TLConsumerFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'id',
            label: tList('tableHead.id'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.id}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },
        {
            id: 'location',
            label: tList('tableHead.location'),
            value: (row, searchValue, onClick, handlers) => {
                const value = row.location || '';
                const handler = handlers?.['location'];

                const callback = handler ? handler(row) : undefined;

                return (
                    <>
                        <DefaultSearchableCell
                            value={value}
                            searchValue={searchValue}
                            onClick={callback}
                            hyperlinkColor={`${Theme.palette.primary.main} !important`}
                        >
                            <Box>
                                <LocationSvg className={common.locationSVG} />
                            </Box>
                        </DefaultSearchableCell>
                    </>
                );
            },
            sort: false
        },

        {
            id: 'location_type',
            maxWidth: 110,
            label: tList('tableHead.locationType'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.location_type} searchValue={searchValue} />;
            },
            sort: false
        },
        {
            id: 'industry',
            label: tList('tableHead.industry'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.industry} searchValue={searchValue} />;
            },
            sort: false
        },
        {
            id: 'profile',
            justifyContent: 'center',
            label: tList('tableHead.profile'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['profile'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.profile}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },
        {
            id: 'voltage_type',
            justifyContent: 'center',
            label: tList('tableHead.voltage'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.voltage} searchValue={searchValue} />;
            },
            sort: false
        },
        {
            id: 'dso',
            label: tList('tableHead.dso'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.dso} searchValue={searchValue} />;
            },
            sort: false
        },
        {
            id: 'created_at',
            label: tList('tableHead.createdAt'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.created_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'expiry_date',
            label: tList('tableHead.expiryDate'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.expiry_date;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }
                            ]}
                        />
                    </Box>
                );
            }
        }
    ];
};
export const getCirculationsHeadCells = (tList: TFunction): TableCellRenderer<TLCirculationFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'id',
            label: tList('tableHead.id'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.name}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },
        {
            id: 'voltage_type',
            justifyContent: 'center',
            label: tList('tableHead.voltage'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.voltage} searchValue={searchValue} />;
            },
            sort: false
        },
        {
            id: 'dso',
            label: tList('tableHead.dso'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.dso} searchValue={searchValue} />;
            },
            sort: false
        },
        {
            id: 'created_at',
            label: tList('tableHead.createdAt'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.created_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'expiry_date',
            label: tList('tableHead.expiryDate'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.expiry_date;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }
                            ]}
                        />
                    </Box>
                );
            }
        }
    ];
};
