import { FC } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import style from './style.module.scss';

const PageContainer: FC<{ className?: string[] }> = ({ children, className = [] }) => {
    return <Box className={classNames(style.wrapper, ...className)}>{children}</Box>;
};

export { PageContainer };
