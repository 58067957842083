import { useEffect } from 'react';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider, FormHelperText, Input, InputLabel, Typography } from '@mui/material';

import { MODAL_IDS } from '..';
import { User } from '../../interfaces/apiv2';
import { UserFE } from '../../interfaces/uiv2';
import { useFormLogic } from '../../hooks/useFormLogic';
import { useAppDispatch, useAppSelector } from '../../stores';
import { updateSelfAsync } from '../../stores/Auth/Login/Login';
import { AccountSettingsFormPayload } from '../../interfaces/uiv2';
import { transformUserFEtoUserPatchBody } from '../UserModal/helpers';
import { resetCreateUserState, updateUserAsync } from '../../stores/Users';
import { getAccountSettingsSchema, transformUserAccountSettingsToFormData } from './helpers';
import { FormErrorStep, FormModalActions, FormSuccessStep } from '../../components/FormUtils';

import style from './style.module.scss';

const AccountSettingsForm = () => {
    const { t: generalTranslation } = useTranslation();
    const { t } = useTranslation('components/account-settings-modal');
    const dispatch = useAppDispatch();

    const { success, error, loading } = useAppSelector((state) => state.createUser);

    const { user } = useAppSelector((state) => state.login);
    const schema = getAccountSettingsSchema(t);

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useFormLogic<User, AccountSettingsFormPayload, keyof typeof schema.fields, AccountSettingsFormPayload>({
        schema,
        data: user,
        transformFn: transformUserAccountSettingsToFormData
    });

    useEffect(() => {
        return () => {
            dispatch(resetCreateUserState());
            dispatch(updateSelfAsync());
        };
    }, []);

    const onSubmit = (form: AccountSettingsFormPayload) => {
        if (user) {
            const payload = transformUserFEtoUserPatchBody(user as UserFE);
            payload.body.first_name = form.first_name;
            payload.body.email = form.email;
            payload.body.last_name = form.last_name;

            dispatch(updateUserAsync(payload));
        }
    };

    if (user) {
        return (
            <Box className={style.box}>
                {!(success || error) ? (
                    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Typography variant="h6">{t('accountSettings')}</Typography>

                        {/* first & last name */}
                        <Box className={classNames(style.formInput, style.smallInput)}>
                            <Box>
                                <Controller
                                    name="first_name"
                                    defaultValue={''}
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <InputLabel variant="standard" required shrink htmlFor={'firstName'}>
                                                {t('form.firstName')}
                                            </InputLabel>
                                            <Input
                                                {...field}
                                                fullWidth
                                                id={'firstName'}
                                                placeholder={t('form.firstName')}
                                            />
                                            {errors.first_name && (
                                                <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </Box>
                            <Box>
                                <Controller
                                    name="last_name"
                                    defaultValue={''}
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <InputLabel variant="standard" required shrink htmlFor={'lastName'}>
                                                {t('form.lastName')}
                                            </InputLabel>
                                            <Input
                                                {...field}
                                                fullWidth
                                                id={'lastName'}
                                                placeholder={t('form.lastName')}
                                            />
                                            {errors.last_name && (
                                                <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                            )}
                                        </>
                                    )}
                                ></Controller>
                            </Box>
                        </Box>
                        <Box className={style.formInput}>
                            <InputLabel variant="standard" required shrink htmlFor={'client'}>
                                {t('form.client')}
                            </InputLabel>
                            <Input
                                fullWidth
                                id={'client'}
                                disabled
                                value={user?.client?.name}
                                placeholder={t('form.client')}
                            />
                        </Box>
                        <Box className={style.formInput}>
                            <Controller
                                name="email"
                                defaultValue={''}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputLabel variant="standard" required shrink htmlFor={t('form.email')}>
                                            {t('form.email')}
                                        </InputLabel>
                                        <Input
                                            {...field}
                                            type="email"
                                            fullWidth
                                            id={t('form.email')}
                                            placeholder={t('form.email')}
                                        />
                                        {errors.email && (
                                            <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                        )}
                                    </>
                                )}
                            ></Controller>
                        </Box>
                        {/* <Box className={style.formInput}>
                            <Controller
                                name="phone"
                                defaultValue={''}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputLabel variant="standard" required shrink htmlFor={'phone'}>
                                            {t('form.phone')}
                                        </InputLabel>
                                        <Input
                                            {...field}
                                            fullWidth
                                            id={'phone'}
                                            type="tel"
                                            placeholder={t('form.phone')}
                                        />
                                        {errors.phone && (
                                            <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                        )}
                                    </>
                                )}
                            ></Controller>
                        </Box>*/}
                        <Divider />

                        <FormModalActions
                            loading={loading}
                            disabled={false}
                            modalId={MODAL_IDS.ACCOUNT_SETTINGS_MODAL}
                            cancelLabel={generalTranslation('nevermind')}
                            saveLabel={generalTranslation('save')}
                        />
                    </Box>
                ) : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
            </Box>
        );
    }

    return <FormErrorStep />;
};

export default AccountSettingsForm;
