import { ReactComponent as GenerationSVG } from '../../assets/icons/navigation-items/generation.svg';
import { ReactComponent as DemandSVG } from '../../assets/icons/navigation-items/menu-item-demand.svg';
import { ReactComponent as ProsumersSVG } from '../../assets/icons/navigation-items/menu-item-prosumers.svg';
import { ASSET_TYPE } from '../../interfaces/uiv2';

export const performanceRows = [
    {
        icon: <GenerationSVG />,
        title: 'Generation accuracy',
        id: 'accuracy_by_type',
        elements: [
            { label: 'Last day', id: 'PreviousDay', unit: '%', value: '', vector: 'positive' },
            { label: 'Last week', id: 'PreviousWeek', unit: '%', value: '', vector: 'negative' },
            { label: 'Last month', id: 'PreviousMonth', unit: '%', value: '', vector: 'positive' },
            { label: 'Total', id: 'total', unit: '%', value: '', vector: 'positive' }
        ]
    },
    {
        icon: <DemandSVG />,
        title: 'Demand accuracy',
        id: 'demand_accuracy',
        elements: [
            { label: 'Last day', id: 'PreviousDay', unit: '%', value: '', vector: 'positive' },
            { label: 'Last week', id: 'PreviousWeek', unit: '%', value: '', vector: 'negative' },
            { label: 'Last month', id: 'PreviousMonth', unit: '%', value: '', vector: 'positive' },
            { label: 'Total', id: 'total', unit: '%', value: '', vector: 'positive' }
        ]
    },
    {
        icon: <ProsumersSVG />,
        title: 'Prosumers accuracy',
        id: 'prosumers_accuracy',
        elements: [
            { label: 'Last day', id: 'PreviousDay', unit: '%', value: '', vector: 'positive' },
            { label: 'Last week', id: 'PreviousWeek', unit: '%', value: '', vector: 'negative' },
            { label: 'Last month', id: 'PreviousMonth', unit: '%', value: '', vector: 'positive' },
            { label: 'Total', id: 'total', unit: '%', value: '', vector: 'positive' }
        ]
    } /*
    {
        icon: <UptimeSVG />,
        title: 'Uptime',
        elements: [
            { label: 'Total', unit: 'MW', value: '3557', vector: 'positive' },
            { label: 'Operators', unit: 'MW', value: '3557', vector: 'negative' },
            { label: 'Users', unit: 'MW', value: '3557', vector: 'positive' }
        ]
    }*/
];

export const assetsData = {
    title: 'Generation',
    capacity: 0,
    total: 0,
    data: [
        { label: 'Wind', type: ASSET_TYPE.WIND, total: 0, capacity: 0 },
        { label: 'PV', type: ASSET_TYPE.SOLAR, total: 0, capacity: 0 },
        { label: 'Other', type: ASSET_TYPE.OTHER, total: 0, capacity: 0 },
        { label: 'VPP', type: 'VPP', total: 0, capacity: 0 }
    ]
};

export const demandData = {
    title: 'Demand',
    capacity: 0,
    total: 0,
    data: [
        { label: 'LV', type: 'CAS', total: 0, capacity: 0, demand: true },
        { label: 'MV', type: 'ASOS', total: 0, capacity: 0, demand: true },
        { label: 'HV', type: 'NCAS', total: 0, capacity: 0, demand: true },
        { label: 'Prosumers', type: 'Prosumer', total: 0, capacity: 0 }
    ]
};
export type Country = 'RO' | 'BG';

export const defaultLegend = {
    B01: 'Biomass',
    B02: 'Fossil Brown coal/Lignite',
    B03: 'Fossil Coal-derived gas',
    B04: 'Fossil Gas',
    B05: 'Fossil Hard coal',
    B06: 'Fossil Oil',
    B07: 'Fossil Oil shale',
    B08: 'Fossil Peat',
    B09: 'Geothermal',
    B10: 'Hydro Pumped Storage',
    B11: 'Hydro Run-of-river and poundage',
    B12: 'Hydro Water Reservoir',
    B13: 'Marine',
    B14: 'Nuclear',
    B15: 'Other renewable',
    B16: 'Solar',
    B17: 'Waste',
    B18: 'Wind Offshore',
    B19: 'Wind Onshore',
    B20: 'Other'
};

export const colorScaleCodes: { [key: string]: string } = {
    B01: '#8F993E', // earthy green //Warm Brown: '#A67C52'
    B02: '#8B4513', // deep brown
    B03: '#404040', // deep charcoal
    B04: '#004080', // deep blue
    B05: '#333333', // coal black
    B06: '#1C1C1C', // deep oil black
    B07: '#6B4226', // shale brown
    B08: '#8B735B', // peat brown
    B09: '#4CAF50', // geo green
    B10: '#2196F3', // hidro blue
    B11: '#4CAF50', // riverine green
    B12: '#2196F3', // reservoir blue
    B13: '#00796B', // marine blue
    B14: '#33CC33', // Mutated Green
    B15: '#FFA500', // Screamin' Green ?
    B16: '#FFD600', // solar yellow
    B17: '#4CAF50', // waste green
    B18: '#00BCD4', // offshore turqoise
    B19: '#4CAF50', // onshore green
    B20: '#757575' // neutral grey
};
