import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { ApiPaginationInfo, Client, PaginatedApiResponse } from '../../interfaces/apiv2';
import { ClientFE } from '../../interfaces/uiv2';
import { transformClientsAPIToFE } from './helpers';
import { GetParams } from '../../interfaces/general';
import ToastifyType from '../../utils/toastify-config';
import { getQueryParamsFromObject } from '../../utils/table';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DefaultInitialState, DEFAULT_GET_PARAMS_VALUE } from '../../interfaces/redux';
import { PAGINATION_SETTINGS } from '../../utils/config';

interface ListClientsState extends DefaultInitialState {
    clients: Client[];
    clientsFE: ClientFE[];
    pager: ApiPaginationInfo | null;
    getParams: GetParams;
    selectedRows: ClientFE[];
}

export const initialState: ListClientsState = {
    loading: false,
    error: false,
    success: false,
    clients: [],
    clientsFE: [],
    pager: null,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    selectedRows: []
};

export const getClientsAsync = createAsyncThunk('clients/list', async (values: GetParams, { rejectWithValue }) => {
    try {
        const queryParams = getQueryParamsFromObject({ ...values });
        const response = await axios.get<PaginatedApiResponse<Client>>(`/clients${queryParams}`);

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const listClients = createSlice({
    name: 'listClients',
    initialState,
    reducers: {
        resetListClientsState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<ClientFE[]>) => {
            state.selectedRows = payload;
        },
        updateById: (state, { payload }: PayloadAction<Client>) => {
            const [clientFE] = transformClientsAPIToFE([payload]);
            state.clientsFE = current(state).clientsFE.map((client) => (client.id === payload.id ? clientFE : client));
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getClientsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getClientsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.clients = items;
            } else {
                state.clients = [...current(state).clients, ...items];
            }

            state.clientsFE = transformClientsAPIToFE(current(state).clients);
        });
        builder.addCase(getClientsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetListClientsState, setGetParams, updateById, setSelectedRows } = listClients.actions;

export default listClients.reducer;
