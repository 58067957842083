import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { MdDeleteOutline, MdRefresh } from 'react-icons/md';

import {
    setGetGasProfilesParams,
    getGasProfilesListAsync,
    resetListGasProfilesState,
    setSelectedRowsGasProfiles,
    deleteGasProfilesListAsync
} from '../../../stores/Demand';
import { MODAL_IDS } from '../../../modals';
import { ROUTES } from '../../../utils/config';
import { getGasProfilesHeadCells } from '../helpers';
import { GasProfileDataFE } from '../../../interfaces/uiv2';
import { ModalContext } from '../../../hooks/modalContext';
import useStickyHeader from '../../../hooks/useStickyHeader';
import { PageContainer } from '../../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { GenericTable, TableSearch } from '../../../components/BaseTable';
import { CreateGasProfileModal } from '../../../modals/CreateGasProfileModal';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { RemoveForecastContainer } from '../../../components/RemoveEntityContainer';
import { transformOrderByToSort } from '../../../components/BaseTable/GenericTable/helpers';

import style from '../../../features/forecast-list/forecast-list.module.scss';

const GasProfilesList = () => {
    const dispatch = useAppDispatch();
    const { projectId = '' } = useParams();
    const { handleClickOpen } = useContext(ModalContext);

    const { user } = useAppSelector((state) => state.login);
    const { added } = useAppSelector((state) => state.createGasProfiles);
    const { profilesFE, loading, getParams, selectedRows, pager, success } = useAppSelector(
        (state) => state.listGasProfiles
    );
    const { deleted } = useAppSelector((state) => state.deleteGasProfiles);

    const { t } = useTranslation();
    const headCells = getGasProfilesHeadCells(t);
    useStickyHeader('gas-profiles-list');

    useEffect(() => {
        dispatch(getGasProfilesListAsync({ getParams, projectId }));
        return () => {
            dispatch(resetListGasProfilesState());
        };
    }, []);

    useEffect(() => {
        dispatch(getGasProfilesListAsync({ getParams, projectId }));
    }, [deleted, getParams, added, projectId]);

    const clearSelection = () => {
        dispatch(setSelectedRowsGasProfiles([]));
    };

    const openConfirmationModal = () => {
        handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    };

    const getProfilesPageUrl = (row: GasProfileDataFE) => () => {
        return `${ROUTES.AI_GAS_DEMAND_PROFILES_PAGE.replace(':projectId', projectId).replace(':id', row.id)}`;
    };

    const getFormulaPageUrl = (row: GasProfileDataFE) => () => {
        return `${ROUTES.AI_DEMAND_PROFILES_FORMULA_PAGE.replace(':projectId', projectId).replace(':id', row.id)}`;
    };

    const openAddProfileModal = () => {
        handleClickOpen(MODAL_IDS.ADD_GAS_PROFILE_MODAL);
    };

    const removeProfiles = () => {
        dispatch(deleteGasProfilesListAsync({ ids: selectedRows.map((a) => a.id), projectId }));
        clearSelection();
    };

    return (
        <PageContainer>
            <Box id="demand-profiles-list-header" className={style.header}>
                <Typography variant="h6" className={style.title}>
                    {t('header.profiles')}
                </Typography>

                <Box className={style.toolbar}>
                    {!!selectedRows.length && (
                        <>
                            <Typography variant="small4" className={style.label}>
                                {selectedRows.length} {t('table.general.selected')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdRefresh />
                                <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                    {t('table.general.clearSelection')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdDeleteOutline />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={openConfirmationModal}
                                    sx={{ ml: 1 }}
                                >
                                    {t('table.general.removeAll')}
                                </Box>
                            </Typography>
                        </>
                    )}
                    <TableSearch setGetParams={setGetGasProfilesParams} getParams={getParams} />
                    {user?.superadmin ? (
                        <Button disableRipple variant="cta" onClick={openAddProfileModal} sx={{ ml: 1 }}>
                            <AddIcon />
                        </Button>
                    ) : null}
                </Box>
            </Box>

            <GenericTable
                pager={pager}
                rows={profilesFE}
                loading={loading}
                success={success}
                isCheckboxEnabled
                rowUniqueId={'id'}
                columns={headCells}
                selectedRows={selectedRows}
                searchParam={getParams.search}
                setGetParams={setGetGasProfilesParams}
                setSelectedRows={setSelectedRowsGasProfiles}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getProfilesPageUrl,
                    formula: getFormulaPageUrl
                }}
            />
            <CreateGasProfileModal />
            <RemoveForecastContainer cb={removeProfiles} stateAccessor="deleteGasProfiles" />
        </PageContainer>
    );
};

export default GasProfilesList;
