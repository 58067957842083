import { useContext } from 'react';
import { MdClear } from 'react-icons/md';
import { Box, IconButton } from '@mui/material';

import { Theme } from '../../containers/Theme';
import { ModalContext } from '../../hooks/modalContext';

export const CloseModalButton = ({
    id,
    callback,
    top = 26,
    className
}: {
    id: string;
    callback?: () => void;
    top?: number;
    className?: string;
}) => {
    const { handleClose } = useContext(ModalContext);

    const close = () => {
        handleClose(id);
        callback?.();
    };

    return (
        <Box sx={{ position: 'absolute', top, right: Theme.spacing(2.5), zIndex: 2 }} className={className}>
            <IconButton disableRipple={true} onClick={close}>
                <MdClear fontSize={Theme.spacing(3)} color={Theme.palette.primary.main} />
            </IconButton>
        </Box>
    );
};
