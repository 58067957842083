// prettier-ignore

import dayjs from 'dayjs';
import { Forecast } from '../../interfaces/apiv2';
import { sortForecastData } from '../../utils/forecast';
import { LineData } from 'lightweight-charts';

export const mockedDemandForecast = [
    {
        timestamp: 1671667200000,
        value: 1046.7325108778
    },
    {
        timestamp: 1671668100000,
        value: 1042.5904664824
    },
    {
        timestamp: 1671669000000,
        value: 1042.5904664824
    },
    {
        timestamp: 1671669900000,
        value: 1042.5904664824
    },
    {
        timestamp: 1671670800000,
        value: 1042.5904664824
    },
    {
        timestamp: 1671671700000,
        value: 1042.5904664824
    },
    {
        timestamp: 1671672600000,
        value: 1042.5904664824
    },
    {
        timestamp: 1671673500000,
        value: 1046.7325108778
    },
    {
        timestamp: 1671674400000,
        value: 1056.7179196437
    },
    {
        timestamp: 1671675300000,
        value: 1067.1674248304
    },
    {
        timestamp: 1671676200000,
        value: 1096.7622110352
    },
    {
        timestamp: 1671677100000,
        value: 1127.4368346856
    },
    {
        timestamp: 1671678000000,
        value: 1156.9540014471
    },
    {
        timestamp: 1671678900000,
        value: 1195.0101969621
    },
    {
        timestamp: 1671679800000,
        value: 1227.0414086246
    },
    {
        timestamp: 1671680700000,
        value: 1282.0031504671
    },
    {
        timestamp: 1671681600000,
        value: 1333.7416844226
    },
    {
        timestamp: 1671682500000,
        value: 1369.0171886969
    },
    {
        timestamp: 1671683400000,
        value: 1387.4079548697
    },
    {
        timestamp: 1671684300000,
        value: 1444.8554431527
    },
    {
        timestamp: 1671685200000,
        value: 1488.199401885
    },
    {
        timestamp: 1671686100000,
        value: 1518.6725178227
    },
    {
        timestamp: 1671687000000,
        value: 1554.5755049138
    },
    {
        timestamp: 1671687900000,
        value: 1570.6424278779
    },
    {
        timestamp: 1671688800000,
        value: 1608.1578033975
    },
    {
        timestamp: 1671689700000,
        value: 1640.959309847
    },
    {
        timestamp: 1671690600000,
        value: 1641.4182387617
    },
    {
        timestamp: 1671691500000,
        value: 1645.6009429927
    },
    {
        timestamp: 1671692400000,
        value: 1650.5989622364
    },
    {
        timestamp: 1671693300000,
        value: 1650.6860341545
    },
    {
        timestamp: 1671694200000,
        value: 1654.9077715858
    },
    {
        timestamp: 1671695100000,
        value: 1654.9077715858
    },
    {
        timestamp: 1671696000000,
        value: 1647.298923072
    },
    {
        timestamp: 1671696900000,
        value: 1647.298923072
    },
    {
        timestamp: 1671697800000,
        value: 1647.298923072
    },
    {
        timestamp: 1671698700000,
        value: 1642.9901137226
    },
    {
        timestamp: 1671699600000,
        value: 1636.9432931226
    },
    {
        timestamp: 1671700500000,
        value: 1636.9432931226
    },
    {
        timestamp: 1671701400000,
        value: 1642.4543091992
    },
    {
        timestamp: 1671702300000,
        value: 1646.6093785275
    },
    {
        timestamp: 1671703200000,
        value: 1641.5710351388
    },
    {
        timestamp: 1671704100000,
        value: 1639.9917431555
    },
    {
        timestamp: 1671705000000,
        value: 1625.4261268526
    },
    {
        timestamp: 1671705900000,
        value: 1602.6387579611
    },
    {
        timestamp: 1671706800000,
        value: 1583.2918966847
    },
    {
        timestamp: 1671707700000,
        value: 1570.0342378939
    },
    {
        timestamp: 1671708600000,
        value: 1575.9200072889
    },
    {
        timestamp: 1671709500000,
        value: 1575.9200072889
    },
    {
        timestamp: 1671710400000,
        value: 1588.4103587325
    },
    {
        timestamp: 1671711300000,
        value: 1588.4103587325
    },
    {
        timestamp: 1671712200000,
        value: 1586.5766733927
    },
    {
        timestamp: 1671713100000,
        value: 1588.4103587325
    },
    {
        timestamp: 1671714000000,
        value: 1588.9886851461
    },
    {
        timestamp: 1671714900000,
        value: 1594.4997012227
    },
    {
        timestamp: 1671715800000,
        value: 1617.2082421388
    },
    {
        timestamp: 1671716700000,
        value: 1633.2844002437
    },
    {
        timestamp: 1671717600000,
        value: 1654.9048861838
    },
    {
        timestamp: 1671718500000,
        value: 1687.5686498276
    },
    {
        timestamp: 1671719400000,
        value: 1731.5805894062
    },
    {
        timestamp: 1671720300000,
        value: 1767.2600415841
    },
    {
        timestamp: 1671721200000,
        value: 1792.7859858935
    },
    {
        timestamp: 1671722100000,
        value: 1801.5980115927
    },
    {
        timestamp: 1671723000000,
        value: 1801.5980115927
    },
    {
        timestamp: 1671723900000,
        value: 1787.0825747708
    },
    {
        timestamp: 1671724800000,
        value: 1785.4593804021
    },
    {
        timestamp: 1671725700000,
        value: 1778.1068857251
    },
    {
        timestamp: 1671726600000,
        value: 1778.1068857251
    },
    {
        timestamp: 1671727500000,
        value: 1772.44731022
    },
    {
        timestamp: 1671728400000,
        value: 1750.3560985149
    },
    {
        timestamp: 1671729300000,
        value: 1745.5116310062
    },
    {
        timestamp: 1671730200000,
        value: 1714.0495184539
    },
    {
        timestamp: 1671731100000,
        value: 1685.093004451
    },
    {
        timestamp: 1671732000000,
        value: 1641.9219490555
    },
    {
        timestamp: 1671732900000,
        value: 1616.581565637
    },
    {
        timestamp: 1671733800000,
        value: 1582.6423194418
    },
    {
        timestamp: 1671734700000,
        value: 1556.6429336667
    },
    {
        timestamp: 1671735600000,
        value: 1455.7315654968
    },
    {
        timestamp: 1671736500000,
        value: 1441.0947096767
    },
    {
        timestamp: 1671737400000,
        value: 1422.8367911188
    },
    {
        timestamp: 1671738300000,
        value: 1394.2190886518
    },
    {
        timestamp: 1671739200000,
        value: 1323.5556801951
    },
    {
        timestamp: 1671740100000,
        value: 1295.7217995286
    },
    {
        timestamp: 1671741000000,
        value: 1290.7961043207
    },
    {
        timestamp: 1671741900000,
        value: 1282.6065707518
    },
    {
        timestamp: 1671742800000,
        value: 1175.3482006243
    },
    {
        timestamp: 1671743700000,
        value: 1147.8649355759
    },
    {
        timestamp: 1671744600000,
        value: 1162.7197655425
    },
    {
        timestamp: 1671745500000,
        value: 1171.0786000097
    },
    {
        timestamp: 1671746400000,
        value: 1098.9924950887
    },
    {
        timestamp: 1671747300000,
        value: 1081.1654605875
    },
    {
        timestamp: 1671748200000,
        value: 1071.4638547144
    },
    {
        timestamp: 1671749100000,
        value: 1072.4728399349
    },
    {
        timestamp: 1671750000000,
        value: 1075.4931202149
    },
    {
        timestamp: 1671750900000,
        value: 1069.1054821898
    },
    {
        timestamp: 1671751800000,
        value: 1068.5880568893
    },
    {
        timestamp: 1671752700000,
        value: 1061.8259990686
    },
    {
        timestamp: 1671753600000,
        value: 1064.6548891485
    },
    {
        timestamp: 1671754500000,
        value: 1060.6214543536
    },
    {
        timestamp: 1671755400000,
        value: 1060.6214543536
    },
    {
        timestamp: 1671756300000,
        value: 1054.8876869669
    },
    {
        timestamp: 1671757200000,
        value: 1048.9203139732
    },
    {
        timestamp: 1671758100000,
        value: 1048.9203139732
    },
    {
        timestamp: 1671759000000,
        value: 1050.481111126
    },
    {
        timestamp: 1671759900000,
        value: 1067.6149410183
    },
    {
        timestamp: 1671760800000,
        value: 1076.5384432905
    },
    {
        timestamp: 1671761700000,
        value: 1103.7204419896
    },
    {
        timestamp: 1671762600000,
        value: 1128.0709553275
    },
    {
        timestamp: 1671763500000,
        value: 1158.4924728174
    },
    {
        timestamp: 1671764400000,
        value: 1179.1314668067
    },
    {
        timestamp: 1671765300000,
        value: 1219.1311591081
    },
    {
        timestamp: 1671766200000,
        value: 1262.6358635438
    },
    {
        timestamp: 1671767100000,
        value: 1313.7753362853
    },
    {
        timestamp: 1671768000000,
        value: 1349.2134042473
    },
    {
        timestamp: 1671768900000,
        value: 1402.6083654166
    },
    {
        timestamp: 1671769800000,
        value: 1423.2836144698
    },
    {
        timestamp: 1671770700000,
        value: 1452.1700215657
    },
    {
        timestamp: 1671771600000,
        value: 1484.7044597068
    },
    {
        timestamp: 1671772500000,
        value: 1513.493440567
    },
    {
        timestamp: 1671773400000,
        value: 1536.7985435684
    },
    {
        timestamp: 1671774300000,
        value: 1567.8101688964
    },
    {
        timestamp: 1671775200000,
        value: 1579.134271191
    },
    {
        timestamp: 1671776100000,
        value: 1578.1356119309
    },
    {
        timestamp: 1671777000000,
        value: 1578.9942184589
    },
    {
        timestamp: 1671777900000,
        value: 1577.6967230564
    },
    {
        timestamp: 1671778800000,
        value: 1563.7493534876
    },
    {
        timestamp: 1671779700000,
        value: 1555.7417731148
    },
    {
        timestamp: 1671780600000,
        value: 1563.5066408361
    },
    {
        timestamp: 1671781500000,
        value: 1555.3521750378
    },
    {
        timestamp: 1671782400000,
        value: 1553.3879297607
    },
    {
        timestamp: 1671783300000,
        value: 1546.0711298088
    },
    {
        timestamp: 1671784200000,
        value: 1537.1780034798
    },
    {
        timestamp: 1671785100000,
        value: 1528.3562050335
    },
    {
        timestamp: 1671786000000,
        value: 1518.0242974642
    },
    {
        timestamp: 1671786900000,
        value: 1518.0242974642
    },
    {
        timestamp: 1671787800000,
        value: 1519.2451206915
    },
    {
        timestamp: 1671788700000,
        value: 1527.133369081
    },
    {
        timestamp: 1671789600000,
        value: 1522.3979657372
    },
    {
        timestamp: 1671790500000,
        value: 1523.7342474669
    },
    {
        timestamp: 1671791400000,
        value: 1507.7944187834
    },
    {
        timestamp: 1671792300000,
        value: 1502.475416807
    },
    {
        timestamp: 1671793200000,
        value: 1483.2370594256
    },
    {
        timestamp: 1671794100000,
        value: 1483.2370594256
    },
    {
        timestamp: 1671795000000,
        value: 1483.7725656631
    },
    {
        timestamp: 1671795900000,
        value: 1495.6919901396
    },
    {
        timestamp: 1671796800000,
        value: 1504.4155027999
    },
    {
        timestamp: 1671797700000,
        value: 1504.4155027999
    },
    {
        timestamp: 1671798600000,
        value: 1507.3404339742
    },
    {
        timestamp: 1671799500000,
        value: 1513.1191321228
    },
    {
        timestamp: 1671800400000,
        value: 1526.4119529951
    },
    {
        timestamp: 1671801300000,
        value: 1529.7928892888
    },
    {
        timestamp: 1671802200000,
        value: 1550.1482018902
    },
    {
        timestamp: 1671803100000,
        value: 1573.5754794767
    },
    {
        timestamp: 1671804000000,
        value: 1607.2019597274
    },
    {
        timestamp: 1671804900000,
        value: 1642.5607547662
    },
    {
        timestamp: 1671805800000,
        value: 1667.1258140487
    },
    {
        timestamp: 1671806700000,
        value: 1705.3670030719
    },
    {
        timestamp: 1671807600000,
        value: 1743.3627160596
    },
    {
        timestamp: 1671808500000,
        value: 1749.9387876406
    },
    {
        timestamp: 1671809400000,
        value: 1743.3627160596
    },
    {
        timestamp: 1671810300000,
        value: 1749.9387876406
    },
    {
        timestamp: 1671811200000,
        value: 1749.260704576
    },
    {
        timestamp: 1671812100000,
        value: 1745.0223232552
    },
    {
        timestamp: 1671813000000,
        value: 1737.6222260501
    },
    {
        timestamp: 1671813900000,
        value: 1723.1389948133
    },
    {
        timestamp: 1671814800000,
        value: 1704.7236856951
    },
    {
        timestamp: 1671815700000,
        value: 1683.398909876
    },
    {
        timestamp: 1671816600000,
        value: 1662.0935025235
    },
    {
        timestamp: 1671817500000,
        value: 1650.5146318619
    },
    {
        timestamp: 1671818400000,
        value: 1630.5440635773
    },
    {
        timestamp: 1671819300000,
        value: 1600.7432478365
    },
    {
        timestamp: 1671820200000,
        value: 1566.9491992462
    },
    {
        timestamp: 1671821100000,
        value: 1552.0687346265
    },
    {
        timestamp: 1671822000000,
        value: 1460.7997010408
    },
    {
        timestamp: 1671822900000,
        value: 1456.5345273715
    },
    {
        timestamp: 1671823800000,
        value: 1419.2000466058
    },
    {
        timestamp: 1671824700000,
        value: 1396.3208294509
    },
    {
        timestamp: 1671825600000,
        value: 1339.2415520426
    },
    {
        timestamp: 1671826500000,
        value: 1323.2207301626
    },
    {
        timestamp: 1671827400000,
        value: 1302.9601058372
    },
    {
        timestamp: 1671828300000,
        value: 1291.622234105
    },
    {
        timestamp: 1671829200000,
        value: 1189.5758575182
    },
    {
        timestamp: 1671830100000,
        value: 1187.1262218649
    },
    {
        timestamp: 1671831000000,
        value: 1177.0771120762
    },
    {
        timestamp: 1671831900000,
        value: 1171.3655067836
    },
    {
        timestamp: 1671832800000,
        value: 1105.7472694781
    },
    {
        timestamp: 1671833700000,
        value: 1094.2396313676
    },
    {
        timestamp: 1671834600000,
        value: 1090.2997433611
    },
    {
        timestamp: 1671835500000,
        value: 1090.2997433611
    },
    {
        timestamp: 1671836400000,
        value: 1082.6644247916
    },
    {
        timestamp: 1671837300000,
        value: 1060.617539127
    },
    {
        timestamp: 1671838200000,
        value: 1057.6399566497
    },
    {
        timestamp: 1671839100000,
        value: 1057.1302762832
    },
    {
        timestamp: 1671840000000,
        value: 1054.5701598682
    },
    {
        timestamp: 1671840900000,
        value: 1046.2986922669
    },
    {
        timestamp: 1671841800000,
        value: 1038.1368306997
    },
    {
        timestamp: 1671842700000,
        value: 1032.3124262052
    },
    {
        timestamp: 1671843600000,
        value: 1027.2872845632
    },
    {
        timestamp: 1671844500000,
        value: 1019.3216148306
    },
    {
        timestamp: 1671845400000,
        value: 1019.3216148306
    },
    {
        timestamp: 1671846300000,
        value: 1019.3216148306
    },
    {
        timestamp: 1671847200000,
        value: 1022.1996693684
    },
    {
        timestamp: 1671848100000,
        value: 1026.6806607207
    },
    {
        timestamp: 1671849000000,
        value: 1030.2842177145
    },
    {
        timestamp: 1671849900000,
        value: 1049.0201371646
    },
    {
        timestamp: 1671850800000,
        value: 1081.2643266418
    },
    {
        timestamp: 1671851700000,
        value: 1099.9861875636
    },
    {
        timestamp: 1671852600000,
        value: 1102.1720983773
    },
    {
        timestamp: 1671853500000,
        value: 1107.29981253
    },
    {
        timestamp: 1671854400000,
        value: 1131.4325981926
    },
    {
        timestamp: 1671855300000,
        value: 1149.1171909707
    },
    {
        timestamp: 1671856200000,
        value: 1183.2093509119
    },
    {
        timestamp: 1671857100000,
        value: 1195.0097544496
    },
    {
        timestamp: 1671858000000,
        value: 1268.3364543933
    },
    {
        timestamp: 1671858900000,
        value: 1285.4829549881
    },
    {
        timestamp: 1671859800000,
        value: 1300.3609788982
    },
    {
        timestamp: 1671860700000,
        value: 1312.6638772202
    },
    {
        timestamp: 1671861600000,
        value: 1376.0873266738
    },
    {
        timestamp: 1671862500000,
        value: 1383.3024341767
    },
    {
        timestamp: 1671863400000,
        value: 1394.9343974558
    },
    {
        timestamp: 1671864300000,
        value: 1397.7880393267
    },
    {
        timestamp: 1671865200000,
        value: 1402.9824005538
    },
    {
        timestamp: 1671866100000,
        value: 1401.6090716136
    },
    {
        timestamp: 1671867000000,
        value: 1401.9298699833
    },
    {
        timestamp: 1671867900000,
        value: 1401.2871654796
    },
    {
        timestamp: 1671868800000,
        value: 1409.2061820227
    },
    {
        timestamp: 1671869700000,
        value: 1408.5522048218
    },
    {
        timestamp: 1671870600000,
        value: 1408.3067013589
    },
    {
        timestamp: 1671871500000,
        value: 1417.6448245751
    },
    {
        timestamp: 1671872400000,
        value: 1421.9610604282
    },
    {
        timestamp: 1671873300000,
        value: 1421.9610604282
    },
    {
        timestamp: 1671874200000,
        value: 1418.0850714031
    },
    {
        timestamp: 1671875100000,
        value: 1413.6088304359
    },
    {
        timestamp: 1671876000000,
        value: 1409.5737306177
    },
    {
        timestamp: 1671876900000,
        value: 1411.4437110603
    },
    {
        timestamp: 1671877800000,
        value: 1407.6231594912
    },
    {
        timestamp: 1671878700000,
        value: 1398.3704280276
    },
    {
        timestamp: 1671879600000,
        value: 1366.1945396292
    },
    {
        timestamp: 1671880500000,
        value: 1356.5341916889
    },
    {
        timestamp: 1671881400000,
        value: 1360.2198514896
    },
    {
        timestamp: 1671882300000,
        value: 1366.1945396292
    },
    {
        timestamp: 1671883200000,
        value: 1356.4711708209
    },
    {
        timestamp: 1671884100000,
        value: 1358.1709378572
    },
    {
        timestamp: 1671885000000,
        value: 1377.6332271234
    },
    {
        timestamp: 1671885900000,
        value: 1378.7952837483
    },
    {
        timestamp: 1671886800000,
        value: 1391.3269784957
    },
    {
        timestamp: 1671887700000,
        value: 1401.6319187867
    },
    {
        timestamp: 1671888600000,
        value: 1438.856256416
    },
    {
        timestamp: 1671889500000,
        value: 1461.0814100384
    },
    {
        timestamp: 1671890400000,
        value: 1484.8610148789
    },
    {
        timestamp: 1671891300000,
        value: 1523.1470147209
    },
    {
        timestamp: 1671892200000,
        value: 1553.6383919176
    },
    {
        timestamp: 1671893100000,
        value: 1580.9136322807
    },
    {
        timestamp: 1671894000000,
        value: 1639.5277485548
    },
    {
        timestamp: 1671894900000,
        value: 1643.4116162653
    },
    {
        timestamp: 1671895800000,
        value: 1643.4116162653
    },
    {
        timestamp: 1671896700000,
        value: 1624.1247384668
    },
    {
        timestamp: 1671897600000,
        value: 1616.2535924021
    },
    {
        timestamp: 1671898500000,
        value: 1605.2822747132
    },
    {
        timestamp: 1671899400000,
        value: 1593.1672770065
    },
    {
        timestamp: 1671900300000,
        value: 1588.6317428599
    },
    {
        timestamp: 1671901200000,
        value: 1573.0304298801
    },
    {
        timestamp: 1671902100000,
        value: 1564.81485834
    },
    {
        timestamp: 1671903000000,
        value: 1546.8122540488
    },
    {
        timestamp: 1671903900000,
        value: 1520.5303281796
    },
    {
        timestamp: 1671904800000,
        value: 1484.1472512646
    },
    {
        timestamp: 1671905700000,
        value: 1472.4361567199
    },
    {
        timestamp: 1671906600000,
        value: 1442.6242668663
    },
    {
        timestamp: 1671907500000,
        value: 1413.826685693
    },
    {
        timestamp: 1671908400000,
        value: 1362.9411112782
    },
    {
        timestamp: 1671909300000,
        value: 1355.89955442
    },
    {
        timestamp: 1671910200000,
        value: 1342.5176729929
    },
    {
        timestamp: 1671911100000,
        value: 1309.8561613138
    },
    {
        timestamp: 1671912000000,
        value: 1260.872488953
    },
    {
        timestamp: 1671912900000,
        value: 1235.4354824071
    },
    {
        timestamp: 1671913800000,
        value: 1229.4230020077
    },
    {
        timestamp: 1671914700000,
        value: 1211.2896823595
    },
    {
        timestamp: 1671915600000,
        value: 1164.9983216659
    },
    {
        timestamp: 1671916500000,
        value: 1149.0538034203
    },
    {
        timestamp: 1671917400000,
        value: 1093.2780944383
    },
    {
        timestamp: 1671918300000,
        value: 1070.8924162116
    },
    {
        timestamp: 1671919200000,
        value: 1013.9876363062
    },
    {
        timestamp: 1671920100000,
        value: 1006.4506667149
    },
    {
        timestamp: 1671921000000,
        value: 997.9955260971
    },
    {
        timestamp: 1671921900000,
        value: 996.922539519
    },
    {
        timestamp: 1671922800000,
        value: 997.0563767852
    },
    {
        timestamp: 1671923700000,
        value: 994.7716330291
    },
    {
        timestamp: 1671924600000,
        value: 981.6669557318
    },
    {
        timestamp: 1671925500000,
        value: 974.5735723028
    },
    {
        timestamp: 1671926400000,
        value: 936.3653508388
    },
    {
        timestamp: 1671927300000,
        value: 932.5071335127
    },
    {
        timestamp: 1671928200000,
        value: 926.1148527931
    },
    {
        timestamp: 1671929100000,
        value: 919.8412693715
    },
    {
        timestamp: 1671930000000,
        value: 919.8412693715
    },
    {
        timestamp: 1671930900000,
        value: 919.8412693715
    },
    {
        timestamp: 1671931800000,
        value: 919.8412693715
    },
    {
        timestamp: 1671932700000,
        value: 926.2335500911
    },
    {
        timestamp: 1671933600000,
        value: 926.8839114067
    },
    {
        timestamp: 1671934500000,
        value: 940.2602952615
    },
    {
        timestamp: 1671935400000,
        value: 929.4739681763
    },
    {
        timestamp: 1671936300000,
        value: 928.7014106802
    },
    {
        timestamp: 1671937200000,
        value: 950.9380294227
    },
    {
        timestamp: 1671938100000,
        value: 957.5056397034
    },
    {
        timestamp: 1671939000000,
        value: 956.9530556685
    },
    {
        timestamp: 1671939900000,
        value: 956.9530556685
    },
    {
        timestamp: 1671940800000,
        value: 983.5354463281
    },
    {
        timestamp: 1671941700000,
        value: 987.9407786751
    },
    {
        timestamp: 1671942600000,
        value: 1007.2547424879
    },
    {
        timestamp: 1671943500000,
        value: 1020.4763936133
    },
    {
        timestamp: 1671944400000,
        value: 1041.6387013717
    },
    {
        timestamp: 1671945300000,
        value: 1075.5914530101
    },
    {
        timestamp: 1671946200000,
        value: 1115.983528694
    },
    {
        timestamp: 1671947100000,
        value: 1165.6579405034
    },
    {
        timestamp: 1671948000000,
        value: 1192.3046589304
    },
    {
        timestamp: 1671948900000,
        value: 1224.108552226
    },
    {
        timestamp: 1671949800000,
        value: 1268.8716390026
    },
    {
        timestamp: 1671950700000,
        value: 1293.5061689598
    },
    {
        timestamp: 1671951600000,
        value: 1298.3443748356
    },
    {
        timestamp: 1671952500000,
        value: 1303.8666892841
    },
    {
        timestamp: 1671953400000,
        value: 1316.2260342392
    },
    {
        timestamp: 1671954300000,
        value: 1308.8427788421
    },
    {
        timestamp: 1671955200000,
        value: 1338.128285473
    },
    {
        timestamp: 1671956100000,
        value: 1339.258678993
    },
    {
        timestamp: 1671957000000,
        value: 1344.9267892148
    },
    {
        timestamp: 1671957900000,
        value: 1344.9267892148
    },
    {
        timestamp: 1671958800000,
        value: 1344.9267892148
    },
    {
        timestamp: 1671959700000,
        value: 1342.1427070887
    },
    {
        timestamp: 1671960600000,
        value: 1336.4531363045
    },
    {
        timestamp: 1671961500000,
        value: 1344.8599114464
    },
    {
        timestamp: 1671962400000,
        value: 1344.8554763949
    },
    {
        timestamp: 1671963300000,
        value: 1344.8554763949
    },
    {
        timestamp: 1671964200000,
        value: 1325.8594702788
    },
    {
        timestamp: 1671965100000,
        value: 1295.462228814
    },
    {
        timestamp: 1671966000000,
        value: 1241.8109616091
    },
    {
        timestamp: 1671966900000,
        value: 1229.0983608222
    },
    {
        timestamp: 1671967800000,
        value: 1201.5621739188
    },
    {
        timestamp: 1671968700000,
        value: 1195.4981549037
    },
    {
        timestamp: 1671969600000,
        value: 1167.3774055286
    },
    {
        timestamp: 1671970500000,
        value: 1156.113605482
    },
    {
        timestamp: 1671971400000,
        value: 1163.1337214611
    },
    {
        timestamp: 1671972300000,
        value: 1175.768203614
    },
    {
        timestamp: 1671973200000,
        value: 1166.9445698949
    },
    {
        timestamp: 1671974100000,
        value: 1176.8526957262
    },
    {
        timestamp: 1671975000000,
        value: 1175.8813573555
    },
    {
        timestamp: 1671975900000,
        value: 1189.3445865935
    },
    {
        timestamp: 1671976800000,
        value: 1210.812395445
    },
    {
        timestamp: 1671977700000,
        value: 1257.1333244295
    },
    {
        timestamp: 1671978600000,
        value: 1260.2602815121
    },
    {
        timestamp: 1671979500000,
        value: 1276.2523840506
    },
    {
        timestamp: 1671980400000,
        value: 1322.3937560458
    },
    {
        timestamp: 1671981300000,
        value: 1328.191239279
    },
    {
        timestamp: 1671982200000,
        value: 1328.191239279
    },
    {
        timestamp: 1671983100000,
        value: 1328.191239279
    },
    {
        timestamp: 1671984000000,
        value: 1342.1093663924
    },
    {
        timestamp: 1671984900000,
        value: 1338.4371314653
    },
    {
        timestamp: 1671985800000,
        value: 1329.32437493
    },
    {
        timestamp: 1671986700000,
        value: 1326.0137163375
    },
    {
        timestamp: 1671987600000,
        value: 1346.4549616277
    },
    {
        timestamp: 1671988500000,
        value: 1346.4549616277
    },
    {
        timestamp: 1671989400000,
        value: 1326.2628575605
    },
    {
        timestamp: 1671990300000,
        value: 1327.73691485
    },
    {
        timestamp: 1671991200000,
        value: 1320.5788900621
    },
    {
        timestamp: 1671992100000,
        value: 1310.9365044856
    },
    {
        timestamp: 1671993000000,
        value: 1297.1520199454
    },
    {
        timestamp: 1671993900000,
        value: 1276.8561012049
    },
    {
        timestamp: 1671994800000,
        value: 1254.6838010394
    },
    {
        timestamp: 1671995700000,
        value: 1238.2509746772
    },
    {
        timestamp: 1671996600000,
        value: 1223.9188544462
    },
    {
        timestamp: 1671997500000,
        value: 1196.756958191
    },
    {
        timestamp: 1671998400000,
        value: 1141.7062948602
    },
    {
        timestamp: 1671999300000,
        value: 1130.9765098949
    },
    {
        timestamp: 1672000200000,
        value: 1107.121088229
    },
    {
        timestamp: 1672001100000,
        value: 1117.6237402872
    },
    {
        timestamp: 1672002000000,
        value: 1060.9146993905
    },
    {
        timestamp: 1672002900000,
        value: 1047.0044839843
    },
    {
        timestamp: 1672003800000,
        value: 1011.0601562525
    },
    {
        timestamp: 1672004700000,
        value: 970.6357318005
    },
    {
        timestamp: 1672005600000,
        value: 920.0565579801
    },
    {
        timestamp: 1672006500000,
        value: 896.6224144021
    },
    {
        timestamp: 1672007400000,
        value: 896.6224144021
    },
    {
        timestamp: 1672008300000,
        value: 912.1434745536
    },
    {
        timestamp: 1672009200000,
        value: 906.3919401227
    },
    {
        timestamp: 1672010100000,
        value: 900.7136664473
    },
    {
        timestamp: 1672011000000,
        value: 885.1926062958
    },
    {
        timestamp: 1672011900000,
        value: 885.1926062958
    },
    {
        timestamp: 1672012800000,
        value: 853.2128371632
    },
    {
        timestamp: 1672013700000,
        value: 853.2128371632
    },
    {
        timestamp: 1672014600000,
        value: 853.2128371632
    },
    {
        timestamp: 1672015500000,
        value: 853.2128371632
    },
    {
        timestamp: 1672016400000,
        value: 853.2128371632
    },
    {
        timestamp: 1672017300000,
        value: 853.2128371632
    },
    {
        timestamp: 1672018200000,
        value: 853.2128371632
    },
    {
        timestamp: 1672019100000,
        value: 853.2128371632
    },
    {
        timestamp: 1672020000000,
        value: 857.906942739
    },
    {
        timestamp: 1672020900000,
        value: 857.906942739
    },
    {
        timestamp: 1672021800000,
        value: 857.906942739
    },
    {
        timestamp: 1672022700000,
        value: 885.9984799102
    },
    {
        timestamp: 1672023600000,
        value: 896.3393174356
    },
    {
        timestamp: 1672024500000,
        value: 902.3190665704
    },
    {
        timestamp: 1672025400000,
        value: 926.3170278275
    },
    {
        timestamp: 1672026300000,
        value: 930.140859181
    },
    {
        timestamp: 1672027200000,
        value: 961.8919560009
    },
    {
        timestamp: 1672028100000,
        value: 964.3719883265
    },
    {
        timestamp: 1672029000000,
        value: 971.4722886192
    },
    {
        timestamp: 1672029900000,
        value: 979.3244871181
    },
    {
        timestamp: 1672030800000,
        value: 1066.8371416978
    },
    {
        timestamp: 1672031700000,
        value: 1103.7463706788
    },
    {
        timestamp: 1672032600000,
        value: 1079.6592164884
    },
    {
        timestamp: 1672033500000,
        value: 1110.2573469312
    },
    {
        timestamp: 1672034400000,
        value: 1143.3144515893
    },
    {
        timestamp: 1672035300000,
        value: 1179.6862473496
    },
    {
        timestamp: 1672036200000,
        value: 1211.5168386392
    },
    {
        timestamp: 1672037100000,
        value: 1215.669368369
    },
    {
        timestamp: 1672038000000,
        value: 1237.1364539159
    },
    {
        timestamp: 1672038900000,
        value: 1275.7000636418
    },
    {
        timestamp: 1672039800000,
        value: 1272.7767310352
    },
    {
        timestamp: 1672040700000,
        value: 1324.0651478747
    },
    {
        timestamp: 1672041600000,
        value: 1324.3401729643
    },
    {
        timestamp: 1672042500000,
        value: 1316.5694705264
    },
    {
        timestamp: 1672043400000,
        value: 1316.5694705264
    },
    {
        timestamp: 1672044300000,
        value: 1316.5694705264
    },
    {
        timestamp: 1672045200000,
        value: 1315.1196493976
    },
    {
        timestamp: 1672046100000,
        value: 1313.5814983651
    },
    {
        timestamp: 1672047000000,
        value: 1313.5814983651
    },
    {
        timestamp: 1672047900000,
        value: 1313.5814983651
    },
    {
        timestamp: 1672048800000,
        value: 1302.1489001521
    },
    {
        timestamp: 1672049700000,
        value: 1308.7321902444
    },
    {
        timestamp: 1672050600000,
        value: 1273.125788594
    },
    {
        timestamp: 1672051500000,
        value: 1242.1737245177
    },
    {
        timestamp: 1672052400000,
        value: 1202.2752800005
    },
    {
        timestamp: 1672053300000,
        value: 1160.2573188995
    },
    {
        timestamp: 1672054200000,
        value: 1144.3711449358
    },
    {
        timestamp: 1672055100000,
        value: 1156.2428002672
    },
    {
        timestamp: 1672056000000,
        value: 1165.1799381997
    },
    {
        timestamp: 1672056900000,
        value: 1165.1799381997
    },
    {
        timestamp: 1672057800000,
        value: 1212.9668287584
    },
    {
        timestamp: 1672058700000,
        value: 1212.9668287584
    },
    {
        timestamp: 1672059600000,
        value: 1163.0894647496
    },
    {
        timestamp: 1672060500000,
        value: 1163.0894647496
    },
    {
        timestamp: 1672061400000,
        value: 1188.678498663
    },
    {
        timestamp: 1672062300000,
        value: 1204.0465659682
    },
    {
        timestamp: 1672063200000,
        value: 1200.0455028789
    },
    {
        timestamp: 1672064100000,
        value: 1235.2231640701
    },
    {
        timestamp: 1672065000000,
        value: 1229.9692054382
    },
    {
        timestamp: 1672065900000,
        value: 1272.7895445077
    },
    {
        timestamp: 1672066800000,
        value: 1303.5620073758
    },
    {
        timestamp: 1672067700000,
        value: 1301.9184547523
    },
    {
        timestamp: 1672068600000,
        value: 1287.3279537307
    },
    {
        timestamp: 1672069500000,
        value: 1291.3134798283
    },
    {
        timestamp: 1672070400000,
        value: 1303.9291048601
    },
    {
        timestamp: 1672071300000,
        value: 1289.6680380598
    },
    {
        timestamp: 1672072200000,
        value: 1289.6680380598
    },
    {
        timestamp: 1672073100000,
        value: 1303.9291048601
    },
    {
        timestamp: 1672074000000,
        value: 1320.9605426672
    },
    {
        timestamp: 1672074900000,
        value: 1320.9605426672
    },
    {
        timestamp: 1672075800000,
        value: 1304.2618969679
    },
    {
        timestamp: 1672076700000,
        value: 1304.2618969679
    },
    {
        timestamp: 1672077600000,
        value: 1295.2407687667
    },
    {
        timestamp: 1672078500000,
        value: 1295.2407687667
    },
    {
        timestamp: 1672079400000,
        value: 1277.2727730256
    },
    {
        timestamp: 1672080300000,
        value: 1250.8516969213
    },
    {
        timestamp: 1672081200000,
        value: 1219.978561776
    },
    {
        timestamp: 1672082100000,
        value: 1204.9214723105
    },
    {
        timestamp: 1672083000000,
        value: 1192.7853204454
    },
    {
        timestamp: 1672083900000,
        value: 1160.3089634491
    },
    {
        timestamp: 1672084800000,
        value: 1127.547584938
    },
    {
        timestamp: 1672085700000,
        value: 1107.6777708358
    },
    {
        timestamp: 1672086600000,
        value: 1095.2342725109
    },
    {
        timestamp: 1672087500000,
        value: 1083.8083897315
    },
    {
        timestamp: 1672088400000,
        value: 1047.9105600246
    },
    {
        timestamp: 1672089300000,
        value: 1052.1565067836
    },
    {
        timestamp: 1672090200000,
        value: 1052.6848064297
    },
    {
        timestamp: 1672091100000,
        value: 1037.767414866
    },
    {
        timestamp: 1672092000000,
        value: 980.9110007347
    },
    {
        timestamp: 1672092900000,
        value: 977.3731870872
    },
    {
        timestamp: 1672093800000,
        value: 970.0645714276
    },
    {
        timestamp: 1672094700000,
        value: 970.0645714276
    },
    {
        timestamp: 1672095600000,
        value: 964.7519089267
    },
    {
        timestamp: 1672096500000,
        value: 964.7519089267
    },
    {
        timestamp: 1672097400000,
        value: 964.7519089267
    },
    {
        timestamp: 1672098300000,
        value: 959.0096070739
    },
    {
        timestamp: 1672099200000,
        value: 961.2631863855
    },
    {
        timestamp: 1672100100000,
        value: 961.2631863855
    },
    {
        timestamp: 1672101000000,
        value: 946.1655854783
    },
    {
        timestamp: 1672101900000,
        value: 946.1655854783
    },
    {
        timestamp: 1672102800000,
        value: 954.0738006364
    },
    {
        timestamp: 1672103700000,
        value: 954.0738006364
    },
    {
        timestamp: 1672104600000,
        value: 954.0738006364
    },
    {
        timestamp: 1672105500000,
        value: 968.418922904
    },
    {
        timestamp: 1672106400000,
        value: 973.420636017
    },
    {
        timestamp: 1672107300000,
        value: 989.8176917595
    },
    {
        timestamp: 1672108200000,
        value: 1008.8103758076
    },
    {
        timestamp: 1672109100000,
        value: 1034.062701666
    },
    {
        timestamp: 1672110000000,
        value: 1086.0136925695
    },
    {
        timestamp: 1672110900000,
        value: 1114.3532908328
    },
    {
        timestamp: 1672111800000,
        value: 1150.7983942566
    },
    {
        timestamp: 1672112700000,
        value: 1187.4568924747
    },
    {
        timestamp: 1672113600000,
        value: 1253.8098477048
    },
    {
        timestamp: 1672114500000,
        value: 1294.6715592017
    },
    {
        timestamp: 1672115400000,
        value: 1311.3166540522
    },
    {
        timestamp: 1672116300000,
        value: 1338.2914798592
    },
    {
        timestamp: 1672117200000,
        value: 1385.650651538
    },
    {
        timestamp: 1672118100000,
        value: 1424.4746412628
    },
    {
        timestamp: 1672119000000,
        value: 1470.551757136
    },
    {
        timestamp: 1672119900000,
        value: 1507.9590218242
    },
    {
        timestamp: 1672120800000,
        value: 1531.6558675837
    },
    {
        timestamp: 1672121700000,
        value: 1569.6012715105
    },
    {
        timestamp: 1672122600000,
        value: 1590.8267384779
    },
    {
        timestamp: 1672123500000,
        value: 1595.8610991627
    },
    {
        timestamp: 1672124400000,
        value: 1601.7684380386
    },
    {
        timestamp: 1672125300000,
        value: 1611.9590672196
    },
    {
        timestamp: 1672126200000,
        value: 1610.2342529323
    },
    {
        timestamp: 1672127100000,
        value: 1592.5421666862
    },
    {
        timestamp: 1672128000000,
        value: 1568.3093471877
    },
    {
        timestamp: 1672128900000,
        value: 1554.4285008534
    },
    {
        timestamp: 1672129800000,
        value: 1557.545924854
    },
    {
        timestamp: 1672130700000,
        value: 1543.8196288377
    },
    {
        timestamp: 1672131600000,
        value: 1539.2895576044
    },
    {
        timestamp: 1672132500000,
        value: 1549.2018003719
    },
    {
        timestamp: 1672133400000,
        value: 1540.9758286184
    },
    {
        timestamp: 1672134300000,
        value: 1539.622422201
    },
    {
        timestamp: 1672135200000,
        value: 1533.4978436473
    },
    {
        timestamp: 1672136100000,
        value: 1530.5778077071
    },
    {
        timestamp: 1672137000000,
        value: 1522.5754442577
    },
    {
        timestamp: 1672137900000,
        value: 1524.8517594865
    },
    {
        timestamp: 1672138800000,
        value: 1503.2580473504
    },
    {
        timestamp: 1672139700000,
        value: 1492.6987935317
    },
    {
        timestamp: 1672140600000,
        value: 1482.8364002897
    },
    {
        timestamp: 1672141500000,
        value: 1482.2246106441
    },
    {
        timestamp: 1672142400000,
        value: 1471.2771621403
    },
    {
        timestamp: 1672143300000,
        value: 1471.2771621403
    },
    {
        timestamp: 1672144200000,
        value: 1471.767280985
    },
    {
        timestamp: 1672145100000,
        value: 1476.250460096
    },
    {
        timestamp: 1672146000000,
        value: 1474.4554467432
    },
    {
        timestamp: 1672146900000,
        value: 1478.7457093855
    },
    {
        timestamp: 1672147800000,
        value: 1492.6525162657
    },
    {
        timestamp: 1672148700000,
        value: 1504.5097765554
    },
    {
        timestamp: 1672149600000,
        value: 1519.8664111411
    },
    {
        timestamp: 1672150500000,
        value: 1569.6684168265
    },
    {
        timestamp: 1672151400000,
        value: 1592.8392969115
    },
    {
        timestamp: 1672152300000,
        value: 1632.6775854339
    },
    {
        timestamp: 1672153200000,
        value: 1666.3896515109
    },
    {
        timestamp: 1672154100000,
        value: 1666.3896515109
    },
    {
        timestamp: 1672155000000,
        value: 1666.3896515109
    },
    {
        timestamp: 1672155900000,
        value: 1644.8733860983
    },
    {
        timestamp: 1672156800000,
        value: 1650.5343956989
    },
    {
        timestamp: 1672157700000,
        value: 1640.3392391527
    },
    {
        timestamp: 1672158600000,
        value: 1635.9385743101
    },
    {
        timestamp: 1672159500000,
        value: 1628.8148991164
    },
    {
        timestamp: 1672160400000,
        value: 1619.4337151624
    },
    {
        timestamp: 1672161300000,
        value: 1606.4382104966
    },
    {
        timestamp: 1672162200000,
        value: 1582.4412425328
    },
    {
        timestamp: 1672163100000,
        value: 1565.0009792039
    },
    {
        timestamp: 1672164000000,
        value: 1533.3593166544
    },
    {
        timestamp: 1672164900000,
        value: 1520.8791833454
    },
    {
        timestamp: 1672165800000,
        value: 1483.2713594979
    },
    {
        timestamp: 1672166700000,
        value: 1462.4933193642
    },
    {
        timestamp: 1672167600000,
        value: 1393.3089758511
    },
    {
        timestamp: 1672168500000,
        value: 1374.540340674
    },
    {
        timestamp: 1672169400000,
        value: 1352.4977608887
    },
    {
        timestamp: 1672170300000,
        value: 1348.5945091217
    },
    {
        timestamp: 1672171200000,
        value: 1276.6435046269
    },
    {
        timestamp: 1672172100000,
        value: 1285.7485431197
    },
    {
        timestamp: 1672173000000,
        value: 1254.9586548911
    },
    {
        timestamp: 1672173900000,
        value: 1228.2878276717
    },
    {
        timestamp: 1672174800000,
        value: 1188.5270346959
    },
    {
        timestamp: 1672175700000,
        value: 1181.8204235061
    },
    {
        timestamp: 1672176600000,
        value: 1144.3659877845
    },
    {
        timestamp: 1672177500000,
        value: 1111.0354795165
    },
    {
        timestamp: 1672178400000,
        value: 1019.181128047
    },
    {
        timestamp: 1672179300000,
        value: 1006.2909569281
    },
    {
        timestamp: 1672180200000,
        value: 992.4756879519
    },
    {
        timestamp: 1672181100000,
        value: 989.9686059808
    },
    {
        timestamp: 1672182000000,
        value: 986.1059790056
    },
    {
        timestamp: 1672182900000,
        value: 986.1059790056
    },
    {
        timestamp: 1672183800000,
        value: 986.1059790056
    },
    {
        timestamp: 1672184700000,
        value: 987.4186427467
    },
    {
        timestamp: 1672185600000,
        value: 965.8675100131
    },
    {
        timestamp: 1672186500000,
        value: 954.4561964292
    },
    {
        timestamp: 1672187400000,
        value: 954.4561964292
    },
    {
        timestamp: 1672188300000,
        value: 952.3062321454
    },
    {
        timestamp: 1672189200000,
        value: 952.3062321454
    },
    {
        timestamp: 1672190100000,
        value: 952.3062321454
    },
    {
        timestamp: 1672191000000,
        value: 952.3062321454
    },
    {
        timestamp: 1672191900000,
        value: 959.3723312316
    },
    {
        timestamp: 1672192800000,
        value: 967.7271862364
    },
    {
        timestamp: 1672193700000,
        value: 977.9536488406
    },
    {
        timestamp: 1672194600000,
        value: 1008.249127054
    },
    {
        timestamp: 1672195500000,
        value: 1010.4969854458
    },
    {
        timestamp: 1672196400000,
        value: 1059.2028851637
    },
    {
        timestamp: 1672197300000,
        value: 1078.5581985655
    },
    {
        timestamp: 1672198200000,
        value: 1108.3336616567
    },
    {
        timestamp: 1672199100000,
        value: 1140.5316300506
    },
    {
        timestamp: 1672200000000,
        value: 1212.2898838614
    },
    {
        timestamp: 1672200900000,
        value: 1238.5204443072
    },
    {
        timestamp: 1672201800000,
        value: 1247.6777061681
    },
    {
        timestamp: 1672202700000,
        value: 1286.6326451991
    },
    {
        timestamp: 1672203600000,
        value: 1353.6084125052
    },
    {
        timestamp: 1672204500000,
        value: 1364.5655913163
    },
    {
        timestamp: 1672205400000,
        value: 1383.7837198734
    },
    {
        timestamp: 1672206300000,
        value: 1413.0706728705
    },
    {
        timestamp: 1672207200000,
        value: 1453.2516622654
    },
    {
        timestamp: 1672208100000,
        value: 1467.2510400856
    },
    {
        timestamp: 1672209000000,
        value: 1485.1963736695
    },
    {
        timestamp: 1672209900000,
        value: 1506.8617686601
    },
    {
        timestamp: 1672210800000,
        value: 1506.6238130037
    },
    {
        timestamp: 1672211700000,
        value: 1506.6238130037
    },
    {
        timestamp: 1672212600000,
        value: 1511.3044699239
    },
    {
        timestamp: 1672213500000,
        value: 1506.8521348863
    },
    {
        timestamp: 1672214400000,
        value: 1504.5339390243
    },
    {
        timestamp: 1672215300000,
        value: 1500.4365030133
    },
    {
        timestamp: 1672216200000,
        value: 1490.0289504938
    },
    {
        timestamp: 1672217100000,
        value: 1482.6036168843
    },
    {
        timestamp: 1672218000000,
        value: 1479.2136898427
    },
    {
        timestamp: 1672218900000,
        value: 1479.2136898427
    },
    {
        timestamp: 1672219800000,
        value: 1480.332063317
    },
    {
        timestamp: 1672220700000,
        value: 1480.332063317
    },
    {
        timestamp: 1672221600000,
        value: 1478.98536796
    },
    {
        timestamp: 1672222500000,
        value: 1472.1185570065
    },
    {
        timestamp: 1672223400000,
        value: 1466.880224838
    },
    {
        timestamp: 1672224300000,
        value: 1441.391354496
    },
    {
        timestamp: 1672225200000,
        value: 1399.3170495936
    },
    {
        timestamp: 1672226100000,
        value: 1390.7040692799
    },
    {
        timestamp: 1672227000000,
        value: 1389.0748423651
    },
    {
        timestamp: 1672227900000,
        value: 1397.7147518563
    },
    {
        timestamp: 1672228800000,
        value: 1400.3464549635
    },
    {
        timestamp: 1672229700000,
        value: 1400.3464549635
    },
    {
        timestamp: 1672230600000,
        value: 1400.3464549635
    },
    {
        timestamp: 1672231500000,
        value: 1398.9168699611
    },
    {
        timestamp: 1672232400000,
        value: 1403.473513761
    },
    {
        timestamp: 1672233300000,
        value: 1411.7909795095
    },
    {
        timestamp: 1672234200000,
        value: 1445.7119033484
    },
    {
        timestamp: 1672235100000,
        value: 1474.8361114666
    },
    {
        timestamp: 1672236000000,
        value: 1511.321634937
    },
    {
        timestamp: 1672236900000,
        value: 1535.6121331032
    },
    {
        timestamp: 1672237800000,
        value: 1568.1340691209
    },
    {
        timestamp: 1672238700000,
        value: 1590.6717200939
    },
    {
        timestamp: 1672239600000,
        value: 1621.783610408
    },
    {
        timestamp: 1672240500000,
        value: 1631.0187897761
    },
    {
        timestamp: 1672241400000,
        value: 1621.783610408
    },
    {
        timestamp: 1672242300000,
        value: 1613.3175715787
    },
    {
        timestamp: 1672243200000,
        value: 1610.6771184832
    },
    {
        timestamp: 1672244100000,
        value: 1610.6771184832
    },
    {
        timestamp: 1672245000000,
        value: 1610.6771184832
    },
    {
        timestamp: 1672245900000,
        value: 1601.3649617596
    },
    {
        timestamp: 1672246800000,
        value: 1592.607051877
    },
    {
        timestamp: 1672247700000,
        value: 1580.6528764814
    },
    {
        timestamp: 1672248600000,
        value: 1565.2597189328
    },
    {
        timestamp: 1672249500000,
        value: 1544.6471898482
    },
    {
        timestamp: 1672250400000,
        value: 1505.6494082758
    },
    {
        timestamp: 1672251300000,
        value: 1492.4471342931
    },
    {
        timestamp: 1672252200000,
        value: 1456.4642075323
    },
    {
        timestamp: 1672253100000,
        value: 1433.0749503477
    },
    {
        timestamp: 1672254000000,
        value: 1367.6800318568
    },
    {
        timestamp: 1672254900000,
        value: 1352.4411077403
    },
    {
        timestamp: 1672255800000,
        value: 1328.8794708139
    },
    {
        timestamp: 1672256700000,
        value: 1324.709813146
    },
    {
        timestamp: 1672257600000,
        value: 1282.9700702548
    },
    {
        timestamp: 1672258500000,
        value: 1274.7324928715
    },
    {
        timestamp: 1672259400000,
        value: 1259.3581336825
    },
    {
        timestamp: 1672260300000,
        value: 1214.0259108509
    },
    {
        timestamp: 1672261200000,
        value: 1163.311912761
    },
    {
        timestamp: 1672262100000,
        value: 1148.5761276363
    },
    {
        timestamp: 1672263000000,
        value: 1130.838494146
    },
    {
        timestamp: 1672263900000,
        value: 1091.554544813
    },
    {
        timestamp: 1672264800000,
        value: 1006.0035648701
    },
    {
        timestamp: 1672265700000,
        value: 995.0956949208
    },
    {
        timestamp: 1672266600000,
        value: 993.9874919463
    },
    {
        timestamp: 1672267500000,
        value: 993.9874919463
    },
    {
        timestamp: 1672268400000,
        value: 987.3992730825
    },
    {
        timestamp: 1672269300000,
        value: 980.500311622
    },
    {
        timestamp: 1672270200000,
        value: 980.500311622
    },
    {
        timestamp: 1672271100000,
        value: 978.6067613736
    },
    {
        timestamp: 1672272000000,
        value: 957.8708136046
    },
    {
        timestamp: 1672272900000,
        value: 957.8708136046
    },
    {
        timestamp: 1672273800000,
        value: 957.8708136046
    },
    {
        timestamp: 1672274700000,
        value: 942.6830400295
    },
    {
        timestamp: 1672275600000,
        value: 942.6830400295
    },
    {
        timestamp: 1672276500000,
        value: 949.9496094248
    },
    {
        timestamp: 1672277400000,
        value: 949.9496094248
    },
    {
        timestamp: 1672278300000,
        value: 957.8708136046
    },
    {
        timestamp: 1672279200000,
        value: 970.6078137781
    },
    {
        timestamp: 1672280100000,
        value: 980.6064763979
    },
    {
        timestamp: 1672281000000,
        value: 1003.5104878979
    },
    {
        timestamp: 1672281900000,
        value: 1011.3968644064
    },
    {
        timestamp: 1672282800000,
        value: 1073.2708957154
    },
    {
        timestamp: 1672283700000,
        value: 1090.3046221132
    },
    {
        timestamp: 1672284600000,
        value: 1126.2918579874
    },
    {
        timestamp: 1672285500000,
        value: 1133.6227232578
    },
    {
        timestamp: 1672286400000,
        value: 1220.6330813843
    },
    {
        timestamp: 1672287300000,
        value: 1228.5153439098
    },
    {
        timestamp: 1672288200000,
        value: 1245.5302689052
    },
    {
        timestamp: 1672289100000,
        value: 1293.9499729582
    },
    {
        timestamp: 1672290000000,
        value: 1340.7128393593
    },
    {
        timestamp: 1672290900000,
        value: 1347.6284787752
    },
    {
        timestamp: 1672291800000,
        value: 1359.8739448264
    },
    {
        timestamp: 1672292700000,
        value: 1366.4001913866
    },
    {
        timestamp: 1672293600000,
        value: 1422.9978817087
    },
    {
        timestamp: 1672294500000,
        value: 1436.7209794602
    },
    {
        timestamp: 1672295400000,
        value: 1439.3970916088
    },
    {
        timestamp: 1672296300000,
        value: 1438.5616062951
    },
    {
        timestamp: 1672297200000,
        value: 1439.3706735922
    },
    {
        timestamp: 1672298100000,
        value: 1441.6138039956
    },
    {
        timestamp: 1672299000000,
        value: 1436.7406259378
    },
    {
        timestamp: 1672299900000,
        value: 1421.7306496997
    },
    {
        timestamp: 1672300800000,
        value: 1430.6054217633
    },
    {
        timestamp: 1672301700000,
        value: 1425.0833509334
    },
    {
        timestamp: 1672302600000,
        value: 1432.4628861042
    },
    {
        timestamp: 1672303500000,
        value: 1440.0820622755
    },
    {
        timestamp: 1672304400000,
        value: 1439.6501357365
    },
    {
        timestamp: 1672305300000,
        value: 1452.8936154327
    },
    {
        timestamp: 1672306200000,
        value: 1454.5695344721
    },
    {
        timestamp: 1672307100000,
        value: 1488.6145562231
    },
    {
        timestamp: 1672308000000,
        value: 1487.8690276309
    },
    {
        timestamp: 1672308900000,
        value: 1484.3272608977
    },
    {
        timestamp: 1672309800000,
        value: 1455.168020013
    },
    {
        timestamp: 1672310700000,
        value: 1439.9739034226
    },
    {
        timestamp: 1672311600000,
        value: 1403.977900496
    },
    {
        timestamp: 1672312500000,
        value: 1398.4558296661
    },
    {
        timestamp: 1672313400000,
        value: 1403.977900496
    },
    {
        timestamp: 1672314300000,
        value: 1411.601990009
    },
    {
        timestamp: 1672315200000,
        value: 1397.1157736128
    },
    {
        timestamp: 1672316100000,
        value: 1397.1157736128
    },
    {
        timestamp: 1672317000000,
        value: 1397.1157736128
    },
    {
        timestamp: 1672317900000,
        value: 1397.1157736128
    },
    {
        timestamp: 1672318800000,
        value: 1391.2198312493
    },
    {
        timestamp: 1672319700000,
        value: 1388.3962407686
    },
    {
        timestamp: 1672320600000,
        value: 1401.9592503569
    },
    {
        timestamp: 1672321500000,
        value: 1415.3385243997
    },
    {
        timestamp: 1672322400000,
        value: 1443.4663770607
    },
    {
        timestamp: 1672323300000,
        value: 1474.0512263579
    },
    {
        timestamp: 1672324200000,
        value: 1511.0789353763
    },
    {
        timestamp: 1672325100000,
        value: 1543.538263087
    },
    {
        timestamp: 1672326000000,
        value: 1577.6543073229
    },
    {
        timestamp: 1672326900000,
        value: 1577.6543073229
    },
    {
        timestamp: 1672327800000,
        value: 1577.6543073229
    },
    {
        timestamp: 1672328700000,
        value: 1577.6543073229
    },
    {
        timestamp: 1672329600000,
        value: 1565.4646856829
    },
    {
        timestamp: 1672330500000,
        value: 1565.4646856829
    },
    {
        timestamp: 1672331400000,
        value: 1567.6910611952
    },
    {
        timestamp: 1672332300000,
        value: 1567.6910611952
    },
    {
        timestamp: 1672333200000,
        value: 1557.5949418216
    },
    {
        timestamp: 1672334100000,
        value: 1554.0006607741
    },
    {
        timestamp: 1672335000000,
        value: 1529.1652262795
    },
    {
        timestamp: 1672335900000,
        value: 1508.4524380697
    },
    {
        timestamp: 1672336800000,
        value: 1492.6077965736
    },
    {
        timestamp: 1672337700000,
        value: 1467.6481580672
    },
    {
        timestamp: 1672338600000,
        value: 1449.3131729052
    },
    {
        timestamp: 1672339500000,
        value: 1412.3309679336
    },
    {
        timestamp: 1672340400000,
        value: 1349.3781698322
    },
    {
        timestamp: 1672341300000,
        value: 1337.6825839328
    },
    {
        timestamp: 1672342200000,
        value: 1315.8400040101
    },
    {
        timestamp: 1672343100000,
        value: 1316.0320741262
    },
    {
        timestamp: 1672344000000,
        value: 1250.7071483719
    },
    {
        timestamp: 1672344900000,
        value: 1232.6911974251
    },
    {
        timestamp: 1672345800000,
        value: 1196.4631128103
    },
    {
        timestamp: 1672346700000,
        value: 1186.0384888562
    },
    {
        timestamp: 1672347600000,
        value: 1159.5138624966
    },
    {
        timestamp: 1672348500000,
        value: 1142.4178784979
    },
    {
        timestamp: 1672349400000,
        value: 1097.8497802653
    },
    {
        timestamp: 1672350300000,
        value: 1071.4958244057
    },
    {
        timestamp: 1672351200000,
        value: 1016.9310608832
    },
    {
        timestamp: 1672352100000,
        value: 1005.3946135395
    },
    {
        timestamp: 1672353000000,
        value: 1005.3946135395
    },
    {
        timestamp: 1672353900000,
        value: 1002.4595168388
    },
    {
        timestamp: 1672354800000,
        value: 989.5620488925
    },
    {
        timestamp: 1672355700000,
        value: 989.5620488925
    },
    {
        timestamp: 1672356600000,
        value: 990.4100808269
    },
    {
        timestamp: 1672357500000,
        value: 983.2094931233
    },
    {
        timestamp: 1672358400000,
        value: 960.1520070743
    },
    {
        timestamp: 1672359300000,
        value: 955.7138954751
    },
    {
        timestamp: 1672360200000,
        value: 955.7138954751
    },
    {
        timestamp: 1672361100000,
        value: 954.1835390577
    },
    {
        timestamp: 1672362000000,
        value: 954.1835390577
    },
    {
        timestamp: 1672362900000,
        value: 954.1835390577
    },
    {
        timestamp: 1672363800000,
        value: 954.1835390577
    },
    {
        timestamp: 1672364700000,
        value: 954.1835390577
    },
    {
        timestamp: 1672365600000,
        value: 974.2919796795
    },
    {
        timestamp: 1672366500000,
        value: 974.2919796795
    },
    {
        timestamp: 1672367400000,
        value: 1004.5695138864
    },
    {
        timestamp: 1672368300000,
        value: 1018.3027374295
    },
    {
        timestamp: 1672369200000,
        value: 1063.8066647198
    },
    {
        timestamp: 1672370100000,
        value: 1079.7724410211
    },
    {
        timestamp: 1672371000000,
        value: 1100.5790251532
    },
    {
        timestamp: 1672371900000,
        value: 1126.9720926675
    },
    {
        timestamp: 1672372800000,
        value: 1176.4613664542
    },
    {
        timestamp: 1672373700000,
        value: 1194.4097423584
    },
    {
        timestamp: 1672374600000,
        value: 1214.8275084448
    },
    {
        timestamp: 1672375500000,
        value: 1229.7793314188
    },
    {
        timestamp: 1672376400000,
        value: 1323.8744637291
    },
    {
        timestamp: 1672377300000,
        value: 1330.6077323606
    },
    {
        timestamp: 1672378200000,
        value: 1343.5674293959
    },
    {
        timestamp: 1672379100000,
        value: 1351.7238309162
    },
    {
        timestamp: 1672380000000,
        value: 1429.0586180417
    },
    {
        timestamp: 1672380900000,
        value: 1438.7494087238
    },
    {
        timestamp: 1672381800000,
        value: 1449.3168727424
    },
    {
        timestamp: 1672382700000,
        value: 1470.3514099284
    },
    {
        timestamp: 1672383600000,
        value: 1478.4054634035
    },
    {
        timestamp: 1672384500000,
        value: 1494.6411368539
    },
    {
        timestamp: 1672385400000,
        value: 1500.6553936268
    },
    {
        timestamp: 1672386300000,
        value: 1501.9792140462
    },
    {
        timestamp: 1672387200000,
        value: 1502.9710543161
    },
    {
        timestamp: 1672388100000,
        value: 1507.6853497022
    },
    {
        timestamp: 1672389000000,
        value: 1502.9710543161
    },
    {
        timestamp: 1672389900000,
        value: 1501.6472338967
    },
    {
        timestamp: 1672390800000,
        value: 1498.8081601337
    },
    {
        timestamp: 1672391700000,
        value: 1497.9549513082
    },
    {
        timestamp: 1672392600000,
        value: 1498.3583802895
    },
    {
        timestamp: 1672393500000,
        value: 1494.8136628753
    },
    {
        timestamp: 1672394400000,
        value: 1477.9394858778
    },
    {
        timestamp: 1672395300000,
        value: 1477.9394858778
    },
    {
        timestamp: 1672396200000,
        value: 1491.0187316021
    },
    {
        timestamp: 1672397100000,
        value: 1474.0505075563
    },
    {
        timestamp: 1672398000000,
        value: 1448.8724242917
    },
    {
        timestamp: 1672398900000,
        value: 1410.0796232957
    },
    {
        timestamp: 1672399800000,
        value: 1409.2177472181
    },
    {
        timestamp: 1672400700000,
        value: 1402.6282350404
    },
    {
        timestamp: 1672401600000,
        value: 1388.9162740147
    },
    {
        timestamp: 1672402500000,
        value: 1388.9162740147
    },
    {
        timestamp: 1672403400000,
        value: 1384.0622554294
    },
    {
        timestamp: 1672404300000,
        value: 1394.4442766798
    },
    {
        timestamp: 1672405200000,
        value: 1385.9035912095
    },
    {
        timestamp: 1672406100000,
        value: 1385.9035912095
    },
    {
        timestamp: 1672407000000,
        value: 1400.9716917985
    },
    {
        timestamp: 1672407900000,
        value: 1407.4785672599
    },
    {
        timestamp: 1672408800000,
        value: 1448.6431334475
    },
    {
        timestamp: 1672409700000,
        value: 1474.4870180666
    },
    {
        timestamp: 1672410600000,
        value: 1511.8802843056
    },
    {
        timestamp: 1672411500000,
        value: 1538.1352315278
    },
    {
        timestamp: 1672412400000,
        value: 1566.5559460774
    },
    {
        timestamp: 1672413300000,
        value: 1578.0073040001
    },
    {
        timestamp: 1672414200000,
        value: 1566.5559460774
    },
    {
        timestamp: 1672415100000,
        value: 1556.2126182253
    },
    {
        timestamp: 1672416000000,
        value: 1550.5149106156
    },
    {
        timestamp: 1672416900000,
        value: 1543.0775222542
    },
    {
        timestamp: 1672417800000,
        value: 1543.0775222542
    },
    {
        timestamp: 1672418700000,
        value: 1535.5765899689
    },
    {
        timestamp: 1672419600000,
        value: 1538.4969202718
    },
    {
        timestamp: 1672420500000,
        value: 1525.5495030702
    },
    {
        timestamp: 1672421400000,
        value: 1499.1937743313
    },
    {
        timestamp: 1672422300000,
        value: 1487.3412236382
    },
    {
        timestamp: 1672423200000,
        value: 1471.0366522204
    },
    {
        timestamp: 1672424100000,
        value: 1441.9995189596
    },
    {
        timestamp: 1672425000000,
        value: 1415.156264965
    },
    {
        timestamp: 1672425900000,
        value: 1389.7185861391
    },
    {
        timestamp: 1672426800000,
        value: 1334.6747756963
    },
    {
        timestamp: 1672427700000,
        value: 1326.8855010836
    },
    {
        timestamp: 1672428600000,
        value: 1312.7767197003
    },
    {
        timestamp: 1672429500000,
        value: 1304.9434242751
    },
    {
        timestamp: 1672430400000,
        value: 1241.7087638426
    },
    {
        timestamp: 1672431300000,
        value: 1228.6542131521
    },
    {
        timestamp: 1672432200000,
        value: 1188.4119821592
    },
    {
        timestamp: 1672433100000,
        value: 1197.5254530585
    },
    {
        timestamp: 1672434000000,
        value: 1135.1237604129
    },
    {
        timestamp: 1672434900000,
        value: 1123.2159814955
    },
    {
        timestamp: 1672435800000,
        value: 1101.0153598271
    },
    {
        timestamp: 1672436700000,
        value: 1077.5759633842
    },
    {
        timestamp: 1672437600000,
        value: 1030.0703901232
    },
    {
        timestamp: 1672438500000,
        value: 1023.1739772871
    },
    {
        timestamp: 1672439400000,
        value: 1011.0862151235
    },
    {
        timestamp: 1672440300000,
        value: 1011.0862151235
    },
    {
        timestamp: 1672441200000,
        value: 995.6356403617
    },
    {
        timestamp: 1672442100000,
        value: 995.6356403617
    },
    {
        timestamp: 1672443000000,
        value: 995.7580577281
    },
    {
        timestamp: 1672443900000,
        value: 991.9516936181
    },
    {
        timestamp: 1672444800000,
        value: 958.4597318231
    },
    {
        timestamp: 1672445700000,
        value: 956.9740563334
    },
    {
        timestamp: 1672446600000,
        value: 956.9740563334
    },
    {
        timestamp: 1672447500000,
        value: 944.3841379076
    },
    {
        timestamp: 1672448400000,
        value: 944.3841379076
    },
    {
        timestamp: 1672449300000,
        value: 942.0105844231
    },
    {
        timestamp: 1672450200000,
        value: 944.3841379076
    },
    {
        timestamp: 1672451100000,
        value: 944.3841379076
    },
    {
        timestamp: 1672452000000,
        value: 946.1565330284
    },
    {
        timestamp: 1672452900000,
        value: 958.7464514542
    },
    {
        timestamp: 1672453800000,
        value: 958.7464514542
    },
    {
        timestamp: 1672454700000,
        value: 965.2145785286
    },
    {
        timestamp: 1672455600000,
        value: 976.7800753727
    },
    {
        timestamp: 1672456500000,
        value: 997.1174850982
    },
    {
        timestamp: 1672457400000,
        value: 997.1889537473
    },
    {
        timestamp: 1672458300000,
        value: 1017.8189602841
    },
    {
        timestamp: 1672459200000,
        value: 1044.0815897016
    },
    {
        timestamp: 1672460100000,
        value: 1052.3616037992
    },
    {
        timestamp: 1672461000000,
        value: 1068.0890517635
    },
    {
        timestamp: 1672461900000,
        value: 1081.1288085969
    },
    {
        timestamp: 1672462800000,
        value: 1185.9267038914
    },
    {
        timestamp: 1672463700000,
        value: 1210.5901901116
    },
    {
        timestamp: 1672464600000,
        value: 1234.9880778189
    },
    {
        timestamp: 1672465500000,
        value: 1253.0062572698
    },
    {
        timestamp: 1672466400000,
        value: 1327.8117445992
    },
    {
        timestamp: 1672467300000,
        value: 1339.443349916
    },
    {
        timestamp: 1672468200000,
        value: 1351.6690721581
    },
    {
        timestamp: 1672469100000,
        value: 1353.4086284145
    },
    {
        timestamp: 1672470000000,
        value: 1357.4264556328
    },
    {
        timestamp: 1672470900000,
        value: 1358.7640405703
    },
    {
        timestamp: 1672471800000,
        value: 1359.2881488091
    },
    {
        timestamp: 1672472700000,
        value: 1358.1349648052
    },
    {
        timestamp: 1672473600000,
        value: 1374.1971912365
    },
    {
        timestamp: 1672474500000,
        value: 1374.1971912365
    },
    {
        timestamp: 1672475400000,
        value: 1373.7360512887
    },
    {
        timestamp: 1672476300000,
        value: 1372.5331856054
    },
    {
        timestamp: 1672477200000,
        value: 1379.6679548852
    },
    {
        timestamp: 1672478100000,
        value: 1377.40429039
    },
    {
        timestamp: 1672479000000,
        value: 1357.3718244337
    },
    {
        timestamp: 1672479900000,
        value: 1357.3718244337
    },
    {
        timestamp: 1672480800000,
        value: 1351.1694311694
    },
    {
        timestamp: 1672481700000,
        value: 1347.1516039511
    },
    {
        timestamp: 1672482600000,
        value: 1342.9118659222
    },
    {
        timestamp: 1672483500000,
        value: 1319.9764369566
    },
    {
        timestamp: 1672484400000,
        value: 1289.1327727267
    },
    {
        timestamp: 1672485300000,
        value: 1285.1090170504
    },
    {
        timestamp: 1672486200000,
        value: 1287.4918431194
    },
    {
        timestamp: 1672487100000,
        value: 1287.4918431194
    },
    {
        timestamp: 1672488000000,
        value: 1279.5773320625
    },
    {
        timestamp: 1672488900000,
        value: 1290.2076474558
    },
    {
        timestamp: 1672489800000,
        value: 1316.2784924881
    },
    {
        timestamp: 1672490700000,
        value: 1316.0121333432
    },
    {
        timestamp: 1672491600000,
        value: 1314.4962214094
    },
    {
        timestamp: 1672492500000,
        value: 1322.6666838133
    },
    {
        timestamp: 1672493400000,
        value: 1321.5809236183
    },
    {
        timestamp: 1672494300000,
        value: 1328.9988721057
    },
    {
        timestamp: 1672495200000,
        value: 1342.1038257895
    },
    {
        timestamp: 1672496100000,
        value: 1374.4834639105
    },
    {
        timestamp: 1672497000000,
        value: 1416.4052582657
    },
    {
        timestamp: 1672497900000,
        value: 1473.5365942133
    },
    {
        timestamp: 1672498800000,
        value: 1511.7779772614
    },
    {
        timestamp: 1672499700000,
        value: 1521.7095541285
    },
    {
        timestamp: 1672500600000,
        value: 1521.7095541285
    },
    {
        timestamp: 1672501500000,
        value: 1516.8921233484
    },
    {
        timestamp: 1672502400000,
        value: 1513.0947361572
    },
    {
        timestamp: 1672503300000,
        value: 1514.5519758379
    },
    {
        timestamp: 1672504200000,
        value: 1499.7495434196
    },
    {
        timestamp: 1672505100000,
        value: 1494.6084719388
    },
    {
        timestamp: 1672506000000,
        value: 1488.3581503577
    },
    {
        timestamp: 1672506900000,
        value: 1480.3942792642
    },
    {
        timestamp: 1672507800000,
        value: 1459.6159134294
    },
    {
        timestamp: 1672508700000,
        value: 1447.3082920179
    },
    {
        timestamp: 1672509600000,
        value: 1412.1626775827
    },
    {
        timestamp: 1672510500000,
        value: 1389.3307874118
    },
    {
        timestamp: 1672511400000,
        value: 1368.1096577268
    },
    {
        timestamp: 1672512300000,
        value: 1349.7885058831
    },
    {
        timestamp: 1672513200000,
        value: 1308.28497988
    },
    {
        timestamp: 1672514100000,
        value: 1301.5601588285
    },
    {
        timestamp: 1672515000000,
        value: 1269.0948747586
    },
    {
        timestamp: 1672515900000,
        value: 1247.7034417261
    },
    {
        timestamp: 1672516800000,
        value: 1191.8681668267
    },
    {
        timestamp: 1672517700000,
        value: 1160.0653043926
    },
    {
        timestamp: 1672518600000,
        value: 1154.769770167
    },
    {
        timestamp: 1672519500000,
        value: 1142.4149822077
    },
    {
        timestamp: 1672520400000,
        value: 1092.2820612198
    },
    {
        timestamp: 1672521300000,
        value: 1088.7206201365
    },
    {
        timestamp: 1672522200000,
        value: 1062.5961905338
    },
    {
        timestamp: 1672523100000,
        value: 1033.7560495186
    }
].map((i) => ({ ...i, P10: i.value, P25: i.value, P50: i.value, P75: i.value, P90: i.value })) as unknown as Forecast[];

export const mockedDemandHistorical = [
    {
        timestamp: 1671667200000,
        value: 1105.62
    },
    {
        timestamp: 1671668100000,
        value: 1088.726
    },
    {
        timestamp: 1671669000000,
        value: 1073.881
    },
    {
        timestamp: 1671669900000,
        value: 1080.662
    },
    {
        timestamp: 1671670800000,
        value: 1084.403
    },
    {
        timestamp: 1671671700000,
        value: 1070.631
    },
    {
        timestamp: 1671672600000,
        value: 1083.572
    },
    {
        timestamp: 1671673500000,
        value: 1084.053
    },
    {
        timestamp: 1671674400000,
        value: 1096.186
    },
    {
        timestamp: 1671675300000,
        value: 1113.395
    },
    {
        timestamp: 1671676200000,
        value: 1139.893
    },
    {
        timestamp: 1671677100000,
        value: 1152.234
    },
    {
        timestamp: 1671678000000,
        value: 1196.594
    },
    {
        timestamp: 1671678900000,
        value: 1245.535
    },
    {
        timestamp: 1671679800000,
        value: 1282.437
    },
    {
        timestamp: 1671680700000,
        value: 1303.029
    },
    {
        timestamp: 1671681600000,
        value: 1386.559
    },
    {
        timestamp: 1671682500000,
        value: 1446.116
    },
    {
        timestamp: 1671683400000,
        value: 1474.271
    },
    {
        timestamp: 1671684300000,
        value: 1494.59
    },
    {
        timestamp: 1671685200000,
        value: 1525.123
    },
    {
        timestamp: 1671686100000,
        value: 1547.707
    },
    {
        timestamp: 1671687000000,
        value: 1566.167
    },
    {
        timestamp: 1671687900000,
        value: 1595.935
    },
    {
        timestamp: 1671688800000,
        value: 1633.417
    },
    {
        timestamp: 1671689700000,
        value: 1645.353
    },
    {
        timestamp: 1671690600000,
        value: 1661.715
    },
    {
        timestamp: 1671691500000,
        value: 1668.905
    },
    {
        timestamp: 1671692400000,
        value: 1671.389
    },
    {
        timestamp: 1671693300000,
        value: 1655.682
    },
    {
        timestamp: 1671694200000,
        value: 1663.029
    },
    {
        timestamp: 1671695100000,
        value: 1664.391
    },
    {
        timestamp: 1671696000000,
        value: 1645.532
    },
    {
        timestamp: 1671696900000,
        value: 1654.164
    },
    {
        timestamp: 1671697800000,
        value: 1649.63
    },
    {
        timestamp: 1671698700000,
        value: 1632.648
    },
    {
        timestamp: 1671699600000,
        value: 1640.689
    },
    {
        timestamp: 1671700500000,
        value: 1645.245
    },
    {
        timestamp: 1671701400000,
        value: 1630.882
    },
    {
        timestamp: 1671702300000,
        value: 1636.033
    },
    {
        timestamp: 1671703200000,
        value: 1631.751
    },
    {
        timestamp: 1671704100000,
        value: 1619.36
    },
    {
        timestamp: 1671705000000,
        value: 1605.316
    },
    {
        timestamp: 1671705900000,
        value: 1605.312
    },
    {
        timestamp: 1671706800000,
        value: 1587.728
    },
    {
        timestamp: 1671707700000,
        value: 1562.381
    },
    {
        timestamp: 1671708600000,
        value: 1550.028
    },
    {
        timestamp: 1671709500000,
        value: 1537.417
    },
    {
        timestamp: 1671710400000,
        value: 1553.156
    },
    {
        timestamp: 1671711300000,
        value: 1571.449
    },
    {
        timestamp: 1671712200000,
        value: 1569.781
    },
    {
        timestamp: 1671713100000,
        value: 1567.718
    },
    {
        timestamp: 1671714000000,
        value: 1562.328
    },
    {
        timestamp: 1671714900000,
        value: 1566.736
    },
    {
        timestamp: 1671715800000,
        value: 1570.164
    },
    {
        timestamp: 1671716700000,
        value: 1580.323
    },
    {
        timestamp: 1671717600000,
        value: 1595.054
    },
    {
        timestamp: 1671718500000,
        value: 1636.455
    },
    {
        timestamp: 1671719400000,
        value: 1699.102
    },
    {
        timestamp: 1671720300000,
        value: 1729.241
    },
    {
        timestamp: 1671721200000,
        value: 1740.264
    },
    {
        timestamp: 1671722100000,
        value: 1743.221
    },
    {
        timestamp: 1671723000000,
        value: 1747
    },
    {
        timestamp: 1671723900000,
        value: 1751.676
    },
    {
        timestamp: 1671724800000,
        value: 1750.591
    },
    {
        timestamp: 1671725700000,
        value: 1750.635
    },
    {
        timestamp: 1671726600000,
        value: 1746.99
    },
    {
        timestamp: 1671727500000,
        value: 1747.593
    },
    {
        timestamp: 1671728400000,
        value: 1740.638
    },
    {
        timestamp: 1671729300000,
        value: 1734.399
    },
    {
        timestamp: 1671730200000,
        value: 1723.719
    },
    {
        timestamp: 1671731100000,
        value: 1713.054
    },
    {
        timestamp: 1671732000000,
        value: 1681.367
    },
    {
        timestamp: 1671732900000,
        value: 1648.461
    },
    {
        timestamp: 1671733800000,
        value: 1612.31
    },
    {
        timestamp: 1671734700000,
        value: 1581.307
    },
    {
        timestamp: 1671735600000,
        value: 1555.768
    },
    {
        timestamp: 1671736500000,
        value: 1523.21
    },
    {
        timestamp: 1671737400000,
        value: 1484.324
    },
    {
        timestamp: 1671738300000,
        value: 1448.922
    },
    {
        timestamp: 1671739200000,
        value: 1423.608
    },
    {
        timestamp: 1671740100000,
        value: 1410.419
    },
    {
        timestamp: 1671741000000,
        value: 1371.789
    },
    {
        timestamp: 1671741900000,
        value: 1321.913
    },
    {
        timestamp: 1671742800000,
        value: 1277.567
    },
    {
        timestamp: 1671743700000,
        value: 1250.031
    },
    {
        timestamp: 1671744600000,
        value: 1225.262
    },
    {
        timestamp: 1671745500000,
        value: 1186.526
    },
    {
        timestamp: 1671746400000,
        value: 1169.811
    },
    {
        timestamp: 1671747300000,
        value: 1147.089
    },
    {
        timestamp: 1671748200000,
        value: 1128
    },
    {
        timestamp: 1671749100000,
        value: 1098.074
    },
    {
        timestamp: 1671750000000,
        value: 1119.283
    },
    {
        timestamp: 1671750900000,
        value: 1119.101
    },
    {
        timestamp: 1671751800000,
        value: 1095.954
    },
    {
        timestamp: 1671752700000,
        value: 1107.143
    },
    {
        timestamp: 1671753600000,
        value: 1097.382
    },
    {
        timestamp: 1671754500000,
        value: 1080.062
    },
    {
        timestamp: 1671755400000,
        value: 1070.03
    },
    {
        timestamp: 1671756300000,
        value: 1077.827
    },
    {
        timestamp: 1671757200000,
        value: 1066.887
    },
    {
        timestamp: 1671758100000,
        value: 1069.178
    },
    {
        timestamp: 1671759000000,
        value: 1070.984
    },
    {
        timestamp: 1671759900000,
        value: 1070.011
    },
    {
        timestamp: 1671760800000,
        value: 1077.348
    },
    {
        timestamp: 1671761700000,
        value: 1098.568
    },
    {
        timestamp: 1671762600000,
        value: 1104.968
    },
    {
        timestamp: 1671763500000,
        value: 1111.404
    },
    {
        timestamp: 1671764400000,
        value: 1168.142
    },
    {
        timestamp: 1671765300000,
        value: 1208.038
    },
    {
        timestamp: 1671766200000,
        value: 1230.343
    },
    {
        timestamp: 1671767100000,
        value: 1273.97
    },
    {
        timestamp: 1671768000000,
        value: 1344.036
    },
    {
        timestamp: 1671768900000,
        value: 1384.067
    },
    {
        timestamp: 1671769800000,
        value: 1420.997
    },
    {
        timestamp: 1671770700000,
        value: 1451.913
    },
    {
        timestamp: 1671771600000,
        value: 1474.057
    },
    {
        timestamp: 1671772500000,
        value: 1502.15
    },
    {
        timestamp: 1671773400000,
        value: 1538.235
    },
    {
        timestamp: 1671774300000,
        value: 1556.672
    },
    {
        timestamp: 1671775200000,
        value: 1576.001
    },
    {
        timestamp: 1671776100000,
        value: 1598.462
    },
    {
        timestamp: 1671777000000,
        value: 1610.883
    },
    {
        timestamp: 1671777900000,
        value: 1596.79
    },
    {
        timestamp: 1671778800000,
        value: 1596.481
    },
    {
        timestamp: 1671779700000,
        value: 1599.929
    },
    {
        timestamp: 1671780600000,
        value: 1601.862
    },
    {
        timestamp: 1671781500000,
        value: 1592.903
    },
    {
        timestamp: 1671782400000,
        value: 1580.347
    },
    {
        timestamp: 1671783300000,
        value: 1577.1
    },
    {
        timestamp: 1671784200000,
        value: 1576.098
    },
    {
        timestamp: 1671785100000,
        value: 1573.288
    },
    {
        timestamp: 1671786000000,
        value: 1573.299
    },
    {
        timestamp: 1671786900000,
        value: 1573.065
    },
    {
        timestamp: 1671787800000,
        value: 1567.834
    },
    {
        timestamp: 1671788700000,
        value: 1556.991
    },
    {
        timestamp: 1671789600000,
        value: 1549.624
    },
    {
        timestamp: 1671790500000,
        value: 1539.307
    },
    {
        timestamp: 1671791400000,
        value: 1534.941
    },
    {
        timestamp: 1671792300000,
        value: 1532.322
    },
    {
        timestamp: 1671793200000,
        value: 1511.73
    },
    {
        timestamp: 1671794100000,
        value: 1501.953
    },
    {
        timestamp: 1671795000000,
        value: 1489.643
    },
    {
        timestamp: 1671795900000,
        value: 1480.44
    },
    {
        timestamp: 1671796800000,
        value: 1494.127
    },
    {
        timestamp: 1671797700000,
        value: 1513.017
    },
    {
        timestamp: 1671798600000,
        value: 1511.26
    },
    {
        timestamp: 1671799500000,
        value: 1496.436
    },
    {
        timestamp: 1671800400000,
        value: 1489.005
    },
    {
        timestamp: 1671801300000,
        value: 1494.518
    },
    {
        timestamp: 1671802200000,
        value: 1503.251
    },
    {
        timestamp: 1671803100000,
        value: 1518.174
    },
    {
        timestamp: 1671804000000,
        value: 1535.751
    },
    {
        timestamp: 1671804900000,
        value: 1575.297
    },
    {
        timestamp: 1671805800000,
        value: 1631.577
    },
    {
        timestamp: 1671806700000,
        value: 1660.715
    },
    {
        timestamp: 1671807600000,
        value: 1670.839
    },
    {
        timestamp: 1671808500000,
        value: 1674.805
    },
    {
        timestamp: 1671809400000,
        value: 1672.658
    },
    {
        timestamp: 1671810300000,
        value: 1677.685
    },
    {
        timestamp: 1671811200000,
        value: 1682.102
    },
    {
        timestamp: 1671812100000,
        value: 1682.145
    },
    {
        timestamp: 1671813000000,
        value: 1683.718
    },
    {
        timestamp: 1671813900000,
        value: 1678.92
    },
    {
        timestamp: 1671814800000,
        value: 1668.516
    },
    {
        timestamp: 1671815700000,
        value: 1660.598
    },
    {
        timestamp: 1671816600000,
        value: 1648.309
    },
    {
        timestamp: 1671817500000,
        value: 1633.656
    },
    {
        timestamp: 1671818400000,
        value: 1606.371
    },
    {
        timestamp: 1671819300000,
        value: 1578.909
    },
    {
        timestamp: 1671820200000,
        value: 1556.873
    },
    {
        timestamp: 1671821100000,
        value: 1529.437
    },
    {
        timestamp: 1671822000000,
        value: 1500.657
    },
    {
        timestamp: 1671822900000,
        value: 1469.121
    },
    {
        timestamp: 1671823800000,
        value: 1437.339
    },
    {
        timestamp: 1671824700000,
        value: 1397.396
    },
    {
        timestamp: 1671825600000,
        value: 1379.497
    },
    {
        timestamp: 1671826500000,
        value: 1344.689
    },
    {
        timestamp: 1671827400000,
        value: 1310.599
    },
    {
        timestamp: 1671828300000,
        value: 1277.014
    },
    {
        timestamp: 1671829200000,
        value: 1244.66
    },
    {
        timestamp: 1671830100000,
        value: 1208.478
    },
    {
        timestamp: 1671831000000,
        value: 1177.115
    },
    {
        timestamp: 1671831900000,
        value: 1147.547
    },
    {
        timestamp: 1671832800000,
        value: 1125.747
    },
    {
        timestamp: 1671833700000,
        value: 1103.241
    },
    {
        timestamp: 1671834600000,
        value: 1081.334
    },
    {
        timestamp: 1671835500000,
        value: 1066.262
    },
    {
        timestamp: 1671836400000,
        value: 1078.361
    },
    {
        timestamp: 1671837300000,
        value: 1073.665
    },
    {
        timestamp: 1671838200000,
        value: 1060.664
    },
    {
        timestamp: 1671839100000,
        value: 1048.29
    },
    {
        timestamp: 1671840000000,
        value: 1034.083
    },
    {
        timestamp: 1671840900000,
        value: 1026.111
    },
    {
        timestamp: 1671841800000,
        value: 1014.807
    },
    {
        timestamp: 1671842700000,
        value: 1004.598
    },
    {
        timestamp: 1671843600000,
        value: 997.959
    },
    {
        timestamp: 1671844500000,
        value: 992.517
    },
    {
        timestamp: 1671845400000,
        value: 991.325
    },
    {
        timestamp: 1671846300000,
        value: 990.649
    },
    {
        timestamp: 1671847200000,
        value: 997.117
    },
    {
        timestamp: 1671848100000,
        value: 1002.497
    },
    {
        timestamp: 1671849000000,
        value: 1012.283
    },
    {
        timestamp: 1671849900000,
        value: 1022.213
    },
    {
        timestamp: 1671850800000,
        value: 1043.452
    },
    {
        timestamp: 1671851700000,
        value: 1060.184
    },
    {
        timestamp: 1671852600000,
        value: 1077.748
    },
    {
        timestamp: 1671853500000,
        value: 1096.919
    },
    {
        timestamp: 1671854400000,
        value: 1128.067
    },
    {
        timestamp: 1671855300000,
        value: 1143.108
    },
    {
        timestamp: 1671856200000,
        value: 1153.755
    },
    {
        timestamp: 1671857100000,
        value: 1176.452
    },
    {
        timestamp: 1671858000000,
        value: 1206.04
    },
    {
        timestamp: 1671858900000,
        value: 1232.537
    },
    {
        timestamp: 1671859800000,
        value: 1266.051
    },
    {
        timestamp: 1671860700000,
        value: 1292.075
    },
    {
        timestamp: 1671861600000,
        value: 1324.127
    },
    {
        timestamp: 1671862500000,
        value: 1342.175
    },
    {
        timestamp: 1671863400000,
        value: 1355.593
    },
    {
        timestamp: 1671864300000,
        value: 1365.893
    },
    {
        timestamp: 1671865200000,
        value: 1376.372
    },
    {
        timestamp: 1671866100000,
        value: 1379.8
    },
    {
        timestamp: 1671867000000,
        value: 1378.132
    },
    {
        timestamp: 1671867900000,
        value: 1380.327
    },
    {
        timestamp: 1671868800000,
        value: 1383.766
    },
    {
        timestamp: 1671869700000,
        value: 1383.635
    },
    {
        timestamp: 1671870600000,
        value: 1379.553
    },
    {
        timestamp: 1671871500000,
        value: 1381.45
    },
    {
        timestamp: 1671872400000,
        value: 1381.788
    },
    {
        timestamp: 1671873300000,
        value: 1382.837
    },
    {
        timestamp: 1671874200000,
        value: 1379.159
    },
    {
        timestamp: 1671875100000,
        value: 1373.607
    },
    {
        timestamp: 1671876000000,
        value: 1376.088
    },
    {
        timestamp: 1671876900000,
        value: 1374.92
    },
    {
        timestamp: 1671877800000,
        value: 1376.018
    },
    {
        timestamp: 1671878700000,
        value: 1373.823
    },
    {
        timestamp: 1671879600000,
        value: 1366.436
    },
    {
        timestamp: 1671880500000,
        value: 1354.486
    },
    {
        timestamp: 1671881400000,
        value: 1341.566
    },
    {
        timestamp: 1671882300000,
        value: 1331.896
    },
    {
        timestamp: 1671883200000,
        value: 1361.503
    },
    {
        timestamp: 1671884100000,
        value: 1377.606
    },
    {
        timestamp: 1671885000000,
        value: 1380.142
    },
    {
        timestamp: 1671885900000,
        value: 1382.147
    },
    {
        timestamp: 1671886800000,
        value: 1385.485
    },
    {
        timestamp: 1671887700000,
        value: 1395.067
    },
    {
        timestamp: 1671888600000,
        value: 1407.255
    },
    {
        timestamp: 1671889500000,
        value: 1423.383
    },
    {
        timestamp: 1671890400000,
        value: 1440.854
    },
    {
        timestamp: 1671891300000,
        value: 1488.297
    },
    {
        timestamp: 1671892200000,
        value: 1553.89
    },
    {
        timestamp: 1671893100000,
        value: 1592.733
    },
    {
        timestamp: 1671894000000,
        value: 1611.479
    },
    {
        timestamp: 1671894900000,
        value: 1623.637
    },
    {
        timestamp: 1671895800000,
        value: 1630.693
    },
    {
        timestamp: 1671896700000,
        value: 1632.403
    },
    {
        timestamp: 1671897600000,
        value: 1634.874
    },
    {
        timestamp: 1671898500000,
        value: 1632.465
    },
    {
        timestamp: 1671899400000,
        value: 1625.198
    },
    {
        timestamp: 1671900300000,
        value: 1615.526
    },
    {
        timestamp: 1671901200000,
        value: 1596.041
    },
    {
        timestamp: 1671902100000,
        value: 1574.477
    },
    {
        timestamp: 1671903000000,
        value: 1549.079
    },
    {
        timestamp: 1671903900000,
        value: 1524.552
    },
    {
        timestamp: 1671904800000,
        value: 1495.643
    },
    {
        timestamp: 1671905700000,
        value: 1464.459
    },
    {
        timestamp: 1671906600000,
        value: 1432.434
    },
    {
        timestamp: 1671907500000,
        value: 1398.473
    },
    {
        timestamp: 1671908400000,
        value: 1364.986
    },
    {
        timestamp: 1671909300000,
        value: 1332.46
    },
    {
        timestamp: 1671910200000,
        value: 1303.162
    },
    {
        timestamp: 1671911100000,
        value: 1274.509
    },
    {
        timestamp: 1671912000000,
        value: 1258.562
    },
    {
        timestamp: 1671912900000,
        value: 1236.58
    },
    {
        timestamp: 1671913800000,
        value: 1207.12
    },
    {
        timestamp: 1671914700000,
        value: 1179.289
    },
    {
        timestamp: 1671915600000,
        value: 1152.681
    },
    {
        timestamp: 1671916500000,
        value: 1123.588
    },
    {
        timestamp: 1671917400000,
        value: 1097.953
    },
    {
        timestamp: 1671918300000,
        value: 1076.918
    },
    {
        timestamp: 1671919200000,
        value: 1051.742
    },
    {
        timestamp: 1671920100000,
        value: 1030.557
    },
    {
        timestamp: 1671921000000,
        value: 1007.239
    },
    {
        timestamp: 1671921900000,
        value: 991.289
    },
    {
        timestamp: 1671922800000,
        value: 1000.835
    },
    {
        timestamp: 1671923700000,
        value: 996.15
    },
    {
        timestamp: 1671924600000,
        value: 987.652
    },
    {
        timestamp: 1671925500000,
        value: 979.176
    },
    {
        timestamp: 1671926400000,
        value: 969.226
    },
    {
        timestamp: 1671927300000,
        value: 959.347
    },
    {
        timestamp: 1671928200000,
        value: 946.996
    },
    {
        timestamp: 1671929100000,
        value: 938.623
    },
    {
        timestamp: 1671930000000,
        value: 934.81
    },
    {
        timestamp: 1671930900000,
        value: 932.524
    },
    {
        timestamp: 1671931800000,
        value: 930.213
    },
    {
        timestamp: 1671932700000,
        value: 930.748
    },
    {
        timestamp: 1671933600000,
        value: 934.585
    },
    {
        timestamp: 1671934500000,
        value: 939.226
    },
    {
        timestamp: 1671935400000,
        value: 944.679
    },
    {
        timestamp: 1671936300000,
        value: 954.023
    },
    {
        timestamp: 1671937200000,
        value: 968.382
    },
    {
        timestamp: 1671938100000,
        value: 977.814
    },
    {
        timestamp: 1671939000000,
        value: 986.952
    },
    {
        timestamp: 1671939900000,
        value: 999.38
    },
    {
        timestamp: 1671940800000,
        value: 1015.379
    },
    {
        timestamp: 1671941700000,
        value: 1021.324
    },
    {
        timestamp: 1671942600000,
        value: 1020.456
    },
    {
        timestamp: 1671943500000,
        value: 1034.756
    },
    {
        timestamp: 1671944400000,
        value: 1060.034
    },
    {
        timestamp: 1671945300000,
        value: 1092.742
    },
    {
        timestamp: 1671946200000,
        value: 1131.84
    },
    {
        timestamp: 1671947100000,
        value: 1173.564
    },
    {
        timestamp: 1671948000000,
        value: 1215.819
    },
    {
        timestamp: 1671948900000,
        value: 1252.451
    },
    {
        timestamp: 1671949800000,
        value: 1292.235
    },
    {
        timestamp: 1671950700000,
        value: 1327.662
    },
    {
        timestamp: 1671951600000,
        value: 1354.511
    },
    {
        timestamp: 1671952500000,
        value: 1381.401
    },
    {
        timestamp: 1671953400000,
        value: 1398.064
    },
    {
        timestamp: 1671954300000,
        value: 1413.361
    },
    {
        timestamp: 1671955200000,
        value: 1426.239
    },
    {
        timestamp: 1671956100000,
        value: 1432.847
    },
    {
        timestamp: 1671957000000,
        value: 1432.489
    },
    {
        timestamp: 1671957900000,
        value: 1428.097
    },
    {
        timestamp: 1671958800000,
        value: 1414.033
    },
    {
        timestamp: 1671959700000,
        value: 1396.584
    },
    {
        timestamp: 1671960600000,
        value: 1375.814
    },
    {
        timestamp: 1671961500000,
        value: 1354.536
    },
    {
        timestamp: 1671962400000,
        value: 1326.527
    },
    {
        timestamp: 1671963300000,
        value: 1287.854
    },
    {
        timestamp: 1671964200000,
        value: 1241.215
    },
    {
        timestamp: 1671965100000,
        value: 1193.868
    },
    {
        timestamp: 1671966000000,
        value: 1147.647
    },
    {
        timestamp: 1671966900000,
        value: 1107.408
    },
    {
        timestamp: 1671967800000,
        value: 1077.465
    },
    {
        timestamp: 1671968700000,
        value: 1054.165
    },
    {
        timestamp: 1671969600000,
        value: 1066.363
    },
    {
        timestamp: 1671970500000,
        value: 1067.884
    },
    {
        timestamp: 1671971400000,
        value: 1063.705
    },
    {
        timestamp: 1671972300000,
        value: 1058.555
    },
    {
        timestamp: 1671973200000,
        value: 1060.641
    },
    {
        timestamp: 1671974100000,
        value: 1064.752
    },
    {
        timestamp: 1671975000000,
        value: 1070.191
    },
    {
        timestamp: 1671975900000,
        value: 1079.427
    },
    {
        timestamp: 1671976800000,
        value: 1090.606
    },
    {
        timestamp: 1671977700000,
        value: 1125.49
    },
    {
        timestamp: 1671978600000,
        value: 1181.429
    },
    {
        timestamp: 1671979500000,
        value: 1218.554
    },
    {
        timestamp: 1671980400000,
        value: 1236.83
    },
    {
        timestamp: 1671981300000,
        value: 1248.602
    },
    {
        timestamp: 1671982200000,
        value: 1257.979
    },
    {
        timestamp: 1671983100000,
        value: 1264.202
    },
    {
        timestamp: 1671984000000,
        value: 1272.116
    },
    {
        timestamp: 1671984900000,
        value: 1274.904
    },
    {
        timestamp: 1671985800000,
        value: 1276.046
    },
    {
        timestamp: 1671986700000,
        value: 1277.355
    },
    {
        timestamp: 1671987600000,
        value: 1276.987
    },
    {
        timestamp: 1671988500000,
        value: 1274.942
    },
    {
        timestamp: 1671989400000,
        value: 1268.763
    },
    {
        timestamp: 1671990300000,
        value: 1260.689
    },
    {
        timestamp: 1671991200000,
        value: 1253.021
    },
    {
        timestamp: 1671992100000,
        value: 1243.856
    },
    {
        timestamp: 1671993000000,
        value: 1234.2
    },
    {
        timestamp: 1671993900000,
        value: 1219.177
    },
    {
        timestamp: 1671994800000,
        value: 1203.796
    },
    {
        timestamp: 1671995700000,
        value: 1184.302
    },
    {
        timestamp: 1671996600000,
        value: 1165.019
    },
    {
        timestamp: 1671997500000,
        value: 1141.308
    },
    {
        timestamp: 1671998400000,
        value: 1131.45
    },
    {
        timestamp: 1671999300000,
        value: 1119.634
    },
    {
        timestamp: 1672000200000,
        value: 1101.195
    },
    {
        timestamp: 1672001100000,
        value: 1082.046
    },
    {
        timestamp: 1672002000000,
        value: 1060.368
    },
    {
        timestamp: 1672002900000,
        value: 1042.04
    },
    {
        timestamp: 1672003800000,
        value: 1019.816
    },
    {
        timestamp: 1672004700000,
        value: 999.339
    },
    {
        timestamp: 1672005600000,
        value: 982.108
    },
    {
        timestamp: 1672006500000,
        value: 965.62
    },
    {
        timestamp: 1672007400000,
        value: 949.919
    },
    {
        timestamp: 1672008300000,
        value: 938.196
    },
    {
        timestamp: 1672009200000,
        value: 946.267
    },
    {
        timestamp: 1672010100000,
        value: 942.585
    },
    {
        timestamp: 1672011000000,
        value: 934.065
    },
    {
        timestamp: 1672011900000,
        value: 924.189
    },
    {
        timestamp: 1672012800000,
        value: 915.175
    },
    {
        timestamp: 1672013700000,
        value: 909.218
    },
    {
        timestamp: 1672014600000,
        value: 901.647
    },
    {
        timestamp: 1672015500000,
        value: 897.177
    },
    {
        timestamp: 1672016400000,
        value: 892.593
    },
    {
        timestamp: 1672017300000,
        value: 888.876
    },
    {
        timestamp: 1672018200000,
        value: 886.756
    },
    {
        timestamp: 1672019100000,
        value: 885.785
    },
    {
        timestamp: 1672020000000,
        value: 890.836
    },
    {
        timestamp: 1672020900000,
        value: 894.402
    },
    {
        timestamp: 1672021800000,
        value: 898.115
    },
    {
        timestamp: 1672022700000,
        value: 902.823
    },
    {
        timestamp: 1672023600000,
        value: 912.116
    },
    {
        timestamp: 1672024500000,
        value: 922.261
    },
    {
        timestamp: 1672025400000,
        value: 931.2
    },
    {
        timestamp: 1672026300000,
        value: 942.663
    },
    {
        timestamp: 1672027200000,
        value: 958.885
    },
    {
        timestamp: 1672028100000,
        value: 967.512
    },
    {
        timestamp: 1672029000000,
        value: 964.504
    },
    {
        timestamp: 1672029900000,
        value: 978.345
    },
    {
        timestamp: 1672030800000,
        value: 991.715
    },
    {
        timestamp: 1672031700000,
        value: 1014.342
    },
    {
        timestamp: 1672032600000,
        value: 1039.603
    },
    {
        timestamp: 1672033500000,
        value: 1064.532
    },
    {
        timestamp: 1672034400000,
        value: 1090.117
    },
    {
        timestamp: 1672035300000,
        value: 1109.565
    },
    {
        timestamp: 1672036200000,
        value: 1131.791
    },
    {
        timestamp: 1672037100000,
        value: 1149.766
    },
    {
        timestamp: 1672038000000,
        value: 1167.243
    },
    {
        timestamp: 1672038900000,
        value: 1177.93
    },
    {
        timestamp: 1672039800000,
        value: 1188.154
    },
    {
        timestamp: 1672040700000,
        value: 1193.744
    },
    {
        timestamp: 1672041600000,
        value: 1198.045
    },
    {
        timestamp: 1672042500000,
        value: 1203.116
    },
    {
        timestamp: 1672043400000,
        value: 1201.732
    },
    {
        timestamp: 1672044300000,
        value: 1204.426
    },
    {
        timestamp: 1672045200000,
        value: 1212.983
    },
    {
        timestamp: 1672046100000,
        value: 1215.648
    },
    {
        timestamp: 1672047000000,
        value: 1213.616
    },
    {
        timestamp: 1672047900000,
        value: 1215.402
    },
    {
        timestamp: 1672048800000,
        value: 1214.497
    },
    {
        timestamp: 1672049700000,
        value: 1210.127
    },
    {
        timestamp: 1672050600000,
        value: 1200.128
    },
    {
        timestamp: 1672051500000,
        value: 1182.565
    },
    {
        timestamp: 1672052400000,
        value: 1167.269
    },
    {
        timestamp: 1672053300000,
        value: 1148.567
    },
    {
        timestamp: 1672054200000,
        value: 1129.001
    },
    {
        timestamp: 1672055100000,
        value: 1115.367
    },
    {
        timestamp: 1672056000000,
        value: 1135.38
    },
    {
        timestamp: 1672056900000,
        value: 1142.984
    },
    {
        timestamp: 1672057800000,
        value: 1137.588
    },
    {
        timestamp: 1672058700000,
        value: 1134.249
    },
    {
        timestamp: 1672059600000,
        value: 1132.085
    },
    {
        timestamp: 1672060500000,
        value: 1139.858
    },
    {
        timestamp: 1672061400000,
        value: 1144.338
    },
    {
        timestamp: 1672062300000,
        value: 1159.419
    },
    {
        timestamp: 1672063200000,
        value: 1172.923
    },
    {
        timestamp: 1672064100000,
        value: 1209.098
    },
    {
        timestamp: 1672065000000,
        value: 1270.359
    },
    {
        timestamp: 1672065900000,
        value: 1309.605
    },
    {
        timestamp: 1672066800000,
        value: 1329.884
    },
    {
        timestamp: 1672067700000,
        value: 1337.978
    },
    {
        timestamp: 1672068600000,
        value: 1346.747
    },
    {
        timestamp: 1672069500000,
        value: 1353.821
    },
    {
        timestamp: 1672070400000,
        value: 1357.993
    },
    {
        timestamp: 1672071300000,
        value: 1356.879
    },
    {
        timestamp: 1672072200000,
        value: 1355.207
    },
    {
        timestamp: 1672073100000,
        value: 1356.733
    },
    {
        timestamp: 1672074000000,
        value: 1350.871
    },
    {
        timestamp: 1672074900000,
        value: 1342.478
    },
    {
        timestamp: 1672075800000,
        value: 1334.812
    },
    {
        timestamp: 1672076700000,
        value: 1324.589
    },
    {
        timestamp: 1672077600000,
        value: 1314.531
    },
    {
        timestamp: 1672078500000,
        value: 1301.507
    },
    {
        timestamp: 1672079400000,
        value: 1285.636
    },
    {
        timestamp: 1672080300000,
        value: 1270.544
    },
    {
        timestamp: 1672081200000,
        value: 1251.612
    },
    {
        timestamp: 1672082100000,
        value: 1231.867
    },
    {
        timestamp: 1672083000000,
        value: 1210.16
    },
    {
        timestamp: 1672083900000,
        value: 1184.006
    },
    {
        timestamp: 1672084800000,
        value: 1181.403
    },
    {
        timestamp: 1672085700000,
        value: 1169.981
    },
    {
        timestamp: 1672086600000,
        value: 1151.692
    },
    {
        timestamp: 1672087500000,
        value: 1128.346
    },
    {
        timestamp: 1672088400000,
        value: 1090.441
    },
    {
        timestamp: 1672089300000,
        value: 1071.53
    },
    {
        timestamp: 1672090200000,
        value: 1050.43
    },
    {
        timestamp: 1672091100000,
        value: 1016.82
    },
    {
        timestamp: 1672092000000,
        value: 998.865
    },
    {
        timestamp: 1672092900000,
        value: 990.832
    },
    {
        timestamp: 1672093800000,
        value: 976.644
    },
    {
        timestamp: 1672094700000,
        value: 948.69
    },
    {
        timestamp: 1672095600000,
        value: 967.309
    },
    {
        timestamp: 1672096500000,
        value: 965.849
    },
    {
        timestamp: 1672097400000,
        value: 946.855
    },
    {
        timestamp: 1672098300000,
        value: 949.405
    },
    {
        timestamp: 1672099200000,
        value: 943.766
    },
    {
        timestamp: 1672100100000,
        value: 930.976
    },
    {
        timestamp: 1672101000000,
        value: 922.827
    },
    {
        timestamp: 1672101900000,
        value: 922.963
    },
    {
        timestamp: 1672102800000,
        value: 920.596
    },
    {
        timestamp: 1672103700000,
        value: 909.07
    },
    {
        timestamp: 1672104600000,
        value: 920.146
    },
    {
        timestamp: 1672105500000,
        value: 923.432
    },
    {
        timestamp: 1672106400000,
        value: 920.901
    },
    {
        timestamp: 1672107300000,
        value: 933.854
    },
    {
        timestamp: 1672108200000,
        value: 950.372
    },
    {
        timestamp: 1672109100000,
        value: 959.691
    },
    {
        timestamp: 1672110000000,
        value: 995.896
    },
    {
        timestamp: 1672110900000,
        value: 1032.724
    },
    {
        timestamp: 1672111800000,
        value: 1060.584
    },
    {
        timestamp: 1672112700000,
        value: 1075.85
    },
    {
        timestamp: 1672113600000,
        value: 1125.478
    },
    {
        timestamp: 1672114500000,
        value: 1158.655
    },
    {
        timestamp: 1672115400000,
        value: 1168.709
    },
    {
        timestamp: 1672116300000,
        value: 1197.177
    },
    {
        timestamp: 1672117200000,
        value: 1242.953
    },
    {
        timestamp: 1672118100000,
        value: 1278.047
    },
    {
        timestamp: 1672119000000,
        value: 1297.172
    },
    {
        timestamp: 1672119900000,
        value: 1340.633
    },
    {
        timestamp: 1672120800000,
        value: 1383.544
    },
    {
        timestamp: 1672121700000,
        value: 1396.982
    },
    {
        timestamp: 1672122600000,
        value: 1420.65
    },
    {
        timestamp: 1672123500000,
        value: 1436.508
    },
    {
        timestamp: 1672124400000,
        value: 1440.025
    },
    {
        timestamp: 1672125300000,
        value: 1445.638
    },
    {
        timestamp: 1672126200000,
        value: 1455.477
    },
    {
        timestamp: 1672127100000,
        value: 1452.598
    },
    {
        timestamp: 1672128000000,
        value: 1433.862
    },
    {
        timestamp: 1672128900000,
        value: 1449.132
    },
    {
        timestamp: 1672129800000,
        value: 1451.945
    },
    {
        timestamp: 1672130700000,
        value: 1435.696
    },
    {
        timestamp: 1672131600000,
        value: 1435.527
    },
    {
        timestamp: 1672132500000,
        value: 1451.564
    },
    {
        timestamp: 1672133400000,
        value: 1449.952
    },
    {
        timestamp: 1672134300000,
        value: 1442.015
    },
    {
        timestamp: 1672135200000,
        value: 1453.499
    },
    {
        timestamp: 1672136100000,
        value: 1453.585
    },
    {
        timestamp: 1672137000000,
        value: 1433.426
    },
    {
        timestamp: 1672137900000,
        value: 1426.512
    },
    {
        timestamp: 1672138800000,
        value: 1415.639
    },
    {
        timestamp: 1672139700000,
        value: 1384.432
    },
    {
        timestamp: 1672140600000,
        value: 1363.951
    },
    {
        timestamp: 1672141500000,
        value: 1347.647
    },
    {
        timestamp: 1672142400000,
        value: 1356.192
    },
    {
        timestamp: 1672143300000,
        value: 1361.762
    },
    {
        timestamp: 1672144200000,
        value: 1356.424
    },
    {
        timestamp: 1672145100000,
        value: 1346.98
    },
    {
        timestamp: 1672146000000,
        value: 1342.971
    },
    {
        timestamp: 1672146900000,
        value: 1344.57
    },
    {
        timestamp: 1672147800000,
        value: 1348.33
    },
    {
        timestamp: 1672148700000,
        value: 1360.263
    },
    {
        timestamp: 1672149600000,
        value: 1376.629
    },
    {
        timestamp: 1672150500000,
        value: 1414.329
    },
    {
        timestamp: 1672151400000,
        value: 1476.003
    },
    {
        timestamp: 1672152300000,
        value: 1520.412
    },
    {
        timestamp: 1672153200000,
        value: 1525.873
    },
    {
        timestamp: 1672154100000,
        value: 1531.103
    },
    {
        timestamp: 1672155000000,
        value: 1536.81
    },
    {
        timestamp: 1672155900000,
        value: 1544.903
    },
    {
        timestamp: 1672156800000,
        value: 1544.23
    },
    {
        timestamp: 1672157700000,
        value: 1544.004
    },
    {
        timestamp: 1672158600000,
        value: 1539.569
    },
    {
        timestamp: 1672159500000,
        value: 1540.04
    },
    {
        timestamp: 1672160400000,
        value: 1531.962
    },
    {
        timestamp: 1672161300000,
        value: 1526.101
    },
    {
        timestamp: 1672162200000,
        value: 1516.718
    },
    {
        timestamp: 1672163100000,
        value: 1501.423
    },
    {
        timestamp: 1672164000000,
        value: 1468.634
    },
    {
        timestamp: 1672164900000,
        value: 1442.87
    },
    {
        timestamp: 1672165800000,
        value: 1415.769
    },
    {
        timestamp: 1672166700000,
        value: 1388.654
    },
    {
        timestamp: 1672167600000,
        value: 1357.097
    },
    {
        timestamp: 1672168500000,
        value: 1326.656
    },
    {
        timestamp: 1672169400000,
        value: 1296.056
    },
    {
        timestamp: 1672170300000,
        value: 1265.501
    },
    {
        timestamp: 1672171200000,
        value: 1261.071
    },
    {
        timestamp: 1672172100000,
        value: 1245.921
    },
    {
        timestamp: 1672173000000,
        value: 1217.921
    },
    {
        timestamp: 1672173900000,
        value: 1188.811
    },
    {
        timestamp: 1672174800000,
        value: 1141.979
    },
    {
        timestamp: 1672175700000,
        value: 1121.724
    },
    {
        timestamp: 1672176600000,
        value: 1102.54
    },
    {
        timestamp: 1672177500000,
        value: 1069.167
    },
    {
        timestamp: 1672178400000,
        value: 1046.063
    },
    {
        timestamp: 1672179300000,
        value: 1032.843
    },
    {
        timestamp: 1672180200000,
        value: 1013.515
    },
    {
        timestamp: 1672181100000,
        value: 989.191
    },
    {
        timestamp: 1672182000000,
        value: 1014.455
    },
    {
        timestamp: 1672182900000,
        value: 1011.318
    },
    {
        timestamp: 1672183800000,
        value: 987.81
    },
    {
        timestamp: 1672184700000,
        value: 988.122
    },
    {
        timestamp: 1672185600000,
        value: 991.184
    },
    {
        timestamp: 1672186500000,
        value: 974.948
    },
    {
        timestamp: 1672187400000,
        value: 974.194
    },
    {
        timestamp: 1672188300000,
        value: 970.726
    },
    {
        timestamp: 1672189200000,
        value: 961.697
    },
    {
        timestamp: 1672190100000,
        value: 952.994
    },
    {
        timestamp: 1672191000000,
        value: 961.46
    },
    {
        timestamp: 1672191900000,
        value: 965.664
    },
    {
        timestamp: 1672192800000,
        value: 963.474
    },
    {
        timestamp: 1672193700000,
        value: 984.892
    },
    {
        timestamp: 1672194600000,
        value: 993.868
    },
    {
        timestamp: 1672195500000,
        value: 1001.983
    },
    {
        timestamp: 1672196400000,
        value: 1044.022
    },
    {
        timestamp: 1672197300000,
        value: 1071.216
    },
    {
        timestamp: 1672198200000,
        value: 1087.87
    },
    {
        timestamp: 1672199100000,
        value: 1105.843
    },
    {
        timestamp: 1672200000000,
        value: 1152.767
    },
    {
        timestamp: 1672200900000,
        value: 1183.948
    },
    {
        timestamp: 1672201800000,
        value: 1194.157
    },
    {
        timestamp: 1672202700000,
        value: 1235.89
    },
    {
        timestamp: 1672203600000,
        value: 1273.592
    },
    {
        timestamp: 1672204500000,
        value: 1301.72
    },
    {
        timestamp: 1672205400000,
        value: 1342.687
    },
    {
        timestamp: 1672206300000,
        value: 1379.763
    },
    {
        timestamp: 1672207200000,
        value: 1410.17
    },
    {
        timestamp: 1672208100000,
        value: 1435.268
    },
    {
        timestamp: 1672209000000,
        value: 1454.689
    },
    {
        timestamp: 1672209900000,
        value: 1452.129
    },
    {
        timestamp: 1672210800000,
        value: 1477.52
    },
    {
        timestamp: 1672211700000,
        value: 1473.477
    },
    {
        timestamp: 1672212600000,
        value: 1482.377
    },
    {
        timestamp: 1672213500000,
        value: 1482.979
    },
    {
        timestamp: 1672214400000,
        value: 1480.298
    },
    {
        timestamp: 1672215300000,
        value: 1493.474
    },
    {
        timestamp: 1672216200000,
        value: 1496.003
    },
    {
        timestamp: 1672217100000,
        value: 1486.241
    },
    {
        timestamp: 1672218000000,
        value: 1493.957
    },
    {
        timestamp: 1672218900000,
        value: 1501.432
    },
    {
        timestamp: 1672219800000,
        value: 1498.144
    },
    {
        timestamp: 1672220700000,
        value: 1489.011
    },
    {
        timestamp: 1672221600000,
        value: 1497.917
    },
    {
        timestamp: 1672222500000,
        value: 1498.938
    },
    {
        timestamp: 1672223400000,
        value: 1474.669
    },
    {
        timestamp: 1672224300000,
        value: 1474.283
    },
    {
        timestamp: 1672225200000,
        value: 1468.568
    },
    {
        timestamp: 1672226100000,
        value: 1437.771
    },
    {
        timestamp: 1672227000000,
        value: 1409.979
    },
    {
        timestamp: 1672227900000,
        value: 1398.651
    },
    {
        timestamp: 1672228800000,
        value: 1423.225
    },
    {
        timestamp: 1672229700000,
        value: 1434.891
    },
    {
        timestamp: 1672230600000,
        value: 1429.833
    },
    {
        timestamp: 1672231500000,
        value: 1423.865
    },
    {
        timestamp: 1672232400000,
        value: 1418.384
    },
    {
        timestamp: 1672233300000,
        value: 1417.585
    },
    {
        timestamp: 1672234200000,
        value: 1422.008
    },
    {
        timestamp: 1672235100000,
        value: 1425.254
    },
    {
        timestamp: 1672236000000,
        value: 1439.747
    },
    {
        timestamp: 1672236900000,
        value: 1471.175
    },
    {
        timestamp: 1672237800000,
        value: 1523.551
    },
    {
        timestamp: 1672238700000,
        value: 1555.698
    },
    {
        timestamp: 1672239600000,
        value: 1567.888
    },
    {
        timestamp: 1672240500000,
        value: 1573.975
    },
    {
        timestamp: 1672241400000,
        value: 1579.827
    },
    {
        timestamp: 1672242300000,
        value: 1581.056
    },
    {
        timestamp: 1672243200000,
        value: 1577.008
    },
    {
        timestamp: 1672244100000,
        value: 1572.196
    },
    {
        timestamp: 1672245000000,
        value: 1569.525
    },
    {
        timestamp: 1672245900000,
        value: 1564.274
    },
    {
        timestamp: 1672246800000,
        value: 1557.306
    },
    {
        timestamp: 1672247700000,
        value: 1546.549
    },
    {
        timestamp: 1672248600000,
        value: 1536.104
    },
    {
        timestamp: 1672249500000,
        value: 1521.395
    },
    {
        timestamp: 1672250400000,
        value: 1491.368
    },
    {
        timestamp: 1672251300000,
        value: 1461.927
    },
    {
        timestamp: 1672252200000,
        value: 1428.709
    },
    {
        timestamp: 1672253100000,
        value: 1403.67
    },
    {
        timestamp: 1672254000000,
        value: 1369.67
    },
    {
        timestamp: 1672254900000,
        value: 1342.677
    },
    {
        timestamp: 1672255800000,
        value: 1311.298
    },
    {
        timestamp: 1672256700000,
        value: 1274.87
    },
    {
        timestamp: 1672257600000,
        value: 1267.563
    },
    {
        timestamp: 1672258500000,
        value: 1250.945
    },
    {
        timestamp: 1672259400000,
        value: 1225.205
    },
    {
        timestamp: 1672260300000,
        value: 1192.811
    },
    {
        timestamp: 1672261200000,
        value: 1150.154
    },
    {
        timestamp: 1672262100000,
        value: 1128.975
    },
    {
        timestamp: 1672263000000,
        value: 1097.41
    },
    {
        timestamp: 1672263900000,
        value: 1061.113
    },
    {
        timestamp: 1672264800000,
        value: 1043.532
    },
    {
        timestamp: 1672265700000,
        value: 1030.208
    },
    {
        timestamp: 1672266600000,
        value: 1013.841
    },
    {
        timestamp: 1672267500000,
        value: 989.906
    },
    {
        timestamp: 1672268400000,
        value: 1014.065
    },
    {
        timestamp: 1672269300000,
        value: 1012.855
    },
    {
        timestamp: 1672270200000,
        value: 992.456
    },
    {
        timestamp: 1672271100000,
        value: 989.486
    },
    {
        timestamp: 1672272000000,
        value: 990.461
    },
    {
        timestamp: 1672272900000,
        value: 980.069
    },
    {
        timestamp: 1672273800000,
        value: 976.921
    },
    {
        timestamp: 1672274700000,
        value: 974.532
    },
    {
        timestamp: 1672275600000,
        value: 970.455
    },
    {
        timestamp: 1672276500000,
        value: 958.867
    },
    {
        timestamp: 1672277400000,
        value: 968.278
    },
    {
        timestamp: 1672278300000,
        value: 966.621
    },
    {
        timestamp: 1672279200000,
        value: 966.412
    },
    {
        timestamp: 1672280100000,
        value: 984.382
    },
    {
        timestamp: 1672281000000,
        value: 998.784
    },
    {
        timestamp: 1672281900000,
        value: 1004.196
    },
    {
        timestamp: 1672282800000,
        value: 1035.266
    },
    {
        timestamp: 1672283700000,
        value: 1062.46
    },
    {
        timestamp: 1672284600000,
        value: 1084.549
    },
    {
        timestamp: 1672285500000,
        value: 1100.502
    },
    {
        timestamp: 1672286400000,
        value: 1155.59
    },
    {
        timestamp: 1672287300000,
        value: 1187.483
    },
    {
        timestamp: 1672288200000,
        value: 1197.607
    },
    {
        timestamp: 1672289100000,
        value: 1232.481
    },
    {
        timestamp: 1672290000000,
        value: 1273.877
    },
    {
        timestamp: 1672290900000,
        value: 1300.407
    },
    {
        timestamp: 1672291800000,
        value: 1331.576
    },
    {
        timestamp: 1672292700000,
        value: 1374.259
    },
    {
        timestamp: 1672293600000,
        value: 1412.23
    },
    {
        timestamp: 1672294500000,
        value: 1423.885
    },
    {
        timestamp: 1672295400000,
        value: 1453.334
    },
    {
        timestamp: 1672296300000,
        value: 1465.59
    },
    {
        timestamp: 1672297200000,
        value: 1471.69
    },
    {
        timestamp: 1672298100000,
        value: 1463.352
    },
    {
        timestamp: 1672299000000,
        value: 1467.462
    },
    {
        timestamp: 1672299900000,
        value: 1474.051
    },
    {
        timestamp: 1672300800000,
        value: 1462.073
    },
    {
        timestamp: 1672301700000,
        value: 1462.826
    },
    {
        timestamp: 1672302600000,
        value: 1469.22
    },
    {
        timestamp: 1672303500000,
        value: 1465.218
    },
    {
        timestamp: 1672304400000,
        value: 1452.524
    },
    {
        timestamp: 1672305300000,
        value: 1458.004
    },
    {
        timestamp: 1672306200000,
        value: 1463.926
    },
    {
        timestamp: 1672307100000,
        value: 1461.946
    },
    {
        timestamp: 1672308000000,
        value: 1454.089
    },
    {
        timestamp: 1672308900000,
        value: 1455.137
    },
    {
        timestamp: 1672309800000,
        value: 1450.133
    },
    {
        timestamp: 1672310700000,
        value: 1433.926
    },
    {
        timestamp: 1672311600000,
        value: 1410.896
    },
    {
        timestamp: 1672312500000,
        value: 1385.336
    },
    {
        timestamp: 1672313400000,
        value: 1363.857
    },
    {
        timestamp: 1672314300000,
        value: 1346.918
    },
    {
        timestamp: 1672315200000,
        value: 1367.097
    },
    {
        timestamp: 1672316100000,
        value: 1381.71
    },
    {
        timestamp: 1672317000000,
        value: 1384.846
    },
    {
        timestamp: 1672317900000,
        value: 1378.206
    },
    {
        timestamp: 1672318800000,
        value: 1366.261
    },
    {
        timestamp: 1672319700000,
        value: 1366.992
    },
    {
        timestamp: 1672320600000,
        value: 1375.371
    },
    {
        timestamp: 1672321500000,
        value: 1388.727
    },
    {
        timestamp: 1672322400000,
        value: 1402.201
    },
    {
        timestamp: 1672323300000,
        value: 1440.416
    },
    {
        timestamp: 1672324200000,
        value: 1494.891
    },
    {
        timestamp: 1672325100000,
        value: 1531.207
    },
    {
        timestamp: 1672326000000,
        value: 1545.187
    },
    {
        timestamp: 1672326900000,
        value: 1553.891
    },
    {
        timestamp: 1672327800000,
        value: 1554.505
    },
    {
        timestamp: 1672328700000,
        value: 1560.209
    },
    {
        timestamp: 1672329600000,
        value: 1550.872
    },
    {
        timestamp: 1672330500000,
        value: 1549.736
    },
    {
        timestamp: 1672331400000,
        value: 1548.684
    },
    {
        timestamp: 1672332300000,
        value: 1546.778
    },
    {
        timestamp: 1672333200000,
        value: 1542.343
    },
    {
        timestamp: 1672334100000,
        value: 1532.309
    },
    {
        timestamp: 1672335000000,
        value: 1518.247
    },
    {
        timestamp: 1672335900000,
        value: 1501.27
    },
    {
        timestamp: 1672336800000,
        value: 1470.836
    },
    {
        timestamp: 1672337700000,
        value: 1446.579
    },
    {
        timestamp: 1672338600000,
        value: 1421.388
    },
    {
        timestamp: 1672339500000,
        value: 1394.198
    },
    {
        timestamp: 1672340400000,
        value: 1363.885
    },
    {
        timestamp: 1672341300000,
        value: 1340.373
    },
    {
        timestamp: 1672342200000,
        value: 1305.992
    },
    {
        timestamp: 1672343100000,
        value: 1276.09
    },
    {
        timestamp: 1672344000000,
        value: 1269.007
    },
    {
        timestamp: 1672344900000,
        value: 1251.881
    },
    {
        timestamp: 1672345800000,
        value: 1226.075
    },
    {
        timestamp: 1672346700000,
        value: 1190.636
    },
    {
        timestamp: 1672347600000,
        value: 1155.789
    },
    {
        timestamp: 1672348500000,
        value: 1137.339
    },
    {
        timestamp: 1672349400000,
        value: 1106.06
    },
    {
        timestamp: 1672350300000,
        value: 1067.139
    },
    {
        timestamp: 1672351200000,
        value: 1050.261
    },
    {
        timestamp: 1672352100000,
        value: 1036.372
    },
    {
        timestamp: 1672353000000,
        value: 1013.78
    },
    {
        timestamp: 1672353900000,
        value: 987.342
    },
    {
        timestamp: 1672354800000,
        value: 1004.933
    },
    {
        timestamp: 1672355700000,
        value: 1000.488
    },
    {
        timestamp: 1672356600000,
        value: 979.627
    },
    {
        timestamp: 1672357500000,
        value: 978.329
    },
    {
        timestamp: 1672358400000,
        value: 976.556
    },
    {
        timestamp: 1672359300000,
        value: 965.218
    },
    {
        timestamp: 1672360200000,
        value: 952.394
    },
    {
        timestamp: 1672361100000,
        value: 955.579
    },
    {
        timestamp: 1672362000000,
        value: 953.484
    },
    {
        timestamp: 1672362900000,
        value: 937.475
    },
    {
        timestamp: 1672363800000,
        value: 947.588
    },
    {
        timestamp: 1672364700000,
        value: 951.289
    },
    {
        timestamp: 1672365600000,
        value: 952.968
    },
    {
        timestamp: 1672366500000,
        value: 957.85
    },
    {
        timestamp: 1672367400000,
        value: 979.146
    },
    {
        timestamp: 1672368300000,
        value: 997.147
    },
    {
        timestamp: 1672369200000,
        value: 1009.813
    },
    {
        timestamp: 1672370100000,
        value: 1045.464
    },
    {
        timestamp: 1672371000000,
        value: 1072.845
    },
    {
        timestamp: 1672371900000,
        value: 1087.328
    },
    {
        timestamp: 1672372800000,
        value: 1133.775
    },
    {
        timestamp: 1672373700000,
        value: 1167.87
    },
    {
        timestamp: 1672374600000,
        value: 1170.474
    },
    {
        timestamp: 1672375500000,
        value: 1203.496
    },
    {
        timestamp: 1672376400000,
        value: 1242.642
    },
    {
        timestamp: 1672377300000,
        value: 1263.803
    },
    {
        timestamp: 1672378200000,
        value: 1299.598
    },
    {
        timestamp: 1672379100000,
        value: 1334.264
    },
    {
        timestamp: 1672380000000,
        value: 1353.451
    },
    {
        timestamp: 1672380900000,
        value: 1372.54
    },
    {
        timestamp: 1672381800000,
        value: 1388.844
    },
    {
        timestamp: 1672382700000,
        value: 1402.626
    },
    {
        timestamp: 1672383600000,
        value: 1410.186
    },
    {
        timestamp: 1672384500000,
        value: 1415.209
    },
    {
        timestamp: 1672385400000,
        value: 1413.439
    },
    {
        timestamp: 1672386300000,
        value: 1409.138
    },
    {
        timestamp: 1672387200000,
        value: 1404.665
    },
    {
        timestamp: 1672388100000,
        value: 1403.067
    },
    {
        timestamp: 1672389000000,
        value: 1397.543
    },
    {
        timestamp: 1672389900000,
        value: 1392.507
    },
    {
        timestamp: 1672390800000,
        value: 1391.057
    },
    {
        timestamp: 1672391700000,
        value: 1390.747
    },
    {
        timestamp: 1672392600000,
        value: 1388.769
    },
    {
        timestamp: 1672393500000,
        value: 1388.375
    },
    {
        timestamp: 1672394400000,
        value: 1384.336
    },
    {
        timestamp: 1672395300000,
        value: 1379.416
    },
    {
        timestamp: 1672396200000,
        value: 1369.601
    },
    {
        timestamp: 1672397100000,
        value: 1356.754
    },
    {
        timestamp: 1672398000000,
        value: 1343.242
    },
    {
        timestamp: 1672398900000,
        value: 1332.456
    },
    {
        timestamp: 1672399800000,
        value: 1321.594
    },
    {
        timestamp: 1672400700000,
        value: 1312.017
    },
    {
        timestamp: 1672401600000,
        value: 1335.717
    },
    {
        timestamp: 1672402500000,
        value: 1347.873
    },
    {
        timestamp: 1672403400000,
        value: 1345.681
    },
    {
        timestamp: 1672404300000,
        value: 1342.558
    },
    {
        timestamp: 1672405200000,
        value: 1337.236
    },
    {
        timestamp: 1672406100000,
        value: 1343.674
    },
    {
        timestamp: 1672407000000,
        value: 1354.752
    },
    {
        timestamp: 1672407900000,
        value: 1363.691
    },
    {
        timestamp: 1672408800000,
        value: 1380.052
    },
    {
        timestamp: 1672409700000,
        value: 1419.442
    },
    {
        timestamp: 1672410600000,
        value: 1476.204
    },
    {
        timestamp: 1672411500000,
        value: 1520.503
    },
    {
        timestamp: 1672412400000,
        value: 1541.071
    },
    {
        timestamp: 1672413300000,
        value: 1549.767
    },
    {
        timestamp: 1672414200000,
        value: 1554.041
    },
    {
        timestamp: 1672415100000,
        value: 1555.91
    },
    {
        timestamp: 1672416000000,
        value: 1556.924
    },
    {
        timestamp: 1672416900000,
        value: 1555.647
    },
    {
        timestamp: 1672417800000,
        value: 1554.46
    },
    {
        timestamp: 1672418700000,
        value: 1550.253
    },
    {
        timestamp: 1672419600000,
        value: 1542.924
    },
    {
        timestamp: 1672420500000,
        value: 1535.505
    },
    {
        timestamp: 1672421400000,
        value: 1525.312
    },
    {
        timestamp: 1672422300000,
        value: 1508.485
    },
    {
        timestamp: 1672423200000,
        value: 1477.833
    },
    {
        timestamp: 1672424100000,
        value: 1451.679
    },
    {
        timestamp: 1672425000000,
        value: 1425.385
    },
    {
        timestamp: 1672425900000,
        value: 1400.341
    },
    {
        timestamp: 1672426800000,
        value: 1372.891
    },
    {
        timestamp: 1672427700000,
        value: 1344.681
    },
    {
        timestamp: 1672428600000,
        value: 1318.012
    },
    {
        timestamp: 1672429500000,
        value: 1285.825
    },
    {
        timestamp: 1672430400000,
        value: 1267.513
    },
    {
        timestamp: 1672431300000,
        value: 1249.443
    },
    {
        timestamp: 1672432200000,
        value: 1223.261
    },
    {
        timestamp: 1672433100000,
        value: 1191.386
    },
    {
        timestamp: 1672434000000,
        value: 1157.465
    },
    {
        timestamp: 1672434900000,
        value: 1128.19
    },
    {
        timestamp: 1672435800000,
        value: 1100.982
    },
    {
        timestamp: 1672436700000,
        value: 1072.879
    },
    {
        timestamp: 1672437600000,
        value: 1049.838
    },
    {
        timestamp: 1672438500000,
        value: 1029.37
    },
    {
        timestamp: 1672439400000,
        value: 1009.347
    },
    {
        timestamp: 1672440300000,
        value: 993.79
    },
    {
        timestamp: 1672441200000,
        value: 999.521
    },
    {
        timestamp: 1672442100000,
        value: 993.554
    },
    {
        timestamp: 1672443000000,
        value: 981.29
    },
    {
        timestamp: 1672443900000,
        value: 969.429
    },
    {
        timestamp: 1672444800000,
        value: 960.463
    },
    {
        timestamp: 1672445700000,
        value: 952.882
    },
    {
        timestamp: 1672446600000,
        value: 941.475
    },
    {
        timestamp: 1672447500000,
        value: 941.465
    },
    {
        timestamp: 1672448400000,
        value: 934.57
    },
    {
        timestamp: 1672449300000,
        value: 928.586
    },
    {
        timestamp: 1672450200000,
        value: 929.086
    },
    {
        timestamp: 1672451100000,
        value: 929.928
    },
    {
        timestamp: 1672452000000,
        value: 935.572
    },
    {
        timestamp: 1672452900000,
        value: 940.748
    },
    {
        timestamp: 1672453800000,
        value: 945.175
    },
    {
        timestamp: 1672454700000,
        value: 954.803
    },
    {
        timestamp: 1672455600000,
        value: 974.153
    },
    {
        timestamp: 1672456500000,
        value: 985.285
    },
    {
        timestamp: 1672457400000,
        value: 1000.719
    },
    {
        timestamp: 1672458300000,
        value: 1015.543
    },
    {
        timestamp: 1672459200000,
        value: 1045.208
    },
    {
        timestamp: 1672460100000,
        value: 1071.619
    },
    {
        timestamp: 1672461000000,
        value: 1077.426
    },
    {
        timestamp: 1672461900000,
        value: 1096.281
    },
    {
        timestamp: 1672462800000,
        value: 1123.562
    },
    {
        timestamp: 1672463700000,
        value: 1156.038
    },
    {
        timestamp: 1672464600000,
        value: 1188.804
    },
    {
        timestamp: 1672465500000,
        value: 1216.885
    },
    {
        timestamp: 1672466400000,
        value: 1263.408
    },
    {
        timestamp: 1672467300000,
        value: 1292.673
    },
    {
        timestamp: 1672468200000,
        value: 1308.201
    },
    {
        timestamp: 1672469100000,
        value: 1320.434
    },
    {
        timestamp: 1672470000000,
        value: 1336.214
    },
    {
        timestamp: 1672470900000,
        value: 1343.22
    },
    {
        timestamp: 1672471800000,
        value: 1348.554
    },
    {
        timestamp: 1672472700000,
        value: 1352.026
    },
    {
        timestamp: 1672473600000,
        value: 1354.155
    },
    {
        timestamp: 1672474500000,
        value: 1353.709
    },
    {
        timestamp: 1672475400000,
        value: 1354.241
    },
    {
        timestamp: 1672476300000,
        value: 1352.262
    },
    {
        timestamp: 1672477200000,
        value: 1351.466
    },
    {
        timestamp: 1672478100000,
        value: 1352.899
    },
    {
        timestamp: 1672479000000,
        value: 1356.752
    },
    {
        timestamp: 1672479900000,
        value: 1361.928
    },
    {
        timestamp: 1672480800000,
        value: 1358.112
    },
    {
        timestamp: 1672481700000,
        value: 1353.407
    },
    {
        timestamp: 1672482600000,
        value: 1348.067
    },
    {
        timestamp: 1672483500000,
        value: 1337.534
    },
    {
        timestamp: 1672484400000,
        value: 1332.854
    },
    {
        timestamp: 1672485300000,
        value: 1323.069
    },
    {
        timestamp: 1672486200000,
        value: 1314.944
    },
    {
        timestamp: 1672487100000,
        value: 1308.21
    },
    {
        timestamp: 1672488000000,
        value: 1337.873
    },
    {
        timestamp: 1672488900000,
        value: 1354.496
    },
    {
        timestamp: 1672489800000,
        value: 1362.74
    },
    {
        timestamp: 1672490700000,
        value: 1367.336
    },
    {
        timestamp: 1672491600000,
        value: 1367.407
    },
    {
        timestamp: 1672492500000,
        value: 1374.917
    },
    {
        timestamp: 1672493400000,
        value: 1388.897
    },
    {
        timestamp: 1672494300000,
        value: 1411.032
    },
    {
        timestamp: 1672495200000,
        value: 1434.389
    },
    {
        timestamp: 1672496100000,
        value: 1484.012
    },
    {
        timestamp: 1672497000000,
        value: 1552.683
    },
    {
        timestamp: 1672497900000,
        value: 1621.234
    },
    {
        timestamp: 1672498800000,
        value: 1651.245
    },
    {
        timestamp: 1672499700000,
        value: 1675.908
    },
    {
        timestamp: 1672500600000,
        value: 1694.596
    },
    {
        timestamp: 1672501500000,
        value: 1702.833
    },
    {
        timestamp: 1672502400000,
        value: 1708.091
    },
    {
        timestamp: 1672503300000,
        value: 1705.311
    },
    {
        timestamp: 1672504200000,
        value: 1696.073
    },
    {
        timestamp: 1672505100000,
        value: 1680.686
    },
    {
        timestamp: 1672506000000,
        value: 1659.503
    },
    {
        timestamp: 1672506900000,
        value: 1630.945
    },
    {
        timestamp: 1672507800000,
        value: 1595.288
    },
    {
        timestamp: 1672508700000,
        value: 1559.947
    },
    {
        timestamp: 1672509600000,
        value: 1511.411
    },
    {
        timestamp: 1672510500000,
        value: 1466.702
    },
    {
        timestamp: 1672511400000,
        value: 1418.663
    },
    {
        timestamp: 1672512300000,
        value: 1376.113
    },
    {
        timestamp: 1672513200000,
        value: 1328.402
    },
    {
        timestamp: 1672514100000,
        value: 1284.835
    },
    {
        timestamp: 1672515000000,
        value: 1250.593
    },
    {
        timestamp: 1672515900000,
        value: 1218.957
    },
    {
        timestamp: 1672516800000,
        value: 1203.188
    },
    {
        timestamp: 1672517700000,
        value: 1187.736
    },
    {
        timestamp: 1672518600000,
        value: 1166.297
    },
    {
        timestamp: 1672519500000,
        value: 1142.349
    },
    {
        timestamp: 1672520400000,
        value: 1120.854
    },
    {
        timestamp: 1672521300000,
        value: 1104.401
    },
    {
        timestamp: 1672522200000,
        value: 1088.65
    },
    {
        timestamp: 1672523100000,
        value: 1080.053
    }
] as unknown as Forecast[];

export const mockedDemandChartData = [
    mockedDemandForecast.map((i) => ({ time: dayjs(i.timestamp).unix(), value: i.value })),
    mockedDemandHistorical.map((i) => ({ time: dayjs(i.timestamp).unix(), value: i.value }))
];

// const minForecastData = Math.min(...mockedDemandForecast.map((i) => Number(i.value)));
// const maxForecastData = Math.max(...mockedDemandForecast.map((i) => Number(i.value)));
// const minDemandData = Math.min(...mockedDemandHistorical.map((i) => Number(i.value)));
// const maxDemandData = Math.max(...mockedDemandHistorical.map((i) => Number(i.value)));

const today = dayjs().startOf('hour');

const forecastData = sortForecastData(
    mockedDemandForecast.map((i, idx) => ({
        time: today
            .clone()
            .subtract(idx * 15, 'minutes')
            .unix(),
        value: i.value
    })) as unknown as LineData[]
);

const demandData = sortForecastData(
    mockedDemandHistorical.map((i, idx) => ({
        time: today
            .clone()
            .subtract(idx * 15, 'minutes')
            .unix(),
        value: i.value
    })) as unknown as LineData[]
);

export const mockedDemandChartDataWithDemand = [
    forecastData,
    demandData.map((item, index) => {
        return {
            time: item.time,
            value: Number(item.value) - Number(forecastData[index].value)
        };
    }),
    demandData
    // mockedDemandForecast.map((i) => ({ time: dayjs(i.timestamp).unix(), value: +i.value - 150 - Math.random() * 10 }))
];
