import { TFunction } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { store } from '../../../../stores';
import { ChargingStationStatus, UserStatus } from '../../../../interfaces/apiv2';
import { getModuleImage } from '../../../../utils/permissions';
import { ASSET_STATUS, UserFE } from '../../../../interfaces/uiv2';
import { TableCellRenderer } from '../../../../interfaces/general';
import { PageHeader } from '../../../../components/PageHeader/PageHeader';
import { transformUserFEtoUserPatchBody } from '../../../../modals/UserModal/helpers';
import { resendActivationLinkAsync, updateUserAsync } from '../../../../stores/Users';
import { DefaultSearchableCell, TableAction, TableActions } from '../../../../components/BaseTable';

import style from './style.module.scss';

export const getBackgroundColorPerStatus = (status: ASSET_STATUS | UserStatus | ChargingStationStatus | number) => {
    switch (status) {
        case UserStatus.DISABLED:
        case ChargingStationStatus.DISABLED:
        case 0:
            return '#E55151';
        case UserStatus.PENDING:
        case ChargingStationStatus.PENDING:
        case 1:
            return '#FEB803';
        case UserStatus.ACTIVE:
        case ChargingStationStatus.ACTIVE:
        case ASSET_STATUS.COMPLETED:
        case 2:
            return '#6FD8B2';
        default:
            return '#FEB803';
    }
};

export const getTableHeadCells = ({ tList, t }: { tList: TFunction; t: TFunction }): TableCellRenderer<UserFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            sort: false,
            value: (row, searchValue, onClick) => {
                const value = [row.last_name, row.first_name].filter((x) => x).join(' ') || '';
                const callback = onClick ? onClick(row) : undefined;

                return <DefaultSearchableCell value={value} searchValue={searchValue} onClick={callback} />;
            }
        },
        {
            id: 'company_name',
            sort_id: 'client.name',
            justifyContent: 'center',
            label: tList('tableHead.companyName'),
            sort: true,
            value: (row, searchValue) => {
                const value = row?.client?.name || '';

                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            }
        },
        {
            id: 'contact_email',
            label: tList('tableHead.email'),
            justifyContent: 'center',
            sort: false,
            value: (row, searchValue) => {
                return (
                    <DefaultSearchableCell
                        className={style.noUnderline}
                        type="mailto"
                        value={row?.email}
                        searchValue={searchValue}
                    />
                );
            }
        },
        {
            id: 'jobTitle',
            sort_id: 'job_title.title',
            maxWidth: '10%',
            justifyContent: 'center',
            label: tList('tableHead.jobTitle'),
            sort: true,
            value: (row, searchValue) => {
                const value = row?.job_title?.title || '';

                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            }
        },
        {
            id: 'activatedModules',
            maxWidth: '15%',
            label: tList('tableHead.modulesAvailable'),
            value: (row) => (
                <Box className={'modulesContainer'}>
                    {row.activatedModules
                        ?.map((module, index) => {
                            const img = getModuleImage(module);

                            return img ? <img key={index} alt={module} src={img} /> : null;
                        })
                        .filter((x) => x)}
                </Box>
            ),
            sort: false
        },
        {
            id: 'status',
            maxWidth: '10%',
            justifyContent: 'center',
            label: tList('tableHead.status'),
            value: (row) => {
                let text;

                switch (row.status) {
                    case UserStatus.DISABLED:
                        text = t('Inactive');
                        break;
                    case UserStatus.PENDING:
                        text = t('Pending');
                        break;
                    case UserStatus.ACTIVE:
                        text = t('Active');
                        break;
                    default:
                        text = t('Pending');
                        break;
                }

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                width: '8px',
                                height: '8px',
                                marginRight: '8px',
                                borderRadius: '50%',
                                background: getBackgroundColorPerStatus(row.status)
                            }}
                        ></Box>
                        <Typography
                            variant="small4"
                            sx={{
                                color: row.status === UserStatus.DISABLED ? '#828282 !important' : '#323232 !important'
                            }}
                        >
                            {text}
                        </Typography>
                    </Box>
                );
            },
            sort: false
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: t('actions'),
            value: (row, searchValue, onClick) => {
                const dispatch = store.dispatch;

                const isActive = row.status === UserStatus.ACTIVE;

                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            actions={
                                [
                                    {
                                        id: 'edit',
                                        label: 'table.general.editInfo',
                                        disabled: false,
                                        callback: () => {
                                            onClick(row)();
                                        }
                                    },
                                    row.status !== UserStatus.PENDING
                                        ? {
                                              id: 'deactivate',
                                              label: isActive ? 'table.general.deactivate' : 'table.general.activate',
                                              disabled: false,
                                              callback: () => {
                                                  const patchUserBody = transformUserFEtoUserPatchBody(row);
                                                  patchUserBody.body.status = isActive
                                                      ? ASSET_STATUS.DISABLED
                                                      : ASSET_STATUS.ACTIVE;
                                                  dispatch(updateUserAsync(patchUserBody));
                                              }
                                          }
                                        : null,
                                    row.status === UserStatus.PENDING
                                        ? {
                                              id: 'resendEmail',
                                              label: 'table.general.resendEmail',
                                              disabled: false,
                                              callback: () => {
                                                  dispatch(
                                                      resendActivationLinkAsync({
                                                          email: row.email
                                                      })
                                                  );
                                              }
                                          }
                                        : null
                                ].filter((action) => action) as TableAction[]
                            }
                        />
                    </Box>
                );
            }
        }
    ];
};
