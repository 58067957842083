import { FC } from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import {
    isSearchable,
    getMatchLength,
    replaceWhiteSpace,
    getSplitFromSearch,
    getSearchMatchClass
} from '../../../utils/table';

import style from './style.module.scss';
import { TypographyTypeMap } from '@mui/material/Typography/Typography';
import { styleguide } from '../../../containers/Theme';

interface DefaultSearchableCellProps {
    value: string;
    searchValue: string | null | undefined;
    onClick?: any;
    linkUrl?: string;
    hyperlinkColor?: string;
    type?: 'mailto' | 'tel';
    className?: string;
    variant?: TypographyTypeMap['props']['variant'];
    isLink?: boolean;
}

const DefaultSearchableCell: FC<DefaultSearchableCellProps> = ({
    value,
    searchValue,
    onClick,
    type,
    isLink,
    linkUrl = '',
    children,
    hyperlinkColor,
    className,
    variant = 'small4'
}) => {
    const handleOnClick = (event: any) => {
        if (onClick) {
            onClick(event);
        }
    };

    if (value && searchValue && isSearchable(value, searchValue)) {
        const split = getSplitFromSearch(searchValue, value);
        const matchLength = getMatchLength(split, searchValue);

        return (
            <Box
                onClick={handleOnClick}
                className={classnames(onClick ? style.hyperlink : '', style.basicCell, className ? className : '')}
            >
                {children}
                {type ? (
                    <a href={`${type}:${value}`}>
                        {split.map((s, i) => (
                            <Typography
                                key={i}
                                variant={variant}
                                sx={{ color: hyperlinkColor }}
                                dangerouslySetInnerHTML={{ __html: replaceWhiteSpace(s) }}
                                className={classnames(
                                    getSearchMatchClass(searchValue, s, matchLength),
                                    className || ''
                                )}
                            />
                        ))}
                    </a>
                ) : (
                    <>
                        {isLink ? (
                            <Link to={linkUrl}>
                                {split.map((s, i) => (
                                    <Typography
                                        key={i}
                                        variant={variant}
                                        sx={{ color: styleguide.primaryColor }}
                                        dangerouslySetInnerHTML={{ __html: replaceWhiteSpace(s) }}
                                        className={getSearchMatchClass(searchValue, s, matchLength)}
                                    />
                                ))}
                            </Link>
                        ) : (
                            <>
                                {split.map((s, i) => (
                                    <Typography
                                        key={i}
                                        variant={variant}
                                        sx={{ color: hyperlinkColor }}
                                        dangerouslySetInnerHTML={{ __html: replaceWhiteSpace(s) }}
                                        className={getSearchMatchClass(searchValue, s, matchLength)}
                                    />
                                ))}
                            </>
                        )}
                    </>
                )}
            </Box>
        );
    }

    if (children) {
        return (
            <Box
                onClick={handleOnClick}
                className={classnames(onClick ? style.hyperlink : '', className ? className : '')}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {children}
                {type ? (
                    <a href={`${type}:${value}`}>
                        <Typography variant={variant} sx={{ color: hyperlinkColor }}>
                            {value}
                        </Typography>
                    </a>
                ) : (
                    <>
                        {isLink ? (
                            <Link to={linkUrl}>
                                <Typography variant={variant} sx={{ color: styleguide.primaryColor }}>
                                    {value}
                                </Typography>
                            </Link>
                        ) : (
                            <Typography variant={variant} sx={{ color: hyperlinkColor }}>
                                {value}
                            </Typography>
                        )}
                    </>
                )}
            </Box>
        );
    }

    return (
        <Box onClick={handleOnClick} className={classnames(onClick ? style.hyperlink : '', className ? className : '')}>
            {type ? (
                <a href={`${type}:${value}`}>
                    <Typography variant={variant} sx={{ color: hyperlinkColor }}>
                        {value}
                    </Typography>
                </a>
            ) : (
                <>
                    {isLink ? (
                        <Link to={linkUrl}>
                            <Typography variant={variant} sx={{ color: styleguide.primaryColor }}>
                                {value}
                            </Typography>
                        </Link>
                    ) : (
                        <Typography variant={variant} sx={{ color: hyperlinkColor }}>
                            {value}
                        </Typography>
                    )}
                </>
            )}
        </Box>
    );
};

export { DefaultSearchableCell };
