import classNames from 'classnames';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import Table from './Table';
import Chart from './Chart';
import {
    getMoreReportingAsync,
    getReportingAsync,
    resetGasDeliveredForecastPage
} from '../../stores/DeliveredForecast';
import { TAB } from '../../interfaces/general';
import { DATE_SETTINGS } from '../../utils/config';
import { getFormattedDate } from '../../utils/helpers';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../utils/table';
import { downloadGasForecast } from '../../stores/Forecasts';
import { useAppDispatch, useAppSelector } from '../../stores';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../components/EntityPageToolbar';

import { ReactComponent as ViewList } from '../../assets/icons/view_list.svg';
import { ReactComponent as GraphIcon } from '../../assets/icons/timeline.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';

import style from '../../features/asset-page/asset-page.module.scss';
import dayjs from 'dayjs';

const GasDeliveredForecast = () => {
    const dispatch = useAppDispatch();
    const { projectId: project_id = '' } = useParams();
    const { t: generalTranslation } = useTranslation();
    const [tab, setTab] = useState<TAB>(TAB.TABLE);

    const [searchParams] = useSearchParams();

    const from_datetime = searchParams.get('from') || '';
    const to_datetime = searchParams.get('to') || '';
    const id = searchParams.get('id') || '';
    const type = searchParams.get('type') || '';

    const subTitle = `${getFormattedDate({
        value: +from_datetime,
        type: DATE_SETTINGS.shortFormat,
        fallback: DEFAULT_EMPTY_CELL_VALUE,
        timezone: dayjs.tz.guess()
    })} - ${getFormattedDate({
        value: +to_datetime,
        type: DATE_SETTINGS.shortFormat,
        fallback: DEFAULT_EMPTY_CELL_VALUE,
        timezone: dayjs.tz.guess()
    })}`;

    const {
        chartData,
        pager,
        error,
        data,
        loading,
        paginatedData,
        getParams: { page }
    } = useAppSelector((state) => state.deliveredForecastPage);

    useEffect(() => {
        dispatch(getReportingAsync({ from_datetime, to_datetime, project_id }));

        return () => {
            dispatch(resetGasDeliveredForecastPage());
        };
    }, []);

    const fetchMoreGas = (p?: number) => {
        if (pager) {
            const page = p || pager.page + 1;
            if (page <= pager.pages) {
                dispatch(getMoreReportingAsync({ from_datetime, to_datetime, project_id, page }));
            }
        }
    };

    useEffect(() => {
        fetchMoreGas(page);
    }, [page]);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    const handleDownload = () => {
        dispatch(
            downloadGasForecast({
                projectId: project_id,
                id,
                file_type: type as any,
                from_date: from_datetime,
                to_date: to_datetime
            })
        );
    };

    return (
        <Box className={classNames(style.pageContainer)}>
            <Box className={style.headerContainer}>
                <Box className={style.header}>
                    <PageHeader
                        className={style.title}
                        id={'demo'}
                        title={generalTranslation('title.deliveredForecast')}
                        subtitle={subTitle}
                    />
                    <Box className={style.actionsContainer}>
                        <EntityPageToolbar>
                            <EntityPageToolbarButton
                                tabId={TAB.CHART}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.chart')}
                            >
                                <GraphIcon />
                            </EntityPageToolbarButton>
                            <EntityPageToolbarButton
                                tabId={TAB.TABLE}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.table')}
                            >
                                <ViewList />
                            </EntityPageToolbarButton>
                            <EntityPageToolbarButton
                                tabId={TAB.ASSETS}
                                activeTabId={tab}
                                handleTabChange={handleDownload}
                                label={'Download'}
                            >
                                <DownloadIcon />
                            </EntityPageToolbarButton>
                        </EntityPageToolbar>
                    </Box>
                </Box>
            </Box>

            {tab === TAB.CHART && (
                <Chart chartData={chartData} data={data} error={error} loading={loading} fetchMoreGas={fetchMoreGas} />
            )}
            {tab === TAB.TABLE && (
                <Table error={error} loading={loading} fetchMoreGas={fetchMoreGas} data={paginatedData} pager={pager} />
            )}
        </Box>
    );
};

export default GasDeliveredForecast;
