import { Layout } from 'react-grid-layout';
import { MouseEvent, useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Box, IconButton } from '@mui/material';
import { MODAL_IDS } from '../../modals';
import { MdOutlineUnfoldLess } from 'react-icons/md';
import { ModalContext } from '../../hooks/modalContext';
import { ReactComponent as UnfoldSVG } from '../../assets/icons/unfold.svg';

import style from './style.module.scss';

export const CardHeaderActions = ({
    l,
    changeHeight,
    deleteDashboardItem,
    item
}: {
    l: Layout;
    changeHeight: (item: string) => void;
    deleteDashboardItem: (item: string) => void;
    item?: { name: string; type: string };
}) => {
    const { handleClickOpen } = useContext(ModalContext);
    const resizeItem = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        changeHeight(l.i);
    };

    const deleteItem = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handleClickOpen(MODAL_IDS.DELETE_CARD_FROM_DASHBOARD_MODAL, {
            deleteItem: () => deleteDashboardItem(l.i),
            item
        });
    };

    return (
        <Box className={style.actions}>
            {l.h === 1 ? (
                <IconButton disableRipple className="dragDisable" onClick={resizeItem}>
                    <UnfoldSVG style={{ width: 18, height: 18 }} />
                </IconButton>
            ) : (
                <IconButton disableRipple className="dragDisable" onClick={resizeItem}>
                    <MdOutlineUnfoldLess />
                </IconButton>
            )}

            <IconButton disableRipple className="dragDisable" onClick={deleteItem}>
                <AiOutlineClose />
            </IconButton>
        </Box>
    );
};
