import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';

import { MODAL_IDS } from '../../modals';
import { GenericTable } from '../BaseTable';
import { ModalContext } from '../../hooks/modalContext';
import { getAssetLocationsTableHeadCells } from './helpers';
import { useAppDispatch, useAppSelector } from '../../stores';
import { deleteAssetLocationsAsync } from '../../stores/Assets';
import { IngestLocationsModal } from '../../modals/IngestLocationsModal';
import { transformOrderByToSort } from '../BaseTable/GenericTable/helpers';
import { AssetLocationFE, GenerationAssetFE } from '../../interfaces/uiv2';
import { EditAssetLocationsModal } from '../../modals/EditAssetLocationsModal/EditAssetLocationsModal';
import { getAssetLocationsAsync, setGetAssetLocationsParams, setSelectedLocationsRows } from '../../stores/Assets';

import style from './style.module.scss';

const LocationsTable = ({ asset }: { asset: GenerationAssetFE }) => {
    const dispatch = useAppDispatch();
    const { handleClickOpen } = useContext(ModalContext);
    const [selected, setSelected] = useState<AssetLocationFE>();

    const {
        locationsFE: rows,
        pager,
        loading,
        success,
        getParams,
        selectedRows
    } = useAppSelector((state) => state.listAssetLocations);

    const { created } = useAppSelector((state) => state.createAssetLocations);
    const { edited } = useAppSelector((state) => state.editAssetLocations);
    const { deleted } = useAppSelector((state) => state.deleteAssetLocations);

    const { t } = useTranslation();
    const headCells = getAssetLocationsTableHeadCells(t);

    useEffect(() => {
        dispatch(getAssetLocationsAsync({ assetId: asset.id, getParams }));
    }, [getParams, created, deleted, edited]);

    const handleDelete = (row: AssetLocationFE) => () => {
        dispatch(deleteAssetLocationsAsync({ id: row.id, assetId: asset.id }));
    };

    const handleEdit = (row: AssetLocationFE) => () => {
        setSelected(row);
        handleClickOpen(MODAL_IDS.EDIT_LOCATION_COORDINATES);
    };

    const handleIngest = (row: AssetLocationFE) => () => {
        setSelected(row);
        handleClickOpen(MODAL_IDS.INGEST_LOCATION_COORDINATES);
    };

    return (
        <Box className={style.container}>
            <GenericTable
                pager={pager}
                className={style.table}
                isCheckboxEnabled
                rows={rows}
                loading={loading}
                success={success}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedLocationsRows}
                setGetParams={setGetAssetLocationsParams}
                columns={headCells}
                rowUniqueId={'index'}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    delete: handleDelete,
                    edit: handleEdit,
                    ingest: handleIngest
                }}
            />
            {selected && <EditAssetLocationsModal assetId={asset.id} data={selected} />}
            {selected && <IngestLocationsModal data={[selected]} asset={asset} />}
        </Box>
    );
};

export { LocationsTable };
