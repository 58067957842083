import listProjects from './listProjects';
import createProjects from './createProjects';
import deleteProjects from './deleteProjects';
import updateProjects from './updateProjects';
import listImportedFiles from './listImportedFiles';
import { api } from '../api';
import { PaginatedApiResponse, ProjectsData } from '../../interfaces/apiv2';
import { getDownloadThunk } from '../../utils/common-thunks';

export * from './listProjects';
export * from './deleteProjects';
export * from './createProjects';
export * from './updateProjects';
export * from './listImportedFiles';

export const projectsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProjects: build.query({
            query: (params) => {
                if (params.search?.trim()) {
                    return {
                        url: '/projects',
                        method: 'get',
                        params: { ...params, search: params.search },
                        data: undefined
                    };
                }

                return { url: '/projects', method: 'get', params };
            },
            transformResponse: (rawData: PaginatedApiResponse<ProjectsData>) => {
                return rawData.items;
            }
        })
    })
});

export const downloadImportedFileData = ({
    filename,
    id,
    project_id,
    type
}: {
    filename: string;
    id: string;
    project_id: string;
    type: 'output' | 'input';
}) =>
    getDownloadThunk({
        thunkName: 'imported_file/download',
        endpoint: `/import-jobs/${id}/${type}-file?project_id=${project_id}`,
        filename
    });

export const { useGetProjectsQuery } = projectsApi;

export default {
    listProjects,
    createProjects,
    deleteProjects,
    updateProjects,
    listImportedFiles
};
