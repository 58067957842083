import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '../../utils/config';
import { useAppDispatch, useAppSelector } from '../../stores';
import { resetDeleteReportListState } from '../../stores/Reports';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { resetDeleteForecastListState } from '../../stores/Forecasts';
import { resetDeleteProjectListState } from '../../stores/Projects';
import {
    resetDeleteClustersListState,
    resetDeleteConsumersListState,
    resetDeleteGasClustersListState,
    resetDeleteGasConsumersListState,
    resetDeleteGasProfilesListState,
    resetDeleteProfilesListState,
    resetDeleteSegmentsListState
} from '../../stores/Demand';

export const RemoveForecastContainer = ({
    cb,
    stateAccessor
}: {
    cb: () => void;
    stateAccessor:
        | 'deleteForecasts'
        | 'deleteReports'
        | 'deleteProjects'
        | 'deleteClusters'
        | 'deleteConsumers'
        | 'deleteSegments'
        | 'deleteGasProfiles'
        | 'deleteGasConsumers'
        | 'deleteGasClusters'
        | 'deleteProfiles';
}) => {
    const query = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { success, loading, error } = useAppSelector((state) => state[stateAccessor]);

    const props = {
        error,
        loading,
        success,
        label: t(stateAccessor),
        cleanup: () => {
            if (stateAccessor === 'deleteForecasts') {
                dispatch(resetDeleteForecastListState());
                navigate(ROUTES.FORECASTS);
                return;
            }

            if (stateAccessor === 'deleteReports') {
                dispatch(resetDeleteReportListState());
                navigate(ROUTES.REPORTS);
                return;
            }

            if (stateAccessor === 'deleteProjects') {
                dispatch(resetDeleteProjectListState());
                navigate(ROUTES.ADMIN_FORECASTING_PROJECTS);
                return;
            }

            if (stateAccessor === 'deleteConsumers') {
                dispatch(resetDeleteConsumersListState());
                return;
            }

            if (stateAccessor === 'deleteClusters') {
                dispatch(resetDeleteClustersListState());
                return;
            }

            if (stateAccessor === 'deleteProfiles') {
                dispatch(resetDeleteProfilesListState());
                return;
            }

            if (stateAccessor === 'deleteSegments') {
                dispatch(resetDeleteSegmentsListState());
                return;
            }

            if (stateAccessor === 'deleteGasConsumers') {
                dispatch(resetDeleteGasConsumersListState());
                navigate(ROUTES.AI_GAS_DEMAND_CONSUMERS.replace(':projectId', query.projectId!));
                return;
            }

            if (stateAccessor === 'deleteGasProfiles') {
                dispatch(resetDeleteGasProfilesListState());
                navigate(ROUTES.AI_GAS_DEMAND_PROFILES.replace(':projectId', query.projectId!));
                return;
            }

            if (stateAccessor === 'deleteGasClusters') {
                dispatch(resetDeleteGasClustersListState());
                navigate(ROUTES.AI_GAS_DEMAND_CLUSTERS.replace(':projectId', query.projectId!));
                return;
            }
        },
        cb
    };

    return <ConfirmationModal {...props} />;
};
