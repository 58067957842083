import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../stores';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TAB } from '../../../../interfaces/general';
import { PageContainer } from '../../../../layout/PageContainer';
import { Box, Typography } from '@mui/material';
import { PageHeader } from '../../../../components/PageHeader/PageHeader';
import classNames from 'classnames';
import { ReactComponent as ReportsSVG } from '../../../../assets/icons/navigation-items/dots-menu-reports.svg';

import style from '../../../../features/asset-page/asset-page.module.scss';
import { getEVSReportAsync, getEVSReportDataAsync, setGetParamsEVSReportPage } from '../../../../stores/EVS';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../../../components/EntityPageToolbar';
import { TableActionsEntityContainer } from '../../../../components/TableActionsEntityContainer';
import { DownloadExtension } from '../../../../components/DownloadButton';
import { ReactComponent as GraphIcon } from '../../../../assets/icons/timeline.svg';
import { ReactComponent as ViewList } from '../../../../assets/icons/view_list.svg';
import common from '../../../../common.module.scss';
import { getFormattedDate } from '../../../../utils/helpers';
import { DATE_SETTINGS, NO_VALUE, ROUTES } from '../../../../utils/config';
import { CircleProgressBar } from '../../../../components/BaseTable/CustomCells/CircleProgressBar';
import { TradingView } from '../../../../components/TradingView';
import { UNIT } from '../../../../interfaces/uiv2';
import MemoizedGenericTable from '../../../../components/GenericTableWrapper/GenericTableWrapper';
import { getTableHeadCells, renderFooterContent } from '../../../ReportsPage/helpers';
import Loading from '../../../../layout/Loading';
import { ReactComponent as ChargingPointSVG } from '../../../../assets/icons/dashboard/ev_point.svg';

const EVSReportPage = () => {
    const query = useParams();
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const { report, pager, loading, loadingData, chartData, errorGettingData, paginatedData, success } = useAppSelector(
        (state) => state.evsReportPage
    );

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `reportPageTitle.${tab}`;

    useEffect(() => {
        if (query?.reportId && query?.projectId) {
            dispatch(getEVSReportAsync({ id: query.reportId, projectId: query.projectId }));
        }
    }, [query]);

    useEffect(() => {
        if (report?.id && query?.projectId) {
            dispatch(getEVSReportDataAsync({ id: report.id, projectId: query.projectId }));
        }
    }, [report, query]);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    const getChargingPointPageUrl = () => {
        if (!report) return '';

        return `${ROUTES.AI_EVS_CHARGHING_POINTS_PAGE.replace(
            ':projectId',
            report.evs_cp_forecast_settings.charging_point.project.id
        ).replace(':id', report.evs_cp_forecast_settings.charging_point.id)}`;
    };

    const headCells = getTableHeadCells({
        t,
        country: '',
        assetMeasureUnit: UNIT.LOW_ENERGY
    });

    if (!report && loading) {
        return (
            <Box className={common.spinnerContainer}>
                <Loading className={common.dataLoading} />
            </Box>
        );
    }

    if (!report) {
        return null;
    }

    return (
        <PageContainer className={[style.pageContainer]}>
            <Box className={style.flex}>
                <Box className={style.headerContainer}>
                    <Box className={style.header}>
                        <PageHeader
                            className={classNames(style.align, style.primary)}
                            title={t('title.reports')}
                            id={report.id}
                            icon={<ReportsSVG />}
                            subtitle={report.name}
                        />
                        <Box className={style.subtitle} sx={{ ml: 2 }}>
                            <Box className={style.dot} />
                            <Typography variant="small1"> {t(pageTitle)}</Typography>
                        </Box>
                    </Box>

                    <Box className={style.toolbarContainer}>
                        <EntityPageToolbar>
                            <EntityPageToolbarButton
                                tabId={TAB.CHART}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={t('assetPageTitle.chart')}
                            >
                                <GraphIcon className={style.chartIcon} />
                            </EntityPageToolbarButton>
                            <EntityPageToolbarButton
                                tabId={TAB.TABLE}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={t('assetPageTitle.table')}
                            >
                                <ViewList />
                            </EntityPageToolbarButton>
                        </EntityPageToolbar>
                        <TableActionsEntityContainer
                            remove={false}
                            entityType="evs-reports"
                            id={report.id}
                            className={style.actionsWrapper}
                            defaultActions={[DownloadExtension.Csv, DownloadExtension.Excel]}
                            params={{ projectId: query.projectId }}
                        />
                    </Box>
                </Box>

                <Box className={classNames(style.subHeaderContainer, tab === 'table' ? common.borderBottom : '')}>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">Type</Typography>
                        <Box component="span" sx={{ ml: 1.5 }} className={style.align}>
                            <ChargingPointSVG />
                        </Box>
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4" sx={{ mr: 1.5 }}>
                            Charging point
                        </Typography>
                        <Link to={getChargingPointPageUrl()}>
                            <Typography variant="small3" className={style.primary}>
                                {report.evs_cp_forecast_settings.charging_point?.name}
                            </Typography>
                        </Link>
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border, style.margin)}>
                        <Typography variant="small4">{t('assetInfo.from')}</Typography>
                        <Typography variant="small3" sx={{ ml: 1.5, mr: 1.5 }}>
                            {getFormattedDate({
                                value: report.from_date,
                                type: DATE_SETTINGS.shortV2FormatHoursIncluded,
                                timezone: report.timezone || 'UTC'
                            })}
                        </Typography>
                        <Typography variant="small4">{t('assetInfo.to')}</Typography>
                        <Typography variant="small3" sx={{ ml: 1.5 }}>
                            {getFormattedDate({
                                value: report.to_date,
                                type: DATE_SETTINGS.shortV2FormatHoursIncluded,
                                timezone: report.timezone || 'UTC'
                            })}
                        </Typography>
                    </Box>
                    <Box className={classNames(style.subHeaderElement)}>
                        <Typography variant="small4">{t('assetInfo.accuracy')}</Typography>
                        <Box component="span" className={common.center} sx={{ ml: 1.5 }}>
                            {report.accuracy ? <CircleProgressBar value={report.accuracy * 100} /> : NO_VALUE}
                        </Box>
                    </Box>
                </Box>

                {tab === 'chart' && (
                    <TradingView
                        data={chartData}
                        loading={loadingData}
                        error={errorGettingData}
                        fetchMoreFn={() => {}}
                        assetMeasureUnit={UNIT.LOW_ENERGY}
                    />
                )}

                {tab === 'table' && (
                    <MemoizedGenericTable
                        data={paginatedData}
                        loading={loadingData}
                        error={errorGettingData}
                        success={success}
                        pager={pager}
                        headCells={headCells}
                        renderFooterContent={renderFooterContent}
                        setGetParams={setGetParamsEVSReportPage}
                    />
                )}
            </Box>
        </PageContainer>
    );
};

export default EVSReportPage;
