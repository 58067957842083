import deleteForecasts from './deleteForecasts';
import listForecasts from './listForecasts';
import forecastPage from './forecastPage';

export * from './deleteForecasts';
export * from './listForecasts';
export * from './forecastPage';

export default {
    deleteForecasts,
    listForecasts,
    forecastPage
};
