import {
    Box,
    Grid,
    Input,
    Stack,
    Divider,
    Checkbox,
    TextField,
    Typography,
    InputLabel,
    Autocomplete,
    FormHelperText,
    FormControlLabel,
    createFilterOptions
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { FC, useEffect, useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Controller, ControllerRenderProps } from 'react-hook-form';

import { debounce } from '../../utils/helpers';
import { MODAL_IDS } from '..';
import { useFormLogic } from '../../hooks/useFormLogic';
import { useDrawerMode } from '../../hooks/useDrawerMode';
import { useGetClientsQuery } from '../../stores/Clients';
import { getJobTitlesAsync } from '../../stores/JobTitles/JobTitles';
import { UserFE, UserFormFields } from '../../interfaces/uiv2';
import { useAppDispatch, useAppSelector } from '../../stores';
import { AppPermissions } from '../../components/AppPermissions';
import { hasAccessTo, transformPermissionsToApiFormat } from '../../utils/user';
import { getUserSchema, transformUserFormToBody, transformToUserSchema, transformUserBodyToUserPatch } from './helpers';
import { createUserAsync, updateUserAsync, resetCreateUserState } from '../../stores/Users';
import { FormErrorStep, FormSuccessStep, FormModalActions } from '../../components/FormUtils';

import style from './style.module.scss';

const filter = createFilterOptions<any>();

const UserForm: FC<{ user: UserFE | null }> = ({ user }) => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('users/create');
    const { t: generalTranslation } = useTranslation();

    const [search, setSearch] = useState('');

    const { isEdit } = useDrawerMode({ data: user });

    const { jobTitles } = useAppSelector((state) => state.jobTitles);
    const { success, error, loading } = useAppSelector((state) => state.createUser);
    const { hasAdminAccess, user: loggedInUser } = useAppSelector((state) => state.login);

    const { data: clients, isLoading } = useGetClientsQuery(
        { size: 100, page: 1, search: search },
        { skip: !hasAccessTo(loggedInUser!, ['Admin.Clients.CREATE']) }
    );

    const schema = getUserSchema(t, user?.permissions);

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        getValues,
        formState: { errors }
    } = useFormLogic<UserFE, UserFormFields, keyof typeof schema.fields, UserFormFields>({
        schema,
        data: user,
        transformFn: transformToUserSchema
    });

    const hasConnectedTo = !(watch('superadmin') || watch('guest'));

    useEffect(() => {
        dispatch(getJobTitlesAsync());

        return () => {
            dispatch(resetCreateUserState());
        };
    }, []);

    const jobTitleOnChange = (newValue: any, field: ControllerRenderProps<UserFormFields, 'jobTitle'>) => {
        if (typeof newValue === 'string') {
            field.onChange({
                title: newValue,
                id: null
            });
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            field.onChange({
                title: newValue.inputValue,
                id: null
            });
        } else {
            field.onChange(newValue);
        }

        return newValue;
    };

    const onChangeClient = debounce((e: any) => {
        const value = e.target.value;
        setSearch(value);
    }, 450);

    const onSubmit = (form: UserFormFields) => {
        if (form.client && !hasConnectedTo) {
            form.client = null;
        }
        const authentication = transformPermissionsToApiFormat(form.permissions, loggedInUser!.permissions);
        const body = transformUserFormToBody(form, authentication);

        if (isEdit) {
            const patchBody = transformUserBodyToUserPatch(body, user!);
            dispatch(updateUserAsync(patchBody));
            return;
        }

        dispatch(createUserAsync(body));
    };

    return (
        <Box className={style.container}>
            {!(success || error) ? (
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction={'row'} columnGap={4}>
                        <Box flex={0.75}>
                            <Typography variant="h6">{!isEdit ? t('title') : t('editTitle')}</Typography>

                            <Typography variant="body1" sx={{ mt: 3, mb: 1 }}>
                                {t('userTitle')}
                            </Typography>

                            <Grid container spacing={2} className={style.item}>
                                <Grid item xs={6}>
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <>
                                                <InputLabel
                                                    variant="standard"
                                                    required
                                                    shrink
                                                    htmlFor={t('form.firstName')}
                                                >
                                                    {t('form.firstName')}
                                                </InputLabel>

                                                <Input
                                                    {...field}
                                                    fullWidth
                                                    id={t('form.firstName')}
                                                    placeholder={t('form.firstName')}
                                                    endAdornment={
                                                        isEdit ? <EditIcon sx={{ fontSize: 12 }} color="info" /> : null
                                                    }
                                                />

                                                {errors.firstName && (
                                                    <FormHelperText error>{errors.firstName?.message}</FormHelperText>
                                                )}
                                            </>
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <>
                                                <InputLabel
                                                    variant="standard"
                                                    required
                                                    shrink
                                                    htmlFor={t('form.lastName')}
                                                >
                                                    {t('form.lastName')}
                                                </InputLabel>

                                                <Input
                                                    {...field}
                                                    id={t('form.lastName')}
                                                    fullWidth
                                                    placeholder={t('form.lastName')}
                                                    endAdornment={
                                                        isEdit ? <EditIcon sx={{ fontSize: 12 }} color="info" /> : null
                                                    }
                                                />

                                                {errors.lastName && (
                                                    <FormHelperText error>{errors.lastName?.message}</FormHelperText>
                                                )}
                                            </>
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Box className={style.item}>
                                <Controller
                                    name="jobTitle"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <InputLabel variant="standard" shrink htmlFor="jobTitle">
                                                {t('form.jobTitle')}
                                            </InputLabel>

                                            <Autocomplete
                                                value={field?.value || null}
                                                id="jobTitle"
                                                freeSolo
                                                onChange={(e, newValue) => jobTitleOnChange(newValue, field)}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);

                                                    const { inputValue } = params;
                                                    // Suggest the creation of a new value
                                                    const isExisting = options.some(
                                                        (option) => inputValue === option.title
                                                    );

                                                    if (inputValue !== '' && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            title: `${generalTranslation('add')} "${inputValue}"`
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                noOptionsText={generalTranslation('select.noOptions')}
                                                options={jobTitles}
                                                autoHighlight
                                                getOptionLabel={(option) => {
                                                    // Value selected with enter, right from the input
                                                    if (typeof option === 'string') {
                                                        return option;
                                                    }
                                                    // Add "xxx" option created dynamically
                                                    // if (option.inputValue) {
                                                    //     return option.inputValue;
                                                    // }
                                                    // Regular option
                                                    return option.title;
                                                }}
                                                renderOption={(props, jobTitle) => (
                                                    <Box {...props} component="li">
                                                        {jobTitle.title}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: t('form.jobTitle')
                                                        }}
                                                    />
                                                )}
                                            />

                                            {errors.jobTitle && (
                                                <FormHelperText error>
                                                    {(errors.jobTitle as { [key: string]: string })?.message}
                                                </FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </Box>

                            <Box className={style.item}>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <>
                                            <InputLabel variant="standard" required shrink htmlFor={t('form.email')}>
                                                {t('form.email')}
                                            </InputLabel>

                                            <Input
                                                {...field}
                                                fullWidth
                                                id={t('form.email')}
                                                placeholder={t('form.email')}
                                                endAdornment={
                                                    isEdit ? <EditIcon sx={{ fontSize: 12 }} color="info" /> : null
                                                }
                                            />

                                            {errors.email && (
                                                <FormHelperText error>{errors.email?.message}</FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </Box>

                            <Box className={style.item}>
                                <InputLabel variant="standard" shrink>
                                    {t('form.role')}
                                </InputLabel>
                                <Box className={style.checkboxRow}>
                                    <Controller
                                        name="superadmin"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field }) => (
                                            <>
                                                <Stack direction="row" spacing={3}>
                                                    <FormControlLabel
                                                        componentsProps={{ typography: { variant: 'small1' } }}
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                checked={field.value}
                                                                onChange={(e) => {
                                                                    if (getValues('guest')) {
                                                                        setValue('guest', false, { shouldDirty: true });
                                                                    }
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                        }
                                                        label={t('form.superadmin')}
                                                    />
                                                </Stack>
                                            </>
                                        )}
                                    />
                                    <Controller
                                        name="guest"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field }) => (
                                            <>
                                                <Stack direction="row" spacing={3}>
                                                    <FormControlLabel
                                                        componentsProps={{ typography: { variant: 'small1' } }}
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                checked={field.value}
                                                                onChange={(e) => {
                                                                    if (getValues('superadmin')) {
                                                                        setValue('superadmin', false, {
                                                                            shouldDirty: true
                                                                        });
                                                                    }
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                        }
                                                        label={t('form.guest')}
                                                    />
                                                </Stack>
                                            </>
                                        )}
                                    />
                                </Box>
                            </Box>

                            {/* FIXME: TBD what condition we need here */}
                            {hasAdminAccess && hasConnectedTo ? (
                                <Box className={style.item}>
                                    <Controller
                                        name="client"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <InputLabel variant="standard" shrink htmlFor="client">
                                                    {t('form.client')}
                                                </InputLabel>

                                                <Autocomplete
                                                    // disabled={getValues('guest') || getValues('superadmin')}
                                                    value={field?.value || null}
                                                    id="client"
                                                    loading={isLoading}
                                                    onChange={(e, v) => {
                                                        field.onChange(v);
                                                        return v;
                                                    }}
                                                    noOptionsText={generalTranslation('select.noOptions')}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    options={clients || []}
                                                    autoHighlight
                                                    popupIcon={<MdKeyboardArrowDown color="#6A9BFF" />}
                                                    getOptionLabel={(option) => option.name}
                                                    renderOption={(props, client) => (
                                                        // Math.random() is needed here because there are duplicates in the records
                                                        <Box
                                                            component="li"
                                                            {...props}
                                                            className={style.dropdownRow}
                                                            key={Math.random()}
                                                        >
                                                            {client.name}
                                                        </Box>
                                                    )}
                                                    // todo: position: 'bottom-start' 👇
                                                    ListboxProps={{ style: { maxHeight: '180px' } }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                placeholder: t('form.client')
                                                            }}
                                                            onChange={onChangeClient}
                                                        />
                                                    )}
                                                />

                                                {errors.client && (
                                                    <FormHelperText error>
                                                        {/* todo: fix this 👇 (types) */}
                                                        {(errors.client as { [key: string]: string })?.message}
                                                    </FormHelperText>
                                                )}
                                            </>
                                        )}
                                    />
                                </Box>
                            ) : null}
                        </Box>

                        <Box flex={0.75}>
                            <Typography variant="body1" sx={{ mt: 6, mb: 3.5 }}>
                                {t('stepTwo.secondaryTitle')}
                            </Typography>
                            <AppPermissions
                                control={control}
                                permissions={user?.permissions || loggedInUser!.permissions}
                            />
                        </Box>
                    </Stack>

                    <Divider />

                    <FormModalActions
                        loading={loading}
                        modalId={MODAL_IDS.USER_MODAL}
                        note={generalTranslation('mandatoryFields')}
                        cancelLabel={generalTranslation('nevermind')}
                        saveLabel={!isEdit ? t('form.submit') : t('form.editSubmit')}
                    />
                </Box>
            ) : null}

            {success ? <FormSuccessStep /> : null}
            {error ? <FormErrorStep /> : null}
        </Box>
    );
};

export { UserForm };

/**
     * 
     const client = watch('client');
      useMemo(() => {
        if (client?.authorized_modules_dict) {
            const modules = client.authorized_modules_dict.reduce((acc, item) => {
                const children = (item.children || []).reduce((a, i) => {
                    return {
                        ...a,
                        [i.name]: !i.disabled
                    };
                }, {});
                return {
                    ...acc,
                    [item.name]: children
                };
            }, {});

            setTimeout(() => {
                setValue('appModules', modules);
            });
        }

        return client?.authorized_modules_dict ? client.authorized_modules_dict : appModules;
    }, [client]);
     * 
     */
