import { isEqual } from 'lodash';
import classnames from 'classnames';
import Flag from 'react-world-flags';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import {
    getEVSChargingStationAsync,
    getEVSChargingStationForecastAsync,
    getEVSChargingStationForecastSettingsAsync,
    getEVSChargingStationHistoricalAsync,
    loadMoreEVSChargingStationData,
    resetEVSChargingStationPage,
    setGetEVSChargingStationParams
} from '../../../stores/EVS';
import { UNIT } from '../../../interfaces/uiv2';
import { TAB } from '../../../interfaces/general';
import { PageContainer } from '../../../layout/PageContainer';
import { TradingView } from '../../../components/TradingView';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ReactComponent as ViewList } from '../../../assets/icons/view_list.svg';
import { ReactComponent as GraphIcon } from '../../../assets/icons/timeline.svg';
import { MemoizedGenericTable } from '../../../components/GenericTableWrapper/GenericTableWrapper';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../../components/EntityPageToolbar';
import { ReactComponent as EVSSVG } from '../../../assets/icons/navigation-items/menu-item-evs.svg';
import { getTableHeadCells, renderFooterContent } from '../../../components/GenerationTable/helpers';

import common from '../../../common.module.scss';
import style from '../../../features/asset-page/asset-page.module.scss';
import dayjs from 'dayjs';

const ChargingStationsPage = () => {
    const query = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { projectId = '' } = useParams();

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;

    const {
        pager,
        chargingStation,
        chartData,
        paginatedData,
        success,
        forecastSettings,
        loading: loadingForecast,
        error: errorForecast
    } = useAppSelector((state) => state.evsChargingStationPage);
    const getParams = useAppSelector((state) => state.evsChargingStationPage.getParams, isEqual);

    useEffect(() => {
        if (query?.id && !chargingStation) {
            dispatch(getEVSChargingStationAsync({ id: query.id, projectId }));
        }
    }, [query, chargingStation]);

    useEffect(() => {
        if (chargingStation) {
            dispatch(getEVSChargingStationForecastSettingsAsync({ chargingStation, projectId }));
        }
    }, [chargingStation]);

    useEffect(() => {
        if (chargingStation && forecastSettings) {
            const forecastIDs = forecastSettings.map(({ id }) => id);

            if (forecastIDs.length) {
                // const firstSetting: any = forecastSettings[0]; // TODO
                const firstNovember = dayjs('2023-11-01'); // TODO

                const from = firstNovember.valueOf();
                const to = dayjs().add(10, 'days').utcOffset(0).endOf('date').valueOf();

                dispatch(
                    getEVSChargingStationForecastAsync({
                        chargingStation,
                        projectId,
                        forecastIDs,
                        to_datetime: to,
                        from_datetime: from
                    })
                );
                dispatch(
                    getEVSChargingStationHistoricalAsync({
                        chargingStation,
                        projectId,
                        forecastIDs,
                        to_datetime: to,
                        from_datetime: from
                    })
                );
            }
        }
    }, [forecastSettings]);

    useEffect(() => {
        return () => {
            dispatch(resetEVSChargingStationPage());
        };
    }, []);

    useEffect(() => {
        if (tab === 'table' && pager?.page !== getParams.page) {
            dispatch(loadMoreEVSChargingStationData());
        }
    }, [getParams, tab, pager]);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    if (!chargingStation) {
        return null;
    }

    const headCells = getTableHeadCells({ t, assetMeasureUnit: UNIT.LOW_ENERGY });

    return (
        <PageContainer className={[common.pageContainer]}>
            <Box className={style.headerContainer}>
                <Box className={classnames(style.header)}>
                    <PageHeader
                        title={'Charging Stations'}
                        className={classnames(style.align)}
                        id={chargingStation.id}
                        icon={<EVSSVG />}
                        subtitle={chargingStation.name}
                    />

                    <Box className={style.subtitle} sx={{ ml: 2 }}>
                        <Box className={style.dot} />
                        <Typography variant="small1"> {t(pageTitle)}</Typography>
                    </Box>
                </Box>

                <Box className={style.toolbarContainer}>
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>
                    </EntityPageToolbar>
                </Box>
            </Box>

            <Box className={common.subHeaderContainer}>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.type')}</Typography>
                    <Typography variant="small3" className={classnames(common.ml, common.flex)}>
                        <EVSSVG />
                    </Typography>
                    <Typography variant="small3" className={common.ml}>
                        {'Charging Station'}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.region')}</Typography>
                    <Typography variant="small3" className={classnames(common.ml, common.alignCenter)}>
                        <Flag
                            height="24"
                            width="24"
                            code={chargingStation.country}
                            style={{ marginRight: '8px' }}
                            className="flagIcon"
                        />
                        {chargingStation.country.toUpperCase()}
                    </Typography>
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={chartData as any}
                    error={false}
                    fetchMoreFn={() => {}}
                    loading={loadingForecast}
                    legendOpts={{
                        forecasted: true,
                        measured: true
                    }}
                    assetMeasureUnit={UNIT.LOW_ENERGY}
                />
            )}
            {tab === 'table' && (
                <MemoizedGenericTable
                    renderFooterContent={renderFooterContent}
                    headCells={headCells}
                    pager={pager}
                    success={success}
                    data={paginatedData}
                    error={errorForecast}
                    loading={loadingForecast}
                    setGetParams={setGetEVSChargingStationParams}
                    fetchMoreFn={() => {}}
                />
            )}
        </PageContainer>
    );
};

export default ChargingStationsPage;
