import dayjs from 'dayjs';
import { LineData } from 'lightweight-charts';

import {
    GenerationReportTimeseries,
    GenerationForecastTimeseries,
    GenerationMeasuredTimeseries,
    GenerationReportData
} from '../interfaces/apiv2';
import { toFixed } from './helpers';
import { getTimezoneByCountry, sortForecastData } from './forecast';

const handleTimestampParsing = ({ timestamp, country }: { timestamp: string | number; country?: string }) => {
    const timezone = country ? getTimezoneByCountry(country) : 'UTC';
    const date = dayjs.tz(timestamp, 'UTC');
    const offset = date.tz(timezone).utcOffset();
    const time = date.add(offset, 'minutes').unix();

    return time;
};

const handleValueParsing = ({ value, totalCapacity }: { value: number; totalCapacity: number }) => {
    // we divide the number to 1000 because we receive kW and we are displaying MW.
    const rawValue = totalCapacity < 500 ? value : value / 1000;

    return toFixed(Number(rawValue), 3);
};

export const transformGenerationForecastToView = ({
    data,
    country,
    valueKey,
    totalCapacity
}: {
    country?: string;
    totalCapacity: number;
    data: GenerationForecastTimeseries[];
    valueKey: Exclude<keyof GenerationForecastTimeseries, 'timestamp'>;
}): LineData[] => {
    return sortForecastData(
        data.map((point) => {
            const time = handleTimestampParsing({ timestamp: point.timestamp, country });
            const value = handleValueParsing({ value: point[valueKey], totalCapacity });

            return {
                time,
                value
            } as LineData;
        })
    );
};

export const transformGenerationMeasuredToView = ({
    data,
    country,
    valueKey,
    totalCapacity
}: {
    country?: string;
    totalCapacity: number;
    data: GenerationMeasuredTimeseries[];
    valueKey: Exclude<keyof GenerationMeasuredTimeseries, 'timestamp'>;
}): LineData[] => {
    return data.map((point) => {
        const time = handleTimestampParsing({
            timestamp: Number.isNaN(Number(point.timestamp)) ? point.timestamp : Number(point.timestamp),
            country
        });
        const value = handleValueParsing({ value: point[valueKey], totalCapacity });

        return {
            time,
            value
        } as LineData;
    });
};

export const transformGenerationReportToView = ({
    data,
    totalCapacity
}: {
    totalCapacity: number;
    data: GenerationReportTimeseries[];
}): GenerationReportData[] => {
    return data.map((x, ix) => ({
        ...x,
        measured: handleValueParsing({ value: x.measured, totalCapacity }),
        forecasted: handleValueParsing({ value: x.forecasted, totalCapacity }),
        excess: handleValueParsing({ value: x.excess, totalCapacity }),
        deficit: handleValueParsing({ value: x.deficit, totalCapacity }),
        index: ix + 1
    }));
};

export const transformGenerationReportToChart = ({
    data,
    totalCapacity,
    country
}: {
    data: GenerationReportData[];
    totalCapacity: number;
    country?: string;
}) => {
    const forecasted = transformGenerationForecastToView({
        data: data.map((point) => ({
            timestamp: point.timestamp,
            value: point.forecasted * 1000
        })) as unknown as GenerationForecastTimeseries[],
        valueKey: 'value',
        totalCapacity,
        country
    });

    const measured = transformGenerationMeasuredToView({
        data: data.map((point) => ({
            timestamp: point.timestamp,
            value: point.measured * 1000
        })) as unknown as GenerationForecastTimeseries[],
        valueKey: 'value',
        totalCapacity,
        country
    });

    return [sortForecastData(forecasted), sortForecastData(measured)];
};
