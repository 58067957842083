import { isEqual } from 'lodash';
import classnames from 'classnames';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Box, Checkbox, Tooltip, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import { BaseTablePagination } from '..';
import { EditableCell } from './EditableCell';
import { useAppDispatch } from '../../../stores';
import SmallTooltip from '../../Tooltips/SmallTooltip';
import NoValueTable from '../../NoValueTable/NoValueTable';
import { PAGINATION_SETTINGS } from '../../../utils/config';
import { TableCellRenderer } from '../../../interfaces/general';
import { buildCustomSx, transformSortToOrderBy } from './helpers';
import { EditCell, ExpandedRow, GenericTableProps } from './interfaces';
import { ReactComponent as SortSvg } from '../../../assets/icons/sort.svg';
import { ReactComponent as SaveIcon } from '../../../assets/icons/save-table.svg';
import { ReactComponent as TooltipInfoSvg } from '../../../assets/icons/tooltip-info.svg';

import { MdAddCircleOutline, MdRemoveCircleOutline, MdEditOff } from 'react-icons/md';

import style from './style.module.scss';

//in memory data for table
let tableData: any = {};

const GenericTable = <T,>({
    className,
    rows,
    pager,
    columns,
    loading,
    storeSort,
    handleEdit,
    searchParam,
    rowUniqueId,
    setGetParams,
    selectedRows,
    setSelectedRows,
    isCheckboxEnabled,
    isAccordionRowEnabled,
    handlers,
    success,
    cleanupCb,
    customFooter,
    pagination = true,
    emptyTablePlaceholder = true,
    virtualized = false,
    scrollingPlaceholder = false,
    editParams,
    expandableTableRow
}: GenericTableProps<T>) => {
    const dispatch = useAppDispatch();
    const observer = useRef<IntersectionObserver>();

    const [sort, setSort] = useState(storeSort);
    const [editMode, setEditMode] = useState<any>();
    const [expandedRows, setExpandedRows] = useState<ExpandedRow>({});
    const hasMore = pager ? pager.page * pager.size <= pager.total : false;

    const lastElementRef = useCallback(
        (node) => {
            if (loading) return;

            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore && setGetParams) {
                    dispatch(setGetParams({ page: (pager?.page || PAGINATION_SETTINGS.default_start_page) + 1 }));
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore, pager]
    );

    useEffect(() => {
        if (setGetParams && sort) {
            const newSort = transformSortToOrderBy(sort);

            if (!isEqual(sort, storeSort)) {
                // reset pager when the sort is enabled.
                dispatch(setGetParams({ order_by: newSort, page: PAGINATION_SETTINGS.default_start_page }));
            }
        }
    }, [sort]);

    useEffect(() => {
        if (cleanupCb) {
            tableData = {};
            return cleanupCb;
        }
    }, []);

    useEffect(() => {
        tableData = {};
    }, [editParams?.tableMode]);

    useEffect(() => {
        if (searchParam && selectedRows?.length) {
            const idMap: any = {};
            selectedRows.forEach((row: T) => {
                idMap[row[rowUniqueId as keyof T]] = true;
            });
            const newSelectedRows = rows.filter((row: T) => {
                return idMap[row[rowUniqueId as keyof T]];
            });

            setSelectedRows && dispatch(setSelectedRows(newSelectedRows));
        }
    }, [searchParam]);

    const isRowExpanded = (row: any) => {
        return expandedRows[row.index];
    };

    const handleAccordionClick = (row: any) => () => {
        if (!isAccordionRowEnabled) {
            return;
        }
        if (expandedRows[row.index]) {
            setExpandedRows({ ...expandedRows, [row.index]: false });
        } else {
            setExpandedRows({ ...expandedRows, [row.index]: true });
        }
    };

    const saveTable = () => {
        editParams?.cb(Object.values(tableData));
    };

    const cancelEditTable = () => {
        editParams?.cb([]);
    };

    const handleTableModeEditCell =
        ({ row, cell }: { cell: TableCellRenderer<T>; row: any }) =>
        (newEditValue: any) =>
        () => {
            const updatedRow = { ...row, [cell.id]: newEditValue };
            tableData[row[rowUniqueId as keyof T]] = updatedRow;
        };

    const handleConfirmEdit =
        ({ row, cell }: { cell: TableCellRenderer<T>; row: any }) =>
        (newEditValue: any) =>
        () => {
            setEditMode(false);

            const payload: EditCell = {
                idName: rowUniqueId,
                idValue: row[rowUniqueId as keyof T],
                keyName: cell.id,
                keyValue: newEditValue,
                initialRow: row,
                updatedRow: { ...row, [cell.id]: newEditValue }
            };
            editParams?.cb(payload);
        };

    // used row as any because type error when using row[cell.id]
    const onCellClick =
        ({ row, cell }: { cell: TableCellRenderer<T>; row: any }) =>
        () => {
            setEditMode({ row, cell });
        };

    const handleCheckboxClick = (row: T) => (event: any) => {
        event.stopPropagation();
        if (!selectedRows || !setSelectedRows) {
            return;
        }

        const selectedIndex = selectedRows.findIndex(
            (item) =>
                (item[rowUniqueId as keyof T] as unknown as string) ===
                (row[rowUniqueId as keyof T] as unknown as string)
        );
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }

        dispatch(setSelectedRows(newSelected));
    };

    const isSelected = (row: T) =>
        selectedRows
            ? selectedRows.findIndex(
                  (item) =>
                      (item[rowUniqueId as keyof T] as unknown as string) ===
                      (row[rowUniqueId as keyof T] as unknown as string)
              ) !== -1
            : false;

    const handleSelectAllClick = (event: any) => {
        if (!setSelectedRows) {
            return;
        }

        if (event?.target?.checked) {
            dispatch(setSelectedRows(rows));
            return;
        }

        dispatch(setSelectedRows([]));
    };

    const sortHandler = (cell: TableCellRenderer<T>) => () => {
        if (!cell.sort) {
            return;
        }

        const id = cell.sort_id || cell.id;

        setSort((prev) => ({
            ...prev,
            [id]: prev?.[id] === undefined ? '' : prev?.[id] === '' ? '-' : undefined
        }));
    };

    const renderRow = ({
        isScrolling,
        index,
        style: virtualizedStyle
    }: {
        index: number;
        style: any;
        isScrolling?: boolean;
    }) => {
        const row = rows[index];
        const rowIndex = index;
        const isItemSelected = isSelected(row);
        const isExpanded = isRowExpanded(row);

        if (isScrolling && scrollingPlaceholder) {
            return (
                <Box style={virtualizedStyle}>
                    <Box className={classnames(style.row, style.white, 'genericTableRow', style.loadingRow)}>
                        <Typography variant={'small4'}>{'Loading...'}</Typography>
                    </Box>
                </Box>
            );
        }

        return (
            <Box
                key={row[rowUniqueId as keyof T] as unknown as string}
                className={style.rowContainer}
                style={virtualizedStyle}
            >
                <Box
                    ref={rows.length === rowIndex + 1 ? lastElementRef : null}
                    onClick={handleAccordionClick(row)}
                    className={classnames(
                        style.row,
                        style.white,
                        isItemSelected ? style.selected : '',
                        'genericTableRow',
                        isAccordionRowEnabled ? style.hover : '',
                        isExpanded ? style.expandedTop : '',
                        expandableTableRow?.activeRow === index ? style.expandedTop : ''
                    )}
                >
                    {isCheckboxEnabled ? (
                        <Box className={style.col} sx={{ maxWidth: 50 }}>
                            <Checkbox
                                sx={{ p: 0 }}
                                disableRipple
                                color="primary"
                                checked={isItemSelected}
                                onClick={handleCheckboxClick(row)}
                            />
                        </Box>
                    ) : null}

                    {columns
                        .filter((e) => e.id !== 'accordion')
                        .map((cell, key) => {
                            const customSx = buildCustomSx(cell);
                            const showEditableCell =
                                editMode &&
                                row[rowUniqueId as keyof T] === editMode.row[rowUniqueId as keyof T] &&
                                cell?.id === editMode.cell?.id;

                            return (
                                <Box className={style.col} key={`${key}${rowIndex}`} sx={customSx}>
                                    {cell.value ? (
                                        <>
                                            {cell.editable ? (
                                                showEditableCell || editParams?.tableMode ? (
                                                    <EditableCell
                                                        value={(row as any)[cell.id]}
                                                        cb={
                                                            editParams?.tableMode
                                                                ? handleTableModeEditCell({ row, cell })
                                                                : handleConfirmEdit({ row, cell })
                                                        }
                                                        autoSave={editParams?.autoSave}
                                                        cancel={() => setEditMode(false)}
                                                        tableMode={editParams?.tableMode}
                                                    />
                                                ) : (
                                                    <Box onClick={onCellClick({ row, cell })}>
                                                        {cell.value(row, searchParam, handleEdit, handlers, rowIndex)}
                                                    </Box>
                                                )
                                            ) : (
                                                cell.value(row, searchParam, handleEdit, handlers, rowIndex)
                                            )}
                                        </>
                                    ) : (
                                        <Typography variant="small4">{row[cell.id as keyof T]}</Typography>
                                    )}
                                </Box>
                            );
                        })}

                    {isAccordionRowEnabled ? (
                        <Box className={style.col} sx={{ maxWidth: 50 }}>
                            {isExpanded ? (
                                <Box className={style.accordionIcon} onClick={handleAccordionClick(row)}>
                                    <MdRemoveCircleOutline />
                                </Box>
                            ) : (
                                <Box className={style.accordionIcon} onClick={handleAccordionClick(row)}>
                                    <MdAddCircleOutline />
                                </Box>
                            )}
                        </Box>
                    ) : null}
                </Box>
                {isAccordionRowEnabled && isExpanded && (
                    <Box
                        ref={rows.length === rowIndex + 1 ? lastElementRef : null}
                        className={classnames(
                            style.row,
                            style.white,
                            isItemSelected ? style.selected : '',
                            'genericTableRow',
                            isExpanded ? style.expandedBot : ''
                        )}
                    >
                        {columns
                            .filter((e) => e.id === 'accordion')
                            .map((cell, key) => {
                                return (
                                    <Box className={style.col} key={`${key}${rowIndex}${rowIndex}`}>
                                        {cell.value ? (
                                            cell.value(row, searchParam, handleEdit, handlers, rowIndex)
                                        ) : (
                                            <Typography variant="small4">{row[cell.id as keyof T]}</Typography>
                                        )}
                                    </Box>
                                );
                            })}
                    </Box>
                )}
                {expandableTableRow?.rowsId && expandableTableRow.activeRow === index && (
                    <div className={style.expandableTableRow}>
                        <GenericTable
                            pager={null}
                            rows={row[expandableTableRow.rowsId as keyof T] as unknown as T[]}
                            columns={expandableTableRow.columns}
                            rowUniqueId={'id'}
                            loading={false}
                            success={true}
                            handlers={expandableTableRow.handlers}
                        />
                    </div>
                )}
            </Box>
        );
    };

    if (rows.length === 0 && !loading && success && emptyTablePlaceholder) {
        return (
            <Box className={classnames(style.noValueTable)}>
                <NoValueTable />
            </Box>
        );
    }

    return (
        <>
            <Box className={classnames(style.tableContainer, className, virtualized && style.virtualized)}>
                {editParams?.tableMode && (
                    <Box className={style.editModeToolbar}>
                        <Typography
                            variant="small4"
                            className={classnames(style.button, style.saveButton)}
                            onClick={saveTable}
                        >
                            <SaveIcon />
                        </Typography>
                        <Typography
                            variant="small4"
                            className={classnames(style.button, style.cancelButton)}
                            onClick={cancelEditTable}
                        >
                            <MdEditOff />
                        </Typography>
                    </Box>
                )}
                <Box className={classnames(style.header, 'genericTableHeader')}>
                    <Box className={classnames(style.row, style.headerRow, 'headerRow')}>
                        {isCheckboxEnabled && selectedRows ? (
                            <Box className={style.col} sx={{ maxWidth: 50 }}>
                                <Checkbox
                                    sx={{ p: 0 }}
                                    disableRipple
                                    color="primary"
                                    onClick={handleSelectAllClick}
                                    checked={rows.length > 0 && selectedRows.length === rows.length}
                                    indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
                                />
                            </Box>
                        ) : null}

                        {columns.map((cell, key) => {
                            const customSx = buildCustomSx(cell);

                            return (
                                <Box
                                    key={key}
                                    sx={customSx}
                                    onClick={sortHandler(cell)}
                                    className={classnames(
                                        style.col,
                                        cell.sort ? style.sortable : '',
                                        cell?.className || ''
                                    )}
                                >
                                    <Typography variant="small4" className="tableHeaderCell">
                                        {cell.label}
                                    </Typography>
                                    {cell.tooltip ? (
                                        cell.smallTooltip ? (
                                            <SmallTooltip placement="top" title={cell.tooltip}>
                                                <Box component="span" className={classnames(style.icon, style.center)}>
                                                    <TooltipInfoSvg />
                                                </Box>
                                            </SmallTooltip>
                                        ) : (
                                            <Tooltip placement="top" title={cell.tooltip}>
                                                <Box component="span" className={style.center}>
                                                    <TooltipInfoSvg />
                                                </Box>
                                            </Tooltip>
                                        )
                                    ) : null}
                                    {cell.sort ? (
                                        <SortSvg style={{ marginLeft: '4px', width: 12, height: 12 }} />
                                    ) : null}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>

                <Box
                    className={classnames(
                        style.body,
                        'genericTableBody',
                        customFooter && style.padding,
                        virtualized && style.virtualized
                    )}
                >
                    {virtualized ? (
                        <AutoSizer>
                            {({ height, width }: { height: number; width: number }) => (
                                <List
                                    height={height}
                                    itemCount={rows.length}
                                    itemSize={48}
                                    width={width}
                                    useIsScrolling
                                >
                                    {renderRow}
                                </List>
                            )}
                        </AutoSizer>
                    ) : (
                        rows.map((row, rowIndex) => renderRow({ style: style.none, index: rowIndex }))
                    )}
                    {customFooter && (
                        <Box className={classnames(style.customFooter, 'genericTableCustomFooter')}>
                            <Box key="customFooter" className={classnames(style.row, style.white)}>
                                {columns.map((cell, key) => {
                                    const customSx = buildCustomSx(cell);

                                    return (
                                        <Box
                                            className={classnames(style.col, 'customFooterColumn')}
                                            key={`${key}customFooter`}
                                            sx={customSx}
                                        >
                                            {cell.value ? (
                                                cell.value(customFooter as any, searchParam, handleEdit, handlers)
                                            ) : (
                                                <Typography variant="small4">
                                                    {customFooter[cell.id as any] as any}
                                                </Typography>
                                            )}
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>

            {pagination && <BaseTablePagination loading={loading} />}
        </>
    );
};

export { GenericTable };
