import { TFunction } from 'i18next';
import { Box, SvgIcon } from '@mui/material';

import { VPPFE } from '../../interfaces/uiv2';
import { TableCellRenderer } from '../../interfaces/general';
import { getFormattedCapacityValue } from '../../utils/helpers';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { ReactComponent as VPPSVG } from '../../assets/icons/vpp.svg';
import { DefaultSearchableCell, TableActions } from '../../components/BaseTable';
import { CircleProgressBar } from '../../components/BaseTable/CustomCells/CircleProgressBar';

import style from './style.module.scss';

enum COLUMN_ID {
    INDEX = 'index',
    VPP_NAME = 'vpp_name',
    TYPE = 'vpp_type',
    TOTAL_CAPACITY = 'capacity_value',
    FORECAST_HOUR = 'forecast_hour',
    FORECAST_DAY = 'forecast_day',
    FORECAST_WEEK = 'forecast_week',
    ACCURACY = 'accuracy',
    ACTIONS = 'actions'
}

interface AssetListHeadCellsProps {
    tList: TFunction;
}

export const prosumersVpp = {
    index: 0,
    id: 'prosumers',
    name: 'OGRE Prosumers',
    user_id: '928c99ed-8983-4f01-86c1-5f1ced122f6e',
    is_implicit: true,
    is_mock: true,
    created_at: '2023-03-21T15:21:52.006866',
    updated_at: '2023-04-28T12:04:37.088342',
    deleted_at: '2023-04-28T12:04:37.088342',
    forecasted_day: 700,
    forecasted_hour: 50,
    forecasted_week: 3500,
    total_capacity: 5218,
    accuracy: 0.97,
    latest_forecast: [
        3197.622648477554, 4208.312818288803, 6071.404330790043, 5852.910547435284, 4826.582369923592, 3899.19509267807,
        2143.124647259712
    ]
};

export const staticVpp = {
    index: 0,
    id: 'demo',
    name: 'Demo  VPP',
    user_id: '928c99ed-8983-4f01-86c1-5f1ced122f6e',
    is_implicit: true,
    is_mock: true,
    created_at: '2023-03-21T15:21:52.006866',
    updated_at: '2023-04-28T12:04:37.088342',
    deleted_at: '2023-04-28T12:04:37.088342',
    forecasted_day: 3600,
    forecasted_hour: 200,
    forecasted_week: 1650,
    total_capacity: 48000,
    accuracy: 0.96,
    latest_forecast: [
        4197.622648477554, 4208.312818288803, 5071.404330790043, 5852.910547435284, 4226.582369923592, 2899.19509267807,
        4143.124647259712
    ]
};

export const getTableHeadCellsVPPAssets = ({ tList }: AssetListHeadCellsProps): TableCellRenderer<VPPFE>[] => {
    return [
        {
            id: COLUMN_ID.INDEX,
            maxWidth: 40,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: COLUMN_ID.TYPE,
            maxWidth: 75,
            label: tList('tableHead.type'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                return (
                    <Box sx={{ display: 'flex' }}>
                        <SvgIcon component={VPPSVG} sx={{ '> path': row.is_implicit ? { fill: '#E5253D' } : {} }} />
                    </Box>
                );
            }
        },
        {
            id: COLUMN_ID.VPP_NAME,
            label: tList('tableHead.name'),
            sort: true,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.name;
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;

                return (
                    <Box sx={{ display: 'flex' }}>
                        <DefaultSearchableCell value={value} searchValue={searchValue} isLink linkUrl={callback()} />
                    </Box>
                );
            }
        },
        {
            id: COLUMN_ID.TOTAL_CAPACITY,
            label: tList('tableHead.totalCapacity'),
            tooltip: tList('totalCapacityTooltip'),
            smallTooltip: true,
            justifyContent: 'center',
            sort: false,
            value: (row, searchValue) => {
                const value = getFormattedCapacityValue(row.total_capacity);
                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            }
        },
        {
            id: COLUMN_ID.FORECAST_HOUR,
            label: tList('tableHead.forecastedHour'),
            tooltip: tList('forecastedHourTooltip'),
            smallTooltip: true,
            justifyContent: 'center',
            sort: false,
            value: (row, searchValue) => {
                const value = getFormattedCapacityValue(row.forecasted_hour, 'MWh');
                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            }
        },
        {
            id: COLUMN_ID.FORECAST_DAY,
            label: tList('tableHead.forecastedDay'),
            tooltip: tList('forecastedDayTooltip'),
            smallTooltip: true,
            justifyContent: 'center',
            sort: false,
            value: (row, searchValue) => {
                const value = getFormattedCapacityValue(row.forecasted_day, 'MWh');
                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            }
        },
        {
            id: COLUMN_ID.FORECAST_WEEK,
            label: tList('tableHead.forecastedWeek'),
            tooltip: tList('forecastedWeekTooltip'),
            smallTooltip: true,
            justifyContent: 'center',
            sort: false,
            value: (row, searchValue) => {
                const value = getFormattedCapacityValue(row.forecasted_week, 'MWh');
                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            }
        },
        {
            id: COLUMN_ID.ACCURACY,
            label: tList('tableHead.accuracy'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const value = +(row.accuracy || 0) * 100;
                return <CircleProgressBar value={value} />;
            }
        },
        {
            id: COLUMN_ID.ACTIONS,
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('tableHead.actions'),
            value: (row, searchValue, onClick, handlers) => {
                const edit = handlers?.['edit'];
                const deleteRow = handlers?.['deleteRow'];
                const actions = [];
                if (!row.is_implicit && !row.is_mock) {
                    actions.push(
                        {
                            id: 'edit',
                            label: 'table.general.editInfo',
                            disabled: false,
                            callback: () => {
                                edit(row)();
                            }
                        },
                        {
                            id: 'delete',
                            label: 'table.general.delete',
                            disabled: false,
                            callback: () => {
                                deleteRow(row)();
                            }
                        }
                    );
                }

                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions className={style.editActionStyling} actions={actions} />
                    </Box>
                );
            }
        }
    ];
};

export const staticVPPAssets = [
    {
        index: 1,
        generation_forecast_models_created_at: '2023-02-06T14:59:02.452289',
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'ORI Icland',
        latitude: 46.6087,
        longitude: 24.71,
        timezone: 'Europe/Bucharest',
        location: 'Icland',
        country_code: 'ro',
        type: 'Solar',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: '2021-01-01T00:00:00',
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '57d48994-94b1-4596-99dc-972f7179643c',
        first_generation_timestamp: '2020-12-31T22:00:00',
        first_forecast_timestamp: '2022-07-27T12:00:00',
        last_generation_timestamp: '2023-02-28T21:45:00',
        last_forecast_timestamp: '2023-05-08T07:00:00',
        accuracy: 0.97,
        latest_forecast: [
            4197.622648477554, 4208.312818288803, 5071.404330790043, 5852.910547435284, 4226.582369923592,
            2899.19509267807, 4143.124647259712
        ],
        capacity_value: 987,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:40:06.082070',
        updated_at: '2023-01-31T08:40:06.082072',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: {
            created_at: '2023-02-06T14:59:02.452289'
        }
    },
    {
        index: 2,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'Kastro Lykovouni',
        latitude: 38.407039,
        longitude: 23.457582,
        timezone: 'Europe/Athens',
        location: 'Bucharest',
        country_code: 'ro',
        type: 'Wind',
        status: 'Active',
        meta: {},
        installation_date: '2022-11-08T22:00:00',
        expiry_date: '2023-11-08T22:00:00',
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '01a67a78-d95b-472b-af46-619e4ac33127',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4497, 4498, 4798, 4856, 4603, 4105, 4372],
        capacity_value: 700,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:52:08.626184',
        updated_at: '2023-01-31T08:52:08.626186',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 3,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'Riganolakas',
        latitude: 38.386096,
        longitude: 21.719609,
        timezone: 'Europe/Athens',
        location: 'Oradea',
        country_code: 'ro',
        type: 'Wind',
        status: 'Active',
        meta: {},
        installation_date: '2022-11-08T22:00:00',
        expiry_date: '2023-11-08T22:00:00',
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '0fb313b80-fc05-4532-98ef-9a432490cc44',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4064, 4296, 4574, 4924, 4194, 2899, 4150],
        capacity_value: 650,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:52:09.075210',
        updated_at: '2023-01-31T08:52:09.075211',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 4,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'LJG GREEN SOURCE ENERGY GAMMA S.R.L. ',
        latitude: 43.826849,
        longitude: 25.826284,
        timezone: 'Europe/Bucharest',
        location: 'Slobozia',
        country_code: 'ro',
        type: 'Solar',
        status: 'Disabled',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '29a26f24-b1db-422c-ab73-d40ca45e21b2',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4576, 4871, 5085, 5129, 4653, 3124, 4439],
        capacity_value: 650,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:13.611332',
        updated_at: '2023-01-31T08:40:13.611334',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 5,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'Cogealac',
        latitude: 44.558044,
        longitude: 28.504914,
        timezone: 'Europe/Bucharest',
        location: 'Grădina',
        country_code: 'ro',
        type: 'Wind',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: true,
        id: 'c7407939-1053-4595-acfd-bb8d33cfd370',
        first_generation_timestamp: '2018-01-01T00:00:00',
        first_forecast_timestamp: '2022-09-07T08:00:00',
        last_generation_timestamp: '2023-02-23T14:30:00',
        last_forecast_timestamp: '2023-02-28T12:00:00',
        accuracy: 0.99,
        latest_forecast: [4171, 4403, 5014, 5434, 4091, 3266, 4462],
        capacity_value: 5550,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:02.088372',
        updated_at: '2023-01-31T08:40:02.088373',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 6,
        generation_forecast_models_created_at: '2023-02-06T14:59:02.452289',
        client_id: '6-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'Ashland Energy Station',
        Latitude: 44.435442,
        Longitude: 26.099255,
        timezone: 'Europe/Bucharest',
        location: 'Icland',
        country_code: 'ro',
        type: 'Coal',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: '2021-01-01T00:00:00',
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '57d9048994-94b1-4596-99dc-972f7179643c',
        first_generation_timestamp: '2020-12-31T22:00:00',
        first_forecast_timestamp: '2022-07-27T12:00:00',
        last_generation_timestamp: '2023-02-28T21:45:00',
        last_forecast_timestamp: '2023-05-08T07:00:00',
        accuracy: 0.99,
        latest_forecast: [4659, 4941, 5146, 5489, 4752, 3582, 4006],
        capacity_value: 987,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:40:06.082070',
        updated_at: '2023-01-31T08:40:06.082072',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: {
            created_at: '2023-02-06T14:59:02.452289'
        }
    },
    {
        index: 7,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-2-4dc4-84b5-bc1f0bacba25',
        name: 'Thunderfall Hydro Station',
        Latitude: 45.652781,
        Longitude: 25.610819,
        timezone: 'Europe/Athens',
        location: 'Bucharest',
        country_code: 'ro',
        type: 'Hydro',
        status: 'Active',
        meta: {},
        installation_date: '2022-11-08T22:00:00',
        expiry_date: '2023-11-08T22:00:00',
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '01a67a78-d9we5b-472b-af46-619e4ac33127',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4348, 4599, 4881, 5260, 4163, 2955, 4412],
        capacity_value: 700,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:52:08.626184',
        updated_at: '2023-01-31T08:52:08.626186',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 8,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-7-bc1f0bacba25',
        name: 'Riganolakas',
        latitude: 38.386096,
        longitude: 21.719609,
        timezone: 'Europe/Athens',
        location: 'Oradea',
        country_code: 'ro',
        type: 'Biomass',
        status: 'Active',
        meta: {},
        installation_date: '2022-11-08T22:00:00',
        expiry_date: '2023-11-08T22:00:00',
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '0fb13b80-fc05-4532-98ef-9a432490cc44',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4755, 5049, 5233, 5425, 4650, 3443, 4075],
        capacity_value: 650,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:52:09.075210',
        updated_at: '2023-01-31T08:52:09.075211',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 9,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-9-bc1f0bacba25',
        name: 'AtomPeak Power Station',
        latitude: 43.826849,
        longitude: 25.826284,
        timezone: 'Europe/Bucharest',
        location: 'Slobozia',
        country_code: 'ro',
        type: 'Nuclear',
        status: 'Disabled',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '29a234566f24-b1db-422c-ab73-d40ca45e21b2',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4053, 4269, 4535, 4811, 4189, 3280, 4153],
        capacity_value: 650,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:13.611332',
        updated_at: '2023-01-31T08:40:13.611334',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 10,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-23-84b5-bc1f0bacba25',
        name: 'ThermalPeak Energy Station',
        latitude: 44.558044,
        longitude: 28.504914,
        timezone: 'Europe/Bucharest',
        location: 'Grădina',
        country_code: 'ro',
        type: 'Geothermal',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: true,
        id: 'c7407939-1053-4595-acfd-453',
        first_generation_timestamp: '2018-01-01T00:00:00',
        first_forecast_timestamp: '2022-09-07T08:00:00',
        last_generation_timestamp: '2023-02-23T14:30:00',
        last_forecast_timestamp: '2023-02-28T12:00:00',
        accuracy: 0.99,
        latest_forecast: [4644, 4913, 5052, 5332, 4515, 3634, 4194],
        capacity_value: 6550,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:02.088372',
        updated_at: '2023-01-31T08:40:02.088373',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 11,
        generation_forecast_models_created_at: '2023-02-06T14:59:02.452289',
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'EnergyMax Enterprises',
        latitude: 46.6087,
        longitude: 24.71,
        timezone: 'Europe/Bucharest',
        location: 'Icland',
        country_code: 'ro',
        type: 'Other',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: '2021-01-01T00:00:00',
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '57d48994-32-4596-99dc-972f7179643c',
        first_generation_timestamp: '2020-12-31T22:00:00',
        first_forecast_timestamp: '2022-07-27T12:00:00',
        last_generation_timestamp: '2023-02-28T21:45:00',
        last_forecast_timestamp: '2023-05-08T07:00:00',
        accuracy: 0.99,
        latest_forecast: [4310, 4545, 4804, 5172, 4113, 3162, 4319],
        capacity_value: 987,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:40:06.082070',
        updated_at: '2023-01-31T08:40:06.082072',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: {
            created_at: '2023-02-06T14:59:02.452289'
        }
    },
    {
        index: 12,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'Kastro Lykovouni',
        latitude: 38.407039,
        longitude: 23.457582,
        timezone: 'Europe/Athens',
        location: 'Bucharest',
        country_code: 'ro',
        type: 'Gas',
        status: 'Active',
        meta: {},
        installation_date: '2022-11-08T22:00:00',
        expiry_date: '2023-11-08T22:00:00',
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '34-d95b-472b-af46-619e4ac33127',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4686, 4969, 5149, 5451, 4690, 3544, 4120],
        capacity_value: 700,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:52:08.626184',
        updated_at: '2023-01-31T08:52:08.626186',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 13,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'Emberburn Coal Plant',
        latitude: 38.386096,
        longitude: 21.719609,
        timezone: 'Europe/Athens',
        location: 'Oradea',
        country_code: 'ro',
        type: 'Coal',
        status: 'Active',
        meta: {},
        installation_date: '2022-11-08T22:00:00',
        expiry_date: '2023-11-08T22:00:00',
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '545-fc05-4532-98ef-9a432490cc44',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4055, 4259, 4555, 4856, 4097, 3444, 4307],
        capacity_value: 650,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:52:09.075210',
        updated_at: '2023-01-31T08:52:09.075211',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 14,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'LJG GREEN SOURCE ENERGY GAMMA S.R.L. ',
        latitude: 43.826849,
        longitude: 25.826284,
        timezone: 'Europe/Bucharest',
        location: 'Slobozia',
        country_code: 'ro',
        type: 'Biomass',
        status: 'Disabled',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '65675-b1db-422c-ab73-d40ca45e21b2',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4620, 4945, 5102, 5305, 4544, 3226, 4084],
        capacity_value: 650,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:13.611332',
        updated_at: '2023-01-31T08:40:13.611334',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 15,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'EnerCorp Solutions',
        latitude: 44.558044,
        longitude: 28.504914,
        timezone: 'Europe/Bucharest',
        location: 'Grădina',
        country_code: 'ro',
        type: 'Other',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: true,
        id: '7674-1053-4595-acfd-bb8d33cfd370',
        first_generation_timestamp: '2018-01-01T00:00:00',
        first_forecast_timestamp: '2022-09-07T08:00:00',
        last_generation_timestamp: '2023-02-23T14:30:00',
        last_forecast_timestamp: '2023-02-28T12:00:00',
        accuracy: 0.99,
        latest_forecast: [4287, 4522, 4803, 5110, 4186, 3127, 4393],
        capacity_value: 7050,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:02.088372',
        updated_at: '2023-01-31T08:40:02.088373',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 16,
        generation_forecast_models_created_at: '2023-02-06T14:59:02.452289',
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'PowerTech Industries',
        latitude: 46.6087,
        longitude: 24.71,
        timezone: 'Europe/Bucharest',
        location: 'Icland',
        country_code: 'ro',
        type: 'Other',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: '2021-01-01T00:00:00',
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '334556-94b1-4596-99dc-972f7179643c',
        first_generation_timestamp: '2020-12-31T22:00:00',
        first_forecast_timestamp: '2022-07-27T12:00:00',
        last_generation_timestamp: '2023-02-28T21:45:00',
        last_forecast_timestamp: '2023-05-08T07:00:00',
        accuracy: 0.99,
        latest_forecast: [4522, 4806, 4937, 5233, 4729, 3882, 4307],
        capacity_value: 987,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:40:06.082070',
        updated_at: '2023-01-31T08:40:06.082072',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: {
            created_at: '2023-02-06T14:59:02.452289'
        }
    },
    {
        index: 17,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'Kastro Lykovouni',
        latitude: 38.407039,
        longitude: 23.457582,
        timezone: 'Europe/Athens',
        location: 'Bucharest',
        country_code: 'ro',
        type: 'Consumer',
        status: 'Active',
        meta: {},
        installation_date: '2022-11-08T22:00:00',
        expiry_date: '2023-11-08T22:00:00',
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '01a6437a78-d95b-472b-af46-619e4ac33127',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4122, 4355, 4650, 4970, 4252, 3445, 4169],
        capacity_value: 700,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:52:08.626184',
        updated_at: '2023-01-31T08:52:08.626186',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 18,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'Riganolakas',
        latitude: 38.386096,
        longitude: 21.719609,
        timezone: 'Europe/Athens',
        location: 'Oradea',
        country_code: 'ro',
        type: 'Consumer',
        status: 'Active',
        meta: {},
        installation_date: '2022-11-08T22:00:00',
        expiry_date: '2023-11-08T22:00:00',
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '0fb13b340-fc05-4532-98ef-9a432490cc44',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4702, 4985, 5073, 5365, 4592, 3781, 4361],
        capacity_value: 650,
        measure_unit: 'Energy',
        created_at: '2023-01-31T08:52:09.075210',
        updated_at: '2023-01-31T08:52:09.075211',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 19,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'LJG GREEN SOURCE ENERGY GAMMA S.R.L. ',
        latitude: 43.826849,
        longitude: 25.826284,
        timezone: 'Europe/Bucharest',
        location: 'Slobozia',
        country_code: 'ro',
        type: 'Consumer',
        status: 'Disabled',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: false,
        id: '29a2623f24-b1db-422c-ab73-d40ca45e21b2',
        first_generation_timestamp: null,
        first_forecast_timestamp: null,
        last_generation_timestamp: null,
        last_forecast_timestamp: null,
        accuracy: 0.99,
        latest_forecast: [4401, 4607, 4913, 5232, 4205, 3245, 3997],
        capacity_value: 650,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:13.611332',
        updated_at: '2023-01-31T08:40:13.611334',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 20,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'Aquaridge Dam',
        latitude: 44.558044,
        longitude: 28.504914,
        timezone: 'Europe/Bucharest',
        location: 'Grădina',
        country_code: 'ro',
        type: 'Hydro',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: true,
        id: 'c740712939-1053-4595-acfd-bb8d33cfd370',
        first_generation_timestamp: '2018-01-01T00:00:00',
        first_forecast_timestamp: '2022-09-07T08:00:00',
        last_generation_timestamp: '2023-02-23T14:30:00',
        last_forecast_timestamp: '2023-02-28T12:00:00',
        accuracy: 0.99,
        latest_forecast: [4655, 4924, 5053, 5376, 4702, 3921, 4456],
        capacity_value: 10050,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:02.088372',
        updated_at: '2023-01-31T08:40:02.088373',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null
    },
    {
        index: 21,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'AlphaTech',
        latitude: 44.558044,
        longitude: 28.504914,
        timezone: 'Europe/Bucharest',
        location: 'Cluj Napoca',
        country_code: 'ro',
        type: 'Prosumer',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: true,
        id: 'c740712939-1053-4595-acfd-bb8d33cfd370',
        first_generation_timestamp: '2018-01-01T00:00:00',
        first_forecast_timestamp: '2022-09-07T08:00:00',
        last_generation_timestamp: '2023-02-23T14:30:00',
        last_forecast_timestamp: '2023-02-28T12:00:00',
        accuracy: 0.99,
        latest_forecast: [4655, 5376, 4702, 3921, 4456, 4924, 5053],
        capacity_value: 11050,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:02.088372',
        updated_at: '2023-01-31T08:40:02.088373',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null,
        total_capacity: 110
    },
    {
        index: 22,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'PowerPro ',
        latitude: 44.558044,
        longitude: 28.504914,
        timezone: 'Europe/Bucharest',
        location: 'Cluj Napoca',
        country_code: 'ro',
        type: 'Prosumer',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: true,
        id: 'c740712939-1053-4595-acfd-bb8d33cfd370',
        first_generation_timestamp: '2018-01-01T00:00:00',
        first_forecast_timestamp: '2022-09-07T08:00:00',
        last_generation_timestamp: '2023-02-23T14:30:00',
        last_forecast_timestamp: '2023-02-28T12:00:00',
        accuracy: 0.99,
        latest_forecast: [5053, 5376, 4655, 4924, 4702, 3921, 4456],
        capacity_value: 12050,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:02.088372',
        updated_at: '2023-01-31T08:40:02.088373',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null,
        total_capacity: 24
    },
    {
        index: 23,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'Nexus Systems',
        latitude: 44.558044,
        longitude: 28.504914,
        timezone: 'Europe/Bucharest',
        location: 'Cluj Napoca',
        country_code: 'ro',
        type: 'Prosumer',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: true,
        id: 'c740712939-1053-4595-acfd-bb8d33cfd370',
        first_generation_timestamp: '2018-01-01T00:00:00',
        first_forecast_timestamp: '2022-09-07T08:00:00',
        last_generation_timestamp: '2023-02-23T14:30:00',
        last_forecast_timestamp: '2023-02-28T12:00:00',
        accuracy: 0.99,
        latest_forecast: [5376, 4702, 3921, 4456, 4655, 4924, 5053],
        capacity_value: 13050,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:02.088372',
        updated_at: '2023-01-31T08:40:02.088373',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null,
        total_capacity: 140
    },
    {
        index: 24,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'EcoBlend',
        latitude: 44.558044,
        longitude: 28.504914,
        timezone: 'Europe/Bucharest',
        location: 'Cluj Napoca',
        country_code: 'ro',
        type: 'Prosumer',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: true,
        id: 'c740712939-1053-4595-acfd-bb8d33cfd370',
        first_generation_timestamp: '2018-01-01T00:00:00',
        first_forecast_timestamp: '2022-09-07T08:00:00',
        last_generation_timestamp: '2023-02-23T14:30:00',
        last_forecast_timestamp: '2023-02-28T12:00:00',
        accuracy: 0.99,
        latest_forecast: [3921, 4655, 5053, 5376, 4702, 3921, 4456, 4924],
        capacity_value: 11050,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:02.088372',
        updated_at: '2023-01-31T08:40:02.088373',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null,
        total_capacity: 27
    },
    {
        index: 25,
        generation_forecast_models_created_at: null,
        client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
        name: 'Greentech',
        latitude: 44.558044,
        longitude: 28.504914,
        timezone: 'Europe/Bucharest',
        location: 'Cluj Napoca',
        country_code: 'ro',
        type: 'Prosumer',
        status: 'Active',
        meta: {},
        installation_date: null,
        expiry_date: null,
        training_start: null,
        meteo_start_date: null,
        manufacturer: '',
        is_mock: true,
        has_realtime_data: true,
        id: 'c740712939-1053-4595-acfd-bb8d33cfd370',
        first_generation_timestamp: '2018-01-01T00:00:00',
        first_forecast_timestamp: '2022-09-07T08:00:00',
        last_generation_timestamp: '2023-02-23T14:30:00',
        last_forecast_timestamp: '2023-02-28T12:00:00',
        accuracy: 0.99,
        latest_forecast: [4924, 4655, 5376, 4702, 3921, 5053, 4456],
        capacity_value: 12050,
        measure_unit: 'Power',
        created_at: '2023-01-31T08:40:02.088372',
        updated_at: '2023-01-31T08:40:02.088373',
        client: {
            id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
            name: 'OGRE'
        },
        generation_forecast_models: null,
        total_capacity: 19
    }
];
