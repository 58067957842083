import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';

import { DefaultInitialState } from '../../interfaces/redux';
import { ForecastSettingsV2, PostForecastSettingsV2 } from '../../interfaces/apiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';

interface GenerationAssetForecastSettingsState extends DefaultInitialState {}

const initialState: GenerationAssetForecastSettingsState = {
    loading: false,
    error: false,
    success: false
};

export const createGenerationForecastSettingsV2Async = createAsyncThunk<
    ForecastSettingsV2,
    { assetId: string; body: PostForecastSettingsV2 }
>('generationAssetForecastSettingsV2/create', async ({ assetId, body }, { rejectWithValue }) => {
    try {
        const response = await axios.post<ForecastSettingsV2>(
            `/electricity/generation/assets/${assetId}/forecast-settings`,
            body
        );

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const updateGenerationForecastSettingsV2Async = createAsyncThunk<
    ForecastSettingsV2,
    { assetId: string; forecastSettingsId: string; body: PostForecastSettingsV2 }
>('generationAssetForecastSettingsV2/update', async ({ assetId, forecastSettingsId, body }, { rejectWithValue }) => {
    try {
        const response = await axios.patch<ForecastSettingsV2>(
            `/electricity/generation/assets/${assetId}/forecast-settings/${forecastSettingsId}`,
            body
        );

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const generationAssetForecastSettingsV2 = createSlice({
    name: 'generationAssetForecastSettingsV2',
    initialState,
    reducers: {
        resetGenerationAssetForecastSettingsStateV2: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createGenerationForecastSettingsV2Async.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(createGenerationForecastSettingsV2Async.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(createGenerationForecastSettingsV2Async.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(updateGenerationForecastSettingsV2Async.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(updateGenerationForecastSettingsV2Async.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(updateGenerationForecastSettingsV2Async.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetGenerationAssetForecastSettingsStateV2 } = generationAssetForecastSettingsV2.actions;

export default generationAssetForecastSettingsV2.reducer;
