import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SyntheticEvent, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Typography } from '@mui/material';

import { useGetAssetQuery } from '../../stores/Assets';
import { getAssetIcon } from '../../utils/getAssetIcon';
import { PageContainer } from '../../layout/PageContainer';
import GenerationAssetForm from '../../components/GenerationAssetForm/GenerationAssetForm';
import { ReactComponent as DetailsIconSVG } from '../../assets/icons/details-settings.svg';
import { ReactComponent as ForecastIconSVG } from '../../assets/icons/forecast-settings.svg';
import GenerationAssetForecastSettings from '../../components/GenerationAssetForecastSettings/GenerationAssetForecastSettings';

import style from './style.module.scss';

const AdminForecastingPage = () => {
    const { t } = useTranslation('assets/forecast-settings');

    const { assetId } = useParams();
    const [tab, setTab] = useState('1');
    const { data: asset } = useGetAssetQuery({ assetId: assetId }, { skip: !assetId });

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };

    const renderTabLabel = (label: string) => {
        return (
            <Box className={style.tabLabel}>
                <Typography variant="h7">{label}</Typography>
                <Box className={classNames(style.underline, 'underline')}></Box>
            </Box>
        );
    };

    return (
        <Box className={style.pageContainerWrapper}>
            <PageContainer>
                {asset ? (
                    <>
                        <></>
                        <Stack direction="row" alignItems="center" justifyContent="center" className={style.header}>
                            {getAssetIcon(asset.type)}
                            <Typography variant="h6" className={style.pageTitle}>
                                {asset.name}
                            </Typography>
                        </Stack>
                        <Box className={style.container}>
                            <TabContext value={tab}>
                                <Box className={style.tabsHeader}>
                                    <TabList onChange={handleTabChange} aria-label="tabs">
                                        <Tab
                                            icon={<DetailsIconSVG />}
                                            iconPosition="start"
                                            label={renderTabLabel(t('details'))}
                                            value="1"
                                        />
                                        <Tab
                                            icon={<ForecastIconSVG />}
                                            iconPosition="start"
                                            label={renderTabLabel(t('forecasts'))}
                                            value="2"
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <GenerationAssetForm asset={asset} type={asset.type} />
                                </TabPanel>
                                <TabPanel value="2">
                                    <GenerationAssetForecastSettings asset={asset} />
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </>
                ) : (
                    <>
                        <Stack direction="row" alignItems="center" justifyContent="center" className={style.header}>
                            <Typography variant="h6" className={style.pageTitle}>
                                {t('pageTitle')}
                            </Typography>
                        </Stack>
                        <Box className={style.container}>form here</Box>
                    </>
                )}
            </PageContainer>
        </Box>
    );
};

export default AdminForecastingPage;
