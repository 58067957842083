import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { ProjectsData } from '../../interfaces/apiv2';
import { PatchProjects } from '../../interfaces/uiv2';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';

interface UpdateProjectsState extends DefaultInitialState {
    updated: number;
}

const initialState: UpdateProjectsState = {
    loading: false,
    error: false,
    success: false,
    updated: 0
};

export const updateProjectsAsync = createAsyncThunk(
    'projects/update',
    async (values: PatchProjects, { rejectWithValue }) => {
        try {
            const response = await axios.patch<ProjectsData, AxiosResponse<ProjectsData>, PatchProjects>(
                `/projects/${values.id}`,
                values
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const updateProjects = createSlice({
    name: 'updateProjects',
    initialState,
    reducers: {
        resetUpdateProjectsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(updateProjectsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(updateProjectsAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.updated += 1;
        });
        builder.addCase(updateProjectsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetUpdateProjectsState } = updateProjects.actions;

export default updateProjects.reducer;
