import { TFunction } from 'i18next';
import * as yup from 'yup';
import {
    AssetLocationFE,
    AssetLocationsFormFields,
    PatchAssetLocationsBody,
    PostAssetLocationsBody
} from '../../interfaces/uiv2';

interface FormValues {
    [key: string]: any;
}

export const getAssetLocationsSchema = (t: TFunction) => {
    const baseSchema = {
        latitude: yup
            .number()
            .required(t('validations.required'))
            .test('customTest', 'invalidCoordinate', (value) => {
                const isLatitudeValid = new RegExp(/^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})$/);
                return isLatitudeValid.test(value as any);
            }),
        longitude: yup
            .number()
            .required(t('validations.required'))
            .test('customTest', 'invalidCoordinate', (value) => {
                const isLongitudeValid = new RegExp(/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})$/);
                return isLongitudeValid.test(value as any);
            })
    };

    return yup.object<Record<keyof FormValues, yup.AnySchema>>({ ...baseSchema }).required();
};
export const transformAssetLocationsFormDataToPathBody = (
    formData: AssetLocationsFormFields,
    data: AssetLocationFE,
    assetId: string
): PatchAssetLocationsBody => {
    return { body: { ...formData }, assetId, id: data.id };
};
export const transformAssetLocationsFormDataToPostBody = (
    formData: AssetLocationsFormFields,
    assetId: string
): PostAssetLocationsBody => {
    return {
        ...formData,
        assetId: assetId
    };
};

const defaultValues = {
    longitude: '',
    latitude: ''
};

export const getAssetLocationsFormDataDefaultValue = (data?: AssetLocationFE) => {
    if (data) {
        return { longitude: data.longitude, latitude: data.latitude };
    }
    return defaultValues;
};
