import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, InputLabel, Typography } from '@mui/material';

import { UserPermissions } from '../../interfaces/apiv2';

import AdminSvg from '../../assets/icons/permissions/admin.svg';
import GenerationSvg from '../../assets/icons/permissions/generation.svg';
import ElectricitySvg from '../../assets/icons/permissions/electricity.svg';
import GasSvg from '../../assets/icons/permissions/gas.svg';
import DemandIcon from '../../assets/icons/navigation-items/menu-item-demand.svg';
import EVSIcon from '../../assets/icons/navigation-items/menu-item-evs.svg';
import ChargingStationsSvg from '../../assets/icons/map/station.svg';

import style from './style.module.scss';

const MODULE_ICONS = {
    Electricity: ElectricitySvg,
    Gas: GasSvg,
    Admin: AdminSvg,
    EVS: EVSIcon
};

const SUBMODULE_ICONS = {
    Electricity: {
        Generation: GenerationSvg,
        Demand: DemandIcon,
        DeliveryTemplate: GenerationSvg
    },
    Admin: {
        Users: AdminSvg,
        Clients: AdminSvg,
        TrainingAssets: AdminSvg,
        Assets: AdminSvg,
        Support: AdminSvg,
        Projects: AdminSvg
    },
    Gas: {
        GasDemand: DemandIcon
    },
    EVS: {
        ChargingStations: ChargingStationsSvg,
        ChargingPoints: ChargingStationsSvg
    }
};

export const AppPermissions = ({ control, permissions }: { control: any; permissions: UserPermissions }) => {
    const { t: generalTranslation } = useTranslation();

    const modules = Object.keys(permissions);

    return (
        <Box>
            {modules.map((module, key) => {
                const subModules = Object.keys(permissions[module as keyof typeof permissions]);

                return (
                    <Accordion key={key} defaultExpanded={false} className={style.wrapper}>
                        <AccordionSummary
                            id="module-permissions-header"
                            expandIcon={<MdRemoveCircleOutline />}
                            sx={{ fontWeight: 'bold', margin: 0 }}
                            aria-controls="module-permissions-content"
                            classes={{
                                root: style.summaryRoot,
                                expanded: style.summaryExpanded,
                                content: style.content
                            }}
                        >
                            <img src={MODULE_ICONS[module as keyof typeof MODULE_ICONS]} alt={module} />
                            <Typography variant="small1">{generalTranslation(`appPermissions.${module}`)}</Typography>
                        </AccordionSummary>

                        <AccordionDetails classes={{ root: style.detailsRoot }}>
                            {subModules.map((subModule, pKey) => (
                                <Typography variant="small1" key={pKey} className={style.permission}>
                                    <Controller
                                        name={`permissions.${module}.${subModule}`}
                                        control={control}
                                        defaultValue={false}
                                        render={({ field }) => (
                                            <Checkbox
                                                {...field}
                                                id={subModule}
                                                checked={field.value}
                                                className={'permission-checkbox'}
                                                icon={<CheckIcon />}
                                                checkedIcon={<CheckIcon />}
                                            />
                                        )}
                                    />

                                    <InputLabel shrink className={style.label} htmlFor={subModule}>
                                        <img
                                            // eslint-disable-next-line
                                            // @ts-ignore
                                            src={SUBMODULE_ICONS[module][subModule]}
                                            alt={subModule}
                                            className={style.icon}
                                        />

                                        <Typography variant="small1" component="span" className={style.bold}>
                                            {generalTranslation(`appPermissionsSubModules.${module}.${subModule}`)}
                                        </Typography>
                                    </InputLabel>
                                </Typography>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
};
