import { FC } from 'react';
import style from './Card.module.scss';

// Utils
import { DashboardCardProps, DashboardCardType } from '../../config';
import classNames from 'classnames';

// Components
import { Box, Typography } from '@mui/material';
import Value from '../Value';

interface CardProps {
    type: DashboardCardType;
    card: DashboardCardProps;
    groupData: any;
}

const Card: FC<CardProps> = ({ type, card, groupData }) => {
    const { cardKey, icon, title, data } = card;
    const multipleData = Array.isArray(data);

    const cardData = groupData?.[cardKey];

    return (
        <Box
            className={classNames(
                style.container,
                style[type],
                !multipleData && style.noMinHeight,
                !cardData && style.disabled
            )}
        >
            <Box className={style.header}>
                {icon}
                <Typography variant="small1">{title}</Typography>
            </Box>
            {multipleData ? (
                <Box className={classNames(style.content, style[type])}>
                    {data.map(({ dataKey, title, unit }) => (
                        <Box key={dataKey} className={style.info}>
                            <Typography className={style.title} variant="small4">
                                {title}
                            </Typography>
                            <Value variant={type === 'row' ? 'small3' : 'h5'} value={cardData?.[dataKey]} unit={unit} />
                        </Box>
                    ))}
                </Box>
            ) : (
                <Box className={classNames(style.content, style[type])}>
                    <Value variant="h5" value={cardData?.[data.dataKey]} unit={data.unit} />
                </Box>
            )}
        </Box>
    );
};

export default Card;
