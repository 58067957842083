import { debounce } from 'lodash';
import classNames from 'classnames';
import { Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Autocomplete, Box, InputLabel, TextField } from '@mui/material';

import Loading from '../../../layout/Loading';
import { useHideInput } from '../../../hooks/useHideInput';
import { AutocompletesAction, AutocompletesState } from './interfaces';
import { useGetChargingPointForecastSettingsQuery } from '../../../stores/EVS';
import { ChargingPointForecastSettings } from '../../../interfaces/apiv2/evs/charging_point_forecast_settings';

import style from '../style.module.scss';

export const EVSReportCPFSAutocomplete = ({
    state,
    dispatch,
    projectId,
    chargingPointId
}: {
    state: AutocompletesState;
    dispatch: Dispatch<AutocompletesAction>;
    projectId: string;
    chargingPointId: string;
}) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState<string | undefined>(undefined);
    const { data: chargingPointFS, isLoading } = useGetChargingPointForecastSettingsQuery({
        search: search,
        page: 1,
        size: 100,
        projectId,
        chargingPointId
    });

    const onChangeInput = debounce((e: any) => {
        const value = e.target.value;
        setSearch(value || undefined);
    }, 450);

    const callback = (e: ChargingPointForecastSettings) => {
        dispatch({ type: 'SET_CHARGING_POINT_FS', payload: e });
        dispatch({ type: 'SET_OPEN', payload: { ...state.open, chargingPointFS: false } });
    };

    const handleChange = () => {
        dispatch({ type: 'SET_CHARGING_POINT_FS', payload: null });
        setSearch(undefined);
        dispatch({ type: 'SET_OPEN', payload: { ...state.open, chargingPointFS: false } });
    };

    const handleIdChange = (e: ChargingPointForecastSettings) => () => callback(e);

    const { hideInput } = useHideInput({ data: chargingPointFS, callback });

    if (hideInput) {
        return <></>;
    }

    return (
        <Box className={classNames(style.formRow, style.fullWidth)}>
            <InputLabel variant="standard" required shrink htmlFor={t('evsReportForm.chargingPointFS')}>
                {t('evsReportForm.chargingPointFS')}
            </InputLabel>
            <Autocomplete
                id="asynchronous-charging-points-fs"
                value={state.chargingPointFS}
                options={chargingPointFS || []}
                className={state.open.chargingPointFS ? style.autocompleteOpen : ''}
                loading={isLoading}
                onChange={handleChange}
                noOptionsText={t('select.noOptions')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                popupIcon={isLoading ? <Loading className={style.loading} /> : <MdKeyboardArrowDown color="#6A9BFF" />}
                renderOption={(props: any, e: ChargingPointForecastSettings) => (
                    <Box component="li" {...props} key={Math.random()} onClick={handleIdChange(e)}>
                        {e.name}
                    </Box>
                )}
                ListboxProps={{ style: { maxHeight: '180px' } }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={onChangeInput}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: t('evsReportForm.chargingPointFS')
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <Loading /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
                open={state.open.chargingPointFS}
                onOpen={() => {
                    dispatch({ type: 'SET_OPEN', payload: { ...state.open, chargingPointFS: true } });
                }}
                onClose={() => {
                    dispatch({ type: 'SET_OPEN', payload: { ...state.open, chargingPointFS: false } });
                }}
                clearOnEscape={true}
                clearOnBlur={true}
                filterOptions={(x) => x}
            />
        </Box>
    );
};
