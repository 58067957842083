import { BaseModal } from '../../components/BaseModal';
import AddToVppForm from './AddToVppForm';
import { DialogContent } from '@mui/material';
import { MODAL_IDS } from '..';
import { CloseModalButton } from '../../components/BaseModal/CloseModalButton';

const AddToVppModal = () => {
    return (
        <BaseModal maxWidth="sm" fullWidth={false} closeOnBackdropClick id={MODAL_IDS.ADD_TO_VPP_MODAL}>
            <CloseModalButton id={MODAL_IDS.ADD_TO_VPP_MODAL} />
            <DialogContent>
                <AddToVppForm />
            </DialogContent>
        </BaseModal>
    );
};

export { AddToVppModal };
