import { api } from '../api';
import { ForecastDeliveryIntervalV2, PaginatedApiResponse } from '../../interfaces/apiv2';

export const deliveryIntervalsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDeliveryIntervals: build.query({
            query: (params) => {
                return {
                    url: `/electricity/generation/forecast-delivery-settings/${params.deliverySettingId}}/forecast-delivery-intervals`,
                    method: 'get'
                };
            },
            transformResponse: (
                rawData: PaginatedApiResponse<ForecastDeliveryIntervalV2>
            ): ForecastDeliveryIntervalV2[] => {
                return rawData.items;
            },
            keepUnusedDataFor: 0
        })
    })
});

export const { useGetDeliveryIntervalsQuery } = deliveryIntervalsApi;
