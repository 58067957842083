import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';

interface IdeleteVPP extends DefaultInitialState {
    assetsDeleted: number;
}

const initialState: IdeleteVPP = {
    loading: false,
    error: false,
    success: false,
    assetsDeleted: 0
};

export const deleteVPPAsync = createAsyncThunk('vpp/delete', async (id: string, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/electricity/generation/assets/vpp/${id}`);
        return response.data as null;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const deleteVPPMultipleAsync = createAsyncThunk(
    'vpp/delete_multiple',
    async (vppIds: string[], { rejectWithValue }) => {
        try {
            const endpoint = '/electricity/generation/assets/vpp';
            await Promise.all(
                vppIds.map((vppId) => {
                    return axios.delete(`${endpoint}/${vppId}`);
                })
            );
            return null;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const deleteVPP = createSlice({
    name: 'deleteVPP',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(deleteVPPAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(deleteVPPAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.assetsDeleted = state.assetsDeleted + 1;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(deleteVPPAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(deleteVPPMultipleAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(deleteVPPMultipleAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.assetsDeleted = state.assetsDeleted + 1;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(deleteVPPMultipleAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export default deleteVPP.reducer;
