import i18next from 'i18next';
import { toast } from 'react-toastify';
import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import ToastifyType from '../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo, EVSReport } from '../../interfaces/apiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { ForecastDataGetParams, ForecastFE, ReportDataFE } from '../../interfaces/uiv2';
import { transformReportDataToChart } from '../Reports/helpers';

interface IForecastPage extends DefaultInitialState {
    data: ForecastFE[];
    paginatedData: ReportDataFE[];
    chartData: LineData[][];
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
    report: EVSReport | null;
    reportData: ReportDataFE[];
    loadingData: boolean;
    errorGettingData: boolean;
}

const initialState: IForecastPage = {
    data: [],
    paginatedData: [],
    chartData: [[], []],
    pager: null,

    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        order_by: undefined
    },
    report: null,
    reportData: [],
    loadingData: false,
    errorGettingData: false,
    loading: false,
    error: false,
    success: false
};

export const getEVSReportAsync = createAsyncThunk(
    'evs_reports/page',
    async (params: { id: string; projectId: string }, { rejectWithValue }) => {
        try {
            const endpoint = `/evs/${params.projectId}/reports/${params.id}`;
            const response = await axios.get<EVSReport>(endpoint);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getEVSReportDataAsync = createAsyncThunk(
    'evs_reports/pageData',
    async (params: { id: string; projectId: string }, { rejectWithValue }) => {
        try {
            const endpoint = `/evs/${params.projectId}/reports/${params.id}/data`;
            const response = await axios.get<ReportDataFE[]>(endpoint, {
                cache: {
                    ttl: 1000 * 60 * 15
                }
            });

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const evsReportPage = createSlice({
    name: 'evsReportPage',
    initialState,
    reducers: {
        resetEVSReportPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setActiveEVSReport: (state, { payload }: PayloadAction<EVSReport | null>) => {
            state.report = payload;
        },
        loadMore: (state) => {
            const { reportData, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: reportData.length
            };
            state.paginatedData = reportData.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEVSReportAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSReportAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.report = payload;
        });
        builder.addCase(getEVSReportAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getEVSReportDataAsync.pending, (state) => {
            state.loadingData = true;
            state.errorGettingData = false;
            state.success = false;
        });
        builder.addCase(getEVSReportDataAsync.fulfilled, (state, { payload }) => {
            state.loadingData = false;
            state.errorGettingData = false;
            state.success = true;

            const reportData = payload.map((x, ix) => ({
                ...x,
                measured: x.measured * 1000,
                forecasted: x.forecasted * 1000,
                excess: x.excess * 1000,
                deficit: x.deficit * 1000,
                index: ix + 1
            }));

            state.reportData = reportData;
            /* FIXME: fix the type here */
            state.chartData = transformReportDataToChart(reportData);

            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).reportData.length
            };
            state.paginatedData = current(state).reportData.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
        });
        builder.addCase(getEVSReportDataAsync.rejected, (state) => {
            state.loadingData = false;
            state.errorGettingData = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const {
    resetEVSReportPage,
    setActiveEVSReport,
    setGetParams: setGetParamsEVSReportPage,
    loadMore
} = evsReportPage.actions;

export default evsReportPage.reducer;
