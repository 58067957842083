import { DialogContent } from '@mui/material';
import { BaseModal } from '../../components/BaseModal';
import DeleteVppConfirmationForm from './DeleteVppConfirmationForm';
import { MODAL_IDS } from '..';
import { CloseModalButton } from '../../components/BaseModal/CloseModalButton';

const DeleteAssetFromVppConfirmationModal = () => {
    return (
        <BaseModal maxWidth="sm" fullWidth={false} closeOnBackdropClick id={MODAL_IDS.DELETE_VPP_CONFIRMATION_MODAL}>
            <CloseModalButton id={MODAL_IDS.DELETE_VPP_CONFIRMATION_MODAL} />
            <DialogContent>
                <DeleteVppConfirmationForm />
            </DialogContent>
        </BaseModal>
    );
};

export { DeleteAssetFromVppConfirmationModal };
