import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { MdAdd, MdRefresh } from 'react-icons/md';

import {
    setGetParams,
    setSelectedRows,
    getGasReportsListAsync,
    resetListGasReportsState
} from '../../../stores/GasReports';
import { MODAL_IDS } from '../../../modals';
import { getTableHeadCells } from './helpers';
import { ROUTES } from '../../../utils/config';
import { ModalContext } from '../../../hooks/modalContext';
import { PageContainer } from '../../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { GenericTable, TableSearch } from '../../../components/BaseTable';
import { CreateGasReportModal } from '../../../modals/CreateGasReportModal';
import { transformOrderByToSort } from '../../../components/BaseTable/GenericTable/helpers';

import style from '../../../features/forecast-list/forecast-list.module.scss';

export const GasReportsList = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { projectId = '' } = useParams();
    const getParams = useAppSelector((state) => state.listGasReports.getParams, isEqual);
    const {
        reportsFE: rows,
        loading,
        selectedRows,
        pager,
        success: fetchingSuccess
    } = useAppSelector((state) => state.listGasReports);

    const { created } = useAppSelector((state) => state.createGasReport);
    const { handleClickOpen } = useContext(ModalContext);

    const headCells = getTableHeadCells(t, projectId);

    useEffect(() => {
        dispatch(getGasReportsListAsync({ ...getParams, projectId }));
    }, [getParams, created]);

    const clearSelection = () => {
        dispatch(setSelectedRows([]));
    };

    const handleAddNewReport = () => {
        handleClickOpen(MODAL_IDS.ADD_GAS_REPORT_MODAL);
    };

    const getReportsPageUrl = (row: any) => () => {
        const from = dayjs(row.from_timestamp).valueOf();
        const to = dayjs(row.to_timestamp).valueOf();
        return `${ROUTES.GAS_REPORTS_PAGE.replace(':projectId', projectId).replace(
            ':id',
            row.id
        )}?from=${from}&to=${to}`;
    };

    return (
        <PageContainer>
            <Box id="reports-gas-list-header" className={style.header}>
                <Typography variant="h6" className={style.title}>
                    {t('title.reports')}
                </Typography>
                {selectedRows.length ? (
                    <Box className={style.toolbar}>
                        {/* selected items toolbar */}

                        <Typography variant="small4" className={style.label}>
                            {selectedRows.length} {t('toolbar.selected')}
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <MdRefresh />
                            <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                {t('toolbar.clearSelection')}
                            </Box>
                        </Typography>
                        <TableSearch setGetParams={setGetParams} getParams={getParams} />
                    </Box>
                ) : (
                    <>
                        {/* Non selected toolbar */}
                        <Box className={style.toolbar}>
                            <TableSearch setGetParams={setGetParams} getParams={getParams} />

                            <Button disableRipple variant="cta" onClick={handleAddNewReport} sx={{ ml: 1 }}>
                                <MdAdd />
                            </Button>
                        </Box>
                    </>
                )}
            </Box>

            <GenericTable
                rows={rows}
                pager={pager}
                loading={loading}
                success={fetchingSuccess}
                isCheckboxEnabled
                columns={headCells}
                rowUniqueId={'id'}
                selectedRows={selectedRows}
                setGetParams={setGetParams}
                searchParam={getParams.search}
                setSelectedRows={setSelectedRows}
                cleanupCb={() => dispatch(resetListGasReportsState())}
                storeSort={transformOrderByToSort(getParams.order_by)}
                scrollingPlaceholder
                pagination
                handlers={{
                    name: getReportsPageUrl
                }}
            />
            <CreateGasReportModal />
        </PageContainer>
    );
};
