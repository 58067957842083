import classnames from 'classnames';
import { VictoryLegend, VictoryPie } from 'victory';
import { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { Box, SvgIcon, Typography } from '@mui/material';

import { GasDashboardLayout } from '../../interfaces/uiv2';
import { getConsumersTypeData } from './helpers';
import { getDemandIcon } from '../../utils/helpers';

import style from './style.module.scss';

export const GasDashboardPieCard = ({ l, dashboard }: { l: Layout; dashboard: GasDashboardLayout }) => {
    const { t } = useTranslation();

    const totalConsumers = Object.values(dashboard.consumers_per_type).reduce((a, c) => a + c, 0);

    return (
        <>
            <Box className={style.itemHeader}>
                <Box className={style.name}>
                    <SvgIcon component={getDemandIcon('consumer')} />
                    <Typography variant="h7" className="dragDisable">
                        {t('header.consumator')}
                    </Typography>
                </Box>
            </Box>

            <Box className={classnames(style.itemDetails, l.h === 1 ? style.noBorder : '')}>
                <Typography className={style.element} variant="small4">
                    {t('dashboardDemand.totalConsumatori')}
                    <Typography variant="small3" className={style.black} sx={{ ml: 1.5 }}>
                        {totalConsumers}
                    </Typography>
                </Typography>
            </Box>

            <Box className={style.itemChartContainer}>
                <Box
                    className={classnames(style.itemChart, l.w === 12 && style.p12, l.w === 8 && style.p12)}
                    sx={{ display: 'flex', position: 'relative' }}
                >
                    <svg className={style.legend}>
                        <VictoryLegend
                            standalone={false}
                            colorScale={['#88DEC1', '#9C80F1', '#80D5F8']}
                            x={0}
                            y={0}
                            title=""
                            centerTitle
                            data={getConsumersTypeData(dashboard, true) as any}
                        />
                    </svg>
                    <svg className={style.pieChart}>
                        <VictoryPie
                            standalone={false}
                            labelRadius={5}
                            innerRadius={0}
                            width={300}
                            height={260}
                            padding={{
                                left: 100,
                                bottom: 20,
                                top: 20
                            }}
                            colorScale={['#88DEC1', '#9C80F1', '#80D5F8']}
                            data={getConsumersTypeData(dashboard, false)}
                            labels={() => null}
                        />
                    </svg>
                </Box>
            </Box>
        </>
    );
};
