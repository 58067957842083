import enUS from 'date-fns/locale/en-US';
import { dateFnsLocalizer } from 'react-big-calendar';
import { format, getDay, parse, startOfISOWeek } from 'date-fns';

const statusCodes = {
    ok: 200,
    badRequest: 400,
    unauthorized: 401,
    forbidden: 403,
    notFound: 404,
    unprocessableEntity: 422,
    internalServerError: 500
};

const locales = {
    'en-US': enUS
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: startOfISOWeek,
    getDay,
    locales
});

const storage = {
    token: 'token',
    userId: 'userId',
    clientId: 'clientId',
    appView: 'appView',
    mapState: 'mapState'
};

export enum ROUTES {
    INDEX = '/',
    LOGIN = '/login',
    FORBIDDEN = '/forbidden',
    RESET_PASSWORD = '/reset-password',
    CHANGE_PASSWORD = '/change-password',
    NOTIFICATIONS = '/notifications',
    REPORTS = '/reports',
    REPORTS_PAGE = '/reports/:publicId',
    FORECASTS = '/forecasts',
    FORECASTS_PAGE = '/forecasts/:forecastId',
    EVS_FORECASTS = '/evs_forecasts',
    EVS_FORECASTS_PAGE = '/evs_forecasts/:forecastId',
    EVS_REPORTS = '/evs-reports/:projectId',
    EVS_REPORTS_PAGE = '/evs-reports/:projectId/:reportId',
    ADMIN = '/admin',
    ADMIN_FORECASTING = '/admin/forecasting',
    ADMIN_FORECASTING_ASSETS = '/admin/forecasting/assets/:assetId',
    ADMIN_DELIVERY_ASSETS = '/admin/delivery/assets',
    ADMIN_FORECASTING_PROJECTS = '/admin/projects',
    ADMIN_GENERATION = '/admin/dashboard',
    ADMIN_GENERATION_ASSETS = '/admin/generation/assets',
    ADMIN_GENERATION_ASSETS_SOLAR = '/admin/generation/assets/solar',
    ADMIN_GENERATION_ASSETS_WIND = '/admin/generation/assets/wind',
    ADMIN_GENERATION_ASSETS_OTHER = '/admin/generation/assets/other',
    ADMIN_GENERATION_ASSETS_PAGE = '/admin/generation/assets/:id',
    ADMIN_GENERATION_PROJECTS = '/admin/generation/projects',
    ADMIN_ACCOUNTS = '/admin/accounts',
    ADMIN_ACCOUNTS_CLIENTS = '/admin/accounts/clients',
    ADMIN_ACCOUNTS_USERS = '/admin/accounts/users',
    ADMIN_ACCOUNTS_TEAMS = '/admin/accounts/teams',
    ADMIN_ALARM = '/admin/alarm',
    ADMIN_WIDGETS = '/admin/widgets',
    ADMIN_LOGS = '/admin/logs',
    // gas
    AI_GAS_DASHBOARD = '/ai/gas/:projectId/dashboard',
    AI_GAS_DEMAND_CONSUMERS = '/ai/gas/demand/:projectId/consumers',
    AI_GAS_DEMAND_CONSUMERS_PAGE = '/ai/gas/demand/:projectId/consumers/:id',
    AI_GAS_DEMAND_PROFILES = '/ai/gas/demand/:projectId/profiles',
    AI_GAS_DEMAND_PROFILES_PAGE = '/ai/gas/demand/:projectId/profiles/:id',
    AI_GAS_DEMAND_CLUSTERS = '/ai/gas/demand/:projectId/clusters',
    AI_GAS_DEMAND_CLUSTERS_PAGE = '/ai/gas/demand/:projectId/clusters/:id',
    GAS_FORECASTS = '/ai/gas/:projectId/forecasts',
    GAS_DELIVERED_FORECAST_PAGE = '/ai/gas/delivered-forecast/:projectId',
    GAS_REPORTS = '/ai/gas/:projectId/reports',
    GAS_REPORTS_PAGE = '/ai/gas/:projectId/reports/:id',

    // electricity
    AI = '/ai',
    AI_DASHBOARD = '/ai/dashboard',
    AI_GENERATION_ASSETS = '/ai/generation/assets',
    AI_GENERATION_ASSETS_PAGE = '/ai/generation/assets/:id',
    AI_GENERATION_MOCK_ASSETS_PAGE = '/ai/assets/:id',
    AI_GENERATION_VPP = '/vpp',
    MAP = '/map',
    AI_GENERATION_VPP_PAGE = '/vpp/:vppId',
    AI_GENERATION_MOCK_VPP_PAGE = '/vpp/page/:id',
    AI_NTL = '/ai/ntl',
    AI_NTL_INSPECTIONS = '/ai/ntl/inspections',
    AI_NTL_EVALUATIONS = '/ai/ntl/evaluations',
    AI_NTL_LEGAL = '/ai/ntl/legal',
    AI_NTL_COMPLETED = '/ai/ntl/completed',
    AI_DEMAND_BASE = '/ai/projects-demand',
    AI_DEMAND = '/ai/demand/:projectId',
    AI_PROSUMERS = '/ai/prosumers',
    AI_PROSUMERS_PAGE = '/ai/prosumers/list/:id',
    AI_PROSUMERS_VPP = '/ai/prosumers/vpp',
    AI_PROSUMERS_VPP_PAGE = '/ai/prosumers/vpp/:vppId',
    AI_PROSUMERS_CLUSTERS_LIST = '/ai/prosumers-cluster/list',
    AI_PROSUMERS_CLUSTERS_PAGE = '/ai/prosumers-cluster/:id',
    AI_DEMAND_CONSUMERS = '/ai/demand/:projectId/consumers',
    AI_PROSUMERS_LIST = '/ai/demand/:projectId/prosumers',
    AI_DEMAND_DASHBOARD = '/ai/demand/:projectId/dashboard',
    AI_DEMAND_CONSUMERS_PAGE = '/ai/demand/:projectId/consumers/:id',
    AI_DEMAND_PROFILES = '/ai/demand/:projectId/profiles',
    AI_DEMAND_PROFILES_PAGE = '/ai/demand/:projectId/profiles/:id',
    AI_DEMAND_PROFILES_FORMULA_PAGE = '/ai/demand/:projectId/profiles-formula/:id',
    AI_DEMAND_CLUSTERS = '/ai/demand/:projectId/clusters',
    AI_DEMAND_CLUSTERS_PAGE = '/ai/demand/:projectId/clusters/:id',
    AI_DEMAND_SEGMENTS = '/ai/demand/:projectId/segments',
    AI_DEMAND_SEGMENTS_PAGE = '/ai/demand/:projectId/segments/:id',
    AI_TRANSMISSION = '/ai/transmission',
    AI_ANOMALY = '/ai/anomaly-detection',
    EMS = '/ems',
    PRICE_FORECAST = '/pricing',
    PRICE_FORECAST_PAGE = '/pricing/:id',
    AI_TL = '/ai/tl/dashboard',
    AI_TL_CLUSTERS = '/ai/tl/clusters',
    AI_TL_CONSUMERS = '/ai/tl/consumers',
    AI_TL_GENERATION_ASSETS = 'ai/tl/generation',
    AI_TL_CIRCULATIONS = '/ai/tl/circulations',
    AI_TL_CLUSTERS_PAGE = '/ai/tl/clusters/:id',
    AI_TL_GENERATION_ASSETS_PAGE = '/ai/tl/generation/assets/:id',
    AI_TL_CONSUMERS_PAGE = '/ai/tl/consumers/:id',
    AI_TL_CIRCULATIONS_PAGE = '/ai/tl/circulations/:id',
    AI_EVS = '/ai/evs',
    AI_EVS_DASHBOARD = '/ai/evs/dashboard',
    AI_EVS_CHARGHING_STATIONS = '/ai/evs/:projectId/charging-stations',
    AI_EVS_CHARGHING_STATIONS_PAGE = '/ai/evs/:projectId/charging-stations/:id',
    AI_EVS_CHARGHING_POINTS_PAGE = '/ai/evs/:projectId/charging-stations/:stationId/charging-points/:id',
    AI_EVS_CLUSTERS = '/ai/evs/:projectId/clusters',
    AI_EVS_CLUSTERS_PAGE = '/ai/evs/:projectId/clusters/:id',
    AI_DISABLED = '/ai/disabled',
    DATA_MANAGEMENT = '/data-management'
}

const PAGINATION_SETTINGS = {
    page_size: 30,
    default_fe_page_size: 100,
    default_start_page: 1
};

const DATE_SETTINGS = {
    shortFormat: 'DD.MM.YYYY',
    timeShortFormat: 'HH:mm:ss',
    timeShorterFormat: 'HH:mm',
    shortFormatHoursIncluded: 'DD.MM.YYYY - HH:mm',
    shortV2FormatHoursIncluded: 'YYYY.MM.DD - HH:mm',
    longFormat: 'YYYY_MM_DD__HH_mm_ss',
    longFormatUS: 'YYYY/MM/DD HH:mm',
    longFormatUSDot: 'YYYY.MM.DD HH:mm',
    longFormatUsDash: 'YYYY-MM-DD HH:mm:ss',
    mediumFormatEU: 'DD.MM.YYYY HH:mm Z',
    //date-fns valid format
    shortFormatFNS: 'dd.MM.yyyy',
    shortFormatFNS2: 'yyyy.MM.dd',
    timeShortFormatFNS: 'HH:mm'
};

const getForecastFilteringFormattedDate = (date: Date) => {
    return date.toISOString();
};

const NO_VALUE = '-';
const NO_VALUE_NA = 'N/A';
const NO_VALUE_ZERO = '0';

export {
    statusCodes,
    storage,
    NO_VALUE,
    NO_VALUE_NA,
    NO_VALUE_ZERO,
    PAGINATION_SETTINGS,
    DATE_SETTINGS,
    getForecastFilteringFormattedDate,
    localizer,
    locales
};
