import { FC } from 'react';

import { BaseModal } from '.';
import { CloseModalButton } from './CloseModalButton';
import { BaseModalProps } from '../../interfaces/general';

const FormModal: FC<BaseModalProps> = ({ id, fullWidth, maxWidth, children }) => {
    return (
        <BaseModal id={id} fullWidth={fullWidth} maxWidth={maxWidth}>
            <CloseModalButton id={id} />
            {children}
        </BaseModal>
    );
};

export { FormModal };
