import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Box, Typography } from '@mui/material';

import { DATE_SETTINGS, NO_VALUE_ZERO } from '../../utils/config';
import { TableCellRenderer } from '../../interfaces/general';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../utils/table';
import { getFormattedDate, toFixed } from '../../utils/helpers';
import { DefaultSearchableCell, TableActions } from '../../components/BaseTable';
import { CircleProgressBar } from '../../components/BaseTable/CustomCells/CircleProgressBar';
import { ASSET_STATUS, ProsumersDataFE, ProsumersForecastDataFE } from '../../interfaces/uiv2';
import { getBackgroundColorPerStatus } from '../../features/admin-overview/Users/ListUsers/helpers';
import { ReactComponent as ProsumersIcon } from '../../assets/icons/navigation-items/menu-item-prosumers.svg';

import { ReactComponent as WindIcon } from '../../assets/icons/park_types/wind_type.svg';
import { ReactComponent as SolarIcon } from '../../assets/icons/park_types/solar_type.svg';
import { ReactComponent as VPPIcon } from '../../assets/icons/vpp.svg';
import { ReactComponent as ClustersIcon } from '../../assets/icons/demand/clusters.svg';

import style from '../Demand/style.module.scss';
// import BatteryIcon from '../../components/BatteryIcon/BatteryIcon';
import classnames from 'classnames';
import { renderFooterCellWrapper } from '../../components/GenerationTable/helpers';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { ProsumerClusterFE } from '../../stores/Prosumers';

export const transformLatestForecastData = (latestForecast: number[]) => {
    return latestForecast.map((forecastValue, i) => ({
        date: dayjs()
            .add(i + 1, 'days')
            .format('ddd'),
        value: +forecastValue.toFixed(0)
    }));
};

export const renderProsumersForecastFooterContent = (data: ProsumersForecastDataFE[], t: TFunction) => {
    return {
        _id: 'mockedFooter',
        customFooter: true,
        index: t('customFooter.total'),
        date: t('customFooter.allIntervals'),
        time: t('customFooter.allIntervals'),
        demand: toFixed(data.reduce((prev, crt) => prev + (crt.demand ? +crt.demand : 0), 0)),
        generation: toFixed(data.reduce((prev, crt) => prev + (crt.generation ? +crt.generation : 0), 0)),
        meter: toFixed(data.reduce((prev, crt) => prev + (crt.meter ? +crt.meter : 0), 0)),
        notified: toFixed(data.reduce((prev, crt) => prev + (crt.notified ? +crt.notified : 0), 0)),
        net: toFixed(data.reduce((prev, crt) => prev + (crt.net ? +crt.net : 0), 0)),
        traded: toFixed(data.reduce((prev, crt) => prev + (crt.traded ? +crt.traded : 0), 0)),
        balance: toFixed(data.reduce((prev, crt) => prev + (crt.balance ? +crt.balance : 0), 0))
    };
};

export const getProsumersForecastTableHeadCells = (t: TFunction): TableCellRenderer<ProsumersForecastDataFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {row.index < 10 ? `0${row.index}` : `${row.index}`}
                    </Typography>
                );
            },
            sort: false
        },
        {
            id: 'date',
            label: t('tableHead.date'),
            justifyContent: 'center',
            sort: false,
            value: (row, searchValue) => {
                const date = getFormattedDate({ value: row.date, type: DATE_SETTINGS.shortFormat });
                return <DefaultSearchableCell value={date} searchValue={searchValue} className={style.default} />;
            }
        },
        {
            id: 'time',
            sort_id: 'time',
            justifyContent: 'center',
            label: t('tableHead.time'),
            // TODO: this should be true by design, at lets keep it false until api is working
            sort: false,
            value: (row, searchValue) => {
                const time = getFormattedDate({ value: row.date, type: DATE_SETTINGS.timeShorterFormat });
                return <DefaultSearchableCell value={time} searchValue={searchValue} className={style.default} />;
            }
        },
        {
            id: 'demand',
            label: `${t('tableHead.demand')} (kW)`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.demand, `${t('tableFooter.total')} (kW)`)
                            : row.demand || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        },
        {
            id: 'generation',
            label: `${t('tableHead.generation')} (kW)`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.generation, `${t('tableFooter.total')} (kW)`)
                            : row.generation || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        },
        {
            id: 'meter',
            label: `${t('tableHead.meter')} (kW)`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.meter, `${t('tableFooter.total')} (kW)`)
                            : row.meter || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        },
        {
            id: 'notified',
            label: `${t('tableHead.notified')} (kW)`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.notified, `${t('tableFooter.total')} (kW)`)
                            : row.notified || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        },
        {
            id: 'net',
            label: `${t('tableHead.net')} (kW)`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.net, `${t('tableFooter.total')} (kW)`)
                            : row.net || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        },
        {
            id: 'traded',
            label: `${t('tableHead.traded')} (kW)`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.traded, `${t('tableFooter.total')} (kW)`)
                            : row.traded || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        },
        {
            id: 'balance',
            label: `${t('tableHead.balance')} (kW)`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.balance, `${t('tableFooter.total')} (kW)`)
                            : row.balance || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        }
    ];
};

export const getProsumersHeadCells = (tList: TFunction): TableCellRenderer<ProsumersDataFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return <Typography variant="small4">{row.index < 10 ? `0${row.index}` : `${row.index}`}</Typography>;
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 70,
            label: tList('tableHead.type'),
            value: () => {
                return <ProsumersIcon />;
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            minWidth: 150,
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.name}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },

        {
            id: 'dso',
            justifyContent: 'center',
            label: tList('tableHead.dso'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.dso} searchValue={searchValue}></DefaultSearchableCell>;
            },
            sort: false
        },
        {
            id: 'location',
            label: tList('tableHead.location'),
            value: (row, searchValue, onClick, handlers) => {
                const value = row.location || '';
                const handler = handlers?.['location'];

                const callback = handler ? handler(row) : undefined;
                return (
                    <Typography className={style.active} variant="small4" onClick={callback}>
                        {value}
                    </Typography>
                );
            },
            sort: true
        },
        {
            id: 'total_capacity',
            label: tList('tableHead.totalCapacity'),
            justifyContent: 'center',
            sort: false,
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={`${row.total_capacity} kW`} searchValue={searchValue} />;
            }
        },

        {
            id: 'demand',
            label: tList('tableHead.demand'),
            justifyContent: 'center',
            sort: false,
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.demand} searchValue={searchValue} />;
            }
        },
        // {
        //     id: 'storage',
        //     label: tList('tableHead.storage'),
        //     justifyContent: 'center',
        //     sort: true,
        //     value: (row) => {
        //         const value = `${row.total_capacity} kW`;
        //         return <BatteryIcon value={value} percentage={row.storagePercentage} id={row.id} />;
        //     }
        // },
        {
            id: 'status',
            maxWidth: '10%',
            justifyContent: 'center',
            label: tList('tableHead.status'),
            value: (row) => {
                let text;

                switch (row.status) {
                    case ASSET_STATUS.DISABLED:
                        text = tList('Inactive');
                        break;
                    case ASSET_STATUS.PENDING:
                        text = tList('Pending');
                        break;
                    case ASSET_STATUS.ACTIVE:
                        text = tList('Active');
                        break;
                    default:
                        text = tList('Pending');
                        break;
                }

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                width: '8px',
                                height: '8px',
                                marginRight: '8px',
                                borderRadius: '50%',
                                background: getBackgroundColorPerStatus(row.status)
                            }}
                        ></Box>
                        <Typography
                            variant="small4"
                            sx={{
                                color:
                                    row.status === ASSET_STATUS.DISABLED ? '#828282 !important' : '#323232 !important'
                            }}
                        >
                            {text}
                        </Typography>
                    </Box>
                );
            },
            sort: false
        },
        {
            id: 'expiryDate',
            label: tList('tableHead.expiryDate'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.expiry_date;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'accuracy',
            label: tList('tableHead.accuracy'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const value = (+row.accuracy || 0) * 100;
                return <CircleProgressBar value={value} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'addToVPP',
                                    label: 'table.general.addToVPP',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }
                            ]}
                        />
                    </Box>
                );
            }
        }
    ];
};

export const getClustersHeadCells = (tList: TFunction): TableCellRenderer<ProsumerClusterFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 70,
            label: tList('tableHead.type'),
            value: () => {
                return <ClustersIcon />;
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.name}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },

        {
            id: 'total_prosumers',
            label: tList('tableHead.total_prosumers'),
            justifyContent: 'center',
            value: (row) => {
                return <Typography variant="small4">{row.total_prosumers}</Typography>;
            },
            sort: true
        },
        {
            id: 'created_at',
            label: tList('tableHead.createdAt'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.created_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'updated_at',
            label: tList('tableHead.updatedAt'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.updated_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'addToVPP',
                                    label: 'table.general.addToVPP',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'addToDashboard',
                                    label: 'table.general.addToDashboard',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }
                            ]}
                        />
                    </Box>
                );
            }
        }
    ];
};

//TODO: DELETE THIS FN AND REUSE THE OTHER ONE WHEN WE KNOW THE REAL TYPES
export const getAssetIcon = (type: string) => {
    switch (type) {
        case 'Prosumer':
            return ProsumersIcon;
        case 'Wind':
            return WindIcon;
        case 'Solar':
            return SolarIcon;
        case 'VPP':
            return VPPIcon;
        default:
            return ProsumersIcon;
    }
};
