import { ASSET_TYPE } from '../uiv2';

export interface TableCellRenderer<T> {
    id: string;
    label: string | Element;
    sort: boolean;
    sort_id?: string;
    tooltip?: string;
    smallTooltip?: boolean;
    maxWidth?: number | string;
    minWidth?: number | string;
    className?: any;
    justifyContent?: string;
    value?: (
        row: T,
        searchValue?: string | null,
        onClick?: any,
        handlers?: { [col_id: string]: any },
        rowIndex?: number
    ) => JSX.Element | string;

    // todo: delete these props
    numeric?: boolean;
    disablePadding?: boolean;

    editable?: boolean;
}

export enum ColumnOrder {
    asc = 'asc',
    desc = 'desc'
}

export interface GetParams {
    search?: string | null;
    page?: number;
    size?: number;
    order_by?: string[];
    asset_type?: ASSET_TYPE;
}
