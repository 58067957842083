import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Input, InputLabel, Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MODAL_IDS } from '../../modals';
import { GenericTable } from '../BaseTable';
import { debounce } from '../../utils/helpers';
import ToastifyType from '../../utils/toastify-config';
import { ModalContext } from '../../hooks/modalContext';
import { getTableHeadCells, transformer } from './helpers';
import { useAppDispatch, useAppSelector } from '../../stores';
import { FormErrorStep, FormModalActions } from '../FormUtils';
import { transformOrderByToSort } from '../BaseTable/GenericTable/helpers';
import { ReactComponent as AddSvg } from '../../assets/icons/add-icon.svg';
import { ReactComponent as UndoSvg } from '../../assets/icons/undo-icon.svg';
import { ReactComponent as RedoSvg } from '../../assets/icons/redo-icon.svg';
import { GenerationAsset, PatchVPPBody, PostVPP } from '../../interfaces/apiv2';
import { ReactComponent as RemoveSvg } from '../../assets/icons/remove-icon.svg';
import { getAssetsAsync, setSelectedRows, setGetParams, resetListAssetsState } from '../../stores/Assets';
import {
    createVPPAsync,
    getVPPAssetsAsync,
    resetcreateVPPState,
    setSelectedGenerationAssets,
    updateVPPAsync
} from '../../stores/VPP';

import style from './vpp-form.module.scss';

interface IVPPForm {
    isEditable?: boolean;
}

const VPPForm = ({ isEditable }: IVPPForm) => {
    const dispatch = useAppDispatch();
    const { payload } = useContext(ModalContext);
    const { handleClose } = useContext(ModalContext);
    const { t: generalTranslation } = useTranslation();
    const { t } = useTranslation('assets/create-update-vpp');
    const { t: tList } = useTranslation('assets/generation');

    // vpp rows are the items on the right table
    const [vppRows, setVppRows] = useState<GenerationAsset[]>([]);
    const [filteredRows, setFilteredVppRows] = useState<GenerationAsset[]>([]);
    const [vppSearchTerm, setVppSearchTerm] = useState('');
    const [undoActions, setUndoActions] = useState<any>([]);
    const [redoActions, setRedoActions] = useState<any>([]);
    const [vppName, setVPPName] = useState(payload?.vpp?.name || '');

    // asset are the items on the left table
    const {
        selectedRows,
        assets,
        loading: loadingAssets,
        pager,
        success: successFetchingAssets
    } = useAppSelector((state) => state.listAssets);
    const headCells = getTableHeadCells({ tList, t: generalTranslation });
    const vppHeadCells = getTableHeadCells({ tList, t: generalTranslation, disabledSort: true });
    const { success, loading, error } = useAppSelector((state) => state.createVPP);
    const { generationAssets, selectedGenerationAssets } = useAppSelector((state) => state.getVPPAssets);
    const getParams = useAppSelector((state) => state.listAssets.getParams, isEqual);

    useEffect(() => {
        if (success) {
            handleClose(isEditable ? MODAL_IDS.EDIT_VPP : MODAL_IDS.CREATE_VPP);
            toast.success(generalTranslation('commonSuccessMessage'), ToastifyType.success);
        }
    }, [success]);

    useEffect(() => {
        setVppRows(transformer(generationAssets, []));
    }, [generationAssets]);

    useEffect(() => {
        if (isEditable && payload?.vpp) {
            dispatch(getVPPAssetsAsync({ id: payload.vpp.id }));
        }
    }, [payload?.vpp]);

    useEffect(() => {
        return () => {
            dispatch(resetcreateVPPState());
            dispatch(resetListAssetsState());
        };
    }, []);

    useEffect(() => {
        dispatch(getAssetsAsync({ ...getParams }));
    }, [getParams]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearchHandler(event.target.value);
    };

    const handleVppSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        debouncedVppSearchHandler(event.target.value);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVPPName(event.target.value);
    };

    const handleAddAssetsToVpp = () => {
        if (selectedRows.length) {
            const actions = [
                ...undoActions,
                {
                    undo: () => {
                        const newRows = vppRows.filter((row) => {
                            return !selectedRows.some((e) => e.id === row.id);
                        });
                        setVppRows(newRows);
                    },
                    redo: () => {
                        setVppRows([...vppRows, ...selectedRows]);
                    }
                }
            ];

            setVppRows([...vppRows, ...selectedRows]);
            dispatch(setSelectedRows([]));
            setUndoActions(actions);
        }
    };

    const handleRemoveAssetsFromVpp = () => {
        if (selectedGenerationAssets.length) {
            const newRows = vppRows.filter((row) => {
                return !selectedGenerationAssets.some((e) => e.id === row.id);
            });
            setVppRows(newRows);
            dispatch(setSelectedGenerationAssets([]));
            const actions = [
                ...undoActions,
                {
                    undo: () => {
                        setVppRows([...vppRows]);
                    },
                    redo: () => {
                        setVppRows(newRows);
                    }
                }
            ];
            setUndoActions(actions);
        }
    };

    const handleUndo = () => {
        const newActions = [...undoActions];
        const action = newActions.pop();
        setRedoActions([...redoActions, action]);
        action.undo();
        setUndoActions(newActions);
    };

    const handleRedo = () => {
        const newActions = [...redoActions];
        const action = newActions.pop();
        setUndoActions([...undoActions, action]);
        action.redo();
        setRedoActions(newActions);
    };

    const debouncedSearchFn = (value: string | null) => {
        dispatch(setGetParams({ search: value, page: 1 }));
    };

    const debouncedVppSearchFn = (value: string) => {
        setVppSearchTerm(value);
        const newVppRows = vppRows.filter((e) => {
            return (
                e.name.toUpperCase().includes(value.toUpperCase()) ||
                e.location?.toUpperCase().includes(value.toUpperCase())
            );
        });
        setFilteredVppRows(newVppRows);
    };

    const debouncedSearchHandler = useCallback(debounce(debouncedSearchFn, 450), []);
    const debouncedVppSearchHandler = useCallback(debounce(debouncedVppSearchFn, 450), [vppRows]);

    const handleSubmit = () => {
        if (isEditable) {
            const body: PatchVPPBody = {
                id: payload?.vpp.id,
                payload: {
                    name: vppName,
                    add_asset_ids: vppRows
                        .filter((e) => {
                            return !generationAssets.some((a) => a.id === e.id);
                        })
                        .map((e) => e.id),
                    remove_asset_ids: generationAssets
                        .filter((e) => {
                            return !vppRows.some((a) => a.id === e.id);
                        })
                        .map((e) => e.id)
                }
            };

            dispatch(updateVPPAsync(body));
        } else {
            const body: PostVPP = {
                name: vppName,
                asset_ids: vppRows.map((e) => e.id)
            };
            dispatch(createVPPAsync(body));
        }
    };

    const rows: GenerationAsset[] = useMemo(() => transformer(assets, vppRows), [assets, vppRows]);
    const isAddActive = selectedRows.length && selectedGenerationAssets.length === 0;
    const isRemoveActive = selectedRows.length === 0 && selectedGenerationAssets.length;
    const isRedoActive = redoActions.length;
    const isUndoActive = undoActions.length;

    return (
        <>
            {!(success || error) ? (
                <Box className={style.modalContainer}>
                    <Typography variant="h4" className={style.title}>
                        {t(isEditable ? 'editTitle' : 'title')}
                    </Typography>
                    <Box className={style.customBodyTableClassName}>
                        <Box className={style.table}>
                            <Box className={style.nameContainer}>
                                <InputLabel variant="standard" required shrink htmlFor={t('inputNameLabel')}>
                                    {t('inputNameLabel')}
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id={t('inputNamePlaceholder')}
                                    placeholder={t('inputNamePlaceholder')}
                                    onChange={handleNameChange}
                                    value={vppName}
                                />
                            </Box>
                            <Box className={style.searchInput}>
                                <Input
                                    fullWidth
                                    id={t('filterInputPlaceholder')}
                                    placeholder={t('filterInputPlaceholder')}
                                    onChange={handleSearch}
                                />
                            </Box>
                            <InputLabel variant="standard" required shrink htmlFor={t('inputNameLabel')}>
                                {t('assets')}
                            </InputLabel>
                            <GenericTable
                                className={style.tableContainer}
                                rows={rows}
                                pager={pager}
                                loading={loadingAssets}
                                success={successFetchingAssets}
                                isCheckboxEnabled
                                columns={headCells}
                                rowUniqueId={'id'}
                                selectedRows={selectedRows as GenerationAsset[]}
                                emptyTablePlaceholder={false}
                                setGetParams={setGetParams}
                                searchParam={getParams.search}
                                setSelectedRows={setSelectedRows as any}
                                storeSort={transformOrderByToSort(getParams.order_by)}
                            />
                            <Typography variant="small4" className={style.footerLabel}>
                                {t('totalAssets')}
                                {rows.length}
                            </Typography>
                        </Box>
                        <Box className={style.toolbar}>
                            <div
                                className={classNames(style.actionBtn, !isAddActive ? style.disabled : '')}
                                onClick={handleAddAssetsToVpp}
                            >
                                <AddSvg />
                            </div>
                            <div
                                className={classNames(style.actionBtn, !isRemoveActive ? style.disabled : '')}
                                onClick={handleRemoveAssetsFromVpp}
                            >
                                <RemoveSvg />
                            </div>
                            <div
                                className={classNames(style.actionBtn, !isUndoActive ? style.disabled : '')}
                                onClick={handleUndo}
                            >
                                <UndoSvg />
                            </div>
                            <div
                                className={classNames(style.actionBtn, !isRedoActive ? style.disabled : '')}
                                onClick={handleRedo}
                            >
                                <RedoSvg />
                            </div>
                        </Box>
                        <Box className={classNames(style.table, style.mt)}>
                            <Box className={style.searchInput}>
                                <Input
                                    fullWidth
                                    id={t('filterInputPlaceholder')}
                                    placeholder={t('filterInputPlaceholder')}
                                    onChange={handleVppSearch}
                                />
                            </Box>
                            <InputLabel variant="standard" required shrink htmlFor={t('inputNameLabel')}>
                                {t('newvpp')}
                            </InputLabel>
                            <GenericTable
                                className={style.tableContainer}
                                rows={vppSearchTerm ? filteredRows : vppRows}
                                pager={pager}
                                loading={loadingAssets}
                                success={success}
                                isCheckboxEnabled
                                columns={vppHeadCells}
                                rowUniqueId={'id'}
                                selectedRows={selectedGenerationAssets}
                                emptyTablePlaceholder={false}
                                setGetParams={setGetParams}
                                searchParam={vppSearchTerm}
                                setSelectedRows={setSelectedGenerationAssets}
                                storeSort={transformOrderByToSort(getParams.order_by)}
                            />
                            <Typography variant="small4" className={style.footerLabel}>
                                {t('totalAssets')}
                                {vppRows.length}
                            </Typography>
                        </Box>
                    </Box>
                    <FormModalActions
                        modalId={isEditable ? MODAL_IDS.EDIT_VPP : MODAL_IDS.CREATE_VPP}
                        note={t('mandatoryFields')}
                        cancelLabel={generalTranslation('nevermind')}
                        saveLabel={t('save')}
                        disabled={!vppName || !vppRows.length}
                        loading={loading}
                        onSubmit={handleSubmit}
                    />
                </Box>
            ) : null}
            {error ? <FormErrorStep /> : null}
        </>
    );
};

export default VPPForm;
