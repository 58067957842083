import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../../../utils/axios';
import i18next from '../../../../utils/i18next';
import ToastifyType from '../../../../utils/toastify-config';
import { DefaultInitialState } from '../../../../interfaces/redux';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';

interface DeleteClustersState extends DefaultInitialState {
    deleted: number;
}

const initialState: DeleteClustersState = {
    loading: false,
    error: false,
    success: false,
    deleted: 0
};

export const deleteGasClustersListAsync = createAsyncThunk(
    'gas-clusters_list/delete',
    async ({ ids, projectId }: { ids: string[]; projectId: string }, { rejectWithValue }) => {
        try {
            await Promise.all(
                ids.map((cluster_id) => {
                    return axios.delete(`/gas/demand/${projectId}/clusters/${cluster_id}`);
                })
            );
            return null;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const deleteGasClusters = createSlice({
    name: 'deleteGasClusters',
    initialState,
    reducers: {
        resetDeleteGasClustersListState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(deleteGasClustersListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(deleteGasClustersListAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.deleted += 1;
        });
        builder.addCase(deleteGasClustersListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetDeleteGasClustersListState } = deleteGasClusters.actions;

export default deleteGasClusters.reducer;
