import dayjs from 'dayjs';
import classNames from 'classnames';
import Flag from 'react-world-flags';
import { MdAdd } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';

import {
    loadMore,
    downloadChart,
    getAssetAsync,
    resetAssetPage,
    getForecastAsync,
    getMeasuredAsync,
    resetForecastData,
    getMoreForecastAsync,
    getMoreMeasuredAsync,
    setGetParamsAssetPage,
    getGenerationForecastAsync,
    setGenerationTablePayloadTemp,
    resetListAssetAvailabilityState
} from '../../stores/Assets';
import { MODAL_IDS } from '../../modals';
import Loading from '../../layout/Loading';
import { TAB } from '../../interfaces/general';
import { NO_VALUE, ROUTES } from '../../utils/config';
import { ModalContext } from '../../hooks/modalContext';
import { getAssetIcon } from '../../utils/getAssetIcon';
import { TableAction } from '../../components/BaseTable';
import { TradingView } from '../../components/TradingView';
import { useAppDispatch, useAppSelector } from '../../stores';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { AssetLocationModal } from '../../modals/AssetLocationModal';
import { LocationsTable } from '../../components/AssetLocationsTable';
import { GenerationTableForecastDataPayload, UNIT } from '../../interfaces/uiv2';
import { AddAssetLocationsModal } from '../../modals/AddAssetLocationsModal';
import { ReactComponent as GraphIcon } from '../../assets/icons/timeline.svg';
import { ReactComponent as ViewList } from '../../assets/icons/view_list.svg';
import { MaintenanceContainer } from '../admin-generation/MaintenanceContainer';
import { ReactComponent as LocationSvg } from '../../assets/icons/location.svg';
import { IngestMultipleLocationsModal } from '../../modals/IngestLocationsModal';
import AssetStatusLabel from '../../components/AssetStatusLabel/AssetStatusLabel';
import { ReactComponent as AssetLocationsIcon } from '../../assets/icons/locations.svg';
import { ReactComponent as MaintenanceIcon } from '../../assets/icons/maintenance.svg';
import { MemoizedGenerationTable } from '../../components/GenerationTable/GenerationTable';
import { TableActionsEntityContainer } from '../../components/TableActionsEntityContainer';
import { getFormattedCapacityValue, getFormattedDate } from '../../utils/helpers';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../components/EntityPageToolbar';

import style from './asset-page.module.scss';
import { DownloadExtension } from '../../components/DownloadButton';
import { formatAssetMeasureUnit } from '../../utils/forecast';

const AssetPage = () => {
    const query = useParams();
    const dispatch = useAppDispatch();
    const { t: generalTranslation } = useTranslation();

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;
    const [dates, setDates] = useState({ from: 0, to: 0 });

    const {
        assetFE: asset,
        pager,
        forecasts,
        getParams,
        errorForecast,
        errorMeasured,
        paginatedData,
        loadingForecast,
        successForecast,
        loadingMeasured
    } = useAppSelector((state) => state.assetPage);
    const { user } = useAppSelector((state) => state.login);
    const { selectedRows: selectedLocations } = useAppSelector((state) => state.listAssetLocations);

    const { handleClickOpen } = useContext(ModalContext);

    useEffect(() => {
        return () => {
            dispatch(resetAssetPage());
            dispatch(resetForecastData());
            dispatch(resetListAssetAvailabilityState());
        };
    }, []);

    useEffect(() => {
        if (!asset && query?.id) {
            dispatch(getAssetAsync({ id: query.id }));
        }
    }, [query?.id, asset]);

    useEffect(() => {
        if (asset && tab === 'chart') {
            const from = dayjs().subtract(1, 'week').utcOffset(0).startOf('date').toISOString();
            const to = dayjs().add(10, 'days').utcOffset(0).endOf('date').toISOString();
            dispatch(
                getForecastAsync({
                    id: asset.id,
                    from: from.replace('T', ' ').replace('.000Z', ''),
                    to: to.replace('T', ' ').replace('.999Z', '')
                })
            );
            dispatch(
                getMeasuredAsync({
                    id: asset.id,
                    from: from.replace('T', ' ').replace('.000Z', ''),
                    to: to.replace('T', ' ').replace('.999Z', '')
                })
            );
        }
    }, [tab, asset]);

    useEffect(() => {
        if (tab === 'table' && pager?.page !== getParams.page) {
            dispatch(loadMore());
        }
    }, [getParams, tab, pager]);

    if (!asset) {
        return <Loading className={style.loading} />;
    }

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    const handleLocationOpen = () => {
        handleClickOpen(MODAL_IDS.ASSET_LOCATION_MODAL);
    };

    // eslint-disable-next-line
    const handleDownload = () => {
        dispatch(downloadChart(true));
    };

    const getForecastSettingsUrl = () => {
        return ROUTES.ADMIN_FORECASTING_ASSETS.replace(':assetId', asset.id);
    };

    const fetchMore = async (toString: string) => {
        const toDayJs = dayjs(toString);
        const timezone = asset.timezone || 'UTC';
        const offset = toDayJs.tz(timezone).utcOffset();
        const to = toDayJs.add(offset, 'minutes').toISOString();
        const from = dayjs.tz(to, 'UTC').subtract(1, 'week').toISOString();

        dispatch(
            getMoreForecastAsync({
                id: asset.id,
                from: from.replace('T', ' ').replace('.000Z', ''),
                to: to.replace('T', ' ').replace('.999Z', '')
            })
        );

        dispatch(
            getMoreMeasuredAsync({
                id: asset.id,
                from: from.replace('T', ' ').replace('.000Z', ''),
                to: to.replace('T', ' ').replace('.999Z', '')
            })
        );
    };

    const fetchGenerationForecast = async (payload: GenerationTableForecastDataPayload) => {
        setDates({
            from: payload.from,
            to: payload.to
        });
        dispatch(
            getGenerationForecastAsync({
                ...payload,
                id: asset.id,
                to: dayjs(payload.to).toISOString().replace('T', ' ').replace('.999Z', ''),
                from: dayjs(payload.from).toISOString().replace('T', ' ').replace('.000Z', '')
            })
        );
    };

    const assetUnit = formatAssetMeasureUnit(asset.measure_unit, asset.capacity_value || 0);

    return (
        <>
            <Box className={classNames(style.pageContainer)}>
                <Box className={style.headerContainer}>
                    <Box className={style.header}>
                        {getAssetIcon(asset.type)}
                        <PageHeader
                            className={style.title}
                            id={asset.id}
                            title={generalTranslation('title.assets')}
                            subtitle={asset.name}
                        />

                        <Box className={style.subtitle} sx={{ ml: 2 }}>
                            <Box className={style.dot} />
                            <Typography variant="small1"> {generalTranslation(pageTitle)}</Typography>
                        </Box>
                    </Box>
                    <Box className={style.toolbarContainer}>
                        {!!selectedLocations.length && (
                            <Button
                                disableRipple
                                className={style.button}
                                variant="cta"
                                sx={{ mr: 1 }}
                                onClick={() => handleClickOpen(MODAL_IDS.INGEST_MULTIPLE_LOCATION_COORDINATES)}
                            >
                                <Typography variant="small4">{generalTranslation('table.general.ingest')}</Typography>
                            </Button>
                        )}
                        {tab === TAB.ASSET_LOCATIONS && user?.superadmin && (
                            <Button
                                disableRipple
                                className={style.button}
                                variant="cta"
                                sx={{ mr: 1 }}
                                onClick={() => handleClickOpen(MODAL_IDS.ADD_LOCATION_COORDINATES)}
                            >
                                <MdAdd />
                            </Button>
                        )}
                        <EntityPageToolbar>
                            <EntityPageToolbarButton
                                tabId={TAB.CHART}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.chart')}
                            >
                                <GraphIcon className={style.icon} />
                            </EntityPageToolbarButton>
                            <EntityPageToolbarButton
                                tabId={TAB.TABLE}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.table')}
                            >
                                <ViewList />
                            </EntityPageToolbarButton>
                            <EntityPageToolbarButton
                                tabId={TAB.MAINTENANCE}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.maintenance')}
                            >
                                <MaintenanceIcon />
                            </EntityPageToolbarButton>
                            {user?.superadmin && (
                                <EntityPageToolbarButton
                                    tabId={TAB.ASSET_LOCATIONS}
                                    activeTabId={tab}
                                    handleTabChange={handleTabChange}
                                    label={generalTranslation('assetPageTitle.asset_locations')}
                                >
                                    <AssetLocationsIcon className={style.icon} />
                                </EntityPageToolbarButton>
                            )}
                        </EntityPageToolbar>

                        <TableActionsEntityContainer
                            entityType="asset"
                            id={asset.id}
                            asset={asset}
                            params={dates}
                            className={style.actionsWrapper}
                            remove={false}
                            defaultActions={tab === TAB.TABLE ? [DownloadExtension.Csv, DownloadExtension.Excel] : []}
                            extraActions={
                                [
                                    user?.superadmin
                                        ? {
                                              id: 'go-to-forecast-settings',
                                              link: true,
                                              url: getForecastSettingsUrl(),
                                              callback: () => getForecastSettingsUrl,
                                              label: generalTranslation('goToForecastSettings')
                                          }
                                        : null,
                                    tab === TAB.CHART
                                        ? {
                                              id: 'download',
                                              label: generalTranslation('toolbar.downloadChart'),
                                              callback: handleDownload
                                          }
                                        : null
                                ].filter((x) => x) as TableAction[]
                            }
                        />
                    </Box>
                </Box>

                <Box className={style.subHeaderContainer}>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">{generalTranslation('assetInfo.type')}</Typography>
                        <Typography variant="small3" sx={{ ml: 1.5 }}>
                            {generalTranslation(`assetTypes.${asset.type}`)}
                        </Typography>
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">{generalTranslation('assetInfo.country')}</Typography>
                        {asset.country_code ? (
                            <>
                                <Flag
                                    height="24"
                                    width="24"
                                    code={asset.country_code}
                                    style={{ margin: '0 8px' }}
                                    className="flagIcon"
                                />
                                <Typography variant="small3">{asset.country_code!.toUpperCase()}</Typography>
                            </>
                        ) : (
                            NO_VALUE
                        )}
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">{generalTranslation('assetInfo.location')}</Typography>

                        <Box className={style.location} sx={{ ml: 1.5 }} onClick={handleLocationOpen}>
                            <LocationSvg style={{ marginRight: '4px', minHeight: '14px', minWidth: '14px' }} />
                            <Typography variant="small4">{asset?.location || NO_VALUE}</Typography>
                        </Box>
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">{generalTranslation('assetInfo.totalCapacity')}</Typography>
                        <Typography variant="small3" sx={{ ml: 1.5 }}>
                            {getFormattedCapacityValue(asset.capacity_value)}
                        </Typography>
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">{generalTranslation('assetInfo.status')}</Typography>
                        <Box sx={{ ml: 1.5 }}>
                            <AssetStatusLabel status={asset.status} variant="small3" />
                        </Box>
                    </Box>
                    <Box className={style.subHeaderElement}>
                        <Typography variant="small4">{generalTranslation('assetInfo.expiryDate')}</Typography>
                        <Typography variant="small3" sx={{ ml: 1.5 }}>
                            {getFormattedDate({ value: asset.expiry_date, fallback: NO_VALUE })}
                        </Typography>
                    </Box>
                </Box>

                {tab === TAB.CHART && (
                    <TradingView
                        data={forecasts}
                        fetchMoreFn={fetchMore}
                        error={errorForecast || errorMeasured}
                        loading={loadingForecast || loadingMeasured}
                        assetMeasureUnit={assetUnit as UNIT}
                        // limits={[limitLeftForecastBoundary, limitLeftMeasuredBoundary]}
                    />
                )}
                {tab === TAB.TABLE && (
                    <MemoizedGenerationTable
                        pager={pager}
                        setGetParams={setGetParamsAssetPage}
                        data={paginatedData}
                        fetchMoreFn={fetchGenerationForecast}
                        setTablePayload={setGenerationTablePayloadTemp}
                        error={errorForecast}
                        loading={loadingForecast}
                        success={successForecast}
                        assetMeasureUnit={assetUnit as UNIT}
                        timezone={asset.timezone}
                    />
                )}
                {tab === TAB.MAINTENANCE && <MaintenanceContainer assetId={asset.id} />}
                {tab === TAB.ASSET_LOCATIONS && <LocationsTable asset={asset} />}
            </Box>
            {asset && (
                <AssetLocationModal
                    payload={{
                        longitude: asset.longitude,
                        latitude: asset.latitude,
                        title: asset.name,
                        location: asset.location || '',
                        type: asset.type
                    }}
                />
            )}
            {asset && <AddAssetLocationsModal assetId={asset.id} />}
            {!!selectedLocations.length && <IngestMultipleLocationsModal data={selectedLocations} asset={asset} />}
        </>
    );
};

export default AssetPage;
