import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Loading from '../../layout/Loading';
import { GenericTable } from '../BaseTable';
import { ApiPaginationInfo } from '../../interfaces/apiv2';
import GenerationTableToolbar from './GenerationTableToolbar';
import { renderFooterContent, getTableHeadCells } from './helpers';
import { ForecastFE, GenerationTableForecastDataPayload, UNIT } from '../../interfaces/uiv2';

import style from './style.module.scss';
import classNames from 'classnames';

interface GenerationTableProps {
    data: ForecastFE[];
    error: boolean;
    loading: boolean;
    success: boolean;
    fetchMoreFn: (payload: GenerationTableForecastDataPayload) => void;
    pager: ApiPaginationInfo | null;
    setGetParams: any;
    setTablePayload?: any;
    assetMeasureUnit: UNIT;
    timezone?: string;
    editTable?: boolean;
    editCb?: any;
    disabledToolbar?: any;
    smallTable?: boolean;
}

const GenerationTable = ({
    data: forecasts,
    loading,
    success,
    fetchMoreFn,
    pager,
    setGetParams,
    setTablePayload,
    assetMeasureUnit,
    timezone,
    editTable,
    editCb,
    smallTable,
    disabledToolbar
}: GenerationTableProps) => {
    const { t } = useTranslation();
    const headCells = getTableHeadCells({ t, assetMeasureUnit });

    const tableFooter = renderFooterContent(forecasts, t);

    // TODO: implement sort on table when api is working or only client side
    const sort = {};
    return (
        <Box className={style.wrapper}>
            {!disabledToolbar && (
                <GenerationTableToolbar
                    fetchMoreFn={fetchMoreFn}
                    setTablePayload={setTablePayload}
                    timezone={timezone}
                />
            )}

            <Box className={style.generationTableContainer}>
                {loading ? (
                    <Loading />
                ) : (
                    <GenericTable
                        pager={pager}
                        className={classNames(style.generationTable, smallTable && style.smallTable)}
                        rows={forecasts}
                        loading={loading}
                        success={success}
                        setGetParams={setGetParams}
                        columns={headCells}
                        rowUniqueId={'index'}
                        storeSort={sort}
                        customFooter={tableFooter}
                        editParams={{ cb: editCb, tableMode: editTable }}
                    />
                )}
            </Box>
        </Box>
    );
};

export const MemoizedGenerationTable = React.memo(GenerationTable);

export default GenerationTable;
