import { isEqual } from 'lodash';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Divider, InputLabel, SvgIcon, TextField, Typography } from '@mui/material';

import { MODAL_IDS } from '..';
import { transformer } from './helpers';
import { ModalContext } from '../../hooks/modalContext';
import { useAppDispatch, useAppSelector } from '../../stores';
import { ReactComponent as VPPSVG } from '../../assets/icons/vpp.svg';
import { FormErrorStep, FormModalActions, FormSuccessStep } from '../../components/FormUtils';
import { getListVPPAssetsAsync, getVPPAssetsAsync, resetcreateVPPState, updateVPPAsync } from '../../stores/VPP';

import style from './style.module.scss';
import { VPPFE } from '../../interfaces/uiv2';
import { PatchVPPBody } from '../../interfaces/apiv2';

const AddToVppForm = () => {
    const dispatch = useAppDispatch();
    const { payload } = useContext(ModalContext);
    const { t: generalTranslation } = useTranslation();
    const { t } = useTranslation('components/delete-card-modal');

    const { vppFE } = useAppSelector((state) => state.listVPP);
    const {
        generationAssetsFE,
        loading: loadingAssets,
        error: errorRetrievingAssets
    } = useAppSelector((state) => state.getVPPAssets);
    const getParams = useAppSelector((state) => state.listVPP.getParams, isEqual);
    const { success, loading, error } = useAppSelector((state) => state.createVPP);

    const rows = useMemo(() => transformer(vppFE), [vppFE]);

    const [open, setOpen] = useState(false);
    const [selectedVPP, setSelectedVPP] = useState<VPPFE | null>(null);

    useEffect(() => {
        if (selectedVPP) {
            dispatch(getVPPAssetsAsync({ id: selectedVPP.id }));
        }
    }, [selectedVPP]);

    useEffect(() => {
        dispatch(getListVPPAssetsAsync({ ...getParams }));

        return () => {
            dispatch(resetcreateVPPState());
        };
    }, []);

    const handleIdChange = (e: VPPFE) => () => {
        setSelectedVPP(e);
        setOpen(false);
    };

    const handleChange = () => {
        setSelectedVPP(null);
        setOpen(false);
    };

    const handleSubmit = () => {
        if (selectedVPP) {
            const data: PatchVPPBody = {
                id: selectedVPP.id,
                payload: {
                    name: selectedVPP.name,
                    add_asset_ids: [...generationAssetsFE.map((e) => e.id)],
                    remove_asset_ids: []
                }
            };

            if (payload?.asset) {
                data.payload.add_asset_ids.push(payload.asset.id);
            }

            dispatch(updateVPPAsync(data));
        }
    };

    const assetAlreadyInVPP = generationAssetsFE.some((e) => e.id === payload?.asset.id);

    return (
        <Box className={style.box}>
            {!(success || error) ? (
                <>
                    <Typography className={style.title} variant="h6">
                        {t('addToVpp')}
                    </Typography>

                    <Typography variant="body1" className={style.label}>
                        {t('addToVppLabel')}
                    </Typography>

                    <InputLabel variant="standard" required shrink htmlFor={'message'}>
                        {t('vpp')}
                    </InputLabel>
                    <Autocomplete
                        value={selectedVPP}
                        id="asynchronous-assets"
                        className={open ? style.autocompleteOpen : ''}
                        onChange={handleChange}
                        noOptionsText={generalTranslation('select.noOptions')}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={rows}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props: any, e: VPPFE) => (
                            <Box component="li" {...props} key={Math.random()} onClick={handleIdChange(e)}>
                                <VPPSVG className={classNames(style.mr8, e.is_implicit ? style.autogenerated : '')} />
                                {e.name}
                            </Box>
                        )}
                        ListboxProps={{ style: { maxHeight: '180px' } }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: t('selectVpp')
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: <>{params.InputProps.endAdornment}</>,
                                    startAdornment: (
                                        <SvgIcon
                                            component={VPPSVG}
                                            sx={{ '> path': selectedVPP?.is_implicit ? { fill: '#E5253D' } : {} }}
                                        />
                                    )
                                }}
                            />
                        )}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        clearOnEscape={true}
                        clearOnBlur={true}
                        filterOptions={(x) => x}
                    />

                    {assetAlreadyInVPP && (
                        <Typography variant="small2" className={style.label}>
                            {t('assetAlreadyInVPP')}
                        </Typography>
                    )}

                    <Divider className={style.line} />

                    <FormModalActions
                        loading={loading}
                        disabled={!selectedVPP || loadingAssets || errorRetrievingAssets || assetAlreadyInVPP}
                        modalId={MODAL_IDS.ADD_TO_VPP_MODAL}
                        cancelLabel={generalTranslation('nevermind')}
                        saveLabel={generalTranslation('table.general.add')}
                        onSubmit={handleSubmit}
                    />
                </>
            ) : null}
            {success ? <FormSuccessStep /> : null}
            {error ? <FormErrorStep /> : null}
        </Box>
    );
};

export default AddToVppForm;
