import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../../../utils/axios';
import i18next from '../../../../utils/i18next';
import ToastifyType from '../../../../utils/toastify-config';
import { DefaultInitialState } from '../../../../interfaces/redux';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';

interface DeleteConsumersState extends DefaultInitialState {
    deleted: number;
}

const initialState: DeleteConsumersState = {
    loading: false,
    error: false,
    success: false,
    deleted: 0
};

export const deleteGasConsumersListAsync = createAsyncThunk(
    'gas-consumers_list/delete',
    async ({ ids, projectId }: { ids: string[]; projectId: string }, { rejectWithValue }) => {
        try {
            await Promise.all(
                ids.map((consumer_id) => {
                    return axios.delete(`/gas/demand/${projectId}/consumers/${consumer_id}`);
                })
            );
            return null;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const deleteGasConsumers = createSlice({
    name: 'deleteGasConsumers',
    initialState,
    reducers: {
        resetDeleteGasConsumersListState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(deleteGasConsumersListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(deleteGasConsumersListAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.deleted += 1;
        });
        builder.addCase(deleteGasConsumersListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetDeleteGasConsumersListState } = deleteGasConsumers.actions;

export default deleteGasConsumers.reducer;
