import dayjs from 'dayjs';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import {
    resetGasReportPage,
    getGasReportingAsync,
    getGasReportingMoreAsync,
    setGetParamsGasReportPage,
    downloadGasReportAsync
} from '../../../stores/GasReports/gasReportPage';
import { TAB } from '../../../interfaces/general';
import Chart from '../../DeliveredForecast/Chart';
import Table from '../../DeliveredForecast/Table';
import { DATE_SETTINGS } from '../../../utils/config';
import { getFormattedDate } from '../../../utils/helpers';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../../utils/table';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ReactComponent as ViewList } from '../../../assets/icons/view_list.svg';
import { ReactComponent as GraphIcon } from '../../../assets/icons/timeline.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../../components/EntityPageToolbar';

import style from '../../../features/asset-page/asset-page.module.scss';

export const GasReportPage = () => {
    const dispatch = useAppDispatch();
    const { projectId: project_id = '', id = '' } = useParams();
    const { t: generalTranslation } = useTranslation();
    const [tab, setTab] = useState<TAB>(TAB.TABLE);

    const [searchParams] = useSearchParams();

    const from_datetime = searchParams.get('from') || '';
    const to_datetime = searchParams.get('to') || '';

    const subTitle = `${getFormattedDate({
        value: +from_datetime,
        type: DATE_SETTINGS.shortFormat,
        fallback: DEFAULT_EMPTY_CELL_VALUE,
        timezone: dayjs.tz.guess()
    })} - ${getFormattedDate({
        value: +to_datetime,
        type: DATE_SETTINGS.shortFormat,
        fallback: DEFAULT_EMPTY_CELL_VALUE,
        timezone: dayjs.tz.guess()
    })}`;

    const {
        chartData,
        pager,
        error,
        data,
        loading,
        paginatedData,
        getParams: { page }
    } = useAppSelector((state) => state.gasReportPage);

    useEffect(() => {
        dispatch(getGasReportingAsync({ from_datetime, to_datetime, project_id }));

        return () => {
            dispatch(resetGasReportPage());
        };
    }, []);

    const fetchMoreGas = (p?: number) => {
        if (pager) {
            const page = p || pager.page + 1;
            if (page <= pager.pages) {
                dispatch(getGasReportingMoreAsync({ from_datetime, to_datetime, project_id, page }));
            }
        }
    };

    useEffect(() => {
        fetchMoreGas(page);
    }, [page]);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    const handleDownload = () => {
        dispatch(
            downloadGasReportAsync({
                id: id,
                projectId: project_id,
                from_date: from_datetime,
                to_date: to_datetime
            })
        );
    };

    return (
        <Box className={classNames(style.pageContainer)}>
            <Box className={style.headerContainer}>
                <Box className={style.header}>
                    <PageHeader
                        className={style.title}
                        id={'demo'}
                        title={generalTranslation('title.deliveredForecast')}
                        subtitle={subTitle}
                    />
                    <Box className={style.actionsContainer}>
                        <EntityPageToolbar>
                            <EntityPageToolbarButton
                                tabId={TAB.CHART}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.chart')}
                            >
                                <GraphIcon />
                            </EntityPageToolbarButton>
                            <EntityPageToolbarButton
                                tabId={TAB.TABLE}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={generalTranslation('assetPageTitle.table')}
                            >
                                <ViewList />
                            </EntityPageToolbarButton>
                            <EntityPageToolbarButton
                                tabId={TAB.ASSETS}
                                activeTabId={tab}
                                handleTabChange={handleDownload}
                                label={'Download'}
                            >
                                <DownloadIcon />
                            </EntityPageToolbarButton>
                        </EntityPageToolbar>
                    </Box>
                </Box>
            </Box>

            {tab === TAB.CHART && (
                <Chart chartData={chartData} data={data} error={error} loading={loading} fetchMoreGas={fetchMoreGas} />
            )}
            {tab === TAB.TABLE && (
                <Table
                    error={error}
                    pager={pager}
                    loading={loading}
                    data={paginatedData}
                    fetchMoreGas={fetchMoreGas}
                    setGetParams={setGetParamsGasReportPage}
                />
            )}
        </Box>
    );
};
