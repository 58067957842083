import { DefaultEntityTimestamps } from '../../interfaces/apiv2';

export interface TLCluster extends DefaultEntityTimestamps {
    id: string;
    name: string;
    pods: number;
}

export interface TLConsumer extends DefaultEntityTimestamps {
    id: string;
    location: string;
    location_type: string;
    industry: string;
    profile: string;
    voltage: string;
    dso: string;
    expiry_date: string;
    longitude: number;
    latitude: number;
}

export interface TLCirculation extends DefaultEntityTimestamps {
    id: string;
    name: string;
    voltage: string;
    dso: string;
    expiry_date: string;
}

export interface TLConsumerFE extends TLConsumer {
    index: number;
}
export interface TLCirculationFE extends TLCirculation {
    index: number;
}
export interface TLClusterFE extends TLCluster {
    index: number;
}

export const staticTLClusters: TLCluster[] = [
    {
        id: '1',
        name: 'Consumatori Bucuresti',
        pods: 3,
        created_at: '2022-01-05',
        updated_at: ''
    },
    {
        id: '2',
        name: '011ADF038090',
        pods: 2,
        created_at: '2022-03-10',
        updated_at: ''
    },
    {
        id: '3',
        name: 'Consumatori Brasov',
        pods: 1,
        created_at: '2022-02-15',
        updated_at: ''
    },
    {
        id: '4',
        name: 'Consumatori Constanta',
        pods: 5,
        created_at: '2022-04-20',
        updated_at: ''
    },
    {
        id: '5',
        name: 'Consumatori Craiova',
        pods: 4,
        created_at: '2022-01-10',
        updated_at: ''
    },
    {
        id: '6',
        name: '011ADF038092',
        pods: 2,
        created_at: '2022-03-25',
        updated_at: ''
    },
    {
        id: '7',
        name: '011ADF034991',
        pods: 3,
        created_at: '2022-02-12',
        updated_at: ''
    },
    {
        id: '8',
        name: 'Consumers Oradea',
        pods: 1,
        created_at: '2022-05-01',
        updated_at: ''
    },
    {
        id: '9',
        name: 'Consumers Cluj',
        pods: 2,
        created_at: '2022-02-28',
        updated_at: ''
    },
    {
        id: '10',
        name: '011ADF03455901',
        pods: 3,
        created_at: '2022-03-15',
        updated_at: ''
    },
    {
        id: '11',
        name: '024ADF03455901',
        pods: 1,
        created_at: '2022-04-05',
        updated_at: ''
    },
    {
        id: '12',
        name: 'My Cluster 5',
        pods: 4,
        created_at: '2022-01-20',
        updated_at: ''
    }
];
export const staticTLConsumers: TLConsumer[] = [
    {
        id: '011ADF038090',
        location: 'Ilfov',
        location_type: 'Urban',
        industry: 'Medical',
        profile: 'Profile 1',
        voltage: 'HV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2015-11-08',
        expiry_date: '2023-10-23',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Fantanele',
        location_type: 'Rural',
        industry: 'Auto',
        profile: 'Profile 2',
        voltage: 'LV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2023-04-28',
        expiry_date: '2024-09-15',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Baneasa',
        location_type: 'Urban',
        industry: 'Textile',
        profile: 'Profile 3',
        voltage: 'HV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2018-08-21',
        expiry_date: '2023-11-10',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Bucuresti',
        location_type: 'Urban',
        industry: 'Medical',
        profile: 'Profile 4',
        voltage: 'LV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2014-10-20',
        expiry_date: '2027-02-16',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Constanta',
        location_type: 'Urban',
        industry: 'Textile',
        profile: 'Profile 5',
        voltage: 'LV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2021-05-24',
        expiry_date: '2026-10-09',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Ilfov',
        location_type: 'Urban',
        industry: 'Hidroagriculture',
        profile: 'Profile 6',
        voltage: 'LV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2016-06-14',
        expiry_date: '2025-06-06',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Fantanele',
        location_type: 'Urban',
        industry: 'Textile',
        profile: 'Profile 7',
        voltage: 'HV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2015-09-19',
        expiry_date: '2025-09-29',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Baneasa',
        location_type: 'Urban',
        industry: 'Hidroagriculture',
        profile: 'Profile 8',
        voltage: 'HV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2020-10-01',
        expiry_date: '2025-05-18',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Bucuresti',
        location_type: 'Rural',
        industry: 'Auto',
        profile: 'Profile 9',
        voltage: 'HV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2018-03-08',
        expiry_date: '2025-04-10',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Constanta',
        location_type: 'Urban',
        industry: 'Textile',
        profile: 'Profile 10',
        voltage: 'LV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2019-04-14',
        expiry_date: '2024-11-12',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Bucuresti',
        location_type: 'Urban',
        industry: 'Hidroagriculture',
        profile: 'Profile 11',
        voltage: 'HV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2019-11-06',
        expiry_date: '2027-01-04',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Constanta',
        location_type: 'Rural',
        industry: 'Hidroagriculture',
        profile: 'Profile 12',
        voltage: 'HV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2018-06-30',
        expiry_date: '2024-04-15',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Constanta',
        location_type: 'Urban',
        industry: 'Medical',
        profile: 'Profile 13',
        voltage: 'HV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2022-10-24',
        expiry_date: '2026-01-03',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Bucuresti',
        location_type: 'Rural',
        industry: 'Auto',
        profile: 'Profile 14',
        voltage: 'HV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2014-03-04',
        expiry_date: '2024-09-17',
        updated_at: ''
    },
    {
        id: '011ADF038090',
        location: 'Constanta',
        location_type: 'Rural',
        industry: 'Hidroagriculture',
        profile: 'Profile 15',
        voltage: 'LV',
        longitude: 0,
        latitude: 0,
        dso: 'Enel Muntenia',
        created_at: '2022-10-25',
        expiry_date: '2026-05-01',
        updated_at: ''
    }
];

export const staticTLCirculations: TLCirculation[] = [
    {
        id: '64662e38480a36790bb8a1f3',
        name: 'Technical Losses',
        voltage: 'HV',
        dso: 'Enel Vest',
        created_at: '2021-10-01',
        expiry_date: '2024-09-27',
        updated_at: ''
    },
    {
        id: '64662e38fb31922040e8b123',
        name: 'TL Brasov',
        voltage: 'HV',
        dso: 'EON Bucuresti',
        created_at: '2019-06-17',
        expiry_date: '2026-06-06',
        updated_at: ''
    },
    {
        id: '64662e381c985db4c7a1db2c',
        name: 'TL ADF 90112',
        voltage: 'HV',
        dso: 'Enel Muntenia',
        created_at: '2022-04-14',
        expiry_date: '2023-09-17',
        updated_at: ''
    },
    {
        id: '64662e3828bc5ddc2f43da3c',
        name: 'Generation 1',
        voltage: 'LV',
        dso: 'EON Bucuresti',
        created_at: '2020-07-29',
        expiry_date: '2024-09-21',
        updated_at: ''
    },
    {
        id: '64662e38a058ed54160ee159',
        name: 'Injected MV',
        voltage: 'LV',
        dso: 'EON Bucuresti',
        created_at: '2022-03-02',
        expiry_date: '2024-03-08',
        updated_at: ''
    },
    {
        id: '64662e38a17c79ab8254bf81',
        name: 'Injected LV',
        voltage: 'HV',
        dso: 'EON Marea Neagra',
        created_at: '2019-04-12',
        expiry_date: '2025-06-01',
        updated_at: ''
    },
    {
        id: '64662e38a8ec46abea424223',
        name: 'Injected HV',
        voltage: 'LV',
        dso: 'Enel Muntenia',
        created_at: '2016-04-01',
        expiry_date: '2026-08-07',
        updated_at: ''
    },
    {
        id: '64662e38819d3465e1f57caf',
        name: 'TL LV',
        voltage: 'HV',
        dso: 'EON Marea Neagra',
        created_at: '2015-12-08',
        expiry_date: '2023-12-12',
        updated_at: ''
    },
    {
        id: '64662e38a53c09d6a5e681b1',
        name: 'TL MV',
        voltage: 'LV',
        dso: 'Enel Muntenia',
        created_at: '2020-10-13',
        expiry_date: '2024-03-07',
        updated_at: ''
    },
    {
        id: '64662e3845fa42f1ab82dfbe',
        name: 'TL HV',
        voltage: 'HV',
        dso: 'EON Bucuresti',
        created_at: '2019-04-15',
        expiry_date: '2024-02-10',
        updated_at: ''
    },
    {
        id: '64662e3869e2c67b093c6e9e',
        name: 'TL Bucuresti',
        voltage: 'LV',
        dso: 'EON Bucuresti',
        created_at: '2019-01-16',
        expiry_date: '2026-02-06',
        updated_at: ''
    },
    {
        id: '64662e38b206c402381a74fb',
        name: 'TL Constanta',
        voltage: 'LV',
        dso: 'EON Marea Neagra',
        created_at: '2015-07-11',
        expiry_date: '2026-08-30',
        updated_at: ''
    },
    {
        id: '64662e38ff0c786b1940575b',
        name: 'Tranzit',
        voltage: 'HV',
        dso: 'EON Bucuresti',
        created_at: '2015-08-16',
        expiry_date: '2025-05-18',
        updated_at: ''
    },
    {
        id: '64662e38610ae907ef41bf8e',
        name: 'Import',
        voltage: 'LV',
        dso: 'EON Bucuresti',
        created_at: '2014-12-24',
        expiry_date: '2024-11-29',
        updated_at: ''
    },
    {
        id: '64662e3886accc2f3589c4c1',
        name: 'TL Cluj',
        voltage: 'HV',
        dso: 'Enel Vest',
        created_at: '2019-03-12',
        expiry_date: '2023-09-13',
        updated_at: ''
    },
    {
        id: '64662e38240096adffe6fff3',
        name: 'TL Craiova',
        voltage: 'HV',
        dso: 'Enel Vest',
        created_at: '2014-05-02',
        expiry_date: '2024-01-07',
        updated_at: ''
    }
];
