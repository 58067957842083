import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';

import { MODAL_IDS } from '../../modals';
import { ModalContext } from '../../hooks/modalContext';
import { GenericTable } from '../../components/BaseTable';
import { useAppSelector } from '../../stores';
import { AssetLocationModal } from '../../modals/AssetLocationModal';
import { getTableHeadCells } from '../admin-generation/AssetListWrapper/helpers';
import { transformOrderByToSort } from '../../components/BaseTable/GenericTable/helpers';
import { GenerationAssetFE } from '../../interfaces/uiv2';
import { ROUTES } from '../../utils/config';

interface ISimplifiedAssetListProps {
    assets: GenerationAssetFE[];
    getParams: any;
    setGetParams: any;
    setSelectedRows: any;
    options?: any;
    tl?: boolean;
}

const SimplifiedAssetList = ({
    assets,
    getParams,
    setGetParams,
    setSelectedRows,
    options = {},
    tl = false
}: ISimplifiedAssetListProps) => {
    const { t } = useTranslation();
    const { t: tList } = useTranslation('assets/generation');

    const { handleClickOpen } = useContext(ModalContext);

    const { selectedRows, loading, pager, success } = useAppSelector((state) => state.listAssets);

    const [assetLocation, setAssetLocation] = useState<GenerationAssetFE>();

    const headCells = getTableHeadCells({ tList, t, isAdmin: false });

    const openLocationModal = (row: GenerationAssetFE) => () => {
        setAssetLocation(row);
        handleClickOpen(MODAL_IDS.ASSET_LOCATION_MODAL);
    };

    const getAssetPageUrl = (row: GenerationAssetFE) => () => {
        if (row.is_mock) {
            return `${ROUTES.AI_GENERATION_MOCK_ASSETS_PAGE.replace(':id', row.id)}?name=${row.name}`;
        }
        if (tl) {
            return `${ROUTES.AI_TL_GENERATION_ASSETS_PAGE.replace(':id', row.id)}?name=${row.name}`;
        }
        return `${ROUTES.AI_GENERATION_ASSETS_PAGE.replace(':id', row.id)}?name=${row.name}`;
    };

    const addToVpp = (row: GenerationAssetFE) => {
        handleClickOpen(MODAL_IDS.ADD_TO_VPP_MODAL, { asset: row });
    };

    return (
        <>
            <GenericTable
                rows={assets}
                pager={pager}
                success={success}
                className={options.className || ''}
                loading={loading}
                isCheckboxEnabled={false}
                selectableRows={false}
                columns={headCells}
                rowUniqueId={'id'}
                selectedRows={selectedRows}
                setGetParams={setGetParams}
                searchParam={getParams.search}
                setSelectedRows={setSelectedRows}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getAssetPageUrl,
                    location: openLocationModal,
                    addToVpp: options.addToVpp ? addToVpp : null
                }}
            />
            {assetLocation && (
                <AssetLocationModal
                    payload={{
                        longitude: assetLocation.longitude,
                        latitude: assetLocation.latitude,
                        title: assetLocation.name,
                        location: assetLocation.location || '',
                        type: assetLocation.type
                    }}
                />
            )}
        </>
    );
};

export default SimplifiedAssetList;
