import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { PostIngestLocationsBody } from '../../interfaces/uiv2';

interface ingestLocationsState extends DefaultInitialState {
    ingested: number;
}

const initialState: ingestLocationsState = {
    loading: false,
    error: false,
    success: false,
    ingested: 0
};

export const ingestLocationsAsync = createAsyncThunk(
    'assets/ingest-locations',
    async (body: PostIngestLocationsBody, { rejectWithValue }) => {
        try {
            const response = await axios.post<null>('/developer/meteo-ingest/ingest-meteo', body);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const ingestLocations = createSlice({
    name: 'ingestLocations',
    initialState,
    reducers: {
        resetIngestLocationsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(ingestLocationsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(ingestLocationsAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(ingestLocationsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetIngestLocationsState } = ingestLocations.actions;

export default ingestLocations.reducer;
