import { SvgIcon } from '@mui/material';
import { createTheme } from '@mui/material';

import { ReactComponent as CheckboxDefaultVSG } from '../../assets/icons/checkbox-default.svg';
import { ReactComponent as CheckboxCheckedSVG } from '../../assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxIndeterminateSVG } from '../../assets/icons/checkbox-indeterminate.svg';

export const styleguide = {
    primaryColor: '#3745F2',
    primaryTextColor: '#323232',
    primaryHoverColor: '#7c85fc',
    defaultLabelColor: '#6a9bff',
    defaultBadgeColor: '#e55151',
    defaultAvatarColor: '#7b61ff',
    defaultInputTextColor: '#6a9bff',
    defaultBorderColor: '#d5e3ff !important',
    defaultLightBackground: '#f8fbff',
    defaultFontSize: '14px',
    defaultBorderRadius: '8px',
    primaryFontFamily: "'Montserrat', sans-serif",
    primaryDisabledColor: '#e6eefe',
    whiteColor: '#fff',
    barPendingColor: '#FFD96480',
    defaultSuccessColor: '#6FD8B2',
    lightBlue: '#F1F6FF',
    gray: '#979797',
    darkGrey: '#808080'
};

const typography = {
    fontFamily: styleguide.primaryFontFamily,
    htmlFontSize: 16,
    h1: {
        fontWeight: 700,
        fontSize: '3rem', // 48px
        lineHeight: '3.375rem'
    },
    h2: {
        fontWeight: 600,
        fontSize: '2.5rem', // 40px
        lineHeight: '2.75rem'
    },
    h3: {
        fontWeight: 400,
        fontSize: '2.125rem', // 34px
        lineHeight: '2.375rem'
    },
    h4: {
        fontWeight: 700,
        fontSize: '1.75rem', // 28px
        lineHeight: '2rem'
    },
    h5: {
        fontWeight: 400,
        fontSize: '1.5rem', // 24px
        lineHeight: '1.75rem'
    },
    h6: {
        fontWeight: 700,
        fontSize: '1.25rem', // 20px
        lineHeight: '1.5rem'
    },
    h7: {
        fontWeight: 700,
        fontSize: '1rem', // 16px
        lineHeight: '1.25rem'
    },
    body1: {
        fontWeight: 400,
        fontSize: '1rem', // 16px
        lineHeight: '1.25rem'
    },
    body2: {
        fontWeight: 700,
        fontSize: '0.75rem', // 12px
        lineHeight: '1rem'
    },
    small1: {
        fontWeight: 400,
        fontSize: '0.875rem', // 14px
        lineHeight: '1.125rem'
    },
    small2: {
        fontWeight: 400,
        fontSize: '0.688rem', // 11px
        lineHeight: '0.875rem'
    },
    small3: {
        fontWeight: 700,
        fontSize: '0.75rem', // 12px
        lineHeight: '1rem'
    },
    small4: {
        fontWeight: 400,
        fontSize: '0.75rem', // 12px
        lineHeight: '1rem'
    },
    verySmall: {
        fontWeight: 400,
        fontSize: '0.5rem', // 8px
        lineHeight: '0.625rem'
    },
    verySmallBold: {
        fontWeight: 700,
        fontSize: '0.5rem', // 8px
        lineHeight: '0.625rem'
    },
    tableHeaderCell: {
        fontWeight: 700,
        fontSize: '0.875rem', // 14px
        lineHeight: '1.125rem'
    },
    formFooterNote: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '11.11px',
        lineHeight: '23px',
        color: styleguide.defaultLabelColor
    }
};

export const Theme = createTheme({
    palette: {
        primary: {
            main: styleguide.primaryColor
        },
        info: {
            main: styleguide.defaultLabelColor
        },
        success: {
            main: '#6FD8B2'
        }
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    background: 'white',
                    color: styleguide.primaryTextColor,
                    boxShadow: '0px 10px 40px rgba(44, 62, 80, 0.25)',
                    borderRadius: styleguide.defaultBorderRadius,
                    padding: '8px 12px',
                    ...typography.small4
                },
                arrow: {
                    color: 'white',
                    background: 'transparent'
                }
            },
            defaultProps: {
                arrow: true,
                enterDelay: 700,
                enterNextDelay: 700,
                enterTouchDelay: 700,
                leaveDelay: 0,
                leaveTouchDelay: 0
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    letterSpacing: '-2px',
                    textIndent: '-1px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 16,
                    lineHeight: 32,
                    height: 32,
                    width: 32
                }
            },
            variants: [
                {
                    props: { variant: 'userAccount' },
                    style: {
                        background: styleguide.defaultAvatarColor,
                        letterSpacing: 0,
                        textIndent: 0
                    }
                }
            ]
        },
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    color: styleguide.primaryTextColor,
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    lineHeight: '1.125rem',

                    '&::placeholder': {
                        opacity: 1,
                        color: '#828282'
                    }
                },
                listbox: {
                    color: styleguide.primaryTextColor,
                    fontSize: styleguide.defaultFontSize
                }
            }
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    '.MuiBadge-badge': {
                        background: styleguide.defaultBadgeColor,
                        borderRadius: 50,
                        lineHeight: 20
                    }
                }
            }
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiButton: {
            variants: [
                {
                    props: {
                        variant: 'primary'
                    },
                    style: {
                        fontSize: '0.875rem',
                        fontWeight: '700',
                        lineHeight: '1.75rem',

                        background: styleguide.primaryColor,
                        color: styleguide.whiteColor,
                        borderRadius: '8px',
                        textTransform: 'none',
                        '&:hover': {
                            background: styleguide.primaryHoverColor
                        },
                        '&:disabled': {
                            background: styleguide.primaryDisabledColor
                        },

                        padding: '12px 24px',
                        height: 40,
                        minHeight: 40
                    }
                },
                {
                    props: {
                        variant: 'secondary'
                    },
                    style: {
                        fontSize: '0.875rem',
                        fontWeight: '700',
                        lineHeight: '1.75rem',

                        color: styleguide.primaryColor,
                        background: 'transparent',
                        textTransform: 'none',
                        borderRadius: '16px',
                        border: `2px solid ${styleguide.primaryColor}`,

                        '&:hover': {
                            color: styleguide.primaryHoverColor,
                            borderColor: styleguide.primaryHoverColor,
                            background: 'transparent'
                        }
                    }
                },
                {
                    props: {
                        variant: 'text'
                    },
                    style: {
                        fontSize: '0.875rem',
                        fontWeight: '700',
                        lineHeight: '1.75rem',

                        color: styleguide.primaryColor,
                        background: 'transparent',
                        textTransform: 'none',

                        '&:hover': {
                            background: 'transparent'
                        },

                        '&.lightFont': {
                            fontWeight: '400'
                        }
                    }
                },
                {
                    props: { variant: 'secondary-light' },
                    style: {
                        color: styleguide.defaultLabelColor,
                        background: 'transparent',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: '16px',
                        borderColor: styleguide.defaultBorderColor,
                        '&:hover': {
                            color: styleguide.primaryHoverColor,
                            borderColor: styleguide.primaryHoverColor,
                            background: 'transparent'
                        }
                    }
                },
                {
                    props: {
                        variant: 'white'
                    },
                    style: {
                        fontSize: '0.875rem',
                        fontWeight: '700',
                        lineHeight: '1.75rem',

                        color: styleguide.primaryColor,
                        background: '#e6eefe',
                        borderRadius: '16px',
                        textTransform: 'none',

                        '&:hover': {
                            color: styleguide.primaryColor,
                            background: '#e6eefe'
                        },

                        padding: '12px 24px'
                    }
                },
                {
                    props: {
                        variant: 'link'
                    },
                    style: {
                        fontWeight: '400',
                        fontSize: '0.875rem',
                        lineHeight: '1.125rem',

                        textTransform: 'none',
                        color: styleguide.primaryColor,
                        '&:hover': {
                            background: 'transparent'
                        }
                    }
                },
                {
                    props: {
                        variant: 'chart-actions'
                    },
                    style: {
                        width: '32px',
                        height: '32px',
                        padding: 0,
                        border: `1px solid ${styleguide.defaultBorderColor}`,
                        minWidth: 'initial',
                        borderRadius: styleguide.defaultBorderRadius,
                        textTransform: 'none',
                        color: styleguide.primaryColor,
                        '&:hover': {
                            background: '#E6EEFE'
                        }
                    }
                },
                {
                    props: {
                        variant: 'action'
                    },
                    style: {
                        fontWeight: '400',
                        fontSize: '0.75rem',
                        lineHeight: '1rem',
                        height: '32px',
                        padding: '6px 16px !important',
                        border: 'none',
                        minWidth: 'initial',
                        borderRadius: '8px',
                        backgroundColor: `${styleguide.lightBlue} !important`,

                        textTransform: 'none',
                        color: styleguide.primaryColor,

                        '&:hover': {
                            backgroundColor: `transparent !important`
                        }
                    }
                },
                {
                    props: {
                        variant: 'cta'
                    },
                    style: {
                        fontSize: '1rem',
                        height: '32px',
                        width: '32px',
                        border: `1px solid ${styleguide.defaultBorderColor}`,
                        color: styleguide.primaryColor,
                        minWidth: '0',
                        padding: '0',
                        borderRadius: '8px',
                        backgroundColor: `${styleguide.whiteColor} !important`,
                        '&:hover': {
                            backgroundColor: `transparent !important`
                        }
                    }
                }
            ],
            defaultProps: {
                disableRipple: true
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    minHeight: '20px',
                    marginBottom: '5px'
                }
            }
        },
        MuiInput: {
            defaultProps: {
                disableUnderline: true
            },
            styleOverrides: {
                root: {
                    border: '1px solid #e6eefe',
                    background: styleguide.whiteColor,
                    borderRadius: '16px',
                    minHeight: '55px',
                    color: styleguide.primaryTextColor,
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    lineHeight: '1.125rem',
                    padding: '7.5px 12px',
                    resize: 'none',
                    boxShadow: 'none',
                    margin: '0',
                    '&.Mui-focused': {
                        border: '1px solid #3346fb'
                    },
                    'input::placeholder': {
                        opacity: 1,
                        color: '#828282'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    border: '1px solid',
                    borderColor: styleguide.defaultBorderColor,
                    borderRadius: styleguide.defaultBorderRadius,
                    '& fieldset': {
                        border: 'none'
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    /*background: 'none',
                    //borderRadius: '8px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '13px',
                    color: '#323232',
                    resize: 'none',
                    boxShadow: 'none',
                    margin: '0',
                    'input::placeholder': {
                        opacity: 1,
                        color: '#828282'
                    }*/
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.permission-checkbox': {
                        color: '#d5e3ff'
                    },
                    '&.Mui-checked.permission-checkbox': {
                        color: '#6fd8b2'
                    }
                }
            },
            defaultProps: {
                icon: <SvgIcon component={CheckboxDefaultVSG} />,
                checkedIcon: <SvgIcon component={CheckboxCheckedSVG} />,
                indeterminateIcon: <SvgIcon component={CheckboxIndeterminateSVG} />
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.permission-checkbox': {
                        color: '#d5e3ff'
                    },
                    '&.Mui-checked.permission-checkbox': {
                        color: '#6fd8b2'
                    }
                }
            },
            defaultProps: {
                icon: <SvgIcon component={CheckboxDefaultVSG} />,
                checkedIcon: <SvgIcon component={CheckboxCheckedSVG} />
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: styleguide.darkGrey,
                    position: 'relative',
                    fontStyle: 'normal',
                    transform: 'none',
                    marginBottom: '8px',
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: '1.5rem'
                },
                standard: {
                    width: '100%',
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: '1rem',
                    margin: 0,
                    marginBottom: '8px'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    background: styleguide.whiteColor,
                    boxShadow: '0px 4px 54px -30px rgba(0, 0, 0, 0.71)',
                    borderRadius: '8px'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: styleguide.defaultBorderColor,
                    boxShadow: '0px 16px 100px rgba(19, 19, 19, 0.06)'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    /*color: styleguide.primaryColor,*/
                    padding: '4px 8px',
                    margin: '2px 8px',
                    borderRadius: '8px',
                    '&:hover': {
                        background: styleguide.lightBlue
                    },
                    "&[aria-selected='true']": {
                        background: styleguide.lightBlue
                    }
                }
            }
        },
        MuiDialogContent: {
            defaultProps: {
                sx: { p: 4, pb: '20px' }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '&.header-search': {
                        '.MuiTextField-root': {
                            width: '100%'
                        },
                        '.MuiInputBase-input': {
                            color: styleguide.defaultInputTextColor,
                            fontSize: styleguide.defaultFontSize,
                            lineHeight: '18px',
                            height: 'auto'
                        },
                        '.MuiInputBase-root': {
                            width: '100%',
                            fieldset: {
                                borderRadius: styleguide.defaultBorderRadius,
                                borderColor: styleguide.defaultBorderColor
                            }
                        },
                        '.MuiInputAdornment-root': {
                            color: styleguide.defaultInputTextColor,
                            fontSize: 24
                        }
                    },
                    '&.table-search': {
                        '.MuiInputBase-input': {
                            padding: '8.5px 14px',
                            color: styleguide.defaultInputTextColor,
                            fontSize: 16,
                            // a little trick to not have a movement when the search is filled
                            border: '1px solid transparent'
                        },
                        '.MuiInputBase-root': {
                            width: '100%',
                            fieldset: {
                                borderRadius: styleguide.defaultBorderRadius,
                                borderColor: styleguide.primaryColor
                            }
                        },
                        '.MuiInputAdornment-root': {
                            color: styleguide.primaryColor,
                            fontSize: 24
                        }
                    }
                }
            },
            defaultProps: {
                inputProps: {
                    autoComplete: 'off'
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '.MuiTabs-flexContainer': {
                        display: 'inline-block',
                        padding: '0 0 16px 26px'
                    },
                    '.MuiTabs-indicator': {
                        display: 'none'
                    }
                }
            }
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    padding: 0,
                    minHeight: 0,
                    minWidth: 0,
                    margin: '0 45px 0 0',
                    borderRadius: '50px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    color: styleguide.primaryColor,
                    '&.Mui-selected': {
                        background: styleguide.primaryColor,
                        padding: '7px 15px',
                        color: styleguide.whiteColor
                    }
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    h7: 'p',
                    body1: 'p',
                    body2: 'span',
                    small1: 'span',
                    small2: 'span',
                    small3: 'span',
                    small4: 'span',
                    verySmall: 'span',
                    verySmallBold: 'span',

                    tableHeaderCell: 'span',
                    formFooterNote: 'span'
                }
            }
        }
    },
    typography
});
