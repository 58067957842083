import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MdAdd, MdDeleteOutline, MdRefresh, MdSchedule, MdTrendingUp } from 'react-icons/md';

import { MODAL_IDS } from '../../../../modals';
import { useIsMount } from '../../../../hooks/isMount';
import { ModalContext } from '../../../../hooks/modalContext';
import useStickyHeader from '../../../../hooks/useStickyHeader';
import { PageContainer } from '../../../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../../../stores';
import { GenericTable, TableSearch } from '../../../../components/BaseTable';
import { DateRangeInput } from '../../../../components/DateRangeInput/DateRangeInput';
import { RemoveForecastContainer } from '../../../../components/RemoveEntityContainer';
import {
    getEVSReportsListAsync,
    resetListEVSReportsState,
    setEVSReportsGetParams,
    setEVSReportsSelectedRows
} from '../../../../stores/EVS';
import { DownloadButton, DownloadExtension } from '../../../../components/DownloadButton';
import { transformOrderByToSort } from '../../../../components/BaseTable/GenericTable/helpers';
import { PAGINATION_SETTINGS, ROUTES, getForecastFilteringFormattedDate } from '../../../../utils/config';

import style from '../../../../features/forecast-list/forecast-list.module.scss';
import { getTableHeadCells } from './helpers';
import { EVSReportFE } from '../../../../interfaces/uiv2/evs/evs_report';
import { useParams } from 'react-router-dom';

const EVSReportsList = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const isFirstRender = useIsMount();
    const { t } = useTranslation();
    const { handleClickOpen } = useContext(ModalContext);

    useStickyHeader('reports-list-header');

    // const { success } = useAppSelector((state) => state.createReports); // change
    // const { success: deleteSuccess } = useAppSelector((state) => state.deleteReports); // change
    const getParams = useAppSelector((state) => state.listEVSReports.getParams, isEqual);
    // const { getParams: assetsGetParams } = useAppSelector((state) => state.listAssets); // change
    const {
        reportsFE: rows,
        loading,
        selectedRows,
        pager,
        success: fetchingSuccess
    } = useAppSelector((state) => state.listEVSReports);

    const headCells = getTableHeadCells(t, params.projectId);
    const [dateRange, setDateRange] = useState<DateRange | null>(null);

    useEffect(() => {
        if (dateRange?.from && dateRange?.to) {
            dispatch(
                setEVSReportsGetParams({
                    from_date__gte: getForecastFilteringFormattedDate(dateRange.from),
                    to_date__lte: dayjs(getForecastFilteringFormattedDate(dateRange.to))
                        .endOf('day')
                        .add(1, 'day')
                        .toDate()
                        .toISOString(),
                    page: PAGINATION_SETTINGS.default_start_page
                })
            );
        }

        if (!dateRange && !isFirstRender) {
            dispatch(
                setEVSReportsGetParams({
                    from_date__gte: undefined,
                    to_date__lte: undefined,
                    page: PAGINATION_SETTINGS.default_start_page
                })
            );
        }
    }, [dateRange]);

    useEffect(() => {
        if (params.projectId) {
            dispatch(getEVSReportsListAsync({ ...getParams, project_id: params.projectId }));
        }
    }, [getParams, params]);

    // useEffect(() => {
    //     if (deleteSuccess || success) {
    //         dispatch(getEVSReportsListAsync({ ...getParams }));
    //     }
    // }, [deleteSuccess, success]);

    const handleSort = (type: string) => () => {
        dispatch(setEVSReportsGetParams({ order_by: [type], page: 1 })); // change
    };

    const clearSelection = () => {
        dispatch(setEVSReportsSelectedRows([])); // change
    };

    const removeAll = () => {
        // dispatch(deleteReportsListAsync(selectedRows.map((a) => a.id))); // change
        clearSelection();
    };

    const getChargingPointPageUrl = (row: EVSReportFE) => () => {
        return `${ROUTES.AI_EVS_CHARGHING_POINTS_PAGE.replace(
            ':projectId',
            row.evs_cp_forecast_settings.charging_point.project.id
        ).replace(':id', row.evs_cp_forecast_settings.charging_point.id)}`;
    };

    const getReportsPageUrl = (row: EVSReportFE) => () => {
        return `${ROUTES.EVS_REPORTS_PAGE.replace(':reportId', row.id).replace(
            ':projectId',
            params.projectId as string
        )}`;
    };

    const openConfirmationModal = () => {
        handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    };

    const handleAddNewReport = () => {
        handleClickOpen(MODAL_IDS.CREATE_EVS_REPORT_MODAL);
    };

    const ids = useMemo(() => {
        return selectedRows.map((e) => e.id);
    }, [selectedRows]);

    return (
        <PageContainer>
            <Box id="reports-list-header" className={style.header}>
                <Typography variant="h6" className={style.title}>
                    {t('title.reports')}
                </Typography>
                {selectedRows.length ? (
                    <Box className={style.toolbar}>
                        {/* selected items toolbar */}

                        <Typography variant="small4" className={style.label}>
                            {selectedRows.length} {t('toolbar.selected')}
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <MdRefresh />
                            <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                {t('toolbar.clearSelection')}
                            </Box>
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <MdDeleteOutline />
                            <Box
                                component="span"
                                className={style.primary}
                                onClick={openConfirmationModal}
                                sx={{ ml: 1 }}
                            >
                                {t('toolbar.removeAll')}
                            </Box>
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <Box
                                component="span"
                                className={classnames(style.primary, style.nodecoration)}
                                sx={{ ml: 1, mr: 1 }}
                            >
                                {t('toolbar.downloadAll')}
                            </Box>
                            <DownloadButton id={ids} type={DownloadExtension.Pdf} value={'reports'} />
                            <DownloadButton id={ids} type={DownloadExtension.Excel} value={'reports'} />
                            <DownloadButton id={ids} type={DownloadExtension.Csv} value={'reports'} />
                        </Typography>
                        <TableSearch setGetParams={setEVSReportsGetParams} getParams={getParams} />
                    </Box>
                ) : (
                    <>
                        {/* Non selected toolbar */}
                        <Box className={style.toolbar}>
                            <Typography variant="small4" className={style.label}>
                                {t('toolbar.sort')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdSchedule />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={handleSort('-created_at')}
                                    sx={{ ml: 1 }}
                                >
                                    {t('toolbar.mostRecent')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdTrendingUp />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={handleSort('created_at')}
                                    sx={{ ml: 1 }}
                                >
                                    {t('toolbar.oldestToNewest')}
                                </Box>
                            </Typography>
                            <DateRangeInput
                                range={dateRange}
                                setRange={setDateRange}
                                label={t('table.general.selectPeriod')}
                                className={style.selectPeriod}
                            />
                            <TableSearch setGetParams={setEVSReportsGetParams} getParams={getParams} />

                            <Button disableRipple variant="cta" onClick={handleAddNewReport} sx={{ ml: 1 }}>
                                <MdAdd />
                            </Button>
                        </Box>
                    </>
                )}
            </Box>

            <GenericTable
                rows={rows}
                pager={pager}
                loading={loading}
                success={fetchingSuccess}
                isCheckboxEnabled
                columns={headCells}
                rowUniqueId={'id'}
                selectedRows={selectedRows}
                setGetParams={setEVSReportsGetParams}
                searchParam={getParams.search}
                setSelectedRows={setEVSReportsSelectedRows}
                cleanupCb={() => dispatch(resetListEVSReportsState())}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getReportsPageUrl,
                    chargingPoint: getChargingPointPageUrl
                }}
            />

            <RemoveForecastContainer cb={removeAll} stateAccessor="deleteReports" />
        </PageContainer>
    );
};

export default EVSReportsList;
