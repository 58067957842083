import { SxProps } from '@mui/material';

import { Sort } from './interfaces';
import { TableCellRenderer } from '../../../interfaces/general';

export const transformSortToOrderBy = (sort: Sort): string[] => {
    return Object.keys(sort).reduceRight((acc, key) => {
        if (sort[key] !== undefined) {
            return [...acc, `${sort[key]}${key}`];
        }

        return acc;
    }, [] as string[]);
};

export const transformOrderByToSort = (orderBy: string[] | undefined): Sort => {
    const sort = {} as Sort;

    if (orderBy) {
        orderBy.forEach((order) => {
            const direction = order[0];
            const field = order.slice(1);
            sort[field] = direction;
        });
    }

    return sort;
};

export const buildCustomSx = <T>(cell: TableCellRenderer<T>): SxProps => {
    return {
        minWidth: cell.minWidth,
        maxWidth: cell.maxWidth,
        justifyContent: cell.justifyContent || 'initial'
    };
};
