import i18next from 'i18next';
import { toast } from 'react-toastify';
import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../../../utils/axios';
import { Cluster } from '../../../../interfaces/apiv2';
import { GetParams } from '../../../../interfaces/general';
import ToastifyType from '../../../../utils/toastify-config';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { sortForecastData, transformForecastToView } from '../../../../utils/forecast';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../../../interfaces/redux';
import { getForecastDataChartThunk } from '../../helpers';
import { FORECAST_TYPE } from '../../../../interfaces/uiv2';

interface SegmentPage extends DefaultInitialState {
    segment: Cluster | null;
    getParams: GetParams;
    loadingForecast: boolean;
    successForecast: boolean;
    errorForecast: boolean;
    loadingHistorical: boolean;
    successHistorical: boolean;
    errorHistorical: boolean;
    forecasts: LineData[][];
}

const initialState: SegmentPage = {
    segment: null,
    loading: false,
    error: false,
    success: false,
    loadingForecast: false,
    successForecast: false,
    errorForecast: false,
    loadingHistorical: false,
    successHistorical: false,
    errorHistorical: false,
    forecasts: [[], []], // [forecast, historical]
    getParams: DEFAULT_GET_PARAMS_VALUE
};

export const getSegmentAsync = createAsyncThunk(
    'segments/show',
    async (values: { id: string; projectId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<Cluster>(`/electricity/demand/${values.projectId}/clusters/${values.id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getSegmentForecastAsync = getForecastDataChartThunk({
    thunkName: 'segments/forecast',
    endpoint: `/electricity/demand/{project_id}/clusters/forecast/{consumer_id}`
});

export const getSegmentHistoricalAsync = getForecastDataChartThunk({
    thunkName: 'segments/historical',
    endpoint: `/electricity/demand/{project_id}/clusters/historical/{consumer_id}`
});

export const segmentPage = createSlice({
    name: 'segmentPage',
    initialState,
    reducers: {
        resetSegmentPageState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        resetSegmentForecastData: (state) => {
            state.forecasts = [[], []];
            state.errorForecast = false;
            state.loadingForecast = false;
            state.successForecast = false;
            state.errorHistorical = false;
            state.loadingHistorical = false;
            state.successHistorical = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSegmentAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getSegmentAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.segment = payload;
        });
        builder.addCase(getSegmentAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getSegmentForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getSegmentForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            const actualData = (current(state).forecasts || [])[0] || [];
            state.forecasts[0] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.ELECTRICITY),
                ...actualData
            ]);
        });
        builder.addCase(getSegmentForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
        builder.addCase(getSegmentHistoricalAsync.pending, (state) => {
            state.loadingHistorical = true;
            state.errorHistorical = false;
            state.successHistorical = false;
        });
        builder.addCase(getSegmentHistoricalAsync.fulfilled, (state, { payload }) => {
            state.loadingHistorical = false;
            state.errorHistorical = false;
            state.successHistorical = true;
            const actualData = (current(state).forecasts || [])[1] || [];
            state.forecasts[1] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.ELECTRICITY),
                ...actualData
            ]);
        });
        builder.addCase(getSegmentHistoricalAsync.rejected, (state) => {
            state.loadingHistorical = false;
            state.errorHistorical = true;
            state.successHistorical = false;
        });
    }
});

export const {
    resetSegmentPageState,
    setGetParams: setGerSegmentParams,
    resetSegmentForecastData
} = segmentPage.actions;

export default segmentPage.reducer;
