import classNames from 'classnames';
import { Box } from '@mui/material';
import React, { FC, useMemo } from 'react';

import { UNIT } from '../../interfaces/uiv2';
import { useTranslation } from 'react-i18next';
import { GenericTable } from '../../components/BaseTable';
import { getTableHeadCells, renderFooterContent } from './helpers';

import style from '../../components/GenerationTable/style.module.scss';

const Table: FC<any> = ({ pager, data, loading, success, fetchMoreGas, setGetParams }) => {
    const { t } = useTranslation();
    const headCells = getTableHeadCells({ t, assetMeasureUnit: UNIT.M3 });
    const tableFooter = useMemo(() => renderFooterContent(data, t), [data]);
    const sort = {};

    return (
        <Box className={classNames(style.wrapper)}>
            <Box className={classNames(style.generationTableContainer, style.gas)}>
                <GenericTable
                    pager={pager}
                    className={classNames(style.generationTable, fetchMoreGas ? '' : style.smallTable)}
                    rows={data}
                    loading={loading}
                    success={success}
                    setGetParams={setGetParams}
                    columns={headCells}
                    rowUniqueId={'index'}
                    storeSort={sort}
                    customFooter={tableFooter}
                    pagination={true}
                    scrollingPlaceholder={true}
                />
            </Box>
        </Box>
    );
};

export default Table;
