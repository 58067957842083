import classnames from 'classnames';
import Flag from 'react-world-flags';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';

import Loading from '../../layout/Loading';
import { UNIT } from '../../interfaces/uiv2';
import { TAB } from '../../interfaces/general';
import { DATE_SETTINGS } from '../../utils/config';
import { getFormattedDate } from '../../utils/helpers';
import { PageContainer } from '../../layout/PageContainer';
import { TradingView } from '../../components/TradingView';
import { useAppDispatch, useAppSelector } from '../../stores';
import { transformForecastToTable } from '../../utils/forecast';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { ReactComponent as GraphIcon } from '../../assets/icons/timeline.svg';
import { ReactComponent as ViewList } from '../../assets/icons/view_list.svg';
import { mockedDemandForecast } from '../../stores/Demand/mocked-demand-chart';
import { RemoveForecastContainer } from '../../components/RemoveEntityContainer';
import { ReactComponent as ClustersIcon } from '../../assets/icons/demand/clusters.svg';
import { TableActionsEntityContainer } from '../../components/TableActionsEntityContainer';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../components/EntityPageToolbar';
import { MemoizedDemandGenerationTable } from '../../components/GenerationTable/DemandGenerationTable';

import common from '../../common.module.scss';
import style from '../../features/asset-page/asset-page.module.scss';
import {
    getProsumerClusterAsync,
    resetClusterForecastData,
    resetProsumerClusterPageState
} from '../../stores/Prosumers';
import { getMockedProsumersChartData } from '../../stores/Prosumers/mocked-chart';
import { LineData } from 'lightweight-charts';

const ProsumerClusterPage = () => {
    const query = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;
    const [chartData, setChartData] = useState<LineData[][]>([]);
    const { cluster, error, loading, success } = useAppSelector((state) => state.prosumerClusterPage);
    const paginatedData = transformForecastToTable(mockedDemandForecast.slice(0, 100), 'RO');

    useEffect(() => {
        if (!cluster && query?.id) {
            dispatch(getProsumerClusterAsync({ id: query.id }));
        }
    }, [query?.id, cluster]);

    useEffect(() => {
        if (cluster) {
            setChartData(getMockedProsumersChartData(cluster.total_capacity || 20));
        }
    }, [cluster]);

    useEffect(() => {
        return () => {
            dispatch(resetClusterForecastData());
            dispatch(resetProsumerClusterPageState());
        };
    }, []);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    const remove = () => {
        // dispatch(deleteClustersListAsync({ ids: [query.id!], projectId: query.projectId! }));
    };

    if (!cluster) {
        return <Loading className={style.loading} />;
    }

    return (
        <PageContainer className={[common.pageContainer]}>
            <Box className={style.headerContainer}>
                <Box className={classnames(style.header)}>
                    <PageHeader
                        id={cluster.id}
                        title={t('title.clusters')}
                        className={style.title}
                        subtitle={cluster.name}
                        icon={<ClustersIcon />}
                    />

                    <Box className={style.subtitle} sx={{ ml: 2 }}>
                        <Box className={style.dot} />
                        <Typography variant="small1"> {t(pageTitle)}</Typography>
                    </Box>
                </Box>

                <Box className={style.toolbarContainer}>
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>
                    </EntityPageToolbar>
                    <TableActionsEntityContainer entityType="forecast" id={''} className={style.actionsWrapper} />
                </Box>
            </Box>

            <Box className={common.subHeaderContainer}>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.type')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        Cluster
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.location')}</Typography>
                    <Flag
                        height="24"
                        width="24"
                        className={classnames('flagIcon', common.ml)}
                        code={cluster.country || cluster.location}
                    />
                    <Typography variant="small3" className={common.ml}>
                        {cluster.country ? cluster.country.toUpperCase() : cluster.location}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.total_prosumers')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {cluster.total_prosumers}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4" className={common.ml}>
                        {t('tableHead.createdAt')}
                    </Typography>
                    <Typography variant="small3" className={common.ml}>
                        {getFormattedDate({
                            value: cluster.created_at,
                            type: DATE_SETTINGS.shortV2FormatHoursIncluded
                        })}
                    </Typography>
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={chartData as any}
                    error={false}
                    fetchMoreFn={() => {}}
                    loading={false}
                    legendOpts={{
                        forecasted: false,
                        measured: false,
                        demand: true,
                        generation: true,
                        aggregated: true
                    }}
                    assetMeasureUnit={UNIT.LOW_ENERGY}
                />
            )}
            {tab === 'table' && (
                <MemoizedDemandGenerationTable
                    data={paginatedData}
                    error={error}
                    loading={loading}
                    success={success}
                    assetMeasureUnit={UNIT.LOW_ENERGY}
                />
            )}

            <RemoveForecastContainer cb={remove} stateAccessor="deleteForecasts" />
        </PageContainer>
    );
};

export default ProsumerClusterPage;
