import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { toast } from 'react-toastify';
import i18next from '../../../utils/i18next';
import ToastifyType from '../../../utils/toastify-config';
import { ResetPasswordBody } from '../../../interfaces/uiv2';
import { DefaultInitialState } from '../../../interfaces/redux';
import { commonReduxErrorHandler } from '../../../utils/error-handling';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const resetPasswordAsync = createAsyncThunk(
    'auth/resetPassword',
    async (values: ResetPasswordBody, { rejectWithValue }) => {
        try {
            const response = await axios.post('/users/password/new/', values);
            commonOnResetPasswordSuccess();
            return response.data;
        } catch (e) {
            commonOnResetPasswordSuccess();
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

const commonOnResetPasswordSuccess = () => {
    toast.success(i18next.t('auth/resetPassword:resetPasswordSuccess'), ToastifyType.success);
};

export const resetPassword = createSlice({
    name: 'resetPassword',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetPasswordAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(resetPasswordAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = true;
        });
        builder.addCase(resetPasswordAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    }
});

export default resetPassword.reducer;
