import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    ClustersDataFE,
    ConsumersDataFE,
    DemandForecastDataPayload,
    GasClustersDataFE,
    GasConsumersDataFE,
    GasForecastFE,
    GasProfileDataFE,
    ProfilesDataFE
} from '../../interfaces/uiv2';
import axios from '../../utils/axios';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import {
    Cluster,
    Consumer,
    Forecast,
    GasCluster,
    GasConsumer,
    GasForecast,
    GasProfile,
    Profile
} from '../../interfaces/apiv2';

export const transformSegmentsDataToFE = (data: Cluster[]): ClustersDataFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};
export const transformClustersDataToFE = (data: Cluster[]): ClustersDataFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};

export const transformGasConsumersDataToFE = (data: GasConsumer[]): GasConsumersDataFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};

export const transformConsumersDataToFE = (data: Consumer[]): ConsumersDataFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};

export const transformProfilesDataToFE = (data: Profile[]): ProfilesDataFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};

export const transformGasProfilesDataToFE = (data: GasProfile[]): GasProfileDataFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};

export const transformGasClustersDataToFE = (data: GasCluster[]): GasClustersDataFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};

export const transformGasForecastToFE = (data: GasForecast[]): GasForecastFE[] => {
    return data.reverse().map((e, i) => ({ ...e, index: i + 1 }));
};

export const transformEntityToAPItoFE = (data: any[]): any[] => {
    return data.map((e, index) => ({
        index: index + 1,
        generation_forecast_models_created_at: e.generation_forecast_models?.created_at || null,
        ...e
    }));
};

export const getForecastDataChartThunk = ({ thunkName, endpoint }: { thunkName: string; endpoint: string }) => {
    return createAsyncThunk(thunkName, async (values: DemandForecastDataPayload, { rejectWithValue }) => {
        try {
            const response = await axios.get<Forecast[]>(
                endpoint.replace('{project_id}', values.project_id).replace('{consumer_id}', values.id),
                {
                    // cache: {
                    //     ttl: 1000 * 60 * 15
                    // },
                    params: {
                        from_datetime: values.from,
                        to_datetime: values.to,
                        forecast_type: values.forecast_type,
                        time_increment: values.time_increment
                    }
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    });
};
