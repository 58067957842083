import dayjs from 'dayjs';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';

import AssetsVPP from './AssetsVPP';
import {
    loadMore,
    getVPPForecastAsync,
    getVPPMeasuredAsync,
    getVPPForecastAsyncMore,
    getVPPMeasuredAsyncMore,
    getVPPGenerationForecastAsync,
    setGetParamsVPPPage as vppSetGetParams
} from '../../stores/VPP';
import { MODAL_IDS } from '../../modals';
import { TAB } from '../../interfaces/general';
import { downloadChart } from '../../stores/Assets';
import { ForecastType } from '../../interfaces/apiv2';
import { ModalContext } from '../../hooks/modalContext';
import { TradingView } from '../../components/TradingView';
import { PageContainer } from '../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../stores';
import { getFormattedCapacityValue } from '../../utils/helpers';
import { setGetParams, setSelectedRows } from '../../stores/Assets';
import SimplifiedAssetList from '../asset-list/SimplifiedAssetList';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { ReactComponent as VPPIcon } from '../../assets/icons/vpp.svg';
import { ReactComponent as Ballot } from '../../assets/icons/ballot.svg';
import { ReactComponent as GraphIcon } from '../../assets/icons/timeline.svg';
import { ReactComponent as ViewList } from '../../assets/icons/view_list.svg';
import { GenerationTableForecastDataPayload, UNIT, VPPFE } from '../../interfaces/uiv2';
import { TableActionsEntityContainer } from '../../components/TableActionsEntityContainer';
import { MemoizedGenerationTable } from '../../components/GenerationTable/GenerationTable';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../components/EntityPageToolbar';

import vppStyle from './vpp.module.scss';
import style from '../asset-page/asset-page.module.scss';

export const VPPPageView: FC<{ vpp: VPPFE }> = ({ vpp }) => {
    const dispatch = useAppDispatch();
    const { t: generalTranslation } = useTranslation();
    const { handleClickOpen } = useContext(ModalContext);
    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;

    const {
        pager,
        getParams,
        forecasts,
        generationForecasts,
        errorForecast,
        errorMeasured,
        loadingForecast,
        loadingMeasured,
        successForecast
    } = useAppSelector((state) => state.vppPage);
    const { generationAssetsFE } = useAppSelector((state) => state.getVPPAssets);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    const handleDownload = () => {
        dispatch(downloadChart(true));
    };

    const handleEditVPP = (vpp: VPPFE) => {
        handleClickOpen(MODAL_IDS.EDIT_VPP, { vpp });
    };

    const fetchMore = async (toString: string) => {
        const toDayJs = dayjs(toString);
        const fromDayJs = toDayJs.subtract(1, 'week');
        const to = toDayJs.valueOf();
        const from = fromDayJs.valueOf();

        dispatch(
            getVPPForecastAsyncMore({
                id: vpp.id,
                from,
                to,
                forecast_type: ForecastType.LongTerm
            })
        );

        dispatch(getVPPMeasuredAsyncMore({ id: vpp.id, from, to }));
    };

    const fetchGenerationForecast = async (payload: GenerationTableForecastDataPayload) => {
        dispatch(
            getVPPGenerationForecastAsync({
                id: vpp.id,
                ...payload,
                from: payload.from,
                to: payload.to
            })
        );
    };

    useEffect(() => {
        if (tab === 'chart') {
            const from = dayjs().subtract(1, 'week').utcOffset(0).startOf('date').valueOf();
            const to = dayjs().add(10, 'days').utcOffset(0).endOf('date').valueOf();
            dispatch(
                getVPPForecastAsync({
                    id: vpp.id,
                    from,
                    to,
                    forecast_type: ForecastType.LongTerm
                })
            );
            dispatch(getVPPMeasuredAsync({ id: vpp.id, from, to }));
        }
    }, [tab]);

    useEffect(() => {
        if (tab === 'table' && pager?.page !== getParams.page) {
            dispatch(loadMore());
        }
    }, [getParams, tab, pager]);

    return (
        <PageContainer className={[style.pageContainer]}>
            <Box className={style.headerContainer}>
                <Box className={classNames(style.header, vpp.is_implicit ? vppStyle.header : '')}>
                    <PageHeader
                        className={style.title}
                        title={generalTranslation('title.vpp')}
                        id={vpp.id}
                        icon={<VPPIcon />}
                        subtitle={vpp.name}
                    />
                    <Box className={style.subtitle} sx={{ ml: 1.5 }}>
                        <Box className={style.dot} />
                        <Typography variant="small1"> {generalTranslation(pageTitle)}</Typography>
                    </Box>
                </Box>

                <Box className={classNames(style.toolbarContainer)}>
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={generalTranslation('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={generalTranslation('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.ASSETS}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={generalTranslation('assetPageTitle.assets')}
                        >
                            <Ballot />
                        </EntityPageToolbarButton>
                    </EntityPageToolbar>

                    <TableActionsEntityContainer
                        entityType="vpp"
                        id={vpp.id}
                        className={style.actionsWrapper}
                        remove={false}
                        // defaultActions={tab === TAB.TABLE ? [DownloadExtension.Csv, DownloadExtension.Excel] : []}
                        extraActions={[
                            {
                                id: 'editVpp',
                                label: 'table.general.editInfo',
                                disabled: false,
                                callback: () => {
                                    handleEditVPP(vpp);
                                }
                            },
                            tab === TAB.CHART
                                ? {
                                      id: 'download',
                                      label: generalTranslation('toolbar.downloadChart'),
                                      callback: handleDownload
                                  }
                                : null
                        ]}
                    />
                </Box>
            </Box>

            <Box
                className={classNames(
                    style.subHeaderContainer,
                    vppStyle.toolbar,
                    tab === 'assets' ? vppStyle.subHeaderContainer : ''
                )}
            >
                <Box className={classNames(style.subHeaderElement, style.border)}>
                    <Typography variant="small4">{generalTranslation('assetInfo.totalCapacity')}</Typography>
                    <Typography variant="small3" sx={{ ml: 1.5 }}>
                        {getFormattedCapacityValue(vpp.total_capacity)}
                    </Typography>
                </Box>
                <Box className={classNames(style.subHeaderElement, style.border)}>
                    <Typography variant="small4">{generalTranslation('assetInfo.totalAssets')}</Typography>
                    <Typography variant="small3" sx={{ ml: 1.5 }}>
                        {generationAssetsFE.length}
                    </Typography>
                </Box>
                <Box className={style.subHeaderElement}>
                    <Typography variant="small4">{generalTranslation('assetInfo.assetsInVPP')}</Typography>
                    <AssetsVPP variant="small3" assets={generationAssetsFE} />
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={forecasts}
                    fetchMoreFn={fetchMore}
                    error={errorForecast || errorMeasured}
                    loading={loadingForecast || loadingMeasured}
                    assetMeasureUnit={'MWh'}
                />
            )}

            {tab === 'table' && (
                <MemoizedGenerationTable
                    pager={pager}
                    setGetParams={vppSetGetParams}
                    data={generationForecasts}
                    fetchMoreFn={fetchGenerationForecast}
                    error={errorForecast}
                    loading={loadingForecast}
                    success={successForecast}
                    assetMeasureUnit={UNIT.ENERGY}
                    timezone={'utc'}
                />
            )}

            {tab === 'assets' && (
                <Box className={vppStyle.assetContainer}>
                    <SimplifiedAssetList
                        assets={generationAssetsFE}
                        getParams={{}}
                        setGetParams={setGetParams}
                        setSelectedRows={setSelectedRows}
                        options={{ className: vppStyle.headerTopOverwrite }}
                    />
                </Box>
            )}
        </PageContainer>
    );
};
