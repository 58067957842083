import { TFunction } from 'i18next';
import * as yup from 'yup';
import { ASSET_STATUS, PatchProjects, PostProjects, ProjectFormFields, ProjectsDataFE } from '../../interfaces/uiv2';
import { Client, PROJECT_CURRENCY, PROJECT_MODULE, PROJECT_TYPE, ProjectsData } from '../../interfaces/apiv2';
import { COUNTRIES_LIST, CountryItem } from '../../utils/countriesList';

interface FormValues {
    [key: string]: any;
}

export const getProjectFormSchema = (t: TFunction) => {
    const baseSchema = {
        name: yup.string().required(t('validations.required')),
        client: yup.object().required(t('validations.required')),
        type: yup.string().oneOf(Object.values(PROJECT_TYPE)).required(t('form.validations.required')),
        module: yup.string().oneOf(Object.values(PROJECT_MODULE)).required(t('form.validations.required')),
        country: yup
            .object()
            .shape({ code: yup.string(), label: yup.string(), phone: yup.string() })
            .typeError(t('form.validations.required'))
            .required(t('form.validations.required')),
        date_added: yup.date().nullable(),
        status: yup.string().oneOf(Object.values(ASSET_STATUS)).required(t('form.validations.required'))
    };

    return yup.object<Record<keyof FormValues, yup.AnySchema>>({ ...baseSchema }).required();
};

export const transformProjectFormDataToPatchBody = (
    formData: ProjectFormFields,
    project: ProjectsData
): PatchProjects => {
    const { name, country, status, type, module, client } = formData;
    return {
        ...project,
        name,
        country: country!.code,
        status,
        type,
        module,
        client_id: client!.id
    };
};
export const transformProjectFormDataToPostBody = (formData: ProjectFormFields): PostProjects => {
    return {
        name: formData.name,
        status: formData.status,
        client_id: formData.client!.id,
        country: formData.country!.code,
        type: formData.type,
        default_currency: formData.default_currency,
        module: formData.module
    };
};

const defaultValues = {
    name: '',
    type: PROJECT_TYPE.DEMAND_FORECAST,
    module: PROJECT_MODULE.ELECTRICITY,
    client: null as unknown as Client,
    country: null as unknown as CountryItem,
    date_added: '',
    status: ASSET_STATUS.PENDING,
    default_currency: PROJECT_CURRENCY.EUR
};

export const transformToProjectSchema = (project?: ProjectsDataFE): ProjectFormFields => {
    if (project) {
        const { country: country_code, name, status, module, type, created_at, client, default_currency } = project;
        const country = COUNTRIES_LIST.find((item) => item.code === country_code);

        return {
            name,
            country,
            status,
            date_added: created_at,
            module,
            type,
            client,
            default_currency
        };
    } else return defaultValues;
};
export const getProjectFormDataDefaultValue = (project?: ProjectsDataFE) => {
    if (!project) {
        return defaultValues;
    }

    return {
        name: project.name,
        type: project.type,
        module: project.module,
        client: project.client?.id,
        country: project.country,
        date_added: project.created_at,
        status: project.status
    };
};
