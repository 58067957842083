import { GenerationAsset } from '../../interfaces/apiv2';
import { AssetLocationFE, GenerationAssetFE } from '../../interfaces/uiv2';
import { AssetLocation } from '../../interfaces/apiv2';

export const transformGenerationAssetAPItoFE = (data: GenerationAsset[]): GenerationAssetFE[] => {
    return data.map((e, index) => ({
        index: index + 1,
        generation_forecast_models_created_at: e.generation_forecast_models?.created_at || null,
        ...e
    }));
};

export const transformAssetLocationsAPItoFE = (data: AssetLocation[]): AssetLocationFE[] => {
    return data.map((e, index) => ({
        index: index + 1,
        ...e
    }));
};
