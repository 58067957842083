import { isEqual } from 'lodash';
import classnames from 'classnames';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MdAdd, MdDeleteOutline, MdRefresh, MdSchedule, MdTrendingUp } from 'react-icons/md';

import {
    setGetParams,
    setSelectedRows,
    getReportsListAsync,
    resetListReportsState,
    deleteReportsListAsync
} from '../../stores/Reports';
import { ReportFE } from '../../interfaces/uiv2';
import { useIsMount } from '../../hooks/isMount';
import { MODAL_IDS } from '../../modals';
import { getAssetsAsync } from '../../stores/Assets';
import { ModalContext } from '../../hooks/modalContext';
import useStickyHeader from '../../hooks/useStickyHeader';
import { getTableHeadCells } from './helpers';
import { useAppDispatch, useAppSelector } from '../../stores';
import { PageContainer } from '../../layout/PageContainer';
import { DownloadButton, DownloadExtension } from '../../components/DownloadButton';
import { GenericTable, TableSearch } from '../../components/BaseTable';
import { DateRangeInput } from '../../components/DateRangeInput/DateRangeInput';
import { RemoveForecastContainer } from '../../components/RemoveEntityContainer';
import { getForecastFilteringFormattedDate, PAGINATION_SETTINGS, ROUTES } from '../../utils/config';
import { transformOrderByToSort } from '../../components/BaseTable/GenericTable/helpers';

import style from '../../features/forecast-list/forecast-list.module.scss';
import dayjs from 'dayjs';

const ReportsList = () => {
    const dispatch = useAppDispatch();
    const isFirstRender = useIsMount();
    const { t } = useTranslation();
    const { handleClickOpen } = useContext(ModalContext);

    useStickyHeader('reports-list-header');

    const { success } = useAppSelector((state) => state.createReports);
    const { success: deleteSuccess } = useAppSelector((state) => state.deleteReports);
    const getParams = useAppSelector((state) => state.listReports.getParams, isEqual);
    const { getParams: assetsGetParams } = useAppSelector((state) => state.listAssets);
    const {
        reportsFE: rows,
        loading,
        selectedRows,
        pager,
        success: fetchingSuccess
    } = useAppSelector((state) => state.listReports);

    const headCells = getTableHeadCells(t);
    const [dateRange, setDateRange] = useState<DateRange | null>(null);

    useEffect(() => {
        if (dateRange?.from && dateRange?.to) {
            dispatch(
                setGetParams({
                    from_date__gte: getForecastFilteringFormattedDate(dateRange.from),
                    to_date__lte: dayjs(getForecastFilteringFormattedDate(dateRange.to))
                        .endOf('day')
                        .add(1, 'day')
                        .toDate()
                        .toISOString(),
                    page: PAGINATION_SETTINGS.default_start_page
                })
            );
        }

        if (!dateRange && !isFirstRender) {
            dispatch(
                setGetParams({
                    from_date__gte: undefined,
                    to_date__lte: undefined,
                    page: PAGINATION_SETTINGS.default_start_page
                })
            );
        }
    }, [dateRange]);

    useEffect(() => {
        dispatch(getReportsListAsync({ ...getParams }));
    }, [getParams]);

    useEffect(() => {
        if (deleteSuccess || success) {
            dispatch(getReportsListAsync({ ...getParams }));
        }
    }, [deleteSuccess, success]);

    useEffect(() => {
        dispatch(getAssetsAsync({ ...assetsGetParams }));
    }, [assetsGetParams]);

    const handleSort = (type: string) => () => {
        dispatch(setGetParams({ order_by: [type], page: 1 }));
    };

    const clearSelection = () => {
        dispatch(setSelectedRows([]));
    };

    const removeAll = () => {
        dispatch(deleteReportsListAsync(selectedRows.map((a) => a.id)));
        clearSelection();
    };

    const getAssetPageUrl = (row: ReportFE) => () => {
        return `${ROUTES.AI_GENERATION_ASSETS_PAGE.replace(':id', row.asset.id)}`;
    };
    const getReportsPageUrl = (row: ReportFE) => () => {
        return `${ROUTES.REPORTS_PAGE.replace(':publicId', row.id)}`;
    };

    const openConfirmationModal = () => {
        handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    };

    const handleAddNewReport = () => {
        handleClickOpen(MODAL_IDS.CREATE_REPORT_MODAL);
    };

    const ids = useMemo(() => {
        return selectedRows.map((e) => e.id);
    }, [selectedRows]);

    return (
        <PageContainer>
            <Box id="reports-list-header" className={style.header}>
                <Typography variant="h6" className={style.title}>
                    {t('title.reports')}
                </Typography>
                {selectedRows.length ? (
                    <Box className={style.toolbar}>
                        {/* selected items toolbar */}

                        <Typography variant="small4" className={style.label}>
                            {selectedRows.length} {t('toolbar.selected')}
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <MdRefresh />
                            <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                {t('toolbar.clearSelection')}
                            </Box>
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <MdDeleteOutline />
                            <Box
                                component="span"
                                className={style.primary}
                                onClick={openConfirmationModal}
                                sx={{ ml: 1 }}
                            >
                                {t('toolbar.removeAll')}
                            </Box>
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <Box
                                component="span"
                                className={classnames(style.primary, style.nodecoration)}
                                sx={{ ml: 1, mr: 1 }}
                            >
                                {t('toolbar.downloadAll')}
                            </Box>
                            {/* <DownloadButton id={ids} type={DownloadExtension.Pdf} value={'reports'} /> */}
                            <DownloadButton id={ids} type={DownloadExtension.Excel} value={'reports'} />
                            <DownloadButton id={ids} type={DownloadExtension.Csv} value={'reports'} />
                        </Typography>
                        <TableSearch setGetParams={setGetParams} getParams={getParams} />
                    </Box>
                ) : (
                    <>
                        {/* Non selected toolbar */}
                        <Box className={style.toolbar}>
                            <Typography variant="small4" className={style.label}>
                                {t('toolbar.sort')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdSchedule />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={handleSort('-created_at')}
                                    sx={{ ml: 1 }}
                                >
                                    {t('toolbar.mostRecent')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdTrendingUp />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={handleSort('created_at')}
                                    sx={{ ml: 1 }}
                                >
                                    {t('toolbar.oldestToNewest')}
                                </Box>
                            </Typography>
                            <DateRangeInput
                                range={dateRange}
                                setRange={setDateRange}
                                label={t('table.general.selectPeriod')}
                                className={style.selectPeriod}
                            />
                            <TableSearch setGetParams={setGetParams} getParams={getParams} />

                            <Button disableRipple variant="cta" onClick={handleAddNewReport} sx={{ ml: 1 }}>
                                <MdAdd />
                            </Button>
                        </Box>
                    </>
                )}
            </Box>

            <GenericTable
                rows={rows}
                pager={pager}
                loading={loading}
                success={fetchingSuccess}
                isCheckboxEnabled
                columns={headCells}
                rowUniqueId={'id'}
                selectedRows={selectedRows}
                setGetParams={setGetParams}
                searchParam={getParams.search}
                setSelectedRows={setSelectedRows}
                cleanupCb={() => dispatch(resetListReportsState())}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getReportsPageUrl,
                    asset: getAssetPageUrl
                }}
            />

            <RemoveForecastContainer cb={removeAll} stateAccessor="deleteReports" />
        </PageContainer>
    );
};

export default ReportsList;
