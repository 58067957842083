import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { PostEVSReport } from '../../interfaces/apiv2';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const postEVSReportAsync = createAsyncThunk(
    'reports/post',
    async (payload: PostEVSReport, { rejectWithValue }) => {
        try {
            const { projectId, ...rest } = payload;
            const response = await axios.post(`/evs/${projectId}/reports`, rest);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const createEVSReports = createSlice({
    name: 'createEVSReports',
    initialState,
    reducers: {
        resetEVSCreateReportsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(postEVSReportAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(postEVSReportAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(postEVSReportAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetEVSCreateReportsState } = createEVSReports.actions;

export default createEVSReports.reducer;
