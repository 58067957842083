import { Box, SvgIcon } from '@mui/material';
import classnames from 'classnames';

import { ReactComponent as LoaderSVG } from '../../assets/icons/loader.svg';

import style from './style.module.scss';

const Loading = ({ className }: { className?: string }) => {
    return (
        <Box className={classnames(style.wrapper, className)}>
            <SvgIcon component={LoaderSVG} />
        </Box>
    );
};

export default Loading;
