import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { MdDeleteOutline, MdRefresh } from 'react-icons/md';

import { MODAL_IDS } from '../../../modals';
import { ROUTES } from '../../../utils/config';
import { getClustersHeadCells } from '../helpers';
import { ModalContext } from '../../../hooks/modalContext';
import { TLClusterFE } from '../../../stores/TL/mock-data';
import { PageContainer } from '../../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { GenericTable, TableSearch } from '../../../components/BaseTable';
import { transformOrderByToSort } from '../../../components/BaseTable/GenericTable/helpers';
import { getTLClustersListAsync, resetListTLClustersState, setGetTLClustersParams } from '../../../stores/TL';

import style from '../../../features/forecast-list/forecast-list.module.scss';
import useStickyHeader from '../../../hooks/useStickyHeader';

const TLClustersList = () => {
    const dispatch = useAppDispatch();
    const { handleClickOpen } = useContext(ModalContext);
    const [selectedRows, setSelectedRows] = useState([]);

    const { clustersFE, loading, getParams, pager, success } = useAppSelector((state) => state.listTLClusters);

    useEffect(() => {
        dispatch(getTLClustersListAsync({ getParams }));
        return () => {
            dispatch(resetListTLClustersState());
        };
    }, []);

    useEffect(() => {
        dispatch(getTLClustersListAsync({ getParams }));
    }, [getParams]);

    const { t } = useTranslation();
    const headCells = getClustersHeadCells(t);

    const clearSelection = () => {
        setSelectedRows([]);
    };

    const openConfirmationModal = () => {
        handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    };

    const getClustersPageUrl = (row: TLClusterFE) => () => {
        return `${ROUTES.AI_TL_CLUSTERS_PAGE.replace(':id', row.id)}`;
    };

    useStickyHeader('tl-clusters-list-header');

    return (
        <PageContainer>
            <Box className={style.header} id={'tl-clusters-list-header'}>
                <Typography variant="h6" className={style.title}>
                    {t('header.clusters')}
                </Typography>

                <Box className={style.toolbar}>
                    {!!selectedRows.length && (
                        <>
                            <Typography variant="small4" className={style.label}>
                                {selectedRows.length} {t('table.general.selected')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdRefresh />
                                <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                    {t('table.general.clearSelection')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdDeleteOutline />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={openConfirmationModal}
                                    sx={{ ml: 1 }}
                                >
                                    {t('table.general.removeAll')}
                                </Box>
                            </Typography>
                        </>
                    )}

                    <TableSearch setGetParams={setGetTLClustersParams} getParams={getParams} />
                </Box>
            </Box>

            <GenericTable
                pager={pager}
                loading={loading}
                success={success}
                rows={clustersFE}
                isCheckboxEnabled
                rowUniqueId={'id'}
                columns={headCells}
                selectedRows={selectedRows}
                searchParam={getParams.search}
                setGetParams={setGetTLClustersParams}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getClustersPageUrl
                }}
            />
        </PageContainer>
    );
};

export default TLClustersList;
