import { FC } from 'react';
import { SxProps } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { styleguide } from '../../containers/Theme';
import { downloadEVSReport } from '../../stores/EVS';
import { downloadReport } from '../../stores/Reports';
import { useAppDispatch, useAppSelector } from '../../stores';
import { downloadGasReportAsync } from '../../stores/GasReports';
import { downloadEvsForecast, downloadForecast, downloadGasForecast } from '../../stores/Forecasts';

import style from './style.module.scss';
import dayjs from 'dayjs';

export enum DownloadExtension {
    Pdf = 'Pdf',
    Excel = 'Excel',
    Csv = 'Csv',
    Txt = 'Text'
}

export interface DownloadButtonProps {
    type: DownloadExtension;
    id: string | string[];
    projectId?: string;
    disabled?: boolean;
    value?: 'reports' | 'forecast' | 'gas-forecast' | 'gas-report' | 'evs-forecast' | 'evs-reports';
    row?: any;
    filename?: any; // used to form the filename
}

const getColorFromType = (type: DownloadButtonProps['type'], disabled: boolean): SxProps => {
    if (disabled) {
        return {
            color: styleguide.gray,
            borderColor: styleguide.gray
        };
    }
    switch (type) {
        case DownloadExtension.Pdf: {
            return {
                color: styleguide.defaultBadgeColor,
                borderColor: styleguide.defaultBadgeColor,
                '&:hover': {
                    color: styleguide.whiteColor,
                    background: styleguide.defaultBadgeColor
                }
            };
        }
        case DownloadExtension.Excel: {
            return {
                color: styleguide.defaultSuccessColor,
                borderColor: styleguide.defaultSuccessColor,
                '&:hover': {
                    color: styleguide.whiteColor,
                    background: styleguide.defaultSuccessColor
                }
            };
        }
        case DownloadExtension.Csv: {
            return {
                color: styleguide.defaultAvatarColor,
                borderColor: styleguide.defaultAvatarColor,
                '&:hover': {
                    color: styleguide.whiteColor,
                    background: styleguide.defaultAvatarColor
                }
            };
        }
        case DownloadExtension.Txt: {
            return {
                color: styleguide.defaultAvatarColor,
                borderColor: styleguide.defaultAvatarColor,
                '&:hover': {
                    color: styleguide.whiteColor,
                    background: styleguide.defaultAvatarColor
                }
            };
        }
        default: {
            return {
                color: styleguide.defaultAvatarColor,
                borderColor: styleguide.defaultAvatarColor,
                '&:hover': {
                    color: styleguide.whiteColor,
                    background: styleguide.defaultAvatarColor
                }
            };
        }
    }
};

export const DownloadButton: FC<DownloadButtonProps> = ({
    filename,
    type,
    id,
    disabled,
    value = 'forecast',
    projectId,
    row
}) => {
    const dispatch = useAppDispatch();

    const { loadingDownload: reportLoading, activeDownloadMeta: reportActiveDownloadMeta } = useAppSelector(
        (state) => state.listReports
    );
    const { loadingDownload: forecastLoading, activeDownloadMeta: forecastActiveDownloadMeta } = useAppSelector(
        (state) => state.listForecasts
    );

    const isSingleValue = !Array.isArray(id);
    const loading = reportLoading || forecastLoading;
    const typeLoading = [reportActiveDownloadMeta?.type, forecastActiveDownloadMeta?.type].includes(type);
    const activeIds = [...(reportActiveDownloadMeta?.ids || []), ...(forecastActiveDownloadMeta?.ids || [])];

    const onClick = () => {
        if (value === 'forecast') {
            dispatch(downloadForecast({ ids: Array.isArray(id) ? id : [id], type, name: filename }));
        }

        if (value === 'evs-forecast' && !Array.isArray(id)) {
            dispatch(
                downloadEvsForecast({
                    id,
                    file_type: DownloadExtension.Txt,
                    from_date: row.from_date,
                    to_date: row.to_date
                })
            );
        }

        if (value === 'evs-reports' && !Array.isArray(id)) {
            dispatch(
                downloadEVSReport({
                    ids: Array.isArray(id) ? id : [id],
                    params: { projectId },
                    type
                })
            );
        }

        if (value === 'reports') {
            dispatch(downloadReport({ ids: Array.isArray(id) ? id : [id], type, name: filename }));
        }

        if (value === 'gas-forecast' && projectId && !Array.isArray(id)) {
            dispatch(
                downloadGasForecast({
                    projectId,
                    id,
                    file_type: type,
                    from_date: dayjs(row.from_date).format('YYYY.MM.DD'),
                    to_date: dayjs(row.to_date).format('YYYY.MM.DD')
                })
            );
        }
        if (value === 'gas-report' && projectId && !Array.isArray(id)) {
            dispatch(
                downloadGasReportAsync({ projectId, id, from_date: row.from_timestamp, to_date: row.to_timestamp })
            );
        }
    };

    const mapTypes = {
        [DownloadExtension.Csv]: 'CSV',
        [DownloadExtension.Pdf]: 'PDF',
        [DownloadExtension.Excel]: 'XLSX',
        [DownloadExtension.Txt]: 'TXT'
    };

    return (
        <LoadingButton
            disableRipple
            onClick={onClick}
            disabled={disabled}
            className={style.button}
            loading={
                (loading && isSingleValue && activeIds.includes(id) && activeIds.length === 1 && typeLoading) ||
                (loading && !isSingleValue && typeLoading)
            }
            sx={{ ...getColorFromType(type, !!disabled) }}
        >
            {mapTypes[type]}
        </LoadingButton>
    );
};
