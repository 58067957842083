import { DialogContent } from '@mui/material';

import { CloseModalButton } from '../../components/BaseModal/CloseModalButton';
import AccountSettingsForm from './AccountSettingsForm';
import { MODAL_IDS } from '..';
import { BaseModal } from '../../components/BaseModal';

const AccountSettingsModal = () => {
    return (
        <BaseModal maxWidth="sm" fullWidth={false} closeOnBackdropClick id={MODAL_IDS.ACCOUNT_SETTINGS_MODAL}>
            <CloseModalButton id={MODAL_IDS.ACCOUNT_SETTINGS_MODAL} />
            <DialogContent>
                <AccountSettingsForm />
            </DialogContent>
        </BaseModal>
    );
};

export { AccountSettingsModal };
