import { FC } from 'react';
import { Box } from '@mui/material';

import style from './index.module.scss';
import { HeaderV2 } from '../HeaderV2';

const Layout: FC = ({ children }) => {
    return (
        <Box className={style.wrapper}>
            <HeaderV2 />

            <Box className={style.contentWrapper}>
                <Box className={style.content}>{children}</Box>
            </Box>
        </Box>
    );
};

export { Layout };
