import { DialogContent } from '@mui/material';

import { MODAL_IDS } from '..';
import { ImportFileModalProps } from '.';
import { ImportForm } from './ImportForm';
import { FormModal } from '../../components/BaseModal/FormModal';

export const ImportFileModal = (props: ImportFileModalProps) => {
    return (
        <>
            <FormModal id={MODAL_IDS.IMPORT_FILE_MODAL} fullWidth={false} maxWidth="sm">
                <DialogContent>
                    <ImportForm {...props} />
                </DialogContent>
            </FormModal>
        </>
    );
};
