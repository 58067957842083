import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, SvgIcon, Typography } from '@mui/material';

import { getIconByType } from './helpers';
import { GasConsumerType, GasDashboardLayout } from '../../interfaces/uiv2';
import { ReactComponent as ConsumersIcon } from '../../assets/icons/demand/consumers.svg';
import { ReactComponent as ClustersIcon } from '../../assets/icons/demand/clusters.svg';
import { ReactComponent as ProfilesIcon } from '../../assets/icons/demand/profiles.svg';

import style from './style.module.scss';

export const DashboardGasDemandStaticCard = ({ item }: { item: GasDashboardLayout }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box className={style.itemHeader}>
                <Box className={classnames(style.name, style.black)}>
                    <Typography variant="h7">{t('portofoliu')}</Typography>
                </Box>
            </Box>

            <Box className={style.itemStaticDetailsWrapper}>
                <Box className={style.itemStaticDetails}>
                    <Box>
                        <Box className={style.itemStaticBox}>
                            <SvgIcon component={ConsumersIcon} />
                            <Typography variant="h5">{item.consumers_count}</Typography>
                        </Box>
                        <Typography variant="small1">{t('dashboardDemand.totalConsumatori')}</Typography>
                    </Box>

                    <Box>
                        <Box className={style.itemStaticBox}>
                            <SvgIcon component={ClustersIcon} />
                            <Typography variant="h5">{item.clusters_count}</Typography>
                        </Box>
                        <Typography variant="small1">{t('dashboardDemand.totalGrupuri')}</Typography>
                    </Box>

                    <Box>
                        <Box className={style.itemStaticBox}>
                            <SvgIcon component={ProfilesIcon} />
                            <Typography variant="h5">{item.gas_profiles}</Typography>
                        </Box>
                        <Typography variant="small1">{t('dashboardDemand.totalProfile')}</Typography>
                    </Box>
                </Box>

                <Box className={style.itemStaticDetails}>
                    {Object.keys(item.consumers_per_type).map((type, index) => (
                        <Box key={index}>
                            <Box className={style.itemStaticBox}>
                                {getIconByType(type as GasConsumerType)}
                                <Box>
                                    <Typography variant="h5">
                                        {item.consumers_per_type[type as GasConsumerType]}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography variant="small1">{t(`dashboardDemand.${type}`)}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </>
    );
};
