import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { ProjectsData } from '../../interfaces/apiv2';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { PostProjects } from '../../interfaces/uiv2';

interface CreateProjectsState extends DefaultInitialState {
    created: number;
}

const initialState: CreateProjectsState = {
    loading: false,
    error: false,
    success: false,
    created: 0
};

export const createProjectsAsync = createAsyncThunk(
    'projects/create',
    async (values: PostProjects, { rejectWithValue }) => {
        try {
            const response = await axios.post<ProjectsData, AxiosResponse<ProjectsData>, PostProjects>(
                '/projects',
                values
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const createProjects = createSlice({
    name: 'createProjects',
    initialState,
    reducers: {
        resetCreateProjectsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createProjectsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(createProjectsAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.created += 1;
        });
        builder.addCase(createProjectsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetCreateProjectsState } = createProjects.actions;

export default createProjects.reducer;
