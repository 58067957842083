import listTLConsumers from './listTLConsumers';
import listTLClusters from './listTLClusters';
import listTLCirculations from './listTLCirculations';

import consumerTLPage from './tlConsumerPage';
import clusterTLPage from './tlClusterPage';
import circulationTLPage from './tlCirculationPage';

export * from './listTLConsumers';
export * from './listTLClusters';
export * from './listTLCirculations';

export * from './tlConsumerPage';
export * from './tlClusterPage';
export * from './tlCirculationPage';

export default {
    listTLConsumers,
    listTLCirculations,
    listTLClusters,
    consumerTLPage,
    clusterTLPage,
    circulationTLPage
};
