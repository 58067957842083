import store from 'store';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Cluster, Marker } from './Marker';
import { storage } from '../../utils/config';
import { MAP_LOCATIONS, MAP_STYLES } from './helpers';
import { useLazyGetMapMarkersQuery } from '../../stores/Map';

import style from './style.module.scss';
import { debounce } from 'lodash';
import Layers, { LayerOption } from './Layers';
interface MapProps {
    DEFAULT_CENTER?: GoogleMapReact.Coords;
    DEFAULT_ZOOM?: number;
    MAX_ZOOM?: number;
}

const convertZoomToRadius = (zoom: number) => {
    return ((40000 / 2) ^ zoom) * 2;
};

const Map: FC<MapProps> = ({ DEFAULT_CENTER = MAP_LOCATIONS.Viena, DEFAULT_ZOOM = 11, MAX_ZOOM = 20 }) => {
    /* eslint-disable */
    const mapRef = useRef<google.maps.Map>();

    const [openMarker, setOpenMarker] = useState<number | null>(null);
    const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
    const [bounds, setBounds] = useState<[number, number, number, number] | undefined>(undefined);
    const [center, setCenter] = useState<GoogleMapReact.Coords>(DEFAULT_CENTER);
    const [entities, setEntities] = useState<LayerOption[]>([]);

    const [useGetMapMarkersQuery, { data: points, isLoading }] = useLazyGetMapMarkersQuery();

    const fetchMapMarkers = useCallback(() => {
        useGetMapMarkersQuery({
            radius: convertZoomToRadius(zoom),
            lat: center.lat,
            long: center.lng,
            entities: entities.map((item) => item.name)
        });
    }, [zoom, center, entities]);

    const debouncedFetchMapMarkers = useCallback(debounce(fetchMapMarkers, 300), [fetchMapMarkers]);

    useEffect(() => {
        debouncedFetchMapMarkers();
        return () => {
            debouncedFetchMapMarkers.cancel();
        };
    }, [debouncedFetchMapMarkers]);

    const { clusters, supercluster } = useSupercluster({
        points: points || [],
        bounds,
        zoom,
        options: { radius: 75, maxZoom: MAX_ZOOM },
        disableRefresh: isLoading
    });

    const initializeMap = (map: google.maps.Map) => {
        const mapState = store.get(storage.mapState);

        if (mapState) {
            const { zoom, bounds, center } = mapState;

            setZoom(zoom);
            setBounds(bounds);
            setCenter(center);
        } else {
            navigator?.geolocation.getCurrentPosition(({ coords }) => {
                if (coords) {
                    setCenter({ lat: coords.latitude, lng: coords.longitude });
                }
            });

            const initialBounds: google.maps.LatLngBounds | undefined = map.getBounds();

            if (initialBounds) {
                setBounds([
                    initialBounds.getSouthWest().lng(),
                    initialBounds.getSouthWest().lat(),
                    initialBounds.getNorthEast().lng(),
                    initialBounds.getNorthEast().lat()
                ]);
            }
        }
    };

    const handleClusterZoom = (clusterID: string | number | undefined) => {
        if (clusterID) {
            const cluster = clusters.find((cluster) => cluster.id === clusterID);
            const zoomLevel = supercluster?.getClusterExpansionZoom(Number(clusterID));

            if (cluster && zoomLevel) {
                setCenter({
                    lng: cluster?.geometry.coordinates[0],
                    lat: cluster?.geometry.coordinates[1]
                });
                setZoom(zoomLevel);
            }
        }
    };

    const handleMapChange = ({ zoom, bounds }: GoogleMapReact.ChangeEventValue) => {
        if (mapRef.current) {
            // const { w } = meters2ScreenPixels(5000, center, zoom); // TODO: Useful if we want to draw a legend ruler
            const { lat, lng } = mapRef.current?.getCenter() || {};

            setBounds([bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat]);
            setZoom(zoom);
            setCenter({
                lat: lat!(),
                lng: lng!()
            });
        }
    };

    const recenterOnMarker = () => {
        const openPoint = (points || []).find((point) => point.properties.id === openMarker);
        if (openPoint) {
            setTimeout(() => {
                mapRef.current?.panTo({
                    lng: openPoint?.geometry.coordinates[0],
                    lat: openPoint?.geometry.coordinates[1]
                });

                // Add a little offset when re-centering onClick
                mapRef.current?.panBy(0, -300);
            });
        }
    };

    useEffect(() => {
        recenterOnMarker();
    }, [openMarker]);

    // Save map state to localStorage, on unmount and beforeunload
    const setMapState = () => {
        store.set(storage.mapState, { zoom, bounds, center });
    };

    useEffect(() => {
        window.addEventListener('beforeunload', setMapState);

        return () => {
            setMapState();
            window.removeEventListener('beforeunload', setMapState);
        };
    }, [zoom, bounds, center]);

    return (
        <div className={style.mapContainer}>
            <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string }}
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                zoom={zoom}
                center={center}
                options={{
                    styles: MAP_STYLES,
                    maxZoom: MAX_ZOOM,
                    disableDefaultUI: true,
                    disableDoubleClickZoom: true,
                    clickableIcons: false
                }}
                onChange={handleMapChange}
                onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                    initializeMap(map);
                }}
            >
                {clusters.map((cluster) => {
                    if (cluster.properties.cluster) {
                        return (
                            <Cluster
                                key={cluster.properties.cluster_id}
                                cluster={cluster}
                                handleClusterZoom={() => handleClusterZoom(cluster.id)}
                                // Required by <GoogleMapReact> even if aren't used in <Marker>
                                lng={cluster.geometry.coordinates[0]}
                                lat={cluster.geometry.coordinates[1]}
                            />
                        );
                    }

                    return (
                        <Marker
                            key={cluster.properties.id}
                            cluster={cluster}
                            openMarker={openMarker}
                            setOpenMarker={setOpenMarker}
                            // Required by <GoogleMapReact> even if aren't used in <Marker>
                            lng={cluster.geometry.coordinates[0]}
                            lat={cluster.geometry.coordinates[1]}
                        />
                    );
                })}
            </GoogleMapReact>

            <Layers setActive={setEntities} />
        </div>
    );
};

export default Map;
