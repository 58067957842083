import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo, ChargingStations } from '../../interfaces/apiv2';
import { transformChargingStationsDataToFE } from './helpers';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { sortForecastData, transformForecastToTable, transformForecastToView } from '../../utils/forecast';
import { ChargingStationsFE, FORECAST_TYPE, ForecastDataGetParams, ForecastFE } from '../../interfaces/uiv2';
import { getQueryParamsFromObject } from '../../utils/table';

interface IEVSChargingStationPage extends DefaultInitialState {
    chargingStation: ChargingStationsFE | null;
    data: ForecastFE[];
    paginatedData: ForecastFE[];
    chartData: LineData[][];
    forecastSettings: { id: string }[];
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
}

const initialState: IEVSChargingStationPage = {
    chargingStation: null,
    data: [],
    paginatedData: [],
    chartData: [[], []],
    forecastSettings: [],
    loading: false,
    error: false,
    success: false,
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        order_by: undefined
    },
    pager: null
};

export const getEVSChargingStationAsync = createAsyncThunk(
    'chargingStation/show',
    async ({ id, projectId }: { id: string; projectId: string }, { rejectWithValue }) => {
        try {
            const endpoint = `/evs/${projectId}/charging-stations`;
            const response = await axios.get<ChargingStations>(`${endpoint}/${id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getEVSChargingStationForecastSettingsAsync = createAsyncThunk(
    'chargingStation_forecast_settings/get',
    async (
        { projectId, chargingStation }: { chargingStation: ChargingStationsFE; projectId: string },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.get<any>(
                `/evs/${projectId}/charging-stations/${chargingStation.id}/charging-points/all-forecast-settings`
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getEVSChargingStationForecastAsync = createAsyncThunk(
    'chargingStation_forecast/get',
    async (
        {
            projectId,
            chargingStation,
            forecastIDs,
            from_datetime,
            to_datetime
        }: {
            chargingStation: ChargingStationsFE;
            projectId: string;
            forecastIDs: string[];
            from_datetime: number;
            to_datetime: number;
        },
        { rejectWithValue }
    ) => {
        const queryParams = getQueryParamsFromObject({
            forecast_settings_ids: forecastIDs,
            from_datetime: from_datetime,
            to_datetime: to_datetime
        });

        try {
            const response = await axios.get<ForecastFE[]>(
                `/evs/${projectId}/charging-stations/${chargingStation.id}/forecast${queryParams}`
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getEVSChargingStationHistoricalAsync = createAsyncThunk(
    'chargingStation_historical/get',
    async (
        {
            projectId,
            chargingStation,
            forecastIDs,
            from_datetime,
            to_datetime
        }: {
            chargingStation: ChargingStationsFE;
            projectId: string;
            forecastIDs: string[];
            from_datetime: number;
            to_datetime: number;
        },
        { rejectWithValue }
    ) => {
        const queryParams = getQueryParamsFromObject({
            forecast_settings_ids: forecastIDs,
            from_datetime: from_datetime,
            to_datetime: to_datetime
        });

        try {
            const response = await axios.get<ForecastFE[]>(
                `/evs/${projectId}/charging-stations/${chargingStation.id}/historical${queryParams}`
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const chargingStationPage = createSlice({
    name: 'chargingStationPage',
    initialState,
    reducers: {
        resetEVSChargingStationPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setActiveEVSChargingStation: (state, { payload }: PayloadAction<ChargingStationsFE | null>) => {
            state.chargingStation = payload;
        },
        loadMore: (state) => {
            const { data, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: data.length
            };
            state.paginatedData = data.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEVSChargingStationForecastAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSChargingStationForecastAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.data = transformForecastToTable(
                payload.map((x) => ({
                    ...x,
                    value: x.value * 1000
                })) as any
            );
            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).data.length
            };
            state.paginatedData = current(state).data.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
            state.chartData = [
                transformForecastToView(
                    payload.map((x) => ({
                        ...x,
                        value: x.value * 1000
                    })) as any,
                    FORECAST_TYPE.ELECTRICITY
                ),
                []
            ];
        });
        builder.addCase(getEVSChargingStationForecastAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });

        builder.addCase(getEVSChargingStationHistoricalAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSChargingStationHistoricalAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const actualData = (current(state).chartData || [])[1] || [];

            if (payload.length) {
                state.chartData[1] = sortForecastData([
                    ...transformForecastToView(
                        payload.map((x) => ({
                            ...x,
                            value: x.value * 1000
                        })) as any,
                        FORECAST_TYPE.ELECTRICITY
                    ),
                    ...actualData
                ]);
            }
        });
        builder.addCase(getEVSChargingStationHistoricalAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });

        builder.addCase(getEVSChargingStationForecastSettingsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSChargingStationForecastSettingsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.forecastSettings = payload;
        });
        builder.addCase(getEVSChargingStationForecastSettingsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });

        builder.addCase(getEVSChargingStationAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSChargingStationAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.chargingStation = transformChargingStationsDataToFE([payload])[0];
        });
        builder.addCase(getEVSChargingStationAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
    }
});

export const {
    resetEVSChargingStationPage,
    setActiveEVSChargingStation,
    setGetParams: setGetEVSChargingStationParams,
    loadMore: loadMoreEVSChargingStationData
} = chargingStationPage.actions;

export default chargingStationPage.reducer;
