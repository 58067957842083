import { ForecastType, GasForecast } from '../apiv2';
import { GetParams } from '../general';

export interface RunForecastFormData {
    start_date?: string;
    end_date?: string;
    apiKey: string;
}

export interface DeliverForecastFormData {
    [key: string]: any;
    type: ForecastType;
    defaultEmails: boolean;
}

export interface PostDeliverForecastBody {
    assetId: string;
    start_date?: string;
    end_date?: string;
    emails: string[] | null;
    type: ForecastType;
}

export interface PostRunForecastBody extends RunForecastFormData {
    assetId: string;
}

export interface GasForecastFE extends GasForecast {
    index: number;
}

export interface ForecastFE {
    index: number;
    customFooter?: boolean;
    timestamp: string;
    date: string;
    time: string;
    localDate?: string;
    localTime?: string;
    value: number;
    p10_value: number;
    p25_value: number;
    p75_value: number;
    p90_value: number;
}

export interface GenerationTableForecastDataPayload {
    from: number;
    to: number;
    time_increment: number;
    forecast_type: ForecastType;
}

export interface GenerationTableForecastDataPayloadSerialized {
    from: number;
    to: number;
    time_increment: number;
    forecast_type: ForecastType;
}

export interface DemandForecastDataPayload extends ForecastDataPayload {
    project_id: string;
}

export interface ForecastDataPayload {
    id: string;
    from: any;
    to: any;
    time_increment?: number;
    forecast_type?: ForecastType;
    projectId?: string;
}

export interface ForecastDataGetParams extends GetParams {
    start_date?: string;
    end_date?: string;
}

export enum FORECAST_TYPE {
    GAS = 'GAS',
    ELECTRICITY = 'ELECTRICITY'
}
