import listReports from './listReports';
import createReports from './createReports';
import deleteReports from './deleteReports';
import reportPage from './reportPage';

export * from './listReports';
export * from './createReports';
export * from './deleteReports';
export * from './reportPage';

export default {
    listReports,
    createReports,
    deleteReports,
    reportPage
};
