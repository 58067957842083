import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import Loading from '../../../layout/Loading';
import { UNIT } from '../../../interfaces/uiv2';
import { TAB } from '../../../interfaces/general';
import { DATE_SETTINGS } from '../../../utils/config';
import { getFormattedDate } from '../../../utils/helpers';
import { PageContainer } from '../../../layout/PageContainer';
import { TradingView } from '../../../components/TradingView';
import { deleteFormulasListAsync } from '../../../stores/Demand';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { transformForecastToTable } from '../../../utils/forecast';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ReactComponent as ViewList } from '../../../assets/icons/view_list.svg';
import { ReactComponent as GraphIcon } from '../../../assets/icons/timeline.svg';
import { RemoveForecastContainer } from '../../../components/RemoveEntityContainer';
import { ReactComponent as ProfilesIcon } from '../../../assets/icons/demand/profiles.svg';
import { TableActionsEntityContainer } from '../../../components/TableActionsEntityContainer';
import { MemoizedGenericTable } from '../../../components/GenericTableWrapper/GenericTableWrapper';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../../components/EntityPageToolbar';
import { getTableHeadCells, renderFooterContent } from '../../../components/GenerationTable/helpers';
import { getProfileAsync, resetProfilePageState, setGetProfilesParams } from '../../../stores/Demand';
import { mockedDemandChartData as chartData, mockedDemandForecast } from '../../../stores/Demand/mocked-demand-chart';

import common from './../../../common.module.scss';
import style from '../../../features/asset-page/asset-page.module.scss';

const FormulaPage = () => {
    const query = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;

    const { profile, success, loading, error } = useAppSelector((state) => state.profilePage);
    const paginatedData = transformForecastToTable(mockedDemandForecast.slice(0, 100), 'RO');

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    useEffect(() => {
        if (!profile && query?.id && query?.projectId) {
            dispatch(getProfileAsync({ id: query.id, projectId: query.projectId } as any));
        }
    }, [query?.id, profile]);

    useEffect(() => {
        return () => {
            dispatch(resetProfilePageState());
        };
    }, []);

    const remove = () => {
        dispatch(deleteFormulasListAsync({ ids: [query.id!], projectId: query.projectId! }));
    };
    const headCells = getTableHeadCells({ t, assetMeasureUnit: UNIT.ENERGY });

    if (!profile) {
        return <Loading className={style.loading} />;
    }

    return (
        <PageContainer className={[common.pageContainer]}>
            <Box className={style.headerContainer}>
                <Box className={classnames(style.header)}>
                    <PageHeader
                        id={profile.id}
                        title={'title.formula'}
                        className={style.title}
                        icon={<ProfilesIcon />}
                        subtitle={profile.description}
                    />

                    <Box className={style.subtitle} sx={{ ml: 2 }}>
                        <Box className={style.dot} />
                        <Typography variant="small1"> {t(pageTitle)}</Typography>
                    </Box>
                </Box>

                <Box className={style.toolbarContainer}>
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>
                    </EntityPageToolbar>
                    <TableActionsEntityContainer entityType="forecast" id={''} className={style.actionsWrapper} />
                </Box>
            </Box>

            <Box className={common.subHeaderContainer}>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.type')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        Formula
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4" className={common.ml}>
                        {t('tableHead.createdAt')}
                    </Typography>
                    <Typography variant="small3" className={common.ml}>
                        {getFormattedDate({
                            value: profile.created_at,
                            type: DATE_SETTINGS.shortV2FormatHoursIncluded
                        })}
                    </Typography>
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={chartData as any}
                    error={false}
                    fetchMoreFn={() => {}}
                    loading={false}
                    legendOpts={{
                        forecasted: true,
                        measured: true
                    }}
                    assetMeasureUnit={UNIT.POWER}
                />
            )}
            {tab === 'table' && (
                <MemoizedGenericTable
                    pager={null}
                    success={success}
                    data={paginatedData}
                    error={error}
                    loading={loading}
                    renderFooterContent={renderFooterContent}
                    headCells={headCells}
                    fetchMoreFn={() => {}}
                    setGetParams={setGetProfilesParams}
                />
            )}

            <RemoveForecastContainer cb={remove} stateAccessor="deleteForecasts" />
        </PageContainer>
    );
};

export default FormulaPage;
