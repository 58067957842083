import classNames from 'classnames';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
    buildUserMenu,
    MENU_ITEMS_AI_ELECTRICITY,
    MENU_ITEMS_ADMIN_ELECTRICITY,
    DOTS_MENU_ITEMS_AI_ELECTRICITY
} from './helpers';
import { ROUTES } from '../../utils/config';
import { useAppSelector } from '../../stores';
import { DotsMenu } from './components/DotsMenu';
import { LogoMenu } from './components/LogoMenu';
import { APP_MODULES } from '../../interfaces/uiv2';
import { CenterLinks } from './components/CenterLinks';
import { DemandModal } from '../../modals/DemandModal';
import { useGetProjectsQuery } from '../../stores/Projects';
import HeaderUserMenu from './components/HeaderUserMenu/HeaderUserMenu';
import { ReactComponent as MapIcon } from '../../assets/icons/navigation-items/dots-menu-map.svg';

import style from './style.module.scss';
import { hasAccessTo } from '../../utils/user';

export const HeaderV2 = () => {
    const { pathname } = useLocation();
    const { user } = useAppSelector((state) => state.login);
    const [activeModule, setActiveModule] = useState<APP_MODULES>(APP_MODULES.AI);
    const { data: projects = [] } = useGetProjectsQuery({ page_size: 100, page: 1 });

    useEffect(() => {
        const isAiMenu = pathname.startsWith('/ai/');
        const isAdminMenu = pathname.startsWith('/admin');

        setActiveModule((prev) => {
            if (!isAiMenu && !isAdminMenu) {
                return prev;
            }

            if (prev === APP_MODULES.AI) {
                if (isAdminMenu) {
                    return APP_MODULES.ADMIN;
                }
                return APP_MODULES.AI;
            }

            if (prev === APP_MODULES.ADMIN) {
                if (isAiMenu) {
                    return APP_MODULES.AI;
                }
                return APP_MODULES.ADMIN;
            }

            return APP_MODULES.AI;
        });
    }, [pathname]);

    if (!user) {
        return <></>;
    }

    const userMenu = buildUserMenu(user, MENU_ITEMS_AI_ELECTRICITY);
    const userMenuAdmin = buildUserMenu(user, MENU_ITEMS_ADMIN_ELECTRICITY);
    const userMenuDots = buildUserMenu(user, DOTS_MENU_ITEMS_AI_ELECTRICITY);

    return (
        <>
            <Box className={style.wrapper} id="header-wrapper">
                <LogoMenu activeModule={activeModule} />

                <CenterLinks
                    projects={projects}
                    menuItems={activeModule === APP_MODULES.AI ? userMenu : userMenuAdmin}
                />

                <Box className={style.rightContainer}>
                    <Box className={style.right}>
                        {hasAccessTo(user!, ['EVS.ChargingStations.READ']) ? (
                            <Link to={ROUTES.MAP}>
                                <Box className={classNames(style.tooltip, pathname.includes(ROUTES.MAP) && style.open)}>
                                    <MapIcon />
                                </Box>
                            </Link>
                        ) : null}
                        <Box sx={{ mr: 1, ml: 1 }}>
                            <DotsMenu
                                menuItems={userMenuDots}
                                user={user}
                                activeModule={activeModule}
                                projects={projects}
                            />
                        </Box>
                        <HeaderUserMenu />
                    </Box>
                </Box>
            </Box>
            <DemandModal />
        </>
    );
};
