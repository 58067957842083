import i18next from 'i18next';
import { toast } from 'react-toastify';
import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import ToastifyType from '../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo, DeliveredForecast } from '../../interfaces/apiv2';
import { Forecast } from '../../interfaces/apiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { transformEvsForecastToTable, transformEvsForecastToView } from '../../utils/forecast';
import { ForecastDataGetParams, ForecastFE } from '../../interfaces/uiv2';

interface IForecastPage extends DefaultInitialState {
    forecast: DeliveredForecast | null;
    data: ForecastFE[];
    paginatedData: ForecastFE[];
    chartData: LineData[][];
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
}

const initialState: IForecastPage = {
    forecast: null,
    data: [],
    paginatedData: [],
    chartData: [[], []],
    loading: false,
    error: false,
    success: false,
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        // order_by: ['-created_on']
        order_by: undefined
    },
    pager: null
};

export const getForecastAsync = createAsyncThunk(
    'forecast/show',
    async (values: { id: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<DeliveredForecast>(`/evs/delivered-forecasts/${values.id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getForecastDataAsync = createAsyncThunk(
    'forecast_data/get',
    async ({ forecast }: { forecast: DeliveredForecast }, { rejectWithValue }) => {
        try {
            const response = await axios.get<Forecast[]>(`/evs/delivered-forecasts/${forecast.id}/data`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const evsForecastPage = createSlice({
    name: 'evsForecastPage',
    initialState,
    reducers: {
        resetForecastPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setActiveForecast: (state, { payload }: PayloadAction<DeliveredForecast | null>) => {
            state.forecast = payload;
        },
        loadMore: (state) => {
            const { data, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: data.length
            };
            state.paginatedData = data.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getForecastDataAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getForecastDataAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            const country = current(state).forecast?.forecast_settings?.asset?.country_code;
            state.data = transformEvsForecastToTable(payload, country);
            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).data.length
            };
            state.paginatedData = current(state).data.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
            state.chartData = [transformEvsForecastToView(payload), []];
        });
        builder.addCase(getForecastDataAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getForecastAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getForecastAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.forecast = payload;
        });
        builder.addCase(getForecastAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetForecastPage, setActiveForecast, setGetParams, loadMore } = evsForecastPage.actions;

export default evsForecastPage.reducer;
