import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { ApiPaginationInfo, PaginatedApiResponse, User } from '../../interfaces/apiv2';
import { UserFE } from '../../interfaces/uiv2';
import { transformUsersAPIToFE } from './helpers';
import { GetParams } from '../../interfaces/general';
import ToastifyType from '../../utils/toastify-config';
import { getQueryParamsFromObject } from '../../utils/table';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DefaultInitialState, DEFAULT_GET_PARAMS_VALUE } from '../../interfaces/redux';
import { PAGINATION_SETTINGS } from '../../utils/config';

interface ListUserState extends DefaultInitialState {
    users: User[];
    usersFE: UserFE[];
    pager: ApiPaginationInfo | null;
    getParams: GetParams;
    selectedRows: UserFE[];
}

export const initialState: ListUserState = {
    loading: false,
    error: false,
    success: false,
    users: [],
    usersFE: [],
    pager: null,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    selectedRows: []
};

export const getUsersAsync = createAsyncThunk('users/list', async (values: GetParams, { rejectWithValue }) => {
    try {
        const queryParams = getQueryParamsFromObject({ ...values });
        const response = await axios.get<PaginatedApiResponse<User>>(`/users${queryParams}`);

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const inactivateUserAsync = createAsyncThunk(
    'users/inactivate',
    async (values: { public_id: string; state: boolean }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put<User, AxiosResponse<User>>(`/users/${values.public_id}/set_active`, {
                set_active: values.state
            });

            const [userFE] = transformUsersAPIToFE([response.data]);
            dispatch(updateById(userFE));

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const resendActivationLinkAsync = createAsyncThunk(
    'users/resend',
    async (values: { email: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/users/password/new/', values);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const listUsers = createSlice({
    name: 'listUsers',
    initialState,
    reducers: {
        resetListUsersState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<UserFE[]>) => {
            state.selectedRows = payload;
        },
        updateById: (state, { payload }: PayloadAction<User>) => {
            const [userFE] = transformUsersAPIToFE([payload]);
            state.usersFE = current(state).usersFE.map((user) => (user.id === payload.id ? userFE : user));
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUsersAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getUsersAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.users = items;
            } else {
                state.users = [...current(state).users, ...items];
            }
            state.usersFE = transformUsersAPIToFE(current(state).users);
        });
        builder.addCase(getUsersAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(inactivateUserAsync.fulfilled, () => {
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(inactivateUserAsync.rejected, () => {
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(resendActivationLinkAsync.fulfilled, () => {
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(resendActivationLinkAsync.rejected, () => {
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetListUsersState, setGetParams, setSelectedRows, updateById } = listUsers.actions;

export default listUsers.reducer;
