import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { SupportFormPost } from '../../interfaces/apiv2';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const postSupportFormAsync = createAsyncThunk(
    'support',
    async (payload: SupportFormPost, { rejectWithValue }) => {
        try {
            const endpoint = '/support';
            const response = await axios.post(endpoint, payload);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const userSupportForm = createSlice({
    name: 'userSupportForm',
    initialState,
    reducers: {
        resetCreateSupportForm: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(postSupportFormAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(postSupportFormAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(postSupportFormAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetCreateSupportForm } = userSupportForm.actions;

export default userSupportForm.reducer;
