import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Loading from '../../layout/Loading';
import { VPPPageView } from '../../features/vpp-page-view';
import { useAppDispatch, useAppSelector } from '../../stores';
import { getVPPAssetsAsync, getVPPAsync, resetGetVPPAssetsAsync, resetVPPPage } from '../../stores/VPP';
import { useIsMount } from '../../hooks/isMount';

const VPPPage = () => {
    const query = useParams();
    const dispatch = useAppDispatch();
    const isFirstRender = useIsMount();

    const { vpp } = useAppSelector((state) => state.vppPage);
    const { generationAssetsFE } = useAppSelector((state) => state.getVPPAssets);
    const { VPPCreated } = useAppSelector((state) => state.createVPP);

    useEffect(() => {
        if (!vpp && query?.vppId) {
            dispatch(getVPPAsync({ id: query.vppId }));
        }
    }, [query?.vppId, vpp]);

    useEffect(() => {
        return () => {
            dispatch(resetGetVPPAssetsAsync());
            dispatch(resetVPPPage());
        };
    }, []);

    useEffect(() => {
        if (!generationAssetsFE.length && query?.vppId) {
            dispatch(getVPPAssetsAsync({ id: query.vppId }));
        }
    }, [query?.vppId]);

    useEffect(() => {
        if (!isFirstRender) {
            if (query?.vppId) {
                dispatch(getVPPAsync({ id: query.vppId }));
                dispatch(getVPPAssetsAsync({ id: query.vppId }));
            }
        }
    }, [VPPCreated]);

    useEffect(() => {
        return () => {
            dispatch(resetVPPPage());
        };
    }, []);

    if (!vpp) {
        return <Loading />;
    }

    return <VPPPageView vpp={vpp} />;
};

export default VPPPage;
