import { ASSET_STATUS } from '../../interfaces/uiv2';

export const mockedProsumersData = [
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 1',
        status: ASSET_STATUS.ACTIVE,
        storage: 20,
        storagePercentage: 1,
        accuracy: 0.98,
        demand: '215 kW',
        total_capacity: 20,
        id: 'd691f750-f960-44f3-a884-e116524ce664',
        industry: 'Auto',
        voltage: 'LV',
        created_at: '2021-09-28T02:32:45',
        updated_at: '2021-09-28T02:32:45',
        expiry_date: '2029-08-27T04:18:52',
        location_type: 'Urban',
        dso: 'EDM',
        profile: 'P001',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO',
        latest_forecast: [88, 184, 229, 254, 242, 206, 193]
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 2',
        status: ASSET_STATUS.DISABLED,
        storage: 37,
        storagePercentage: 0.86,
        accuracy: 0.98,
        demand: '265 kW',
        total_capacity: 37,
        id: '7666e40a-9fb7-40f1-9b1c-ab6a48cc6337',
        industry: 'Auto',
        voltage: 'MV',
        created_at: '2021-06-28T02:19:29',
        updated_at: '2021-06-28T02:19:29',
        expiry_date: '2026-12-11T08:28:08',
        location_type: 'Urban',
        dso: 'EDB',
        profile: 'P002',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 3',
        status: ASSET_STATUS.DISABLED,
        storage: 68,
        storagePercentage: 0.71,
        accuracy: 0.98,
        demand: '232 kW',
        total_capacity: 68,
        id: '5a7fee50-4a34-465b-a2f4-6338cf9ecbd7',
        industry: 'Medical',
        voltage: 'LV',
        created_at: '2021-04-18T05:47:36',
        updated_at: '2021-04-18T05:47:36',
        expiry_date: '2024-01-22T03:33:21',
        location_type: 'Urban',
        dso: 'EDD',
        profile: 'P003',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 4',
        status: ASSET_STATUS.PENDING,
        storage: 53,
        storagePercentage: 0.57,
        accuracy: 0.98,
        demand: '247 kW',
        total_capacity: 53,
        id: 'bb0b4f67-ab7a-4e0b-bc3e-84d9f0b78c4d',
        industry: 'Medical',
        voltage: 'LV',
        created_at: '2020-05-21T12:43:29',
        updated_at: '2020-05-21T12:43:29',
        expiry_date: '2024-06-01T05:53:55',
        location_type: 'Urban',
        dso: 'DEL',
        profile: 'P004',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 5',
        status: ASSET_STATUS.PENDING,
        storage: 45,
        storagePercentage: 0.43,
        accuracy: 0.98,
        demand: '221 kW',
        total_capacity: 45,
        id: '3d1263a2-6807-45ab-9546-3d4dcb0ee11a',
        industry: 'Auto',
        voltage: 'LV',
        created_at: '2022-01-20T11:18:41',
        updated_at: '2022-01-20T11:18:41',
        expiry_date: '2027-12-07T10:55:15',
        location_type: 'Rural',
        dso: 'DEO',
        profile: 'P005',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 6',
        status: ASSET_STATUS.ACTIVE,
        storage: 64,
        storagePercentage: 0.29,
        accuracy: 0.98,
        demand: '275 kW',
        total_capacity: 64,
        id: '96d356f4-a277-4d01-9ac1-42c8f0e4aa1c',
        industry: 'Medical',
        voltage: 'HV',
        created_at: '2021-12-12T04:05:31',
        updated_at: '2021-12-12T04:05:31',
        expiry_date: '2027-06-15T01:48:58',
        location_type: 'Rural',
        dso: 'EDD',
        profile: 'P006',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 7',
        status: ASSET_STATUS.ACTIVE,
        storage: 12,
        storagePercentage: 0.14,
        accuracy: 0.98,
        demand: '284 kW',
        total_capacity: 12,
        id: '933dbbed-6cf2-4730-a419-f0eeb0daeff2',
        industry: 'Auto',
        voltage: 'MV',
        created_at: '2020-02-23T08:15:45',
        updated_at: '2020-02-23T08:15:45',
        expiry_date: '2024-08-23T06:37:05',
        location_type: 'Rural',
        dso: 'DEL',
        profile: 'P007',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 8',
        status: ASSET_STATUS.ACTIVE,
        storage: 86,
        storagePercentage: 0.07,
        accuracy: 0.98,
        demand: '245 kW',
        total_capacity: 86,
        id: '0ae5e97c-bce4-4385-8deb-600897908815',
        industry: 'Medical',
        voltage: 'LV',
        created_at: '2020-07-14T02:20:22',
        updated_at: '2020-07-14T02:20:22',
        expiry_date: '2026-11-29T07:05:02',
        location_type: 'Urban',
        dso: 'DEO',
        profile: 'P008',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 9',
        status: ASSET_STATUS.ACTIVE,
        storage: 23,
        storagePercentage: 0,
        accuracy: 0.98,
        demand: '299 kW',
        total_capacity: 23,
        id: '53579987-ff0a-4745-9b5e-bd4fbf05ab8c',
        industry: 'Medical',
        voltage: 'MV',
        created_at: '2020-06-05T06:01:52',
        updated_at: '2020-06-05T06:01:52',
        expiry_date: '2028-06-15T04:07:07',
        location_type: 'Rural',
        dso: 'DEL',
        profile: 'P009',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 10',
        status: ASSET_STATUS.ACTIVE,
        storage: 91,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '263 kW',
        total_capacity: 91,
        id: 'f33ce09c-5621-4e0b-8fe8-c3588ac1d51d',
        industry: 'Textile',
        voltage: 'HV',
        created_at: '2020-08-12T10:49:54',
        updated_at: '2020-08-12T10:49:54',
        expiry_date: '2024-01-26T11:38:44',
        location_type: 'Rural',
        dso: 'EDM',
        profile: 'P010',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 11',
        status: ASSET_STATUS.ACTIVE,
        storage: 168,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '599 kW',
        total_capacity: 168,
        id: '2bfb2b2d-8378-433d-830e-b832c12308d7',
        industry: 'Textile',
        voltage: 'LV',
        created_at: '2020-04-12T04:23:08',
        updated_at: '2020-04-12T04:23:08',
        expiry_date: '2023-06-27T06:13:58',
        location_type: 'Urban',
        dso: 'EDB',
        profile: 'P011',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 12',
        status: ASSET_STATUS.ACTIVE,
        storage: 157,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '563 kW',
        total_capacity: 157,
        id: 'd65401f6-6187-4b59-8eee-8f5c72243f05',
        industry: 'Auto',
        voltage: 'MV',
        created_at: '2022-05-25T03:52:42',
        updated_at: '2022-05-25T03:52:42',
        expiry_date: '2026-12-18T10:48:21',
        location_type: 'Rural',
        dso: 'DEL',
        profile: 'P012',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 13',
        status: ASSET_STATUS.ACTIVE,
        storage: 189,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '521 kW',
        total_capacity: 189,
        id: '731a1636-0f37-4ba2-9a97-38cedb953ee4',
        industry: 'Medical',
        voltage: 'MV',
        created_at: '2020-11-29T10:25:11',
        updated_at: '2020-11-29T10:25:11',
        expiry_date: '2025-11-04T07:45:49',
        location_type: 'Urban',
        dso: 'EDD',
        profile: 'P013',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 14',
        status: ASSET_STATUS.ACTIVE,
        storage: 120,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '501 kW',
        total_capacity: 120,
        id: '4985d26f-a08f-444b-b76f-73dae88f28d0',
        industry: 'Medical',
        voltage: 'MV',
        created_at: '2023-02-12T03:09:58',
        updated_at: '2023-02-12T03:09:58',
        expiry_date: '2024-01-01T01:05:13',
        location_type: 'Rural',
        dso: 'EDM',
        profile: 'P014',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 15',
        status: ASSET_STATUS.ACTIVE,
        storage: 145,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '572 kW',
        total_capacity: 145,
        id: '52bdebb7-7804-4a20-8b3d-91378258a978',
        industry: 'Textile',
        voltage: 'MV',
        created_at: '2020-07-28T06:35:59',
        updated_at: '2020-07-28T06:35:59',
        expiry_date: '2024-02-22T04:00:12',
        location_type: 'Rural',
        dso: 'EDB',
        profile: 'P015',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 16',
        status: ASSET_STATUS.ACTIVE,
        storage: 173,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '542 kW',
        total_capacity: 173,
        id: '6b3f6bdf-fa7e-45ea-8c95-7e6ef6e54b14',
        industry: 'Textile',
        voltage: 'LV',
        created_at: '2021-11-02T04:10:05',
        updated_at: '2021-11-02T04:10:05',
        expiry_date: '2031-07-04T04:23:49',
        location_type: 'Urban',
        dso: 'DEO',
        profile: 'P016',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 17',
        status: ASSET_STATUS.ACTIVE,
        storage: 134,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '531 kW',
        total_capacity: 134,
        id: 'b27881f7-fccc-437f-95b1-ff22c93e5758',
        industry: 'Auto',
        voltage: 'LV',
        created_at: '2020-12-13T09:44:47',
        updated_at: '2020-12-13T09:44:47',
        expiry_date: '2024-12-24T05:09:40',
        location_type: 'Urban',
        dso: 'EDB',
        profile: 'P017',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 18',
        status: ASSET_STATUS.ACTIVE,
        storage: 191,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '562 kW',
        total_capacity: 191,
        id: '6e55c29e-420a-44ce-843c-b0134ad944fc',
        industry: 'Textile',
        voltage: 'MV',
        created_at: '2022-04-09T11:24:39',
        updated_at: '2022-04-09T11:24:39',
        expiry_date: '2025-01-18T07:44:39',
        location_type: 'Rural',
        dso: 'EDD',
        profile: 'P018',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 19',
        status: ASSET_STATUS.ACTIVE,
        storage: 123,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '537 kW',
        total_capacity: 123,
        id: 'a19ac135-213c-45d9-9447-abe6d4f80bec',
        industry: 'Auto',
        voltage: 'MV',
        created_at: '2023-02-12T05:15:27',
        updated_at: '2023-02-12T05:15:27',
        expiry_date: '2026-08-19T03:44:57',
        location_type: 'Urban',
        dso: 'EDM',
        profile: 'P019',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 20',
        status: ASSET_STATUS.ACTIVE,
        storage: 179,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '583 kW',
        total_capacity: 179,
        id: '432f2c3a-7311-48f0-bc0b-cc7f54890c61',
        industry: 'Textile',
        voltage: 'MV',
        created_at: '2023-02-14T03:08:14',
        updated_at: '2023-02-14T03:08:14',
        expiry_date: '2026-05-08T02:43:59',
        location_type: 'Urban',
        dso: 'DEL',
        profile: 'P020',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 21',
        status: ASSET_STATUS.ACTIVE,
        storage: 726,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '999 kW',
        total_capacity: 726,
        id: '962ef1ac-98d3-4957-b696-b9503a704dd3',
        industry: 'Textile',
        voltage: 'MV',
        created_at: '2021-04-06T05:08:51',
        updated_at: '2021-04-06T05:08:51',
        expiry_date: '2027-03-05T02:01:16',
        location_type: 'Rural',
        dso: 'DEO',
        profile: 'P021',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 22',
        status: ASSET_STATUS.ACTIVE,
        storage: 392,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '1003 kW',
        total_capacity: 392,
        id: '0e9db42e-0ca6-4226-8daf-4c81fcc84c3e',
        industry: 'Textile',
        voltage: 'HV',
        created_at: '2021-12-29T04:57:56',
        updated_at: '2021-12-29T04:57:56',
        expiry_date: '2023-12-31T05:39:33',
        location_type: 'Rural',
        dso: 'DEL',
        profile: 'P022',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 23',
        status: ASSET_STATUS.ACTIVE,
        storage: 543,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '1211 kW',
        total_capacity: 543,
        id: 'f8963f74-ddbf-494e-9994-fd0e41f6841a',
        industry: 'Textile',
        voltage: 'HV',
        created_at: '2021-11-01T05:44:43',
        updated_at: '2021-11-01T05:44:43',
        expiry_date: '2030-08-17T11:19:41',
        location_type: 'Rural',
        dso: 'EDB',
        profile: 'P023',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 24',
        status: ASSET_STATUS.ACTIVE,
        storage: 628,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '936 kW',
        total_capacity: 628,
        id: '717bc010-a669-4625-b92f-5156abacd99e',
        industry: 'Medical',
        voltage: 'MV',
        created_at: '2020-12-23T11:28:18',
        updated_at: '2020-12-23T11:28:18',
        expiry_date: '2030-01-20T10:00:44',
        location_type: 'Rural',
        dso: 'EDD',
        profile: 'P024',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        type: 'Prosumer',
        name: 'Prosumers OGRE 25',
        status: ASSET_STATUS.ACTIVE,
        storage: 851,
        storagePercentage: 0.7,
        accuracy: 0.98,
        demand: '1083 kW',
        total_capacity: 851,
        id: '9ca88c27-65f2-40b0-b15d-6a3076e67aa7',
        industry: 'Auto',
        voltage: 'LV',
        created_at: '2022-03-02T07:25:16',
        updated_at: '2022-03-02T07:25:16',
        expiry_date: '2024-12-24T12:10:23',
        location_type: 'Urban',
        dso: 'DEL',
        profile: 'P025',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    }
];

export const mockedProsumers = {
    title: '8083437449001',
    type: 'Prosumer',
    name: 'Prosumers OGRE',
    status: ASSET_STATUS.ACTIVE,
    storage: 100,
    storagePercentage: 0.7,
    accuracy: 0.98,
    demand: '920 kW',
    total_capacity: 100,
    country: 'RO',
    location: 'Baneasa',
    longitude: 44.06667,
    latitude: 27.7,
    voltage: 'HV',
    dso: 'DEL',
    created_at: new Date(),
    updated_at: new Date()
};
