import { DialogContent } from '@mui/material';
import { useContext } from 'react';

import { MODAL_IDS } from '..';
import Cleanup from '../../components/Cleanup/Cleanup';
import { ModalContext } from '../../hooks/modalContext';
import ProjectForm from '../CreateProjectModal/ProjectForm';
import { useAppDispatch, useAppSelector } from '../../stores';
import { FormModal } from '../../components/BaseModal/FormModal';
import { resetUpdateProjectsState } from '../../stores/Projects';
import { FormErrorStep, FormSuccessStep } from '../../components/FormUtils';

import style from '../CreateProjectModal/style.module.scss';

const EditProjectModal = () => {
    const dispatch = useAppDispatch();
    const { success, error } = useAppSelector((state) => state.updateProjects);
    const { payload } = useContext(ModalContext);

    const handleCleanup = () => {
        // use reset create project state
        dispatch(resetUpdateProjectsState());
    };

    return (
        <FormModal id={MODAL_IDS.ADMIN_EDIT_PROJECT} fullWidth={false} maxWidth="lg">
            <DialogContent className={style.modalContainer}>
                {!(success || error) ? <ProjectForm project={payload?.project} /> : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
                {/* needed because create asset modal never unmounts but what is inside of dialog content yes */}
                <Cleanup cb={handleCleanup} />
            </DialogContent>
        </FormModal>
    );
};

export { EditProjectModal };
