import dayjs from 'dayjs';
import { FC, useState } from 'react';
import * as yup from 'yup';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormHelperText,
    Input,
    InputLabel,
    Stack,
    TextField,
    Typography
} from '@mui/material';

import { ReactComponent as DeleteSVG } from '../../assets/icons/delete_forever.svg';
import { useAppDispatch } from '../../stores';
import { MODAL_IDS } from '..';
import { useFormLogic } from '../../hooks/useFormLogic';
import { ForecastType } from '../../interfaces/apiv2';
import { FormModalActions } from '../../components/FormUtils';
import { deliverForecastAsync } from '../../stores/Assets';
import { DeliverForecastFormData, GenerationAssetFE, PostDeliverForecastBody } from '../../interfaces/uiv2';

import style from './style.module.scss';

const getSchema = (emails: string[]) => {
    const schemaObject: any = {
        start_date: yup.string(),
        end_date: yup.string(),
        type: yup.string().required().oneOf(Object.values(ForecastType)),
        defaultEmails: yup.boolean()
    };

    emails.forEach((email, index) => {
        schemaObject[`email${index}`] = yup.string().email();
    });

    return yup.object(schemaObject);
};

const getDefaultValues = (emails: string[]) => {
    const defaultValues: any = {
        start_date: dayjs().startOf('day') || '',
        end_date: '',
        type: ForecastType.Intraday,
        defaultEmails: false
    };

    emails.forEach((email, index) => {
        defaultValues[`email${index}`] = email || undefined;
    });

    return defaultValues;
};

export const DeliverForecastForm: FC<{ asset?: GenerationAssetFE }> = ({ asset }) => {
    const dispatch = useAppDispatch();
    const { t: generalTranslation } = useTranslation();
    const { t } = useTranslation('assets/generation');
    const [emails, setEmails] = useState(['']);
    const schema = getSchema(emails);

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useFormLogic<DeliverForecastFormData, DeliverForecastFormData, any, any>({
        schema,
        data: null,
        defaultValues: getDefaultValues(emails)
    });

    const isEmailDisabled = watch('defaultEmails');

    const onSubmit = (form: DeliverForecastFormData) => {
        const emailList = emails
            .map((e, index) => {
                return form[`email${index}`];
            })
            .filter((a) => a);
        const body: PostDeliverForecastBody = {
            start_date: form.start_date ? dayjs(form.start_date).utc().toISOString() : undefined,
            end_date: form.end_date ? dayjs(form.end_date).utc().toISOString() : undefined,
            emails: form.defaultEmails ? null : emailList,
            assetId: asset!.id,
            type: form.type
        };

        dispatch(deliverForecastAsync(body));
    };

    const addEmail = () => {
        setEmails([...emails, '']);
    };

    const removeEmailField = (index: number) => () => {
        const newEmails = emails.filter((e, i) => i !== index);
        setValue(`email${index}`, '');
        setEmails(newEmails);
    };

    return (
        <Box className={style.container}>
            <Typography variant="h6">
                {t('deliverForecast')} <span className={style.assetName}>{asset?.name}</span>
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ p: 2 }}>
                <Box className={style.mb2}>
                    <Controller
                        name="start_date"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" shrink htmlFor={'startDate'}>
                                    {t('form.startDate')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="startDate"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.start_date && (
                                    <FormHelperText error>{t('form.errors.required')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>
                <Box className={style.mb2}>
                    <Controller
                        name="end_date"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" shrink htmlFor={'endDate'}>
                                    {t('form.endDate')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="endDate"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.end_date && <FormHelperText error>{t('form.errors.required')}</FormHelperText>}
                            </>
                        )}
                    />
                </Box>
                <Box className={style.mb2}>
                    {!!emails.length && (
                        <InputLabel variant="standard" shrink htmlFor={t('form.email')}>
                            {t('form.email')}
                        </InputLabel>
                    )}
                    {emails.map((email, index) => {
                        return (
                            <Controller
                                key={index}
                                name={`email${index}`}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Box className={style.emailInput}>
                                            <Input
                                                {...field}
                                                fullWidth
                                                type="email"
                                                id={t('form.email')}
                                                disabled={isEmailDisabled}
                                                placeholder={t('form.email')}
                                            />
                                            <DeleteSVG className={style.icon} onClick={removeEmailField(index)} />
                                            {errors[`email${index}`] && (
                                                <FormHelperText error>{t('form.errors.email')}</FormHelperText>
                                            )}
                                        </Box>
                                    </>
                                )}
                            />
                        );
                    })}
                </Box>
                <Box className={style.mb2}>
                    <Controller
                        name="defaultEmails"
                        control={control}
                        render={({ field }) => (
                            <>
                                <Stack direction="row" spacing={3}>
                                    <FormControlLabel
                                        componentsProps={{ typography: { variant: 'small1' } }}
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={t('form.defaultEmailValues')}
                                    />
                                </Stack>
                            </>
                        )}
                    />
                </Box>
                <Box className={style.mb2}>
                    <Button
                        disabled={isEmailDisabled}
                        component="span"
                        variant="primary"
                        className={style.btn}
                        onClick={addEmail}
                    >
                        {t('form.addEmail')}
                    </Button>
                </Box>
                <Box className={style.mb2}>
                    <InputLabel variant="standard" shrink>
                        {t('form.forecastTypeLabel')}
                    </InputLabel>
                    <Box className={style.row}>
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Stack direction="row" spacing={3}>
                                        <FormControlLabel
                                            componentsProps={{ typography: { variant: 'small1' } }}
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value === ForecastType.Intraday}
                                                    onChange={() => {
                                                        field.onChange(ForecastType.Intraday);
                                                    }}
                                                />
                                            }
                                            label={t('form.intraday')}
                                        />
                                    </Stack>
                                </>
                            )}
                        />
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Stack direction="row" spacing={3}>
                                        <FormControlLabel
                                            componentsProps={{ typography: { variant: 'small1' } }}
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value === ForecastType.DayAhead}
                                                    onChange={() => {
                                                        field.onChange(ForecastType.DayAhead);
                                                    }}
                                                />
                                            }
                                            label={t('form.dayAhead')}
                                        />
                                    </Stack>
                                </>
                            )}
                        />
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Stack direction="row" spacing={3}>
                                        <FormControlLabel
                                            componentsProps={{ typography: { variant: 'small1' } }}
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value === ForecastType.LongTerm}
                                                    onChange={() => {
                                                        field.onChange(ForecastType.LongTerm);
                                                    }}
                                                />
                                            }
                                            label={t('form.longTerm')}
                                        />
                                    </Stack>
                                </>
                            )}
                        />
                    </Box>
                    {errors.type && <FormHelperText error>{t('form.errors.generic')}</FormHelperText>}
                </Box>

                <Box>
                    <Typography variant="small1">{t('deliverForecastInfo1')}</Typography>
                </Box>
                <Box className={classNames(style.mb2, style.w500)}>
                    <Typography variant="small1">{t('deliverForecastInfo2')}</Typography>
                </Box>

                <Divider className={style.divider} />

                <FormModalActions
                    loading={false}
                    modalId={MODAL_IDS.DELIVER_FORECAST_MODAL}
                    cancelLabel={generalTranslation('nevermind')}
                    saveLabel={generalTranslation('submit')}
                />
            </Box>
        </Box>
    );
};

export default DeliverForecastForm;
