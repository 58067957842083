import { ASSET_TYPE, GasConsumerType } from '../interfaces/uiv2';
import { ReactComponent as WindIcon } from '../assets/icons/park_types/wind_type.svg';
import { ReactComponent as SolarIcon } from '../assets/icons/park_types/solar_type.svg';
import { ReactComponent as HydroIcon } from '../assets/icons/park_types/hydro_type.svg';
import { ReactComponent as NuclearIcon } from '../assets/icons/park_types/nuclear_type.svg';
import { ReactComponent as CoalIcon } from '../assets/icons/park_types/coal_type.svg';
import { ReactComponent as BiomassIcon } from '../assets/icons/park_types/biomass_type.svg';
import { ReactComponent as GeothermalIcon } from '../assets/icons/park_types/geothermal_type.svg';
import { ReactComponent as GasIcon } from '../assets/icons/park_types/gas_type.svg';
import { ReactComponent as OtherIcon } from '../assets/icons/park_types/other_assets.svg';
import { ReactComponent as ConsumersIcon } from '../assets/icons/demand/consumers.svg';
import { ReactComponent as ProsumersIcon } from '../assets/icons/navigation-items/menu-item-prosumers.svg';
import { ReactComponent as VPPIcon } from '../assets/icons/vpp.svg';
import { ReactComponent as IndustrialIcon } from '../assets/icons/demand/factory.svg';
import { ReactComponent as CommercialIcon } from '../assets/icons/demand/commercial.svg';
import { ReactComponent as HouseholdIcon } from '../assets/icons/demand/household.svg';
import { ReactComponent as EVSIcon } from '../assets/icons/navigation-items/menu-item-evs.svg';
import { ReactComponent as PLIcon } from '../assets/icons/PL.svg';
import { ReactComponent as TransformersIcon } from '../assets/icons/Transformer.svg';
import { ReactComponent as PoDIcon } from '../assets/icons/dashboard/PoD.svg';
import { ReactComponent as StorageIcon } from '../assets/icons/dashboard/storage.svg';
import { ReactComponent as ChargingStationIcon } from '../assets/icons/dashboard/ev_station.svg';
import { ReactComponent as ChargingPointIcon } from '../assets/icons/dashboard/ev_point.svg';
import { ReactComponent as LocationsIcon } from '../assets/icons/earth.svg';
import { ReactComponent as GenerationIcon } from '../assets/icons/navigation-items/generation.svg';
import { ReactComponent as DemandIcon } from '../assets/icons/navigation-items/menu-item-demand.svg';

import SettingsIcon from '@mui/icons-material/Settings';
import { ChargingStationStatus } from '../interfaces/apiv2';

export type ALL_TYPES =
    | ASSET_TYPE
    | GasConsumerType
    | 'VPP'
    | 'PL'
    | 'Transformers'
    | 'Meters'
    | 'Storage'
    | 'ChargingStation'
    | 'ChargingPoint'
    | 'Locations'
    | 'Generation'
    | 'Demand'
    | 'Prosumers'
    | ChargingStationStatus;

export const getAssetIcon = (type: ALL_TYPES, className?: string) => {
    switch (type) {
        case ASSET_TYPE.WIND:
            return <WindIcon className={className} />;
        case ASSET_TYPE.SOLAR:
            return <SolarIcon className={className} />;
        case ASSET_TYPE.HYDRO:
            return <HydroIcon className={className} />;
        case ASSET_TYPE.NUCLEAR:
            return <NuclearIcon className={className} />;
        case ASSET_TYPE.COAL:
            return <CoalIcon className={className} />;
        case ASSET_TYPE.BIOMASS:
            return <BiomassIcon className={className} />;
        case ASSET_TYPE.GEOTHERMAL:
            return <GeothermalIcon className={className} />;
        case ASSET_TYPE.GAS:
            return <GasIcon className={className} />;
        case ASSET_TYPE.OTHER:
            return <OtherIcon className={className} />;
        case ASSET_TYPE.CONSUMER:
            return <ConsumersIcon className={className} />;
        case ASSET_TYPE.PROSUMER:
            return <ProsumersIcon className={className} />;
        case ASSET_TYPE.EVS:
            return <EVSIcon className={className} />;
        case 'VPP':
            return <VPPIcon className={className} />;
        case 'ASOS':
            return <CommercialIcon className={className} />;
        case 'CAS':
            return <HouseholdIcon className={className} />;
        case 'NCAS':
            return <IndustrialIcon className={className} />;
        case 'PL':
            return <PLIcon className={className} />;
        case 'Transformers':
            return <TransformersIcon className={className} />;
        case 'Meters':
            return <PoDIcon className={className} />;
        case 'Storage':
            return <StorageIcon className={className} />;
        case 'ChargingStation':
            return <ChargingStationIcon className={className} />;
        case 'ChargingPoint':
            return <ChargingPointIcon className={className} />;
        case 'Locations':
            return <LocationsIcon className={className} />;
        case 'Generation':
            return <GenerationIcon className={className} />;
        case 'Demand':
            return <DemandIcon className={className} />;
        default:
            return <SettingsIcon className={className} />;
    }
};
