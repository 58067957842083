import { ASSET_TYPE, DashboardStaticItem } from '../interfaces/uiv2';
import { DashboardItem, Dashboard, GenerationAsset } from '../interfaces/apiv2';
import { Layouts } from 'react-grid-layout';

export const addToDashboardLayout = (dashboardItem: DashboardItem, dashboard?: Dashboard | null) => {
    const newItemW = dashboardItem.size * 4;

    if (dashboard) {
        const [lastItem] = dashboard.layout.lg.slice(-1);

        const item = { x: 0, y: 0, w: newItemW, h: 3, i: dashboardItem.id };

        // what if last item has x: 8, w:4; h: 1;
        // new item should be x: 8, w: 4, h: 2
        if (lastItem) {
            // 4 + 4 + 4 <= 12 // true
            if (lastItem.x + lastItem.w + newItemW <= 12) {
                // item.x = 8
                item.x = lastItem.x + lastItem.w;
                item.y = lastItem.y;
            } else {
                item.x = 0;
                item.y = lastItem.y + 3;
            }
        }

        const newLayout = [...(dashboard?.layout?.lg || []), item];

        return { id: dashboard.id, layout: { lg: newLayout } };
    }

    const item = { x: 0, y: 0, w: newItemW, h: 3, i: dashboardItem.id };

    return { layout: { lg: [item] } };
};

export const deleteFromDashboardLayout = (dashboardItem: DashboardItem, layoutInfo: Dashboard) => {
    const newLayout = layoutInfo.layout.lg.filter((item) => item.i !== dashboardItem.id);

    // rearrange the items in the layout.

    return newLayout;
};

export const generateStaticCard = (assets: GenerationAsset[]): DashboardStaticItem => {
    return assets.reduce(
        (acc, item) => {
            const isSolar = item.type === ASSET_TYPE.SOLAR;
            const isWind = item.type === ASSET_TYPE.WIND;
            const isOther = ![ASSET_TYPE.WIND, ASSET_TYPE.SOLAR].includes(item.type);

            return {
                ...acc,
                totalCapacity: acc.totalCapacity + item.capacity_value,

                totalSolar: isSolar ? acc.totalSolar + 1 : acc.totalSolar,
                totalWind: isWind ? acc.totalWind + 1 : acc.totalWind,
                totalOthers: isOther ? acc.totalOthers + 1 : acc.totalOthers,

                totalSolarCapacity: acc.totalSolarCapacity + (isSolar ? item.capacity_value : 0),
                totalWindCapacity: acc.totalWindCapacity + (isWind ? item.capacity_value : 0),
                totalOthersCapacity: acc.totalOthersCapacity + (isOther ? item.capacity_value : 0)
            };
        },
        {
            totalSolar: 0,
            totalWind: 0,
            totalOthers: 0,
            totalSolarCapacity: 0,
            totalWindCapacity: 0,
            totalOthersCapacity: 0,
            totalCapacity: 0,
            totalAssets: assets.length
        }
    );
};

export const getLinesColor = (asset?: GenerationAsset) => {
    if (asset?.type === ASSET_TYPE.SOLAR) {
        return '#6A9BFF';
    }

    return '#6FD8B2';
};

/**
 * @name default layout for users that don't have the dashboard entity created yet.
 */
export const DASHBOARD_DEFAULT_LAYOUT = {
    lg: [{ i: 'static', w: 4, h: 3, x: 0, y: 0, static: true }]
} as Layouts;
