import dayjs from 'dayjs';
import { FC } from 'react';
import * as yup from 'yup';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Autocomplete, Box, Chip, Divider, FormHelperText, InputLabel, TextField, Typography } from '@mui/material';

import { useAppDispatch } from '../../stores';
import { MODAL_IDS } from '..';
import { trainAssetAsync } from '../../stores/Assets';
import { useFormLogic } from '../../hooks/useFormLogic';
import { FormModalActions } from '../../components/FormUtils';
import { GenerationAssetFE, TrainAssetFormData } from '../../interfaces/uiv2';
import { getFormattedDate } from '../../utils/helpers';
import { DATE_SETTINGS } from '../../utils/config';

import style from './style.module.scss';
import common from '../../common.module.scss';
import { useGetAssetForecastSettingsQuery } from '../../stores/GenerationAssetForecastSettingsV2';
import { MdKeyboardArrowDown } from 'react-icons/md';

export const TrainAssetForm: FC<{ asset?: GenerationAssetFE }> = ({ asset }) => {
    const dispatch = useAppDispatch();
    const { t: generalTranslation } = useTranslation();

    const { t } = useTranslation('assets/generation');
    const schema = yup.object({
        start_date: yup.string().required(),
        end_date: yup.string().nullable(),
        forecastSettings: yup.object().required()
    });

    const { data: forecastSettings } = useGetAssetForecastSettingsQuery({ assetId: asset!.id }, { skip: !asset });

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useFormLogic<TrainAssetFormData, TrainAssetFormData, any, any>({
        schema,
        data: null,
        defaultValues: {
            start_date: asset!.meteo_start_date || '',
            end_date: dayjs().startOf('date')
        }
    });

    const onSubmit = (form: TrainAssetFormData) => {
        const dates = {
            start_date: dayjs(form.start_date).utc().toISOString(),
            end_date: form.end_date ? dayjs(form.end_date).utc().toISOString() : undefined
        };

        dispatch(trainAssetAsync({ id: asset!.id, forecast_settings_id: form.forecastSettings.id, ...dates }));
    };

    const chips = ['first_generation_timestamp', 'last_generation_timestamp'];

    return (
        <Box className={style.container}>
            <Typography variant="h6">
                {t('trainAsset')} <span className={style.assetName}>{asset?.name}</span>
            </Typography>
            <Box className={common.chipsBox}>
                {chips.map((item) => (
                    <Chip
                        key={item}
                        label={`${t(`form.${item}`)}: ${getFormattedDate({
                            value: asset?.[item as keyof typeof asset],
                            timezone: 'UTC',
                            type: DATE_SETTINGS.shortFormatHoursIncluded
                        })}`}
                        color={asset?.[item as keyof typeof asset] ? 'success' : 'error'}
                    />
                ))}
            </Box>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ p: 2 }}>
                <Box sx={{ mb: 2 }}>
                    <Controller
                        name="start_date"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={'startDate'}>
                                    {t('form.startDate')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="startDate"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.start_date && (
                                    <FormHelperText error>{t('form.validations.required')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Controller
                        name="end_date"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" shrink htmlFor={'endDate'}>
                                    {t('form.endDate')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="endDate"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.end_date && (
                                    <FormHelperText error>{t('form.validations.required')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>

                <Box sx={{ mb: 4 }} className={style.formRow}>
                    <Controller
                        name="forecastSettings"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={'forecast_settings_id'}>
                                    {t('form.forecast_settings_id')}
                                </InputLabel>

                                <Autocomplete
                                    {...field}
                                    value={field.value || null}
                                    id={'forecast_settings_id'}
                                    onChange={(e, v) => {
                                        field.onChange(v);
                                        return v;
                                    }}
                                    ListboxProps={{ style: { maxHeight: '120px' } }}
                                    noOptionsText={generalTranslation('select.noOptions')}
                                    options={forecastSettings || []}
                                    autoHighlight
                                    className={style.selectInput}
                                    disableClearable
                                    popupIcon={<MdKeyboardArrowDown color="#6A9BFF" />}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: t('form.forecast_settings_id')
                                            }}
                                        />
                                    )}
                                />

                                {errors.forecastSettings && (
                                    <FormHelperText error>{t('form.validations.required')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>

                <Divider className={style.divider} />

                <FormModalActions
                    loading={false}
                    modalId={MODAL_IDS.TRAIN_ASSET_MODAL}
                    cancelLabel={generalTranslation('nevermind')}
                    saveLabel={generalTranslation('submit')}
                />
            </Box>
        </Box>
    );
};

export default TrainAssetForm;
