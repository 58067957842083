import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Input, Button, FormHelperText, Stack } from '@mui/material';

import { ROUTES } from '../../../utils/config';
import Logo from '../../../assets/images/logo.svg';
import { ChangePasswordBody } from '../../../interfaces/uiv2';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { changePasswordAsync } from '../../../stores/Auth';
import { BoldInputLabel } from '../../../components/FormUtils/Inputs';
import Illustration from '../../../assets/images/reset-password-illustration.png';

import style from './index.module.scss';

const ChangePassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('auth/changePassword');
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector((state) => state.changePassword);
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState<string | undefined | null>(undefined);

    const schema = yup
        .object({
            password: yup
                .string()
                .matches(/^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/, t('requirements'))
                .required(t('requiredPassword')),
            confirmPassword: yup.string().test('passwords-match', t('passwordsMatch'), function (value) {
                return this.parent.password === value;
            })
        })
        .required();

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<ChangePasswordBody['data']>({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data: ChangePasswordBody['data']) => dispatch(changePasswordAsync({ data, token }));

    useEffect(() => {
        const tokenParam = searchParams.get('token');
        setToken(tokenParam);
    }, []);

    const goToLogin = () => navigate(ROUTES.LOGIN);

    return (
        <Box className={style.authWrapper}>
            <Box className={style.changePassword}>
                <Box className={style.illustration}>
                    <img src={Illustration} alt={t('reset')} />
                </Box>
                <Box className={style.content}>
                    <Box className={style.logoContainer}>
                        <Link to={ROUTES.LOGIN}>
                            <img className={style.logo} src={Logo} />
                        </Link>
                    </Box>
                    <Box className={style.container}>
                        <h2>{t('title')}</h2>
                        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name={'password'}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <BoldInputLabel shrink htmlFor={t('newPassord')}>
                                            {t('newPassord')}
                                        </BoldInputLabel>
                                        <Input
                                            {...field}
                                            id={t('newPassord')}
                                            fullWidth
                                            placeholder={t('newPasswordPlaceholder')}
                                            type="password"
                                        />
                                        <FormHelperText error>{errors.password?.message}</FormHelperText>
                                    </>
                                )}
                            />
                            <Controller
                                name={'confirmPassword'}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <BoldInputLabel shrink htmlFor={t('confirmPassword')}>
                                            {t('confirmPassword')}
                                        </BoldInputLabel>
                                        <Input
                                            {...field}
                                            id={t('confirmPassword')}
                                            fullWidth
                                            placeholder={t('confirmPasswordPlaceholder')}
                                            type="password"
                                        />
                                        <FormHelperText error>{errors.confirmPassword?.message}</FormHelperText>
                                    </>
                                )}
                            />
                            <Stack spacing={2} direction="row">
                                <LoadingButton loading={loading} disabled={loading} type="submit" variant="primary">
                                    {t('reset')}
                                </LoadingButton>
                            </Stack>
                        </Box>
                    </Box>
                    <Box className={style.options}>
                        <span>{t('continue')}</span>
                        <Button onClick={goToLogin} variant="white">
                            {t('signIn')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ChangePassword;
