import { useState } from 'react';
import { Box, Button } from '@mui/material';

import { MANUAL_UUID_FOR_CREATE } from './helpers';
import { useGetMeteoSourcesQuery } from '../../stores/Meteo';
import IndividualForecastSettings from './IndividualForecastSettings';
import { ForecastSettingsFEV2, GenerationAssetFE } from '../../interfaces/uiv2';
import { useGetAssetForecastSettingsQuery } from '../../stores/GenerationAssetForecastSettingsV2';

const GenerationAssetForecastSettings = ({ asset }: { asset: GenerationAssetFE }) => {
    const [localSettings, setLocalSettings] = useState<Omit<ForecastSettingsFEV2, 'asset'>[]>([]);

    const { data: sources = [] } = useGetMeteoSourcesQuery({});

    const { data: forecastSettings } = useGetAssetForecastSettingsQuery({ assetId: asset.id });

    const deleteLocalSetting = (id: string) => {
        setLocalSettings(localSettings.filter((item) => item.id !== id));
    };

    const addNewSetting = () => {
        const newSetting = {
            aggregation_level: 'ASSET',
            error_measurements: ['NMAE', 'MAE'],
            meteo_sources: [],
            name: 'New setting',
            percentiles: false,
            time_resolution: 15,
            use_realtime_data: false,
            id: `${MANUAL_UUID_FOR_CREATE}-${Math.random()}`,
            created_at: 'created_at',
            updated_at: 'updated_at',
            delivery_settings: []
        };
        setLocalSettings([...localSettings, newSetting]);
    };

    return (
        <Box>
            <Button variant="primary" fullWidth onClick={addNewSetting} sx={{ mb: 2 }}>
                Add new setting
            </Button>
            {forecastSettings?.map((forecastSettings) => (
                <IndividualForecastSettings
                    forecastSettings={forecastSettings}
                    key={forecastSettings.id}
                    assetType={asset.type}
                    assetId={asset.id}
                    sources={sources}
                    deleteLocalSetting={deleteLocalSetting}
                />
            ))}
            {localSettings.map((forecastSettings) => (
                <IndividualForecastSettings
                    forecastSettings={forecastSettings}
                    assetType={asset.type}
                    assetId={asset.id}
                    key={forecastSettings.id}
                    sources={sources}
                    deleteLocalSetting={deleteLocalSetting}
                />
            ))}
        </Box>
    );
};

export default GenerationAssetForecastSettings;
