import { Box } from '@mui/material';
import classnames from 'classnames';

import Loading from '../../../layout/Loading';
import style from './BaseTablePagination.module.scss';

interface BaseTablePaginationProps {
    loading: boolean;
}

const BaseTablePagination = (props: BaseTablePaginationProps) => {
    const { loading } = props;

    if (loading) {
        return (
            <Box className={classnames(style.tableFooter, 'tableFooterContainer')}>
                <Loading />
            </Box>
        );
    }

    return null;
};

export { BaseTablePagination };
