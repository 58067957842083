import { Box } from '@mui/system';
import { Typography, TypographyProps } from '@mui/material';

import { ASSET_TYPE, GenerationAssetFE } from '../../interfaces/uiv2';
import { ReactComponent as GasIcon } from '../../assets/icons/park_types/gas_type.svg';
import { ReactComponent as WindIcon } from '../../assets/icons/park_types/wind_type.svg';
import { ReactComponent as CoalIcon } from '../../assets/icons/park_types/coal_type.svg';
import { ReactComponent as SolarIcon } from '../../assets/icons/park_types/solar_type.svg';
import { ReactComponent as HydroIcon } from '../../assets/icons/park_types/hydro_type.svg';
import { ReactComponent as OtherIcon } from '../../assets/icons/park_types/other_assets.svg';
import { ReactComponent as NuclearIcon } from '../../assets/icons/park_types/nuclear_type.svg';
import { ReactComponent as BiomassIcon } from '../../assets/icons/park_types/biomass_type.svg';
import { ReactComponent as GeothermalIcon } from '../../assets/icons/park_types/geothermal_type.svg';
import { ReactComponent as ConsumerIcon } from '../../assets/icons/demand/consumers.svg';
import { ReactComponent as ProsumerIcon } from '../../assets/icons/navigation-items/menu-item-prosumers.svg';

import styles from './vpp.module.scss';

const AssetsVPP = ({
    assets,
    variant = 'small3'
}: {
    assets: GenerationAssetFE[];
    variant?: TypographyProps['variant'];
}) => {
    const types = {
        [ASSET_TYPE.SOLAR]: 0,
        [ASSET_TYPE.WIND]: 0,
        [ASSET_TYPE.HYDRO]: 0,
        [ASSET_TYPE.NUCLEAR]: 0,
        [ASSET_TYPE.COAL]: 0,
        [ASSET_TYPE.BIOMASS]: 0,
        [ASSET_TYPE.GEOTHERMAL]: 0,
        [ASSET_TYPE.GAS]: 0,
        [ASSET_TYPE.OTHER]: 0,
        [ASSET_TYPE.PROSUMER]: 0,
        [ASSET_TYPE.CONSUMER]: 0,
        [ASSET_TYPE.EVS]: 0
    };

    assets.forEach((asset) => {
        types[asset.type] += 1;
    });

    return (
        <>
            {!!types[ASSET_TYPE.WIND] && (
                <Box className={styles.assets}>
                    <WindIcon />
                    <Typography variant={variant}>{types[ASSET_TYPE.WIND]}</Typography>
                </Box>
            )}
            {!!types[ASSET_TYPE.SOLAR] && (
                <Box className={styles.assets}>
                    <SolarIcon />
                    <Typography variant={variant}>{types[ASSET_TYPE.SOLAR]}</Typography>
                </Box>
            )}
            {!!types[ASSET_TYPE.HYDRO] && (
                <Box className={styles.assets}>
                    <HydroIcon />
                    <Typography variant={variant}>{types[ASSET_TYPE.SOLAR]}</Typography>
                </Box>
            )}
            {!!types[ASSET_TYPE.COAL] && (
                <Box className={styles.assets}>
                    <NuclearIcon />
                    <Typography variant={variant}>{types[ASSET_TYPE.COAL]}</Typography>
                </Box>
            )}
            {!!types[ASSET_TYPE.BIOMASS] && (
                <Box className={styles.assets}>
                    <CoalIcon />
                    <Typography variant={variant}>{types[ASSET_TYPE.BIOMASS]}</Typography>
                </Box>
            )}
            {!!types[ASSET_TYPE.GEOTHERMAL] && (
                <Box className={styles.assets}>
                    <BiomassIcon />
                    <Typography variant={variant}>{types[ASSET_TYPE.GEOTHERMAL]}</Typography>
                </Box>
            )}
            {!!types[ASSET_TYPE.NUCLEAR] && (
                <Box className={styles.assets}>
                    <GeothermalIcon />
                    <Typography variant={variant}>{types[ASSET_TYPE.NUCLEAR]}</Typography>
                </Box>
            )}
            {!!types[ASSET_TYPE.GAS] && (
                <Box className={styles.assets}>
                    <GasIcon />
                    <Typography variant={variant}>{types[ASSET_TYPE.GAS]}</Typography>
                </Box>
            )}
            {!!types[ASSET_TYPE.OTHER] && (
                <Box className={styles.assets}>
                    <OtherIcon />
                    <Typography variant={variant}>{types[ASSET_TYPE.OTHER]}</Typography>
                </Box>
            )}
            {!!types[ASSET_TYPE.CONSUMER] && (
                <Box className={styles.assets}>
                    <ConsumerIcon />
                    <Typography variant={variant}>{types[ASSET_TYPE.CONSUMER]}</Typography>
                </Box>
            )}
            {!!types[ASSET_TYPE.PROSUMER] && (
                <Box className={styles.assets}>
                    <ProsumerIcon />
                    <Typography variant={variant}>{types[ASSET_TYPE.PROSUMER]}</Typography>
                </Box>
            )}
        </>
    );
};

export default AssetsVPP;
