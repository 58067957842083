import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { MdAdd, MdDeleteOutline, MdRefresh } from 'react-icons/md';

import { MODAL_IDS } from '../../modals';
import { ROUTES } from '../../utils/config';
import { hasAccessTo } from '../../utils/user';
import { getProjectsHeadCells } from './helpers';
import { ProjectsDataFE } from '../../interfaces/uiv2';
import { PROJECT_MODULE } from '../../interfaces/apiv2';
import { ModalContext } from '../../hooks/modalContext';
import useStickyHeader from '../../hooks/useStickyHeader';
import { PageContainer } from '../../layout/PageContainer';
import { PERMISSION_TYPE } from '../../stores/Permissions';
import { useAppDispatch, useAppSelector } from '../../stores';
import { PermissionsModal } from '../../modals/PermissionsModal';
import { EditProjectModal } from '../../modals/EditProjectModal';
import { CreateProjectModal } from '../../modals/CreateProjectModal';
import { GenericTable, TableSearch } from '../../components/BaseTable';
import { RemoveForecastContainer } from '../../components/RemoveEntityContainer';
import { transformOrderByToSort } from '../../components/BaseTable/GenericTable/helpers';
import { ReactComponent as ProjectsIcon } from '../../assets/icons/navigation-items/menu-item-widgets.svg';
import {
    getProjectsListAsync,
    resetListProjectsState,
    setSelectedRows,
    setGetParams,
    deleteProjectsListAsync
} from '../../stores/Projects';

import style from '../../features/forecast-list/forecast-list.module.scss';

const ProjectsList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { handleClickOpen } = useContext(ModalContext);

    const [activeRowPermissions, setActiveRowPermissions] = useState<ProjectsDataFE>();

    const { user } = useAppSelector((state) => state.login);
    const { deleted } = useAppSelector((state) => state.deleteProjects);
    const { created } = useAppSelector((state) => state.createProjects);
    const { updated } = useAppSelector((state) => state.updateProjects);
    const { projectsFE, pager, loading, getParams, selectedRows, success } = useAppSelector(
        (state) => state.listProjects
    );

    const hasAccessToCreateProjects = hasAccessTo(user!, ['Admin.Projects.UPDATE']);

    useEffect(() => {
        dispatch(getProjectsListAsync(getParams));
        return () => {
            dispatch(resetListProjectsState());
        };
    }, []);

    useEffect(() => {
        dispatch(getProjectsListAsync(getParams));
    }, [getParams, deleted, created, updated]);

    const { t } = useTranslation();
    const headCells = getProjectsHeadCells(t);

    const clearSelection = () => {
        dispatch(setSelectedRows([]));
    };

    const removeProjects = () => {
        dispatch(deleteProjectsListAsync(selectedRows.map((a) => a.id)));
        clearSelection();
    };

    const openConfirmationModal = () => {
        handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    };

    const goToPage = (row: ProjectsDataFE) => () => {
        if (row.module === PROJECT_MODULE.GAS) {
            navigate(`${ROUTES.AI_GAS_DEMAND_CONSUMERS.replace(':projectId', row.id)}`);
        }

        if (row.module === PROJECT_MODULE.ELECTRICITY) {
            navigate(`${ROUTES.AI_DEMAND_CONSUMERS.replace(':projectId', row.id)}`);
        }

        if (row.module === PROJECT_MODULE.ELECTRICITY) {
            navigate(`${ROUTES.AI_EVS_CHARGHING_STATIONS.replace(':projectId', row.id)}`);
        }
    };

    const editProject = (row: ProjectsDataFE) => () => {
        handleClickOpen(MODAL_IDS.ADMIN_EDIT_PROJECT, { project: row });
    };

    const handleCreateNewProject = () => {
        handleClickOpen(MODAL_IDS.ADMIN_ADD_NEW_PROJECT);
    };

    const handleEditPermissions = (row: ProjectsDataFE) => () => {
        setActiveRowPermissions(row);
        handleClickOpen(MODAL_IDS.ASSET_PERMISSIONS_MODAL);
    };
    useStickyHeader('project-list-header');

    return (
        <PageContainer>
            <Box className={style.header} id={'project-list-header'}>
                <Typography variant="h6" className={style.title}>
                    <ProjectsIcon />
                    {t('header.projects')}
                </Typography>

                <Box className={style.toolbar}>
                    {!!selectedRows.length && (
                        <>
                            <Typography variant="small4" className={style.label}>
                                {selectedRows.length} {t('table.general.selected')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdRefresh />
                                <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                    {t('table.general.clearSelection')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdDeleteOutline />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={openConfirmationModal}
                                    sx={{ ml: 1 }}
                                >
                                    {t('table.general.removeAll')}
                                </Box>
                            </Typography>
                        </>
                    )}
                    <TableSearch setGetParams={setGetParams} getParams={getParams} />
                    {selectedRows.length === 0 && hasAccessToCreateProjects && (
                        <Button disableRipple variant="cta" onClick={handleCreateNewProject} sx={{ ml: 1 }}>
                            <MdAdd />
                        </Button>
                    )}
                </Box>
            </Box>

            <GenericTable
                rows={projectsFE}
                loading={loading}
                success={success}
                pager={pager}
                isCheckboxEnabled
                columns={headCells}
                rowUniqueId={'id'}
                selectedRows={selectedRows}
                setGetParams={setGetParams}
                setSelectedRows={setSelectedRows}
                searchParam={getParams.search}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: goToPage,
                    edit: hasAccessToCreateProjects && editProject,
                    editPermissions: handleEditPermissions
                }}
            />
            <CreateProjectModal />
            <EditProjectModal />
            <RemoveForecastContainer cb={removeProjects} stateAccessor="deleteProjects" />
            {activeRowPermissions && (
                <PermissionsModal
                    selectedItem={activeRowPermissions}
                    resetActiveRow={() => setActiveRowPermissions(undefined)}
                    permissionType={PERMISSION_TYPE.PROJECT}
                />
            )}
        </PageContainer>
    );
};

export default ProjectsList;
