import { TFunction } from 'i18next';
import { Box } from '@mui/material';

import { getAssetIcon } from '../../utils/getAssetIcon';
import { DeliveredForecastFE, GasDeliveredForecastFE } from '../../interfaces/uiv2';
import { TableCellRenderer } from '../../interfaces/general';
import { DefaultSearchableCell } from '../../components/BaseTable';
import { DownloadButton, DownloadExtension } from '../../components/DownloadButton';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { extractDate, getFormattedDate } from '../../utils/helpers';
import { DATE_SETTINGS } from '../../utils/config';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../utils/table';

export const getTableHeadCells = (t: TFunction): TableCellRenderer<DeliveredForecastFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 75,
            label: t('tableHead.type'),
            value: (row) => {
                return getAssetIcon(row.forecast_settings.asset.type);
            },
            sort: true,
            sort_id: 'forecast_asset.type'
        },
        {
            id: 'forecast_type',
            label: t('tableHead.forecast_type'),
            sort: false,
            value: (row, searchValue, onClick, handlers) => {
                const value = t(row.forecast_delivery_interval.name || 'allForecast');
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;

                return <DefaultSearchableCell value={value} searchValue={searchValue} isLink linkUrl={callback()} />;
            }
        },
        {
            id: 'asset',
            label: t('tableHead.asset'),
            sort: true,
            value: (row, searchValue) => {
                const value = row.forecast_settings.asset.name;

                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            },
            sort_id: 'forecast_asset.name'
        },
        {
            id: 'from',
            label: t('tableHead.from'),
            sort: false,
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.from_date} searchValue={searchValue} />;
            }
        },
        {
            id: 'to',
            label: t('tableHead.to'),
            sort: false,
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.to_date} searchValue={searchValue} />;
            }
        },
        {
            id: 'download',
            label: t('tableHead.download'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const filename = `Ogre_Forecast_${row.forecast_settings.asset.name}_${row.from_date}-${row.to_date}`;
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <DownloadButton
                            type={DownloadExtension.Pdf}
                            id={row.id}
                            filename={filename}
                            disabled={!!row.group_id}
                        />
                        <DownloadButton type={DownloadExtension.Excel} id={row.id} filename={filename} />
                        <DownloadButton type={DownloadExtension.Csv} id={row.id} filename={filename} />
                    </Box>
                );
            }
        }
    ];
};
export const getGasTableHeadCells = (t: TFunction, projectId: string): TableCellRenderer<GasDeliveredForecastFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'name',
            label: t('tableHead.forecast_type'),
            sort: false,
            value: (row, searchValue) => {
                const from_date = getFormattedDate({
                    value: row.from_date,
                    type: DATE_SETTINGS.shortFormat,
                    fallback: DEFAULT_EMPTY_CELL_VALUE
                });
                const to_date = getFormattedDate({
                    value: row.to_date,
                    type: DATE_SETTINGS.shortFormat,
                    fallback: DEFAULT_EMPTY_CELL_VALUE
                });
                const value = `Forecast: ${extractDate(from_date)} - ${extractDate(to_date)}`;

                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            }
        },
        {
            id: 'forecast_type',
            label: t('tableHead.type'),
            sort: false,
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.forecast_for!} searchValue={searchValue} />;
            }
        },
        {
            id: 'from',
            label: t('tableHead.from'),
            sort: false,
            value: (row, searchValue) => {
                const value = getFormattedDate({
                    value: row.from_date,
                    type: DATE_SETTINGS.shortFormat,
                    fallback: DEFAULT_EMPTY_CELL_VALUE
                });
                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            }
        },
        {
            id: 'to',
            label: t('tableHead.to'),
            sort: false,
            value: (row, searchValue) => {
                const value = getFormattedDate({
                    value: row.to_date,
                    type: DATE_SETTINGS.shortFormat,
                    fallback: DEFAULT_EMPTY_CELL_VALUE
                });
                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            }
        },
        {
            id: 'download',
            label: t('tableHead.download'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const filename = `Ogre_Forecast_${row.forecast_for}__${row.from_date}__${row.to_date}`;
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {row.file_type === DownloadExtension.Pdf && (
                            <DownloadButton
                                type={DownloadExtension.Pdf}
                                id={row.id}
                                value="gas-forecast"
                                projectId={projectId}
                                row={row}
                                filename={filename}
                            />
                        )}
                        {row.file_type === DownloadExtension.Excel && (
                            <DownloadButton
                                type={DownloadExtension.Excel}
                                id={row.id}
                                value="gas-forecast"
                                projectId={projectId}
                                row={row}
                                filename={filename}
                            />
                        )}
                        {row.file_type === DownloadExtension.Csv && (
                            <DownloadButton
                                type={DownloadExtension.Csv}
                                id={row.id}
                                value="gas-forecast"
                                projectId={projectId}
                                row={row}
                                filename={filename}
                            />
                        )}
                    </Box>
                );
            }
        }
    ];
};
