import { TFunction } from 'i18next';
import { Box } from '@mui/material';
import { DATE_SETTINGS } from '../../../utils/config';
import { TableCellRenderer } from '../../../interfaces/general';
import { DefaultSearchableCell } from '../../../components/BaseTable';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import AssetStatusLabel from '../../../components/AssetStatusLabel/AssetStatusLabel';
import { DownloadButton, DownloadExtension } from '../../../components/DownloadButton';
import { getFormattedDate } from '../../../utils/helpers';

export const getTableHeadCells = (tList: TFunction, projectId: string): TableCellRenderer<any>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.report'),
            sort: true,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.name;
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;

                return <DefaultSearchableCell value={value} searchValue={searchValue} isLink linkUrl={callback?.()} />;
            },
            sort_id: 'report.date'
        },
        {
            id: 'from',
            label: tList('tableHead.from'),
            sort: false,
            value: (row, searchValue) => {
                const date = getFormattedDate({
                    value: row.from_timestamp,
                    type: DATE_SETTINGS.shortFormatHoursIncluded
                });
                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'to',
            label: tList('tableHead.to'),
            sort: false,
            value: (row, searchValue) => {
                const date = getFormattedDate({
                    value: row.to_timestamp,
                    type: DATE_SETTINGS.shortFormatHoursIncluded
                });

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'status',
            label: tList('tableHead.status'),
            sort: false,
            value: (row) => {
                return <AssetStatusLabel status={row.status} />;
            }
        },
        {
            id: 'download',
            label: tList('tableHead.download'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const filename = `Ogre_Report_${row.name}`;
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <DownloadButton
                            disabled={row.status !== 'Completed'}
                            type={DownloadExtension.Excel}
                            id={row.id}
                            value="gas-report"
                            projectId={projectId}
                            row={row}
                            filename={filename}
                        />
                    </Box>
                );
            }
        }
    ];
};
