import { isEqual } from 'lodash';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import {
    getEVSChargingPointAsync,
    getEVSChargingPointForecastAsync,
    getEVSChargingPointHistoricalAsync,
    getEVSForecastSettingsAsync,
    loadMoreEVSChargingPointData,
    resetEVSChargingPointPage,
    setGetEVSChargingPointParams
} from '../../../stores/EVS';
import { UNIT } from '../../../interfaces/uiv2';
import { TAB } from '../../../interfaces/general';
import { PageContainer } from '../../../layout/PageContainer';
import { TradingView } from '../../../components/TradingView';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ReactComponent as ViewList } from '../../../assets/icons/view_list.svg';
import { ReactComponent as GraphIcon } from '../../../assets/icons/timeline.svg';
import { MemoizedGenericTable } from '../../../components/GenericTableWrapper/GenericTableWrapper';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../../components/EntityPageToolbar';
import { ReactComponent as ChargingPointSVG } from '../../../assets/icons/dashboard/ev_point.svg';
import { getTableHeadCells, renderFooterContent } from '../../../components/GenerationTable/helpers';

import common from '../../../common.module.scss';
import style from '../../../features/asset-page/asset-page.module.scss';
import dayjs from 'dayjs';
import { TableActionsEntityContainer } from '../../../components/TableActionsEntityContainer';
import { DownloadExtension } from '../../../components/DownloadButton';
import AssetStatusLabel from '../../../components/AssetStatusLabel/AssetStatusLabel';
import { CircleProgressBar } from '../../../components/BaseTable/CustomCells/CircleProgressBar';

const ChargingPointsPage = () => {
    const query = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { projectId = '', stationId = '' } = useParams();

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;
    const [dates, setDates] = useState({ from: 0, to: 0 });

    const {
        pager,
        chargingPoint,
        forecastSettings,
        chartData,
        paginatedData,
        success,
        loading: loadingForecast,
        error: errorForecast
    } = useAppSelector((state) => state.evsChargingPointPage);
    const getParams = useAppSelector((state) => state.evsChargingPointPage.getParams, isEqual);

    useEffect(() => {
        if (query?.id && !chargingPoint) {
            dispatch(getEVSChargingPointAsync({ id: query.id, projectId, stationId }));
        }
    }, [query, chargingPoint]);

    useEffect(() => {
        if (chargingPoint) {
            dispatch(getEVSForecastSettingsAsync({ chargingPoint: chargingPoint.id, projectId }));
        }
    }, [chargingPoint]);

    useEffect(() => {
        if (forecastSettings?.length && chargingPoint) {
            const firstSetting: any = forecastSettings[0]; // TODO
            const firstNovember = dayjs('2023-11-01'); // TODO

            const from = firstNovember.valueOf();
            const to = dayjs().add(10, 'days').utcOffset(0).endOf('date').valueOf();

            setDates({
                from,
                to
            });

            dispatch(
                getEVSChargingPointForecastAsync({
                    chargingPoint: chargingPoint.id,
                    projectId,
                    forecastSettingId: firstSetting.id,
                    from,
                    to
                })
            );
            dispatch(
                getEVSChargingPointHistoricalAsync({
                    chargingPoint: chargingPoint.id,
                    projectId,
                    forecastSettingId: firstSetting.id,
                    from,
                    to
                })
            );
        }
    }, [forecastSettings]);

    useEffect(() => {
        return () => {
            dispatch(resetEVSChargingPointPage());
        };
    }, []);

    useEffect(() => {
        if (tab === 'table' && pager?.page !== getParams.page) {
            dispatch(loadMoreEVSChargingPointData());
        }
    }, [getParams, tab, pager]);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    if (!chargingPoint) {
        return null;
    }

    const headCells = getTableHeadCells({ t, assetMeasureUnit: UNIT.LOW_ENERGY });

    return (
        <PageContainer className={[common.pageContainer]}>
            <Box className={style.headerContainer}>
                <Box className={classnames(style.header)}>
                    <PageHeader
                        title={t('title.chargingPoints')}
                        className={classnames(style.align)}
                        id={chargingPoint.id}
                        icon={<ChargingPointSVG />}
                        subtitle={chargingPoint.name}
                    />

                    <Box className={style.subtitle} sx={{ ml: 2 }}>
                        <Box className={style.dot} />
                        <Typography variant="small1"> {t(pageTitle)}</Typography>
                    </Box>
                </Box>

                <Box className={style.toolbarContainer}>
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>

                        <TableActionsEntityContainer
                            entityType="evs"
                            id={chargingPoint.id}
                            params={{
                                chargingPoint,
                                charging_point_id: chargingPoint.id,
                                from_datetime: dates.from,
                                to_datetime: dates.to,
                                project_id: projectId,
                                forecast_settings_id: forecastSettings?.[0]?.id
                            }}
                            className={style.actionsWrapper}
                            remove={false}
                            defaultActions={tab === 'table' ? [DownloadExtension.Csv, DownloadExtension.Excel] : []}
                        />
                    </EntityPageToolbar>
                </Box>
            </Box>

            <Box className={common.subHeaderContainer}>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.type')}</Typography>
                    <Typography variant="small3" className={classnames(common.ml, common.flex)}>
                        <ChargingPointSVG />
                    </Typography>
                    <Typography variant="small3" className={common.ml}>
                        {'Charging Point'}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">Connector ID</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {chargingPoint.external_id}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">Power (kWh)</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {chargingPoint.power}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">Connector type</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {chargingPoint.connector_type}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">Electricity type</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {chargingPoint.electricity_type}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">Status</Typography>
                    <Box sx={{ ml: 1.5 }}>
                        <AssetStatusLabel status={chargingPoint.status} variant="small3" />
                    </Box>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">Connectivity ratio</Typography>
                    <Box sx={{ ml: 1.5 }}>
                        <CircleProgressBar value={+(chargingPoint.connectivity_ratio || 0) * 100} />
                    </Box>
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={chartData as any}
                    error={false}
                    fetchMoreFn={() => {}}
                    loading={loadingForecast}
                    legendOpts={{
                        forecasted: true,
                        measured: true
                    }}
                    assetMeasureUnit={UNIT.LOW_ENERGY}
                />
            )}
            {tab === 'table' && (
                <MemoizedGenericTable
                    renderFooterContent={renderFooterContent}
                    headCells={headCells}
                    pager={pager}
                    success={success}
                    data={paginatedData}
                    error={errorForecast}
                    loading={loadingForecast}
                    setGetParams={setGetEVSChargingPointParams}
                    fetchMoreFn={() => {}}
                />
            )}
        </PageContainer>
    );
};

export default ChargingPointsPage;
