import { DialogContent } from '@mui/material';

import { MODAL_IDS } from '..';
import { BaseModal } from '../../components/BaseModal';
import { MemoizedCreateGasProfileForm } from './CreateGasProfileForm';
import { CloseModalButton } from '../../components/BaseModal/CloseModalButton';

const CreateGasProfileModal = () => {
    return (
        <BaseModal maxWidth="sm" fullWidth={false} closeOnBackdropClick id={MODAL_IDS.ADD_GAS_PROFILE_MODAL}>
            <CloseModalButton id={MODAL_IDS.ADD_GAS_PROFILE_MODAL} top={28} />
            <DialogContent sx={{ p: 4, pb: '20px' }}>
                <MemoizedCreateGasProfileForm />
            </DialogContent>
        </BaseModal>
    );
};

export { CreateGasProfileModal };
