import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Autocomplete, Box, InputLabel, TextField } from '@mui/material';

import Loading from '../../../layout/Loading';

import { AutocompletesAction, AutocompletesState } from './interfaces';

import style from '../style.module.scss';
import { useGetDeliveryIntervalsQuery } from '../../../stores/GenerationDeliverySettings';
import { ForecastDeliveryIntervalV2 } from '../../../interfaces/apiv2';
import classNames from 'classnames';

export const GenerationReportDeliveryIntervals = ({
    state,
    dispatch
}: {
    state: AutocompletesState;
    dispatch: Dispatch<AutocompletesAction>;
}) => {
    const { t } = useTranslation();

    const { data: deliveryIntervals, isLoading } = useGetDeliveryIntervalsQuery(
        {
            deliverySettingId: state?.deliverySetting?.id
        },
        { skip: !state.deliverySetting }
    );

    const handleChange = () => {
        dispatch({ type: 'SET_DELIVERY_INTERVAL', payload: null });
        dispatch({ type: 'SET_OPEN', payload: { ...state.open, deliveryInterval: false } });
    };

    const handleIdChange = (e: ForecastDeliveryIntervalV2) => () => {
        dispatch({ type: 'SET_DELIVERY_INTERVAL', payload: e });
        dispatch({ type: 'SET_OPEN', payload: { ...state.open, deliveryInterval: false } });
    };

    return (
        <Box className={classNames(style.formRow, style.fullWidth)}>
            <InputLabel variant="standard" required shrink htmlFor={t('reportForm.deliveryInterval')}>
                {t('reportForm.deliveryInterval')}
            </InputLabel>
            <Autocomplete
                id="asynchronous-delivery-intervals"
                value={state.deliveryInterval}
                options={deliveryIntervals || []}
                className={state.open.deliveryInterval ? style.autocompleteOpen : ''}
                loading={isLoading}
                onChange={handleChange}
                noOptionsText={t('select.noOptions')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                popupIcon={isLoading ? <Loading className={style.loading} /> : <MdKeyboardArrowDown color="#6A9BFF" />}
                renderOption={(props: any, e: ForecastDeliveryIntervalV2) => (
                    <Box component="li" {...props} key={Math.random()} onClick={handleIdChange(e)}>
                        {e.name}
                    </Box>
                )}
                ListboxProps={{ style: { maxHeight: '180px' } }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: t('reportForm.deliveryInterval')
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <Loading /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
                open={state.open.deliveryInterval}
                onOpen={() => {
                    dispatch({ type: 'SET_OPEN', payload: { ...state.open, deliveryInterval: true } });
                }}
                onClose={() => {
                    dispatch({ type: 'SET_OPEN', payload: { ...state.open, deliveryInterval: false } });
                }}
                clearOnEscape={true}
                clearOnBlur={true}
                filterOptions={(x) => x}
            />
        </Box>
    );
};
