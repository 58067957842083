import { isEqual } from 'lodash';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
    loadMore,
    setGetParams,
    getForecastAsync,
    resetForecastPage,
    getForecastDataAsync
} from '../../../../stores/EVS/forecastPage';
import { TAB } from '../../../../interfaces/general';
import { getFormattedDate } from '../../../../utils/helpers';
import { getAssetIcon } from '../../../../utils/getAssetIcon';
import { PageContainer } from '../../../../layout/PageContainer';
import { TradingView } from '../../../../components/TradingView';
import { useAppDispatch, useAppSelector } from '../../../../stores';
import { PageHeader } from '../../../../components/PageHeader/PageHeader';
import { DownloadExtension } from '../../../../components/DownloadButton';
import { DATE_SETTINGS } from '../../../../utils/config';
import { ReactComponent as ViewList } from '../../../../assets/icons/view_list.svg';
import { ReactComponent as GraphIcon } from '../../../../assets/icons/timeline.svg';
import { TableActionsEntityContainer } from '../../../../components/TableActionsEntityContainer';
import { MemoizedGenericTable } from '../../../../components/GenericTableWrapper/GenericTableWrapper';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../../../components/EntityPageToolbar';
import { getDemandTableHeadCells, renderDemandFooterContent } from '../../../../components/GenerationTable/helpers';
import { ReactComponent as ForecastsSVG } from '../../../../assets/icons/navigation-items/dots-menu-forecasts.svg';

import common from './../../../../common.module.scss';
import style from './ForecastPage.module.scss';
import { UNIT } from '../../../../interfaces/uiv2';

const EVSForecastPage = () => {
    const query = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    // const pageTitle = `assetPageTitle.${tab}`;

    const {
        forecast,
        pager,
        paginatedData,
        chartData,
        loading: loadingForecast,
        error: errorForecast,
        success
    } = useAppSelector((state) => state.evsForecastPage);
    const getParams = useAppSelector((state) => state.forecastPage.getParams, isEqual);

    useEffect(() => {
        return () => {
            dispatch(resetForecastPage());
        };
    }, []);

    useEffect(() => {
        if (query?.forecastId && !forecast) {
            dispatch(getForecastAsync({ id: query.forecastId }));
        }
    }, [query, forecast]);

    useEffect(() => {
        if (forecast) {
            dispatch(getForecastDataAsync({ forecast }));
        }
    }, [forecast]);

    useEffect(() => {
        if (tab === 'table' && pager?.page !== getParams.page) {
            dispatch(loadMore());
        }
    }, [getParams, tab, pager]);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    if (!forecast) {
        return null;
    }

    const headCells = getDemandTableHeadCells({ t, assetMeasureUnit: UNIT.ENERGY });

    return (
        <PageContainer className={[common.pageContainer]}>
            {/* <Box className={common.wrapper}> */}
            <Box className={style.headerContainer}>
                <Box className={classnames(style.header)}>
                    <PageHeader
                        title={t('header.evs_forecasts')}
                        className={classnames(style.primary, style.align)}
                        id={forecast.id}
                        icon={<ForecastsSVG />}
                        subtitle={forecast.delivery_group?.name}
                    />
                </Box>

                <Box className={style.toolbarContainer}>
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>
                    </EntityPageToolbar>
                    <TableActionsEntityContainer
                        entityType="forecast"
                        id={forecast.id}
                        className={style.actionsWrapper}
                        defaultActions={[DownloadExtension.Csv, DownloadExtension.Excel, DownloadExtension.Pdf]}
                    />
                </Box>
            </Box>

            <Box className={common.subHeaderContainer}>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.type')}</Typography>
                    <Box className={common.assetTypeBox}>
                        {getAssetIcon('ChargingPoint')}
                        <Typography variant="small3">Charging Point</Typography>
                    </Box>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.from')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {getFormattedDate({
                            value: forecast.from_date,
                            type: DATE_SETTINGS.shortV2FormatHoursIncluded,
                            timezone: forecast.forecast_settings?.asset?.timezone
                        })}
                    </Typography>
                    <Typography variant="small4" className={common.ml}>
                        {t('tableHead.to')}
                    </Typography>
                    <Typography variant="small3" className={common.ml}>
                        {getFormattedDate({
                            value: forecast.to_date,
                            type: DATE_SETTINGS.shortV2FormatHoursIncluded,
                            timezone: forecast.forecast_settings?.asset?.timezone
                        })}
                    </Typography>
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={chartData}
                    error={errorForecast}
                    fetchMoreFn={() => {}}
                    loading={loadingForecast}
                    legendOpts={{
                        forecasted: true,
                        measured: false
                    }}
                    assetMeasureUnit={UNIT.ENERGY}
                />
            )}
            {tab === 'table' && (
                <MemoizedGenericTable
                    renderFooterContent={renderDemandFooterContent}
                    headCells={headCells}
                    pager={pager}
                    data={paginatedData}
                    error={errorForecast}
                    loading={loadingForecast}
                    success={success}
                    setGetParams={setGetParams}
                />
            )}
            {/* </Box> */}
        </PageContainer>
    );
};

export default EVSForecastPage;
