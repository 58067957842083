import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { GetParams } from '../../interfaces/general';
import ToastifyType from '../../utils/toastify-config';
import { transformGasReportsAPItoFE } from './helpers';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { getQueryParamsFromObject } from '../../utils/table';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { ApiPaginationInfo, PaginatedApiResponse } from '../../interfaces/apiv2';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';

interface ListGasReportsState extends DefaultInitialState {
    reports: any[];
    reportsFE: any[];
    searchParam: string | null;
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
    selectedRows: any[];
    loadingDownload: boolean;
    activeDownloadMeta: { ids: string[]; type: string } | null;
}

const initialState: ListGasReportsState = {
    reports: [],
    reportsFE: [],
    loading: false,
    error: false,
    success: false,
    pager: null,
    searchParam: null,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    selectedRows: [],

    loadingDownload: false,
    activeDownloadMeta: null
};

export const getGasReportsListAsync = createAsyncThunk<
    PaginatedApiResponse<any>,
    GetParams & { projectId: string },
    { state: RootState }
>('gas/reports/list', async (values: GetParams & { projectId: string }, { rejectWithValue }) => {
    try {
        const queryParams = getQueryParamsFromObject({ ...values });
        const endpoint = `/gas/demand/${values.projectId}/consumers/reporting/list`;

        const response = await axios.get<PaginatedApiResponse<any>>(`${endpoint}${queryParams}`);

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const listGasReports = createSlice({
    name: 'listGasReports',
    initialState,
    reducers: {
        resetListGasReportsState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<any>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<any[]>) => {
            state.selectedRows = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getGasReportsListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getGasReportsListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.reports = items;
            } else {
                state.reports = [...current(state).reports, ...items];
            }
            state.reportsFE = transformGasReportsAPItoFE(current(state).reports);
        });
        builder.addCase(getGasReportsListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        // builder.addCase(downloadReport.pending, (state, { meta }) => {
        //     state.loadingDownload = true;
        //     state.activeDownloadMeta = meta.arg;
        // });
        // builder.addCase(downloadReport.fulfilled, (state) => {
        //     state.loadingDownload = false;
        //     state.activeDownloadMeta = null;
        // });
        // builder.addCase(downloadReport.rejected, (state) => {
        //     state.loadingDownload = false;
        //     state.activeDownloadMeta = null;
        // });
    }
});

export const { resetListGasReportsState, setGetParams, setSelectedRows } = listGasReports.actions;

export default listGasReports.reducer;
