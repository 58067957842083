import { DialogContent } from '@mui/material';

import { MODAL_IDS } from '..';
import { BaseModal } from '../../components/BaseModal';
import { MemoizedCreateReport } from './CreateReport';
import { CloseModalButton } from '../../components/BaseModal/CloseModalButton';

const CreateReportModal = () => {
    return (
        <BaseModal maxWidth="sm" fullWidth={false} closeOnBackdropClick id={MODAL_IDS.CREATE_REPORT_MODAL}>
            <CloseModalButton id={MODAL_IDS.CREATE_REPORT_MODAL} top={28} />
            <DialogContent sx={{ p: 4, pb: '20px' }}>
                <MemoizedCreateReport />
            </DialogContent>
        </BaseModal>
    );
};

export { CreateReportModal };
