import axios from '../../utils/axios';
import { DefaultInitialState } from '../../interfaces/redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ToastifyType from '../../utils/toastify-config';
import i18next from '../../utils/i18next';
import { PostTrainAssetBody } from '../../interfaces/uiv2';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const trainAssetAsync = createAsyncThunk(
    'assets/trainAssetAsync',
    async (values: PostTrainAssetBody, { rejectWithValue }) => {
        try {
            const response = await axios.post(`/electricity/generation/assets/${values.id}/train`, {
                start_date: values.start_date,
                end_date: values.end_date,
                forecast_settings_id: values.forecast_settings_id
            });

            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
    }
);

export const trainAsset = createSlice({
    name: 'trainAsset',
    initialState,
    reducers: {
        resetTrainAssetState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(trainAssetAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(trainAssetAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('trainingStarted'), ToastifyType.success);
        });
        builder.addCase(trainAssetAsync.rejected, (state, error: any) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(`${i18next.t('trainingFailed')}: ${error.payload.detail}`, ToastifyType.error);
        });
    }
});

export const { resetTrainAssetState } = trainAsset.actions;

export default trainAsset.reducer;
