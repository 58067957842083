import { DialogContent } from '@mui/material';

import { MODAL_IDS } from '..';
import AssetLocationsForm from './AssetLocationsForm';
import Cleanup from '../../components/Cleanup/Cleanup';
import { useAppDispatch, useAppSelector } from '../../stores';
import { FormModal } from '../../components/BaseModal/FormModal';
import { resetCreateAssetLocationsState } from '../../stores/Assets';
import { FormErrorStep, FormSuccessStep } from '../../components/FormUtils';

const AddAssetLocationsModal = ({ assetId }: { assetId: string }) => {
    const dispatch = useAppDispatch();
    const { success, error } = useAppSelector((state) => state.createAssetLocations);

    const handleCleanup = () => {
        // use reset create project state
        dispatch(resetCreateAssetLocationsState());
    };

    return (
        <FormModal id={MODAL_IDS.ADD_LOCATION_COORDINATES} fullWidth={false} maxWidth="sm">
            <DialogContent>
                {!(success || error) ? <AssetLocationsForm assetId={assetId} /> : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
                {/* needed because create asset modal never unmounts but what is inside of dialog content yes */}
                <Cleanup cb={handleCleanup} />
            </DialogContent>
        </FormModal>
    );
};

export { AddAssetLocationsModal };
