import { transformPermissionsBEtoFE } from './user';
import { User, UserPermissions } from '../interfaces/apiv2';
import AdminSvg from '../assets/icons/permissions/admin.svg';
import ElectricitySvg from '../assets/icons/permissions/electricity.svg';
import GasSvg from '../assets/icons/permissions/gas.svg';

export const getModuleImage = (module: string) => {
    switch (module) {
        case 'Electricity': {
            return ElectricitySvg;
        }
        case 'Admin': {
            return AdminSvg;
        }
        case 'Gas': {
            return GasSvg;
        }
        default: {
            return '';
        }
    }
};

export const getActiveModulesFromPermissions = (permissions: UserPermissions, user?: User) => {
    let activatedModules: string[];

    const permissionsFE = transformPermissionsBEtoFE(permissions);

    const moduleKeys = Object.keys(permissionsFE);
    type ModuleKeys = keyof typeof permissionsFE;

    activatedModules = moduleKeys.filter((module) => Object.values(permissionsFE[module as ModuleKeys]).some((i) => i));

    if (user?.superadmin) {
        activatedModules = moduleKeys;
    }

    return activatedModules;
};
