import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { ForecastDeliveryInterval, ForecastType, PostForecastDeliveryInterval } from '../../interfaces/apiv2';

interface ForecastDeliveryIntervalsState extends DefaultInitialState {
    forecastDeliveryIntervalsSettings: {
        [ForecastType.Intraday]: ForecastDeliveryInterval | null;
        [ForecastType.DayAhead]: ForecastDeliveryInterval | null;
        [ForecastType.LongTerm]: ForecastDeliveryInterval | null;
    };
}

const initialState: ForecastDeliveryIntervalsState = {
    loading: false,
    error: false,
    success: false,
    forecastDeliveryIntervalsSettings: {
        [ForecastType.Intraday]: null,
        [ForecastType.DayAhead]: null,
        [ForecastType.LongTerm]: null
    }
};

export const getForecastDeliveryIntervals = createAsyncThunk(
    'forecastDeliveryIntervals/list',
    async ({ asset_id, forecast_type }: { asset_id: string; forecast_type: ForecastType }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ForecastDeliveryInterval>(
                `/electricity/generation/assets/${asset_id}/forecast-delivery-intervals`,
                {
                    params: {
                        forecast_type
                    }
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const createForecastDeliveryIntervalsAsync = createAsyncThunk<
    ForecastDeliveryInterval,
    { assetId: string; body: PostForecastDeliveryInterval }
>('forecastDeliveryIntervals/create', async ({ assetId, body }, { rejectWithValue }) => {
    try {
        const response = await axios.post<ForecastDeliveryInterval>(
            `/electricity/generation/assets/${assetId}/forecast-delivery-intervals?forecast_type=${body.forecast_type}`,
            body
        );

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const updateForecastDeliveryIntervalsAsync = createAsyncThunk<
    ForecastDeliveryInterval,
    { assetId: string; body: PostForecastDeliveryInterval }
>('forecastDeliveryIntervals/update', async ({ assetId, body }, { rejectWithValue }) => {
    try {
        const response = await axios.patch<ForecastDeliveryInterval>(
            `/electricity/generation/assets/${assetId}/forecast-delivery-intervals?forecast_type=${body.forecast_type}`,
            body
        );

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const forecastDeliveryIntervals = createSlice({
    name: 'forecastDeliveryIntervals',
    initialState,
    reducers: {
        resetForecastDeliveryIntervalsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getForecastDeliveryIntervals.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getForecastDeliveryIntervals.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.forecastDeliveryIntervalsSettings[payload.forecast_type] = payload;
        });
        builder.addCase(getForecastDeliveryIntervals.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });

        builder.addCase(createForecastDeliveryIntervalsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(createForecastDeliveryIntervalsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.forecastDeliveryIntervalsSettings[payload.forecast_type] = payload;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(createForecastDeliveryIntervalsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(updateForecastDeliveryIntervalsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(updateForecastDeliveryIntervalsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.forecastDeliveryIntervalsSettings[payload.forecast_type] = payload;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(updateForecastDeliveryIntervalsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetForecastDeliveryIntervalsState } = forecastDeliveryIntervals.actions;

export default forecastDeliveryIntervals.reducer;
