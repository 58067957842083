import login from './Login/Login';
import changePassword from './ChangePassword/ChangePassword';
import resetPassword from './ResetPassword/ResetPassword';

export * from './ChangePassword/ChangePassword';
export * from './Login/Login';
export * from './ResetPassword/ResetPassword';

export default {
    login,
    changePassword,
    resetPassword
};
