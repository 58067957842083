import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';
import i18next from '../../../utils/i18next';
import ToastifyType from '../../../utils/toastify-config';
import { DefaultInitialState } from '../../../interfaces/redux';
import { commonReduxErrorHandler } from '../../../utils/error-handling';
import { AssetLocation } from '../../../interfaces/apiv2';
import { PostAssetLocationsBody } from '../../../interfaces/uiv2';

interface createAssetLocationsState extends DefaultInitialState {
    created: number;
}

const initialState: createAssetLocationsState = {
    loading: false,
    error: false,
    success: false,
    created: 0
};

export const createAssetLocationsAsync = createAsyncThunk(
    'assets/create-locations',
    async (body: PostAssetLocationsBody, { rejectWithValue }) => {
        try {
            const endpoint = `/electricity/generation/assets/${body.assetId}/locations`;

            const response = await axios.post<AssetLocation>(endpoint, body);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const createAssetLocations = createSlice({
    name: 'createAssetLocations',
    initialState,
    reducers: {
        resetCreateAssetLocationsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createAssetLocationsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(createAssetLocationsAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.created += 1;
        });
        builder.addCase(createAssetLocationsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetCreateAssetLocationsState } = createAssetLocations.actions;

export default createAssetLocations.reducer;
