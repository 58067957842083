import i18next from 'i18next';
import { toast } from 'react-toastify';
import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../../../utils/axios';
import ToastifyType from '../../../../utils/toastify-config';
import { ApiPaginationInfo, GasCluster, GasForecast } from '../../../../interfaces/apiv2';
import { PAGINATION_SETTINGS } from '../../../../utils/config';
import {
    FORECAST_TYPE,
    ForecastDataGetParams,
    GasForecastFE,
    GenerationTableForecastDataPayloadSerialized
} from '../../../../interfaces/uiv2';
import { getDemandChartThunk } from '../../../../utils/common-thunks';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { DefaultInitialState, DEFAULT_GET_PARAMS_VALUE } from '../../../../interfaces/redux';
import { sortForecastData, transformForecastToView } from '../../../../utils/forecast';
import { DownloadExtension } from '../../../../components/DownloadButton';
import { getQueryParamsFromObject } from '../../../../utils/table';
import fileDownload from 'js-file-download';
import { transformEntityToAPItoFE, transformGasForecastToFE } from '../../helpers';

interface GasClusterPage extends DefaultInitialState {
    cluster: GasCluster | null;
    clusterFE: GasCluster | null;
    download: boolean;
    forecasts: LineData[][];
    generationForecasts: GasForecastFE[];
    paginatedData: GasForecastFE[];
    errorForecast: boolean;
    loadingForecast: boolean;
    successForecast: boolean;
    errorMeasured: boolean;
    loadingDownload: boolean;
    loadingMeasured: boolean;
    successMeasured: boolean;
    limitLeftForecastBoundary: boolean;
    limitLeftMeasuredBoundary: boolean;
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
    generationTablePayloadTemp: GenerationTableForecastDataPayloadSerialized | null;
}

export const getGasClusterAsync = createAsyncThunk<GasCluster, { id: string; projectId: string }>(
    'gas_cluster_page/show',
    async (values: { id: string; projectId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<GasCluster>(`/gas/demand/${values.projectId}/clusters/${values.id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const downloadGasClusterData = createAsyncThunk(
    'GasClusterPage/download',
    async (
        {
            cluster,
            projectId,
            ...rest
        }: {
            cluster: GasCluster;
            projectId: string;
            from_datetime: string;
            to_datetime: string;
            file_type: DownloadExtension;
        },
        { rejectWithValue }
    ) => {
        toast.success(i18next.t('downloadStarted'), ToastifyType.success);
        try {
            const url = `/gas/demand/${projectId}/clusters/${cluster.id}/forecast/download${getQueryParamsFromObject({
                ...rest
            })}`;
            const response = await axios.get(url, {
                responseType: 'blob'
            });

            const extension = rest.file_type === DownloadExtension.Excel ? 'xlsx' : rest.file_type.toLowerCase();

            fileDownload(
                response.data,
                `${cluster.name}__${new Date(Number(rest.from_datetime)).toISOString()}__${new Date(
                    Number(rest.to_datetime)
                ).toISOString()}.${extension}`
            );
            toast.success(i18next.t('downloadSuccess'), ToastifyType.success);
        } catch (e) {
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getGasClusterForecastAsync = getDemandChartThunk({
    thunkName: 'gas_cluster/forecast',
    endpoint: 'forecast',
    module: 'gas',
    submodule: 'clusters'
});

export const getGasClusterGenerationForecastAsync = getDemandChartThunk({
    thunkName: 'gas_cluster/generation_forecast',
    endpoint: 'forecast',
    module: 'gas',
    submodule: 'clusters'
});

export const getGasClusterMeasuredAsync = getDemandChartThunk({
    thunkName: 'gas_cluster/measured',
    endpoint: 'historical',
    module: 'gas',
    submodule: 'clusters'
});

export const getGasClusterMoreForecastAsync = getDemandChartThunk({
    thunkName: 'gas_cluster/forecast_more',
    endpoint: 'forecast',
    module: 'gas',
    submodule: 'clusters'
});

export const getGasClusterMoreMeasuredAsync = getDemandChartThunk({
    thunkName: 'gas_cluster/measured_more',
    endpoint: 'historical',
    module: 'gas',
    submodule: 'clusters'
});

const initialState: GasClusterPage = {
    loading: false,
    error: false,
    success: false,
    cluster: null,
    clusterFE: null,
    download: false,
    forecasts: [[], []],
    generationForecasts: [],
    errorForecast: false,
    loadingForecast: false,
    successForecast: false,
    loadingDownload: false,
    errorMeasured: false,
    loadingMeasured: false,
    successMeasured: false,
    limitLeftForecastBoundary: false,
    limitLeftMeasuredBoundary: false,
    pager: null,
    paginatedData: [],
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        // order_by: ['-created_on']
        order_by: undefined
    },
    generationTablePayloadTemp: null
};

export const gasClusterPage = createSlice({
    name: 'gasClusterPage',
    initialState,
    reducers: {
        resetGasClusterPage: () => initialState,
        setActiveAsset: (state, { payload }: PayloadAction<GasCluster | null>) => {
            state.cluster = payload;
        },
        downloadChart: (state, { payload }) => {
            state.download = payload;
        },
        resetForecastData: (state) => {
            state.forecasts = [[], []];
            state.errorForecast = false;
            state.loadingForecast = false;
            state.successForecast = false;
            state.errorMeasured = false;
            state.loadingMeasured = false;
            state.successMeasured = false;
            state.limitLeftForecastBoundary = false;
            state.limitLeftMeasuredBoundary = false;
        },
        loadMore: (state) => {
            const { generationForecasts, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: generationForecasts.length
            };
            state.paginatedData = generationForecasts.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        },
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setGenerationTablePayloadTemp: (state, { payload }) => {
            state.generationTablePayloadTemp = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(downloadGasClusterData.pending, (state) => {
            state.loadingDownload = true;
        });
        builder.addCase(downloadGasClusterData.fulfilled, (state) => {
            state.loadingDownload = false;
        });
        builder.addCase(downloadGasClusterData.rejected, (state) => {
            state.loadingDownload = false;
        });
        builder.addCase(getGasClusterAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getGasClusterAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.cluster = payload;
            state.clusterFE = transformEntityToAPItoFE([payload])[0];
        });
        builder.addCase(getGasClusterAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getGasClusterForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getGasClusterForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            const actualData = (current(state).forecasts || [])[0] || [];

            state.forecasts[0] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.GAS, state.cluster?.country),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftForecastBoundary = true;
            }
        });
        builder.addCase(getGasClusterForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
        builder.addCase(getGasClusterGenerationForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getGasClusterGenerationForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            state.generationForecasts = transformGasForecastToFE(payload as unknown as GasForecast[]);

            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).generationForecasts.length
            };
            //state.paginatedData = current(state).generationForecasts.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
        });
        builder.addCase(getGasClusterGenerationForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
        builder.addCase(getGasClusterMeasuredAsync.pending, (state) => {
            state.loadingMeasured = true;
            state.errorMeasured = false;
            state.successMeasured = false;
        });
        builder.addCase(getGasClusterMeasuredAsync.fulfilled, (state, { payload }) => {
            state.loadingMeasured = false;
            state.errorMeasured = false;
            state.successMeasured = true;
            const actualData = (current(state).forecasts || [])[1] || [];
            state.forecasts[1] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.GAS, state.cluster?.country),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftMeasuredBoundary = true;
            }
        });
        builder.addCase(getGasClusterMeasuredAsync.rejected, (state) => {
            state.loadingMeasured = false;
            state.errorMeasured = true;
            state.successMeasured = false;
        });

        builder.addCase(getGasClusterMoreForecastAsync.fulfilled, (state, { payload }) => {
            const actualData = (current(state).forecasts || [])[0] || [];

            state.forecasts[0] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.GAS),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftForecastBoundary = true;
            }
        });

        builder.addCase(getGasClusterMoreMeasuredAsync.fulfilled, (state, { payload }) => {
            const actualData = (current(state).forecasts || [])[1] || [];
            state.forecasts[1] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.GAS),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftMeasuredBoundary = true;
            }
        });
    }
});

export const {
    resetGasClusterPage,
    setGenerationTablePayloadTemp: setGasClusterPageGenerationTablePayloadTemp,
    resetForecastData: resetGasClusterForecastData,
    loadMore: loadMoreGasClusterData,
    setGetParams: setGetParamsGasClusterPage
} = gasClusterPage.actions;

export default gasClusterPage.reducer;
