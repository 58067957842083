import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo } from '../../interfaces/apiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { transformForecastToTable, transformForecastToView } from '../../utils/forecast';
import { staticTLConsumers, TLConsumer } from './mock-data';
import { FORECAST_TYPE, ForecastDataGetParams, ForecastFE } from '../../interfaces/uiv2';
import { mockRequest } from '../../utils/helpers';

interface ITLConsumerPage extends DefaultInitialState {
    consumer: TLConsumer | null;
    data: ForecastFE[];
    paginatedData: ForecastFE[];
    chartData: LineData[][];
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
}

const initialState: ITLConsumerPage = {
    consumer: null,
    data: [],
    paginatedData: [],
    chartData: [[], []],
    loading: false,
    error: false,
    success: false,
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        // order_by: ['-created_on']
        order_by: undefined
    },
    pager: null
};

export const getTLConsumerAsync = createAsyncThunk(
    'consumer/show',
    async (values: { id: string }, { rejectWithValue }) => {
        try {
            /*const response = await axios.get<TLConsumer>(`/consumer/${values.id}`);

        return response.data;*/
            const consumer = staticTLConsumers.find((e) => e.id === values.id);
            const data = await mockRequest(250, consumer);
            return data as TLConsumer;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getTLConsumerDataAsync = createAsyncThunk(
    'consumer_data/get',
    async ({ consumer }: { consumer: TLConsumer }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ForecastFE[]>(`/consumer/${consumer.id}/forecast`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const consumerPage = createSlice({
    name: 'consumerPage',
    initialState,
    reducers: {
        resetTLConsumerPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setActiveTLConsumer: (state, { payload }: PayloadAction<TLConsumer | null>) => {
            state.consumer = payload;
        },
        loadMore: (state) => {
            const { data, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: data.length
            };
            state.paginatedData = data.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTLConsumerDataAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getTLConsumerDataAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.data = transformForecastToTable(payload as any);
            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).data.length
            };
            state.paginatedData = current(state).data.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
            state.chartData = [transformForecastToView(payload as any, FORECAST_TYPE.ELECTRICITY), []];
        });
        builder.addCase(getTLConsumerDataAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            //toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getTLConsumerAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getTLConsumerAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.consumer = payload;
        });
        builder.addCase(getTLConsumerAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            //toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const {
    resetTLConsumerPage,
    setActiveTLConsumer,
    setGetParams: setGetTLConsumerParams,
    loadMore: loadMoreTLConsumerData
} = consumerPage.actions;

export default consumerPage.reducer;
