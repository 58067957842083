import classnames from 'classnames';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useAppDispatch } from '../../../stores';
import { debounce } from '../../../utils/helpers';
import { GetParams } from '../../../interfaces/general';

import style from './style.module.scss';

export interface TableSearchProps {
    setGetParams: ActionCreatorWithPayload<GetParams, string>;
    getParams?: GetParams;
    inputValue?: string;
}

export const TableSearch = ({ setGetParams, getParams }: TableSearchProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [value, setValue] = useState(getParams?.search || '');
    const defaultInputPlaceholder = t('table.general.search');
    const [inputPlaceholder, setInputPlaceholder] = useState(defaultInputPlaceholder);

    const debouncedFn = (value: string | null) => {
        dispatch(setGetParams({ search: value, page: 1 }));
    };

    useEffect(() => {
        setValue(getParams?.search || '');
    }, [getParams?.search]);

    useEffect(() => {
        if (getParams?.search) {
            dispatch(setGetParams({ search: getParams.search, page: 1 }));
        }
    }, []);

    const debouncedHandler = useCallback(debounce(debouncedFn, 450), []);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setValue(value);
        debouncedHandler(value);
    };

    const handleFocus = () => {
        setInputPlaceholder('');
    };

    const handleBlur = () => {
        setInputPlaceholder(defaultInputPlaceholder);
    };

    useEffect(() => {
        return () => {
            dispatch(setGetParams({ search: null }));
        };
    }, []);

    return (
        <TextField
            variant="outlined"
            onChange={handleChange}
            placeholder={inputPlaceholder}
            className={classnames('table-search', style.filterInput)}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
    );
};
