import { useEffect } from 'react';

function useStickyHeader(headerId: string) {
    useEffect(() => {
        function fixHeader() {
            const headerWrapper = document.getElementById(headerId);

            if (window.pageYOffset > 10) {
                headerWrapper?.classList.add('borderHeader');
            } else {
                headerWrapper?.classList.remove('borderHeader');
            }
        }
        document.addEventListener('scroll', fixHeader);

        return () => {
            document.removeEventListener('scroll', fixHeader);
        };
    });

    //return null;
}

export default useStickyHeader;
