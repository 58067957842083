import dayjs from 'dayjs';

import { AssetAvailability } from '../../interfaces/apiv2';
import { AssetAvailabilityEvent } from '../../interfaces/uiv2';

export const getEventsFromAvailability = (availability: AssetAvailability): AssetAvailabilityEvent[] => {
    return Object.keys(availability).reduce((acc, day) => {
        const intervals = availability[day].map((interval) => {
            const [hours] = Object.keys(interval);
            const value = interval[hours];
            let startH,
                endH = '';

            if (hours.includes('-')) {
                [startH, endH] = hours.split(' - ');
            } else {
                startH = hours;
            }

            const [startHours, startMinutes] = startH.split(':');
            const [endHours, endMinutes] = endH.split(':');

            const start = dayjs(day, 'YYYY-MM-DD')
                .set('hour', Number(startHours))
                .set('minute', Number(startMinutes))
                .toISOString();

            const end = dayjs(day, 'YYYY-MM-DD')
                .set('hour', Number(endHours || 23))
                .set('minute', Number(endMinutes || 59))
                .toISOString();

            return {
                title: `${value * 100}%`,
                start,
                end
            };
        });

        return [...acc, ...intervals];
    }, [] as AssetAvailabilityEvent[]);
};
