import axios from '../../utils/axios';
import { toast } from 'react-toastify';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { GenerationAssetFE } from '../../interfaces/uiv2';
import { DefaultInitialState } from '../../interfaces/redux';
import { PatchGenerationAsset } from '../../interfaces/apiv2';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { commonReduxErrorHandler } from '../../utils/error-handling';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const updateAssetAsync = createAsyncThunk(
    'assets/update',
    async (
        {
            body,
            asset
        }: {
            body: PatchGenerationAsset;
            asset: GenerationAssetFE;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.patch(`/electricity/generation/assets/${asset.id}`, body);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const updateAsset = createSlice({
    name: 'updateAsset',
    initialState,
    reducers: {
        resetUpdateAssetState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(updateAssetAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(updateAssetAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(updateAssetAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
    }
});

export const { resetUpdateAssetState } = updateAsset.actions;

export default updateAsset.reducer;
