import { MdAdd } from 'react-icons/md';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';

import { MODAL_IDS } from '../../../modals';
import { getTableHeadCells } from './helpers';
import { ROUTES } from '../../../utils/config';
import { ModalContext } from '../../../hooks/modalContext';
import { PageContainer } from '../../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { TrainAssetModal } from '../../../modals/TrainAssetModal';
import { RunForecastModal } from '../../../modals/RunForecastModal';
import { CreateAssetModal } from '../../../modals/CreateAssetModal';
import { AssetLocationModal } from '../../../modals/AssetLocationModal';
import { DeliverForecastModal } from '../../../modals/DeliverForecastModal';
import { PermissionsModal } from '../../../modals/PermissionsModal';
import { ASSET_TYPE, GenerationAssetFE, UserFE } from '../../../interfaces/uiv2';
import { IngestAssetMeteoDataModal } from '../../../modals/IngestAssetMeteoDataModal';
import { AssetTechnicalDetailsModal } from '../../../modals/AssetTechnicalDetailsModal';
import { transformOrderByToSort } from '../../../components/BaseTable/GenericTable/helpers';
import {
    getAssetsAsync,
    resetListAssetsState,
    setGetParams,
    setSelectedRows,
    setGetExplicitParams
} from '../../../stores/Assets';
import { GenericTable, GenericTableToolbar, TableSearch, TableToolbarActions } from '../../../components/BaseTable';
import AssetTypeFiltering from '../../../components/AssetTypeFiltering/AssetTypeFiltering';
import { PERMISSION_TYPE } from '../../../stores/Permissions';

const AssetListWrapper = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { handleClickOpen, open } = useContext(ModalContext);
    const { t: tList } = useTranslation('assets/generation');

    const getParams = useAppSelector((state) => state.listAssets.getParams);
    const { selectedRows, assetsFE, loading, pager, success } = useAppSelector((state) => state.listAssets);
    const { success: newAssetCreated, error: assetCreatedError } = useAppSelector((state) => state.createAsset);
    const { user } = useAppSelector((state) => state.login);

    const [activeRow, setActiveRow] = useState<GenerationAssetFE>();
    const [activeRowPermissions, setActiveRowPermissions] = useState<GenerationAssetFE>();
    const headCells = getTableHeadCells({ tList, t, isAdmin: true, user: user as UserFE });

    useEffect(() => {
        dispatch(getAssetsAsync({ ...getParams }));
    }, [getParams]);

    useEffect(() => {
        if (!open[MODAL_IDS.CREATE_ASSET_MODAL]) {
            if (newAssetCreated || assetCreatedError) {
                dispatch(getAssetsAsync({ ...getParams }));
            }
        }
    }, [open[MODAL_IDS.CREATE_ASSET_MODAL], newAssetCreated, assetCreatedError]);

    useEffect(() => {
        return () => {
            dispatch(resetListAssetsState());
        };
    }, []);

    const handleAddNewAsset = () => handleClickOpen(MODAL_IDS.CREATE_ASSET_MODAL);

    const handleEditPermissions = (row: GenerationAssetFE) => () => {
        setActiveRowPermissions(row);
        handleClickOpen(MODAL_IDS.ASSET_PERMISSIONS_MODAL);
    };

    const openModal = (modalId: MODAL_IDS) => (row: GenerationAssetFE) => () => {
        setActiveRow(row);
        handleClickOpen(modalId);
    };

    const getEditAssetPageUrl = (row: GenerationAssetFE) => () => {
        return ROUTES.ADMIN_FORECASTING_ASSETS.replace(':assetId', row.id);
    };

    const handleEditDelivery = (row: GenerationAssetFE) => () => {
        return `${ROUTES.ADMIN_DELIVERY_ASSETS}?id=${row.id}`;
    };

    const getAssetPageUrl = (row: GenerationAssetFE) => () => {
        return `${ROUTES.ADMIN_GENERATION_ASSETS_PAGE.replace(':id', row.id)}`;
    };

    return (
        <>
            <PageContainer>
                <GenericTableToolbar tableTitle={tList(`title.assets`)}>
                    <Box sx={{ display: selectedRows.length !== 0 ? 'initial' : 'none', mr: 2 }}>
                        <TableToolbarActions selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
                    </Box>

                    <AssetTypeFiltering getParams={getParams} setGetParams={setGetExplicitParams} />
                    <TableSearch setGetParams={setGetParams} getParams={getParams} />

                    {selectedRows.length === 0 && (
                        <Button disableRipple variant="cta" onClick={handleAddNewAsset} sx={{ ml: 1 }}>
                            <MdAdd />
                        </Button>
                    )}
                </GenericTableToolbar>

                <GenericTable
                    rows={assetsFE}
                    pager={pager}
                    loading={loading}
                    success={success}
                    isCheckboxEnabled
                    columns={headCells}
                    rowUniqueId={'id'}
                    selectedRows={selectedRows}
                    setGetParams={setGetParams}
                    searchParam={getParams.search}
                    setSelectedRows={setSelectedRows}
                    storeSort={transformOrderByToSort(getParams.order_by)}
                    handlers={{
                        name: getAssetPageUrl,
                        edit: getEditAssetPageUrl,
                        location: openModal(MODAL_IDS.ASSET_LOCATION_MODAL),
                        editPermissions: handleEditPermissions,
                        editDelivery: handleEditDelivery,
                        trainAsset: openModal(MODAL_IDS.TRAIN_ASSET_MODAL),
                        ingestAssetMeteoData: openModal(MODAL_IDS.INGEST_METEO_DATA_ASSET_MODAL),
                        runForecast: openModal(MODAL_IDS.RUN_FORECAST_MODAL),
                        deliverForecast: openModal(MODAL_IDS.DELIVER_FORECAST_MODAL),
                        techDetails: openModal(MODAL_IDS.ASSET_TECHNICAL_DETAILS_MODAL)
                    }}
                />
            </PageContainer>

            <CreateAssetModal type={ASSET_TYPE.SOLAR} />
            {activeRow && (
                <AssetLocationModal
                    payload={{
                        longitude: activeRow.longitude,
                        latitude: activeRow.latitude,
                        title: activeRow.name,
                        location: activeRow.location || '',
                        type: activeRow.type
                    }}
                />
            )}
            <TrainAssetModal asset={activeRow} />
            <IngestAssetMeteoDataModal asset={activeRow} />
            <RunForecastModal asset={activeRow} />
            <AssetTechnicalDetailsModal asset={activeRow} />
            <DeliverForecastModal asset={activeRow} />
            {activeRowPermissions && (
                <PermissionsModal
                    selectedItem={activeRowPermissions}
                    resetActiveRow={() => setActiveRowPermissions(undefined)}
                    permissionType={PERMISSION_TYPE.ASSET}
                />
            )}
        </>
    );
};

export default AssetListWrapper;
