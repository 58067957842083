import { Navigate } from 'react-router-dom';

import { ROUTES } from '../../utils/config';
import { BaseRouteProps } from './interfaces';
import { useAppSelector } from '../../stores';

const ProtectedRoute = ({ redirectPath = ROUTES.LOGIN, children, force }: BaseRouteProps) => {
    const { user } = useAppSelector((state) => state.login);

    if (!user || force) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
};

export default ProtectedRoute;
