import dayjs from 'dayjs';
import { FC } from 'react';
import * as yup from 'yup';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Box, Divider, FormHelperText, Input, InputLabel, TextField, Typography } from '@mui/material';

import { useAppDispatch } from '../../stores';
import { MODAL_IDS } from '..';
import { useFormLogic } from '../../hooks/useFormLogic';
import { FormModalActions } from '../../components/FormUtils';
import { runForecastAsync } from '../../stores/Assets';
import { GenerationAssetFE, PostRunForecastBody, RunForecastFormData } from '../../interfaces/uiv2';

import style from './style.module.scss';

export const RunForecastForm: FC<{ asset?: GenerationAssetFE }> = ({ asset }) => {
    const dispatch = useAppDispatch();
    const { t: generalTranslation } = useTranslation();
    const { t } = useTranslation('assets/generation');
    const schema = yup.object({
        start_date: yup.string(),
        end_date: yup.string(),
        apiKey: yup.string().required()
    });

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useFormLogic<RunForecastFormData, RunForecastFormData, any, any>({
        schema,
        data: null,
        defaultValues: {
            start_date: asset!.meteo_start_date || '',
            end_date: '',
            apiKey: ''
        }
    });

    const onSubmit = (form: RunForecastFormData) => {
        const body: PostRunForecastBody = {
            start_date: form.start_date ? dayjs(form.start_date).utc().toISOString() : undefined,
            end_date: form.end_date ? dayjs(form.end_date).utc().toISOString() : undefined,
            apiKey: form.apiKey,
            assetId: asset!.id
        };

        dispatch(runForecastAsync(body));
    };

    return (
        <Box className={style.container}>
            <Typography variant="h6">
                {t('runForecast')} <span className={style.assetName}>{asset?.name}</span>
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ p: 2 }}>
                <Box className={style.mb2}>
                    <Controller
                        name="start_date"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" shrink htmlFor={'startDate'}>
                                    {t('form.startDate')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="startDate"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.start_date && (
                                    <FormHelperText error>{t('form.errors.required')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>
                <Box className={style.mb2}>
                    <Controller
                        name="end_date"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" shrink htmlFor={'endDate'}>
                                    {t('form.endDate')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="endDate"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.end_date && <FormHelperText error>{t('form.errors.required')}</FormHelperText>}
                            </>
                        )}
                    />
                </Box>
                <Box className={style.mb4}>
                    <Controller
                        name="apiKey"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.apiKey')}>
                                    {t('form.apiKey')}
                                </InputLabel>
                                <Input {...field} fullWidth id={t('form.apiKey')} placeholder={t('form.apiKey')} />
                                {errors.apiKey && <FormHelperText error>{t('form.errors.generic')}</FormHelperText>}
                            </>
                        )}
                    />
                </Box>

                <Divider className={style.divider} />

                <FormModalActions
                    loading={false}
                    modalId={MODAL_IDS.RUN_FORECAST_MODAL}
                    cancelLabel={generalTranslation('nevermind')}
                    saveLabel={generalTranslation('submit')}
                />
            </Box>
        </Box>
    );
};

export default RunForecastForm;
