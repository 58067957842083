import { Client, GenerationAsset } from '../apiv2';
import { ForecastSettingsFEV2 } from './forecast_settings_v2';

export enum UNIT {
    ENERGY = 'Energy',
    LOW_ENERGY = 'LOW_ENERGY',
    POWER = 'Power',
    USD = 'USD',
    M3 = 'm³'
}
export enum ASSET_TYPE {
    SOLAR = 'Solar',
    WIND = 'Wind',
    // not yet added
    HYDRO = 'Hydro',
    NUCLEAR = 'Nuclear',
    COAL = 'Coal',
    BIOMASS = 'Biomass',
    GEOTHERMAL = 'Geothermal',
    GAS = 'Gas',
    OTHER = 'Other',
    PROSUMER = 'Prosumer',
    CONSUMER = 'Consumer',
    EVS = 'EVS' // TODO: ?
}

// TODO: MOVE IT FROM HERE
export const ASSET_TYPES = [
    {
        name: ASSET_TYPE.WIND
    },
    {
        name: ASSET_TYPE.SOLAR
    }
];

export enum ASSET_STATUS {
    PENDING = 'Pending',
    DISABLED = 'Disabled',
    ACTIVE = 'Active',
    COMPLETED = 'Completed'
}

export interface GenerationAssetFE extends GenerationAsset {
    index: number;
    is_mock?: boolean;
    generation_forecast_models_created_at: string | null;
}

export enum INSTALLATION_TYPE_WIND {
    ONSHORE = 'Onshore',
    OFFSHORE = 'Offshore',
    OTHER = 'Other'
}

export enum INSTALLATION_TYPE_SOLAR {
    GROUND = 'Ground',
    ROOF = 'Roof',
    OTHER = 'Other'
}

export interface GenerationAssetFormFields {
    name: string;
    client: Client;
    type: ASSET_TYPE;
    manufacturer?: string;
    capacity_value: number;
    measure_unit: UNIT;
    longitude: number;
    latitude: number;
    installation_date: string;
    expiry_date: string;
    has_realtime_data: boolean;
    installation_type?: INSTALLATION_TYPE_SOLAR | INSTALLATION_TYPE_WIND;
    // wind type
    turbines?: number;
    hub_height?: number;
    windTracking?: boolean;
    //solar type
    tilt_angle?: number;
    azimuth?: number;
    solarTracking?: boolean;
}

export interface PostTrainAssetBody {
    id: string;
    start_date: string;
    end_date?: string;
    forecast_settings_id: string;
}

export interface TrainAssetFormData {
    start_date: string;
    end_date: string;
    forecastSettings: ForecastSettingsFEV2;
}

export interface PostIngestMeteoAssetBody {
    id: string;
    start_date: string;
    end_date?: string;
    sources: string[];
}

export interface IngestMeteoAssetFormData {
    start_date: string;
    end_date: string;
    sources: string[];
}

export interface AssetTechnicalDetailsFormData extends GenerationAsset {
    meteo_start_date?: string;
    training_start?: string;
}

export interface PatchAssetTechnicalDetailsBody {
    id: string;
    body: AssetTechnicalDetailsFormData;
}

export interface AssetAvailabilityPayload {
    publicId: string;
    from: number;
    to: number;
}

export interface AssetAvailabilityEvent {
    title: string;
    start: string;
    end: string;
}
