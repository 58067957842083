import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { User } from '../../interfaces/apiv2';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';

interface IListPermissions extends DefaultInitialState {
    accessListPermissions: User[];
}

export enum PERMISSION_TYPE {
    ASSET = 'ASSET',
    PROJECT = 'PROJECT'
}

const initialState: IListPermissions = {
    loading: false,
    error: false,
    success: false,
    accessListPermissions: []
};

export const getPermissionsAsync = createAsyncThunk(
    'assets/listPermissions',
    async ({ id, type }: { id: string; type: PERMISSION_TYPE }, { rejectWithValue }) => {
        try {
            const endpoint =
                type === PERMISSION_TYPE.ASSET
                    ? `/electricity/generation/assets/${id}/access-list`
                    : `/projects/${id}/access-list`;
            const response = await axios.get<User[]>(endpoint);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listPermissions = createSlice({
    name: 'listPermissions',
    initialState,
    reducers: {
        resetListPermissionsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getPermissionsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getPermissionsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.accessListPermissions = payload;
        });
        builder.addCase(getPermissionsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetListPermissionsState } = listPermissions.actions;

export default listPermissions.reducer;
