import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Input, Stack, Divider, Typography, InputLabel, FormHelperText } from '@mui/material';

import { MODAL_IDS } from '../index';
import { AssetLocationFE, AssetLocationsFormFields } from '../../interfaces/uiv2';
import { useFormLogic } from '../../hooks/useFormLogic';
import { FormModalActions } from '../../components/FormUtils';
import { useAppDispatch, useAppSelector } from '../../stores';
import { createAssetLocationsAsync, editAssetLocationsAsync } from '../../stores/Assets';
import {
    getAssetLocationsSchema,
    getAssetLocationsFormDataDefaultValue,
    transformAssetLocationsFormDataToPostBody,
    transformAssetLocationsFormDataToPathBody
} from './helpers';

import style from './style.module.scss';

const AssetLocationsForm = ({ assetId, data }: { assetId: string; data?: AssetLocationFE }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { loading: editLoading } = useAppSelector((state) => state.editAssetLocations);
    const { loading: createLoading } = useAppSelector((state) => state.createAssetLocations);
    const loading = editLoading || createLoading;

    const schema = getAssetLocationsSchema(t);

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty }
    } = useFormLogic<any, AssetLocationsFormFields, any, AssetLocationsFormFields>({
        schema,
        data,
        defaultValues: getAssetLocationsFormDataDefaultValue(data)
    });

    const onSubmit = (formData: AssetLocationsFormFields) => {
        if (data) {
            const body = transformAssetLocationsFormDataToPathBody(formData, data, assetId);
            dispatch(editAssetLocationsAsync(body));
            return;
        }
        const body = transformAssetLocationsFormDataToPostBody(formData, assetId);
        dispatch(createAssetLocationsAsync(body));
    };

    const onError = (err: any) => {
        console.error('Error submitting form', err);
    };

    return (
        <Box className={style.container} component="form" noValidate onSubmit={handleSubmit(onSubmit, onError)}>
            <Typography variant="h6" className={style.title}>
                {t('header.addLocations')}
            </Typography>

            {/* name & type */}
            <Stack direction="row" justifyContent="space-between" spacing={4} width="100%">
                <Box className={style.formInput}>
                    <Controller
                        name="latitude"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.latitude')}>
                                    {t('form.label.latitude')}
                                </InputLabel>
                                <Input
                                    {...field}
                                    fullWidth
                                    id={t('form.label.latitude')}
                                    placeholder={t('form.label.latitude')}
                                />
                                {errors.latitude && <FormHelperText error>{t('form.errors.generic')}</FormHelperText>}
                                {errors.latitude?.type === 'customTest' && (
                                    <FormHelperText error>{t('form.errors.coordinate')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>

                <Box className={style.formInput}>
                    <Controller
                        name="longitude"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.longitude')}>
                                    {t('form.label.longitude')}
                                </InputLabel>
                                <Input
                                    {...field}
                                    fullWidth
                                    id={t('form.label.longitude')}
                                    placeholder={t('form.label.longitude')}
                                />
                                {errors.longitude && <FormHelperText error>{t('form.errors.generic')}</FormHelperText>}
                                {errors.longitude?.type === 'customTest' && (
                                    <FormHelperText error>{t('form.errors.coordinate')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>
            </Stack>

            <Divider sx={{ mt: 4 }} />

            <FormModalActions
                loading={loading}
                disabled={!isDirty}
                modalId={data ? MODAL_IDS.EDIT_LOCATION_COORDINATES : MODAL_IDS.ADD_LOCATION_COORDINATES}
                note={t('mandatoryFields')}
                cancelLabel={t('nevermind')}
                saveLabel={t('form.label.submit')}
            />
        </Box>
    );
};

export default AssetLocationsForm;
