import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { GetParams } from '../../interfaces/general';
import ToastifyType from '../../utils/toastify-config';
import { getDownloadThunk } from '../../utils/common-thunks';
import { getQueryParamsFromObject } from '../../utils/table';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { ApiPaginationInfo, EVSReport, PaginatedApiResponse } from '../../interfaces/apiv2';
import { ReportsListGetParams } from '../../interfaces/uiv2';
import { transformReportsAPItoFE } from './helpers';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { EVSReportFE } from '../../interfaces/uiv2/evs/evs_report';

interface EVSListReportsState extends DefaultInitialState {
    reports: EVSReport[];
    reportsFE: EVSReportFE[];
    searchParam: string | null;
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
    selectedRows: EVSReportFE[];
    loadingDownload: boolean;
    activeDownloadMeta: { ids: string[]; type: string } | null;
}

const initialState: EVSListReportsState = {
    reports: [],
    reportsFE: [],
    loading: false,
    error: false,
    success: false,
    pager: null,
    searchParam: null,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    selectedRows: [],

    loadingDownload: false,
    activeDownloadMeta: null
};

export const getEVSReportsListAsync = createAsyncThunk<
    PaginatedApiResponse<EVSReport>,
    GetParams & { project_id: string },
    { state: RootState }
>('evs/reports/list', async (values: GetParams & { project_id: string }, { rejectWithValue }) => {
    try {
        const queryParams = getQueryParamsFromObject({ ...values });
        const endpoint = `/evs/${values.project_id}/reports`;
        const response = await axios.get<PaginatedApiResponse<EVSReport>>(`${endpoint}${queryParams}`);

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const downloadEVSReport = getDownloadThunk({
    thunkName: 'evs_report_list/download',
    endpoint: `/evs/:projectId/reports/files/download`,
    bodyPropName: 'report_ids',
    filename: 'report'
});

export const listEVSReports = createSlice({
    name: 'listEVSReports',
    initialState,
    reducers: {
        resetListEVSReportsState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ReportsListGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<any[]>) => {
            state.selectedRows = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEVSReportsListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getEVSReportsListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.reports = items;
            } else {
                state.reports = [...current(state).reports, ...items];
            }
            state.reportsFE = transformReportsAPItoFE(current(state).reports);
        });
        builder.addCase(getEVSReportsListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(downloadEVSReport.pending, (state, { meta }) => {
            state.loadingDownload = true;
            state.activeDownloadMeta = meta.arg;
        });
        builder.addCase(downloadEVSReport.fulfilled, (state) => {
            state.loadingDownload = false;
            state.activeDownloadMeta = null;
        });
        builder.addCase(downloadEVSReport.rejected, (state) => {
            state.loadingDownload = false;
            state.activeDownloadMeta = null;
        });
    }
});

export const {
    resetListEVSReportsState,
    setGetParams: setEVSReportsGetParams,
    setSelectedRows: setEVSReportsSelectedRows
} = listEVSReports.actions;

export default listEVSReports.reducer;
