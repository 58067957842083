import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../../../utils/axios';
import i18next from '../../../../utils/i18next';
import ToastifyType from '../../../../utils/toastify-config';
import { DefaultInitialState } from '../../../../interfaces/redux';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';

interface DeleteSegmentsState extends DefaultInitialState {
    deleted: number;
}

const initialState: DeleteSegmentsState = {
    loading: false,
    error: false,
    success: false,
    deleted: 0
};

export const deleteSegmentsListAsync = createAsyncThunk(
    'segments_list/delete',
    async ({ ids, projectId }: { ids: string[]; projectId: string }, { rejectWithValue }) => {
        try {
            await Promise.all(
                ids.map((id) => {
                    return axios.delete(`/electricity/demand/${projectId}/clusters/${id}`);
                })
            );
            return null;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const deleteSegments = createSlice({
    name: 'deleteSegments',
    initialState,
    reducers: {
        resetDeleteSegmentsListState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(deleteSegmentsListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(deleteSegmentsListAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.deleted += 1;
        });
        builder.addCase(deleteSegmentsListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetDeleteSegmentsListState } = deleteSegments.actions;

export default deleteSegments.reducer;
