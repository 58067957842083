import i18next from 'i18next';
import { useEffect } from 'react';
import { throttle } from 'lodash';
import { CssBaseline, ThemeProvider } from '@mui/material';

import Router from './containers/Routes';
import { Theme } from './containers/Theme';
import { MODAL_IDS } from './modals';
import { ModalContext } from './hooks/modalContext';
import { useModalControls } from './hooks/useModalControls';

const App = () => {
    const modalControls = useModalControls({ modalIds: Object.values(MODAL_IDS) });
    // OGRE-653 auto-hide scrollbar
    useEffect(() => {
        document.body.classList.add('hiddenScroll');
        let scrollTimer: ReturnType<typeof setTimeout>;

        function handleScroll(e: any) {
            if (scrollTimer) clearTimeout(scrollTimer);

            scrollTimer = setTimeout(() => {
                e?.target?.body?.classList.add('hiddenScroll');
            }, 500);
            e?.target?.body?.classList.remove('hiddenScroll');
        }

        document.addEventListener('scroll', throttle(handleScroll, 400));

        function fixHeaderBg() {
            const headerWrapper = document.getElementById('header-wrapper');

            if (window.pageYOffset > 10) {
                headerWrapper?.classList.add('solidHeaderBg');
            } else {
                headerWrapper?.classList.remove('solidHeaderBg');
            }
        }

        document.addEventListener('scroll', fixHeaderBg);

        i18next.loadNamespaces([
            'assets/create-update-vpp',
            'assets/forecast-settings',
            'assets/generation',
            'assets/vpps',
            'forecasts-list/list',
            'translation',
            'auth/changePassword',
            'auth/resetPassword',
            'auth/login',
            'clients/list',
            'clients/create',
            'users/list',
            'users/create',
            'components/account-settings-modal',
            'components/support-modal',
            'components/delete-card-modal'
        ]);

        return () => {
            if (scrollTimer) clearTimeout(scrollTimer);
            document.removeEventListener('scroll', handleScroll);
            document.removeEventListener('scroll', fixHeaderBg);
        };
    }, []);

    return (
        <ThemeProvider theme={Theme}>
            <CssBaseline />
            <ModalContext.Provider value={modalControls}>
                <Router />
            </ModalContext.Provider>
        </ThemeProvider>
    );
};

export default App;
