import { DialogContent } from '@mui/material';

import { MODAL_IDS } from '..';
import IngestForm from './IngestForm';
import Cleanup from '../../components/Cleanup/Cleanup';
import { useAppDispatch, useAppSelector } from '../../stores';
import { FormModal } from '../../components/BaseModal/FormModal';
import { resetIngestLocationsState } from '../../stores/IngestLocationsData';
import { FormErrorStep, FormSuccessStep } from '../../components/FormUtils';
import { AssetLocationFE, GenerationAssetFE } from '../../interfaces/uiv2';

const IngestLocationsModal = ({ data, asset }: { data: AssetLocationFE[]; asset: GenerationAssetFE }) => {
    const dispatch = useAppDispatch();
    const { success, error } = useAppSelector((state) => state.ingestLocations);

    const handleCleanup = () => {
        // use reset create project state
        dispatch(resetIngestLocationsState());
    };

    return (
        <FormModal id={MODAL_IDS.INGEST_LOCATION_COORDINATES} fullWidth={false} maxWidth="sm">
            <DialogContent>
                {!(success || error) ? <IngestForm data={data} asset={asset} /> : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
                {/* needed because create asset modal never unmounts but what is inside of dialog content yes */}
                <Cleanup cb={handleCleanup} />
            </DialogContent>
        </FormModal>
    );
};

export { IngestLocationsModal };
