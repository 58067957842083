import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { toast } from 'react-toastify';
import ToastifyType from '../../../utils/toastify-config';
import i18next from '../../../utils/i18next';
import { ROUTES } from '../../../utils/config';
import { ChangePasswordBody } from '../../../interfaces/uiv2';
import { DefaultInitialState } from '../../../interfaces/redux';
import { redirect } from 'react-router-dom';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const changePasswordAsync = createAsyncThunk(
    'auth/changePassword',
    async ({ data, token }: ChangePasswordBody, { rejectWithValue }) => {
        try {
            const { password } = data;
            const response = await axios.patch('/users/password', { password, token });
            commonOnChangePasswordSuccess();
            return response.data;
        } catch (e) {
            commonOnChangePasswordError();
            return rejectWithValue;
        }
    }
);

const commonOnChangePasswordSuccess = () => {
    toast.success(i18next.t('auth/changePassword:changePasswordSuccess'), ToastifyType.success);
    redirect(ROUTES.LOGIN);
};

const commonOnChangePasswordError = () => {
    toast.error(i18next.t('auth/changePassword:changePasswordError'), ToastifyType.error);
    redirect(ROUTES.RESET_PASSWORD);
};

export const changePassword = createSlice({
    name: 'changePassword',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(changePasswordAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(changePasswordAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = true;
        });
        builder.addCase(changePasswordAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    }
});

export default changePassword.reducer;
