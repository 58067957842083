import dashboard from './Dashboard';

export * from './Dashboard';

export default { dashboard };

type Accuracy = {
    PreviousDay: number;
    PreviousMonth: number;
    PreviousWeek: number;
    total: number;
};

export interface GenerationStats {
    asset_data_per_type: {
        [i in ALL_TYPES]: {
            type: i;
            count: number;
            total_capacity: number;
            smart_meters?: number;
        };
    };
    accuracy_by_type: Accuracy;
    demand_accuracy: Accuracy;
    prosumers_accuracy: Accuracy;
    vpp_data: {
        count: number;
        total_capacity: number;
    };
}

export interface EVSStats {
    charging_stations: number;
    charging_points: number;
}

export interface EVSStatsFE {
    evs_stats: {
        charging_stations: { count: number };
        charging_points: { count: number };
    };
}

export interface GenerationStatsFE {
    asset_data_per_type: {
        [i in ALL_TYPES]: {
            type: i;
            count: number;
            total_capacity: number;
            smart_meters?: number;
        };
    };
    performance: {
        accuracy_by_type: Accuracy;
        demand_accuracy: Accuracy;
        prosumers_accuracy: Accuracy;
    };
    demand_accuracy: Accuracy;
    prosumers_accuracy: Accuracy;
    total_members: number;
    total_capacity: number;
    total_consumers: number;
    smart_meters: number;
}

export type Country = 'RO' | 'BG';

export interface CountryData {
    data: Record<
        string,
        {
            timestamp: string;
            value: Record<string, number>;
            type: string;
            country: Country;
            unit_of_measure: string;
            ingested_at: string;
            meta: null;
        }
    >;
    legend: Record<string, string>;
}

import { api } from '../api';
import { ALL_TYPES } from '../../utils/getAssetIcon';

const getTotalByKeys = (data: any, keys: any[], keyToCount: string) => {
    return keys.reduce((acc, key) => {
        return acc + (data?.[key]?.[keyToCount] || 0);
    }, 0);
};

export const dashboardApi = api.injectEndpoints({
    endpoints: (build) => ({
        getStats: build.query({
            query: () => {
                return { url: `/stats`, method: 'get', data: [] };
            },
            transformResponse: (rawData: CountryData): CountryData => {
                return rawData;
            },
            keepUnusedDataFor: 10 * 60 // 10 minutes
        }),
        getElectricityStats: build.query({
            query: () => {
                return { url: `/electricity/generation/stats`, method: 'get', data: [] };
            },
            transformResponse: (rawData: GenerationStats): GenerationStatsFE => {
                return {
                    ...rawData,
                    performance: {
                        accuracy_by_type: rawData.accuracy_by_type,
                        prosumers_accuracy: rawData.prosumers_accuracy,
                        demand_accuracy: rawData.demand_accuracy
                    },
                    demand_accuracy: rawData.demand_accuracy,
                    prosumers_accuracy: rawData.prosumers_accuracy,
                    asset_data_per_type: {
                        ...rawData.asset_data_per_type,
                        VPP: {
                            type: 'VPP',
                            count: rawData.vpp_data?.count || 0,
                            total_capacity: rawData.vpp_data?.total_capacity || 0
                        }
                    },
                    total_members: getTotalByKeys(rawData.asset_data_per_type, ['Solar', 'Wind'], 'count'),
                    total_capacity: getTotalByKeys(rawData.asset_data_per_type, ['Solar', 'Wind'], 'total_capacity'),
                    total_consumers: getTotalByKeys(rawData.asset_data_per_type, ['CAS', 'ASOS', 'NCAS'], 'count'),
                    smart_meters: getTotalByKeys(rawData.asset_data_per_type, ['CAS', 'ASOS', 'NCAS'], 'smart_meters')
                };
            },
            keepUnusedDataFor: 10 * 60 // 10 minutes
        }),
        getEvsStats: build.query({
            query: () => {
                return { url: `/evs/stats`, method: 'get', data: [] };
            },
            transformResponse: (rawData: EVSStats): EVSStatsFE => {
                return {
                    evs_stats: {
                        charging_stations: { count: rawData.charging_stations },
                        charging_points: { count: rawData.charging_points }
                    }
                };
            },
            keepUnusedDataFor: 10 * 60 // 10 minutes
        })
    })
});

export const { useGetStatsQuery, useGetElectricityStatsQuery, useGetEvsStatsQuery } = dashboardApi;
