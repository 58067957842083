import { FC } from 'react';
import { DialogContent } from '@mui/material';

import { MODAL_IDS } from '..';
import Cleanup from '../../components/Cleanup/Cleanup';
import { GenerationAssetFE } from '../../interfaces/uiv2';
import { useAppDispatch, useAppSelector } from '../../stores';
import { FormModal } from '../../components/BaseModal/FormModal';
import IngestAssetMeteoDataForm from './IngestAssetMeteoDataForm';
import { resetIngestMeteoDataAssetState } from '../../stores/Assets';
import { FormErrorStep, FormSuccessStep } from '../../components/FormUtils';

export const IngestAssetMeteoDataModal: FC<{ asset?: GenerationAssetFE }> = ({ asset }) => {
    const dispatch = useAppDispatch();

    const { success, error } = useAppSelector((state) => state.ingestMeteoDataAsset);

    const handleCleanup = () => {
        dispatch(resetIngestMeteoDataAssetState());
    };

    return (
        <FormModal id={MODAL_IDS.INGEST_METEO_DATA_ASSET_MODAL} fullWidth={false} maxWidth="sm">
            <DialogContent>
                {!(success || error) ? <IngestAssetMeteoDataForm asset={asset} /> : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
                <Cleanup cb={handleCleanup} />
            </DialogContent>
        </FormModal>
    );
};
