import { TFunction } from 'i18next';
import classnames from 'classnames';
import Flag from 'react-world-flags';
import { Box, SvgIcon, Typography } from '@mui/material';

import { NO_VALUE_NA } from '../../utils/config';
import { TableCellRenderer } from '../../interfaces/general';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { DefaultSearchableCell, TableActions } from '../../components/BaseTable';

import style from './style.module.scss';
import common from '../../common.module.scss';

import { ReactComponent as ArrowSVG } from '../../assets/icons/arrow.svg';
import { ReactComponent as EuropeSVG } from '../../assets/icons/europe.svg';

export const getTableHeadCells = (t: TFunction): TableCellRenderer<any>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        className={classnames(style.default)}
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'market',
            justifyContent: 'center',
            maxWidth: 150,
            label: t('tableHead.market'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;

                return (
                    <DefaultSearchableCell value={row.market} searchValue={searchValue} isLink linkUrl={callback()} />
                );
            },
            sort: true
        },
        {
            id: 'region',
            justifyContent: 'center',
            maxWidth: 150,
            label: t('tableHead.region'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={style.EUFlagCenter}>
                        <SvgIcon component={EuropeSVG} style={{ marginRight: '8px' }} />
                        {row.region}
                    </Typography>
                );
            },
            sort: false
        },
        {
            id: 'country',
            justifyContent: 'center',
            maxWidth: 150,
            label: t('tableHead.country'),
            value: (row, searchValue) => {
                const country = row.country?.toUpperCase() || '';

                if (!country) {
                    return (
                        <Typography variant="small4" className={style.default}>
                            {NO_VALUE_NA}
                        </Typography>
                    );
                }

                return (
                    <DefaultSearchableCell value={country} searchValue={searchValue}>
                        <Flag
                            height="24"
                            width="24"
                            code={country}
                            style={{ marginRight: '8px' }}
                            className="flagIcon"
                        />
                    </DefaultSearchableCell>
                );
            },
            sort: false
        },
        {
            id: 'price',
            justifyContent: 'center',
            maxWidth: 150,
            label: t('tableHead.price'),
            value: (row) => {
                return (
                    <Typography
                        variant="small4"
                        className={classnames(style.default, row.price > 500 ? common.error : style.green)}
                    >
                        {row.price}
                    </Typography>
                );
            },
            sort: true
        },
        {
            id: '1h',
            justifyContent: 'center',
            maxWidth: 150,
            label: t('tableHead.1h'),
            value: (row) => {
                return (
                    <Typography
                        variant="small4"
                        className={classnames(style.default, row['1h'] > 10 ? common.error : common.green)}
                    >
                        {row['1h']}

                        {row['1h'] > 10 ? (
                            <ArrowSVG className={classnames(common.error, common.ml)} />
                        ) : (
                            <ArrowSVG className={classnames(common.greenIcon, common.ml)} />
                        )}
                    </Typography>
                );
            },
            sort: true
        },
        {
            id: '24h',
            justifyContent: 'center',
            maxWidth: 150,
            label: t('tableHead.24h'),
            value: (row) => {
                return (
                    <Typography
                        variant="small4"
                        className={classnames(style.default, row['24h'] < 10 ? common.error : common.green)}
                    >
                        {row['24h']}

                        {row['24h'] < 10 ? (
                            <ArrowSVG className={classnames(common.error, common.ml)} />
                        ) : (
                            <ArrowSVG className={classnames(common.greenIcon, common.ml)} />
                        )}
                    </Typography>
                );
            },
            sort: true
        },
        {
            id: '7d',
            justifyContent: 'center',
            maxWidth: 150,
            label: t('tableHead.7d'),
            value: (row) => {
                return (
                    <Typography
                        variant="small4"
                        className={classnames(style.default, row['7d'] < 5 ? common.error : common.green)}
                    >
                        {row['7d']}

                        {row['7d'] < 5 ? (
                            <ArrowSVG className={classnames(common.error, common.ml)} />
                        ) : (
                            <ArrowSVG className={classnames(common.greenIcon, common.ml)} />
                        )}
                    </Typography>
                );
            },
            sort: true
        },
        {
            id: 'volume_24h',
            justifyContent: 'center',
            label: t('tableHead.volume_24h'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {row['volume_24h']}
                    </Typography>
                );
            },
            sort: false
        },
        {
            id: 'volume_7d',
            justifyContent: 'center',
            label: t('tableHead.volume_7d'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {row['volume_7d']}
                    </Typography>
                );
            },
            sort: false
        },

        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: t('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }
                            ]}
                        />
                    </Box>
                );
            }
        }
    ];
};
