import { api } from '../api';
import listAssets from './listAssets';
import createAsset from './createAsset';
import updateAsset from './updateAsset';
import trainAsset from './trainAsset';
import ingestMeteoDataAsset from './ingestMeteoDataAsset';
import assetPage from './assetPage';
import runForecast from './runForecastForAsset';
import deliverForecast from './deliverForecastForAsset';
import listAssetForecast from './listAssetForecast';
import listAssetAvailability from './listAssetAvailability';
import updateAssetTechnicalDetails from './updateAssetTechnicalDetails';
import listAssetLocations from './Locations/listAssetLocations';
import createAssetLocations from './Locations/createAssetLocations';
import deleteAssetLocations from './Locations/deleteAssetLocations';
import editAssetLocations from './Locations/editAssetLocations';
import { GenerationAsset, PaginatedApiResponse } from '../../interfaces/apiv2';
import { GenerationAssetFE } from '../../interfaces/uiv2';
import { transformGenerationAssetAPItoFE } from './helpers';

export const assetsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAssets: build.query({
            query: (params: { search?: string | null; page: number; size: number }) => {
                if (params.search?.trim()) {
                    return {
                        url: '/electricity/generation/assets',
                        method: 'get',
                        params: { ...params, search: params.search },
                        data: undefined
                    };
                }

                return { url: '/electricity/generation/assets', method: 'get', params, data: undefined };
            },
            transformResponse: (rawData: PaginatedApiResponse<GenerationAsset>): GenerationAssetFE[] => {
                return transformGenerationAssetAPItoFE(rawData.items);
            }
        }),
        getAsset: build.query({
            query: (params) => {
                return { url: `/electricity/generation/assets/${params.assetId}`, method: 'get', data: undefined };
            },
            transformResponse: (rawData: GenerationAsset): GenerationAssetFE => {
                return transformGenerationAssetAPItoFE([rawData])[0];
            }
        })
    })
});

export const { useGetAssetsQuery, useGetAssetQuery } = assetsApi;

export * from './listAssets';
export * from './createAsset';
export * from './updateAsset';
export * from './trainAsset';
export * from './ingestMeteoDataAsset';
export * from './runForecastForAsset';
export * from './deliverForecastForAsset';
export * from './listAssetForecast';
export * from './listAssetAvailability';
export * from './assetPage';
export * from './updateAssetTechnicalDetails';
export * from './Locations/listAssetLocations';
export * from './Locations/createAssetLocations';
export * from './Locations/deleteAssetLocations';
export * from './Locations/editAssetLocations';

export default {
    listAssets,
    updateAsset,
    trainAsset,
    ingestMeteoDataAsset,
    runForecast,
    deliverForecast,
    createAsset,
    listAssetForecast,
    listAssetAvailability,
    assetPage,
    updateAssetTechnicalDetails,
    listAssetLocations,
    createAssetLocations,
    deleteAssetLocations,
    editAssetLocations
};
