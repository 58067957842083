import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../../../utils/axios';
import i18next from '../../../../utils/i18next';
import { GetParams } from '../../../../interfaces/general';
import { transformConsumersDataToFE } from '../../helpers';
import ToastifyType from '../../../../utils/toastify-config';
import { ConsumersDataFE } from '../../../../interfaces/uiv2';
import { PAGINATION_SETTINGS } from '../../../../utils/config';
import { getQueryParamsFromObject } from '../../../../utils/table';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../../../interfaces/redux';
import { Consumer, PaginatedApiResponse, ApiPaginationInfo } from '../../../../interfaces/apiv2';

interface ListConsumersState extends DefaultInitialState {
    consumers: Consumer[];
    consumersFE: ConsumersDataFE[];
    selectedRows: ConsumersDataFE[];
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
}

const initialState: ListConsumersState = {
    consumers: [],
    consumersFE: [],
    selectedRows: [],
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    pager: null
};

export const getConsumersListAsync = createAsyncThunk(
    'consumers/list',
    async ({ projectId, getParams }: { getParams: GetParams; projectId: string }, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...getParams });
            const response = await axios.get<PaginatedApiResponse<Consumer>>(
                `/electricity/demand/${projectId}/consumers${queryParams}`
            );
            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);
//

export const importConsumersAsync = createAsyncThunk(
    'consumers/import',
    async (
        { projectId, file, type }: { projectId: string; file: File; type: 'import' | 'historical' },
        { rejectWithValue }
    ) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(`/electricity/demand/${projectId}/consumers/${type}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listConsumers = createSlice({
    name: 'listConsumers',
    initialState,
    reducers: {
        resetListConsumersState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<ConsumersDataFE[]>) => {
            state.selectedRows = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getConsumersListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getConsumersListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.consumers = items;
            } else {
                state.consumers = [...current(state).consumers, ...items];
            }
            state.consumersFE = transformConsumersDataToFE(current(state).consumers);
        });
        builder.addCase(getConsumersListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(importConsumersAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(importConsumersAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(importConsumersAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const {
    resetListConsumersState,
    setGetParams: setGetConsumersParams,
    setSelectedRows: setSelectedRowsConsumers
} = listConsumers.actions;

export default listConsumers.reducer;
