import createVPP from './createVPP';
import listVPP from './listVPP';
import deleteVPP from './deleteVPP';
import vppPage from './vppPage';
import getVPPAssets from './showVPPAssets';

export * from './createVPP';
export * from './deleteVPP';
export * from './listVPP';
export * from './vppPage';
export * from './showVPPAssets';

export default { createVPP, listVPP, deleteVPP, vppPage, getVPPAssets };
