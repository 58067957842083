import {
    Box,
    Grow,
    Paper,
    Avatar,
    Popper,
    Button,
    MenuItem,
    MenuList,
    Typography,
    ClickAwayListener
} from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { MODAL_IDS } from '../../../../modals';
import { User } from '../../../../interfaces/apiv2';
import { stringAvatar } from '../../../../utils/table';
import { commonOnLogout } from '../../../../utils/user';
import { ModalContext } from '../../../../hooks/modalContext';
import { useAppDispatch, useAppSelector } from '../../../../stores';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg';

import style from './style.module.scss';

const buildHeaderName = (user: User) => {
    return user.first_name || user.last_name;
};

const HeaderUserMenu = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);
    const { handleClickOpen } = useContext(ModalContext);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const { user } = useAppSelector((state) => state.login);

    const username = user ? buildHeaderName(user) : '';

    const handleLogout = () => commonOnLogout(dispatch);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const openModal = (modalId: MODAL_IDS) => () => {
        setOpen(false);
        return handleClickOpen(modalId);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Box className={style.container}>
            <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                disableRipple
                className={classNames(style.menu, open && style.menuOpen)}
                sx={{
                    borderColor: '#d5e3ff !important'
                }}
            >
                <Avatar variant="userAccount" className={style.avatar} {...stringAvatar(username)} />
                <Typography variant="small4" className={style.welcome}>{`${t(
                    'header.welcome'
                )} ${username}`}</Typography>
                <ArrowDownIcon className={classNames(style.arrow, open && style.flip)} />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                className={style.dropdown}
                sx={{
                    width: '200px',
                    borderColor: 'transparent !important'
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem
                                        className={style.menuItem}
                                        onClick={openModal(MODAL_IDS.ACCOUNT_SETTINGS_MODAL)}
                                    >
                                        {t('header.settings')}
                                    </MenuItem>
                                    <MenuItem
                                        className={style.menuItem}
                                        onClick={openModal(MODAL_IDS.CLIENT_SUPPORT_MODAL)}
                                    >
                                        {t('header.help')}
                                    </MenuItem>
                                    <MenuItem className={style.menuItem} onClick={handleLogout}>
                                        {t('logout')}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

export default HeaderUserMenu;
