import dayjs from 'dayjs';
import { useState } from 'react';
import classnames from 'classnames';
import en from 'date-fns/locale/en-GB';
import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { DateRange, DayPicker } from 'react-day-picker';
import { getFormattedDate, getUserTimezone } from '../../utils/helpers';
import { ReactComponent as CalendarSVG } from '../../assets/icons/calendar_month.svg';

import 'react-day-picker/dist/style.css';
import style from './style.module.scss';

export interface DateRangeInputProps {
    className?: string;
    label?: string;
    datePickerConfig?: any;
    range: DateRange | null;
    setRange: (e: DateRange | null) => void;
}

export const DateRangeInput = ({ className, label, datePickerConfig = {}, range, setRange }: DateRangeInputProps) => {
    const { t } = useTranslation();
    const [showPicker, setShowPicker] = useState(false);

    const onChange = () => {
        setShowPicker(!showPicker);
    };

    const handleResetRange = () => {
        setRange(null);
    };

    let footer = <Typography variant="small4">{t('pickADay')}</Typography>;
    if (range) {
        footer = (
            <Typography className={style.resetRange} variant="small4" onClick={handleResetRange}>
                {t('table.general.resetPeriod')}
                <MdClose />
            </Typography>
        );
    }

    const timezone = getUserTimezone();

    return (
        <Box className={classnames(className ? className : '', style.container)}>
            <Button
                disableRipple
                disabled={datePickerConfig.componentDisabled}
                variant="outlined"
                onClick={onChange}
                className={classnames(style.selectPeriodBtn, showPicker && style.activeBtn)}
            >
                {range ? (
                    <Typography variant="small4">
                        {getFormattedDate({ value: range.from, timezone })}
                        {' - '}
                        {getFormattedDate({ value: range.to, timezone })}
                    </Typography>
                ) : (
                    <Typography variant="small4">{label ? label : t('table.general.selectPeriod')}</Typography>
                )}
                <CalendarSVG className={style.calendarIcon} />
            </Button>
            {showPicker && (
                <>
                    <Box onClick={() => setShowPicker(false)} className={style.background}></Box>
                    <DayPicker
                        fromYear={2000}
                        toYear={dayjs().get('year') + 5}
                        mode="range"
                        locale={en}
                        className={style.datepicker}
                        selected={range}
                        footer={footer}
                        onSelect={setRange}
                        {...datePickerConfig}
                    />
                </>
            )}
        </Box>
    );
};
