import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../utils/config';
import { User } from '../../../interfaces/apiv2';
import { APP_MODULES } from '../../../interfaces/uiv2';
import { ReactComponent as SwitchModuleIcon } from '../../../assets/icons/navigation-items/dots-menu-switch-module.svg';

import style from '../style.module.scss';

export const SwitchModule = ({
    user,
    activeModule,
    handleTooltipClose
}: {
    user: User;
    activeModule: APP_MODULES;
    handleTooltipClose: (value: boolean) => void;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (!user.superadmin) {
        return <></>;
    }

    const text = `header.${activeModule === APP_MODULES.AI ? APP_MODULES.ADMIN : APP_MODULES.AI}`;

    const switchModule = () => {
        if (activeModule === APP_MODULES.AI) {
            navigate(ROUTES.ADMIN_GENERATION_ASSETS);
        }
        if (activeModule === APP_MODULES.ADMIN) {
            navigate(ROUTES.AI_DASHBOARD);
        }

        handleTooltipClose(true);
    };

    return (
        <Box className={classNames(style.row)} key={'switch'} onClick={switchModule}>
            <Link to={'/'}>
                <Box className={style.icon}>
                    <SwitchModuleIcon />
                </Box>

                <Typography className={style.label} variant="small4">
                    {t(text)}
                </Typography>
            </Link>
        </Box>
    );
};
