import { isEqual } from 'lodash';
import { MdAdd } from 'react-icons/md';
import { Box, Button } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { UserModal } from '../../../../modals/UserModal';
import {
    setGetParams,
    initialState,
    getUsersAsync,
    setSelectedRows,
    inactivateUserAsync,
    resetListUsersState
} from '../../../../stores/Users';
import { getTableHeadCells } from './helpers';
import { useTranslation } from 'react-i18next';
import i18next from '../../../../utils/i18next';
import { UserFE } from '../../../../interfaces/uiv2';
import { MODAL_IDS } from '../../../../modals';
import { ModalContext } from '../../../../hooks/modalContext';
import { resetListAssetsState } from '../../../../stores/Assets';
import { useAppDispatch, useAppSelector } from '../../../../stores';
import { PageContainer } from '../../../../layout/PageContainer';
import { transformOrderByToSort } from '../../../../components/BaseTable/GenericTable/helpers';
import { GenericTable, GenericTableToolbar, TableToolbarActions, TableSearch } from '../../../../components/BaseTable';

const ListUsers = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const { t: tList } = useTranslation('users/list');

    const { success } = useAppSelector((state) => state.createUser);
    const getParams = useAppSelector((state) => state.listUsers.getParams, isEqual);
    const {
        usersFE: rows,
        pager,
        loading,
        selectedRows,
        success: successFetchingData
    } = useAppSelector((state) => state.listUsers);

    const headCells = getTableHeadCells({ tList, t });
    const { open, handleClickOpen } = useContext(ModalContext);
    const [activeRow, setActiveRow] = useState<UserFE | null>(null);
    const resetInit = () => dispatch(getUsersAsync(initialState.getParams));
    const init = useCallback(() => dispatch(getUsersAsync(getParams)), [getParams]);

    useEffect(() => {
        init();
    }, [getParams]);

    useEffect(() => {
        return () => {
            dispatch(resetListUsersState());
        };
    }, []);

    useEffect(() => {
        if (!open[MODAL_IDS.USER_MODAL]) {
            setActiveRow(null);
            if (success) {
                init();
            }
        }
    }, [open[MODAL_IDS.USER_MODAL], success]);

    const handleInactivateAll = () => {
        Promise.all(
            selectedRows.map((row) => dispatch(inactivateUserAsync({ public_id: row.id, state: false })))
        ).finally(() => {
            dispatch(setSelectedRows([]));
            if (getParams.search) {
                init();
            } else {
                resetInit();
            }
        });
    };

    const handleAddNewUser = () => {
        i18next.loadNamespaces('users/create').then(() => {
            handleClickOpen(MODAL_IDS.USER_MODAL);
        });
    };

    const handleEditUser = (row: UserFE) => {
        return () => {
            i18next.loadNamespaces('users/create').then(() => {
                handleClickOpen(MODAL_IDS.USER_MODAL);
                setActiveRow(row);
            });
        };
    };

    return (
        <>
            <PageContainer>
                <GenericTableToolbar tableTitle={tList('tableTitle')}>
                    <Box sx={{ display: selectedRows.length !== 0 ? 'initial' : 'none', mr: 2 }}>
                        <TableToolbarActions
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            deleteActionCallback={handleInactivateAll}
                            deleteActionLabel={t('table.general.deactivateAll')}
                        />
                    </Box>

                    <TableSearch setGetParams={setGetParams} />

                    {selectedRows.length === 0 && (
                        <Button disableRipple variant="cta" onClick={handleAddNewUser} sx={{ ml: 1 }}>
                            <MdAdd />
                        </Button>
                    )}
                </GenericTableToolbar>

                <GenericTable
                    rows={rows}
                    pager={pager}
                    loading={loading}
                    success={successFetchingData}
                    isCheckboxEnabled
                    columns={headCells}
                    rowUniqueId={'id'}
                    selectedRows={selectedRows}
                    setGetParams={setGetParams}
                    handleEdit={handleEditUser}
                    searchParam={getParams.search}
                    setSelectedRows={setSelectedRows}
                    cleanupCb={() => dispatch(resetListAssetsState())}
                    storeSort={transformOrderByToSort(getParams.order_by)}
                />
            </PageContainer>

            <UserModal user={activeRow} />
        </>
    );
};

export default ListUsers;
