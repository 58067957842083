import dayjs from 'dayjs';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import ToastifyType from '../../utils/toastify-config';
import { ApiPaginationInfo, GenerationAsset } from '../../interfaces/apiv2';
import { PAGINATION_SETTINGS } from '../../utils/config';
import {
    ForecastDataGetParams,
    ForecastFE,
    GenerationAssetFE,
    GenerationTableForecastDataPayloadSerialized
} from '../../interfaces/uiv2';
import { transformGenerationAssetAPItoFE } from './helpers';
import { getAssetPageChartThunk } from '../../utils/common-thunks';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DefaultInitialState, DEFAULT_GET_PARAMS_VALUE } from '../../interfaces/redux';
import { sortForecastData, transformForecastToTable } from '../../utils/forecast';
import { DownloadExtension } from '../../components/DownloadButton';
import { getQueryParamsFromObject } from '../../utils/table';
import fileDownload from 'js-file-download';
import { transformGenerationForecastToView, transformGenerationMeasuredToView } from '../../utils/timeseries-data';

interface AssetPage extends DefaultInitialState {
    asset: GenerationAsset | null;
    assetFE: GenerationAssetFE | null;
    download: boolean;
    forecasts: LineData[][];
    generationForecasts: ForecastFE[];
    paginatedData: ForecastFE[];
    errorForecast: boolean;
    loadingForecast: boolean;
    successForecast: boolean;
    errorMeasured: boolean;
    loadingDownload: boolean;
    loadingMeasured: boolean;
    successMeasured: boolean;
    limitLeftForecastBoundary: boolean;
    limitLeftMeasuredBoundary: boolean;
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
    generationTablePayloadTemp: GenerationTableForecastDataPayloadSerialized | null;
}

export const getAssetAsync = createAsyncThunk<GenerationAsset, { id: string }>(
    'asset_page/show',
    async (values: { id: string }, { rejectWithValue }) => {
        try {
            const endpoint = `/electricity/generation/assets`;

            const response = await axios.get<GenerationAsset>(`${endpoint}/${values.id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const downloadAssetData = createAsyncThunk(
    'assetPage/download',
    async (
        {
            asset,
            ...rest
        }: { asset: GenerationAssetFE; from_datetime: string; to_datetime: string; file_type: DownloadExtension },
        { rejectWithValue }
    ) => {
        toast.success(i18next.t('downloadStarted'), ToastifyType.success);
        try {
            const url = `/electricity/generation/assets/${asset.id}/forecast/download${getQueryParamsFromObject({
                ...rest
            })}`;
            const response = await axios.get(url, {
                responseType: 'blob'
            });

            const extension = rest.file_type === DownloadExtension.Excel ? 'xlsx' : rest.file_type.toLowerCase();

            fileDownload(
                response.data,
                `${asset.name}__${new Date(Number(rest.from_datetime)).toISOString()}__${new Date(
                    Number(rest.to_datetime)
                ).toISOString()}.${extension}`
            );
            toast.success(i18next.t('downloadSuccess'), ToastifyType.success);
        } catch (e) {
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getForecastAsync = getAssetPageChartThunk({ thunkName: 'asset_page/forecast', endpoint: 'forecast' });

export const getGenerationForecastAsync = getAssetPageChartThunk({
    thunkName: 'asset_page/generation_forecast',
    endpoint: 'forecast'
});

export const getMeasuredAsync = getAssetPageChartThunk({
    thunkName: 'asset_page/measured',
    endpoint: 'historical'
});

export const getMoreForecastAsync = getAssetPageChartThunk({
    thunkName: 'asset_page/forecast_more',
    endpoint: 'forecast'
});

export const getMoreMeasuredAsync = getAssetPageChartThunk({
    thunkName: 'asset_page/measured_more',
    endpoint: 'historical'
});

const initialState: AssetPage = {
    loading: false,
    error: false,
    success: false,
    asset: null,
    assetFE: null,
    download: false,
    forecasts: [[], []],
    generationForecasts: [],
    errorForecast: false,
    loadingForecast: false,
    successForecast: false,
    loadingDownload: false,
    errorMeasured: false,
    loadingMeasured: false,
    successMeasured: false,
    limitLeftForecastBoundary: false,
    limitLeftMeasuredBoundary: false,
    pager: null,
    paginatedData: [],
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        // order_by: ['-created_on']
        order_by: undefined
    },
    generationTablePayloadTemp: null
};

export const assetPage = createSlice({
    name: 'assetPage',
    initialState,
    reducers: {
        resetAssetPage: () => initialState,
        setActiveAsset: (state, { payload }: PayloadAction<GenerationAsset | null>) => {
            state.asset = payload;
        },
        downloadChart: (state, { payload }) => {
            state.download = payload;
        },
        resetForecastData: (state) => {
            state.forecasts = [[], []];
            state.errorForecast = false;
            state.loadingForecast = false;
            state.successForecast = false;
            state.errorMeasured = false;
            state.loadingMeasured = false;
            state.successMeasured = false;
            state.limitLeftForecastBoundary = false;
            state.limitLeftMeasuredBoundary = false;
        },
        loadMore: (state) => {
            const { generationForecasts, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: generationForecasts.length
            };
            state.paginatedData = generationForecasts.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        },
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setGenerationTablePayloadTemp: (state, { payload }) => {
            state.generationTablePayloadTemp = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(downloadAssetData.pending, (state) => {
            state.loadingDownload = true;
        });
        builder.addCase(downloadAssetData.fulfilled, (state) => {
            state.loadingDownload = false;
        });
        builder.addCase(downloadAssetData.rejected, (state) => {
            state.loadingDownload = false;
        });
        builder.addCase(getAssetAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getAssetAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.asset = payload;
            state.assetFE = transformGenerationAssetAPItoFE([payload])[0];
        });
        builder.addCase(getAssetAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;

            const actualData = (current(state).forecasts || [])[0] || [];
            state.forecasts[0] = sortForecastData([
                ...transformGenerationForecastToView({
                    data: payload,
                    country: current(state).asset?.country_code,
                    valueKey: 'value',
                    totalCapacity: current(state).asset?.capacity_value || 0
                }),
                ...actualData
            ]);

            const firstGeneratedForecastTimestamp = dayjs(current(state).asset?.first_generation_timestamp);
            const firstReceivedForecast = dayjs(+payload[0]?.timestamp);
            const firstTimestamp = Math.round(new Date().getTime() / 1000);

            if (
                firstReceivedForecast.isBefore(firstGeneratedForecastTimestamp) ||
                dayjs(+firstTimestamp * 1000).isBefore(firstGeneratedForecastTimestamp)
            ) {
                state.limitLeftForecastBoundary = true;
            }
        });
        builder.addCase(getForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
        builder.addCase(getGenerationForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getGenerationForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            state.generationForecasts = transformForecastToTable(payload, current(state).asset?.country_code);

            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).generationForecasts.length
            };
            state.paginatedData = current(state).generationForecasts.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
        });
        builder.addCase(getGenerationForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
        builder.addCase(getMeasuredAsync.pending, (state) => {
            state.loadingMeasured = true;
            state.errorMeasured = false;
            state.successMeasured = false;
        });
        builder.addCase(getMeasuredAsync.fulfilled, (state, { payload }) => {
            state.loadingMeasured = false;
            state.errorMeasured = false;
            state.successMeasured = true;
            const actualData = (current(state).forecasts || [])[1] || [];

            const firstGeneratedForecastTimestamp = dayjs(current(state).asset?.first_forecast_timestamp);
            const firstReceivedForecast = dayjs(+payload[1]?.timestamp);
            const firstTimestamp = Math.round(new Date().getTime() / 1000);

            if (payload.length) {
                state.forecasts[1] = sortForecastData([
                    ...transformGenerationMeasuredToView({
                        data: payload,
                        valueKey: 'value',
                        totalCapacity: current(state).asset?.capacity_value || 0,
                        country: current(state).asset?.country_code
                    }),
                    ...actualData
                ]);
            }

            if (
                firstReceivedForecast.isBefore(firstGeneratedForecastTimestamp) ||
                dayjs(+firstTimestamp * 1000).isBefore(firstGeneratedForecastTimestamp)
            ) {
                state.limitLeftMeasuredBoundary = true;
            }
        });
        builder.addCase(getMeasuredAsync.rejected, (state) => {
            state.loadingMeasured = false;
            state.errorMeasured = true;
            state.successMeasured = false;
        });

        builder.addCase(getMoreForecastAsync.fulfilled, (state, { payload }) => {
            const actualData = (current(state).forecasts || [])[0] || [];
            state.forecasts[0] = sortForecastData([
                ...transformGenerationForecastToView({
                    data: payload,
                    country: current(state).asset?.country_code,
                    valueKey: 'value',
                    totalCapacity: current(state).asset?.capacity_value || 0
                }),
                ...actualData
            ]);

            const firstGeneratedForecastTimestamp = dayjs(current(state).asset?.first_generation_timestamp);
            const firstReceivedForecast = dayjs(+payload[0]?.timestamp);
            const firstTimestamp = state.forecasts[0][0].time;

            if (
                firstReceivedForecast.isBefore(firstGeneratedForecastTimestamp) ||
                dayjs(+firstTimestamp * 1000).isBefore(firstGeneratedForecastTimestamp)
            ) {
                state.limitLeftForecastBoundary = true;
            }
        });

        builder.addCase(getMoreMeasuredAsync.fulfilled, (state, { payload }) => {
            const actualData = (current(state).forecasts || [])[1] || [];

            const firstGeneratedForecastTimestamp = dayjs(current(state).asset?.first_forecast_timestamp);
            const firstReceivedForecast = dayjs(+payload[1]?.timestamp);
            const firstTimestamp = current(state).forecasts[1]?.[0]?.time;

            if (payload.length) {
                state.forecasts[1] = sortForecastData([
                    ...transformGenerationMeasuredToView({
                        data: payload,
                        valueKey: 'value',
                        totalCapacity: current(state).asset?.capacity_value || 0,
                        country: current(state).asset?.country_code
                    }),
                    ...actualData
                ]);
            }

            if (
                firstTimestamp &&
                (firstReceivedForecast.isBefore(firstGeneratedForecastTimestamp) ||
                    dayjs(+firstTimestamp * 1000).isBefore(firstGeneratedForecastTimestamp))
            ) {
                state.limitLeftMeasuredBoundary = true;
            }
        });
    }
});

export const {
    resetAssetPage,
    setActiveAsset,
    downloadChart,
    setGenerationTablePayloadTemp,
    resetForecastData,
    loadMore,
    setGetParams: setGetParamsAssetPage
} = assetPage.actions;

export default assetPage.reducer;
