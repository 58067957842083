import { useMemo, useState } from 'react';

export const useModalControls = ({ modalIds }: { modalIds: string[] }) => {
    const modals: { [key: string]: boolean } = useMemo(
        () =>
            modalIds.reduce((acc, item) => {
                return {
                    ...acc,
                    [item]: false
                };
            }, {}),
        [modalIds]
    );

    const [open, setOpen] = useState(modals);
    const [payload, setPayload] = useState(null);

    const handleClickOpen = (id: string, data?: any) => {
        if (id) {
            setOpen((prevM) => ({ ...prevM, [id]: true }));
        }

        if (data) {
            setPayload(data);
        }
    };

    const handleClose = (id: string) => {
        if (id) {
            setOpen((prevM) => ({ ...prevM, [id]: false }));
        }
    };

    return {
        open,
        handleClickOpen,
        handleClose,
        payload
    };
};
