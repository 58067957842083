import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { GetParams } from '../../interfaces/general';
import ToastifyType from '../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ChargingStationsFE } from '../../interfaces/uiv2';
import { getQueryParamsFromObject } from '../../utils/table';
import { transformChargingStationsDataToFE } from './helpers';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { PaginatedApiResponse, ApiPaginationInfo, ChargingStations } from '../../interfaces/apiv2';

interface ListChargingStationsState extends DefaultInitialState {
    stations: ChargingStations[];
    stationsFE: ChargingStationsFE[];
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
}

const initialState: ListChargingStationsState = {
    stations: [],
    stationsFE: [],
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    pager: null
};

export const getChargingStationsListAsync = createAsyncThunk(
    'evs-charging-stations/list',
    async ({ projectId, getParams }: { getParams: GetParams; projectId: string }, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...getParams });
            const response = await axios.get<PaginatedApiResponse<ChargingStations>>(
                `/evs/${projectId}/charging-stations${queryParams}`
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listChargingStations = createSlice({
    name: 'listChargingStations',
    initialState,
    reducers: {
        resetChargingStations: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getChargingStationsListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getChargingStationsListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.stations = items;
            } else {
                state.stations = [...current(state).stations, ...items];
            }
            state.stationsFE = transformChargingStationsDataToFE(current(state).stations);
        });
        builder.addCase(getChargingStationsListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetChargingStations, setGetParams: setGetChargingStationsParams } = listChargingStations.actions;

export default listChargingStations.reducer;
