import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import {
    ForecastDeliverySettings,
    PatchForecastDeliverySettings,
    PostForecastDeliverySettings,
    User
} from '../../interfaces/apiv2';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { ForecastExternalEmailDeliveryFormFields } from '../../interfaces/uiv2';

interface ForecastDeliverySettingsState extends DefaultInitialState {
    forecastDeliverySettings: ForecastDeliverySettings | null;
    internalEmails: User[];
    externalEmails: ForecastExternalEmailDeliveryFormFields[];
    emailListIsDirty: boolean;
}

const initialState: ForecastDeliverySettingsState = {
    loading: false,
    error: false,
    success: false,
    forecastDeliverySettings: null,
    internalEmails: [],
    externalEmails: [],
    emailListIsDirty: false
};

export const getForecastDeliverySettingsAsync = createAsyncThunk(
    'forecastDeliverySettings/list',
    async ({ assetId }: { assetId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ForecastDeliverySettings>(
                `/electricity/generation/assets/${assetId}/forecast-delivery-settings`
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const createForecastDeliverySettingsAsync = createAsyncThunk(
    'forecastDeliverySettings/create',
    async ({ assetId, body }: { assetId: string; body: PostForecastDeliverySettings }, { rejectWithValue }) => {
        try {
            const response = await axios.post<ForecastDeliverySettings>(
                `/electricity/generation/assets/${assetId}/forecast-delivery-settings`,
                body
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const updateForecastDeliverySettingsAsync = createAsyncThunk(
    'forecastDeliverySettings/update',
    async ({ assetId, body }: { assetId: string; body: PatchForecastDeliverySettings }, { rejectWithValue }) => {
        try {
            const response = await axios.patch<ForecastDeliverySettings>(
                `/electricity/generation/assets/${assetId}/forecast-delivery-settings`,
                body
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const forecastDeliverySettings = createSlice({
    name: 'forecastDeliverySettings',
    initialState,
    reducers: {
        resetForecastDeliverySettingsState: () => initialState,
        setInternalEmails: (state, { payload }: PayloadAction<User[]>) => {
            state.internalEmails = payload;
        },
        setExternalEmails: (state, { payload }: PayloadAction<ForecastExternalEmailDeliveryFormFields[]>) => {
            state.externalEmails = payload;
        },
        setEmailListDirtyState: (state, { payload }: PayloadAction<boolean>) => {
            state.emailListIsDirty = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getForecastDeliverySettingsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getForecastDeliverySettingsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.forecastDeliverySettings = payload;
        });
        builder.addCase(getForecastDeliverySettingsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });

        builder.addCase(createForecastDeliverySettingsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(createForecastDeliverySettingsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.forecastDeliverySettings = payload;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(createForecastDeliverySettingsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(updateForecastDeliverySettingsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(updateForecastDeliverySettingsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.forecastDeliverySettings = payload;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(updateForecastDeliverySettingsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetForecastDeliverySettingsState, setInternalEmails, setExternalEmails, setEmailListDirtyState } =
    forecastDeliverySettings.actions;

export default forecastDeliverySettings.reducer;
