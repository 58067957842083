import { TFunction } from 'i18next';
import { Box } from '@mui/material';
import { ProjectsDataFE } from '../../interfaces/uiv2';
import { getFormattedDate } from '../../utils/helpers';
import { TableCellRenderer } from '../../interfaces/general';
import { PROJECT_MODULE, PROJECT_TYPE } from '../../interfaces/apiv2';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { DefaultSearchableCell, TableActions } from '../../components/BaseTable';
import AssetStatusLabel from '../../components/AssetStatusLabel/AssetStatusLabel';

import { ReactComponent as GasIcon } from '../../assets/icons/projects/gas-type.svg';
import { ReactComponent as HydroIcon } from '../../assets/icons/projects/hydro-type.svg';
import { ReactComponent as HeatingIcon } from '../../assets/icons/projects/heating-type.svg';
import { ReactComponent as ElectricityIcon } from '../../assets/icons/projects/electricty-type.svg';

import { ReactComponent as DemandIcon } from '../../assets/icons/navigation-items/menu-item-demand.svg';
import { ReactComponent as NTLIcon } from '../../assets/icons/navigation-items/menu-item-ntl.svg';
import { ReactComponent as AnomalyIcon } from '../../assets/icons/navigation-items/menu-item-anomaly.svg';
import { ReactComponent as TransmissionIcon } from '../../assets/icons/navigation-items/menu-item-transmission.svg';

import style from './style.module.scss';

export const getProjectModuleIcon = (type: PROJECT_MODULE) => {
    switch (type) {
        case PROJECT_MODULE.GAS:
            return <GasIcon />;
        case PROJECT_MODULE.HYDRO:
            return <HydroIcon />;
        case PROJECT_MODULE.ELECTRICITY:
            return <ElectricityIcon />;
        case PROJECT_MODULE.HEATING:
            return <HeatingIcon />;
        default:
            return <ElectricityIcon />;
    }
};

export const getProjectTypeIcon = (type: PROJECT_TYPE) => {
    switch (type) {
        case PROJECT_TYPE.DEMAND_FORECAST:
            return <DemandIcon />;
        case PROJECT_TYPE.NTL:
            return <NTLIcon />;
        case PROJECT_TYPE.TL:
            return <TransmissionIcon />;
        case PROJECT_TYPE.ANOMALY:
            return <AnomalyIcon />;
        default:
            return <DemandIcon />;
    }
};

export const getProjectsHeadCells = (tList: TFunction): TableCellRenderer<ProjectsDataFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 40,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'module',
            maxWidth: 100,
            label: tList('tableHead.module'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                return getProjectModuleIcon(row.module);
            }
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            maxWidth: 150,
            sort: true,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.name;
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;

                return (
                    <DefaultSearchableCell
                        value={value}
                        searchValue={searchValue}
                        onClick={callback}
                        variant={'small3'}
                    />
                );
            }
        },
        {
            id: 'type',
            label: tList('tableHead.type'),
            maxWidth: 150,
            sort: true,
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.type} searchValue={searchValue} />;
            }
        },
        {
            id: 'client_id',
            label: tList('tableHead.clients'),
            sort: true,
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.client?.name || ''} searchValue={searchValue} />;
            }
        },
        {
            id: 'created_at',
            label: tList('tableHead.date'),
            sort: true,
            maxWidth: 150,
            value: (row, searchValue) => {
                return (
                    <DefaultSearchableCell
                        value={getFormattedDate({ value: row.created_at })}
                        searchValue={searchValue}
                    />
                );
            }
        },
        {
            id: 'status',
            label: tList('tableHead.status'),
            maxWidth: 100,
            sort: true,
            value: (row) => {
                return <AssetStatusLabel status={row.status} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('tableHead.actions'),
            value: (row, searchValue, onClick, handlers) => {
                const edit = handlers?.['edit'];
                const editPermissions = handlers?.['editPermissions'];
                const actions = [];
                if (edit) {
                    actions.push(
                        {
                            id: 'edit',
                            label: 'table.general.editInfo',
                            disabled: false,
                            callback: () => {
                                edit(row)();
                            }
                        },
                        {
                            id: 'editPermissions',
                            label: 'table.general.editPermissions',
                            disabled: false,
                            callback: () => {
                                editPermissions(row)();
                            }
                        }
                    );
                }
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions className={style.editActionStyling} actions={actions} />
                    </Box>
                );
            }
        }
    ];
};
