export const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

export const stringAvatar = (name: string, join = '') => {
    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: name
            .split(' ')
            .filter((x) => x)
            .map((x) => x[0])
            .join(join)
    };
};

// TODO: to be removed
export const getModuleImage = (module: string) => {
    switch (module) {
        case 'gas':
            return '/assets/icon/gas.svg';
        case 'water':
            return '/assets/icon/water.svg';
        case 'heating':
            return '/assets/icon/heating.svg';
        case 'electricity':
            return '/assets/icon/electricity.svg';
        default:
            return '';
    }
};

// todo: types <T>(apiData: PaginatinatedApiResponse<T>): T[]
export const getDataFromApiResponse = (apiData: any) => {
    return apiData?.result?.length !== undefined ? apiData.result : apiData;
};

export const getSplitFromSearch = (searchValue: string, value: string) => {
    const escaped = searchValue.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    const reg = new RegExp(`${escaped}(.*)`, 'si');
    let split = value.split(reg);

    const indexOf = value.toLowerCase().indexOf(searchValue.toLowerCase());
    const valToReplace = indexOf !== -1 ? value.substring(indexOf, indexOf + searchValue.length) : searchValue;

    if (split[0] === '') {
        split[0] = valToReplace;
    } else if (split[1] === '') {
        split[1] = valToReplace;
    } else {
        split = [split[0], valToReplace, split[1]];
    }

    return split.filter((x) => x);
};

export const getMatchLength = (valueSplit: string[], searchValue: string) => {
    return valueSplit
        .map((s) => (searchValue.toLowerCase().includes(s.toLowerCase()) ? s.length : -1))
        .reduce((acc, item) => (item > acc ? item : acc), -1);
};

export const getSearchMatchClass = (searchValue: string, element: string, matchLength: number) => {
    return searchValue.toLowerCase().includes(element.toLowerCase()) && matchLength === element.length
        ? 'TableSearchHighlightResult'
        : '';
};

export const replaceWhiteSpace = (value: string) => value.replace(/\s/g, '&nbsp;');

export const isSearchable = (value: string, searchValue: string | null) =>
    searchValue?.trim() && value?.toLowerCase()?.includes(searchValue?.toLowerCase());

interface QueryTransformParams {
    [key: string]: unknown;
}

export const getQueryParamsFromObject = (params: QueryTransformParams) => {
    let queryParams = '';
    if (!params) {
        return queryParams;
    }

    function handleData(data: unknown, key: string, q: string) {
        // handle strings
        if ((typeof data === 'string' || data instanceof String) && data.length > 0) {
            if (q === '') {
                q += '?';
            }
            q += `${q.length > 1 ? '&' : ''}${key}=${encodeURIComponent(data as string)}`;
        }

        // handle numbers and booleans
        if (!isNaN(data as number) && data !== null && typeof data !== 'string') {
            if (q === '') {
                q += '?';
            }
            q += `${q.length > 1 ? '&' : ''}${key}=${encodeURIComponent(data as number)}`;
        }

        // handle arrays
        if (Array.isArray(data)) {
            data.forEach((value) => {
                q = handleData(value, key, q);
            });
        }

        return q;
    }

    // check to see if params is an object
    if (typeof params === 'object' && !Array.isArray(params) && params !== null) {
        if (Object.keys(params).length === 0) {
            return queryParams;
        }
        Object.keys(params)
            .filter((key) => key !== 'order_by')
            .forEach((key) => {
                queryParams = handleData(params[key], key, queryParams);
            });

        if (params?.order_by) {
            queryParams += `&order_by=${((params?.order_by || []) as string[]).join(',')}`;
        }
    } else {
        console.error('Error transformParamsToQueryParams: params is not an object');
    }

    return queryParams;
};

export function ddToDms(lat: number, lng: number) {
    const latDirection = lat >= 0 ? 'N' : 'S';
    const latFormatted = [getDms(lat), latDirection].join(' ');

    const lngDirection = lng >= 0 ? 'E' : 'W';
    const lngFormatted = [getDms(lng), lngDirection].join(' ');

    return [latFormatted, lngFormatted].join(' ');
}

function getDms(val: number) {
    const value = Math.abs(val);

    const valDeg = Math.floor(value);
    const valMin = Math.floor((value - valDeg) * 60);
    const valSec = Math.round((value - valDeg - valMin / 60) * 3600 * 1000) / 1000;

    return `${valDeg}° ${valMin}′ ${valSec}″`;
}

export const DEFAULT_EMPTY_CELL_VALUE = '-';
export const DEFAULT_CAPACITY_UNIT = 'MW';
