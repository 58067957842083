import i18next from 'i18next';
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { GasProfile } from '../../../../interfaces/apiv2';
import ToastifyType from '../../../../utils/toastify-config';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../../../interfaces/redux';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { GetParams } from '../../../../interfaces/general';
import axios from '../../../../utils/axios';

interface GasProfilePage extends DefaultInitialState {
    profile: GasProfile | null;
    getParams: GetParams;
}

const initialState: GasProfilePage = {
    profile: null,
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE
};

export const getGasProfileAsync = createAsyncThunk(
    'gas-profiles/show',
    async (values: { id: string; projectId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<GasProfile>(`/gas/demand/${values.projectId}/profiles/${values.id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const gasProfilePage = createSlice({
    name: 'gasProfilePage',
    initialState,
    reducers: {
        resetGasProfilePageState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getGasProfileAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getGasProfileAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.profile = payload;
        });
        builder.addCase(getGasProfileAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetGasProfilePageState, setGetParams: setGetGasProfileParams } = gasProfilePage.actions;

export default gasProfilePage.reducer;
