import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { Autocomplete, Divider, InputLabel, SvgIcon, TextField, Typography } from '@mui/material';

import { MODAL_IDS } from '..';
import { useAppSelector } from '../../stores';
import { VPPFE } from '../../interfaces/uiv2';
import { FormModalActions } from '../../components/FormUtils';
import { ModalContext } from '../../hooks/modalContext';
import { ReactComponent as VPPSVG } from '../../assets/icons/vpp.svg';

import style from './style.module.scss';

const DeleteVppConfirmationForm = () => {
    const { payload, handleClose } = useContext(ModalContext);
    const { t: generalTranslation } = useTranslation();
    const { t } = useTranslation('components/delete-card-modal');

    const { loading } = useAppSelector((state) => state.deleteVPP);

    const [open, setOpen] = useState(false);
    const [selectedAsset, setAsset] = useState<VPPFE | null>(
        payload?.rows.find((e: VPPFE) => e.id === payload?.item.id)
    );

    const handleIdChange = (e: VPPFE) => () => {
        setAsset(e);
        setOpen(false);
    };

    const handleChange = () => {
        setAsset(null);
        setOpen(false);
    };

    const handleSubmit = () => {
        if (payload && payload.deleteItem) {
            payload.deleteItem();
            handleClose(MODAL_IDS.DELETE_VPP_CONFIRMATION_MODAL);
        }
    };

    return (
        <Box className={style.box}>
            <Typography className={style.title} variant="h6">
                {t('delete')}
            </Typography>

            <Typography variant="body1" className={style.label}>
                {t('label')}
            </Typography>

            <InputLabel variant="standard" required shrink htmlFor={'message'}>
                {t('vpp')}
            </InputLabel>
            <Autocomplete
                value={selectedAsset}
                id="asynchronous-assets"
                className={open ? style.autocompleteOpen : ''}
                onChange={handleChange}
                disabled
                noOptionsText={generalTranslation('select.noOptions')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={payload?.rows || []}
                getOptionLabel={(option) => option.name}
                renderOption={(props: any, e: VPPFE) => (
                    <Box component="li" {...props} key={Math.random()} onClick={handleIdChange(e)}>
                        <SvgIcon component={VPPSVG} sx={{ '> path': e.is_implicit ? { fill: '#E5253D' } : {} }} />
                        {e.name}
                    </Box>
                )}
                ListboxProps={{ style: { maxHeight: '180px' } }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: t('selectVpp')
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                            startAdornment: (
                                <SvgIcon
                                    component={VPPSVG}
                                    sx={{ '> path': selectedAsset?.is_implicit ? { fill: '#E5253D' } : {} }}
                                />
                            )
                        }}
                    />
                )}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                clearOnEscape={true}
                clearOnBlur={true}
                filterOptions={(x) => x}
            />

            <Divider className={style.line} />

            <FormModalActions
                loading={loading}
                disabled={!selectedAsset}
                modalId={MODAL_IDS.DELETE_VPP_CONFIRMATION_MODAL}
                cancelLabel={generalTranslation('nevermind')}
                saveLabel={generalTranslation('table.general.delete')}
                onSubmit={handleSubmit}
            />
        </Box>
    );
};

export default DeleteVppConfirmationForm;
