import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { GetParams } from '../../interfaces/general';
import ToastifyType from '../../utils/toastify-config';
import { getDownloadThunk } from '../../utils/common-thunks';
import { getQueryParamsFromObject } from '../../utils/table';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { ApiPaginationInfo, PaginatedApiResponse, Report } from '../../interfaces/apiv2';
import { ReportFE, ReportsListGetParams } from '../../interfaces/uiv2';
import { transformReportsAPItoFE } from './helpers';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { PAGINATION_SETTINGS } from '../../utils/config';

interface ListReportsState extends DefaultInitialState {
    reports: Report[];
    reportsFE: ReportFE[];
    searchParam: string | null;
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
    selectedRows: ReportFE[];
    loadingDownload: boolean;
    activeDownloadMeta: { ids: string[]; type: string } | null;
}

const initialState: ListReportsState = {
    reports: [],
    reportsFE: [],
    loading: false,
    error: false,
    success: false,
    pager: null,
    searchParam: null,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    selectedRows: [],

    loadingDownload: false,
    activeDownloadMeta: null
};

export const getReportsListAsync = createAsyncThunk<PaginatedApiResponse<Report>, GetParams, { state: RootState }>(
    'reports/list',
    async (values: GetParams, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...values });
            const endpoint = '/electricity/generation/assets/reports';
            const response = await axios.get<PaginatedApiResponse<Report>>(`${endpoint}${queryParams}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const downloadReport = getDownloadThunk({
    thunkName: 'report_list/download',
    endpoint: '/electricity/generation/assets/reports/files/download',
    bodyPropName: 'report_ids',
    filename: 'report'
});

export const listReports = createSlice({
    name: 'listReports',
    initialState,
    reducers: {
        resetListReportsState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ReportsListGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<any[]>) => {
            state.selectedRows = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getReportsListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getReportsListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.reports = items;
            } else {
                state.reports = [...current(state).reports, ...items];
            }
            state.reportsFE = transformReportsAPItoFE(current(state).reports);
        });
        builder.addCase(getReportsListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(downloadReport.pending, (state, { meta }) => {
            state.loadingDownload = true;
            state.activeDownloadMeta = meta.arg;
        });
        builder.addCase(downloadReport.fulfilled, (state) => {
            state.loadingDownload = false;
            state.activeDownloadMeta = null;
        });
        builder.addCase(downloadReport.rejected, (state) => {
            state.loadingDownload = false;
            state.activeDownloadMeta = null;
        });
    }
});

export const { resetListReportsState, setGetParams, setSelectedRows } = listReports.actions;

export default listReports.reducer;
