import { isEqual } from 'lodash';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { DateRange } from 'react-day-picker';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MdSchedule, MdTrendingUp, MdRefresh, MdDeleteOutline } from 'react-icons/md';

import {
    setGetParams,
    setSelectedRows,
    resetForecastsList,
    deleteForecastsListAsync,
    getGasForecastsListAsync
} from '../../stores/Forecasts';
import { MODAL_IDS } from '../../modals';
import { getGasTableHeadCells } from './helpers';
import { useIsMount } from '../../hooks/isMount';
import { ModalContext } from '../../hooks/modalContext';
import useStickyHeader from '../../hooks/useStickyHeader';
import { PageContainer } from '../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../stores';
import { GenericTable, TableSearch } from '../../components/BaseTable';
import { DateRangeInput } from '../../components/DateRangeInput/DateRangeInput';
import { RemoveForecastContainer } from '../../components/RemoveEntityContainer';
import { DownloadButton, DownloadExtension } from '../../components/DownloadButton';
import { transformOrderByToSort } from '../../components/BaseTable/GenericTable/helpers';
import { getForecastFilteringFormattedDate, PAGINATION_SETTINGS } from '../../utils/config';

import style from './forecast-list.module.scss';

export const GasForecastList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isFirstRender = useIsMount();
    const { handleClickOpen } = useContext(ModalContext);
    const [dateRange, setDateRange] = useState<DateRange | null>(null);

    const { projectId = '' } = useParams();

    useStickyHeader('forecast-list-header');

    const headCells = getGasTableHeadCells(t, projectId);
    const getParams = useAppSelector((state) => state.listForecasts.getParams, isEqual);
    const {
        selectedRows,
        gasForecastsFE: rows,
        loading,
        pager,
        success
    } = useAppSelector((state) => state.listForecasts);

    useEffect(() => {
        if (dateRange?.from && dateRange?.to) {
            dispatch(
                setGetParams({
                    from_date__gte: getForecastFilteringFormattedDate(dateRange.from),
                    to_date__lte: getForecastFilteringFormattedDate(dateRange.to),
                    page: PAGINATION_SETTINGS.default_start_page
                })
            );
        }

        if (!dateRange && !isFirstRender) {
            dispatch(
                setGetParams({
                    from_date__gte: undefined,
                    to_date__lte: undefined,
                    page: PAGINATION_SETTINGS.default_start_page
                })
            );
        }
    }, [dateRange]);

    useEffect(() => {
        dispatch(getGasForecastsListAsync({ getParams, projectId }));
    }, [getParams]);

    const handleSort = (type: string) => () => {
        dispatch(setGetParams({ order_by: [type], page: PAGINATION_SETTINGS.default_start_page }));
    };

    const clearSelection = () => {
        dispatch(setSelectedRows([]));
    };

    const removeAll = () => {
        dispatch(deleteForecastsListAsync(selectedRows.map((a) => a.id)));
        clearSelection();
    };

    const openConfirmationModal = () => {
        handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    };

    const filename = useMemo(() => {
        return selectedRows.reduce((prev, crt) => prev + `_${crt.forecast_settings.asset.name}`, 'Ogre_Forecasts');
    }, [selectedRows]);

    const ids = useMemo(() => {
        return selectedRows.map((e) => e.id);
    }, [selectedRows]);

    return (
        <PageContainer>
            <Box id="forecast-list-header" className={style.header}>
                <Typography variant="h6" className={style.title}>
                    {t('title.forecasts')}
                </Typography>
                {selectedRows.length ? (
                    <Box className={style.toolbar}>
                        {/* selected items toolbar */}

                        <Typography variant="small4" className={style.label}>
                            {selectedRows.length} {t('table.general.selected')}
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <MdRefresh />
                            <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                {t('table.general.clearSelection')}
                            </Box>
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <MdDeleteOutline />
                            <Box
                                component="span"
                                className={style.primary}
                                onClick={openConfirmationModal}
                                sx={{ ml: 1 }}
                            >
                                {t('table.general.removeAll')}
                            </Box>
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <Box
                                component="span"
                                className={classnames(style.primary, style.nodecoration)}
                                sx={{ ml: 1, mr: 1 }}
                            >
                                {t('table.general.downloadAll')}
                            </Box>

                            <DownloadButton id={ids} type={DownloadExtension.Pdf} filename={filename} />
                            <DownloadButton id={ids} type={DownloadExtension.Excel} filename={filename} />
                            <DownloadButton id={ids} type={DownloadExtension.Csv} filename={filename} />
                        </Typography>
                        <TableSearch setGetParams={setGetParams} getParams={getParams} />
                    </Box>
                ) : (
                    <>
                        {/* Non selected toolbar */}
                        <Box className={style.toolbar}>
                            <Typography variant="small4" className={style.label}>
                                {t('toolbar.sort')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdSchedule />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={handleSort('-created_at')}
                                    sx={{ ml: 1 }}
                                >
                                    {t('toolbar.mostRecent')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdTrendingUp />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={handleSort('created_at')}
                                    sx={{ ml: 1 }}
                                >
                                    {t('toolbar.oldestToNewest')}
                                </Box>
                            </Typography>
                            <DateRangeInput
                                range={dateRange}
                                setRange={setDateRange}
                                label={t('table.general.selectPeriod')}
                                className={style.selectPeriod}
                            />
                            <TableSearch setGetParams={setGetParams} getParams={getParams} />
                        </Box>
                    </>
                )}
            </Box>

            <GenericTable
                rows={rows}
                pager={pager}
                loading={loading}
                success={success}
                columns={headCells}
                cleanupCb={() => dispatch(resetForecastsList())}
                rowUniqueId={'id'}
                setGetParams={setGetParams}
                searchParam={getParams.search}
                storeSort={transformOrderByToSort(getParams.order_by)}
            />

            <RemoveForecastContainer cb={removeAll} stateAccessor="deleteForecasts" />
        </PageContainer>
    );
};
