import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';
import i18next from '../../../utils/i18next';
import ToastifyType from '../../../utils/toastify-config';
import { DefaultInitialState } from '../../../interfaces/redux';
import { commonReduxErrorHandler } from '../../../utils/error-handling';
import { AssetLocation } from '../../../interfaces/apiv2';
import { PatchAssetLocationsBody } from '../../../interfaces/uiv2';

interface editAssetLocationsState extends DefaultInitialState {
    edited: number;
}

const initialState: editAssetLocationsState = {
    loading: false,
    error: false,
    success: false,
    edited: 0
};

export const editAssetLocationsAsync = createAsyncThunk(
    'assets/edit-locations',
    async (payload: PatchAssetLocationsBody, { rejectWithValue }) => {
        try {
            const endpoint = `/electricity/generation/assets/${payload.assetId}/locations/${payload.id}`;

            const response = await axios.patch<AssetLocation>(endpoint, payload.body);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const editAssetLocations = createSlice({
    name: 'editAssetLocations',
    initialState,
    reducers: {
        resetEditAssetLocationsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(editAssetLocationsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(editAssetLocationsAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.edited += 1;
        });
        builder.addCase(editAssetLocationsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetEditAssetLocationsState } = editAssetLocations.actions;

export default editAssetLocations.reducer;
