import { useState } from 'react';
import { Box } from '@mui/system';
import classNames from 'classnames';

import { useAppDispatch } from '../../stores';
import { ASSET_TYPE } from '../../interfaces/uiv2';
import { ReactComponent as WindIcon } from '../../assets/icons/park_types/wind_type.svg';
import { ReactComponent as SolarIcon } from '../../assets/icons/park_types/solar_type.svg';
import { ReactComponent as NuclearIcon } from '../../assets/icons/park_types/nuclear_type.svg';
import { ReactComponent as CoalIcon } from '../../assets/icons/park_types/coal_type.svg';

import styles from './style.module.scss';

interface AssetTypeFilteringProps {
    getParams: any;
    setGetParams: any;
    tl?: boolean;
}

const defaultState = [
    { label: ASSET_TYPE.SOLAR, icon: () => <SolarIcon />, checked: false },
    { label: ASSET_TYPE.WIND, icon: () => <WindIcon />, checked: false }
];

const extendedState = [
    ...defaultState,
    { label: ASSET_TYPE.NUCLEAR, icon: () => <NuclearIcon />, checked: false },
    { label: ASSET_TYPE.COAL, icon: () => <CoalIcon />, checked: false }
];

const AssetTypeFiltering = ({ getParams, setGetParams, tl }: AssetTypeFilteringProps) => {
    const dispatch = useAppDispatch();
    const [types, setTypes] = useState(tl ? extendedState : defaultState);

    const handleChange = (type: ASSET_TYPE) => () => {
        const typeParams: ASSET_TYPE[] = [];
        const newTypes = types.map((e) => {
            if (e.label === type) {
                e.checked = !e.checked;
            }

            if (e.checked) {
                typeParams.push(e.label);
            }

            return e;
        });
        setTypes(newTypes);

        if (typeParams.length) {
            dispatch(setGetParams({ ...getParams, type: typeParams.length > 1 ? '' : typeParams }));
        } else {
            const params: any = {};
            Object.keys(getParams)
                .filter((k) => k !== 'type')
                .forEach((e) => {
                    params[e] = getParams[e];
                });
            dispatch(setGetParams(params));
        }
    };
    return (
        <>
            {types.map((asset) => {
                return (
                    <Box
                        className={classNames(styles.assets, asset.checked && styles.active)}
                        key={asset.label}
                        onClick={handleChange(asset.label)}
                    >
                        {asset.icon()}
                    </Box>
                );
            })}
        </>
    );
};

export default AssetTypeFiltering;
