import classnames from 'classnames';
import { Layout } from 'react-grid-layout';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLine } from 'victory';

import { mockLatest } from './helpers';
import { ROUTES } from '../../utils/config';
import { VPPFE } from '../../interfaces/uiv2';
import AssetsVPP from '../vpp-page-view/AssetsVPP';
import { CardHeaderActions } from './CardHeaderActions';
import { useGetVppAssetsQuery } from '../../stores/VPP';
import { getFormattedCapacityValue } from '../../utils/helpers';
import { ReactComponent as VPPSVG } from '../../assets/icons/vpp.svg';
import { DashboardItem, DASHBOARD_CHART_TYPE } from '../../interfaces/apiv2';

import style from './style.module.scss';

export const DashboardVppCard = ({
    l,
    item,
    vpp,
    changeHeight,
    deleteDashboardItem
}: {
    l: Layout;
    item: DashboardItem;
    vpp: VPPFE;
    changeHeight: (item: string) => void;
    deleteDashboardItem: (item: string) => void;
}) => {
    const { t } = useTranslation();

    const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });

    const graphRef = useCallback((node) => {
        if (node !== null) {
            setBoundingRect(node.getBoundingClientRect());
        }
    }, []);

    const getVPPPageUrl = () => {
        return `${ROUTES.AI_GENERATION_VPP_PAGE.replace(':vppId', vpp.id)}`;
    };

    const { data: assets = [] } = useGetVppAssetsQuery({ id: vpp.id });

    return (
        <>
            <Box className={style.itemHeader}>
                <Box className={style.name}>
                    <VPPSVG className={vpp.is_implicit ? style.icon : ''} />
                    <Link to={getVPPPageUrl()}>
                        <Typography variant="h7" className="dragDisable">
                            {vpp.name}
                        </Typography>
                    </Link>
                </Box>
                <CardHeaderActions
                    l={l}
                    changeHeight={changeHeight}
                    deleteDashboardItem={deleteDashboardItem}
                    item={{ name: vpp.name, type: '' }}
                />
            </Box>

            <Box className={classnames(style.itemDetails, l.h === 1 ? style.noBorder : '')}>
                <Typography className={classnames(style.element, style.border)} variant="small4">
                    {t('assetInfo.capacity')}
                    <Typography variant="small3" className={style.black} sx={{ ml: 1.5 }}>
                        {getFormattedCapacityValue(vpp.total_capacity)}
                    </Typography>
                </Typography>

                <Box className={classnames(style.element, l.w > 4 ? style.border : '')}>
                    <Typography variant="small4" className={style.black} sx={{ ml: 1.5 }}>
                        {t('assetInfo.assets')}
                    </Typography>
                    <AssetsVPP assets={assets}></AssetsVPP>
                </Box>
            </Box>

            {l.h === 3 ? (
                <Box className={classnames(style.itemChartContainer, style.vppChart)}>
                    <Box className={style.itemChart} ref={graphRef}>
                        {item.chart_type === DASHBOARD_CHART_TYPE[0] ? (
                            <VictoryChart height={boundingRect.height} width={boundingRect.width}>
                                <VictoryBar
                                    style={{
                                        data: {
                                            fill: 'url(#barGradient)',
                                            strokeLinejoin: 'round',
                                            strokeWidth: 8
                                        },
                                        labels: { fill: '#3745F2' }
                                    }}
                                    x={'date'}
                                    y={'value'}
                                    barWidth={40}
                                    data={mockLatest}
                                    labels={({ datum }) => datum.value}
                                />
                                <VictoryAxis
                                    style={{
                                        axis: {
                                            stroke: '#D0D0D0'
                                        },
                                        tickLabels: {
                                            fill: '#828282'
                                        }
                                    }}
                                />
                            </VictoryChart>
                        ) : null}

                        {item.chart_type === DASHBOARD_CHART_TYPE[1] ? (
                            <VictoryChart height={boundingRect.height} width={boundingRect.width}>
                                <VictoryLine
                                    style={{
                                        data: { stroke: '#D5E3FF' },
                                        parent: { border: '1px solid #ccc' }
                                    }}
                                    data={mockLatest}
                                    x={'date'}
                                    y={'value'}
                                />
                                <VictoryAxis
                                    style={{
                                        axis: {
                                            stroke: '#D0D0D0'
                                        },
                                        tickLabels: {
                                            fill: '#828282'
                                        }
                                    }}
                                />

                                <VictoryAxis
                                    dependentAxis
                                    orientation="right"
                                    style={{
                                        axis: {
                                            stroke: 'transparent'
                                        },
                                        tickLabels: {
                                            fill: '#828282'
                                        },
                                        grid: { stroke: '#D0D0D0', strokeWidth: 0.5 }
                                    }}
                                />
                            </VictoryChart>
                        ) : null}
                    </Box>
                </Box>
            ) : null}
        </>
    );
};
