import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../../../utils/axios';
import i18next from '../../../../utils/i18next';
import ToastifyType from '../../../../utils/toastify-config';
import { GetParams } from '../../../../interfaces/general';
import { transformClustersDataToFE } from '../../helpers';
import { ClustersDataFE } from '../../../../interfaces/uiv2';
import { PAGINATION_SETTINGS } from '../../../../utils/config';
import { getQueryParamsFromObject } from '../../../../utils/table';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../../../interfaces/redux';
import { ApiPaginationInfo, Cluster, PaginatedApiResponse } from '../../../../interfaces/apiv2';

interface ListClustersState extends DefaultInitialState {
    clusters: Cluster[];
    clustersFE: ClustersDataFE[];
    selectedRows: ClustersDataFE[];
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
}

const initialState: ListClustersState = {
    clusters: [],
    clustersFE: [],
    selectedRows: [],
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    pager: null
};

export const getClustersListAsync = createAsyncThunk(
    'Clusters/list',
    async ({ projectId, getParams }: { getParams: GetParams; projectId: string }, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...getParams, type: 'Cluster' });
            const response = await axios.get<PaginatedApiResponse<Cluster>>(
                `/electricity/demand/${projectId}/clusters${queryParams}`
            );
            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const importClustersAsync = createAsyncThunk(
    'Clusters/import',
    async (
        { projectId, file, type }: { projectId: string; file: File; type: 'import' | 'historical' },
        { rejectWithValue }
    ) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(
                `/electricity/demand/${projectId}/clusters/${type}?cluster_type=Cluster`,
                formData,
                {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listClusters = createSlice({
    name: 'listClusters',
    initialState,
    reducers: {
        resetListClustersState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<ClustersDataFE[]>) => {
            state.selectedRows = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getClustersListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getClustersListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.clusters = items;
            } else {
                state.clusters = [...current(state).clusters, ...items];
            }
            state.clustersFE = transformClustersDataToFE(current(state).clusters);
        });
        builder.addCase(getClustersListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(importClustersAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(importClustersAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(importClustersAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const {
    resetListClustersState,
    setGetParams: setGetClustersParams,
    setSelectedRows: setSelectedRowsClusters
} = listClusters.actions;

export default listClusters.reducer;
