import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Box, Typography } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

import { useAppSelector } from '../../stores';
import ToastifyType from '../../utils/toastify-config';

import common from '../../common.module.scss';
import style from '../../features/asset-page/asset-page.module.scss';

interface PageHeaderProps {
    title?: string;
    subtitle: string;
    icon?: ReactElement;
    className?: string;
    id: string;
    variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

export const PageHeader: FC<PageHeaderProps> = ({ id, icon, subtitle, className, variant, title }) => {
    const { user } = useAppSelector((state) => state.login);
    const isSuperAdmin = user && user.superadmin;

    const copyToClipboard = async () => {
        if (isSuperAdmin) {
            try {
                await navigator.clipboard.writeText(id);
                toast.success(`${id} Copied to clipboard!`, ToastifyType.success);
                /* Resolved - text copied to clipboard successfully */
            } catch (err) {
                toast.error('Failed to copy id', ToastifyType.error);
                /* Rejected - text failed to copy to the clipboard */
            }
        }
    };

    if (title) {
        return (
            <>
                {icon && icon}
                <Typography
                    variant={'h6'}
                    className={classNames(className, style.mr1, isSuperAdmin && common.hover, icon && style.ml1)}
                    onClick={copyToClipboard}
                >
                    {title}
                </Typography>
                <Box className={style.dot} />
                <Typography
                    variant={variant || 'h7'}
                    className={classNames(className, style.m0, isSuperAdmin && common.hover)}
                    onClick={copyToClipboard}
                >
                    {subtitle}
                </Typography>
            </>
        );
    }

    return (
        <Typography
            variant={variant || 'h6'}
            className={classNames(className, isSuperAdmin && common.hover)}
            onClick={copyToClipboard}
        >
            {subtitle}
        </Typography>
    );
};
