import { getRandomInt } from '../../utils/helpers';

export const mockedConsumersData = [
    {
        name: 'SD07364',
        type: 'PoD',
        id: 'd691f750-f960-44f3-a884-e116524ce664',
        industry: 'Auto',
        voltage: 'LV',
        created_at: '2021-09-28T02:32:45',
        updated_at: '2021-09-28T02:32:45',
        expiry_date: '2029-08-27T04:18:52',
        location_type: 'Urban',
        dso: 'EDM',
        profile: 'P001',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO',
        latest_forecast: [88, 184, 229, 254, 242, 206, 193]
    },
    {
        name: 'SD07365',
        type: 'PoD',
        id: '7666e40a-9fb7-40f1-9b1c-ab6a48cc6337',
        industry: 'Auto',
        voltage: 'MV',
        created_at: '2021-06-28T02:19:29',
        updated_at: '2021-06-28T02:19:29',
        expiry_date: '2026-12-11T08:28:08',
        location_type: 'Urban',
        dso: 'EDB',
        profile: 'P002',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07366',
        type: 'PoD',
        id: '5a7fee50-4a34-465b-a2f4-6338cf9ecbd7',
        industry: 'Medical',
        voltage: 'LV',
        created_at: '2021-04-18T05:47:36',
        updated_at: '2021-04-18T05:47:36',
        expiry_date: '2024-01-22T03:33:21',
        location_type: 'Urban',
        dso: 'EDD',
        profile: 'P003',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07367',
        type: 'PoD',
        id: 'bb0b4f67-ab7a-4e0b-bc3e-84d9f0b78c4d',
        industry: 'Medical',
        voltage: 'LV',
        created_at: '2020-05-21T12:43:29',
        updated_at: '2020-05-21T12:43:29',
        expiry_date: '2024-06-01T05:53:55',
        location_type: 'Urban',
        dso: 'DEL',
        profile: 'P004',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07368',
        type: 'PoD',
        id: '3d1263a2-6807-45ab-9546-3d4dcb0ee11a',
        industry: 'Auto',
        voltage: 'LV',
        created_at: '2022-01-20T11:18:41',
        updated_at: '2022-01-20T11:18:41',
        expiry_date: '2027-12-07T10:55:15',
        location_type: 'Rural',
        dso: 'DEO',
        profile: 'P005',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07369',
        type: 'PoD',
        id: '96d356f4-a277-4d01-9ac1-42c8f0e4aa1c',
        industry: 'Medical',
        voltage: 'HV',
        created_at: '2021-12-12T04:05:31',
        updated_at: '2021-12-12T04:05:31',
        expiry_date: '2027-06-15T01:48:58',
        location_type: 'Rural',
        dso: 'EDD',
        profile: 'P006',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07370',
        type: 'PoD',
        id: '933dbbed-6cf2-4730-a419-f0eeb0daeff2',
        industry: 'Auto',
        voltage: 'MV',
        created_at: '2020-02-23T08:15:45',
        updated_at: '2020-02-23T08:15:45',
        expiry_date: '2024-08-23T06:37:05',
        location_type: 'Rural',
        dso: 'DEL',
        profile: 'P007',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07371',
        type: 'PoD',
        id: '0ae5e97c-bce4-4385-8deb-600897908815',
        industry: 'Medical',
        voltage: 'LV',
        created_at: '2020-07-14T02:20:22',
        updated_at: '2020-07-14T02:20:22',
        expiry_date: '2026-11-29T07:05:02',
        location_type: 'Urban',
        dso: 'DEO',
        profile: 'P008',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07372',
        type: 'PoD',
        id: '53579987-ff0a-4745-9b5e-bd4fbf05ab8c',
        industry: 'Medical',
        voltage: 'MV',
        created_at: '2020-06-05T06:01:52',
        updated_at: '2020-06-05T06:01:52',
        expiry_date: '2028-06-15T04:07:07',
        location_type: 'Rural',
        dso: 'DEL',
        profile: 'P009',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07373',
        type: 'PoD',
        id: 'f33ce09c-5621-4e0b-8fe8-c3588ac1d51d',
        industry: 'Textile',
        voltage: 'HV',
        created_at: '2020-08-12T10:49:54',
        updated_at: '2020-08-12T10:49:54',
        expiry_date: '2024-01-26T11:38:44',
        location_type: 'Rural',
        dso: 'EDM',
        profile: 'P010',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07374',
        type: 'PoD',
        id: '2bfb2b2d-8378-433d-830e-b832c12308d7',
        industry: 'Textile',
        voltage: 'LV',
        created_at: '2020-04-12T04:23:08',
        updated_at: '2020-04-12T04:23:08',
        expiry_date: '2023-06-27T06:13:58',
        location_type: 'Urban',
        dso: 'EDB',
        profile: 'P011',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07375',
        type: 'PoD',
        id: 'd65401f6-6187-4b59-8eee-8f5c72243f05',
        industry: 'Auto',
        voltage: 'MV',
        created_at: '2022-05-25T03:52:42',
        updated_at: '2022-05-25T03:52:42',
        expiry_date: '2026-12-18T10:48:21',
        location_type: 'Rural',
        dso: 'DEL',
        profile: 'P012',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07376',
        type: 'PoD',
        id: '731a1636-0f37-4ba2-9a97-38cedb953ee4',
        industry: 'Medical',
        voltage: 'MV',
        created_at: '2020-11-29T10:25:11',
        updated_at: '2020-11-29T10:25:11',
        expiry_date: '2025-11-04T07:45:49',
        location_type: 'Urban',
        dso: 'EDD',
        profile: 'P013',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07377',
        type: 'PoD',
        id: '4985d26f-a08f-444b-b76f-73dae88f28d0',
        industry: 'Medical',
        voltage: 'MV',
        created_at: '2023-02-12T03:09:58',
        updated_at: '2023-02-12T03:09:58',
        expiry_date: '2024-01-01T01:05:13',
        location_type: 'Rural',
        dso: 'EDM',
        profile: 'P014',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07378',
        type: 'PoD',
        id: '52bdebb7-7804-4a20-8b3d-91378258a978',
        industry: 'Textile',
        voltage: 'MV',
        created_at: '2020-07-28T06:35:59',
        updated_at: '2020-07-28T06:35:59',
        expiry_date: '2024-02-22T04:00:12',
        location_type: 'Rural',
        dso: 'EDB',
        profile: 'P015',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07379',
        type: 'PoD',
        id: '6b3f6bdf-fa7e-45ea-8c95-7e6ef6e54b14',
        industry: 'Textile',
        voltage: 'LV',
        created_at: '2021-11-02T04:10:05',
        updated_at: '2021-11-02T04:10:05',
        expiry_date: '2031-07-04T04:23:49',
        location_type: 'Urban',
        dso: 'DEO',
        profile: 'P016',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07380',
        type: 'PoD',
        id: 'b27881f7-fccc-437f-95b1-ff22c93e5758',
        industry: 'Auto',
        voltage: 'LV',
        created_at: '2020-12-13T09:44:47',
        updated_at: '2020-12-13T09:44:47',
        expiry_date: '2024-12-24T05:09:40',
        location_type: 'Urban',
        dso: 'EDB',
        profile: 'P017',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07381',
        type: 'PoD',
        id: '6e55c29e-420a-44ce-843c-b0134ad944fc',
        industry: 'Textile',
        voltage: 'MV',
        created_at: '2022-04-09T11:24:39',
        updated_at: '2022-04-09T11:24:39',
        expiry_date: '2025-01-18T07:44:39',
        location_type: 'Rural',
        dso: 'EDD',
        profile: 'P018',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07382',
        type: 'PoD',
        id: 'a19ac135-213c-45d9-9447-abe6d4f80bec',
        industry: 'Auto',
        voltage: 'MV',
        created_at: '2023-02-12T05:15:27',
        updated_at: '2023-02-12T05:15:27',
        expiry_date: '2026-08-19T03:44:57',
        location_type: 'Urban',
        dso: 'EDM',
        profile: 'P019',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07383',
        type: 'PoD',
        id: '432f2c3a-7311-48f0-bc0b-cc7f54890c61',
        industry: 'Textile',
        voltage: 'MV',
        created_at: '2023-02-14T03:08:14',
        updated_at: '2023-02-14T03:08:14',
        expiry_date: '2026-05-08T02:43:59',
        location_type: 'Urban',
        dso: 'DEL',
        profile: 'P020',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07384',
        type: 'PoD',
        id: '962ef1ac-98d3-4957-b696-b9503a704dd3',
        industry: 'Textile',
        voltage: 'MV',
        created_at: '2021-04-06T05:08:51',
        updated_at: '2021-04-06T05:08:51',
        expiry_date: '2027-03-05T02:01:16',
        location_type: 'Rural',
        dso: 'DEO',
        profile: 'P021',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07385',
        type: 'PoD',
        id: '0e9db42e-0ca6-4226-8daf-4c81fcc84c3e',
        industry: 'Textile',
        voltage: 'HV',
        created_at: '2021-12-29T04:57:56',
        updated_at: '2021-12-29T04:57:56',
        expiry_date: '2023-12-31T05:39:33',
        location_type: 'Rural',
        dso: 'DEL',
        profile: 'P022',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07386',
        type: 'PoD',
        id: 'f8963f74-ddbf-494e-9994-fd0e41f6841a',
        industry: 'Textile',
        voltage: 'HV',
        created_at: '2021-11-01T05:44:43',
        updated_at: '2021-11-01T05:44:43',
        expiry_date: '2030-08-17T11:19:41',
        location_type: 'Rural',
        dso: 'EDB',
        profile: 'P023',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07387',
        type: 'PoD',
        id: '717bc010-a669-4625-b92f-5156abacd99e',
        industry: 'Medical',
        voltage: 'MV',
        created_at: '2020-12-23T11:28:18',
        updated_at: '2020-12-23T11:28:18',
        expiry_date: '2030-01-20T10:00:44',
        location_type: 'Rural',
        dso: 'EDD',
        profile: 'P024',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    },
    {
        name: 'SD07388',
        type: 'PoD',
        id: '9ca88c27-65f2-40b0-b15d-6a3076e67aa7',
        industry: 'Auto',
        voltage: 'LV',
        created_at: '2022-03-02T07:25:16',
        updated_at: '2022-03-02T07:25:16',
        expiry_date: '2024-12-24T12:10:23',
        location_type: 'Urban',
        dso: 'DEL',
        profile: 'P025',
        latitude: 44.43278,
        longitude: 26.10389,
        location: 'Bucuresti',
        country: 'RO'
    }
];

export const mockedProfilesData = [
    {
        id: 'f245b33c-f8e4-492d-a4d9-f41ff5cebf16',
        name: 'P001',
        total_consumers: getRandomInt(38, 243),
        created_at: '2022-09-08T04:36:26',
        updated_at: '2022-09-08T04:36:26',
        profiling: 'Formula 1',
        description: 'Public lighting',
        country: 'RO',
        dso: 'DEER',
        latest_forecast: [100, 200, 300, 260, 280, 300, 180]
    },
    {
        id: 'dddb1cf5-5f84-4e5b-bdba-40b8ccc7c8c8',
        name: 'P002',
        total_consumers: getRandomInt(38, 243),
        created_at: '2020-05-04T01:43:14',
        updated_at: '2020-05-04T01:43:14',
        profiling: 'Formula 2',
        description: 'Rural Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '057ec4e2-a4f2-43a6-a786-f4294c356eab',
        name: 'P003',
        total_consumers: getRandomInt(38, 243),
        created_at: '2021-07-11T11:45:13',
        updated_at: '2021-07-11T11:45:13',
        profiling: 'Formula 3',
        description: 'Urban Household.',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '4a0ddd38-b471-45c4-93cb-50c340563369',
        name: 'P004',
        total_consumers: getRandomInt(38, 243),
        created_at: '2020-01-29T05:07:44',
        updated_at: '2020-01-29T05:07:44',
        profiling: 'Formula 4',
        description: 'Urban Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '26afd935-b597-45b6-9b5c-695a7b9dbc4e',
        name: 'P005',
        total_consumers: getRandomInt(38, 243),
        created_at: '2022-05-27T02:09:08',
        updated_at: '2022-05-27T02:09:08',
        profiling: 'Formula 5',
        description: 'Public lighting',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '14fe846c-8729-4012-81c9-745b32419ae4',
        name: 'P006',
        total_consumers: getRandomInt(38, 243),
        created_at: '2021-07-30T05:58:03',
        updated_at: '2021-07-30T05:58:03',
        profiling: 'Formula 6',
        description: 'Urban Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '466b3976-80c5-422a-bfc2-6f9fb6032329',
        name: 'P007',
        total_consumers: getRandomInt(38, 243),
        created_at: '2022-08-19T12:36:34',
        updated_at: '2022-08-19T12:36:34',
        profiling: 'Formula 7',
        description: 'Public lighting',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '6e14d3d7-4a96-41f6-9be8-c7677b143118',
        name: 'P008',
        total_consumers: getRandomInt(38, 243),
        created_at: '2021-12-16T03:38:51',
        updated_at: '2021-12-16T03:38:51',
        profiling: 'Formula 8',
        description: 'Urban Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: 'c88eea61-24d9-4fc9-8f27-c3d9b02a25a8',
        name: 'P009',
        total_consumers: getRandomInt(38, 243),
        created_at: '2022-06-27T09:21:02',
        updated_at: '2022-06-27T09:21:02',
        profiling: 'Formula 9',
        description: 'Rural Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: 'f1e3ecee-bb97-442b-99a5-29dfe092f91d',
        name: 'P010',
        total_consumers: getRandomInt(38, 243),
        created_at: '2021-05-14T01:15:22',
        updated_at: '2021-05-14T01:15:22',
        profiling: 'Formula 10',
        description: 'Urban Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: 'ac7d55c7-78f2-43ef-9cd5-35e0c34f1d22',
        name: 'P011',
        total_consumers: getRandomInt(38, 243),
        created_at: '2020-07-22T11:27:18',
        updated_at: '2020-07-22T11:27:18',
        profiling: 'Formula 11',
        description: 'Public lighting',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '782a48aa-43a7-4297-ab96-05b3484f41f2',
        name: 'P012',
        total_consumers: getRandomInt(38, 243),
        created_at: '2021-01-18T04:20:44',
        updated_at: '2021-01-18T04:20:44',
        profiling: 'Formula 12',
        description: 'Rural Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: 'b15da82f-79a8-4a28-9bfe-fab914ac5b76',
        name: 'P013',
        total_consumers: getRandomInt(38, 243),
        created_at: '2020-06-30T07:09:05',
        updated_at: '2020-06-30T07:09:05',
        profiling: 'Formula 13',
        description: 'Urban Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: 'c5f29554-1def-4ddc-ab94-be79fd39cad4',
        name: 'P014',
        total_consumers: getRandomInt(38, 243),
        created_at: '2023-01-02T01:30:08',
        updated_at: '2023-01-02T01:30:08',
        profiling: 'Formula 14',
        description: 'Urban Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '6c287617-f6c9-4d3e-bf0f-8ae169f1c6db',
        name: 'P015',
        total_consumers: getRandomInt(38, 243),
        created_at: '2021-06-12T03:43:33',
        updated_at: '2021-06-12T03:43:33',
        profiling: 'Formula 15',
        description: 'Public lighting',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '3c563bc0-1852-436f-af94-feb9374be8fd',
        name: 'P016',
        total_consumers: getRandomInt(38, 243),
        created_at: '2021-10-08T03:28:13',
        updated_at: '2021-10-08T03:28:13',
        profiling: 'Formula 16',
        description: 'Urban Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '0d6ab6f3-61f2-4e3c-9913-668e13f61e03',
        name: 'P017',
        total_consumers: getRandomInt(38, 243),
        created_at: '2020-06-29T08:11:20',
        updated_at: '2020-06-29T08:11:20',
        profiling: 'Formula 17',
        description: 'Public lighting',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '083465ce-3d20-4303-924b-eba117df1ef7',
        name: 'P018',
        total_consumers: getRandomInt(38, 243),
        created_at: '2020-03-22T07:55:39',
        updated_at: '2020-03-22T07:55:39',
        profiling: 'Formula 18',
        description: 'Rural Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '50be4cc7-39e4-41af-9ec0-9845b521e9a7',
        name: 'P019',
        total_consumers: getRandomInt(38, 243),
        created_at: '2021-01-27T01:56:25',
        updated_at: '2021-01-27T01:56:25',
        profiling: 'Formula 19',
        description: 'Urban Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '3ea9ee60-8f8d-4c61-b3af-2ac85bd966ec',
        name: 'P020',
        total_consumers: getRandomInt(38, 243),
        created_at: '2020-04-05T08:21:30',
        updated_at: '2020-04-05T08:21:30',
        profiling: 'Formula 20',
        description: 'Public lighting',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '502b6d60-6750-476b-8ec0-fe7bc8d76c03',
        name: 'P021',
        total_consumers: getRandomInt(38, 243),
        created_at: '2021-11-02T04:22:16',
        updated_at: '2021-11-02T04:22:16',
        profiling: 'Formula 21',
        description: 'Public lighting',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: 'b0ad95de-b20f-4acd-9bf7-ee10de4cecf7',
        name: 'P022',
        total_consumers: getRandomInt(38, 243),
        created_at: '2021-07-28T01:42:02',
        updated_at: '2021-07-28T01:42:02',
        profiling: 'Formula 22',
        description: 'Rural Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '508c958a-49ca-4362-b33f-f505420f8c2a',
        name: 'P023',
        total_consumers: getRandomInt(38, 243),
        created_at: '2020-08-24T11:20:43',
        updated_at: '2020-08-24T11:20:43',
        profiling: 'Formula 23',
        description: 'Urban Household',
        country: 'RO',
        dso: 'DEER'
    },
    {
        id: '01cae21d-458b-492f-9fe1-dcf2eb3b618e',
        name: 'P024',
        total_consumers: getRandomInt(38, 243),
        created_at: '2021-02-03T03:35:57',
        updated_at: '2021-02-03T03:35:57',
        profiling: 'Formula 24',
        description: 'Public lighting',
        country: 'RO',
        dso: 'DEER'
    }
];

export const mockedClustersData = [
    {
        id: '2c8158bf-5d84-44cd-9d38-5567e2e148c0',
        name: 'HV consumers',
        created_at: '2020-09-12T03:49:18',
        updated_at: '2020-09-12T03:49:18',
        country: 'RO',
        consumers: 120,
        segments: 36,
        latest_forecast: [88, 184, 229, 254, 242, 206, 193]
    },
    {
        id: '80429ec6-ffcc-4bcb-95f1-7dcd7429948e',
        name: 'MV consumers',
        created_at: '2022-11-19T10:54:57',
        updated_at: '2022-11-19T10:54:57',
        country: 'RO',
        consumers: 106,
        segments: 183
    },
    {
        id: 'ed81198c-b009-4b9d-a979-918d730b3e75',
        name: 'LV consumers',
        created_at: '2022-12-07T04:47:51',
        updated_at: '2022-12-07T04:47:51',
        country: 'RO',
        consumers: 85,
        segments: 104
    },
    {
        id: '80c17e03-c739-45f6-b4d4-817cd505b009',
        name: 'Consumers Athens',
        created_at: '2020-01-12T06:48:22',
        updated_at: '2020-01-12T06:48:22',
        country: 'RO',
        consumers: 172,
        segments: 28
    },
    {
        id: '30f267b3-58a9-4124-aa56-4003bf9b4edb',
        name: 'HV consumers',
        created_at: '2022-10-07T02:16:16',
        updated_at: '2022-10-07T02:16:16',
        country: 'RO',
        consumers: 177,
        segments: 56
    },
    {
        id: 'ef6cf926-86eb-4da7-83f3-4e34d7fc85a0',
        name: 'Consumers Bucharest',
        created_at: '2021-01-24T09:40:26',
        updated_at: '2021-01-24T09:40:26',
        country: 'RO',
        consumers: 106,
        segments: 110
    },
    {
        id: '52ce57e6-51d3-4520-9267-92080451b89b',
        name: 'Consumers Region 1-4',
        created_at: '2021-07-22T04:34:57',
        updated_at: '2021-07-22T04:34:57',
        country: 'RO',
        consumers: 171,
        segments: 73
    },
    {
        id: '13b87446-7f9f-412c-b19b-d01c999378eb',
        name: 'Household Consumers',
        created_at: '2020-11-27T06:00:54',
        updated_at: '2020-11-27T06:00:54',
        country: 'RO',
        consumers: 183,
        segments: 93
    },
    {
        id: 'fd925bf8-f3a3-4c5b-bd12-4f1783521702',
        name: 'Commercial Consumers',
        created_at: '2022-08-02T12:27:23',
        updated_at: '2022-08-02T12:27:23',
        country: 'RO',
        consumers: 103,
        segments: 33
    },
    {
        id: 'f9ae7362-2ef4-41de-a186-7aad35e38e66',
        name: 'Industrial Consumers',
        created_at: '2020-10-09T05:57:54',
        updated_at: '2020-10-09T05:57:54',
        country: 'RO',
        consumers: 150,
        segments: 60
    },
    {
        id: 'ae3de3d7-94ce-4675-8f87-9bd15293af95',
        name: 'Commercial Consumers',
        created_at: '2022-07-01T12:24:45',
        updated_at: '2022-07-01T12:24:45',
        country: 'RO',
        consumers: 157,
        segments: 144
    },
    {
        id: 'd57cbec5-4df4-486a-8698-39d44ff17899',
        name: 'Consumers Region 1-4',
        created_at: '2021-02-20T07:56:53',
        updated_at: '2021-02-20T07:56:53',
        country: 'RO',
        consumers: 80,
        segments: 187
    },
    {
        id: '2c05e1b5-70ed-43f5-b9eb-38dcae7cf84b',
        name: 'Consumers Bucharest',
        created_at: '2022-03-09T04:02:54',
        updated_at: '2022-03-09T04:02:54',
        country: 'RO',
        consumers: 169,
        segments: 174
    },
    {
        id: '394485ce-a6f1-4b61-a914-01e01e2cebd4',
        name: 'Consumers Athens',
        created_at: '2020-11-21T02:15:22',
        updated_at: '2020-11-21T02:15:22',
        country: 'RO',
        consumers: 89,
        segments: 128
    },
    {
        id: '78a5ecfb-183c-4d62-b5c1-ae2f5a6351f4',
        name: 'HV consumers',
        created_at: '2022-02-11T05:16:46',
        updated_at: '2022-02-11T05:16:46',
        country: 'RO',
        consumers: 192,
        segments: 122
    },
    {
        id: 'a9086db1-20a2-445d-9bcd-0cfc7013c109',
        name: 'Household Consumers',
        created_at: '2022-11-07T11:34:57',
        updated_at: '2022-11-07T11:34:57',
        country: 'RO',
        consumers: 122,
        segments: 100
    },
    {
        id: 'ec7f8ce5-0d0d-4780-98e5-d8c95af0e0fd',
        name: 'Household Consumers',
        created_at: '2021-05-28T08:54:33',
        updated_at: '2021-05-28T08:54:33',
        country: 'RO',
        consumers: 163,
        segments: 144
    },
    {
        id: '14d63356-af6b-419b-a12b-8066530666a9',
        name: 'HV consumers',
        created_at: '2020-01-24T07:45:22',
        updated_at: '2020-01-24T07:45:22',
        country: 'RO',
        consumers: 103,
        segments: 129
    },
    {
        id: '68915a2d-8677-4d47-8908-bef2a0c2e04a',
        name: 'Household Consumers',
        created_at: '2022-12-16T05:38:29',
        updated_at: '2022-12-16T05:38:29',
        country: 'RO',
        consumers: 51,
        segments: 78
    },
    {
        id: 'fc5b77e9-5863-4f1e-87c1-00f9e1ced52b',
        name: 'Household Consumers',
        created_at: '2021-12-17T02:17:02',
        updated_at: '2021-12-17T02:17:02',
        country: 'RO',
        consumers: 102,
        segments: 49
    }
];

export const mockedSegmentsData = [
    {
        id: '30ec8dc9-b6c3-422d-8301-47c2e15e2795',
        country: 'RO',
        name: 'Key Accounts Small',
        created_at: '2022-09-03T01:01:45',
        updated_at: '2022-09-03T01:01:45',
        consumers: 198,
        segments: 194,
        latest_forecast: [83, 187, 224, 263, 245, 212, 186]
    },
    {
        id: '05da73e7-ea7d-4ab9-954c-e57c4c733567',
        country: 'RO',
        name: 'Key Accounts Large',
        created_at: '2022-05-29T07:31:46',
        updated_at: '2022-05-29T07:31:46',
        consumers: 31,
        segments: 80
    },
    {
        id: '5e225c7c-5911-41f6-9b77-7432ce88a813',
        country: 'RO',
        name: 'B2B',
        created_at: '2022-10-29T09:15:58',
        updated_at: '2022-10-29T09:15:58',
        consumers: 60,
        segments: 73
    },
    {
        id: '53184e03-2667-4d60-96a2-511c2186cdef',
        country: 'RO',
        name: 'B2C',
        created_at: '2022-11-22T07:48:32',
        updated_at: '2022-11-22T07:48:32',
        consumers: 116,
        segments: 119
    },
    {
        id: 'eb13d065-4410-47ef-9a01-f3a022ba39b2',
        country: 'RO',
        name: 'B2C Premium',
        created_at: '2020-12-09T09:39:14',
        updated_at: '2020-12-09T09:39:14',
        consumers: 191,
        segments: 35
    },
    {
        id: 'e20de585-f41b-4d2f-8bae-a239664af0de',
        country: 'RO',
        name: 'B2C Standard',
        created_at: '2020-05-23T10:03:21',
        updated_at: '2020-05-23T10:03:21',
        consumers: 115,
        segments: 83
    },
    {
        id: '7fb515ef-ac20-4978-97ee-bfb391a79561',
        country: 'RO',
        name: 'B2C',
        created_at: '2020-03-28T01:38:03',
        updated_at: '2020-03-28T01:38:03',
        consumers: 165,
        segments: 188
    },
    {
        id: 'afee8384-3736-4d95-a506-81f8b0524904',
        country: 'RO',
        name: 'Key Accounts Small',
        created_at: '2021-09-28T11:05:40',
        updated_at: '2021-09-28T11:05:40',
        consumers: 144,
        segments: 57
    },
    {
        id: 'd4594398-ca43-4ac3-80f0-819cb75be1a1',
        country: 'RO',
        name: 'B2C Standard',
        created_at: '2020-01-28T12:27:03',
        updated_at: '2020-01-28T12:27:03',
        consumers: 188,
        segments: 123
    },
    {
        id: 'a0a835a8-e287-4625-a81a-da2b7f71328a',
        country: 'RO',
        name: 'B2B',
        created_at: '2020-02-07T07:22:21',
        updated_at: '2020-02-07T07:22:21',
        consumers: 142,
        segments: 124
    },
    {
        id: '2895a62e-b374-4ffb-9ed5-5ebeadfedbe5',
        country: 'RO',
        name: 'B2C Standard',
        created_at: '2020-06-18T02:05:24',
        updated_at: '2020-06-18T02:05:24',
        consumers: 142,
        segments: 150
    },
    {
        id: '0800bd06-a96a-48e1-8e49-62b0f95c302a',
        country: 'RO',
        name: 'Key Accounts Small',
        created_at: '2020-05-08T11:25:23',
        updated_at: '2020-05-08T11:25:23',
        consumers: 36,
        segments: 196
    },
    {
        id: '3a48cf58-8a56-4c7b-bdcd-b1a9b6fef49d',
        country: 'RO',
        name: 'Key Accounts Small',
        created_at: '2022-11-04T08:40:32',
        updated_at: '2022-11-04T08:40:32',
        consumers: 168,
        segments: 35
    },
    {
        id: 'db353e77-93c1-4749-8f3a-015e12b453c3',
        country: 'RO',
        name: 'Key Accounts Large',
        created_at: '2022-02-17T02:22:03',
        updated_at: '2022-02-17T02:22:03',
        consumers: 193,
        segments: 48
    },
    {
        id: 'a8bc019e-7077-4208-8131-966be5a9cdd6',
        country: 'RO',
        name: 'B2C Premium',
        created_at: '2022-10-18T01:21:56',
        updated_at: '2022-10-18T01:21:56',
        consumers: 145,
        segments: 169
    },
    {
        id: '6b1fe483-e7b3-4106-80c0-59b1f43b9c48',
        country: 'RO',
        name: 'Key Accounts Small',
        created_at: '2022-02-15T09:29:53',
        updated_at: '2022-02-15T09:29:53',
        consumers: 104,
        segments: 63
    },
    {
        id: '14436ae0-27b3-4c75-9f25-73c0a21b08c0',
        country: 'RO',
        name: 'Key Accounts Large',
        created_at: '2020-03-17T06:50:44',
        updated_at: '2020-03-17T06:50:44',
        consumers: 84,
        segments: 169
    },
    {
        id: '924f74db-0c30-4cb0-ac55-d1113c53917a',
        country: 'RO',
        name: 'B2C',
        created_at: '2023-01-31T06:21:46',
        updated_at: '2023-01-31T06:21:46',
        consumers: 45,
        segments: 46
    },
    {
        id: 'd24e8c75-1ff2-4c41-a99f-5dc4c413993e',
        country: 'RO',
        name: 'Key Accounts Large',
        created_at: '2020-02-25T06:13:51',
        updated_at: '2020-02-25T06:13:51',
        consumers: 20,
        segments: 139
    },
    {
        id: 'a2eecd8d-2f9a-48f6-a9dc-9833de30467c',
        country: 'RO',
        name: 'Key Accounts Small',
        created_at: '2021-10-18T08:44:45',
        updated_at: '2021-10-18T08:44:45',
        consumers: 130,
        segments: 130
    },
    {
        id: 'c8cabc2e-1f92-405d-8dbc-68fe2cfbd349',
        country: 'RO',
        name: 'B2B',
        created_at: '2020-05-10T07:15:08',
        updated_at: '2020-05-10T07:15:08',
        consumers: 48,
        segments: 194
    },
    {
        id: '0931f1fd-953c-4fb8-994c-115139c86150',
        country: 'RO',
        name: 'Key Accounts Small',
        created_at: '2022-02-18T11:50:25',
        updated_at: '2022-02-18T11:50:25',
        consumers: 187,
        segments: 41
    },
    {
        id: '42275ab6-fb1f-4d92-b7f2-ab0e9a4caca2',
        country: 'RO',
        name: 'B2C Premium',
        created_at: '2020-08-17T02:07:01',
        updated_at: '2020-08-17T02:07:01',
        consumers: 106,
        segments: 71
    },
    {
        id: '75949482-b6d9-4f6a-b19f-0b1f0f23f775',
        country: 'RO',
        name: 'Key Accounts Large',
        created_at: '2021-09-08T08:31:08',
        updated_at: '2021-09-08T08:31:08',
        consumers: 102,
        segments: 139
    },
    {
        id: '7a519638-6a81-457d-9826-68b8bb8d3da0',
        country: 'RO',
        name: 'Key Accounts Small',
        created_at: '2021-01-29T02:51:34',
        updated_at: '2021-01-29T02:51:34',
        consumers: 162,
        segments: 35
    }
];

export const mockedConsumer = {
    title: '8083437449001',
    type: 'PoD',
    country: 'RO',
    location: 'Baneasa',
    longitude: 44.06667,
    latitude: 27.7,
    voltage: 'HV',
    dso: 'DEL',
    created_at: new Date(),
    updated_at: new Date()
};

export const mockedProfile = {
    id: '01cae21d-458b-492f-9fe1-dcf2eb3b618e',
    name: 'P024',
    total_consumers: getRandomInt(38, 243),
    created_at: '2021-02-03T03:35:57',
    updated_at: '2021-02-03T03:35:57',
    profiling: 'Formula 24',
    description: 'Public lighting'
};

export const mockedCluster = {
    id: '7a519638-6a81-457d-9826-68b8bb8d3da0',
    name: 'Key Accounts Small',
    created_at: '2021-01-29T02:51:34',
    updated_at: '2021-01-29T02:51:34',
    consumers: 162,
    segments: 35,
    country: 'RO'
};

export const mockedSegment = {
    title: 'KAS - Key Accounts Small',
    type: 'Segment',
    country: 'RO',
    consumers: 468581,
    segments: 365,
    created_at: new Date(),
    updated_at: new Date()
};

export const mockedChartData = [
    [
        {
            time: 1679011200,
            value: 0
        },
        {
            time: 1679012100,
            value: 0
        },
        {
            time: 1679013000,
            value: 0
        },
        {
            time: 1679013900,
            value: 0
        },
        {
            time: 1679014800,
            value: 0
        },
        {
            time: 1679015700,
            value: 0
        },
        {
            time: 1679016600,
            value: 0
        },
        {
            time: 1679017500,
            value: 0
        },
        {
            time: 1679018400,
            value: 0
        },
        {
            time: 1679019300,
            value: 0
        },
        {
            time: 1679020200,
            value: 0
        },
        {
            time: 1679021100,
            value: 0
        },
        {
            time: 1679022000,
            value: 0
        },
        {
            time: 1679022900,
            value: 0
        },
        {
            time: 1679023800,
            value: 0
        },
        {
            time: 1679024700,
            value: 0
        },
        {
            time: 1679025600,
            value: 0
        },
        {
            time: 1679026500,
            value: 0
        },
        {
            time: 1679027400,
            value: 0
        },
        {
            time: 1679028300,
            value: 0
        },
        {
            time: 1679029200,
            value: 0
        },
        {
            time: 1679030100,
            value: 0
        },
        {
            time: 1679031000,
            value: 0
        },
        {
            time: 1679031900,
            value: 0
        },
        {
            time: 1679032800,
            value: 0
        },
        {
            time: 1679033700,
            value: 0
        },
        {
            time: 1679034600,
            value: 0
        },
        {
            time: 1679035500,
            value: 0.001
        },
        {
            time: 1679036400,
            value: 0.003
        },
        {
            time: 1679037300,
            value: 0.008
        },
        {
            time: 1679038200,
            value: 0.014
        },
        {
            time: 1679039100,
            value: 0.023
        },
        {
            time: 1679040000,
            value: 0.036
        },
        {
            time: 1679040900,
            value: 0.049
        },
        {
            time: 1679041800,
            value: 0.067
        },
        {
            time: 1679042700,
            value: 0.082
        },
        {
            time: 1679043600,
            value: 0.094
        },
        {
            time: 1679044500,
            value: 0.103
        },
        {
            time: 1679045400,
            value: 0.115
        },
        {
            time: 1679046300,
            value: 0.125
        },
        {
            time: 1679047200,
            value: 0.137
        },
        {
            time: 1679048100,
            value: 0.143
        },
        {
            time: 1679049000,
            value: 0.148
        },
        {
            time: 1679049900,
            value: 0.159
        },
        {
            time: 1679050800,
            value: 0.163
        },
        {
            time: 1679051700,
            value: 0.167
        },
        {
            time: 1679052600,
            value: 0.168
        },
        {
            time: 1679053500,
            value: 0.171
        },
        {
            time: 1679054400,
            value: 0.173
        },
        {
            time: 1679055300,
            value: 0.173
        },
        {
            time: 1679056200,
            value: 0.174
        },
        {
            time: 1679057100,
            value: 0.174
        },
        {
            time: 1679058000,
            value: 0.173
        },
        {
            time: 1679058900,
            value: 0.172
        },
        {
            time: 1679059800,
            value: 0.168
        },
        {
            time: 1679060700,
            value: 0.165
        },
        {
            time: 1679061600,
            value: 0.165
        },
        {
            time: 1679062500,
            value: 0.162
        },
        {
            time: 1679063400,
            value: 0.154
        },
        {
            time: 1679064300,
            value: 0.147
        },
        {
            time: 1679065200,
            value: 0.142
        },
        {
            time: 1679066100,
            value: 0.136
        },
        {
            time: 1679067000,
            value: 0.129
        },
        {
            time: 1679067900,
            value: 0.118
        },
        {
            time: 1679068800,
            value: 0.105
        },
        {
            time: 1679069700,
            value: 0.092
        },
        {
            time: 1679070600,
            value: 0.083
        },
        {
            time: 1679071500,
            value: 0.068
        },
        {
            time: 1679072400,
            value: 0.056
        },
        {
            time: 1679073300,
            value: 0.036
        },
        {
            time: 1679074200,
            value: 0.024
        },
        {
            time: 1679075100,
            value: 0.012
        },
        {
            time: 1679076000,
            value: 0.006
        },
        {
            time: 1679076900,
            value: 0.002
        },
        {
            time: 1679077800,
            value: 0
        },
        {
            time: 1679078700,
            value: 0
        },
        {
            time: 1679079600,
            value: 0
        },
        {
            time: 1679080500,
            value: 0
        },
        {
            time: 1679081400,
            value: 0
        },
        {
            time: 1679082300,
            value: 0
        },
        {
            time: 1679083200,
            value: 0
        },
        {
            time: 1679084100,
            value: 0
        },
        {
            time: 1679085000,
            value: 0
        },
        {
            time: 1679085900,
            value: 0
        },
        {
            time: 1679086800,
            value: 0
        },
        {
            time: 1679087700,
            value: 0
        },
        {
            time: 1679088600,
            value: 0
        },
        {
            time: 1679089500,
            value: 0
        },
        {
            time: 1679090400,
            value: 0
        },
        {
            time: 1679091300,
            value: 0
        },
        {
            time: 1679092200,
            value: 0
        },
        {
            time: 1679093100,
            value: 0
        },
        {
            time: 1679094000,
            value: 0
        },
        {
            time: 1679094900,
            value: 0
        },
        {
            time: 1679095800,
            value: 0
        },
        {
            time: 1679096700,
            value: 0
        }
    ],
    []
];

export const mockedPaginatedData = [
    {
        timestamp: 1679004000000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 1,
        date: '16.03.2023',
        localDate: '17.03.2023',
        time: '22:00',
        localTime: '00:00'
    },
    {
        timestamp: 1679004900000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 2,
        date: '16.03.2023',
        localDate: '17.03.2023',
        time: '22:15',
        localTime: '00:15'
    },
    {
        timestamp: 1679005800000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 3,
        date: '16.03.2023',
        localDate: '17.03.2023',
        time: '22:30',
        localTime: '00:30'
    },
    {
        timestamp: 1679006700000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 4,
        date: '16.03.2023',
        localDate: '17.03.2023',
        time: '22:45',
        localTime: '00:45'
    },
    {
        timestamp: 1679007600000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 5,
        date: '16.03.2023',
        localDate: '17.03.2023',
        time: '23:00',
        localTime: '01:00'
    },
    {
        timestamp: 1679008500000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 6,
        date: '16.03.2023',
        localDate: '17.03.2023',
        time: '23:15',
        localTime: '01:15'
    },
    {
        timestamp: 1679009400000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 7,
        date: '16.03.2023',
        localDate: '17.03.2023',
        time: '23:30',
        localTime: '01:30'
    },
    {
        timestamp: 1679010300000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 8,
        date: '16.03.2023',
        localDate: '17.03.2023',
        time: '23:45',
        localTime: '01:45'
    },
    {
        timestamp: 1679011200000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 9,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '00:00',
        localTime: '02:00'
    },
    {
        timestamp: 1679012100000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 10,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '00:15',
        localTime: '02:15'
    },
    {
        timestamp: 1679013000000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 11,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '00:30',
        localTime: '02:30'
    },
    {
        timestamp: 1679013900000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 12,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '00:45',
        localTime: '02:45'
    },
    {
        timestamp: 1679014800000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 13,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '01:00',
        localTime: '03:00'
    },
    {
        timestamp: 1679015700000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 14,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '01:15',
        localTime: '03:15'
    },
    {
        timestamp: 1679016600000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 15,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '01:30',
        localTime: '03:30'
    },
    {
        timestamp: 1679017500000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 16,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '01:45',
        localTime: '03:45'
    },
    {
        timestamp: 1679018400000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 17,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '02:00',
        localTime: '04:00'
    },
    {
        timestamp: 1679019300000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 18,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '02:15',
        localTime: '04:15'
    },
    {
        timestamp: 1679020200000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 19,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '02:30',
        localTime: '04:30'
    },
    {
        timestamp: 1679021100000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 20,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '02:45',
        localTime: '04:45'
    },
    {
        timestamp: 1679022000000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 21,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '03:00',
        localTime: '05:00'
    },
    {
        timestamp: 1679022900000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 22,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '03:15',
        localTime: '05:15'
    },
    {
        timestamp: 1679023800000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 23,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '03:30',
        localTime: '05:30'
    },
    {
        timestamp: 1679024700000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 24,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '03:45',
        localTime: '05:45'
    },
    {
        timestamp: 1679025600000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 25,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '04:00',
        localTime: '06:00'
    },
    {
        timestamp: 1679026500000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 26,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '04:15',
        localTime: '06:15'
    },
    {
        timestamp: 1679027400000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 27,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '04:30',
        localTime: '06:30'
    },
    {
        timestamp: 1679028300000,
        P10: 0.001,
        P25: 0.001,
        P50: 0.001,
        P75: 0.001,
        P90: 0.001,
        value: 0.9263997,
        index: 28,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '04:45',
        localTime: '06:45'
    },
    {
        timestamp: 1679029200000,
        P10: 0.003,
        P25: 0.003,
        P50: 0.003,
        P75: 0.003,
        P90: 0.003,
        value: 2.787142,
        index: 29,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '05:00',
        localTime: '07:00'
    },
    {
        timestamp: 1679030100000,
        P10: 0.008,
        P25: 0.008,
        P50: 0.008,
        P75: 0.008,
        P90: 0.008,
        value: 7.5960836,
        index: 30,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '05:15',
        localTime: '07:15'
    },
    {
        timestamp: 1679031000000,
        P10: 0.014,
        P25: 0.014,
        P50: 0.014,
        P75: 0.014,
        P90: 0.014,
        value: 13.822547,
        index: 31,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '05:30',
        localTime: '07:30'
    },
    {
        timestamp: 1679031900000,
        P10: 0.023,
        P25: 0.023,
        P50: 0.023,
        P75: 0.023,
        P90: 0.023,
        value: 23.059847,
        index: 32,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '05:45',
        localTime: '07:45'
    },
    {
        timestamp: 1679032800000,
        P10: 0.036,
        P25: 0.036,
        P50: 0.036,
        P75: 0.036,
        P90: 0.036,
        value: 36.358143,
        index: 33,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '06:00',
        localTime: '08:00'
    },
    {
        timestamp: 1679033700000,
        P10: 0.049,
        P25: 0.049,
        P50: 0.049,
        P75: 0.049,
        P90: 0.049,
        value: 49.137833,
        index: 34,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '06:15',
        localTime: '08:15'
    },
    {
        timestamp: 1679034600000,
        P10: 0.067,
        P25: 0.067,
        P50: 0.067,
        P75: 0.067,
        P90: 0.067,
        value: 66.61935,
        index: 35,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '06:30',
        localTime: '08:30'
    },
    {
        timestamp: 1679035500000,
        P10: 0.082,
        P25: 0.082,
        P50: 0.082,
        P75: 0.082,
        P90: 0.082,
        value: 81.55116,
        index: 36,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '06:45',
        localTime: '08:45'
    },
    {
        timestamp: 1679036400000,
        P10: 0.094,
        P25: 0.094,
        P50: 0.094,
        P75: 0.094,
        P90: 0.094,
        value: 93.67657,
        index: 37,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '07:00',
        localTime: '09:00'
    },
    {
        timestamp: 1679037300000,
        P10: 0.103,
        P25: 0.103,
        P50: 0.103,
        P75: 0.103,
        P90: 0.103,
        value: 103.13016,
        index: 38,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '07:15',
        localTime: '09:15'
    },
    {
        timestamp: 1679038200000,
        P10: 0.115,
        P25: 0.115,
        P50: 0.115,
        P75: 0.115,
        P90: 0.115,
        value: 115.30726,
        index: 39,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '07:30',
        localTime: '09:30'
    },
    {
        timestamp: 1679039100000,
        P10: 0.125,
        P25: 0.125,
        P50: 0.125,
        P75: 0.125,
        P90: 0.125,
        value: 125.350624,
        index: 40,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '07:45',
        localTime: '09:45'
    },
    {
        timestamp: 1679040000000,
        P10: 0.137,
        P25: 0.137,
        P50: 0.137,
        P75: 0.137,
        P90: 0.137,
        value: 137.4084,
        index: 41,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '08:00',
        localTime: '10:00'
    },
    {
        timestamp: 1679040900000,
        P10: 0.143,
        P25: 0.143,
        P50: 0.143,
        P75: 0.143,
        P90: 0.143,
        value: 142.7711,
        index: 42,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '08:15',
        localTime: '10:15'
    },
    {
        timestamp: 1679041800000,
        P10: 0.148,
        P25: 0.148,
        P50: 0.148,
        P75: 0.148,
        P90: 0.148,
        value: 148.37132,
        index: 43,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '08:30',
        localTime: '10:30'
    },
    {
        timestamp: 1679042700000,
        P10: 0.159,
        P25: 0.159,
        P50: 0.159,
        P75: 0.159,
        P90: 0.159,
        value: 159.19896,
        index: 44,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '08:45',
        localTime: '10:45'
    },
    {
        timestamp: 1679043600000,
        P10: 0.163,
        P25: 0.163,
        P50: 0.163,
        P75: 0.163,
        P90: 0.163,
        value: 163.0331,
        index: 45,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '09:00',
        localTime: '11:00'
    },
    {
        timestamp: 1679044500000,
        P10: 0.167,
        P25: 0.167,
        P50: 0.167,
        P75: 0.167,
        P90: 0.167,
        value: 166.91663,
        index: 46,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '09:15',
        localTime: '11:15'
    },
    {
        timestamp: 1679045400000,
        P10: 0.168,
        P25: 0.168,
        P50: 0.168,
        P75: 0.168,
        P90: 0.168,
        value: 168.33586,
        index: 47,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '09:30',
        localTime: '11:30'
    },
    {
        timestamp: 1679046300000,
        P10: 0.171,
        P25: 0.171,
        P50: 0.171,
        P75: 0.171,
        P90: 0.171,
        value: 171.25356,
        index: 48,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '09:45',
        localTime: '11:45'
    },
    {
        timestamp: 1679047200000,
        P10: 0.173,
        P25: 0.173,
        P50: 0.173,
        P75: 0.173,
        P90: 0.173,
        value: 172.52023,
        index: 49,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '10:00',
        localTime: '12:00'
    },
    {
        timestamp: 1679048100000,
        P10: 0.173,
        P25: 0.173,
        P50: 0.173,
        P75: 0.173,
        P90: 0.173,
        value: 173.01062,
        index: 50,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '10:15',
        localTime: '12:15'
    },
    {
        timestamp: 1679049000000,
        P10: 0.174,
        P25: 0.174,
        P50: 0.174,
        P75: 0.174,
        P90: 0.174,
        value: 173.75168,
        index: 51,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '10:30',
        localTime: '12:30'
    },
    {
        timestamp: 1679049900000,
        P10: 0.174,
        P25: 0.174,
        P50: 0.174,
        P75: 0.174,
        P90: 0.174,
        value: 173.65756,
        index: 52,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '10:45',
        localTime: '12:45'
    },
    {
        timestamp: 1679050800000,
        P10: 0.173,
        P25: 0.173,
        P50: 0.173,
        P75: 0.173,
        P90: 0.173,
        value: 173.03255,
        index: 53,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '11:00',
        localTime: '13:00'
    },
    {
        timestamp: 1679051700000,
        P10: 0.172,
        P25: 0.172,
        P50: 0.172,
        P75: 0.172,
        P90: 0.172,
        value: 171.89743,
        index: 54,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '11:15',
        localTime: '13:15'
    },
    {
        timestamp: 1679052600000,
        P10: 0.168,
        P25: 0.168,
        P50: 0.168,
        P75: 0.168,
        P90: 0.168,
        value: 168.24103,
        index: 55,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '11:30',
        localTime: '13:30'
    },
    {
        timestamp: 1679053500000,
        P10: 0.165,
        P25: 0.165,
        P50: 0.165,
        P75: 0.165,
        P90: 0.165,
        value: 165.19232,
        index: 56,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '11:45',
        localTime: '13:45'
    },
    {
        timestamp: 1679054400000,
        P10: 0.165,
        P25: 0.165,
        P50: 0.165,
        P75: 0.165,
        P90: 0.165,
        value: 165.2036,
        index: 57,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '12:00',
        localTime: '14:00'
    },
    {
        timestamp: 1679055300000,
        P10: 0.162,
        P25: 0.162,
        P50: 0.162,
        P75: 0.162,
        P90: 0.162,
        value: 162.00427,
        index: 58,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '12:15',
        localTime: '14:15'
    },
    {
        timestamp: 1679056200000,
        P10: 0.154,
        P25: 0.154,
        P50: 0.154,
        P75: 0.154,
        P90: 0.154,
        value: 153.56593,
        index: 59,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '12:30',
        localTime: '14:30'
    },
    {
        timestamp: 1679057100000,
        P10: 0.147,
        P25: 0.147,
        P50: 0.147,
        P75: 0.147,
        P90: 0.147,
        value: 147.24255,
        index: 60,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '12:45',
        localTime: '14:45'
    },
    {
        timestamp: 1679058000000,
        P10: 0.142,
        P25: 0.142,
        P50: 0.142,
        P75: 0.142,
        P90: 0.142,
        value: 141.92241,
        index: 61,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '13:00',
        localTime: '15:00'
    },
    {
        timestamp: 1679058900000,
        P10: 0.136,
        P25: 0.136,
        P50: 0.136,
        P75: 0.136,
        P90: 0.136,
        value: 136.19238,
        index: 62,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '13:15',
        localTime: '15:15'
    },
    {
        timestamp: 1679059800000,
        P10: 0.129,
        P25: 0.129,
        P50: 0.129,
        P75: 0.129,
        P90: 0.129,
        value: 129.02187,
        index: 63,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '13:30',
        localTime: '15:30'
    },
    {
        timestamp: 1679060700000,
        P10: 0.118,
        P25: 0.118,
        P50: 0.118,
        P75: 0.118,
        P90: 0.118,
        value: 118.411644,
        index: 64,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '13:45',
        localTime: '15:45'
    },
    {
        timestamp: 1679061600000,
        P10: 0.105,
        P25: 0.105,
        P50: 0.105,
        P75: 0.105,
        P90: 0.105,
        value: 105.19204,
        index: 65,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '14:00',
        localTime: '16:00'
    },
    {
        timestamp: 1679062500000,
        P10: 0.092,
        P25: 0.092,
        P50: 0.092,
        P75: 0.092,
        P90: 0.092,
        value: 92.3033,
        index: 66,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '14:15',
        localTime: '16:15'
    },
    {
        timestamp: 1679063400000,
        P10: 0.083,
        P25: 0.083,
        P50: 0.083,
        P75: 0.083,
        P90: 0.083,
        value: 82.52717,
        index: 67,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '14:30',
        localTime: '16:30'
    },
    {
        timestamp: 1679064300000,
        P10: 0.068,
        P25: 0.068,
        P50: 0.068,
        P75: 0.068,
        P90: 0.068,
        value: 68.239525,
        index: 68,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '14:45',
        localTime: '16:45'
    },
    {
        timestamp: 1679065200000,
        P10: 0.056,
        P25: 0.056,
        P50: 0.056,
        P75: 0.056,
        P90: 0.056,
        value: 56.246777,
        index: 69,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '15:00',
        localTime: '17:00'
    },
    {
        timestamp: 1679066100000,
        P10: 0.036,
        P25: 0.036,
        P50: 0.036,
        P75: 0.036,
        P90: 0.036,
        value: 36.29047,
        index: 70,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '15:15',
        localTime: '17:15'
    },
    {
        timestamp: 1679067000000,
        P10: 0.024,
        P25: 0.024,
        P50: 0.024,
        P75: 0.024,
        P90: 0.024,
        value: 23.75035,
        index: 71,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '15:30',
        localTime: '17:30'
    },
    {
        timestamp: 1679067900000,
        P10: 0.012,
        P25: 0.012,
        P50: 0.012,
        P75: 0.012,
        P90: 0.012,
        value: 12.312423,
        index: 72,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '15:45',
        localTime: '17:45'
    },
    {
        timestamp: 1679068800000,
        P10: 0.006,
        P25: 0.006,
        P50: 0.006,
        P75: 0.006,
        P90: 0.006,
        value: 6.1497717,
        index: 73,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '16:00',
        localTime: '18:00'
    },
    {
        timestamp: 1679069700000,
        P10: 0.002,
        P25: 0.002,
        P50: 0.002,
        P75: 0.002,
        P90: 0.002,
        value: 2.0021484,
        index: 74,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '16:15',
        localTime: '18:15'
    },
    {
        timestamp: 1679070600000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 75,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '16:30',
        localTime: '18:30'
    },
    {
        timestamp: 1679071500000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 76,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '16:45',
        localTime: '18:45'
    },
    {
        timestamp: 1679072400000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 77,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '17:00',
        localTime: '19:00'
    },
    {
        timestamp: 1679073300000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 78,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '17:15',
        localTime: '19:15'
    },
    {
        timestamp: 1679074200000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 79,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '17:30',
        localTime: '19:30'
    },
    {
        timestamp: 1679075100000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 80,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '17:45',
        localTime: '19:45'
    },
    {
        timestamp: 1679076000000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 81,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '18:00',
        localTime: '20:00'
    },
    {
        timestamp: 1679076900000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 82,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '18:15',
        localTime: '20:15'
    },
    {
        timestamp: 1679077800000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 83,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '18:30',
        localTime: '20:30'
    },
    {
        timestamp: 1679078700000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 84,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '18:45',
        localTime: '20:45'
    },
    {
        timestamp: 1679079600000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 85,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '19:00',
        localTime: '21:00'
    },
    {
        timestamp: 1679080500000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 86,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '19:15',
        localTime: '21:15'
    },
    {
        timestamp: 1679081400000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 87,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '19:30',
        localTime: '21:30'
    },
    {
        timestamp: 1679082300000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 88,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '19:45',
        localTime: '21:45'
    },
    {
        timestamp: 1679083200000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 89,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '20:00',
        localTime: '22:00'
    },
    {
        timestamp: 1679084100000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 90,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '20:15',
        localTime: '22:15'
    },
    {
        timestamp: 1679085000000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 91,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '20:30',
        localTime: '22:30'
    },
    {
        timestamp: 1679085900000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 92,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '20:45',
        localTime: '22:45'
    },
    {
        timestamp: 1679086800000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 93,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '21:00',
        localTime: '23:00'
    },
    {
        timestamp: 1679087700000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 94,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '21:15',
        localTime: '23:15'
    },
    {
        timestamp: 1679088600000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 95,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '21:30',
        localTime: '23:30'
    },
    {
        timestamp: 1679089500000,
        P10: 0,
        P25: 0,
        P50: 0,
        P75: 0,
        P90: 0,
        value: 0,
        index: 96,
        date: '17.03.2023',
        localDate: '17.03.2023',
        time: '21:45',
        localTime: '23:45'
    }
];
