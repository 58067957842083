import i18next from 'i18next';
import { toast } from 'react-toastify';
import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../../../utils/axios';
import { Consumer } from '../../../../interfaces/apiv2';
import { getForecastDataChartThunk } from '../../helpers';
import { GetParams } from '../../../../interfaces/general';
import ToastifyType from '../../../../utils/toastify-config';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { sortForecastData, transformForecastToView } from '../../../../utils/forecast';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../../../interfaces/redux';
import { FORECAST_TYPE } from '../../../../interfaces/uiv2';

interface ConsumerPage extends DefaultInitialState {
    consumer: Consumer | null;
    getParams: GetParams;
    loadingForecast: boolean;
    successForecast: boolean;
    errorForecast: boolean;
    loadingHistorical: boolean;
    successHistorical: boolean;
    errorHistorical: boolean;
    forecasts: LineData[][];
}

const initialState: ConsumerPage = {
    consumer: null,
    loading: false,
    error: false,
    success: false,
    loadingForecast: false,
    successForecast: false,
    errorForecast: false,
    loadingHistorical: false,
    successHistorical: false,
    errorHistorical: false,
    forecasts: [[], []], // [forecast, historical]
    getParams: DEFAULT_GET_PARAMS_VALUE
};

export const getConsumerForecastAsync = getForecastDataChartThunk({
    thunkName: 'consumers/forecast',
    endpoint: `/electricity/demand/consumers/{project_id}/forecast/{consumer_id}`
});

export const getConsumerHistoricalAsync = getForecastDataChartThunk({
    thunkName: 'consumers/historical',
    endpoint: `/electricity/demand/consumers/{project_id}/historical/{consumer_id}`
});

export const getConsumerAsync = createAsyncThunk(
    'consumers/show',
    async (values: { id: string; projectId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<Consumer>(
                `/electricity/demand/${values.projectId}/consumers/${values.id}`
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const consumerPage = createSlice({
    name: 'consumerPage',
    initialState,
    reducers: {
        resetConsumerPageState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        resetConsumerForecastData: (state) => {
            state.forecasts = [[], []];
            state.errorForecast = false;
            state.loadingForecast = false;
            state.successForecast = false;
            state.errorHistorical = false;
            state.loadingHistorical = false;
            state.successHistorical = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getConsumerAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getConsumerAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.consumer = payload;
        });
        builder.addCase(getConsumerAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getConsumerForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getConsumerForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            const actualData = (current(state).forecasts || [])[0] || [];
            state.forecasts[0] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.ELECTRICITY),
                ...actualData
            ]);
        });
        builder.addCase(getConsumerForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
        builder.addCase(getConsumerHistoricalAsync.pending, (state) => {
            state.loadingHistorical = true;
            state.errorHistorical = false;
            state.successHistorical = false;
        });
        builder.addCase(getConsumerHistoricalAsync.fulfilled, (state, { payload }) => {
            state.loadingHistorical = false;
            state.errorHistorical = false;
            state.successHistorical = true;
            const actualData = (current(state).forecasts || [])[1] || [];
            state.forecasts[1] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.ELECTRICITY),
                ...actualData
            ]);
        });
        builder.addCase(getConsumerHistoricalAsync.rejected, (state) => {
            state.loadingHistorical = false;
            state.errorHistorical = true;
            state.successHistorical = false;
        });
    }
});

export const {
    resetConsumerPageState,
    setGetParams: setGetConsumerParams,
    resetConsumerForecastData
} = consumerPage.actions;

export default consumerPage.reducer;
