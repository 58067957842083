import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const deleteProsumersListAsync = createAsyncThunk(
    'prosumers_list/delete',
    async (ids: string[], { rejectWithValue }) => {
        try {
            await Promise.all(
                ids.map((id) => {
                    return axios.delete(`/electricity/generation/prosumer/${id}`);
                })
            );
            return null;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const deleteProsumers = createSlice({
    name: 'deleteProsumers',
    initialState,
    reducers: {
        resetDeleteProsumerListState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(deleteProsumersListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(deleteProsumersListAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(deleteProsumersListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetDeleteProsumerListState } = deleteProsumers.actions;

export default deleteProsumers.reducer;
