import listConsumers from './electricity/consumers/listConsumers';
import listGasConsumers from './gas/consumers/listGasConsumers';
import listGasProfiles from './gas/profiles/listGasProfiles';
import listGasClusters from './gas/clusters/listGasClusters';
import listClusters from './electricity/clusters/listClusters';
import listProfiles from './electricity/profiles/listProfiles';
import listSegments from './electricity/segments/listSegments';
import listGasDashboard from './gas/dashboard/listGasDashboard';

import deleteConsumers from './electricity/consumers/deleteConsumers';
import deleteGasConsumers from './gas/consumers/deleteGasConsumers';
import deleteGasProfiles from './gas/profiles/deleteGasProfiles';
import deleteGasClusters from './gas/clusters/deleteGasClusters';
import deleteClusters from './electricity/clusters/deleteClusters';
import deleteProfiles from './electricity/profiles/deleteProfiles';
import deleteSegments from './electricity/segments/deleteSegments';

import consumerPage from './electricity/consumers/consumerPage';
import gasConsumerPage from './gas/consumers/gasConsumerPage';
import gasProfilePage from './gas/profiles/gasProfilePage';
import gasClusterPage from './gas/clusters/gasClusterPage';
import profilePage from './electricity/profiles/profilePage';
import clusterPage from './electricity/clusters/clusterPage';
import segmentPage from './electricity/segments/segmentPage';
import deleteFormulas from './electricity/profiles/deleteProfileFormula';

import createProfiles from './gas/profiles/createProfiles';
import { api } from '../api';
import { GasConsumer, GasDSO, PaginatedApiResponse } from '../../interfaces/apiv2';

export * from './gas/profiles/createProfiles';

export * from './electricity/consumers/listConsumers';
export * from './gas/consumers/listGasConsumers';
export * from './gas/profiles/listGasProfiles';
export * from './gas/clusters/listGasClusters';
export * from './electricity/clusters/listClusters';
export * from './electricity/profiles/listProfiles';
export * from './electricity/segments/listSegments';

export * from './electricity/consumers/deleteConsumers';
export * from './gas/consumers/deleteGasConsumers';
export * from './gas/profiles/deleteGasProfiles';
export * from './gas/clusters/deleteGasClusters';
export * from './electricity/clusters/deleteClusters';
export * from './electricity/profiles/deleteProfiles';
export * from './electricity/segments/deleteSegments';
export * from './electricity/profiles/deleteProfileFormula';

export * from './electricity/consumers/consumerPage';
export * from './gas/consumers/gasConsumerPage';
export * from './gas/profiles/gasProfilePage';
export * from './gas/clusters/gasClusterPage';
export * from './electricity/profiles/profilePage';
export * from './electricity/clusters/clusterPage';
export * from './electricity/segments/segmentPage';

export const INTRATA_CLUSTER_ID = 'dc389891-d2c6-41ee-8927-3b0e11191bdb';

export const demandApi = api.injectEndpoints({
    endpoints: (build) => ({
        getGasDso: build.query({
            query: (params) => {
                if (params.search?.trim()) {
                    return {
                        url: `/gas/demand/${params.projectId}/dsos`,
                        method: 'get',
                        params: { ...params, search: params.search },
                        data: undefined
                    };
                }

                return { url: `/gas/demand/${params.projectId}/dsos`, method: 'get', params, data: undefined };
            },
            transformResponse: (rawData: PaginatedApiResponse<GasDSO>): GasDSO[] => {
                return rawData.items;
            }
        }),
        getIntrataForecast: build.query({
            query: (params) => {
                return {
                    url: `/gas/demand/${params.projectId}/clusters/${INTRATA_CLUSTER_ID}`,
                    method: 'get',
                    params,
                    data: undefined
                };
            },
            transformResponse: (rawData: GasConsumer): number[] => {
                return rawData.latest_forecasts || [];
            }
        })
    })
});

export const { useGetGasDsoQuery, useGetIntrataForecastQuery } = demandApi;

export default {
    createGasProfiles: createProfiles,
    deleteConsumers,
    deleteGasConsumers,
    deleteGasProfiles,
    deleteGasClusters,
    deleteFormulas,
    deleteSegments,
    deleteClusters,
    deleteProfiles,
    listConsumers,
    listGasConsumers,
    listGasDashboard,
    listGasProfiles,
    listGasClusters,
    listClusters,
    listProfiles,
    listSegments,
    consumerPage,
    gasConsumerPage,
    gasProfilePage,
    gasClusterPage,
    profilePage,
    clusterPage,
    segmentPage
};
