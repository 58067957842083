import { DefaultEntityTimestamps, GenerationAsset, User } from './index';

interface NotificationContent {
    message: string;
    metadata: any;
}

export enum NOTIFICATION_TYPE {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS'
}

export interface DeliveredNotifications extends DefaultEntityTimestamps {
    id: string;
    user: User;
    type: NOTIFICATION_TYPE;
    asset: GenerationAsset;
    content: NotificationContent;
    user_id: string;
    asset_id: string;
    client_id: string;
    report_id: string;
    forecast_id: string;
    acknowledged: boolean;
    context_user_id: string;
    client: any;
    report: any;
    forecast: any;
    context_user: any;
    demand_report: any;
    demand_report_id: string;
}
