import { SyntheticEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Typography, InputLabel, Autocomplete } from '@mui/material';

import { MODAL_IDS } from '../index';
import { ROUTES } from '../../utils/config';
import { ProjectsData } from '../../interfaces/apiv2';
import { ModalContext } from '../../hooks/modalContext';
import { getProjectModuleIcon } from '../../pages/ProjectsList/helpers';

import style from './style.module.scss';

const DemandForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleClose, payload } = useContext(ModalContext);

    const handleOnChange = (e: SyntheticEvent<Element, Event>, value: ProjectsData | null) => {
        if (payload.path) {
            navigate(payload.path.replace(':projectId', value!.id));
        } else {
            navigate(ROUTES.AI_DEMAND_CONSUMERS.replace(':projectId', value!.id));
        }

        if (payload.callback) {
            payload.callback(value);
        }

        handleClose(MODAL_IDS.DEMAND_MODAL);
        return value;
    };

    return (
        <Box className={style.container}>
            <Typography variant="h6" className={style.title}>
                {/* {true ? t('header.evs') : t('header.demand')} */}
                {t(`header.${payload.projectMetadata.type}`)}
            </Typography>
            <Box>
                <InputLabel variant="standard" shrink htmlFor={t('form.label.project')}>
                    {t('form.label.selectProject')}
                </InputLabel>

                <Autocomplete
                    id="project"
                    onChange={handleOnChange}
                    noOptionsText={t('select.noOptions')}
                    options={payload.projects || []}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, project) => (
                        <Typography variant="small1" {...props} key={project.id}>
                            <Box component="span" className="m-r-1">
                                {getProjectModuleIcon(project.module)}
                            </Box>
                            {project.name}
                        </Typography>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="project"
                            inputProps={{
                                ...params.inputProps,
                                placeholder: t('form.label.project')
                            }}
                        />
                    )}
                />
            </Box>
        </Box>
    );
};

export default DemandForm;
