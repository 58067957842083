import {
    Box,
    Input,
    Stack,
    Select,
    Divider,
    MenuItem,
    TextField,
    Typography,
    InputLabel,
    Autocomplete,
    FormHelperText
} from '@mui/material';
import Flag from 'react-world-flags';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import {
    getProjectFormSchema,
    getProjectFormDataDefaultValue,
    transformProjectFormDataToPostBody,
    transformToProjectSchema,
    transformProjectFormDataToPatchBody
} from './helpers';
import { MODAL_IDS } from '../index';
import { hasAccessTo } from '../../utils/user';
import { useFormLogic } from '../../hooks/useFormLogic';
import { FormModalActions } from '../../components/FormUtils';
import { useAppDispatch, useAppSelector } from '../../stores';
import { PROJECT_CURRENCY, PROJECT_MODULE, PROJECT_TYPE } from '../../interfaces/apiv2';
import { COUNTRIES_LIST, CountryItem } from '../../utils/countriesList';
import { getClientsAsync, useGetClientsQuery } from '../../stores/Clients';
import AssetStatusLabel from '../../components/AssetStatusLabel/AssetStatusLabel';
import { createProjectsAsync, updateProjectsAsync } from '../../stores/Projects';
import { ASSET_STATUS, ProjectFormFields, ProjectsDataFE } from '../../interfaces/uiv2';
import { getProjectModuleIcon, getProjectTypeIcon } from '../../pages/ProjectsList/helpers';

import style from './style.module.scss';

const ProjectForm = ({ project }: { project?: ProjectsDataFE }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { user } = useAppSelector((state) => state.login);
    const { loading } = useAppSelector((state) => state.createProjects);
    const { loading: patchLoading } = useAppSelector((state) => state.updateProjects);

    const getParams = useAppSelector((state) => state.listClients.getParams, isEqual);

    const schema = getProjectFormSchema(t);
    const { data: clients } = useGetClientsQuery(
        { page_size: 50, page: 1 },
        { skip: !hasAccessTo(user!, ['Admin.Clients.CREATE']) }
    );

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty },
        reset
    } = useFormLogic<any, ProjectFormFields, any, ProjectFormFields>({
        schema,
        data: project,
        defaultValues: getProjectFormDataDefaultValue(project),
        transformFn: transformToProjectSchema
    });

    const init = useCallback(() => dispatch(getClientsAsync(getParams)), [getParams]);

    const onSubmit = (formData: ProjectFormFields) => {
        if (project) {
            const patch = transformProjectFormDataToPatchBody(formData, project);
            dispatch(updateProjectsAsync(patch));
            return;
        }
        const post = transformProjectFormDataToPostBody(formData);
        dispatch(createProjectsAsync(post));
    };

    const onError = (err: any) => {
        console.error('Error submitting form', err);
    };

    useEffect(() => {
        init();
        return reset;
    }, [getParams]);

    return (
        <Box className={style.container} component="form" noValidate onSubmit={handleSubmit(onSubmit, onError)}>
            <Typography variant="h6" className={style.title}>
                {t('header.addNewProject')}
            </Typography>

            {/* name & type */}
            <Stack direction="row" justifyContent="space-between" spacing={4} width="100%">
                <Box className={style.formInput}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.name')}>
                                    {t('form.label.name')}
                                </InputLabel>
                                <Input
                                    {...field}
                                    fullWidth
                                    id={t('form.label.name')}
                                    placeholder={t('form.label.name')}
                                />
                                {errors.name && <FormHelperText error>{t('form.errors.generic')}</FormHelperText>}
                            </>
                        )}
                    />
                </Box>
                <Box className={style.formInput}>
                    <Controller
                        name="client"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.client')}>
                                    {t('form.label.client')}
                                </InputLabel>

                                <Autocomplete
                                    {...field}
                                    id="client"
                                    value={field.value}
                                    onChange={(e, value) => {
                                        field.onChange(value);
                                        return value;
                                    }}
                                    noOptionsText={t('select.noOptions')}
                                    options={clients || []}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, client) => (
                                        <Typography variant="small1" {...props}>
                                            {client.name}
                                        </Typography>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="client"
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: t('form.label.client')
                                            }}
                                        />
                                    )}
                                />
                                {errors.client && <FormHelperText error>{t('form.errors.generic')}</FormHelperText>}
                            </>
                        )}
                    ></Controller>
                </Box>
            </Stack>

            <Stack direction="row" justifyContent="space-between" spacing={4} width="100%">
                <Box className={classnames(style.formInput, style.select)}>
                    <Controller
                        name="module"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.module')}>
                                    {t('form.label.module')}
                                </InputLabel>

                                <Select
                                    {...field}
                                    fullWidth
                                    labelId="asset-module-label"
                                    id="asset-module-id"
                                    label={t('form.module.type')}
                                >
                                    {Object.values(PROJECT_MODULE).map((type) => (
                                        <MenuItem value={type} key={type}>
                                            {getProjectModuleIcon(type)}
                                            <Typography className={style.ml1} variant="small1">
                                                {t(`projectModules.${type}`)}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    ></Controller>
                </Box>
                <Box className={classnames(style.formInput, style.select)}>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.type')}>
                                    {t('form.label.type')}
                                </InputLabel>

                                <Select
                                    {...field}
                                    fullWidth
                                    labelId="asset-type-label"
                                    id="asset-type-id"
                                    label={t('form.label.type')}
                                >
                                    {Object.values(PROJECT_TYPE).map((type) => (
                                        <MenuItem className={style.dropdownMenuItem} value={type} key={type}>
                                            {getProjectTypeIcon(type)}
                                            <Typography className={style.ml1} variant="small1">
                                                {t(`projectTypes.${type}`)}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    ></Controller>
                </Box>
            </Stack>

            <Stack direction="row" justifyContent="space-between" spacing={4} width="100%">
                <Box className={style.formInput}>
                    <Controller
                        name="country"
                        control={control}
                        defaultValue={null as unknown as CountryItem}
                        render={({ field }) => {
                            return (
                                <>
                                    <InputLabel variant="standard" required shrink htmlFor="country">
                                        {t('form.label.country')}
                                    </InputLabel>

                                    <Autocomplete
                                        value={field?.value}
                                        id="country"
                                        onChange={(e, v) => {
                                            field.onChange(v);
                                            return v;
                                        }}
                                        autoHighlight
                                        disableClearable
                                        options={COUNTRIES_LIST}
                                        popupIcon={<MdKeyboardArrowDown color="#6A9BFF" />}
                                        noOptionsText={t('select.noOptions')}
                                        getOptionLabel={(option) => option.label}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderOption={(props, country) => (
                                            <Box {...props} component="li">
                                                <Flag
                                                    width="24"
                                                    height="24"
                                                    code={country.code}
                                                    className={classnames('flagIcon', 'm-r-1')}
                                                />
                                                {country.label}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: field.value?.code ? (
                                                        <Flag
                                                            width="24"
                                                            height="24"
                                                            code={field.value.code}
                                                            className={classnames('flagIcon', 'm-r-1')}
                                                        />
                                                    ) : null
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: t('form.label.country')
                                                }}
                                            />
                                        )}
                                    />

                                    {errors.country && (
                                        <FormHelperText error>
                                            {/* todo: fix this 👇 (types) */}
                                            {(errors.country as { [key: string]: string })?.message}
                                        </FormHelperText>
                                    )}
                                </>
                            );
                        }}
                    />
                </Box>
                <Box className={classnames(style.formInput, style.calendar)}>
                    <Controller
                        name="date_added"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.dateAdded')}>
                                    {t('form.label.dateAdded')}
                                </InputLabel>

                                <DesktopDatePicker
                                    {...field}
                                    inputFormat="DD|MM|YYYY"
                                    renderInput={(params) => (
                                        <TextField
                                            id="date_added"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.date_added && (
                                    <FormHelperText error>{t('form.errors.invalidDate')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>
            </Stack>

            {/* status */}
            <Stack direction="row" spacing={4} justifyContent="space-between" width="100%">
                <Box className={classnames(style.formInput, style.select)}>
                    <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.status')}>
                                    {t('form.label.status')}
                                </InputLabel>

                                <Select
                                    {...field}
                                    fullWidth
                                    disabled
                                    labelId="status-label"
                                    id="project-status"
                                    label={t('form.label.status')}
                                >
                                    {Object.values(ASSET_STATUS).map((status: ASSET_STATUS) => {
                                        return (
                                            <MenuItem key={status} value={status}>
                                                <AssetStatusLabel status={status}></AssetStatusLabel>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </>
                        )}
                    ></Controller>
                </Box>

                <Box className={classnames(style.formInput, style.select)}>
                    <Controller
                        name="default_currency"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.currency')}>
                                    {t('form.label.currency')}
                                </InputLabel>

                                <Select
                                    {...field}
                                    fullWidth
                                    labelId="currency-label"
                                    id="currency-id"
                                    label={t('form.module.currency')}
                                >
                                    {Object.values(PROJECT_CURRENCY).map((type) => (
                                        <MenuItem value={type} key={type}>
                                            <Typography variant="small1">{t(`projectCurrencies.${type}`)}</Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    ></Controller>
                </Box>
            </Stack>

            <Divider sx={{ mt: 4 }} />

            <FormModalActions
                loading={loading || patchLoading}
                disabled={!isDirty}
                modalId={MODAL_IDS.CREATE_ASSET_MODAL}
                note={t('mandatoryFields')}
                cancelLabel={t('nevermind')}
                saveLabel={t('form.label.submit')}
            />
        </Box>
    );
};

export default ProjectForm;
