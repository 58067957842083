import { isEqual } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo } from 'react';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { MdDeleteOutline, MdOutlineRefresh, MdAdd } from 'react-icons/md';

import {
    setSelectedVPPs,
    getListVPPAssetsAsync,
    deleteVPPAsync,
    setGetParamsVPPAssets,
    resetlistVPPState,
    deleteVPPMultipleAsync
} from '../../stores/VPP';
import { VPPFE } from '../../interfaces/uiv2';
import { MODAL_IDS } from '../../modals';
import { styleguide } from '../../containers/Theme';
import { ModalContext } from '../../hooks/modalContext';
import useStickyHeader from '../../hooks/useStickyHeader';
import { useAppDispatch, useAppSelector } from '../../stores';
import { PageContainer } from '../../layout/PageContainer';
import { getTableHeadCellsVPPAssets, prosumersVpp, staticVpp } from './helpers';
import { GenericTable, TableSearch } from '../../components/BaseTable';
import CreateVPPModal from '../../modals/CreateVPPModal/CreateVPPModal';
import { transformOrderByToSort } from '../../components/BaseTable/GenericTable/helpers';
import { DeleteAssetFromVppConfirmationModal } from '../../modals/DeleteAssetFromVppConfirmationModal';

import style from './style.module.scss';
import { ROUTES } from '../../utils/config';

const VPPList = () => {
    useStickyHeader('vpp-list-header');

    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const { handleClickOpen } = useContext(ModalContext);
    const { t: tList } = useTranslation('assets/vpps');

    const { user } = useAppSelector((state) => state.login);
    const { VPPCreated } = useAppSelector((state) => state.createVPP);
    const { assetsDeleted } = useAppSelector((state) => state.deleteVPP);
    const getParams = useAppSelector((state) => state.listVPP.getParams, isEqual);
    const { selectedVPPs, vppFE, loading, pager, success } = useAppSelector((state) => state.listVPP);

    const headCells = getTableHeadCellsVPPAssets({ tList });
    const rows = useMemo(() => {
        if (user?.superadmin) {
            return [staticVpp, prosumersVpp, ...vppFE];
        } else {
            return vppFE;
        }
    }, [vppFE]);

    useEffect(() => {
        dispatch(getListVPPAssetsAsync({ ...getParams }));
    }, [getParams, assetsDeleted, VPPCreated]);

    useEffect(() => {
        return () => {
            dispatch(resetlistVPPState());
        };
    }, []);

    const handleEditVPP = (vpp: VPPFE) => () => {
        handleClickOpen(MODAL_IDS.EDIT_VPP, { vpp });
    };

    const handleDeleteVPP = (row: VPPFE) => () => {
        handleClickOpen(MODAL_IDS.DELETE_VPP_CONFIRMATION_MODAL, {
            deleteItem: () => dispatch(deleteVPPAsync(row.id)),
            item: { ...row },
            rows
        });
    };

    const getVPPPage = (row: VPPFE) => () => {
        if (row.is_mock) {
            return ROUTES.AI_GENERATION_MOCK_VPP_PAGE.replace(':id', row.id);
        }
        const route = pathname.includes(ROUTES.AI_GENERATION_VPP)
            ? ROUTES.AI_GENERATION_VPP_PAGE
            : ROUTES.AI_PROSUMERS_VPP_PAGE;
        return `${route.replace(':vppId', row.id)}`;
    };

    const handleDeleteSelected = () => {
        const toDelete = selectedVPPs.filter((e) => !e.is_implicit).map((grp) => grp.id);
        dispatch(deleteVPPMultipleAsync(toDelete));
        // reset selected in case the user selected vpps that are autogenerated, deselect those.
        dispatch(setSelectedVPPs([]));
    };

    const handleClearSelection = () => {
        dispatch(setSelectedVPPs([]));
    };

    return (
        <>
            <PageContainer>
                <Box id="vpp-list-header" className={style.headerRow}>
                    <Typography variant="h6" className={style.title}>
                        {tList('tableTitle')}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {!!selectedVPPs.length && (
                            <>
                                <Typography variant="small4" className={style.selectedLabel}>
                                    {selectedVPPs.length} {tList('selected')}
                                </Typography>
                                <Button
                                    disableRipple
                                    className={style.button}
                                    variant="action"
                                    onClick={handleClearSelection}
                                    startIcon={
                                        <SvgIcon component={MdOutlineRefresh} sx={{ color: styleguide.primaryColor }} />
                                    }
                                >
                                    {tList('clearSelection')}
                                </Button>
                                <Button
                                    disableRipple
                                    className={style.button}
                                    variant="action"
                                    onClick={handleDeleteSelected}
                                    startIcon={
                                        <SvgIcon component={MdDeleteOutline} sx={{ color: styleguide.primaryColor }} />
                                    }
                                >
                                    {tList('deleteSelected')}
                                </Button>
                            </>
                        )}
                        <TableSearch setGetParams={setGetParamsVPPAssets} />
                        <Button
                            disableRipple
                            variant="cta"
                            sx={{ ml: 1 }}
                            onClick={() => handleClickOpen(MODAL_IDS.CREATE_VPP)}
                        >
                            <MdAdd />
                        </Button>
                    </Box>
                </Box>

                <GenericTable
                    rows={rows}
                    pager={pager}
                    loading={loading}
                    success={success}
                    isCheckboxEnabled
                    columns={headCells}
                    rowUniqueId={'id'}
                    selectedRows={selectedVPPs}
                    setGetParams={setGetParamsVPPAssets}
                    searchParam={getParams.search}
                    setSelectedRows={setSelectedVPPs}
                    storeSort={transformOrderByToSort(getParams.order_by)}
                    handlers={{
                        edit: handleEditVPP,
                        deleteRow: handleDeleteVPP,
                        name: getVPPPage
                    }}
                />
            </PageContainer>
            <DeleteAssetFromVppConfirmationModal />
            <CreateVPPModal />
        </>
    );
};

export default VPPList;
