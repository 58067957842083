import store from 'store';
import { Suspense, useEffect } from 'react';
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import Loading from '../../layout/Loading';
import EditVPPModal from '../../modals/EditVPPModal/EditVPPModal';
import { getSelfAsync } from '../../stores/Auth';
import NProgress from '../../layout/NProgress';
import { Layout } from '../../layout/BaseLayout';
import { useAppDispatch, useAppSelector } from '../../stores';
import { storage, ROUTES } from '../../utils/config';

// Auth pages

import Login from '../../pages/Auth/Login';
import ResetPassword from '../../pages/Auth/ResetPassword';
import ChangePassword from '../../pages/Auth/ChangePassword';

import AdminForecastingPage from '../../pages/AdminForecastingPage/AdminForecastingPage';

import ReportsList from '../../pages/ReportsList/ReportsList';
import ForecastsList from '../../pages/ForecastsList/ForecastsList';
// import GenerationDashboard from '../../pages/Electricity/Generation/GenerationDashboard';

import EVSForecastList from '../../pages/EVS/Forecasts/ForecastList';
import EVSForecastPage from '../../pages/EVS/Forecasts/ForecastPage';

import Map from '../../pages/Map';

import GenerationAssetPage from '../../features/asset-page/AssetPage';

import ReportsPage from '../../pages/ReportsPage/ReportsPage';
import VPPPage from '../../pages/VPPPage/VPPPage';
import AssetList from '../../features/asset-list/AssetList';
import VPPList from '../../features/vpp-list/VPPList';
import Notifications from '../../pages/Notifications/Notifications';
import ListUsers from '../../features/admin-overview/Users/ListUsers';
import ListClients from '../../features/admin-overview/Clients/ListClients';
import AssetListWrapper from '../../features/admin-generation/AssetListWrapper/AssetListWrapper';

// Misc pages
import NotFound from '../../pages/NotFound';
import Forbidden from '../../pages/Forbidden';
import PermissionRoute from './PermissionRoute';
import ForecastPage from '../../pages/ForecastPage/ForecastPage';
import { ClientSupportModal } from '../../modals/ClientSupportModal';
import { AccountSettingsModal } from '../../modals/AccountSettingsModal';
import { CreateReportModal } from '../../modals/CreateReportModal';
import {
    ConsumersList,
    ConsumersPage,
    ClustersList,
    ClustersPage,
    SegmentsList,
    SegmentsPage,
    ProfilesList,
    ProfilesPage
} from '../../pages/Demand';
import ProsumersList from '../../pages/Prosumers/ProsumersList';
import ProsumersPage from '../../pages/Prosumers/ProsumersPage';
import ProjectsList from '../../pages/ProjectsList/ProjectsList';
import DemandDashboard from '../../pages/Demand/DemandDashboard';
import FormulaPage from '../../pages/Demand/Profiles/FormulaPage';
import MockAssetPage from '../../features/asset-page/MockAssetPage';
import ProsumersDashboard from '../../pages/Prosumers/ProsumersDashboard';
import { MockVPPPage } from '../../features/vpp-page-view/MockVPPPageView';
import { MarketsList } from '../../pages/PriceForecasting/MarketsList';
import MarketPricingPage from '../../pages/MarketPricingPage/MarketPricingPage';
import AdminGenerationDashboard from '../../pages/AdminGenerationDashboard/AdminGenerationDashboard';
import {
    TLClustersPage,
    TLClustersList,
    TLConsumersList,
    TLConsumersPage,
    TLCirculationsPage,
    TLCirculationsList
} from '../../pages/TL/';
import DataManagement from '../../features/data-management/DataManagement';
import {
    GasConsumersList,
    GasDemandDashboard,
    GasConsumersPage,
    GasProfilesList,
    GasProfilesPage,
    GasClustersList,
    GasClustersPage
} from '../../pages/Gas-Demand';
import DashboardV2 from '../../pages/DashboardV2';
import { GasForecastList } from '../../features/forecast-list';
import { getActivePathsFromBEPermissions } from '../../utils/user';
import { GasReportsList } from '../../pages/Gas-Demand/GasReportsList';
import { GasReportPage } from '../../pages/Gas-Demand/GasReportPage/GasReportPage';
import ProsumerClustersList from '../../pages/Prosumers/ProsumerClustersList';
import GasDeliveredForecast from '../../pages/DeliveredForecast/GasDeliveredForecast';
import ProsumerClusterPage from '../../pages/Prosumers/ProsumerClustersPage';
import {
    EVSDashboard,
    ChargingStationsList,
    ChargingStationsPage,
    ChargingPointsPage,
    EVSClustersList,
    EVSClustersPage
} from '../../pages/EVS';
import AdminGenerationAssetDelivery from '../../pages/AdminGenerationAssetDelivery/AdminGenerationAssetDelivery';
import EVSReportsList from '../../pages/EVS/EVSReports/EVSReportsList/EVSReportsList';
import { CreateEVSReportModal } from '../../modals/CreateEVSReportModal';
import EVSReportPage from '../../pages/EVS/EVSReports/EVSReportPage/EVSReportPage';

const Router = () => {
    const dispatch = useAppDispatch();

    const token = store.get(storage.token);
    const { loading, user } = useAppSelector((state) => state.login);

    useEffect(() => {
        if (token) {
            dispatch(getSelfAsync());
        }
    }, []);

    if ((token && !user) || loading) {
        return <Loading />;
    }

    const hasElectricityPermission = !!getActivePathsFromBEPermissions(user?.permissions?.Electricity).length;
    const hasEVSPermissions = !!getActivePathsFromBEPermissions(user?.permissions?.EVS).length;
    const hasGasPermission = !!getActivePathsFromBEPermissions(user?.permissions?.Gas).length;

    const renderUserRoutes = () => {
        return (
            <Route
                path={ROUTES.INDEX}
                element={
                    <ProtectedRoute>
                        <Layout>
                            <Outlet />
                            <EditVPPModal />
                            <ClientSupportModal />
                            <AccountSettingsModal />
                            <CreateReportModal />
                            <CreateEVSReportModal />
                        </Layout>
                    </ProtectedRoute>
                }
            >
                {hasElectricityPermission || hasEVSPermissions ? (
                    <Route index element={<Navigate to={ROUTES.AI_DASHBOARD} replace />} />
                ) : hasGasPermission ? (
                    <Route index element={<Navigate to={ROUTES.AI_GAS_DASHBOARD} replace />} />
                ) : null}

                <Route
                    path={ROUTES.ADMIN_ACCOUNTS}
                    element={<Navigate to={ROUTES.ADMIN_ACCOUNTS_USERS} replace={true} />}
                />

                <Route
                    path={ROUTES.ADMIN_ACCOUNTS_USERS}
                    element={
                        <PermissionRoute requiredPermissions={['Admin.Users.WRITE', 'Admin.Users.CREATE']}>
                            <ListUsers />
                        </PermissionRoute>
                    }
                />

                <Route path={ROUTES.ADMIN_FORECASTING_PROJECTS} element={<ProjectsList />}></Route>
                <Route path={ROUTES.DATA_MANAGEMENT} element={<DataManagement />}></Route>
                <Route
                    path={ROUTES.ADMIN_ACCOUNTS_CLIENTS}
                    element={
                        <PermissionRoute requiredPermissions={['Admin.Clients.WRITE', 'Admin.Clients.CREATE']}>
                            <ListClients />
                        </PermissionRoute>
                    }
                />

                <Route
                    path={ROUTES.ADMIN_GENERATION}
                    element={
                        <PermissionRoute requiredPermissions={['Admin.Assets.CREATE']}>
                            <AdminGenerationDashboard />
                        </PermissionRoute>
                    }
                />

                <Route
                    path={ROUTES.ADMIN_GENERATION_ASSETS}
                    element={
                        <PermissionRoute requiredPermissions={['Admin.Assets.CREATE']}>
                            <AssetListWrapper />
                        </PermissionRoute>
                    }
                />

                <Route
                    path={ROUTES.ADMIN_GENERATION_ASSETS_PAGE}
                    element={
                        <PermissionRoute requiredPermissions={['Admin.Assets.CREATE']}>
                            <GenerationAssetPage />
                        </PermissionRoute>
                    }
                />

                <Route
                    path={ROUTES.ADMIN_FORECASTING}
                    element={<Navigate to={ROUTES.ADMIN_FORECASTING_ASSETS} replace={true} />}
                />

                <Route
                    path={ROUTES.ADMIN_FORECASTING_ASSETS}
                    element={
                        <PermissionRoute
                            requiredPermissions={['Admin.Assets.Delivery.WRITE', 'Admin.Assets.Forecast.WRITE']}
                        >
                            <AdminForecastingPage />
                        </PermissionRoute>
                    }
                />
                <Route
                    path={ROUTES.ADMIN_DELIVERY_ASSETS}
                    element={
                        <PermissionRoute
                            requiredPermissions={['Admin.Assets.Delivery.WRITE', 'Admin.Assets.Forecast.WRITE']}
                        >
                            <AdminGenerationAssetDelivery />
                        </PermissionRoute>
                    }
                />

                <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />

                <Route path={ROUTES.AI_DASHBOARD} element={<DashboardV2 />}></Route>
                <Route path={ROUTES.AI_GAS_DASHBOARD} element={<GasDemandDashboard />}></Route>
                <Route path={ROUTES.AI_GAS_DEMAND_CONSUMERS} element={<GasConsumersList />}></Route>
                <Route path={ROUTES.AI_GAS_DEMAND_CONSUMERS_PAGE} element={<GasConsumersPage />}></Route>
                <Route path={ROUTES.AI_GAS_DEMAND_PROFILES} element={<GasProfilesList />}></Route>
                <Route path={ROUTES.AI_GAS_DEMAND_PROFILES_PAGE} element={<GasProfilesPage />}></Route>
                <Route path={ROUTES.AI_GAS_DEMAND_CLUSTERS} element={<GasClustersList />}></Route>
                <Route path={ROUTES.AI_GAS_DEMAND_CLUSTERS_PAGE} element={<GasClustersPage />}></Route>
                <Route path={ROUTES.GAS_DELIVERED_FORECAST_PAGE} element={<GasDeliveredForecast />}></Route>

                <Route path={ROUTES.AI_TL} element={<DemandDashboard />}></Route>
                <Route path={ROUTES.AI_TL_CLUSTERS} element={<TLClustersList />}></Route>
                <Route path={ROUTES.AI_TL_CLUSTERS_PAGE} element={<TLClustersPage />}></Route>
                <Route path={ROUTES.AI_TL_CONSUMERS} element={<TLConsumersList />}></Route>
                <Route path={ROUTES.AI_TL_GENERATION_ASSETS} element={<AssetList tl key={123} />}></Route>
                <Route path={ROUTES.AI_TL_CONSUMERS_PAGE} element={<TLConsumersPage />}></Route>
                <Route path={ROUTES.AI_TL_CIRCULATIONS} element={<TLCirculationsList />}></Route>
                <Route path={ROUTES.AI_TL_CIRCULATIONS_PAGE} element={<TLCirculationsPage />}></Route>

                <Route path={ROUTES.AI_GENERATION_ASSETS} element={<AssetList key={124} />}></Route>
                <Route path={ROUTES.AI_GENERATION_ASSETS_PAGE} element={<GenerationAssetPage />}></Route>
                <Route path={ROUTES.AI_TL_GENERATION_ASSETS_PAGE} element={<GenerationAssetPage />}></Route>
                <Route path={ROUTES.AI_GENERATION_MOCK_ASSETS_PAGE} element={<MockAssetPage />}></Route>

                <Route path={ROUTES.AI_GENERATION_VPP} element={<VPPList />}></Route>
                <Route path={ROUTES.AI_GENERATION_VPP_PAGE} element={<VPPPage />}></Route>
                <Route path={ROUTES.AI_GENERATION_MOCK_VPP_PAGE} element={<MockVPPPage />}></Route>

                <Route path={ROUTES.AI_PROSUMERS} element={<ProsumersDashboard />}></Route>
                <Route path={ROUTES.AI_PROSUMERS_VPP} element={<VPPList />}></Route>
                <Route path={ROUTES.AI_PROSUMERS_VPP_PAGE} element={<VPPPage />}></Route>
                <Route path={ROUTES.AI_PROSUMERS_LIST} element={<ProsumersList />}></Route>
                <Route path={ROUTES.AI_PROSUMERS_CLUSTERS_LIST} element={<ProsumerClustersList />}></Route>
                <Route path={ROUTES.AI_PROSUMERS_CLUSTERS_PAGE} element={<ProsumerClusterPage />}></Route>
                <Route path={ROUTES.AI_PROSUMERS_PAGE} element={<ProsumersPage />}></Route>
                <Route path={ROUTES.AI_DEMAND} element={<DemandDashboard />}></Route>

                <Route
                    path={ROUTES.AI_DEMAND_BASE}
                    element={
                        <ProtectedRoute force redirectPath={ROUTES.AI_DEMAND_CONSUMERS}>
                            <></>
                        </ProtectedRoute>
                    }
                ></Route>
                <Route path={ROUTES.AI_DEMAND_DASHBOARD} element={<DemandDashboard />}></Route>
                <Route path={ROUTES.AI_DEMAND_CONSUMERS} element={<ConsumersList />}></Route>
                <Route path={ROUTES.AI_DEMAND_CONSUMERS_PAGE} element={<ConsumersPage />}></Route>
                <Route path={ROUTES.AI_DEMAND_PROFILES} element={<ProfilesList />}></Route>
                <Route path={ROUTES.AI_DEMAND_PROFILES_PAGE} element={<ProfilesPage />}></Route>
                <Route path={ROUTES.AI_DEMAND_PROFILES_FORMULA_PAGE} element={<FormulaPage />}></Route>
                <Route path={ROUTES.AI_DEMAND_CLUSTERS} element={<ClustersList />}></Route>
                <Route path={ROUTES.AI_DEMAND_CLUSTERS_PAGE} element={<ClustersPage />}></Route>
                <Route path={ROUTES.AI_DEMAND_SEGMENTS} element={<SegmentsList />}></Route>
                <Route path={ROUTES.AI_DEMAND_SEGMENTS_PAGE} element={<SegmentsPage />}></Route>

                <Route path={ROUTES.MAP} element={<Map />} />

                <Route path={ROUTES.AI_EVS} element={<Navigate to={ROUTES.AI_EVS_DASHBOARD} replace />} />
                <Route path={ROUTES.AI_EVS_DASHBOARD} element={<EVSDashboard />} />
                <Route path={ROUTES.AI_EVS_CHARGHING_STATIONS} element={<ChargingStationsList />} />
                <Route path={ROUTES.AI_EVS_CHARGHING_STATIONS_PAGE} element={<ChargingStationsPage />} />
                <Route path={ROUTES.AI_EVS_CHARGHING_POINTS_PAGE} element={<ChargingPointsPage />} />
                <Route path={ROUTES.AI_EVS_CLUSTERS} element={<EVSClustersList />} />
                <Route path={ROUTES.AI_EVS_CLUSTERS_PAGE} element={<EVSClustersPage />} />

                <Route path={ROUTES.FORECASTS} element={<ForecastsList />}></Route>
                <Route path={ROUTES.GAS_FORECASTS} element={<GasForecastList />}></Route>
                <Route path={ROUTES.FORECASTS_PAGE} element={<ForecastPage />}></Route>

                <Route path={ROUTES.EVS_FORECASTS} element={<EVSForecastList />}></Route>
                <Route path={ROUTES.EVS_FORECASTS_PAGE} element={<EVSForecastPage />}></Route>

                <Route path={ROUTES.REPORTS} element={<ReportsList />}></Route>
                <Route path={ROUTES.REPORTS_PAGE} element={<ReportsPage />}></Route>

                <Route path={ROUTES.EVS_REPORTS} element={<EVSReportsList />}></Route>
                <Route path={ROUTES.EVS_REPORTS_PAGE} element={<EVSReportPage />}></Route>

                <Route path={ROUTES.GAS_REPORTS} element={<GasReportsList />}></Route>
                <Route path={ROUTES.GAS_REPORTS_PAGE} element={<GasReportPage />}></Route>

                <Route path={ROUTES.PRICE_FORECAST} element={<MarketsList />}></Route>
                <Route path={ROUTES.PRICE_FORECAST_PAGE} element={<MarketPricingPage />}></Route>

                <Route path={ROUTES.FORBIDDEN} element={<Forbidden />} />

                <Route path="*" element={<Navigate to={ROUTES.INDEX} replace />} />
            </Route>
        );
    };

    return (
        <Suspense fallback={<NProgress />}>
            <Routes>
                {/* private routes */}
                {renderUserRoutes()}

                {/* public routes */}
                <Route
                    path={ROUTES.LOGIN}
                    element={
                        <PublicRoute>
                            <Login />
                        </PublicRoute>
                    }
                />
                <Route
                    path={ROUTES.RESET_PASSWORD}
                    element={
                        <PublicRoute>
                            <ResetPassword />
                        </PublicRoute>
                    }
                />
                <Route
                    path={ROUTES.CHANGE_PASSWORD}
                    element={
                        <PublicRoute>
                            <ChangePassword />
                        </PublicRoute>
                    }
                />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
};

export default Router;
