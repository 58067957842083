import { FC, memo } from 'react';
import style from './style.module.scss';

import Supercluster from 'supercluster';
import Tooltip from './Tooltip';
import MarkerIcon from './MarkerIcon';

interface ClusterProps {
    cluster: Supercluster.PointFeature<any>;
    handleClusterZoom: () => void;
    lng: number;
    lat: number;
}
interface MarkerProps {
    cluster: Supercluster.PointFeature<any>;
    openMarker: number | null;
    setOpenMarker: (id: number | null) => void;
    lng: number;
    lat: number;
}

const cluster: FC<ClusterProps> = ({ cluster, handleClusterZoom }) => {
    const { cluster_id, point_count } = cluster.properties;

    return (
        <div key={cluster_id} className={style.cluster} onClick={handleClusterZoom}>
            <div>{point_count}</div>
        </div>
    );
};

const marker: FC<MarkerProps> = ({ cluster, openMarker, setOpenMarker }) => {
    const { id, data } = cluster.properties;
    const tooltipOpen = id === openMarker;

    const toggleTooltip = () => {
        if (tooltipOpen) {
            setOpenMarker(null);
        } else {
            setOpenMarker(id);
        }
    };

    return (
        <div key={id} className={style.marker} onClick={toggleTooltip}>
            {tooltipOpen && <Tooltip properties={cluster.properties} />}
            <MarkerIcon entityType={data.entity_type} isActive={tooltipOpen} />
        </div>
    );
};

const Cluster = memo(cluster, (prev, next) => {
    return prev.cluster.properties.cluster_id === next.cluster.properties.cluster_id;
});

const Marker = memo(marker, (prev, next) => {
    return prev.cluster.properties.id === next.cluster.properties.id && prev.openMarker === next.openMarker;
});

Cluster.displayName = 'Cluster';
Marker.displayName = 'Marker';

export { Cluster, Marker };
