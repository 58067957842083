import { toast } from 'react-toastify';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { GetParams } from '../../interfaces/general';
import { transformImportedFilesToFE } from './helpers';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { getQueryParamsFromObject } from '../../utils/table';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { ApiPaginationInfo, ImportedFile, PaginatedApiResponse } from '../../interfaces/apiv2';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { ImportedFileFE } from '../../interfaces/uiv2';

interface ListImportedFilesState extends DefaultInitialState {
    importedFiles: ImportedFile[];
    importedFilesFE: ImportedFileFE[];
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
}

const initialState: ListImportedFilesState = {
    importedFiles: [],
    importedFilesFE: [],
    pager: null,
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE
};

export const getImportedFilesListAsync = createAsyncThunk(
    'importedFiles/list',
    async (values: GetParams, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...values });
            const response = await axios.get<PaginatedApiResponse<ImportedFile>>(`/import-jobs${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listImportedFiles = createSlice({
    name: 'listImportedFiles',
    initialState,
    reducers: {
        resetListImportedFilesState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getImportedFilesListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getImportedFilesListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.importedFiles = items;
            } else {
                state.importedFiles = [...current(state).importedFiles, ...items];
            }

            state.importedFilesFE = transformImportedFilesToFE(current(state).importedFiles);
        });
        builder.addCase(getImportedFilesListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetListImportedFilesState, setGetParams: setGetImportedFilesParams } = listImportedFiles.actions;

export default listImportedFiles.reducer;
