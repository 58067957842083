import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { ProsumersDataFE } from '../../interfaces/uiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { transformProsumersDataToFE } from './helpers';
import { GetParams } from '../../interfaces/general';
import { getQueryParamsFromObject } from '../../utils/table';
import { Prosumers } from '../../interfaces/apiv2';

import axios from '../../utils/axios';

interface ListProsumersState extends DefaultInitialState {
    prosumers: Prosumers[];
    prosumersFE: ProsumersDataFE[];
    getParams: GetParams;
}

const initialState: ListProsumersState = {
    prosumers: [],
    prosumersFE: [],
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE
};

export const getProsumersListAsync = createAsyncThunk(
    'prosumers/list',
    async (values: GetParams, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...values });
            const response = await axios.get(`/electricity/prosumers${queryParams}`);
            const items = response.data.items;
            return items as Prosumers[];
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listProsumers = createSlice({
    name: 'listProsumers',
    initialState,
    reducers: {
        resetListProsumersState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProsumersListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getProsumersListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.prosumers = payload;
            state.prosumersFE = transformProsumersDataToFE(payload);
        });
        builder.addCase(getProsumersListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetListProsumersState, setGetParams } = listProsumers.actions;

export default listProsumers.reducer;
