import { GetParams } from '../general';
import { PAGINATION_SETTINGS } from '../../utils/config';

export interface DefaultInitialState {
    loading: boolean;
    error: boolean;
    success: boolean;
}

export const DEFAULT_GET_PARAMS_VALUE: GetParams = {
    page: PAGINATION_SETTINGS.default_start_page,
    size: PAGINATION_SETTINGS.page_size,
    order_by: ['-created_at'],
    search: null
};
