import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo } from '../../interfaces/apiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { transformForecastToTable, transformForecastToView } from '../../utils/forecast';
import { staticTLCirculations, TLCirculation } from './mock-data';
import { FORECAST_TYPE, ForecastDataGetParams, ForecastFE } from '../../interfaces/uiv2';
import { mockRequest } from '../../utils/helpers';

interface ITLCirculationPage extends DefaultInitialState {
    circulation: TLCirculation | null;
    data: ForecastFE[];
    paginatedData: ForecastFE[];
    chartData: LineData[][];
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
}

const initialState: ITLCirculationPage = {
    circulation: null,
    data: [],
    paginatedData: [],
    chartData: [[], []],
    loading: false,
    error: false,
    success: false,
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        // order_by: ['-created_on']
        order_by: undefined
    },
    pager: null
};

export const getTLCirculationAsync = createAsyncThunk(
    'circulation/show',
    async (values: { id: string }, { rejectWithValue }) => {
        try {
            /*const response = await axios.get<TLCirculation>(`/circulation/${values.id}`);

          return response.data;*/
            const circulation = staticTLCirculations.find((e) => e.id === values.id);
            const data = await mockRequest(250, circulation);
            return data as TLCirculation;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getTLCirculationDataAsync = createAsyncThunk(
    'circulation_data/get',
    async ({ circulation }: { circulation: TLCirculation }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ForecastFE[]>(`/circulation/${circulation.id}/forecast`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const circulationPage = createSlice({
    name: 'circulationPage',
    initialState,
    reducers: {
        resetTLCirculationPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setActiveTLCirculation: (state, { payload }: PayloadAction<TLCirculation | null>) => {
            state.circulation = payload;
        },
        loadMore: (state) => {
            const { data, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: data.length
            };
            state.paginatedData = data.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTLCirculationDataAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getTLCirculationDataAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.data = transformForecastToTable(payload as any);
            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).data.length
            };
            state.paginatedData = current(state).data.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
            state.chartData = [transformForecastToView(payload as any, FORECAST_TYPE.ELECTRICITY), []];
        });
        builder.addCase(getTLCirculationDataAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            //toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getTLCirculationAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getTLCirculationAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.circulation = payload;
        });
        builder.addCase(getTLCirculationAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            //toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const {
    resetTLCirculationPage,
    setActiveTLCirculation,
    setGetParams: setGetTLCirculationParams,
    loadMore: loadMoreTLCirculationData
} = circulationPage.actions;

export default circulationPage.reducer;
