import dayjs from 'dayjs';
import classnames from 'classnames';
import Flag from 'react-world-flags';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';

import {
    getSegmentAsync,
    resetSegmentPageState,
    deleteSegmentsListAsync,
    resetSegmentForecastData,
    getSegmentHistoricalAsync,
    getSegmentForecastAsync
} from '../../../stores/Demand';
import Loading from '../../../layout/Loading';
import { UNIT } from '../../../interfaces/uiv2';
import { TAB } from '../../../interfaces/general';
import { DATE_SETTINGS } from '../../../utils/config';
import { getFormattedDate } from '../../../utils/helpers';
import { PageContainer } from '../../../layout/PageContainer';
import { TradingView } from '../../../components/TradingView';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { transformForecastToTable } from '../../../utils/forecast';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ReactComponent as ViewList } from '../../../assets/icons/view_list.svg';
import { ReactComponent as GraphIcon } from '../../../assets/icons/timeline.svg';
import { mockedDemandForecast } from '../../../stores/Demand/mocked-demand-chart';
import { RemoveForecastContainer } from '../../../components/RemoveEntityContainer';
import { ReactComponent as SegmentsIcon } from '../../../assets/icons/demand/segments.svg';
import { TableActionsEntityContainer } from '../../../components/TableActionsEntityContainer';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../../components/EntityPageToolbar';
import { MemoizedDemandGenerationTable } from '../../../components/GenerationTable/DemandGenerationTable';

import common from './../../../common.module.scss';
import style from '../../../features/asset-page/asset-page.module.scss';

const SegmentsPage = () => {
    const query = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;
    const {
        segment,
        loading,
        success,
        error,
        loadingForecast,
        loadingHistorical,
        errorForecast,
        errorHistorical,
        forecasts
    } = useAppSelector((state) => state.segmentPage);
    const paginatedData = transformForecastToTable(mockedDemandForecast.slice(0, 100), 'RO');

    useEffect(() => {
        if (!segment && query?.id && query?.projectId) {
            dispatch(getSegmentAsync({ id: query.id, projectId: query.projectId }));
        }
    }, [query?.id, segment]);

    useEffect(() => {
        if (segment && tab === 'chart') {
            const from = dayjs().subtract(1, 'week').utcOffset(0).startOf('date').valueOf();
            const to = dayjs().add(10, 'days').utcOffset(0).endOf('date').valueOf();
            dispatch(getSegmentForecastAsync({ project_id: query.projectId!, id: segment.id, from, to }));
            dispatch(getSegmentHistoricalAsync({ project_id: query.projectId!, id: segment.id, from, to }));
        }
    }, [tab, segment]);

    const fetchMore = async (toString: string) => {
        const toDayJs = dayjs(toString);
        const timezone = 'UTC';
        const offset = toDayJs.tz(timezone).utcOffset();
        const to = toDayJs.add(offset, 'minutes').valueOf();
        const from = dayjs.tz(to, 'UTC').subtract(1, 'week').valueOf();

        //TODO: stop at 01.01.2022

        dispatch(getSegmentForecastAsync({ project_id: query.projectId!, id: segment!.id, from, to }));
        dispatch(getSegmentHistoricalAsync({ project_id: query.projectId!, id: segment!.id, from, to }));
    };

    useEffect(() => {
        return () => {
            dispatch(resetSegmentPageState());
            dispatch(resetSegmentForecastData());
        };
    }, []);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    const remove = () => {
        dispatch(deleteSegmentsListAsync({ ids: [query.id!], projectId: query.projectId! }));
    };

    if (!segment) {
        return <Loading className={style.loading} />;
    }

    return (
        <PageContainer className={[common.pageContainer]}>
            <Box className={style.headerContainer}>
                <Box className={classnames(style.header)}>
                    <PageHeader
                        id={segment.id}
                        title={t('title.segments')}
                        className={style.title}
                        icon={<SegmentsIcon />}
                        subtitle={segment.name}
                    />

                    <Box className={style.subtitle} sx={{ ml: 2 }}>
                        <Box className={style.dot} />
                        <Typography variant="small1"> {t(pageTitle)}</Typography>
                    </Box>
                </Box>

                <Box className={style.toolbarContainer}>
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>
                    </EntityPageToolbar>
                    <TableActionsEntityContainer entityType="forecast" id={''} className={style.actionsWrapper} />
                </Box>
            </Box>

            <Box className={common.subHeaderContainer}>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.type')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {segment.type}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.location')}</Typography>
                    <Flag
                        height="24"
                        width="24"
                        className={classnames('flagIcon', common.ml)}
                        code={segment.country || segment.location}
                    />
                    <Typography variant="small3" className={common.ml}>
                        {segment.country ? segment.country.toUpperCase() : segment.location}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.voltage_type')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {segment.voltage_type}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.totalClusters')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {segment.total_consumers}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4" className={common.ml}>
                        {t('tableHead.createdAt')}
                    </Typography>
                    <Typography variant="small3" className={common.ml}>
                        {getFormattedDate({
                            value: segment.created_at,
                            type: DATE_SETTINGS.shortV2FormatHoursIncluded
                        })}
                    </Typography>
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={forecasts}
                    error={errorForecast || errorHistorical}
                    fetchMoreFn={fetchMore}
                    loading={loadingForecast || loadingHistorical}
                    legendOpts={{
                        forecasted: true,
                        historical: true
                    }}
                    assetMeasureUnit={UNIT.ENERGY}
                />
            )}
            {tab === 'table' && (
                <MemoizedDemandGenerationTable
                    data={paginatedData}
                    error={error}
                    loading={loading}
                    success={success}
                    assetMeasureUnit={UNIT.LOW_ENERGY}
                />
            )}

            <RemoveForecastContainer cb={remove} stateAccessor="deleteForecasts" />
        </PageContainer>
    );
};

export default SegmentsPage;
