import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import successImg from '../../assets/images/success-visual.png';

import style from './style.module.scss';

const FormSuccessStep = () => {
    const { t } = useTranslation();

    return (
        <Box className={style.box}>
            <Box className={style.content}>
                <Typography variant="h1">{t('form.success.title')}</Typography>
                <Typography variant="h4">{t('form.success.copy')}</Typography>
            </Box>
            <img src={successImg} alt="success" />
        </Box>
    );
};

export { FormSuccessStep };
