import classnames from 'classnames';
import { Layout } from 'react-grid-layout';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLine, VictoryTooltip, VictoryVoronoiContainer } from 'victory';

import { NO_VALUE, ROUTES } from '../../utils/config';
import { getAssetIcon, transformLatestForecastData } from './helpers';
import { CardHeaderActions } from '../../features/generation-dashboard-view';
import AssetStatusLabel from '../../components/AssetStatusLabel/AssetStatusLabel';
import { getFormattedCapacityValue, getFormattedDate } from '../../utils/helpers';
import { CircleProgressBar } from '../../components/BaseTable/CustomCells/CircleProgressBar';
import { DashboardItem, DASHBOARD_CHART_TYPE, GenerationAsset } from '../../interfaces/apiv2';

import style from '../../features/generation-dashboard-view/style.module.scss';

interface ProsumerAsset extends GenerationAsset {
    measured_data: any[];
}

export const ProsumerAssetCard = ({
    l,
    item,
    asset,
    changeHeight,
    deleteDashboardItem
}: {
    l: Layout;
    asset: ProsumerAsset;
    item: DashboardItem;
    changeHeight: (item: string) => void;
    deleteDashboardItem: (item: string) => void;
}) => {
    const { t } = useTranslation();

    const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });

    const graphRef = useCallback((node) => {
        if (node !== null) {
            setBoundingRect(node.getBoundingClientRect());
        }
    }, []);

    const getAssetPageUrl = () => {
        return `${ROUTES.AI_GENERATION_ASSETS_PAGE.replace(':id', asset.id)}`;
    };

    return (
        <>
            <Box className={style.itemHeader}>
                <Box className={style.name}>
                    {getAssetIcon(asset.type)}
                    <Link to={getAssetPageUrl()}>
                        <Typography variant="h7" className="dragDisable">
                            {asset.name}
                        </Typography>
                    </Link>
                </Box>
                <CardHeaderActions
                    l={l}
                    changeHeight={changeHeight}
                    deleteDashboardItem={deleteDashboardItem}
                    item={{ name: asset.name, type: asset.type }}
                />
            </Box>

            <Box className={classnames(style.itemDetails, l.h === 1 ? style.noBorder : '')}>
                <Typography className={classnames(style.element, style.border)} variant="small4">
                    {t('assetInfo.capacity')}
                    <Typography variant="small3" className={style.black} sx={{ ml: 1.5 }}>
                        {getFormattedCapacityValue(asset.capacity_value)}
                    </Typography>
                </Typography>

                {l.w > 4 ? (
                    <Typography className={classnames(style.element, style.border)} variant="small4">
                        {t('assetInfo.accuracy')}
                        <Box sx={{ ml: 1.5 }}>
                            <CircleProgressBar value={asset?.accuracy * 100 || 0} />
                        </Box>
                    </Typography>
                ) : null}

                <Typography className={classnames(style.element, l.w > 4 ? style.border : '')} variant="small4">
                    {t('assetInfo.expiryDate')}
                    <Typography variant="small3" className={style.black} sx={{ ml: 1.5 }}>
                        {getFormattedDate({ value: asset.expiry_date, fallback: NO_VALUE })}
                    </Typography>
                </Typography>

                {l.w > 4 ? (
                    <Typography className={style.element} variant="small4">
                        {t('assetInfo.status')}
                        <Box sx={{ ml: 1.5 }}>
                            <AssetStatusLabel status={asset.status} variant="small3" />
                        </Box>
                    </Typography>
                ) : null}
            </Box>

            {l.h === 3 ? (
                <Box className={style.itemChartContainer}>
                    <Box
                        className={classnames(
                            style.itemChart,
                            l.w === 12 && style.p12,
                            l.w === 8 && style.p12,
                            item.chart_type === DASHBOARD_CHART_TYPE[1] && style.lineChart
                        )}
                        ref={graphRef}
                    >
                        {item.chart_type === DASHBOARD_CHART_TYPE[0] ? (
                            <VictoryChart height={boundingRect.height} width={boundingRect.width}>
                                <VictoryBar
                                    style={{
                                        data: { fill: 'url(#barGradient)', strokeLinejoin: 'round' },
                                        labels: { fill: '#3745F2' }
                                    }}
                                    barRatio={1.2}
                                    cornerRadius={{ topLeft: 4, topRight: 4 }}
                                    x={'date'}
                                    y={'value'}
                                    data={transformLatestForecastData(asset.latest_forecast)}
                                    labels={({ datum }) => datum.value}
                                />
                                <VictoryAxis
                                    offsetY={50}
                                    crossAxis={false}
                                    style={{
                                        axis: {
                                            stroke: '#D5E3FF'
                                        },
                                        tickLabels: {
                                            fill: '#828282'
                                        }
                                    }}
                                />
                            </VictoryChart>
                        ) : null}

                        {item.chart_type === DASHBOARD_CHART_TYPE[1] ? (
                            <VictoryChart
                                height={boundingRect.height}
                                width={boundingRect.width}
                                containerComponent={
                                    <VictoryVoronoiContainer
                                        labels={({ datum }) => datum.value}
                                        labelComponent={
                                            <VictoryTooltip
                                                cornerRadius={8}
                                                flyoutStyle={{
                                                    stroke: '#d5e3ff',
                                                    fill: 'white'
                                                }}
                                            />
                                        }
                                    />
                                }
                            >
                                <VictoryLine
                                    style={{
                                        data: { stroke: '#6A9BFF' },
                                        parent: { border: '1px solid #ccc' },
                                        labels: { fill: '#6a9bff' }
                                    }}
                                    /*domainPadding={{ x: [0, -20], y: 0 }}*/
                                    data={transformLatestForecastData(asset.latest_forecast)}
                                    x={'date'}
                                    y={'value'}
                                />
                                <VictoryLine
                                    style={{
                                        data: { stroke: '#6FD8B2' },
                                        parent: { border: '1px solid #ccc' },
                                        labels: { fill: '#6FD8B2' }
                                    }}
                                    /*domainPadding={{ x: [0, -20], y: 0 }}*/
                                    data={transformLatestForecastData(asset.measured_data)}
                                    x={'date'}
                                    y={'value'}
                                />
                                <VictoryAxis
                                    style={{
                                        axis: {
                                            stroke: '#D5E3FF'
                                        },
                                        tickLabels: {
                                            fill: '#828282'
                                        }
                                    }}
                                />

                                <VictoryAxis
                                    dependentAxis
                                    orientation="right"
                                    style={{
                                        axis: {
                                            stroke: 'transparent'
                                        },
                                        tickLabels: {
                                            fill: '#828282'
                                        },
                                        grid: { stroke: '#D5E3FF', strokeWidth: 0.5 }
                                    }}
                                />
                            </VictoryChart>
                        ) : null}
                    </Box>
                </Box>
            ) : null}
        </>
    );
};
