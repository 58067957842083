import * as yup from 'yup';
import { TFunction } from 'i18next';
import {
    ASSET_STATUS,
    ASSET_TYPE,
    GenerationAssetFE,
    GenerationAssetFormFields,
    INSTALLATION_TYPE_SOLAR,
    INSTALLATION_TYPE_WIND,
    UNIT,
    UserFE
} from '../../interfaces/uiv2';
import { Client, PatchGenerationAsset, PostGenerationAsset } from '../../interfaces/apiv2';

interface FormValues {
    [key: string]: any;
}

export const getGenerationAssetFormSchema = (t: TFunction, hasClient: boolean) => {
    const baseSchema = {
        name: yup.string().required(t('validations.required')),
        client: hasClient ? yup.string().nullable() : yup.object().required(t('validations.required')),
        type: yup.string().oneOf(Object.values(ASSET_TYPE)).required(t('validations.required')),
        measure_unit: yup.string().required(t('validations.required')),
        has_realtime_data: yup.boolean().required(t('validations.required')),
        capacity_value: yup.number().required(t('validations.required')),
        manufacturer: yup.string(),
        latitude: yup
            .number()
            .required(t('validations.required'))
            .test('customTest', 'invalidCoordinate', (value) => {
                const isLatitudeValid = new RegExp(/^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})$/);
                return isLatitudeValid.test(value as any);
            }),
        longitude: yup
            .number()
            .required(t('validations.required'))
            .test('customTest', 'invalidCoordinate', (value) => {
                const isLongitudeValid = new RegExp(/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})$/);
                return isLongitudeValid.test(value as any);
            }),
        installation_date: yup.date().required(t('validations.required')),
        expiry_date: yup.date().required(t('validations.required')),
        installation_type: yup
            .string()
            .oneOf([...Object.values(INSTALLATION_TYPE_SOLAR), ...Object.values(INSTALLATION_TYPE_WIND)])
            .required(t('validations.required')),
        windTracking: yup.boolean().when('type', (val) => {
            if (val === ASSET_TYPE.WIND) {
                return yup.boolean().required(t('validations.required'));
            }
            return yup.boolean().nullable().optional().notRequired();
        }),
        hub_height: yup.mixed().when('type', (val) => {
            if (val === ASSET_TYPE.WIND) {
                return yup.number().required(t('validations.required'));
            }
            return yup.mixed().nullable().optional().notRequired();
        }),
        turbines: yup.mixed().when('type', (val) => {
            if (val === ASSET_TYPE.WIND) {
                return yup.number().required(t('validations.required'));
            }
            return yup.mixed().nullable().optional().notRequired();
        }),
        solarTracking: yup.boolean().when('type', (val) => {
            if (val === ASSET_TYPE.SOLAR) {
                return yup.boolean().required(t('validations.required'));
            }
            return yup.boolean().nullable().optional().notRequired();
        }),
        azimuth: yup.mixed().when('type', (val) => {
            if (val === ASSET_TYPE.SOLAR) {
                return yup.number().required(t('validations.required'));
            }
            return yup.mixed().nullable().optional().notRequired();
        }),
        tilt_angle: yup.mixed().when('type', (val) => {
            if (val === ASSET_TYPE.SOLAR) {
                return yup.number().required(t('validations.required'));
            }
            return yup.mixed().notRequired().nullable().optional();
        })
    };

    return yup.object<Record<keyof FormValues, yup.AnySchema>>({ ...baseSchema }).required();
};

export const transformGenerationAssetToFormData = (asset: GenerationAssetFE): GenerationAssetFormFields => {
    if (asset) {
        return {
            ...asset,
            windTracking: !!asset.meta.windTracking,
            tilt_angle: asset.meta.tilt_angle,
            azimuth: asset.meta.azimuth,
            solarTracking: !!asset.meta.solarTracking,
            turbines: asset.meta.turbines,
            hub_height: asset.meta.hub_height,
            has_realtime_data: asset.has_realtime_data,
            client: asset.client,
            installation_type: asset.meta.installation_type,
            capacity_value: asset.capacity_value / 1000
        };
    }
    return generationAssetFormDataDefaultValue;
};
export const getGenerationAssetFormDataDefaultValues = (type: ASSET_TYPE) => ({
    ...generationAssetFormDataDefaultValue,
    type
});

export const generationAssetFormDataDefaultValue: GenerationAssetFormFields = {
    name: '',
    manufacturer: '',
    type: ASSET_TYPE.SOLAR,
    longitude: '' as unknown as number,
    latitude: '' as unknown as number,
    client: null as unknown as Client,
    capacity_value: '' as unknown as number,
    measure_unit: UNIT.ENERGY,
    has_realtime_data: false,
    installation_type: null as unknown as any,
    windTracking: false,
    installation_date: '',
    expiry_date: '',
    solarTracking: false
};

export const transformGenerationAssetFormToBody = (
    formData: GenerationAssetFormFields,
    user: UserFE
): PostGenerationAsset => {
    let meta = {};
    if (formData.type === ASSET_TYPE.WIND) {
        meta = {
            turbines: formData.turbines,
            hub_height: formData.hub_height,
            wind_tracking: formData.windTracking,
            installation_type: formData.installation_type
        };
    }

    if (formData.type === ASSET_TYPE.SOLAR) {
        meta = {
            tilt_angle: formData.tilt_angle,
            azimuth: formData.azimuth,
            solar_tracking: formData.solarTracking,
            installation_type: formData.installation_type
        };
    }
    return {
        meta,
        client_id: user.client_id ? user.client_id : formData.client.id,
        capacity_value: formData.capacity_value * 1000,
        measure_unit: formData.measure_unit,
        name: formData.name,
        latitude: formData.latitude,
        longitude: formData.longitude,
        type: formData.type,
        status: ASSET_STATUS.PENDING,
        installation_date: formData.installation_date,
        expiry_date: formData.expiry_date,
        manufacturer: formData.manufacturer,
        has_realtime_data: formData.has_realtime_data
    };
};

export const transformGenerationAssetBodyToPatch = (
    asset: GenerationAssetFE,
    body: PostGenerationAsset
): PatchGenerationAsset => {
    return {
        ...asset,
        ...body,
        meta: {
            ...asset.meta,
            ...body.meta
        }
    };
};
