import { createContext } from 'react';

export const ModalContext = createContext<{
    open: { [key: string]: boolean };
    handleClickOpen: (id: string, data?: any) => void;
    handleClose: (id: string) => void;
    payload?: any;
}>({
    open: {},
    handleClickOpen: () => {},
    handleClose: () => {},
    payload: null
});
