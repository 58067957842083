import classNames from 'classnames';
import Flag from 'react-world-flags';
import countryTimezone from 'country-timezone';
import React, { useMemo, useState } from 'react';
import { VictorySharedEvents } from 'victory-shared-events';
import { VictoryLabel, VictoryLegend, VictoryPie } from 'victory';
import { Box, IconButton, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';

import { DATE_SETTINGS } from '../../utils/config';
import { getFormattedDate } from '../../utils/helpers';
import useWindowDimensions from '../../utils/getWindowResize';
import { Dot, getFontSize, getPercentage, getTooltipInfo, getUnitOfMeasure } from './helpers';
import { ALL_TYPES, getAssetIcon } from '../../utils/getAssetIcon';
import { ReactComponent as ArrowSVG } from '../../assets/icons/arrow.svg';
import { useGetStatsQuery, useGetElectricityStatsQuery } from '../../stores/Dashboard';
import { ReactComponent as TooltipInfoSvg } from '../../assets/icons/tooltip-info.svg';
import { assetsData, colorScaleCodes, Country, defaultLegend, demandData, performanceRows } from './data-structures';

import style from './style.module.scss';
import common from '../../common.module.scss';
import { ASSET_TYPE } from '../../interfaces/uiv2';
import { useAppSelector } from '../../stores';
import { hasAccessTo } from '../../utils/user';

const PerformanceRow = ({ icon, title, elements, disabled }: any) => {
    return (
        <Box className={classNames(style.card, style.performanceRow, disabled && style.disabledCard)}>
            <Box className={classNames(style.performanceRowColumn, style.title)}>
                <Typography variant="small1" className={classNames(common.alignCenter, style.titleLabel)}>
                    {icon}
                    {title}
                </Typography>
            </Box>
            {elements.map(({ value, unit, label, id }: any) => (
                <Box className={style.performanceRowColumn} key={label}>
                    <Box className={classNames(common.flex, common.alignBaseline)}>
                        {value && typeof value === 'number' ? (
                            <>
                                <Typography variant="h5">{value}</Typography>
                                <Typography variant="small1" className={common.ml}>
                                    {unit}
                                </Typography>
                                {/* {vector === 'positive' && (
                                    <ArrowSVG className={classNames(common.greenIcon, common.ml)} />
                                )}
                                {vector === 'negative' && <ArrowSVG className={classNames(common.error, common.ml)} />}*/}
                                <Tooltip title={getTooltipInfo(id)} enterTouchDelay={400}>
                                    <IconButton>
                                        <TooltipInfoSvg />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : (
                            <Typography variant="h5">{'N/A'}</Typography>
                        )}
                    </Box>
                    <Typography variant="small4" className={style.subtitle}>
                        {label}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

/*export const PerformanceAccuracyRow = ({ label, value, unit, vector = '' }: any) => {
    return (
        <Box className={style.element}>
            <Typography variant="small4" className={style.title}>
                {label}
            </Typography>
            <Box className={classNames(common.flex, common.alignBaseline)}>
                <Box className={style.bar} />
                <Typography variant="h5" className={common.ml1}>
                    {value}
                </Typography>
                <Typography variant="small1" className={common.ml}>
                    {unit}
                </Typography>
                {vector === 'negative' && <ArrowSVG className={classNames(common.error, common.ml)} />}
                {vector === 'positive' && <ArrowSVG className={classNames(common.greenIcon, common.ml)} />}
            </Box>
        </Box>
    );
};*/
const PerformanceComponent = () => {
    const { user } = useAppSelector((state) => state.login);
    const { data: stats } = useGetElectricityStatsQuery(
        {},
        { skip: !hasAccessTo(user!, ['Electricity.Generation.Assets.READ']) }
    );

    const rows = useMemo(() => {
        const data = stats;
        return performanceRows.map((row) => {
            if (!data) {
                return { ...row, disabled: true };
            }

            try {
                if (Object.keys(data).includes(row.id)) {
                    return {
                        ...row,
                        elements: row.elements.map((forecast) => {
                            return { ...forecast, value: +((data as any)[row.id][forecast.id] * 100).toFixed(2) };
                        })
                    };
                }
            } catch (e) {
                return { ...row, disabled: true };
            }

            return { ...row, disabled: true };
        });
    }, [stats, performanceRows]);

    return (
        <Box className={style.PerformanceComponent}>
            <Box className={style.cardTitle}>
                <Typography variant="h6">{'Performance'}</Typography>
            </Box>
            <Box className={style.wrapper}>
                {rows.map((e) => (
                    <PerformanceRow key={e.title} {...e} />
                ))}
            </Box>
        </Box>
    );
};

const ServiceComponent = () => {
    const [selectedCountry, setSelectedCountry] = useState<Country>('RO');

    const { data: countryData } = useGetStatsQuery({});
    //const stats: CountryData;
    const legend = countryData ? countryData.legend : defaultLegend;

    const [data = [], selectedLegend, countries, colorScale, totalProduction, timestamp] = useMemo(() => {
        if (!countryData) {
            return [[], {}, [], [], 0, ''];
        }

        const values = countryData.data[selectedCountry].value;
        const totalProduction = Object.keys(values).reduce((acc, crt) => acc + (values as any)[crt as any], 0);

        return [
            Object.values(values).map((y, index) => ({ x: index, y })),
            Object.keys(values).map((key) => ({
                name: `${getPercentage(totalProduction, (values as any)[key as any])}%  ${
                    (legend as any)[key as any]
                } - ${(values as any)[key as any]} ${getUnitOfMeasure(
                    countryData.data[selectedCountry].unit_of_measure
                )}`
            })),
            Object.keys(countryData.data),
            Object.keys(values).map((e) => colorScaleCodes[e]),
            totalProduction,
            countryData.data[selectedCountry].timestamp
        ];
    }, [countryData, selectedCountry]);

    const getCountryName = (id: string) => {
        switch (id) {
            case 'RO':
                return 'Romania';
            case 'BG':
                return 'Bulgaria';
            default:
                return 'Romania';
        }
    };

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedCountry(event.target.value as Country);
    };

    const getMouseOverHandler = () => {
        return {
            onMouseOver: () => {
                return [
                    {
                        target: 'data',
                        eventKey: 'all',
                        childName: ['pie', 'legend'],
                        mutation: (props: any) => {
                            return {
                                style: {
                                    ...props.style,
                                    opacity: 0.2
                                }
                            };
                        }
                    },
                    {
                        target: 'labels',
                        eventKey: 'all',
                        childName: ['pie', 'legend'],
                        mutation: (props: any) => {
                            return {
                                style: {
                                    ...props.style,
                                    opacity: 0.2
                                }
                            };
                        }
                    },
                    {
                        target: 'data',
                        childName: ['pie', 'legend'],
                        mutation: (props: any) => {
                            return {
                                style: {
                                    ...props.style,
                                    opacity: 1
                                }
                            };
                        }
                    },
                    {
                        target: 'labels',
                        childName: ['pie', 'legend'],
                        mutation: (props: any) => {
                            return {
                                style: {
                                    ...props.style,
                                    opacity: 1
                                },
                                active: true
                            };
                        }
                    }
                ];
            },
            onMouseOut: () => {
                return [
                    {
                        target: 'data',
                        eventKey: 'all',
                        childName: ['pie', 'legend'],
                        mutation: () => {
                            return null;
                        }
                    },
                    {
                        target: 'labels',
                        eventKey: 'all',
                        childName: ['pie', 'legend'],
                        mutation: () => {
                            return null;
                        }
                    }
                ];
            }
        };
    };

    const { width } = useWindowDimensions();
    const fontSize = getFontSize(width);

    return (
        <Box className={style.serviceComponentWrapper}>
            <Box className={style.cardTitle}>
                <Typography variant="h6">{'Stats'}</Typography>
            </Box>

            <Box className={style.emptyCard}>
                {countryData && (
                    <>
                        <Box className={style.countryPicker}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedCountry}
                                label="Country"
                                onChange={handleChange}
                            >
                                {countries.map((id) => (
                                    <MenuItem key={id} value={id}>
                                        <Flag height="24" width="24" code={id} className="flagIcon" />
                                        <Typography variant="small1" sx={{ ml: 1 }}>
                                            {getCountryName(id)}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Typography
                            variant="small4"
                            className={style.subTitle}
                        >{`Total ${totalProduction} MW - Production in ${getFormattedDate({
                            value: timestamp,
                            timezone: countryTimezone.getTimezonesWithCountryCode(selectedCountry),
                            type: DATE_SETTINGS.shortFormatHoursIncluded
                        })}`}</Typography>
                        <VictorySharedEvents
                            key={selectedCountry}
                            events={[
                                {
                                    childName: ['pie', 'legend'],
                                    target: 'data',
                                    eventHandlers: getMouseOverHandler()
                                },
                                {
                                    childName: ['pie', 'legend'],
                                    target: 'labels',
                                    eventHandlers: getMouseOverHandler()
                                }
                            ]}
                        >
                            <VictoryPie
                                width={220}
                                height={120}
                                name="pie"
                                padding={{
                                    right: 0,
                                    left: 0,
                                    top: 15
                                }}
                                style={{ data: { fillOpacity: 0.9, stroke: 'white', strokeWidth: 1 } }}
                                colorScale={colorScale}
                                data={data}
                                labels={() => ''}
                            />
                            <VictoryLegend
                                colorScale={colorScale}
                                x={0}
                                y={0}
                                name={'legend'}
                                gutter={10}
                                rowGutter={-8}
                                centerTitle
                                data={selectedLegend as any}
                                style={{ labels: { fontSize: fontSize, fontFamily: 'Montserrat, sans-serif' } }}
                                labelComponent={<VictoryLabel />}
                            />
                        </VictorySharedEvents>
                    </>
                )}
            </Box>

            {/* <Box className={style.cardTitle}>
                <Typography variant="h6">{'Managed Services'}</Typography>
            </Box>
            <Box className={common.flexColumn}>
                {serviceData.map((data) => (
                    <ServiceCard key={data.title} {...data} />
                ))}
            </Box>*/}
        </Box>
    );
};

/*export const ServiceCard = ({ title, icon, items, url }: any) => {
    const isDisabled = !items.length;
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(url);
    };

    return (
        <Box className={classNames(style.card, style.serviceCard, isDisabled && style.disabledCard)}>
            <Box className={classNames(common.flex, isDisabled && style.disabledCard)}>
                {icon}
                <Typography
                    variant="small1"
                    className={classNames(style.title, common.ml1, isDisabled && style.disabled)}
                >
                    {title}
                </Typography>
                <Box component="span" className={classNames(style.insightBtn, style.float)}>
                    <InsightsSVG />
                </Box>
            </Box>
            <Box className={classNames(style.body)}>
                {isDisabled && <Typography variant="h5">{'N/A'}</Typography>}
                {items.map(({ value, label, unit }: any, i: number) => (
                    <Box key={i} className={style.even}>
                        <Typography variant="h5">
                            {value} {unit && <Typography variant="small1">{unit}</Typography>}
                        </Typography>
                        <Typography variant="small4">{label} </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};*/

const AssetCard = ({ label, type, demand }: { type: ALL_TYPES; demand: boolean; label: string }) => {
    const { user } = useAppSelector((state) => state.login);
    const { data: stats } = useGetElectricityStatsQuery(
        {},
        { skip: !hasAccessTo(user!, ['Electricity.Generation.Assets.READ']) }
    );

    const [total, capacity, meters, disabled] = useMemo(() => {
        if (!stats || !stats.asset_data_per_type[type]) {
            return [0, 0, 0, true];
        } else {
            return [
                stats.asset_data_per_type[type].count,
                stats.asset_data_per_type[type].total_capacity,
                stats.asset_data_per_type[type].smart_meters,
                false
            ];
        }
    }, [stats]);

    return (
        <Box className={classNames(style.card, style.assetCard, disabled && style.disabledCard)}>
            <Typography variant="small1" className={common.mb1}>
                {getAssetIcon(type, common.mr1)}
                {label}
            </Typography>
            <Box className={classNames(style.content, common.mb)}>
                <Typography variant="small4">Total {!demand ? 'assets' : 'consumers'}</Typography>
                <Typography variant="small3">{total}</Typography>
            </Box>
            <Box className={style.content}>
                <Typography variant="small4">{demand ? 'Smart Meters' : 'Total capacity'}</Typography>
                <Typography variant="small3">{demand ? meters : `${(capacity / 1000).toFixed(2)} MW`}</Typography>
            </Box>
        </Box>
    );
};

const demandElements = ['ASOS', 'NCAS', 'CAS'];
const electricityElements = ['Wind', 'Solar', 'Other'];

const AssetComponent = ({ data, title, demand }: any) => {
    const { user } = useAppSelector((state) => state.login);
    const { data: stats } = useGetElectricityStatsQuery(
        {},
        { skip: !hasAccessTo(user!, ['Electricity.Generation.Assets.READ']) }
    );

    const [total, capacity] = useMemo(() => {
        if (demand) {
            return [81, 0];
        }
        if (!stats) {
            return [0, 0, 0];
        } else {
            const elements = demand ? demandElements : electricityElements;
            return Object.keys(stats.asset_data_per_type).reduce(
                (prev, crt) => {
                    const generationTypes = ['Solar', 'Wind', 'Others'];
                    if (!generationTypes.includes(crt)) {
                        return prev;
                    }
                    return [
                        prev[0] + (elements.includes(crt) ? stats.asset_data_per_type[crt as ALL_TYPES].count : 0),
                        prev[1] +
                            (elements.includes(crt) ? stats.asset_data_per_type[crt as ALL_TYPES].total_capacity : 0),
                        prev[2] +
                            (elements.includes(crt) ? stats.asset_data_per_type[crt as ALL_TYPES].smart_meters || 0 : 0)
                    ];
                },
                [0, 0, 0]
            );
        }
    }, [stats]);

    return (
        <Box className={style.assetsContainer}>
            <Box className={style.cardTitle}>
                <Typography variant="h6">{title}</Typography>
                <Typography variant="small4">
                    {demand ? 'Total consumers' : 'Total members'}
                    <Typography variant="small3" className={common.ml1}>
                        {total}
                    </Typography>
                </Typography>
                <Typography variant="small4">
                    {demand ? 'Smart meters' : 'Total capacity'}
                    <Typography variant="small3" className={common.ml1}>
                        {demand ? capacity : `${(capacity / 1000).toFixed(2)} MW`}
                    </Typography>
                </Typography>
            </Box>
            <Box className={classNames(style.assetRow)}>
                {data.map((e: any) => (
                    <AssetCard key={e.type} {...e} />
                ))}
            </Box>
        </Box>
    );
};

const GridComponent = ({ data, title }: { data: any[]; title: string }) => {
    const disabled = process.env.REACT_APP_ENV !== 'qa';

    return (
        <Box className={style.assetsContainer}>
            <Box className={style.cardTitle}>
                <Typography variant="h6">{title}</Typography>
            </Box>
            <Box className={classNames(style.assetRow)}>
                {data.map((item: any, index) => (
                    <Box
                        className={classNames(
                            style.card,
                            style.assetCard,
                            disabled && style.disabledCard,
                            style.noMinHeight
                        )}
                        key={index}
                    >
                        <Typography variant="small1" className={common.mb1}>
                            {getAssetIcon(item.type, common.mr1)}
                            {item.label}
                        </Typography>
                        <Box className={classNames(style.content, common.mb)}>
                            <Typography variant="h5">
                                {disabled ? 'N/A' : [item.total, item.measure_unit].filter((x) => x).join(' ')}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

/*export const InfrastructureComponent = () => {
    return (
        <Box className={style.infrastructureContainer}>
            <Box className={style.cardTitle}>
                <Typography variant="h6">{'Infrastructure'}</Typography>
            </Box>
            {/!* first row power lines & transformers *!/}
            <Box className={classNames(style.row, common.mb1, common.mt2)}>
                {/!* power lines*!/}
                <Box className={classNames(style.card, style.element)}>
                    <Typography variant="small1" className={common.mb1}>
                        <AddSvg className={common.mr1} />
                        {'Power lines'}
                    </Typography>
                    <Box className={classNames(common.alignBaseline, common.mb)}>
                        <Typography variant="h5">{'4500'}</Typography>
                        <Typography variant="small1" className={common.ml1}>
                            {'km'}
                        </Typography>
                    </Box>
                </Box>
                {/!* transformers *!/}
                <Box className={classNames(style.card, style.element)}>
                    <Typography variant="small1" className={common.mb1}>
                        <AddSvg className={common.mr1} />
                        {'Transformers'}
                    </Typography>
                    <Box className={classNames(common.alignBaseline, common.mb)}>
                        <Typography variant="h5">{'768'}</Typography>
                        <Typography variant="small1" className={common.ml1}>
                            {'km'}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {/!* second row installations & meters *!/}
            <Box className={classNames(style.card, style.bigRow)}>
                <Typography variant="small1">
                    <AddSvg className={common.mr1} />
                    {'Installations'}
                    <Typography variant="h7" className={common.ml2}>
                        {'135k'}
                    </Typography>
                </Typography>
                <Typography variant="small1" className={common.ml4}>
                    <AddSvg className={common.mr1} />
                    {'Meters'}
                    <Typography variant="h7" className={common.ml2}>
                        {'7.2M'}
                    </Typography>
                </Typography>
            </Box>
            {/!* third row*!/}
            <Box className={classNames(style.card, common.justifyBetween, common.mt1)}>
                <Box className={style.column}>
                    <AddSvg className={common.mb} />
                    <Typography variant="small4" className={common.mb}>
                        {'Charging stations'}
                    </Typography>
                    <Typography variant="h7">{'876'}</Typography>
                </Box>
                <Box className={style.column}>
                    <AddSvg className={common.mb} />
                    <Typography variant="small4" className={common.mb}>
                        {'Storage devices'}
                    </Typography>
                    <Typography variant="h7">{'674'}</Typography>
                </Box>
                <Box className={style.column}>
                    <AddSvg className={common.mb} />
                    <Typography variant="small4" className={common.mb}>
                        {'Other assets'}
                    </Typography>
                    <Typography variant="h7">{'436'}</Typography>
                </Box>
            </Box>
        </Box>
    );
};*/

export const ConnectedComponent = ({ data: { data } }: any) => {
    return (
        <Box className={style.connectedContainer}>
            <Box className={style.cardTitle}>
                <Typography variant="h6">{'Connected'}</Typography>
            </Box>
            <Box className={style.card}>
                <Typography variant="h3" className={style.cardSubtitle}>
                    234 <Typography variant="small1">{'MW'}</Typography>
                    <Typography variant="small1" className={common.darkGrey}>
                        {'this week'}
                    </Typography>
                    <ArrowSVG className={classNames(common.greenIcon, common.ml1)} />
                </Typography>
                <Box className={style.body}>
                    {data.map(({ title, items, icon }: any) => (
                        <Box key={title}>
                            <Typography variant="small1" className={classNames(common.mb2, common.alignCenter)}>
                                {icon(common.mr1)}
                                {title}
                            </Typography>
                            <Box className={classNames(common.flex, common.mt2)}>
                                {items.map(({ label, value }: any) => (
                                    <Box className={common.mr2} key={label}>
                                        <Typography variant="small4">{label}</Typography>
                                        <Typography variant="h7" className={common.mt1}>
                                            {value}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export const SystemStatusComponent = ({ data: { generation, demand } }: any) => {
    return (
        <Box className={style.systemStatusContainer}>
            <Box className={style.cardTitle}>
                <Typography variant="h6">{'System Status'}</Typography>
            </Box>
            <Box className={style.card}>
                <Typography variant="h3" className={style.cardSubtitle}>
                    - 234 <Typography variant="small1">{'MW'}</Typography>
                    <Typography variant="small1" className={common.darkGrey}>
                        {'this week'}
                    </Typography>
                    <ArrowSVG className={classNames(common.error, common.ml1)} />
                </Typography>
                <Box className={classNames(style.body, common.mb2)}>
                    {generation.map(({ label, value }: any) => (
                        <Box key={label} className={common.flexColumn}>
                            <Typography variant="small4" className={classNames(common.mb1, common.alignCenter)}>
                                <Dot classname={common.mr1} />
                                {label}
                            </Typography>
                            <Typography variant="small3">
                                {'+'} {value}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box className={style.body}>
                    {demand.map(({ label, value }: any) => (
                        <Box key={label} className={common.flexColumn}>
                            <Typography variant="small4" className={classNames(common.mb1, common.alignCenter)}>
                                <Dot classname={common.mr1} isRed /> {label}
                            </Typography>
                            <Typography variant="small3">
                                {'-'} {value}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

const Dashboard = () => {
    const { width } = useWindowDimensions();
    const isMobile = width < 1100;
    const gridData = [
        {
            label: 'Power lines',
            type: 'PL' as ALL_TYPES,
            total: 4207,
            measure_unit: 'km'
        },
        {
            label: 'Transformers',
            type: 'Transformers' as ALL_TYPES,
            total: 876,
            measure_unit: ''
        },
        {
            label: 'Meters',
            type: 'Transformers' as ALL_TYPES,
            total: 7.2,
            measure_unit: 'M'
        },
        {
            label: 'Total storage devices',
            type: 'Storage' as ALL_TYPES,
            total: 7.2,
            measure_unit: 'M'
        }
    ];
    const evsData = [
        {
            label: 'Charging stations',
            type: 'ChargingStation' as ALL_TYPES,
            total: 8901,
            measure_unit: ''
        },
        {
            label: 'Total locations',
            type: 'Locations' as ALL_TYPES,
            total: 6893,
            measure_unit: ''
        },
        {
            label: 'EVs charging',
            type: ASSET_TYPE.EVS,
            total: 12901,
            measure_unit: ''
        },
        {
            label: 'Total storage devices',
            type: 'Storage' as ALL_TYPES,
            total: 3901,
            measure_unit: ''
        }
    ];

    if (isMobile) {
        return (
            <Box className={style.container}>
                <PerformanceComponent />
                <ServiceComponent />
                <AssetComponent {...assetsData} />
                <AssetComponent {...demandData} demand />
                {/* <ConnectedComponent data={connectedData} />
                <SystemStatusComponent data={systemStatusData} />*/}
            </Box>
        );
    }

    return (
        <Box className={style.container}>
            <Box className={style.wrap}>
                <PerformanceComponent />
                {/*<InfrastructureComponent />*/}
                <AssetComponent {...assetsData} />
                <AssetComponent {...demandData} demand />
                <GridComponent data={gridData} title="Grid Infrastructure" />
                <GridComponent data={evsData} title="EVs Infrastructure" />
                {/* <ConnectedComponent data={connectedData} />
                <SystemStatusComponent data={systemStatusData} />*/}
            </Box>

            <ServiceComponent />
        </Box>
    );
};

export default Dashboard;
