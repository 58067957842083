import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { PERMISSION_TYPE } from './listPermissions';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const deletePermissionsAsync = createAsyncThunk(
    'assets/deletePermissions',
    async (data: { users: string[]; assetId: string; type: PERMISSION_TYPE }, { rejectWithValue }) => {
        try {
            const endpoint =
                data.type === PERMISSION_TYPE.ASSET
                    ? `/electricity/generation/assets/${data.assetId}/deny`
                    : `/projects/${data.assetId}/deny`;
            await Promise.all(
                data.users.map((user_id: string) => {
                    return axios.put(endpoint, { user_id });
                })
            );

            return null;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const deletePermissions = createSlice({
    name: 'deletePermissions',
    initialState,
    reducers: {
        resetDeletePermissionsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(deletePermissionsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(deletePermissionsAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(deletePermissionsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetDeletePermissionsState } = deletePermissions.actions;

export default deletePermissions.reducer;
