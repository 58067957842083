import { useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdOutlineFileDownload } from 'react-icons/md';
import { Box, Tooltip, Typography } from '@mui/material';

import AssetsVPP from './AssetsVPP';
import { TAB } from '../../interfaces/general';
import { UNIT } from '../../interfaces/uiv2';
import { downloadChart } from '../../stores/Assets';
import { TradingView } from '../../components/TradingView';
import { PageContainer } from '../../layout/PageContainer';
import { transformForecastToTable } from '../../utils/forecast';
import { useAppDispatch, useAppSelector } from '../../stores';
import { getFormattedCapacityValue } from '../../utils/helpers';
import { setGetParams, setSelectedRows } from '../../stores/Assets';
import SimplifiedAssetList from '../asset-list/SimplifiedAssetList';
import { ReactComponent as VPPIcon } from '../../assets/icons/vpp.svg';
import { ReactComponent as GraphIcon } from '../../assets/icons/timeline.svg';
import { ReactComponent as ViewList } from '../../assets/icons/view_list.svg';
import { ReactComponent as Ballot } from '../../assets/icons/ballot.svg';
import { setGetParamsVPPPage as vppSetGetParams } from '../../stores/VPP';
import { mockedProsumersData } from '../../stores/Prosumers/mocked-data';
import { transformProsumersDataToFE } from '../../stores/Prosumers/helpers';
import { staticVpp, prosumersVpp, staticVPPAssets } from '../vpp-list/helpers';
import { MemoizedGenerationTable } from '../../components/GenerationTable/GenerationTable';
import { TableActionsEntityContainer } from '../../components/TableActionsEntityContainer';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../components/EntityPageToolbar';
import {
    mockedDemandChartDataWithDemand as chartData,
    mockedDemandForecast
} from '../../stores/Demand/mocked-demand-chart';

import vppStyle from './vpp.module.scss';
import style from '../asset-page/asset-page.module.scss';

export const MockVPPPage = () => {
    const dispatch = useAppDispatch();
    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const { t: generalTranslation } = useTranslation();

    const pageTitle = `assetPageTitle.${tab}`;
    const { id = '' } = useParams();
    const isProsumersVpp = id === 'prosumers';
    const vpp = isProsumersVpp ? prosumersVpp : staticVpp;
    const assets = isProsumersVpp ? transformProsumersDataToFE(mockedProsumersData) : staticVPPAssets;

    const paginatedData = transformForecastToTable(mockedDemandForecast.slice(0, 100), 'RO');

    const { pager, errorForecast, errorMeasured, loadingForecast, loadingMeasured, successForecast } = useAppSelector(
        (state) => state.vppPage
    );

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    const handleDownload = () => {
        dispatch(downloadChart(true));
    };

    return (
        <PageContainer className={[style.pageContainer]}>
            <Box className={style.headerContainer}>
                <Box className={classNames(style.header, vpp.is_implicit ? vppStyle.header : '')}>
                    <VPPIcon />
                    <Typography variant="h6" className={style.title}>
                        {vpp.name}
                    </Typography>
                    <Box className={style.subtitle} sx={{ ml: 1.5 }}>
                        <Box className={style.dot} />
                        <Typography variant="small1"> {generalTranslation(pageTitle)}</Typography>
                    </Box>
                </Box>

                <Box className={classNames(style.toolbarContainer)}>
                    {tab === 'chart' && (
                        <Tooltip placement="left" title={generalTranslation('toolbar.download')}>
                            <Box className={style.downloadBtn} onClick={handleDownload}>
                                <MdOutlineFileDownload />
                            </Box>
                        </Tooltip>
                    )}
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={generalTranslation('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={generalTranslation('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.ASSETS}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={generalTranslation('assetPageTitle.assets')}
                        >
                            <Ballot />
                        </EntityPageToolbarButton>
                    </EntityPageToolbar>

                    <TableActionsEntityContainer
                        entityType="vpp"
                        id={vpp.id}
                        className={style.actionsWrapper}
                        remove={false}
                        extraActions={[
                            {
                                id: 'download',
                                disabled: tab !== TAB.CHART,
                                label: generalTranslation('toolbar.downloadChart'),
                                callback: () => null
                            },
                            {
                                id: 'editVpp',
                                label: 'table.general.editInfo',
                                disabled: false,
                                callback: () => null
                            }
                        ]}
                    />
                </Box>
            </Box>

            <Box
                className={classNames(
                    style.subHeaderContainer,
                    vppStyle.toolbar,
                    tab === 'assets' ? vppStyle.subHeaderContainer : ''
                )}
            >
                <Box className={classNames(style.subHeaderElement, style.border)}>
                    <Typography variant="small4">{generalTranslation('assetInfo.totalCapacity')}</Typography>
                    <Typography variant="small3" sx={{ ml: 1.5 }}>
                        {getFormattedCapacityValue(vpp.total_capacity)}
                    </Typography>
                </Box>
                <Box className={classNames(style.subHeaderElement, style.border)}>
                    <Typography variant="small4">{generalTranslation('assetInfo.totalAssets')}</Typography>
                    <Typography variant="small3" sx={{ ml: 1.5 }}>
                        {assets.length}
                    </Typography>
                </Box>
                <Box className={style.subHeaderElement}>
                    <Typography variant="small4">{generalTranslation('assetInfo.assetsInVPP')}</Typography>
                    <AssetsVPP variant="small3" assets={assets as any[]} />
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={chartData as any}
                    fetchMoreFn={() => {}}
                    error={errorForecast || errorMeasured}
                    loading={loadingForecast || loadingMeasured}
                    assetMeasureUnit={isProsumersVpp ? UNIT.LOW_ENERGY : UNIT.ENERGY}
                    legendOpts={{
                        measured: false,
                        forecasted: true,
                        demand: true,
                        generation: false,
                        aggregated: true
                    }}
                />
            )}

            {tab === 'table' && (
                <MemoizedGenerationTable
                    pager={pager}
                    setGetParams={vppSetGetParams}
                    data={paginatedData}
                    fetchMoreFn={() => {}}
                    error={errorForecast}
                    loading={loadingForecast}
                    success={successForecast}
                    assetMeasureUnit={isProsumersVpp ? UNIT.LOW_ENERGY : UNIT.ENERGY}
                    timezone={'utc'}
                />
            )}

            {tab === 'assets' && (
                <Box className={vppStyle.assetContainer}>
                    <SimplifiedAssetList
                        assets={assets as any[]}
                        getParams={{}}
                        setGetParams={setGetParams}
                        setSelectedRows={setSelectedRows}
                        options={{ className: vppStyle.headerTopOverwrite }}
                    />
                </Box>
            )}
        </PageContainer>
    );
};
