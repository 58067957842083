import { ReactElement } from 'react';

// Utils
import { getAssetIcon } from '../../utils/getAssetIcon';
import { ASSET_TYPE } from '../../interfaces/uiv2';
import { useGetElectricityStatsQuery, useGetEvsStatsQuery } from '../../stores/Dashboard';

export type DashboardCardType = 'row' | 'column';

export interface DashboardCardProps {
    cardKey: string;
    icon: ReactElement;
    title: string;
    data:
        | { dataKey: string; unit?: string }
        | {
              dataKey: string;
              title: string;
              unit?: string;
          }[];
}

export interface DashboardGroupProps {
    type: DashboardCardType;
    groupKey: string;
    title: string;
    query: typeof useGetElectricityStatsQuery | typeof useGetEvsStatsQuery;
    requiredPermissions: string[];
    headerStats?: {
        headerKey: string;
        title: string;
        unit?: string;
    }[];
    cards: DashboardCardProps[];
}

const Performance: DashboardGroupProps = {
    type: 'column',
    groupKey: 'performance',
    title: 'Performance',
    query: useGetElectricityStatsQuery,
    requiredPermissions: ['Electricity.Generation.Assets.READ'],
    cards: [
        {
            cardKey: 'accuracy_by_type',
            icon: getAssetIcon('Generation'),
            title: 'Generation accuracy',
            data: [
                {
                    dataKey: 'PreviousDay',
                    title: 'Last day',
                    unit: '%'
                },
                {
                    dataKey: 'PreviousWeek',
                    title: 'Last week',
                    unit: '%'
                },
                {
                    dataKey: 'PreviousMonth',
                    title: 'Last month',
                    unit: '%'
                },
                {
                    dataKey: 'total',
                    title: 'Total',
                    unit: '%'
                }
            ]
        },
        {
            cardKey: 'demand_accuracy',
            icon: getAssetIcon('Demand'),
            title: 'Demand accuracy',
            data: [
                {
                    dataKey: 'PreviousDay',
                    title: 'Last day',
                    unit: '%'
                },
                {
                    dataKey: 'PreviousWeek',
                    title: 'Last week',
                    unit: '%'
                },
                {
                    dataKey: 'PreviousMonth',
                    title: 'Last month',
                    unit: '%'
                },
                {
                    dataKey: 'total',
                    title: 'Total',
                    unit: '%'
                }
            ]
        },
        {
            cardKey: 'prosumers_accuracy',
            icon: getAssetIcon(ASSET_TYPE.PROSUMER),
            title: 'Prosumers accuracy',
            data: [
                {
                    dataKey: 'PreviousDay',
                    title: 'Last day',
                    unit: '%'
                },
                {
                    dataKey: 'PreviousWeek',
                    title: 'Last week',
                    unit: '%'
                },
                {
                    dataKey: 'PreviousMonth',
                    title: 'Last month',
                    unit: '%'
                },
                {
                    dataKey: 'total',
                    title: 'Total',
                    unit: '%'
                }
            ]
        }
    ]
};

const Generation: DashboardGroupProps = {
    type: 'row',
    groupKey: 'asset_data_per_type',
    title: 'Generation',
    query: useGetElectricityStatsQuery,
    requiredPermissions: ['Electricity.Generation.Assets.READ'],
    headerStats: [
        { headerKey: 'total_members', title: 'Total members' },
        { headerKey: 'total_capacity', title: 'Total capacity', unit: 'MW' }
    ],
    cards: [
        {
            cardKey: 'Wind',
            icon: getAssetIcon(ASSET_TYPE.WIND),
            title: 'Wind',
            data: [
                { dataKey: 'count', title: 'Total assets' },
                { dataKey: 'total_capacity', title: 'Total capacity', unit: 'MW' }
            ]
        },
        {
            cardKey: 'Solar',
            icon: getAssetIcon(ASSET_TYPE.SOLAR),
            title: 'PV',
            data: [
                { dataKey: 'count', title: 'Total assets' },
                { dataKey: 'total_capacity', title: 'Total capacity', unit: 'MW' }
            ]
        },
        {
            cardKey: 'Other',
            icon: getAssetIcon(ASSET_TYPE.OTHER),
            title: 'Other',
            data: [
                { dataKey: 'count', title: 'Total assets' },
                { dataKey: 'total_capacity', title: 'Total capacity', unit: 'MW' }
            ]
        },
        {
            cardKey: 'VPP',
            icon: getAssetIcon('VPP'),
            title: 'VPP',
            data: [
                { dataKey: 'count', title: 'Total assets' },
                { dataKey: 'total_capacity', title: 'Total capacity', unit: 'MW' }
            ]
        }
    ]
};

const Demand: DashboardGroupProps = {
    type: 'row',
    groupKey: 'demand_data_per_type',
    title: 'Demand',
    query: useGetElectricityStatsQuery,
    requiredPermissions: ['Electricity.Generation.Assets.READ'],
    headerStats: [
        { headerKey: 'total_consumers', title: 'Total consumers' },
        { headerKey: 'smart_meters', title: 'Smart meters' }
    ],
    cards: [
        {
            cardKey: 'CAS',
            icon: getAssetIcon('CAS'),
            title: 'LV',
            data: [
                {
                    dataKey: 'count',
                    title: 'Total consumers'
                },
                {
                    dataKey: 'smart_meters',
                    title: 'Smart Meters'
                }
            ]
        },
        {
            cardKey: 'ASOS',
            icon: getAssetIcon('ASOS'),
            title: 'MV',
            data: [
                {
                    dataKey: 'count',
                    title: 'Total consumers'
                },
                {
                    dataKey: 'smart_meters',
                    title: 'Smart Meters'
                }
            ]
        },
        {
            cardKey: 'NCAS',
            icon: getAssetIcon('NCAS'),
            title: 'HV',
            data: [
                {
                    dataKey: 'count',
                    title: 'Total consumers'
                },
                {
                    dataKey: 'smart_meters',
                    title: 'Smart Meters'
                }
            ]
        },
        {
            cardKey: 'Prosumer',
            icon: getAssetIcon(ASSET_TYPE.PROSUMER),
            title: 'Prosumers',
            data: [
                {
                    dataKey: 'count',
                    title: 'Total assets'
                },
                {
                    dataKey: 'total_capacity',
                    title: 'Total capacity',
                    unit: 'MW'
                }
            ]
        }
    ]
};

const GridInfrastructure: DashboardGroupProps = {
    type: 'row',
    groupKey: 'grid_infrastructure',
    title: 'Grid Infrastructure',
    query: useGetElectricityStatsQuery,
    requiredPermissions: ['Electricity.Generation.Assets.READ'],
    cards: [
        {
            cardKey: 'PL',
            icon: getAssetIcon('PL'),
            title: 'Power lines',
            data: { dataKey: 'total', unit: 'km' }
        },
        {
            cardKey: 'Transformers',
            icon: getAssetIcon('Transformers'),
            title: 'Transformers',
            data: { dataKey: 'total' }
        },
        {
            cardKey: 'Transformers2',
            icon: getAssetIcon('Transformers'),
            title: 'Meters',
            data: { dataKey: 'meters', unit: 'M' }
        },
        {
            cardKey: 'Storage',
            icon: getAssetIcon('Storage'),
            title: 'Total storage devices',
            data: { dataKey: 'total_storage', unit: 'M' }
        }
    ]
};

const EVsInfrastructure: DashboardGroupProps = {
    type: 'row',
    groupKey: 'evs_stats',
    title: 'EVs Infrastructure',
    query: useGetEvsStatsQuery,
    requiredPermissions: ['EVS.ChargingStations.READ'],
    cards: [
        {
            cardKey: 'charging_stations',
            icon: getAssetIcon('ChargingStation'),
            title: 'Charging stations',
            data: { dataKey: 'count' }
        },
        {
            cardKey: 'charging_points',
            icon: getAssetIcon('ChargingPoint'),
            title: 'Charging points',
            data: { dataKey: 'count' }
        },
        {
            cardKey: 'EVS',
            icon: getAssetIcon(ASSET_TYPE.EVS),
            title: 'EVs charging',
            data: { dataKey: 'count' }
        },
        {
            cardKey: 'Storage',
            icon: getAssetIcon('Storage'),
            title: 'Total storage devices',
            data: { dataKey: 'total_storage', unit: 'M' }
        }
    ]
};

export const DashboardConfig: DashboardGroupProps[] = [
    Performance,
    Generation,
    Demand,
    GridInfrastructure,
    EVsInfrastructure
];
