import i18next from 'i18next';
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { GetParams } from '../../interfaces/general';
import { ForecastListGetParams } from '../Forecasts';
import ToastifyType from '../../utils/toastify-config';
import { BarData } from '../../components/TradingViewChart';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { ApiPaginationInfo, PaginatedApiResponse } from '../../interfaces/apiv2';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';

interface IDeliveredGasForecastPage extends DefaultInitialState {
    chartData: BarData[];
    data: ReportingData[];
    paginatedData: ReportFE[];
    pager: ApiPaginationInfo | null;
    getParams: GetParams;
}

export interface Report {
    from_timestamp: string;
    forecasted_division_profiled: number; // forecasted
    measured_division_profiled: number; // measured
    cluster_forecasted: number; // forecasted cluster
    volume_profiled_smart: number; // measured profiled
}

export interface ReportFE extends Report {
    index: number;
    name: string;
}

export interface ReportingData {
    consumer: {
        id: string;
        name: string;
    };
    report: Report[];
}

const initialState: IDeliveredGasForecastPage = {
    chartData: [],
    data: [],
    paginatedData: [],
    loading: false,
    error: false,
    success: false,
    pager: null,
    getParams: DEFAULT_GET_PARAMS_VALUE
};

export const getReportingAsync = createAsyncThunk(
    'reporting/show-1-2-3',
    async (
        { from_datetime, to_datetime, project_id }: { from_datetime: string; to_datetime: string; project_id: string },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.get<PaginatedApiResponse<any>>(
                `/gas/demand/${project_id}/consumers/reporting`,
                {
                    params: {
                        project_id,
                        from_timestamp: from_datetime,
                        to_timestamp: to_datetime,
                        size: 100
                    }
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getMoreReportingAsync = createAsyncThunk(
    'reporting/more-show-4-5-6',
    async (
        {
            from_datetime,
            to_datetime,
            project_id,
            page
        }: { from_datetime: string; to_datetime: string; project_id: string; page: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.get<PaginatedApiResponse<any>>(
                `/gas/demand/${project_id}/consumers/reporting`,
                {
                    params: {
                        project_id,
                        from_timestamp: from_datetime,
                        to_timestamp: to_datetime,
                        page,
                        size: 100
                    }
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

const emptyData = {
    consumer: { name: '' },
    report: [{ measured: 0, forecasted: 0 }]
};

export const deliveredForecastPage = createSlice({
    name: 'deliveredForecastPage',
    initialState,
    reducers: {
        resetGasDeliveredForecastPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastListGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMoreReportingAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getMoreReportingAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            const stateData = [...current(state).data];
            stateData.shift();
            const newData = [emptyData, ...payload.items, ...stateData];
            state.data = newData;
            state.paginatedData = [...payload.items, ...stateData].map((e: ReportingData, index) => {
                return {
                    index: index + 1,
                    name: e.consumer.name,
                    ...e.report[0]
                };
            });
            state.chartData = newData.map((e: ReportingData, index) => {
                return {
                    time: index,
                    name: e.consumer.name,
                    values: [
                        e.report[0].measured_division_profiled || 0,
                        e.report[0].forecasted_division_profiled || 0,
                        e.report[0].volume_profiled_smart,
                        e.report[0].cluster_forecasted
                    ]
                };
            });
            state.pager = {
                total: payload.total,
                page: payload.page,
                size: payload.size,
                pages: payload.pages
            };
        });
        builder.addCase(getMoreReportingAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getReportingAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getReportingAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            const newData = [emptyData, ...payload.items];
            state.data = newData;
            state.paginatedData = payload.items.map((e: ReportingData, index) => {
                return {
                    index: index + 1,
                    name: e.consumer.name,
                    ...e.report[0]
                };
            });
            state.chartData = newData.map((e: ReportingData, index) => {
                return {
                    time: index,
                    name: e.consumer.name,
                    values: [
                        e.report[0].measured_division_profiled || 0,
                        e.report[0].forecasted_division_profiled || 0,
                        e.report[0].volume_profiled_smart,
                        e.report[0].cluster_forecasted
                    ]
                };
            });
            state.pager = {
                total: payload.total,
                page: payload.page,
                size: payload.size,
                pages: payload.pages
            };
        });
        builder.addCase(getReportingAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetGasDeliveredForecastPage, setGetParams } = deliveredForecastPage.actions;

export default deliveredForecastPage.reducer;
