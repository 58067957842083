import dayjs from 'dayjs';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { MdCheck } from 'react-icons/md';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { MdOutlineWarningAmber } from 'react-icons/md';

import { DATE_SETTINGS } from '../../utils/config';
import { ImportedFileFE } from '../../interfaces/uiv2';
import { Avatar, Box, Typography } from '@mui/material';
import { TableCellRenderer } from '../../interfaces/general';
import { DownloadButton } from '../../components/DownloadButton';
import { DEFAULT_EMPTY_CELL_VALUE, stringAvatar } from '../../utils/table';
import { DefaultSearchableCell, TableActions } from '../../components/BaseTable';

import common from '../../common.module.scss';

export const getTableHeadCells = ({ t }: { t: TFunction }): TableCellRenderer<ImportedFileFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return <Typography variant="small4">{row.index < 10 ? `0${row.index}` : `${row.index}`}</Typography>;
            },
            sort: false
        },
        {
            id: 'name',
            label: t('tableHead.name'),
            sort: false,
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.file_name} searchValue={searchValue} />;
            }
        },
        {
            id: 'created_at',
            label: t('tableHead.date'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.created_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormatHoursIncluded)
                        : DEFAULT_EMPTY_CELL_VALUE;
                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'file_type',
            label: t('tableHead.type'),
            justifyContent: 'center',
            sort: true,
            value: (row) => {
                return <DownloadButton type={row.file_type} id={row.id} />;
            }
        },
        {
            id: 'status',
            label: t('tableHead.status'),
            sort: false,
            value: (row, searchValue) => {
                return (
                    <DefaultSearchableCell
                        className={classNames(
                            row.status === 'Completed' && common.success,
                            row.status === 'Pending' && common.warning,
                            row.status === 'Failed' && common.error
                        )}
                        value={row.status}
                        searchValue={searchValue}
                    />
                );
            }
        },
        {
            id: 'user',
            label: t('tableHead.user'),
            sort: false,
            value: (row, searchValue) => {
                const full_name = `${row.user?.first_name} ${row.user?.last_name}`;
                return (
                    <>
                        <Avatar {...stringAvatar(full_name)} variant="userAccount" sx={{ bgcolor: '#7B61FF', mr: 1 }} />{' '}
                        <DefaultSearchableCell value={full_name} searchValue={searchValue} />
                    </>
                );
            }
        },
        {
            id: 'project',
            label: t('tableHead.project'),
            sort: false,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.project?.name;
                const handler = handlers?.['goToProject'];
                const callback = handler ? handler(row) : undefined;

                return (
                    <DefaultSearchableCell
                        value={value}
                        searchValue={searchValue}
                        onClick={callback}
                        variant={'small3'}
                    />
                );
            }
        },
        {
            id: 'errors',
            label: t('tableHead.errors'),
            justifyContent: 'center',
            maxWidth: '80px',
            sort: false,
            value: (row) => {
                return row.has_errors ? (
                    <MdOutlineErrorOutline className={common.error} />
                ) : (
                    <MdCheck className={common.success} />
                );
            }
        },
        {
            id: 'warnings',
            label: t('tableHead.warnings'),
            justifyContent: 'center',
            maxWidth: '80px',
            sort: false,
            value: (row) => {
                return row.has_warnings ? (
                    <MdOutlineWarningAmber className={common.warning} />
                ) : (
                    <MdCheck className={common.success} />
                );
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: t('actions'),
            value: (row, searchValue, onClick, handlers) => {
                const downloadOutput = handlers?.['downloadOutput'];
                const downloadInput = handlers?.['downloadInput'];

                const actions = [
                    {
                        id: 'downloadInput',
                        label: `table.general.downloadInput`,
                        callback: () => {
                            downloadInput(row);
                        }
                    }
                ];

                if (row.has_warnings || row.has_errors) {
                    actions.push({
                        id: 'downloadOutput',
                        label: `table.general.downloadOutput`,
                        callback: () => {
                            downloadOutput(row);
                        }
                    });
                }
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions actions={actions} />
                    </Box>
                );
            }
        }
    ];
};
