import React from 'react';
import { Avatar, Box, SvgIcon, Typography } from '@mui/material';
import style from './permissions.module.scss';
import { stringAvatar } from '../../utils/table';
import { ReactComponent as DeleteSVG } from '../../assets/icons/delete_forever.svg';
import { User } from '../../interfaces/apiv2';
import { buildUsername } from './helpers';

interface IUserRowProps {
    user: User;
    index: number;
    deleteRow: any;
}
const UserRow = ({ user, index, deleteRow }: IUserRowProps) => {
    const full_name = buildUsername(user);
    return (
        <Box className={style.listRow} sx={{ mt: 1, mb: 1 }}>
            <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="small1" className={style.listIndex}>
                    {index < 10 ? '0' + index : index}
                </Typography>
                <Avatar {...stringAvatar(full_name)} variant="userAccount" sx={{ bgcolor: '#7B61FF', ml: 1 }} />
                <Typography variant="small1" className={style.rowName} sx={{ ml: 1.5 }}>
                    {full_name}
                </Typography>
            </Box>
            <Box component="span" sx={{ display: 'flex' }}>
                {/*<Select
                    className={classnames(style.permissionSelect, open && style.isDropdownOpen)}
                    fullWidth
                    label={'permissionsSelect'}
                    value={ASSET_PERMISSION.VIEW || user.permission}
                    onChange={handleChange}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    open={open}
                >
                    <MenuItem value={ASSET_PERMISSION.VIEW}>
                        <ViewSVG className={style.optionSvg} />{' '}
                        <Typography variant="small4">{t('permissionsModal.viewOnly')}</Typography>
                    </MenuItem>
                    <MenuItem value={ASSET_PERMISSION.EDIT}>
                        <EditSVG className={style.optionSvg} />
                        <Typography variant="small4">{t('permissionsModal.edit')}</Typography>
                    </MenuItem>
                </Select>*/}
                <SvgIcon
                    className={style.icon}
                    component={DeleteSVG}
                    titleAccess={'test'}
                    color="primary"
                    onClick={deleteRow(user)}
                />
            </Box>
        </Box>
    );
};

export default UserRow;
