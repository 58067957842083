import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { MdDeleteOutline, MdRefresh } from 'react-icons/md';

import { MODAL_IDS } from '../../../modals';
import { ROUTES } from '../../../utils/config';
import { getChargingPointsHeadCells, getChargingStationsHeadCells } from '../helpers';
import { ModalContext } from '../../../hooks/modalContext';
import { PageContainer } from '../../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { GenericTable, TableSearch } from '../../../components/BaseTable';
import { transformOrderByToSort } from '../../../components/BaseTable/GenericTable/helpers';
import { getChargingStationsListAsync, resetChargingStations, setGetChargingStationsParams } from '../../../stores/EVS';

import style from '../../../features/forecast-list/forecast-list.module.scss';
import { AssetLocationModal } from '../../../modals/AssetLocationModal';
import useStickyHeader from '../../../hooks/useStickyHeader';
import { ChargingStationsFE, UNIT } from '../../../interfaces/uiv2';
import { useParams } from 'react-router-dom';

const ChargingStationsList = () => {
    const dispatch = useAppDispatch();
    const { projectId = '' } = useParams();
    const { handleClickOpen } = useContext(ModalContext);
    const [selectedRows, setSelectedRows] = useState([]);
    const [activeRow, setActiveRow] = useState<ChargingStationsFE>();
    const [expandedRow, setExpandedRow] = useState<number | undefined>();

    const { stationsFE, loading, getParams, pager, success } = useAppSelector((state) => state.listEVSChargingStations);

    useEffect(() => {
        return () => {
            dispatch(resetChargingStations());
        };
    }, []);

    useEffect(() => {
        dispatch(getChargingStationsListAsync({ projectId, getParams }));
    }, [getParams]);

    const { t } = useTranslation();
    const headCells = getChargingStationsHeadCells(t);
    const pointsHeadCells = getChargingPointsHeadCells(t, UNIT.LOW_ENERGY);

    const clearSelection = () => {
        setSelectedRows([]);
    };

    const openConfirmationModal = () => {
        handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
    };

    const openModal = (modalId: MODAL_IDS) => (row: ChargingStationsFE) => () => {
        setActiveRow(row);
        handleClickOpen(modalId);
    };

    const setActiveExpandedRow = (index: number) => {
        setExpandedRow((prevIndex) => {
            if (index === prevIndex) {
                return undefined;
            } else {
                return index;
            }
        });
    };

    const getChargingStationPageUrl = (row: ChargingStationsFE) => () => {
        return `${ROUTES.AI_EVS_CHARGHING_STATIONS_PAGE.replace(':id', row.id).replace(':projectId', projectId)}`;
    };

    const getChargingPointPageUrl = (row: ChargingStationsFE) => () => {
        return `${ROUTES.AI_EVS_CHARGHING_POINTS_PAGE.replace(':id', row.id)
            .replace(':projectId', projectId)
            .replace(':stationId', row?.parent_id || '')}?name=${row.name}`;
    };

    useStickyHeader('charging-stations-list-header');

    return (
        <PageContainer>
            <Box className={style.header} id={'charging-stations-list-header'}>
                <Typography variant="h6" className={style.title}>
                    {t('header.charging-stations')}
                </Typography>

                <Box className={style.toolbar}>
                    {!!selectedRows.length && (
                        <>
                            <Typography variant="small4" className={style.label}>
                                {selectedRows.length} {t('table.general.selected')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdRefresh />
                                <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                    {t('table.general.clearSelection')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdDeleteOutline />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={openConfirmationModal}
                                    sx={{ ml: 1 }}
                                >
                                    {t('table.general.removeAll')}
                                </Box>
                            </Typography>
                        </>
                    )}

                    <TableSearch setGetParams={setGetChargingStationsParams} getParams={getParams} />
                </Box>
            </Box>

            <GenericTable
                pager={pager}
                loading={loading}
                success={success}
                rows={stationsFE}
                isCheckboxEnabled={false}
                rowUniqueId={'id'}
                columns={headCells}
                selectedRows={selectedRows}
                searchParam={getParams.search}
                setGetParams={setGetChargingStationsParams}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    name: getChargingStationPageUrl,
                    profile: getChargingStationPageUrl,
                    location: openModal(MODAL_IDS.ASSET_LOCATION_MODAL),
                    charging_points: (row: any) => setActiveExpandedRow(row.index - 1)
                }}
                expandableTableRow={{
                    columns: pointsHeadCells,
                    rowsId: 'charging_points',
                    activeRow: expandedRow,
                    handlers: {
                        name: getChargingPointPageUrl,
                        profile: getChargingPointPageUrl
                    }
                }}
            />
            {activeRow && (
                <AssetLocationModal
                    payload={{
                        longitude: activeRow.longitude,
                        latitude: activeRow.latitude,
                        title: activeRow.city,
                        location: activeRow.city || ''
                    }}
                />
            )}
        </PageContainer>
    );
};

export default ChargingStationsList;
