import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { GenerationAsset } from '../../interfaces/apiv2';
import { GenerationAssetFE } from '../../interfaces/uiv2';
import { DefaultInitialState } from '../../interfaces/redux';
import { transformGenerationAssetAPItoFE } from '../Assets/helpers';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { api } from '../api';

interface vppAssetsState extends DefaultInitialState {
    generationAssets: GenerationAsset[];
    generationAssetsFE: GenerationAssetFE[];
    selectedGenerationAssets: GenerationAsset[];
}

const initialState: vppAssetsState = {
    loading: false,
    error: false,
    success: false,
    selectedGenerationAssets: [],
    generationAssets: [],
    generationAssetsFE: []
};

export const vppAssetsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getVppAssets: build.query({
            query: (params) => {
                return { url: `/electricity/generation/assets/vpp/${params.id}/assets`, method: 'get', data: [] };
            },
            transformResponse: (rawData: GenerationAsset[]): GenerationAssetFE[] => {
                return transformGenerationAssetAPItoFE(rawData);
            },
            keepUnusedDataFor: 3
        })
    })
});

export const { useGetVppAssetsQuery } = vppAssetsApi;

export const getVPPAssetsAsync = createAsyncThunk(
    'vpp/getAssets',
    async (values: { id: string }, { rejectWithValue }) => {
        try {
            const endpoint = `/electricity/generation/assets/vpp/${values.id}/assets`;

            const response = await axios.get<GenerationAsset[]>(endpoint);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getVPPAssets = createSlice({
    name: 'getVPPAssets',
    initialState,
    reducers: {
        resetGetVPPAssetsAsync: () => initialState,
        setSelectedGenerationAssets: (state, { payload }: PayloadAction<GenerationAsset[]>) => {
            state.selectedGenerationAssets = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getVPPAssetsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getVPPAssetsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.generationAssets = payload;
            state.generationAssetsFE = transformGenerationAssetAPItoFE(current(state).generationAssets);
        });
        builder.addCase(getVPPAssetsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetGetVPPAssetsAsync, setSelectedGenerationAssets } = getVPPAssets.actions;

export default getVPPAssets.reducer;
