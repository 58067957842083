import React, { FC } from 'react';

import { UNIT } from '../../interfaces/uiv2';
import { TradingView } from '../../components/TradingView';

import Loading from '../../layout/Loading';

const Chart: FC<any> = ({ loading, chartData, data, fetchMoreGas, error }) => {
    return (
        <>
            {loading && chartData.length === 0 ? (
                <Loading />
            ) : (
                <TradingView
                    data={chartData}
                    rawData={data}
                    barChart
                    fetchMoreFn={fetchMoreGas}
                    legendOpts={{
                        forecasted: false,
                        measured: false,
                        measuredProfiled: true,
                        forecastedCluster: true,
                        forecastedRo: true,
                        measuredRo: true
                    }}
                    error={error}
                    loading={false}
                    assetMeasureUnit={UNIT.M3}
                />
            )}
        </>
    );
};

export default Chart;
