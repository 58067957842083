import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { transformClustersDataToFE } from './helpers';
import { GetParams } from '../../interfaces/general';
import { getQueryParamsFromObject } from '../../utils/table';
import { DefaultEntityTimestamps, Prosumers } from '../../interfaces/apiv2';

import axios from '../../utils/axios';

export interface ProsumerCluster extends DefaultEntityTimestamps, Prosumers {
    id: string;
    name: string;
    total_prosumers: number;
}

export interface ProsumerClusterFE extends ProsumerCluster {
    index: number;
}

interface ListProsumersState extends DefaultInitialState {
    clusters: ProsumerCluster[];
    clustersFE: ProsumerClusterFE[];
    getParams: GetParams;
}

const initialState: ListProsumersState = {
    clusters: [],
    clustersFE: [],
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE
};

export const getProsumerClustersListAsync = createAsyncThunk(
    'prosumers-clusters/list',
    async (values: GetParams, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...values });
            const response = await axios.get(`/electricity/prosumers/clusters${queryParams}`);
            const items = response.data.items;
            return items as ProsumerCluster[];
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listProsumerClusters = createSlice({
    name: 'listProsumerClusters',
    initialState,
    reducers: {
        resetListProsumerClustersState: () => initialState,
        setGetClustersParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProsumerClustersListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getProsumerClustersListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.clusters = payload;
            state.clustersFE = transformClustersDataToFE(payload);
        });
        builder.addCase(getProsumerClustersListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetListProsumerClustersState, setGetClustersParams } = listProsumerClusters.actions;

export default listProsumerClusters.reducer;
