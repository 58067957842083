import listMarkets from './listMarkets';
import marketsPricingPage from './marketsPricingPage';

export * from './listMarkets';
export * from './marketsPricingPage';

export default {
    listMarkets,
    marketsPricingPage
};
