import dayjs from 'dayjs';
import { FC } from 'react';
import * as yup from 'yup';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Box, Divider, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { MODAL_IDS } from '..';
import { useAppDispatch } from '../../stores';
import { useFormLogic } from '../../hooks/useFormLogic';
import { FormModalActions } from '../../components/FormUtils';
import { updateAssetTechnicalDetailsAsync } from '../../stores/Assets';
import AssetStatusLabel from '../../components/AssetStatusLabel/AssetStatusLabel';
import { AssetTechnicalDetailsFormData, ASSET_STATUS, GenerationAssetFE } from '../../interfaces/uiv2';

import style from './style.module.scss';

export const AssetTechnicalDetailsForm: FC<{ asset?: GenerationAssetFE }> = ({ asset }) => {
    const dispatch = useAppDispatch();
    const { t: generalTranslation } = useTranslation();

    const { t } = useTranslation('assets/generation');

    const schema = yup.object({
        meteo_start_date: yup.string().nullable(),
        training_start: yup.string().nullable(),
        status: yup.string().nullable()
    });

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useFormLogic<
        AssetTechnicalDetailsFormData,
        AssetTechnicalDetailsFormData,
        keyof typeof schema.fields,
        AssetTechnicalDetailsFormData
    >({
        schema,
        data: null,
        defaultValues: {
            meteo_start_date: asset!.meteo_start_date || '',
            training_start: asset!.training_start || '',
            status: asset?.status || 'Pending'
        }
    });

    const onSubmit = (form: AssetTechnicalDetailsFormData) => {
        const body = {
            ...asset!,
            meteo_start_date: form.meteo_start_date
                ? dayjs(form.meteo_start_date).utc().toISOString()
                : asset?.meteo_start_date,
            training_start: form.training_start
                ? dayjs(form.training_start).utc().toISOString()
                : asset?.training_start,
            status: form.status
        };

        dispatch(updateAssetTechnicalDetailsAsync({ id: asset!.id, body }));
    };

    return (
        <Box className={style.container}>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ p: 2 }}>
                <Box sx={{ mb: 2 }}>
                    <Controller
                        name="meteo_start_date"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" shrink htmlFor={'meteoStartDate'}>
                                    {t('form.meteo_start_date')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="meteoStartDate"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.meteo_start_date && (
                                    <FormHelperText error>{t('form.validations.required')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Controller
                        name="training_start"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" shrink htmlFor={'trainingStart'}>
                                    {t('form.training_start')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="trainingStart"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.training_start && (
                                    <FormHelperText error>{t('form.validations.required')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>

                <Box sx={{ mb: 4 }}>
                    <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.status')}>
                                    {t('form.status')}
                                </InputLabel>
                                <Select
                                    {...field}
                                    onChange={(v) => {
                                        field.onChange(v.target.value);
                                        return v;
                                    }}
                                    fullWidth
                                    labelId="asset-status-label"
                                    id="asset-status-id"
                                    label={t('form.status')}
                                >
                                    {['Pending', 'Active', 'Disabled'].map((status) => (
                                        <MenuItem value={status} key={status}>
                                            <AssetStatusLabel
                                                status={status as ASSET_STATUS}
                                                customTextColorEnabled={false}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    />
                </Box>
                <Divider className={style.divider} />

                <FormModalActions
                    loading={false}
                    modalId={MODAL_IDS.ASSET_TECHNICAL_DETAILS_MODAL}
                    cancelLabel={generalTranslation('nevermind')}
                    saveLabel={generalTranslation('submit')}
                />
            </Box>
        </Box>
    );
};

export default AssetTechnicalDetailsForm;
