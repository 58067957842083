import { debounce } from 'lodash';
import classNames from 'classnames';
import { Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Autocomplete, Box, InputLabel, TextField } from '@mui/material';

import Loading from '../../../layout/Loading';
import { ChargingPointsFE } from '../../../interfaces/uiv2';
import { useGetChargingPointsQuery } from '../../../stores/EVS';
import { AutocompletesAction, AutocompletesState } from './interfaces';

import style from '../style.module.scss';

export const EVSReportCPAutocomplete = ({
    state,
    dispatch,
    projectId,
    chargingStationId
}: {
    state: AutocompletesState;
    dispatch: Dispatch<AutocompletesAction>;
    projectId: string;
    chargingStationId: string;
}) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState<string | undefined>(undefined);
    const { data: chargingPoints, isLoading } = useGetChargingPointsQuery({
        search: search,
        page: 1,
        size: 100,
        projectId,
        chargingStationId
    });

    const onChangeInput = debounce((e: any) => {
        const value = e.target.value;
        setSearch(value || undefined);
    }, 450);

    const handleChange = () => {
        dispatch({ type: 'SET_CHARGING_POINT', payload: null });
        dispatch({ type: 'SET_CHARGING_POINT_FS', payload: null });
        setSearch(undefined);
        dispatch({ type: 'SET_OPEN', payload: { ...state.open, chargingPoint: false } });
    };

    const handleIdChange = (e: ChargingPointsFE) => () => {
        dispatch({ type: 'SET_CHARGING_POINT', payload: e });
        dispatch({ type: 'SET_CHARGING_POINT_FS', payload: null });
        dispatch({ type: 'SET_OPEN', payload: { ...state.open, chargingPoint: false } });
    };

    return (
        <Box className={classNames(style.formRow, style.fullWidth)}>
            <InputLabel variant="standard" required shrink htmlFor={t('evsReportForm.chargingPoint')}>
                {t('evsReportForm.chargingPoint')}
            </InputLabel>
            <Autocomplete
                id="asynchronous-charging-points"
                value={state.chargingPoint}
                options={chargingPoints || []}
                className={state.open.chargingPoint ? style.autocompleteOpen : ''}
                loading={isLoading}
                onChange={handleChange}
                noOptionsText={t('select.noOptions')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                popupIcon={isLoading ? <Loading className={style.loading} /> : <MdKeyboardArrowDown color="#6A9BFF" />}
                renderOption={(props: any, e: ChargingPointsFE) => (
                    <Box component="li" {...props} key={Math.random()} onClick={handleIdChange(e)}>
                        {e.name}
                    </Box>
                )}
                ListboxProps={{ style: { maxHeight: '180px' } }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={onChangeInput}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: t('evsReportForm.chargingPoint')
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <Loading /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
                open={state.open.chargingPoint}
                onOpen={() => {
                    dispatch({ type: 'SET_OPEN', payload: { ...state.open, chargingPoint: true } });
                }}
                onClose={() => {
                    dispatch({ type: 'SET_OPEN', payload: { ...state.open, chargingPoint: false } });
                }}
                clearOnEscape={true}
                clearOnBlur={true}
                filterOptions={(x) => x}
            />
        </Box>
    );
};
