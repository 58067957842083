import listEVSChargingStations from './listEVSChargingStations';
import chargingStationPage from './evsChargingStationPage';
import chargingPointPage from './evsChargingPointPage';
import listEVSClusters from './listEVSClusters';
import evsClusterPage from './evsClusterPage';
import listEVSForecasts from './listForecasts';
import evsForecastPage from './forecastPage';
import listEVSReports from './listEVSReports';
import evsReportPage from './evsReportPage';
import { api } from '../api';
import { ChargingPoints, ChargingStations, PaginatedApiResponse } from '../../interfaces/apiv2';
import { ChargingPointsFE, ChargingStationsFE } from '../../interfaces/uiv2';
import { transformChargingPointsDataToFE, transformChargingStationsDataToFE } from './helpers';
import { ChargingPointForecastSettings } from '../../interfaces/apiv2/evs/charging_point_forecast_settings';

export * from './listEVSChargingStations';
export * from './evsChargingStationPage';
export * from './evsChargingPointPage';
export * from './listEVSClusters';
export * from './evsClusterPage';
export * from './listForecasts';
export * from './listEVSReports';
export * from './evsReportPage';

export const evsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getChargingStations: build.query({
            query: (params: { search?: string | null; projectId: string; page: number; size: number }) => {
                if (params.search?.trim()) {
                    return {
                        url: `/evs/${params.projectId}/charging-stations`,
                        method: 'get',
                        params: { ...params, search: params.search },
                        data: undefined
                    };
                }

                return { url: `/evs/${params.projectId}/charging-stations`, method: 'get', params };
            },
            transformResponse: (rawData: PaginatedApiResponse<ChargingStations>): ChargingStationsFE[] => {
                return transformChargingStationsDataToFE(rawData.items);
            }
        }),
        getChargingPoints: build.query({
            query: (params: {
                search?: string | null;
                projectId: string;
                chargingStationId: string;
                page: number;
                size: number;
            }) => {
                if (params.search?.trim()) {
                    return {
                        url: `/evs/${params.projectId}/charging-stations/${params.chargingStationId}/charging-points`,
                        method: 'get',
                        params: { ...params, search: params.search },
                        data: undefined
                    };
                }

                return {
                    url: `/evs/${params.projectId}/charging-stations/${params.chargingStationId}/charging-points`,
                    method: 'get',
                    params
                };
            },
            transformResponse: (rawData: PaginatedApiResponse<ChargingPoints>): ChargingPointsFE[] => {
                return transformChargingPointsDataToFE(rawData.items);
            }
        }),
        getChargingPointForecastSettings: build.query({
            query: (params: {
                search?: string | null;
                projectId: string;
                chargingPointId: string;
                page: number;
                size: number;
            }) => {
                if (params.search?.trim()) {
                    return {
                        url: `/evs/${params.projectId}/charging-points/${params.chargingPointId}/forecast-settings`,
                        method: 'get',
                        params: { ...params, search: params.search },
                        data: undefined
                    };
                }

                return {
                    url: `/evs/${params.projectId}/charging-points/${params.chargingPointId}/forecast-settings`,
                    method: 'get',
                    params
                };
            },
            transformResponse: (rawData: ChargingPointForecastSettings[]): ChargingPointForecastSettings[] => {
                return rawData;
            }
        })
    })
});

export const { useGetChargingStationsQuery, useGetChargingPointsQuery, useGetChargingPointForecastSettingsQuery } =
    evsApi;

export default {
    listEVSChargingStations,
    chargingStationPage,
    chargingPointPage,
    listEVSClusters,
    evsClusterPage,
    listEVSForecasts,
    evsForecastPage,
    listEVSReports,
    evsReportPage
};
