import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Autocomplete, Box, InputLabel, TextField } from '@mui/material';

import Loading from '../../../layout/Loading';
import { ForecastDeliverySettingsV2 } from '../../../interfaces/apiv2';
import { AutocompletesAction, AutocompletesState } from './interfaces';

import style from '../style.module.scss';
import { useHideInput } from '../../../hooks/useHideInput';
import classNames from 'classnames';

export const GenerationReportDeliverySettings = ({
    state,
    dispatch,
    deliverySettings
}: {
    state: AutocompletesState;
    dispatch: Dispatch<AutocompletesAction>;
    deliverySettings: ForecastDeliverySettingsV2[];
}) => {
    const { t } = useTranslation();
    const isLoading = false;

    const handleChange = () => {
        dispatch({ type: 'SET_DELIVERY_SETTING', payload: null });
        dispatch({ type: 'SET_OPEN', payload: { ...state.open, deliverySetting: false } });
    };

    const handleIdChange = (e: ForecastDeliverySettingsV2) => () => callback(e);

    const callback = (e: ForecastDeliverySettingsV2) => {
        dispatch({ type: 'SET_DELIVERY_SETTING', payload: e });
        dispatch({ type: 'SET_OPEN', payload: { ...state.open, deliverySetting: false } });
    };

    const { hideInput } = useHideInput({ data: deliverySettings, callback });

    if (hideInput) {
        return <></>;
    }

    return (
        <Box className={classNames(style.formRow, style.fullWidth)}>
            <InputLabel variant="standard" required shrink htmlFor={t('reportForm.deliverySetting')}>
                {t('reportForm.deliverySetting')}
            </InputLabel>
            <Autocomplete
                id="asynchronous-delivery-settings"
                value={state.deliverySetting}
                options={deliverySettings || []}
                className={state.open.deliverySetting ? style.autocompleteOpen : ''}
                loading={isLoading}
                onChange={handleChange}
                noOptionsText={t('select.noOptions')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                popupIcon={isLoading ? <Loading className={style.loading} /> : <MdKeyboardArrowDown color="#6A9BFF" />}
                renderOption={(props: any, e: ForecastDeliverySettingsV2) => (
                    <Box component="li" {...props} key={Math.random()} onClick={handleIdChange(e)}>
                        {e.name}
                    </Box>
                )}
                ListboxProps={{ style: { maxHeight: '180px' } }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: t('reportForm.deliverySetting')
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <Loading /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
                open={state.open.deliverySetting}
                onOpen={() => {
                    dispatch({ type: 'SET_OPEN', payload: { ...state.open, deliverySetting: true } });
                }}
                onClose={() => {
                    dispatch({ type: 'SET_OPEN', payload: { ...state.open, deliverySetting: false } });
                }}
                clearOnEscape={true}
                clearOnBlur={true}
                filterOptions={(x) => x}
            />
        </Box>
    );
};
