import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState, memo, ChangeEvent, useReducer } from 'react';
import { Box, Input, Divider, InputLabel, Typography } from '@mui/material';

import { MODAL_IDS } from '..';
import { DATE_SETTINGS } from '../../utils/config';
import { useAppDispatch, useAppSelector } from '../../stores';
import { DateRangeInput } from '../../components/DateRangeInput/DateRangeInput';
import { EVSReportCSAutocomplete } from './FormAutocompletes/EVSReportCSAutocomplete';
import { EVSReportCPAutocomplete } from './FormAutocompletes/EVSReportCPAutocomplete';
import { AutocompletesAction, AutocompletesState } from './FormAutocompletes/interfaces';
import { EVSReportCPFSAutocomplete } from './FormAutocompletes/EVSReportCPFSAutocomplete';
import { FormErrorStep, FormModalActions, FormSuccessStep } from '../../components/FormUtils';

import style from './style.module.scss';
import { postEVSReportAsync } from '../../stores/EVS/createEVSReports';

const disabledDays = [
    {
        from: dayjs().subtract(31, 'day').toDate(),
        to: dayjs().subtract(1, 'day').toDate()
    }
];

const defaultDatepickerConfig = {
    disabled: disabledDays,
    componentDisabled: true,
    // temporary hack
    defaultMonth: new Date(2023, 10, 1)
};

function reducer(state: AutocompletesState, action: AutocompletesAction): AutocompletesState {
    switch (action.type) {
        case 'SET_CHARGING_STATION':
            return { ...state, chargingStation: action.payload };
        case 'SET_CHARGING_POINT':
            return { ...state, chargingPoint: action.payload };
        case 'SET_CHARGING_POINT_FS':
            return { ...state, chargingPointFS: action.payload };
        case 'SET_OPEN':
            return { ...state, open: action.payload };
        default:
            return state;
    }
}

const initialState: AutocompletesState = {
    chargingStation: null,
    chargingPoint: null,
    chargingPointFS: null,
    open: {
        chargingStation: false,
        chargingPoint: false,
        chargingPointFS: false
    }
};

const CreateEVSReport = () => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useAppDispatch();

    const [state, dispatchLocalState] = useReducer(reducer, initialState);

    const [reportName, setReportName] = useState('');
    const [dateRange, setDateRange] = useState<DateRange | null>(null);
    const [datepickerConfig, setDatepickerConfig] = useState(defaultDatepickerConfig);

    const { error, loading, success } = useAppSelector((state) => state.createReports);

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setReportName(event.target.value);
    };

    const handleSubmit = () => {
        if (isValid && params.projectId) {
            const payload = {
                name: reportName,
                evs_cp_forecast_settings_id: state.chargingPointFS!.id,
                from_date: dayjs(dateRange.from).startOf('date').utc().format(DATE_SETTINGS.longFormatUsDash),
                to_date: dayjs(dateRange.to)
                    .endOf('day')
                    .add(1, 'millisecond')
                    .utc()
                    .format(DATE_SETTINGS.longFormatUsDash),
                projectId: params.projectId,
                evs_forecast_delivery_interval_id: '2dc443cd-e3a7-4502-8c93-fe1542ad8d93'
            };
            dispatch(postEVSReportAsync(payload));
        }
    };

    useEffect(() => {
        return () => {
            // dispatch(resetEVSCreateReportsState());
        };
    }, []);

    useEffect(() => {
        if (state.chargingPointFS) {
            const firstNovember = dayjs('2023-10-31'); // TODO
            setDatepickerConfig({
                ...datepickerConfig,
                componentDisabled: false,
                disabled: [
                    {
                        from: dayjs().subtract(100, 'year').toDate(),
                        to: firstNovember.toDate()
                    },
                    {
                        from: firstNovember.add(1, 'day').add(1, 'month').toDate(),
                        to: dayjs().add(100, 'year').toDate()
                    }
                ]
            });
        }
    }, [state.chargingPointFS]);

    const isValid = !!reportName && !!dateRange && !!state.chargingPointFS;

    if (!params.projectId) {
        return null;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className={style.box}>
                {!(success || error) ? (
                    <>
                        <Typography variant="h6">{t('createTitle')}</Typography>

                        <Box className={style.formRow}>
                            <InputLabel variant="standard" required shrink htmlFor={t('evsReportForm.name')}>
                                {t('evsReportForm.name')}
                            </InputLabel>
                            <Input
                                fullWidth
                                id={t('evsReportForm.name')}
                                placeholder={t('evsReportForm.name')}
                                onChange={handleNameChange}
                                value={reportName}
                            />
                        </Box>

                        <Box className={style.formRow}>
                            <InputLabel variant="standard" required shrink htmlFor={t('startTime')}>
                                {t('startTime')}
                            </InputLabel>
                            <DateRangeInput
                                className={style.dateRange}
                                label={t('table.general.selectDateRange')}
                                datePickerConfig={datepickerConfig}
                                range={dateRange}
                                setRange={setDateRange}
                            />
                        </Box>

                        <EVSReportCSAutocomplete
                            projectId={params.projectId}
                            state={state}
                            dispatch={dispatchLocalState}
                        />

                        {state.chargingStation ? (
                            <EVSReportCPAutocomplete
                                projectId={params.projectId}
                                chargingStationId={state.chargingStation.id}
                                state={state}
                                dispatch={dispatchLocalState}
                            />
                        ) : null}

                        {state.chargingPoint ? (
                            <EVSReportCPFSAutocomplete
                                projectId={params.projectId}
                                chargingPointId={state.chargingPoint.id}
                                state={state}
                                dispatch={dispatchLocalState}
                            />
                        ) : null}

                        <Divider />

                        <FormModalActions
                            loading={loading}
                            note={' '}
                            disabled={!isValid}
                            modalId={MODAL_IDS.CREATE_EVS_REPORT_MODAL}
                            cancelLabel={t('nevermind')}
                            saveLabel={t('ctaLabel')}
                            onSubmit={handleSubmit}
                        />
                    </>
                ) : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
            </Box>
        </LocalizationProvider>
    );
};

export const MemoizedEVSCreateReport = memo(CreateEVSReport);

export default CreateEVSReport;
