import { FC } from 'react';
import { DialogContent } from '@mui/material';

import { UserForm } from './UserForm';
import { UserFE } from '../../interfaces/uiv2';
import { FormModal } from '../../components/BaseModal/FormModal';
import { MODAL_IDS } from '..';

const UserModal: FC<{ user: UserFE | null }> = ({ user }) => {
    return (
        <FormModal id={MODAL_IDS.USER_MODAL} fullWidth={false} maxWidth="lg">
            <DialogContent>
                <UserForm user={user} />
            </DialogContent>
        </FormModal>
    );
};

export { UserModal };
