import vpp from './VPP';
import auth from './Auth';
import users from './Users';
import assets from './Assets';
import demand from './Demand';
import clients from './Clients';
import reports from './Reports';
import projects from './Projects';
import prosumers from './Prosumers';
import forecasts from './Forecasts';
import tl from './TL';
import evs from './EVS';
import dashboard from './Dashboard';
import jobTitles from './JobTitles';
import permissions from './Permissions';
import support from './UserSupportForm';
import notifications from './Notifications';
import ingestLocations from './IngestLocationsData';
import deliveredForecasts from './DeliveredForecast';
import forecastDeliverySettings from './ForecastDeliverySettings';
import forecastDeliveryIntervals from './ForecastDeliveryIntervals';
import generationAssetForecastSettings from './GenerationAssetForecastSettings';
import markets from './Markets';
import generationAssetForecastSettingsV2 from './GenerationAssetForecastSettingsV2';

import { api } from './api';
import listGasReports from './GasReports';

const rootReducer = {
    login: auth.login,
    resetPassword: auth.resetPassword,
    changePassword: auth.changePassword,
    listNotifications: notifications.listNotifications,
    /* admin accounts page */
    jobTitles: jobTitles.jobTitles,
    listUsers: users.listUsers,
    createUser: users.createUser,
    listClients: clients.listClients,
    createClient: clients.createClient,
    /* assets */
    listAssets: assets.listAssets,
    listAssetAvailability: assets.listAssetAvailability,
    listAssetLocations: assets.listAssetLocations,
    createAssetLocations: assets.createAssetLocations,
    editAssetLocations: assets.editAssetLocations,
    deleteAssetLocations: assets.deleteAssetLocations,
    ingestLocations: ingestLocations.ingestLocations,
    /* assets user */
    listAssetForecast: assets.listAssetForecast,
    /* assets admin */
    createAsset: assets.createAsset,
    updateAsset: assets.updateAsset,
    trainAsset: assets.trainAsset,
    runForecast: assets.runForecast,
    deliverForecast: assets.deliverForecast,
    listPermissions: permissions.listPermissions,
    updatePermissions: permissions.updatePermissions,
    deletePermissions: permissions.deletePermissions,
    ingestMeteoDataAsset: assets.ingestMeteoDataAsset,
    /* end assets */
    /* reports */
    listReports: reports.listReports,
    createReports: reports.createReports,
    deleteReports: reports.deleteReports,
    /* delivered forecasts */
    listForecasts: forecasts.listForecasts,
    deleteForecasts: forecasts.deleteForecasts,
    /* account */
    userSupportForm: support.userSupportForm,
    /* vpps */
    listVPP: vpp.listVPP,
    createVPP: vpp.createVPP,
    deleteVPP: vpp.deleteVPP,
    getVPPAssets: vpp.getVPPAssets,
    /* prosumers */
    listProsumers: prosumers.listProsumers,
    listProsumerClusters: prosumers.listProsumerClusters,
    prosumersPage: prosumers.prosumersPage,
    prosumerClusterPage: prosumers.prosumerClusterPage,
    /* demand */
    deliveredForecastPage: deliveredForecasts.deliveredForecastPage,
    deleteFormulas: demand.deleteFormulas,
    deleteSegments: demand.deleteSegments,
    deleteConsumers: demand.deleteConsumers,
    deleteGasConsumers: demand.deleteGasConsumers,
    deleteGasClusters: demand.deleteGasClusters,
    deleteClusters: demand.deleteClusters,
    deleteProfiles: demand.deleteProfiles,
    deleteGasProfiles: demand.deleteGasProfiles,
    listGasDashboard: demand.listGasDashboard,
    listConsumers: demand.listConsumers,
    listGasConsumers: demand.listGasConsumers,
    listGasClusters: demand.listGasClusters,
    listProfiles: demand.listProfiles,
    listGasProfiles: demand.listGasProfiles,
    listClusters: demand.listClusters,
    listSegments: demand.listSegments,
    consumerPage: demand.consumerPage,
    gasConsumerPage: demand.gasConsumerPage,
    profilePage: demand.profilePage,
    createGasProfiles: demand.createGasProfiles,
    gasProfilePage: demand.gasProfilePage,
    gasClusterPage: demand.gasClusterPage,
    clusterPage: demand.clusterPage,
    segmentPage: demand.segmentPage,
    createGasReport: listGasReports.createGasReport,
    listGasReports: listGasReports.listGasReports,
    gasReportPage: listGasReports.gasReportPage,
    /* projects */
    listProjects: projects.listProjects,
    createProjects: projects.createProjects,
    deleteProjects: projects.deleteProjects,
    updateProjects: projects.updateProjects,
    listImportedFiles: projects.listImportedFiles,
    /* tl */
    listTLClusters: tl.listTLClusters,
    listTLConsumers: tl.listTLConsumers,
    listTLCirculations: tl.listTLCirculations,
    tlClusterPage: tl.clusterTLPage,
    tlConsumerPage: tl.consumerTLPage,
    tlCirculationPage: tl.circulationTLPage,
    /* evs */
    listEVSChargingStations: evs.listEVSChargingStations,
    evsChargingStationPage: evs.chargingStationPage,
    evsChargingPointPage: evs.chargingPointPage,
    listEVSClusters: evs.listEVSClusters,
    evsClusterPage: evs.evsClusterPage,
    listEVSForecasts: evs.listEVSForecasts,
    evsForecastPage: evs.evsForecastPage,
    listEVSReports: evs.listEVSReports,
    evsReportPage: evs.evsReportPage,
    /* pages */
    vppPage: vpp.vppPage,
    assetPage: assets.assetPage,
    dashboard: dashboard.dashboard,
    reportPage: reports.reportPage,
    forecastPage: forecasts.forecastPage,
    generationAssetForecastSettings: generationAssetForecastSettings.generationAssetForecastSettings,
    forecastDeliveryIntervals: forecastDeliveryIntervals.forecastDeliveryIntervals,
    forecastDeliverySettings: forecastDeliverySettings.forecastDeliverySettings,
    updateAssetTechnicalDetails: assets.updateAssetTechnicalDetails,

    listMarkets: markets.listMarkets,
    marketsPricingPage: markets.marketsPricingPage,

    generationAssetForecastSettingsV2: generationAssetForecastSettingsV2.generationAssetForecastSettingsV2,

    [api.reducerPath]: api.reducer
};

export default rootReducer;
