import { TFunction } from 'i18next';
import * as yup from 'yup';
import {
    AssetLocationFE,
    Coordinates,
    INGEST_MODEL,
    INGEST_TYPE,
    IngestLocationFormFields,
    PostIngestLocationsBody,
    GenerationAssetFE
} from '../../interfaces/uiv2';

interface FormValues {
    [key: string]: any;
}

export const getIngestLocationsSchema = (t: TFunction, handleMultipleIngest: boolean) => {
    const baseSchema = {
        model: yup.string().oneOf(Object.values(INGEST_MODEL)).required(t('validations.required')),
        type: yup.string().oneOf(Object.values(INGEST_TYPE)).required(t('validations.required')),
        startDate: yup.date().required(t('validations.required')),
        endDate: yup.date().required(t('validations.required'))
    };

    const singleSchema = {
        latitude: yup
            .number()
            .required(t('validations.required'))
            .test('customTest', 'invalidCoordinate', (value) => {
                const isLatitudeValid = new RegExp(/^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})$/);
                return isLatitudeValid.test(value as any);
            }),
        longitude: yup
            .number()
            .required(t('validations.required'))
            .test('customTest', 'invalidCoordinate', (value) => {
                const isLongitudeValid = new RegExp(/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})$/);
                return isLongitudeValid.test(value as any);
            })
    };

    return yup
        .object<Record<keyof FormValues, yup.AnySchema>>(
            handleMultipleIngest ? { ...baseSchema } : { ...baseSchema, ...singleSchema }
        )
        .required();
};

export const transformIngestLocationsFormDataToPostBody = (
    formData: IngestLocationFormFields,
    coordinates: Coordinates[],
    asset: GenerationAssetFE
): PostIngestLocationsBody => {
    return {
        coordinates,
        model: formData.model,
        location_type: asset.type,
        end_date: formData.endDate,
        start_date: formData.startDate,
        historical: formData.type === INGEST_TYPE.HISTORICAL,
        dataset_name: asset.id.replaceAll('-', '_'),
        table_name: `weather_meteomatics_locations_${formData.type}_${formData.model}`
    };
};

const defaultValues = {
    longitude: '',
    latitude: '',
    startDate: '',
    endDate: '',
    model: INGEST_MODEL.ECMWF,
    type: INGEST_TYPE.FORECAST
};

export const getIngestLocationsFormDataDefaultValue = (data: AssetLocationFE) => {
    return { ...defaultValues, longitude: data.longitude, latitude: data.latitude };
};
