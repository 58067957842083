import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from 'react-day-picker';
import { Box, Typography } from '@mui/material';
import { MdSchedule, MdTrendingUp, MdRefresh } from 'react-icons/md';

import {
    setGetParams,
    setSelectedRows,
    resetNotificationsList,
    getNotificationsListAsync,
    markReportsListAsync
} from '../../stores/Notifications';
import { useIsMount } from '../../hooks/isMount';
import { downloadReport } from '../../stores/Reports';
import useStickyHeader from '../../hooks/useStickyHeader';
import { getNotificationsTableHeadCells } from './helpers';
import { PageContainer } from '../../layout/PageContainer';
import { useAppDispatch, useAppSelector } from '../../stores';
import { DownloadExtension } from '../../components/DownloadButton';
import { GenericTable, TableSearch } from '../../components/BaseTable';
import { DateRangeInput } from '../../components/DateRangeInput/DateRangeInput';
import { getForecastFilteringFormattedDate, PAGINATION_SETTINGS } from '../../utils/config';
import { transformOrderByToSort } from '../../components/BaseTable/GenericTable/helpers';
import { ReactComponent as NotificationsIcon } from '../../assets/icons/navigation-items/dots-menu-notifications.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';

import style from '../../features/forecast-list/forecast-list.module.scss';
import notificationStyle from './style.module.scss';
import { downloadGasReportAsync } from '../../stores/GasReports';

const Notifications = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isFirstRender = useIsMount();
    const [dateRange, setDateRange] = useState<DateRange | null>(null);

    useStickyHeader('notifications-list-header');

    const headCells = getNotificationsTableHeadCells(t);
    const getParams = useAppSelector((state) => state.listNotifications.getParams, isEqual);
    const {
        selectedRows,
        notificationsFE: rows,
        loading,
        success,
        pager,
        markNotifications
    } = useAppSelector((state) => state.listNotifications);

    useEffect(() => {
        if (dateRange?.from && dateRange?.to) {
            dispatch(
                setGetParams({
                    /* maybe we add utc here?  */
                    created_at__gte: getForecastFilteringFormattedDate(dayjs(dateRange.from).startOf('day').toDate()),
                    created_at__lte: getForecastFilteringFormattedDate(dayjs(dateRange.to).endOf('day').toDate()),
                    page: PAGINATION_SETTINGS.default_start_page
                })
            );
        }

        if (!dateRange && !isFirstRender) {
            dispatch(
                setGetParams({
                    created_at__gte: undefined,
                    created_at__lte: undefined,
                    page: PAGINATION_SETTINGS.default_start_page
                })
            );
        }
    }, [dateRange]);

    useEffect(() => {
        dispatch(getNotificationsListAsync({ ...getParams }));
    }, [getParams, markNotifications]);

    const handleSort = (type: string) => () => {
        dispatch(setGetParams({ order_by: [type], page: PAGINATION_SETTINGS.default_start_page }));
    };

    const clearSelection = () => {
        dispatch(setSelectedRows([]));
    };

    const markAllAsSelected = () => {
        dispatch(markReportsListAsync(selectedRows.map((e) => e.id)));
    };

    const handleDownloadReport = (id: string) => () => {
        dispatch(downloadReport({ ids: [id], type: DownloadExtension.Csv }));
    };

    const handleDownloadGasReport = (row: any) => () => {
        dispatch(
            downloadGasReportAsync({
                projectId: row.demand_report.project.id,
                id: row.demand_report.id,
                from_date: row.demand_report.from_timestamp,
                to_date: row.demand_report.to_timestamp
            })
        );
    };

    return (
        <PageContainer>
            <Box id="notifications-list-header" className={style.header}>
                <Typography variant="h6" className={classnames(style.title, notificationStyle.titleIcon)}>
                    <NotificationsIcon />
                    {t('header.notifications')}
                </Typography>
                {selectedRows.length ? (
                    <Box className={style.toolbar}>
                        {/* selected items toolbar */}

                        <Typography variant="small4" className={style.label}>
                            {selectedRows.length} {t('table.general.selected')}
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <MdRefresh />
                            <Box component="span" className={style.primary} onClick={clearSelection} sx={{ ml: 1 }}>
                                {t('table.general.clearSelection')}
                            </Box>
                        </Typography>
                        <Typography variant="small4" className={style.element}>
                            <CheckIcon />
                            <Box component="span" className={style.primary} onClick={markAllAsSelected} sx={{ ml: 1 }}>
                                {t('table.general.seenAll')}
                            </Box>
                        </Typography>
                        <TableSearch setGetParams={setGetParams} getParams={getParams} />
                    </Box>
                ) : (
                    <>
                        {/* Non selected toolbar */}
                        <Box className={style.toolbar}>
                            <Typography variant="small4" className={style.label}>
                                {t('toolbar.sort')}
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdSchedule />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={handleSort('-created_at')}
                                    sx={{ ml: 1 }}
                                >
                                    {t('toolbar.mostRecent')}
                                </Box>
                            </Typography>
                            <Typography variant="small4" className={style.element}>
                                <MdTrendingUp />
                                <Box
                                    component="span"
                                    className={style.primary}
                                    onClick={handleSort('created_at')}
                                    sx={{ ml: 1 }}
                                >
                                    {t('toolbar.oldestToNewest')}
                                </Box>
                            </Typography>
                            <DateRangeInput
                                range={dateRange}
                                setRange={setDateRange}
                                label={t('table.general.selectPeriod')}
                                className={style.selectPeriod}
                            />
                            <TableSearch setGetParams={setGetParams} getParams={getParams} />
                        </Box>
                    </>
                )}
            </Box>
            <GenericTable
                rows={rows}
                pager={pager}
                loading={loading}
                success={success}
                isCheckboxEnabled
                columns={headCells}
                cleanupCb={() => dispatch(resetNotificationsList())}
                rowUniqueId={'index'}
                selectedRows={selectedRows}
                isAccordionRowEnabled
                setGetParams={setGetParams}
                searchParam={getParams.search}
                setSelectedRows={setSelectedRows}
                storeSort={transformOrderByToSort(getParams.order_by)}
                handlers={{
                    downloadReport: handleDownloadReport,
                    downloadGasReport: handleDownloadGasReport
                }}
            />
        </PageContainer>
    );
};

export default Notifications;
