import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import {
    Box,
    Divider,
    FormHelperText,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';

import {
    getIngestLocationsSchema,
    getIngestLocationsFormDataDefaultValue,
    transformIngestLocationsFormDataToPostBody
} from './helpers';
import { MODAL_IDS } from '../index';
import { useFormLogic } from '../../hooks/useFormLogic';
import { FormModalActions } from '../../components/FormUtils';
import { useAppDispatch, useAppSelector } from '../../stores';
import { ingestLocationsAsync } from '../../stores/IngestLocationsData';
import {
    AssetLocationFE,
    GenerationAssetFE,
    INGEST_MODEL,
    INGEST_TYPE,
    IngestLocationFormFields
} from '../../interfaces/uiv2';

import style from '../AddAssetLocationsModal/style.module.scss';

const IngestForm = ({ data, asset }: { data: AssetLocationFE[]; asset: GenerationAssetFE }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const handleMultipleIngest = data.length > 1;

    const { loading } = useAppSelector((state) => state.ingestLocations);

    const schema = getIngestLocationsSchema(t, handleMultipleIngest);

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty }
    } = useFormLogic<any, IngestLocationFormFields, any, IngestLocationFormFields>({
        schema,
        data: null,
        defaultValues: getIngestLocationsFormDataDefaultValue(data[0])
    });

    const onSubmit = (formData: IngestLocationFormFields) => {
        const coordinates = data.map((e) => ({
            longitude: e.longitude,
            latitude: e.latitude
        }));
        const body = transformIngestLocationsFormDataToPostBody(formData, coordinates, asset);
        dispatch(ingestLocationsAsync(body));
    };

    const onError = (err: any) => {
        console.error('Error submitting form', err);
    };

    return (
        <Box className={style.container} component="form" noValidate onSubmit={handleSubmit(onSubmit, onError)}>
            <Typography variant="h6" className={style.title}>
                {t('header.ingestData')}
            </Typography>

            {!handleMultipleIngest && (
                <Stack direction="row" justifyContent="space-between" spacing={4} width="100%">
                    <Box className={style.formInput}>
                        <Controller
                            name="latitude"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink htmlFor={t('form.label.latitude')}>
                                        {t('form.label.latitude')}
                                    </InputLabel>
                                    <Input
                                        {...field}
                                        fullWidth
                                        id={t('form.label.latitude')}
                                        placeholder={t('form.label.latitude')}
                                    />
                                    {errors.latitude && (
                                        <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                    )}
                                    {errors.latitude?.type === 'customTest' && (
                                        <FormHelperText error>{t('form.errors.coordinate')}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                    <Box className={style.formInput}>
                        <Controller
                            name="longitude"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputLabel variant="standard" required shrink htmlFor={t('form.label.longitude')}>
                                        {t('form.label.longitude')}
                                    </InputLabel>
                                    <Input
                                        {...field}
                                        fullWidth
                                        id={t('form.label.longitude')}
                                        placeholder={t('form.label.longitude')}
                                    />
                                    {errors.longitude && (
                                        <FormHelperText error>{t('form.errors.generic')}</FormHelperText>
                                    )}
                                    {errors.longitude?.type === 'customTest' && (
                                        <FormHelperText error>{t('form.errors.coordinate')}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                </Stack>
            )}

            <Stack direction="row" justifyContent="space-between" spacing={4} width="100%">
                <Box className={classnames(style.formInput, style.calendar)}>
                    <Controller
                        name="startDate"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.startDate')}>
                                    {t('form.label.startDate')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="startDate"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.startDate && (
                                    <FormHelperText error>{t('form.errors.invalidDate')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>
                <Box className={classnames(style.formInput, style.calendar)}>
                    <Controller
                        name="endDate"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.endDate')}>
                                    {t('form.label.endDate')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="endDate"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.endDate && (
                                    <FormHelperText error>{t('form.errors.invalidDate')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>
            </Stack>

            <Stack direction="row" justifyContent="space-between" spacing={4} width="100%">
                <Box className={classnames(style.formInput, style.select)}>
                    <Controller
                        name="model"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.model')}>
                                    {t('form.label.model')}
                                </InputLabel>
                                <Select {...field} fullWidth label={t('form.label.model')}>
                                    <MenuItem value={INGEST_MODEL.ECMWF}>
                                        <Typography variant="small1">{INGEST_MODEL.ECMWF}</Typography>
                                    </MenuItem>
                                    <MenuItem value={INGEST_MODEL.MIX}>
                                        <Typography variant="small1">{INGEST_MODEL.MIX}</Typography>
                                    </MenuItem>
                                </Select>
                                {errors.model && <FormHelperText error>{t('form.errors.generic')}</FormHelperText>}
                            </>
                        )}
                    />
                </Box>
                <Box className={classnames(style.formInput, style.select)}>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={t('form.label.type')}>
                                    {t('form.label.type')}
                                </InputLabel>
                                <Select {...field} fullWidth label={t('form.label.type')}>
                                    <MenuItem value={INGEST_TYPE.FORECAST}>
                                        <Typography variant="small1">{INGEST_TYPE.FORECAST}</Typography>
                                    </MenuItem>
                                    <MenuItem value={INGEST_TYPE.HISTORICAL}>
                                        <Typography variant="small1">{INGEST_TYPE.HISTORICAL}</Typography>
                                    </MenuItem>
                                </Select>
                                {errors.type && <FormHelperText error>{t('form.errors.generic')}</FormHelperText>}
                            </>
                        )}
                    />
                </Box>
            </Stack>
            <Divider sx={{ mt: 4 }} />

            <FormModalActions
                loading={loading}
                disabled={!isDirty}
                modalId={
                    handleMultipleIngest
                        ? MODAL_IDS.INGEST_MULTIPLE_LOCATION_COORDINATES
                        : MODAL_IDS.INGEST_LOCATION_COORDINATES
                }
                note={t('mandatoryFields')}
                cancelLabel={t('nevermind')}
                saveLabel={t('form.label.submit')}
            />
        </Box>
    );
};

export default IngestForm;
