import { FC } from 'react';
import { DialogContent } from '@mui/material';

import { ClientForm } from './ClientForm';
import { ClientFE } from '../../interfaces/uiv2';
import { MODAL_IDS } from '..';
import { FormModal } from '../../components/BaseModal/FormModal';

const ClientModal: FC<{ client: ClientFE | null }> = ({ client }) => {
    return (
        <FormModal id={MODAL_IDS.CLIENT_MODAL} fullWidth={false} maxWidth="lg">
            <DialogContent>
                <ClientForm client={client} />
            </DialogContent>
        </FormModal>
    );
};

export { ClientModal };
