import { DialogContent } from '@mui/material';
import { MODAL_IDS } from '..';
import VPPForm from '../../components/VPPForm/VPPForm';
import { FormModal } from '../../components/BaseModal/FormModal';

const CreateVPPModal = () => {
    return (
        <>
            <FormModal id={MODAL_IDS.CREATE_VPP} fullWidth={false} maxWidth="xl">
                <DialogContent>
                    <VPPForm />
                </DialogContent>
            </FormModal>
        </>
    );
};

export default CreateVPPModal;
