import classnames from 'classnames';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Avatar, Box, DialogContent, Divider, InputLabel, TextField, Typography } from '@mui/material';

import {
    deletePermissionsAsync,
    getPermissionsAsync,
    PERMISSION_TYPE,
    resetPermissionState,
    updatePermissionsAsync
} from '../../stores/Permissions';
import { MODAL_IDS } from '..';
import UserRow from './UserRow';
import { buildUsername } from './helpers';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { User } from '../../interfaces/apiv2';
import { debounce } from '../../utils/helpers';
import { stringAvatar } from '../../utils/table';
import { BaseModal } from '../../components/BaseModal';
import ToastifyType from '../../utils/toastify-config';
import Cleanup from '../../components/Cleanup/Cleanup';
import { useGetUsersQuery } from '../../stores/Users';
import { PermissionItem } from '../../interfaces/uiv2';
import { useAppDispatch, useAppSelector } from '../../stores';
import { CloseModalButton } from '../../components/BaseModal/CloseModalButton';
import { FormErrorStep, FormModalActions, FormSuccessStep } from '../../components/FormUtils';

import style from './permissions.module.scss';

export const PermissionsModal = ({
    selectedItem,
    resetActiveRow,
    permissionType
}: {
    selectedItem: PermissionItem;
    resetActiveRow: () => void;
    permissionType: PERMISSION_TYPE;
}) => {
    const { t } = useTranslation();
    const { t: tList } = useTranslation('assets/generation');
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');

    const { data: guestUsers = [] } = useGetUsersQuery({ guest: true, search });
    const { data: clientUsers = [] } = useGetUsersQuery({ client_id: selectedItem.client_id, guest: false, search });

    const { loading, success, error } = useAppSelector((state) => state.updatePermissions);
    const { accessListPermissions } = useAppSelector((state) => state.listPermissions);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [removedUsers, setRemovedUsers] = useState<User[]>([]);

    useEffect(() => {
        setSelectedUsers(accessListPermissions);
    }, [accessListPermissions]);

    const autoCompleteData: User[] = useMemo(() => {
        return [...clientUsers, ...guestUsers].filter((e) => !selectedUsers.some((a) => a.id === e.id));
    }, [clientUsers, guestUsers, selectedUsers]);

    const debouncedFn = (value: string) => {
        setSearch(value);
    };

    const debouncedHandler = useCallback(debounce(debouncedFn, 450), []);

    const initPermissions = useCallback(
        (assetId) => dispatch(getPermissionsAsync({ id: assetId, type: permissionType })),
        [selectedItem]
    );

    useEffect(() => {
        initPermissions(selectedItem.id);
    }, []);

    const onChange = (searchTerm: string) => {
        debouncedHandler(searchTerm);
    };

    const handleCleanup = () => {
        resetActiveRow();
        setSearch('');
        setRemovedUsers([]);
        dispatch(resetPermissionState());
    };

    const handleDelete = (user: User) => {
        return () => {
            const newUsers = selectedUsers.filter((e) => e.id !== user.id);
            setSelectedUsers(newUsers);
            setRemovedUsers([...removedUsers, user]);
        };
    };

    const handleSubmit = () => {
        dispatch(
            updatePermissionsAsync({
                assetId: selectedItem!.id,
                type: permissionType,
                users: selectedUsers
                    .filter((user) => {
                        return !accessListPermissions.some((e) => e.id === user.id);
                    })
                    .map((user) => user.id)
            })
        );
        dispatch(
            deletePermissionsAsync({
                assetId: selectedItem!.id,
                users: removedUsers.map((user) => user.id),
                type: permissionType
            })
        );
    };

    const addNewUser = (user: User) => {
        return () => {
            if (selectedUsers.some((e) => e.id === user.id)) {
                toast.error(tList('emailAlreadyAddedError'), ToastifyType.error);
            } else {
                const newUser = { ...user };
                setSelectedUsers([newUser, ...selectedUsers]);
                const userToBeDeleted = removedUsers.some((e) => e.id === user.id);
                if (userToBeDeleted) {
                    const newRemovedUsers = removedUsers.filter((e) => e.id !== user.id);
                    setRemovedUsers(newRemovedUsers);
                }
            }
        };
    };

    const renderAutocompleteOptions = (props: any, user: User, { inputValue }: any) => {
        const full_name = buildUsername(user);
        const matchesName = match(full_name, inputValue, { insideWords: true });
        const partsName = parse(full_name, matchesName);
        const matchesEmail = match(user.email, inputValue, { insideWords: true });
        const partsEmail = parse(user.email, matchesEmail);
        return (
            <Box {...props} component="li" className={style.dropdownListRow} onClick={addNewUser(user)} key={user.id}>
                <Box sx={{ display: 'flex' }}>
                    <Avatar {...stringAvatar(full_name)} variant="userAccount" sx={{ bgcolor: '#7B61FF' }} />
                    <Box component="span" className={style.rowElement}>
                        {partsName.map((part: any, index: number) => (
                            <span key={index} className={classnames(part.highlight ? style.highlight : '')}>
                                {part.text}
                            </span>
                        ))}
                    </Box>
                </Box>
                <Box component="span" className={style.rowElement}>
                    {partsEmail.map((part: any, index: number) => (
                        <span key={index} className={classnames(part.highlight ? style.highlight : '')}>
                            {part.text}
                        </span>
                    ))}
                </Box>
            </Box>
        );
    };

    return (
        <BaseModal id={MODAL_IDS.ASSET_PERMISSIONS_MODAL} fullWidth={false} maxWidth="lg">
            <CloseModalButton id={MODAL_IDS.ASSET_PERMISSIONS_MODAL} callback={resetActiveRow} />
            <DialogContent>
                {!(success || error) ? (
                    <Box className={style.formContainer} sx={{ width: 500 }}>
                        <Typography variant="h6" className={style.title}>
                            {t('table.general.editPermissions')}
                        </Typography>
                        {/* <Typography variant="h5" sx={{ mb: 3 }}>
                            {selectedItem?.name}
                        </Typography>*/}
                        <Box>
                            <InputLabel
                                sx={{ mb: 1 }}
                                variant="standard"
                                shrink
                                htmlFor={t('permissionsModal.inputLabel')}
                            >
                                {t('permissionsModal.inputLabel')}
                            </InputLabel>
                            <Autocomplete
                                id="SearchUserInput"
                                noOptionsText={t('select.noOptions')}
                                options={autoCompleteData}
                                /* override basic styling */
                                sx={{ mt: '0 !important' }}
                                onInputChange={(event, newInputValue) => {
                                    onChange(newInputValue);
                                }}
                                clearOnBlur={true}
                                clearOnEscape
                                popupIcon={<MdKeyboardArrowDown color="#6A9BFF" />}
                                getOptionLabel={(option) => buildUsername(option)}
                                filterOptions={(options) => options}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderOption={renderAutocompleteOptions}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: t('permissionsModal.inputPlaceholder')
                                        }}
                                    />
                                )}
                            />
                        </Box>
                        <Box className={style.selectedUsersContainer}>
                            {selectedUsers.map((user, i) => (
                                <UserRow user={user} key={user.id} deleteRow={handleDelete} index={i + 1} />
                            ))}
                        </Box>
                        <Divider />
                        <FormModalActions
                            classname="PermissionFooterClassName"
                            loading={loading}
                            onSubmit={handleSubmit}
                            /*onClose={handleCleanup}*/
                            modalId={MODAL_IDS.ASSET_PERMISSIONS_MODAL}
                            note={`${selectedUsers.length} ${t('permissionsModal.accountsAdded')}`}
                            cancelLabel={t('nevermind')}
                            saveLabel={t('save')}
                        />
                    </Box>
                ) : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
                <Cleanup cb={handleCleanup}></Cleanup>
            </DialogContent>
        </BaseModal>
    );
};
