import { FC, useEffect, useState } from 'react';
import style from './style.module.scss';
import MarkerIcon from './MarkerIcon';
import classNames from 'classnames';

import { ReactComponent as TransmissionIcon } from '../../assets/icons/navigation-items/menu-item-transmission.svg';
import { ReactComponent as GenerationSVG } from '../../assets/icons/navigation-items/menu-item-generation-primary.svg';
import { ReactComponent as HouseholdIcon } from '../../assets/icons/demand/household.svg';
import { ReactComponent as ChargingStationsSVG } from '../../assets/icons/navigation-items/menu-item-charging-stations.svg';
import { ReactComponent as ProsumersIcon } from '../../assets/icons/navigation-items/menu-item-prosumers.svg';
import { useAppSelector } from '../../stores';
import { hasAccessTo } from '../../utils/user';

const optionsNames: any = {
    TL: { name: 'Grid', icon: TransmissionIcon },
    ASSETS: { name: 'Generation', icon: GenerationSVG },
    CONSUMERS: { name: 'Consumers', icon: HouseholdIcon },
    PROSUMERS: { name: 'Prosumers', icon: ProsumersIcon },
    CHARGING_STATIONS: { name: 'Charging stations', icon: ChargingStationsSVG }
};
interface LayersProps {
    setActive: any;
}

export type LayerOption = {
    name: string;
    requiredPermissions: string[];
    disabled: boolean;
};

const Layers: FC<LayersProps> = ({ setActive }) => {
    // const options = ['ASSETS', 'CONSUMERS', 'PROSUMERS', 'CHARGING_STATIONS'];
    const { user } = useAppSelector((state) => state.login);
    const optionsWithPermissions: LayerOption[] = [
        {
            name: 'ASSETS',
            requiredPermissions: ['Electricity.Generation.Assets.READ'],
            disabled: true
        },
        {
            name: 'CONSUMERS',
            requiredPermissions: ['Electricity.Demand.DSO.READ'],
            disabled: true
        },
        {
            name: 'CHARGING_STATIONS',
            requiredPermissions: ['EVS.ChargingStations.READ'],
            disabled: true
        }
    ];
    const options = optionsWithPermissions.map((option) => {
        option.disabled = !hasAccessTo(user!, option.requiredPermissions);
        return option;
    });
    const [activeOptions, setActiveOptions] = useState(options);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setActive(activeOptions.filter((x) => !x.disabled));
    }, [activeOptions]);

    const toggleActive = () => {
        setIsActive((prevState) => !prevState);
    };

    const toggleOption = (optionToAdd: LayerOption) => {
        if (optionToAdd.disabled) {
            return;
        }
        setActiveOptions((prevArray) =>
            prevArray.map((i) => i.name).includes(optionToAdd.name)
                ? prevArray.filter((item) => item.name !== optionToAdd.name)
                : [...prevArray, optionToAdd]
        );
    };

    const isOptionActive = (item: LayerOption) => activeOptions.some((option) => option.name === item.name);

    return (
        <div className={style.layers}>
            <div onClick={toggleActive}>
                <MarkerIcon entityType="Layers" isActive={isActive} />
            </div>
            {isActive && (
                <div className={classNames(style.tooltip, style.layersTooltip)}>
                    <div className={style.header}>
                        <span className={style.charge}>Show layers</span>
                    </div>

                    <div className={style.content}>
                        {options.map((option) => {
                            const Icon = optionsNames[option.name].icon;
                            return (
                                <div
                                    key={option.name}
                                    className={classNames(style.itemContainer, option.disabled ? style.disabled : '')}
                                    onClick={() => toggleOption(option)}
                                >
                                    <div className={style.item}>
                                        <div className={style.icon}>
                                            <Icon />
                                        </div>
                                        <div className={style.option}>{optionsNames[option.name].name}</div>
                                    </div>
                                    <div className={classNames(style.toggle, isOptionActive(option) && style.active)} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Layers;
