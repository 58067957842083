import { AxiosError } from 'axios';

export const commonReduxErrorHandler = (e: unknown) => {
    const error = e as AxiosError;

    if (error.isAxiosError) {
        if (error.response) {
            // just destructuring the object so it won't be copied to the new object.
            // eslint-disable-next-line
            const { config, request, ...rest } = error.response;
            return rest;
        }

        const json = error.toJSON() as { name: string; message: string; status: number };
        return {
            name: json?.name,
            message: json?.message,
            status: json?.status
        };
    }

    return e;
};
