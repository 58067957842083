import style from './DashboardV2.module.scss';

// Components
import { Box } from '@mui/material';
import Group from './components/Group';
import Stats from './components/Stats';

// Config
import { DashboardConfig } from './config';

const DashboardV2 = () => {
    return (
        <Box className={style.container}>
            <Box className={style.left}>
                {DashboardConfig.map((group) => (
                    <Group key={group.groupKey} group={group} />
                ))}
            </Box>
            <Box className={style.right}>
                <Stats />
            </Box>
        </Box>
    );
};

export default DashboardV2;
