import store from 'store';
import * as yup from 'yup';
import get from 'lodash/get';

import { ROUTES, storage } from './config';
import { Dispatch } from '@reduxjs/toolkit';
import axios, { resetInterceptors } from './axios';
import { UserPermissionsFE } from '../interfaces/uiv2';
import { User, UserPermissions } from '../interfaces/apiv2';
import { redirect } from 'react-router-dom';

export const getLocalStorageAppView = (): 'ADMIN' | 'MANAGER' | null => {
    const appViewLocalStorage = store.get(storage.appView);

    const validValue = ['ADMIN', 'MANAGER'].includes(appViewLocalStorage);

    return validValue ? appViewLocalStorage : null;
};

// FIXME: Move the functions below to the permissions.tsx file.

export const getActivePathsFromFEPermissions = (o: any, path = ''): string | string[] => {
    if (o === true) return path;

    return Object.keys(o)
        .map((key: string) => getActivePathsFromFEPermissions(o[key], path ? [path, key].join('.') : key))
        .toString()
        .split(',')
        .filter((x) => x);
};

export const getActivePathsFromBEPermissions = (o: any, path = ''): string | string[] => {
    if (!o) {
        return path;
    }
    if (Array.isArray(o) && o?.length) return path;

    return Object.keys(o)
        .map((key: string) => getActivePathsFromBEPermissions(o[key], path ? [path, key].join('.') : key))
        .toString()
        .split(',')
        .filter((x) => x);
};

// permissions: UserPermissions
export const getBitsFromPermissions = (permissions: any, arr: any[]) => {
    const neutralElement = -1;

    if (Array.isArray(permissions)) {
        arr.push(permissions[0] !== undefined ? permissions[0] : neutralElement);
    }

    const keys = Object.keys(permissions);

    keys.forEach((i) => {
        getBitsFromPermissions(permissions[i], arr);
    });

    return arr.filter((x) => x !== neutralElement).sort((a, b) => a - b);
};

export const transformPermissionsToApiFormat = (
    permissions: UserPermissionsFE,
    apiPermissions: UserPermissions
): number => {
    const activePermissions = getActivePathsFromFEPermissions(permissions);

    const bits = [];
    for (const path of activePermissions) {
        const obj = get(apiPermissions, path);
        const value = getBitsFromPermissions(obj, []);
        bits.push(...value);
    }

    let bitmask = 0n;
    for (const bit of bits) {
        bitmask |= 1n << BigInt(bit);
    }

    return Number(bitmask);
};

export const getPermissionsSchema = (permissions: UserPermissions) => {
    const modules = Object.keys(permissions);

    return modules.reduce((acc, module) => {
        return {
            ...acc,
            [module]: yup.object()
        };
    }, {});
};

export const transformPermissionsBEtoFE = (apiPermissions: UserPermissions): UserPermissionsFE => {
    const modules = Object.keys(apiPermissions);
    type ModuleKeys = keyof typeof apiPermissions;

    const schema = modules.reduce((acc, module) => {
        const subModules = Object.keys(apiPermissions[module as ModuleKeys]);

        return {
            ...acc,
            [module]: subModules.reduce((current, subModule) => {
                return {
                    ...current,
                    // eslint-disable-next-line
                    // @ts-ignore
                    [subModule]: !!getActivePathsFromBEPermissions(apiPermissions[module as ModuleKeys][subModule])
                        ?.length
                };
            }, {})
        };
    }, {});

    return schema as UserPermissionsFE;
};

/**
 *
 * @param user user object - API format
 * @param resources paths of the resource
 * @returns boolean
 *
 * @example hasAccessTo(user, ["Electricity.Generation.Reports.CREATE"])
 * @example hasAccessTo(user, ["Electricity.Generation.Reports.CREATE","Electricity.Generation.VPPs.READ"])
 */
export const hasAccessTo = (user: User, resources: string[], method: 'some' | 'every' = 'every') => {
    if (user.superadmin) {
        return true;
    }

    const paths = getActivePathsFromBEPermissions(user.permissions, '') as string[];

    return resources[method]((resource) => paths.includes(resource));
};

export const commonOnLogout = (dispatch: Dispatch) => {
    store.clearAll();
    redirect(ROUTES.LOGIN);
    resetInterceptors(axios);
    dispatch({ type: 'USER_LOGOUT' });
};
