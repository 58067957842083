import { ForecastList } from '../../features/forecast-list';

const ForecastsList = () => {
    return (
        <>
            <ForecastList />
        </>
    );
};

export default ForecastsList;
