import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../utils/axios';

export const api = createApi({
    reducerPath: 'api',
    tagTypes: ['AssetForecastSettings'],
    baseQuery: axiosBaseQuery(),
    endpoints: () => ({})
});
