import { FC } from 'react';

// Components
import { Typography } from '@mui/material';

// Utils
import { TypographyTypeMap } from '@mui/material/Typography/Typography';

interface ValueProps {
    variant?: TypographyTypeMap['props']['variant'];
    unit?: string;
    value?: string | number;
}

const Value: FC<ValueProps> = ({ variant, unit, value }) => {
    const renderedValue = (() => {
        if (!value) {
            return 'N/A';
        }
        if (unit === '%') {
            return (+value * 100).toFixed(2);
        }
        if (unit === 'MW') {
            return (+value / 1000).toFixed(2);
        }

        return value;
    })();

    return (
        <Typography variant={variant}>
            {renderedValue} {value ? unit : ''}
        </Typography>
    );
};

export default Value;
