import { ChargingPoints, ChargingStations, EVSReport } from '../../interfaces/apiv2';
import { EVSCluster } from '../../interfaces/apiv2/evs/clusters';
import { ChargingPointsFE, ChargingStationsFE } from '../../interfaces/uiv2';
import { EVSClusterFE } from '../../interfaces/uiv2';
import { DATE_SETTINGS } from '../../utils/config';
import { getFormattedDate } from '../../utils/helpers';
import { DeliveredForecast } from '../../interfaces/apiv2';
import { DeliveredForecastFE } from '../../interfaces/uiv2';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../utils/table';
import { EVSReportFE } from '../../interfaces/uiv2/evs/evs_report';

export const transformChargingStationsDataToFE = (data: ChargingStations[]): ChargingStationsFE[] => {
    return data.map((e, i) => ({
        ...e,
        index: i + 1,
        type: 'ChargingStation',
        charging_points: e.charging_points.map((cp, cpi) => ({
            ...cp,
            index: cpi + 1,
            type: 'ChargingPoint',
            parent_id: e.id
        }))
    }));
};

export const transformChargingPointsDataToFE = (data: ChargingPoints[]): ChargingPointsFE[] => {
    return data.map((e, i) => ({
        ...e,
        index: i + 1,
        type: 'ChargingPoint'
    }));
};

export const transformClustersDataToFE = (data: EVSCluster[]): EVSClusterFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};

export const transformReportsAPItoFE = (data: EVSReport[]): EVSReportFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};

export const transformDeliveredForecastsAPItoFE = (data: DeliveredForecast[]): DeliveredForecastFE[] => {
    return data.map((item, index) => ({
        ...item,
        index: index + 1,
        from_date: getFormattedDate({
            value: item.from_date,
            type: DATE_SETTINGS.shortV2FormatHoursIncluded,
            timezone: item.forecast_settings?.asset?.timezone,
            fallback: DEFAULT_EMPTY_CELL_VALUE
        }),
        to_date: getFormattedDate({
            value: item.to_date,
            type: DATE_SETTINGS.shortV2FormatHoursIncluded,
            timezone: item.forecast_settings?.asset?.timezone,
            fallback: DEFAULT_EMPTY_CELL_VALUE
        })
    }));
};
