import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';
import axios from '../../utils/axios';
import { toast } from 'react-toastify';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { PostGenerationAsset } from '../../interfaces/apiv2';
import { GenerationAssetFE } from '../../interfaces/uiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const createAssetAsync = createAsyncThunk<PostGenerationAsset, PostGenerationAsset, { state: RootState }>(
    'assets/create',
    async (body: PostGenerationAsset, { rejectWithValue }) => {
        try {
            const response = await axios.post(`electricity/generation/assets`, body);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const saveForecastSettingsAsync = createAsyncThunk<GenerationAssetFE, GenerationAssetFE, { state: RootState }>(
    'assets/saveForecastSettingsAsync',
    async (body: GenerationAssetFE, { rejectWithValue, getState }) => {
        try {
            const { user } = getState().login;

            const response = await axios.put(`/clients/${user?.client?.id}/parks/${body.id}`, {
                ...body,
                trigger_schedule: true
            });

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const createAsset = createSlice({
    name: 'createAsset',
    initialState,
    reducers: {
        resetCreateAssetState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createAssetAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(createAssetAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(createAssetAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });

        builder.addCase(saveForecastSettingsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(saveForecastSettingsAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(saveForecastSettingsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetCreateAssetState } = createAsset.actions;

export default createAsset.reducer;
