import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import i18next from '../../utils/i18next';
import { GetParams } from '../../interfaces/general';
import { transformClustersDataToFE } from './helpers';
import ToastifyType from '../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { PaginatedApiResponse, ApiPaginationInfo } from '../../interfaces/apiv2';
import { mockRequest } from '../../utils/helpers';
import { staticTLClusters, TLCluster, TLClusterFE } from './mock-data';

interface ListClustersState extends DefaultInitialState {
    clusters: TLCluster[];
    clustersFE: TLClusterFE[];
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
}

const initialState: ListClustersState = {
    clusters: [],
    clustersFE: [],
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    pager: null
};

export const getTLClustersListAsync = createAsyncThunk(
    'tl-Clusters/list',
    async ({ getParams }: { getParams: GetParams }, { rejectWithValue }) => {
        try {
            /* const queryParams = getQueryParamsFromObject({ ...getParams });
          const response = await axios.get<PaginatedApiResponse<Consumer>>(
              `/tl/Clusters/${projectId}${queryParams}`
          );
          return response.data;*/

            const data = (await mockRequest(500, staticTLClusters, getParams)) as any;

            return {
                items: data,
                page: 1,
                pager: null
            } as unknown as PaginatedApiResponse<TLCluster>;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listTLClusters = createSlice({
    name: 'listTLClusters',
    initialState,
    reducers: {
        resetListTLClustersState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTLClustersListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getTLClustersListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.clusters = items;
            } else {
                state.clusters = [...current(state).clusters, ...items];
            }
            state.clustersFE = transformClustersDataToFE(current(state).clusters);
        });
        builder.addCase(getTLClustersListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetListTLClustersState, setGetParams: setGetTLClustersParams } = listTLClusters.actions;

export default listTLClusters.reducer;
