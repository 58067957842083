import { FC, useContext } from 'react';
import Dialog from '@mui/material/Dialog';

import { ModalContext } from '../../hooks/modalContext';
import { BaseModalProps } from '../../interfaces/general';

const BaseModal: FC<BaseModalProps> = ({
    id,
    children,
    maxWidth,
    callback,
    fullWidth,
    fullScreen,
    closeOnBackdropClick
}) => {
    const { open, handleClose } = useContext(ModalContext);
    return (
        <>
            <Dialog
                id={id}
                open={open[id]}
                fullWidth={fullWidth}
                fullScreen={fullScreen}
                maxWidth={maxWidth}
                onClose={(event, reason) => {
                    if (!closeOnBackdropClick && reason === 'backdropClick') {
                        return;
                    }

                    handleClose(id);
                    callback?.();
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{ className: 'lightBg' }}
            >
                {children}
            </Dialog>
        </>
    );
};

export { BaseModal };
