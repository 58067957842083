import { useEffect, useState } from 'react';

export const useHideInput = ({ data, callback }: { data?: unknown[]; callback: (arg: any) => void }) => {
    const [hideInput, setHideInput] = useState(true);

    useEffect(() => {
        if (data) {
            if (data?.length === 0) {
                setHideInput(true);
            } else if (data?.length === 1) {
                callback(data[0]);
                setHideInput(true);
            } else {
                setHideInput(false);
            }
        } else {
            setHideInput(true);
        }
    }, [data]);

    return {
        hideInput
    };
};
