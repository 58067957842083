import { FC } from 'react';
import style from './Group.module.scss';

// Utils
import classNames from 'classnames';
import { DashboardGroupProps } from '../../config';
import { useAppSelector } from '../../../../stores';
import { hasAccessTo } from '../../../../utils/user';

// Components
import { Box, Typography } from '@mui/material';
import Card from '../Card';
import Value from '../Value';
import Loading from '../../../../layout/Loading';

interface GroupProps {
    group: DashboardGroupProps;
}

const Group: FC<GroupProps> = ({ group }) => {
    const { groupKey, type, title, headerStats, query, requiredPermissions, cards } = group;
    const withHeaderStats = headerStats && headerStats.length > 0;

    const { user } = useAppSelector((state) => state.login);
    const { data, isLoading } = query({}, { skip: !hasAccessTo(user!, requiredPermissions) });

    if (isLoading) {
        return (
            <Box className={classNames(style.container, style[type])}>
                <Box className={style.header}>
                    <Typography variant="h6">{title}</Typography>
                </Box>
                <Box className={classNames(style.content, style[type], style.loading)}>
                    <Loading className={style.loader} />
                </Box>
            </Box>
        );
    }

    return (
        <Box className={classNames(style.container, style[type])}>
            <Box className={style.header}>
                <Typography variant="h6">{title}</Typography>

                {withHeaderStats && (
                    <Box className={style.stats}>
                        {headerStats.map(({ headerKey, title, unit }) => {
                            return (
                                <Box key={headerKey} className={style.stat}>
                                    <Typography variant="small4">{title}</Typography>
                                    <Value variant="small3" value={(data as any)?.[headerKey]} unit={unit} />
                                </Box>
                            );
                        })}
                    </Box>
                )}
            </Box>

            <Box className={classNames(style.content, style[type])}>
                {cards.map((card) => (
                    <Card key={card.cardKey} type={type} card={card} groupData={(data as any)?.[groupKey]} />
                ))}
            </Box>
        </Box>
    );
};

export default Group;
