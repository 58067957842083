import { DefaultEntityTimestamps, ForecastDeliveryInterval } from '.';

export enum ForecastType {
    DayAhead = 'DayAhead',
    Intraday = 'Intraday',
    LongTerm = 'LongTerm'
}

export interface ForecastSettings extends DefaultEntityTimestamps {
    forecast_type: ForecastType;
    timezone: string;
    time_resolution: number;
    aggregation_level: string;
    horizon: number;
    percentiles: boolean;
    measure_scale: number;
    error_measurements: string[];
    parameters: string[];
    trained_model_id: string;
    trained_p10_id: string;
    trained_p25_id: string;
    trained_p75_id: string;
    trained_p90_id: string;
    delivery_interval?: ForecastDeliveryInterval;
}

export interface PostForecastSettings {
    forecast_type: ForecastType;
    timezone: string;
    time_resolution: number;
    aggregation_level: string;
    /**
     * @see: ISO 8601 format
     * @example: 24:00:00 (means 24 hours)
     */
    horizon: string;
    percentiles: boolean;
    measure_scale: number;
    error_measurements: string[];
    parameters: string[];
    delivery_interval?: {
        start_time: string;
        interval: number;
        interval_unit: 'minute' | 'hour';
    };
    // disabled?: boolean;
}

export interface PatchForecastSettings extends PostForecastSettings {}
