import { UNIT } from '../../interfaces/uiv2';

export const mockMarketsList = [
    {
        id: 'd691f750-f960-44f3-a884-e116524ce664',
        market: 'EPEX',
        region: 'EU',
        country: null,
        price: 535.22,
        '1h': 11,
        '24h': 10,
        '7d': 7,
        volume_24h: 7,
        volume_7d: 78,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: '7666e40a-9fb7-40f1-9b1c-ab6a48cc6337',
        market: 'OTE',
        region: 'EU',
        country: null,
        price: 350.45,
        '1h': 12,
        '24h': 11,
        '7d': 9,
        volume_24h: 3,
        volume_7d: 32,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: '5a7fee50-4a34-465b-a2f4-6338cf9ecbd7',
        market: 'ICE',
        region: 'EU',
        country: null,
        price: 580.22,
        '1h': 10,
        '24h': 22,
        '7d': 9,
        volume_24h: 1.2,
        volume_7d: 12,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: 'bb0b4f67-ab7a-4e0b-bc3e-84d9f0b78c4d',
        market: 'GME',
        region: 'EU',
        country: null,
        price: 535.02,
        '1h': 5,
        '24h': 12,
        '7d': 5,
        volume_24h: 1.1,
        volume_7d: 10,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: '3d1263a2-6807-45ab-9546-3d4dcb0ee11a',
        market: 'OMX',
        region: 'EU',
        country: null,
        price: 535.02,
        '1h': 2,
        '24h': 10,
        '7d': 4,
        volume_24h: 1.22,
        volume_7d: 66,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: '96d356f4-a277-4d01-9ac1-42c8f0e4aa1c',
        market: 'OPCOM',
        region: 'EU',
        country: 'RO',
        price: 535.12,
        '1h': 4,
        '24h': 7,
        '7d': 9,
        volume_24h: 2.7,
        volume_7d: 45,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: '933dbbed-6cf2-4730-a419-f0eeb0daeff2',
        market: 'PXE',
        region: 'EU',
        country: null,
        price: 535.02,
        '1h': 14,
        '24h': 12,
        '7d': 9,
        volume_24h: 2.3,
        volume_7d: 23,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: '0ae5e97c-bce4-4385-8deb-600897908815',
        market: 'APX',
        region: 'EU',
        country: 'GB',
        price: 535.02,
        '1h': 3,
        '24h': 4,
        '7d': 2,
        volume_24h: 1.8,
        volume_7d: 88,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: '53579987-ff0a-4745-9b5e-bd4fbf05ab8c',
        market: 'Belpex',
        region: 'EU',
        country: 'BE',
        price: 455.05,
        '1h': 33,
        '24h': 12,
        '7d': 1,
        volume_24h: 1.6,
        volume_7d: 8.6,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: 'f33ce09c-5621-4e0b-8fe8-c3588ac1d51d',
        market: 'HEnEX',
        region: 'EU',
        country: 'GR',
        price: 390.02,
        '1h': 5,
        '24h': 8,
        '7d': 9,
        volume_24h: 3.6,
        volume_7d: 36.22,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: '2bfb2b2d-8378-433d-830e-b832c12308d7',
        market: 'HUPX',
        region: 'EU',
        country: 'HU',
        price: 594.04,
        '1h': 14,
        '24h': 12,
        '7d': 9,
        volume_24h: 0.56,
        volume_7d: 0.56,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: 'd65401f6-6187-4b59-8eee-8f5c72243f05',
        market: 'IBEX',
        region: 'EU',
        country: 'BG',
        price: 535.02,
        '1h': 23,
        '24h': 5,
        '7d': 7,
        volume_24h: 1.89,
        volume_7d: 1.89,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: '731a1636-0f37-4ba2-9a97-38cedb953ee4',
        market: 'Nordpool Spot',
        region: 'EU',
        country: null,
        price: 344.02,
        '1h': 20,
        '24h': 6,
        '7d': 5,
        volume_24h: 2.02,
        volume_7d: 2.02,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: '52bdebb7-7804-4a20-8b3d-91378258a978',
        market: 'OMIE',
        region: 'EU',
        country: null,
        price: 535.02,
        '1h': 25,
        '24h': 5,
        '7d': 2,
        volume_24h: 3.9,
        volume_7d: 10.12,

        currency: UNIT.USD,
        type: 'Price'
    },
    {
        id: '4985d26f-a08f-444b-b76f-73dae88f28d0',
        market: 'OKTE',
        region: 'EU',
        country: 'SL',
        price: 535.02,
        '1h': 22,
        '24h': 2,
        '7d': 2,
        volume_24h: 2.4,
        volume_7d: 24.22,
        currency: UNIT.USD,
        type: 'Price'
    }
];
