import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../../../utils/axios';
import i18next from '../../../../utils/i18next';
import { transformSegmentsDataToFE } from '../../helpers';
import { GetParams } from '../../../../interfaces/general';
import ToastifyType from '../../../../utils/toastify-config';
import { ClustersDataFE } from '../../../../interfaces/uiv2';
import { PAGINATION_SETTINGS } from '../../../../utils/config';
import { getQueryParamsFromObject } from '../../../../utils/table';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../../../interfaces/redux';
import { ApiPaginationInfo, Cluster, PaginatedApiResponse } from '../../../../interfaces/apiv2';

interface ListSegmentsState extends DefaultInitialState {
    segments: Cluster[];
    segmentsFE: ClustersDataFE[];
    selectedRows: ClustersDataFE[];
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
}

const initialState: ListSegmentsState = {
    segments: [],
    segmentsFE: [],
    selectedRows: [],
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    pager: null
};

export const getSegmentsListAsync = createAsyncThunk(
    'segments/list',
    async ({ projectId, getParams }: { getParams: GetParams; projectId: string }, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...getParams, type: 'Segment' });
            const response = await axios.get<PaginatedApiResponse<Cluster>>(
                `/electricity/demand/${projectId}/clusters${queryParams}`
            );
            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const importSegmentsAsync = createAsyncThunk(
    'segments/import',
    async (
        { projectId, file, type }: { projectId: string; file: File; type: 'import' | 'historical' },
        { rejectWithValue }
    ) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(
                `/electricity/demand/${projectId}/clusters/${type}?cluster_type=Segment`,
                formData,
                {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
            );

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listSegments = createSlice({
    name: 'listSegments',
    initialState,
    reducers: {
        resetListSegmentsState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<ClustersDataFE[]>) => {
            state.selectedRows = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSegmentsListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getSegmentsListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.segments = items;
            } else {
                state.segments = [...current(state).segments, ...items];
            }
            state.segmentsFE = transformSegmentsDataToFE(current(state).segments);
        });
        builder.addCase(getSegmentsListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(importSegmentsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(importSegmentsAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(importSegmentsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const {
    resetListSegmentsState,
    setGetParams: setGetSegmentsParams,
    setSelectedRows: setSelectedRowsSegments
} = listSegments.actions;

export default listSegments.reducer;
