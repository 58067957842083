import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo, Market } from '../../interfaces/apiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { transformForecastToTable, transformForecastToView } from '../../utils/forecast';
import { mockMarketsList } from './mockData';
import { FORECAST_TYPE, ForecastDataGetParams, ForecastFE } from '../../interfaces/uiv2';
import { mockRequest } from '../../utils/helpers';

interface IMarketsPricingPage extends DefaultInitialState {
    market: Market | null;
    data: ForecastFE[];
    paginatedData: ForecastFE[];
    chartData: LineData[][];
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
}

const initialState: IMarketsPricingPage = {
    market: null,
    data: [],
    paginatedData: [],
    chartData: [[], []],
    loading: false,
    error: false,
    success: false,
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        // order_by: ['-created_on']
        order_by: undefined
    },
    pager: null
};

export const getMarketsPricingAsync = createAsyncThunk(
    'market/show',
    async (values: { id: string }, { rejectWithValue }) => {
        try {
            /*const response = await axios.get<Market>(`/market/${values.id}`);

            return response.data;*/
            const market = mockMarketsList.find((e) => e.id === values.id);
            const data = await mockRequest(250, market);
            return data as Market;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getMarketsPricingDataAsync = createAsyncThunk(
    'market_data/get',
    async ({ market }: { market: Market }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ForecastFE[]>(`/market/${market.id}/forecast`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const marketPage = createSlice({
    name: 'marketPage',
    initialState,
    reducers: {
        resetMarketsPricingPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setActiveMarketsPricing: (state, { payload }: PayloadAction<Market | null>) => {
            state.market = payload;
        },
        loadMore: (state) => {
            const { data, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: data.length
            };
            state.paginatedData = data.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMarketsPricingDataAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getMarketsPricingDataAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            const country = current(state).market?.country;
            state.data = transformForecastToTable(payload as any, country || '');
            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).data.length
            };
            state.paginatedData = current(state).data.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
            state.chartData = [transformForecastToView(payload as any, FORECAST_TYPE.ELECTRICITY, country || ''), []];
        });
        builder.addCase(getMarketsPricingDataAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            //toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getMarketsPricingAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getMarketsPricingAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.market = payload;
        });
        builder.addCase(getMarketsPricingAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            //toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetMarketsPricingPage, setActiveMarketsPricing, setGetParams, loadMore } = marketPage.actions;

export default marketPage.reducer;
