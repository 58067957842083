import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { GetParams } from '../../interfaces/general';
import { Forecast } from '../../interfaces/apiv2';

import axios from '../../utils/axios';
import { ProsumerCluster } from './listProsumerClusters';
import { LineData } from 'lightweight-charts';
import { sortForecastData, transformForecastToView } from '../../utils/forecast';
import { FORECAST_TYPE } from '../../interfaces/uiv2';

interface ProsumerClusterPage extends DefaultInitialState {
    cluster: ProsumerCluster | null;
    getParams: GetParams;
    loadingForecast: boolean;
    successForecast: boolean;
    errorForecast: boolean;
    loadingHistorical: boolean;
    successHistorical: boolean;
    errorHistorical: boolean;
    forecasts: LineData[][];
}

const initialState: ProsumerClusterPage = {
    cluster: null,
    loading: false,
    error: false,
    success: false,
    loadingForecast: false,
    successForecast: false,
    errorForecast: false,
    loadingHistorical: false,
    successHistorical: false,
    errorHistorical: false,
    forecasts: [[], []], // [forecast, historical]
    getParams: DEFAULT_GET_PARAMS_VALUE
};

export const getClusterForecastAsync = createAsyncThunk(
    'prosumers-Clusters/forecast',
    async (values: any, { rejectWithValue }) => {
        try {
            const response = await axios.get<Forecast[]>(`/electricity/prosumers/clusters/${values.id}/forecast`, {
                // cache: {
                //     ttl: 1000 * 60 * 15
                // },
                params: {
                    from_datetime: values.from,
                    to_datetime: values.to,
                    forecast_type: values.forecast_type,
                    time_increment: values.time_increment
                }
            });

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getClusterHistoricalAsync = createAsyncThunk(
    'prosumers-Clusters/historical',
    async (values: any, { rejectWithValue }) => {
        try {
            const response = await axios.get<Forecast[]>(`/electricity/prosumers/clusters/${values.id}/historical`, {
                // cache: {
                //     ttl: 1000 * 60 * 15
                // },
                params: {
                    from_datetime: values.from,
                    to_datetime: values.to,
                    forecast_type: values.forecast_type,
                    time_increment: values.time_increment
                }
            });

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getProsumerClusterAsync = createAsyncThunk(
    'prosumers-Clusters/show',
    async (values: { id: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/electricity/prosumers/clusters/${values.id}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const prosumerClusterPage = createSlice({
    name: 'prosumerClusterPage',
    initialState,
    reducers: {
        resetProsumerClusterPageState: () => initialState,
        setGetParamsProsumerClusterPage: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        resetClusterForecastData: (state) => {
            state.forecasts = [[], []];
            state.errorForecast = false;
            state.loadingForecast = false;
            state.successForecast = false;
            state.errorHistorical = false;
            state.loadingHistorical = false;
            state.successHistorical = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProsumerClusterAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getProsumerClusterAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.cluster = payload;
        });
        builder.addCase(getProsumerClusterAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(getClusterForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getClusterForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            const actualData = (current(state).forecasts || [])[0] || [];
            state.forecasts[0] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.ELECTRICITY),
                ...actualData
            ]);
        });
        builder.addCase(getClusterForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
        builder.addCase(getClusterHistoricalAsync.pending, (state) => {
            state.loadingHistorical = true;
            state.errorHistorical = false;
            state.successHistorical = false;
        });
        builder.addCase(getClusterHistoricalAsync.fulfilled, (state, { payload }) => {
            state.loadingHistorical = false;
            state.errorHistorical = false;
            state.successHistorical = true;
            const actualData = (current(state).forecasts || [])[1] || [];
            state.forecasts[1] = sortForecastData([
                ...transformForecastToView(payload, FORECAST_TYPE.ELECTRICITY),
                ...actualData
            ]);
        });
        builder.addCase(getClusterHistoricalAsync.rejected, (state) => {
            state.loadingHistorical = false;
            state.errorHistorical = true;
            state.successHistorical = false;
        });
    }
});

export const { resetProsumerClusterPageState, setGetParamsProsumerClusterPage, resetClusterForecastData } =
    prosumerClusterPage.actions;

export default prosumerClusterPage.reducer;
