import { useState } from 'react';
import style from './style.module.scss';

// Assets
import { MdMoreHoriz } from 'react-icons/md';

// Components
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export interface TableAction {
    id: string;
    label: string;
    callback: () => void;
    disabled?: boolean;
    link?: boolean;
    url?: string;
}

export interface TableActionsProps {
    actions: TableAction[];
    className?: string;
}

const TableActions = ({ actions, className = '' }: TableActionsProps) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                id="long-button"
                aria-label="more"
                aria-haspopup="true"
                onClick={handleClick}
                disabled={!actions.length}
                className={classnames(style.editAction, !actions.length ? style.disabled : '', className)}
                aria-expanded={open ? 'true' : undefined}
                aria-controls={open ? 'long-menu' : undefined}
            >
                <MdMoreHoriz />
            </IconButton>

            {!!actions.length && (
                <Menu
                    MenuListProps={{
                        'aria-labelledby': 'long-button'
                    }}
                    open={open}
                    className={className}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    id="tableActionsPopup"
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    PopoverClasses={{ root: style.rootMenu }}
                >
                    {actions.map((a, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                a.callback();
                                handleClose();
                            }}
                            disabled={a.disabled}
                        >
                            {a.link ? (
                                <Link className={style.link} to={a.url || ''}>
                                    <Typography variant="small4">{t(a.label)}</Typography>
                                </Link>
                            ) : (
                                <Typography variant="small4">{t(a.label)}</Typography>
                            )}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </>
    );
};

export { TableActions };
