import classnames from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { VictoryAxis, VictoryChart, VictoryLine, VictoryTooltip, VictoryVoronoiContainer } from 'victory';

import { formatForecast } from './helpers';
import { getLinesColor } from '../../utils/dashboard';
import { INTRATA_CLUSTER_ID, useGetIntrataForecastQuery } from '../../stores/Demand';
import { ReactComponent as ForecastsIcon } from '../../assets/icons/navigation-items/dots-menu-forecasts.svg';

import style from './style.module.scss';
import { ROUTES } from '../../utils/config';

export const GasDashboardIntraCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });

    const graphRef = useCallback((node) => {
        if (node !== null) {
            setBoundingRect(node.getBoundingClientRect());
        }
    }, []);

    const { data = [] } = useGetIntrataForecastQuery({
        projectId
    });

    const handleClick = () => {
        navigate(
            ROUTES.AI_GAS_DEMAND_CLUSTERS_PAGE.replace(':projectId', projectId || '').replace(':id', INTRATA_CLUSTER_ID)
        );
    };

    return (
        <>
            <Box className={style.itemHeader}>
                <Box className={style.name}>
                    <ForecastsIcon />
                    <Typography variant="h7" className="dragDisable" onClick={handleClick}>
                        {t('header.intrata')}
                    </Typography>
                </Box>
            </Box>

            <Box className={style.itemChartContainer}>
                <Box className={classnames(style.itemChart, style.p12)} ref={graphRef}>
                    <VictoryChart
                        height={boundingRect.height}
                        width={boundingRect.width}
                        containerComponent={
                            <VictoryVoronoiContainer
                                labels={({ datum }) => datum.value}
                                labelComponent={
                                    <VictoryTooltip
                                        cornerRadius={8}
                                        flyoutStyle={{
                                            stroke: '#d5e3ff',
                                            fill: 'white'
                                        }}
                                    />
                                }
                            />
                        }
                    >
                        <VictoryLine
                            style={{
                                data: { stroke: getLinesColor() },
                                parent: { border: '1px solid #ccc' },
                                labels: { fill: '#6a9bff' }
                            }}
                            /*domainPadding={{ x: [0, -20], y: 0 }}*/
                            data={formatForecast(data)}
                            x={'date'}
                            y={'value'}
                        />
                        <VictoryAxis
                            style={{
                                axis: {
                                    stroke: '#D5E3FF'
                                },
                                tickLabels: {
                                    fill: '#828282'
                                }
                            }}
                        />

                        <VictoryAxis
                            dependentAxis
                            orientation="right"
                            style={{
                                axis: {
                                    stroke: 'transparent'
                                },
                                tickLabels: {
                                    fill: '#828282'
                                },
                                grid: { stroke: '#D5E3FF', strokeWidth: 0.5 }
                            }}
                        />
                    </VictoryChart>
                </Box>
            </Box>
        </>
    );
};
