export const layout = {
    lg: [
        {
            h: 3,
            i: 'static',
            w: 4,
            x: 0,
            y: 0,
            static: true
        },
        {
            h: 3,
            i: 'pie',
            w: 4,
            x: 4,
            y: 0,
            static: true
        },
        {
            h: 3,
            i: 'profile',
            w: 4,
            x: 8,
            y: 0,
            static: true
        },
        {
            h: 3,
            i: 'forecast',
            w: 12,
            x: 0,
            y: 3,
            static: true
        }
    ]
};
