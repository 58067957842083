import React from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Loading from '../../layout/Loading';
import { GenericTable } from '../BaseTable';
import { UNIT } from '../../interfaces/uiv2';
import { getDemandTableHeadCells, renderDemandFooterContent } from './helpers';

import style from './style.module.scss';

interface GenerationTableProps {
    data: any[];
    error: boolean;
    loading: boolean;
    success: boolean;
    assetMeasureUnit: UNIT;
}

const GenerationTable = ({ data: forecasts, loading, success, assetMeasureUnit }: GenerationTableProps) => {
    const { t } = useTranslation();
    const tableFooter = renderDemandFooterContent(forecasts, t);
    const headCells = getDemandTableHeadCells({ t, assetMeasureUnit });

    return (
        <Box className={style.wrapper}>
            <Box className={style.generationTableContainer}>
                {loading ? (
                    <Loading />
                ) : (
                    <GenericTable
                        pager={null}
                        className={classNames(style.generationTable, style.smallTable)}
                        rows={forecasts}
                        loading={loading}
                        success={success}
                        setGetParams={(() => {}) as any}
                        columns={headCells}
                        rowUniqueId={'index'}
                        storeSort={{}}
                        customFooter={tableFooter}
                    />
                )}
            </Box>
        </Box>
    );
};

export const MemoizedDemandGenerationTable = React.memo(GenerationTable);

export default GenerationTable;
