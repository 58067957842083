import i18next from 'i18next';
import { toast } from 'react-toastify';
import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import ToastifyType from '../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo } from '../../interfaces/apiv2';
import { getDownloadThunk, getVppPageChartThunk } from '../../utils/common-thunks';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { ForecastDataGetParams, ForecastFE, VPPFE } from '../../interfaces/uiv2';
import { DefaultInitialState, DEFAULT_GET_PARAMS_VALUE } from '../../interfaces/redux';
import { sortForecastData, transformForecastToTable } from '../../utils/forecast';
import { transformGenerationForecastToView } from '../../utils/timeseries-data';

interface VPPPage extends DefaultInitialState {
    vpp: VPPFE | null;
    searchParam: string | null;
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;

    loadingForecast: boolean;
    errorForecast: boolean;
    successForecast: boolean;

    forecasts: LineData[][];
    generationForecasts: ForecastFE[];
    paginatedData: ForecastFE[];

    errorMeasured: boolean;
    loadingMeasured: boolean;
    successMeasured: boolean;
    loadingDownload: boolean;

    limitLeftForecastBoundary: boolean;
    limitLeftMeasuredBoundary: boolean;
}

export const getVPPAsync = createAsyncThunk('vpp_page/show', async (values: { id: string }, { rejectWithValue }) => {
    try {
        const response = await axios.get<any>(`/electricity/generation/assets/vpp/${values.id}`);

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const downloadVPPData = getDownloadThunk({
    thunkName: 'vpp_page/download',
    endpoint: '/electricity/generation/vpp/files/download',
    bodyPropName: 'vpp_id',
    filename: 'data'
});

export const getVPPGenerationForecastAsync = getVppPageChartThunk({
    thunkName: 'vpp_page_generation_forecast/get',
    endpoint: 'forecast'
});

export const getVPPForecastAsync = getVppPageChartThunk({
    thunkName: 'vpp_page_forecast/get',
    endpoint: 'forecast'
});

export const getVPPMeasuredAsync = getVppPageChartThunk({
    thunkName: 'vpp_page_measured/get',
    endpoint: 'historical'
});

export const getVPPForecastAsyncMore = getVppPageChartThunk({
    thunkName: 'vpp_page_forecast_more/get',
    endpoint: 'forecast'
});

export const getVPPMeasuredAsyncMore = getVppPageChartThunk({
    thunkName: 'vpp_page_measured_more/get',
    endpoint: 'historical'
});

const initialState: VPPPage = {
    loading: false,
    error: false,
    success: false,
    vpp: null,
    searchParam: null,
    pager: null,
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        // order_by: ['-created_on']
        order_by: undefined
    },

    loadingForecast: false,
    errorForecast: false,
    successForecast: false,
    loadingDownload: false,

    forecasts: [[], []],
    generationForecasts: [],
    paginatedData: [],

    errorMeasured: false,
    loadingMeasured: false,
    successMeasured: false,

    limitLeftForecastBoundary: false,
    limitLeftMeasuredBoundary: false
};

export const vppPage = createSlice({
    name: 'vppPage',
    initialState,
    reducers: {
        resetVPPPage: () => initialState,
        setActiveVPP: (state, { payload }: PayloadAction<any>) => {
            state.vpp = payload;
        },
        loadMore: (state) => {
            const { generationForecasts, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: generationForecasts.length
            };
            state.paginatedData = generationForecasts.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        },
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(downloadVPPData.pending, (state) => {
            state.loadingDownload = true;
        });
        builder.addCase(downloadVPPData.fulfilled, (state) => {
            state.loadingDownload = false;
        });
        builder.addCase(downloadVPPData.rejected, (state) => {
            state.loadingDownload = false;
        });
        builder.addCase(getVPPAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getVPPAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.vpp = payload;
        });
        builder.addCase(getVPPAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(getVPPForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getVPPForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            const actualData = (current(state).forecasts || [])[0] || [];

            state.forecasts[0] = sortForecastData([
                ...transformGenerationForecastToView({
                    data: payload,
                    valueKey: 'value',
                    totalCapacity: current(state).vpp?.total_capacity || 0
                }),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftForecastBoundary = true;
            }
        });
        builder.addCase(getVPPForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });
        builder.addCase(getVPPGenerationForecastAsync.pending, (state) => {
            state.loadingForecast = true;
            state.errorForecast = false;
            state.successForecast = false;
        });
        builder.addCase(getVPPGenerationForecastAsync.fulfilled, (state, { payload }) => {
            state.loadingForecast = false;
            state.errorForecast = false;
            state.successForecast = true;
            //TODO: maybe pass the country here so it can display the local time of the asset or the local time of the user?
            state.generationForecasts = transformForecastToTable(payload);
        });
        builder.addCase(getVPPGenerationForecastAsync.rejected, (state) => {
            state.loadingForecast = false;
            state.errorForecast = true;
            state.successForecast = false;
        });

        builder.addCase(getVPPMeasuredAsync.pending, (state) => {
            state.loadingMeasured = true;
            state.errorMeasured = false;
            state.successMeasured = false;
        });
        builder.addCase(getVPPMeasuredAsync.fulfilled, (state, { payload }) => {
            state.loadingMeasured = false;
            state.errorMeasured = false;
            state.successMeasured = true;
            const actualData = (current(state).forecasts || [])[1] || [];
            state.forecasts[1] = sortForecastData([
                ...transformGenerationForecastToView({
                    data: payload,
                    valueKey: 'value',
                    totalCapacity: current(state).vpp?.total_capacity || 0
                }),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftMeasuredBoundary = true;
            }
        });
        builder.addCase(getVPPMeasuredAsync.rejected, (state) => {
            state.loadingMeasured = false;
            state.errorMeasured = true;
            state.successMeasured = false;
        });

        builder.addCase(getVPPForecastAsyncMore.fulfilled, (state, { payload }) => {
            const actualData = (current(state).forecasts || [])[0] || [];

            state.forecasts[0] = sortForecastData([
                ...transformGenerationForecastToView({
                    data: payload,
                    valueKey: 'value',
                    totalCapacity: current(state).vpp?.total_capacity || 0
                }),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftForecastBoundary = true;
            }
        });

        builder.addCase(getVPPMeasuredAsyncMore.fulfilled, (state, { payload }) => {
            const actualData = (current(state).forecasts || [])[1] || [];
            state.forecasts[1] = sortForecastData([
                ...transformGenerationForecastToView({
                    data: payload,
                    valueKey: 'value',
                    totalCapacity: current(state).vpp?.total_capacity || 0
                }),
                ...actualData
            ]);

            if (payload.length === 0) {
                state.limitLeftMeasuredBoundary = true;
            }
        });
    }
});

export const { resetVPPPage, setActiveVPP, setGetParams: setGetParamsVPPPage, loadMore } = vppPage.actions;

export default vppPage.reducer;
