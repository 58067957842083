import { DialogContent } from '@mui/material';

import { MODAL_IDS } from '..';
import Cleanup from '../../components/Cleanup/Cleanup';
import { useAppDispatch, useAppSelector } from '../../stores';
import { FormModal } from '../../components/BaseModal/FormModal';
import { resetEditAssetLocationsState } from '../../stores/Assets';
import { FormErrorStep, FormSuccessStep } from '../../components/FormUtils';
import AssetLocationsForm from '../AddAssetLocationsModal/AssetLocationsForm';
import { AssetLocationFE } from '../../interfaces/uiv2';

const EditAssetLocationsModal = ({ assetId, data }: { assetId: string; data: AssetLocationFE }) => {
    const dispatch = useAppDispatch();
    const { success, error } = useAppSelector((state) => state.editAssetLocations);

    const handleCleanup = () => {
        // use reset create project state
        dispatch(resetEditAssetLocationsState());
    };

    return (
        <FormModal id={MODAL_IDS.EDIT_LOCATION_COORDINATES} fullWidth={false} maxWidth="sm">
            <DialogContent>
                {!(success || error) ? <AssetLocationsForm assetId={assetId} data={data} /> : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
                {/* needed because create asset modal never unmounts but what is inside of dialog content yes */}
                <Cleanup cb={handleCleanup} />
            </DialogContent>
        </FormModal>
    );
};

export { EditAssetLocationsModal };
