import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Box } from '@mui/material';
import { TableCellRenderer } from '../../../../interfaces/general';
import { DATE_SETTINGS, NO_VALUE } from '../../../../utils/config';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../../../utils/table';
import { DefaultSearchableCell } from '../../../../components/BaseTable';
import { PageHeader } from '../../../../components/PageHeader/PageHeader';
import { DownloadButton, DownloadExtension } from '../../../../components/DownloadButton';
import { CircleProgressBar } from '../../../../components/BaseTable/CustomCells/CircleProgressBar';
import { EVSReportFE } from '../../../../interfaces/uiv2/evs/evs_report';

export const getTableHeadCells = (tList: TFunction, projectId?: string): TableCellRenderer<EVSReportFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        // {
        //     id: 'type',
        //     maxWidth: 75,
        //     label: tList('tableHead.type'),
        //     value: (row) => {
        //         return getAssetIcon(row.charging_point.type);
        //     },
        //     sort: true,
        //     sort_id: 'asset.type'
        // },
        {
            id: 'name',
            label: tList('tableHead.report'),
            sort: true,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.name;
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;

                return <DefaultSearchableCell value={value} searchValue={searchValue} isLink linkUrl={callback()} />;
            },
            sort_id: 'name'
        },
        {
            id: 'charging-point-name',
            label: tList('tableHead.chargingPoint'),
            sort: false,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.evs_cp_forecast_settings.charging_point.name;
                const handler = handlers?.['chargingPoint'];
                const callback = handler ? handler(row) : undefined;

                return <DefaultSearchableCell value={value} searchValue={searchValue} isLink linkUrl={callback()} />;
            }
        },
        {
            id: 'from',
            label: tList('tableHead.from'),
            sort: false,
            value: (row, searchValue) => {
                const rawValue = row.from_date;
                const timezone = dayjs.tz.guess();
                const utcValue = dayjs.utc(rawValue);
                const localTime = dayjs(utcValue).tz(timezone).format(DATE_SETTINGS.shortV2FormatHoursIncluded);
                const date = rawValue && dayjs(rawValue).isValid() ? localTime : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'to',
            label: tList('tableHead.to'),
            sort: false,
            value: (row, searchValue) => {
                const rawValue = row.to_date;
                const timezone = dayjs.tz.guess();
                const utcValue = dayjs.utc(rawValue);
                const localTime = dayjs(utcValue).tz(timezone).format(DATE_SETTINGS.shortV2FormatHoursIncluded);
                const date = rawValue && dayjs(rawValue).isValid() ? localTime : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'accuracy',
            label: tList('tableHead.accuracy'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const value = +(row.accuracy || 0) * 100;
                return row.accuracy ? <CircleProgressBar value={value} /> : NO_VALUE;
            }
        },
        {
            id: 'download',
            label: tList('tableHead.download'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const filename = `Ogre_Report_${row.name}`;
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <DownloadButton type={DownloadExtension.Pdf} id={row.id} value="evs-reports" filename={filename} /> */}
                        <DownloadButton
                            type={DownloadExtension.Excel}
                            id={row.id}
                            value="evs-reports"
                            filename={filename}
                            projectId={projectId}
                        />
                        <DownloadButton
                            type={DownloadExtension.Csv}
                            id={row.id}
                            value="evs-reports"
                            filename={filename}
                            projectId={projectId}
                        />
                    </Box>
                );
            }
        }
    ];
};
