import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import i18next from '../../utils/i18next';
import { GetParams } from '../../interfaces/general';
import { transformConsumersDataToFE } from './helpers';
import ToastifyType from '../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { PaginatedApiResponse, ApiPaginationInfo } from '../../interfaces/apiv2';
import { mockRequest } from '../../utils/helpers';
import { staticTLConsumers, TLConsumer, TLConsumerFE } from './mock-data';

interface ListConsumersState extends DefaultInitialState {
    consumers: TLConsumer[];
    consumersFE: TLConsumerFE[];
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
}

const initialState: ListConsumersState = {
    consumers: [],
    consumersFE: [],
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    pager: null
};

export const getTLConsumersListAsync = createAsyncThunk(
    'tl-consumers/list',
    async ({ getParams }: { getParams: GetParams }, { rejectWithValue }) => {
        try {
            /* const queryParams = getQueryParamsFromObject({ ...getParams });
            const response = await axios.get<PaginatedApiResponse<Consumer>>(
                `/tl/consumers/${projectId}${queryParams}`
            );
            return response.data;*/

            const data = (await mockRequest(500, staticTLConsumers, getParams)) as any;

            return {
                items: data,
                page: 1,
                pager: null
            } as unknown as PaginatedApiResponse<TLConsumer>;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listTLConsumers = createSlice({
    name: 'listTLConsumers',
    initialState,
    reducers: {
        resetListTLConsumersState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTLConsumersListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getTLConsumersListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.consumers = items;
            } else {
                state.consumers = [...current(state).consumers, ...items];
            }
            state.consumersFE = transformConsumersDataToFE(current(state).consumers);
        });
        builder.addCase(getTLConsumersListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetListTLConsumersState, setGetParams: setGetTLConsumersParams } = listTLConsumers.actions;

export default listTLConsumers.reducer;
