import classnames from 'classnames';
import Flag from 'react-world-flags';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { MODAL_IDS } from '../../modals';
import Loading from '../../layout/Loading';
import { UNIT } from '../../interfaces/uiv2';
import { TAB } from '../../interfaces/general';
import { DATE_SETTINGS } from '../../utils/config';
import { getFormattedDate } from '../../utils/helpers';
import { ModalContext } from '../../hooks/modalContext';
import { getMockedProsumersChartData, mockedProsumersTableData } from '../../stores/Prosumers/mocked-chart';
import { PageContainer } from '../../layout/PageContainer';
import { TradingView } from '../../components/TradingView';
import { useAppDispatch, useAppSelector } from '../../stores';
import { AssetLocationModal } from '../../modals/AssetLocationModal';
import { ReactComponent as ViewList } from '../../assets/icons/view_list.svg';
import { ReactComponent as GraphIcon } from '../../assets/icons/timeline.svg';
import { ReactComponent as LocationSvg } from '../../assets/icons/location.svg';
import { RemoveForecastContainer } from '../../components/RemoveEntityContainer';
import { ReactComponent as ProsumersIcon } from '../../assets/icons/navigation-items/menu-item-prosumers.svg';
import { TableActionsEntityContainer } from '../../components/TableActionsEntityContainer';
import { getProsumersAsync, resetProsumersPageState, setGetProsumerParams } from '../../stores/Prosumers';
import { MemoizedGenericTable } from '../../components/GenericTableWrapper/GenericTableWrapper';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../components/EntityPageToolbar';

import common from './../../common.module.scss';
import style from '../../features/asset-page/asset-page.module.scss';
import classNames from 'classnames';
import AssetStatusLabel from '../../components/AssetStatusLabel/AssetStatusLabel';
import { getProsumersForecastTableHeadCells, renderProsumersForecastFooterContent } from './helpers';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { deleteProsumersListAsync } from '../../stores/Prosumers/deleteProsumers';
import { LineData } from 'lightweight-charts';

const ProsumersPage = () => {
    const query = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { handleClickOpen } = useContext(ModalContext);

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;
    const [chartData, setChartData] = useState<LineData[][]>([]);

    // eslint-disable-next-line
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const { prosumers, loading, success, error } = useAppSelector((state) => state.prosumersPage);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    useEffect(() => {
        if (!prosumers && query?.id) {
            dispatch(getProsumersAsync({ id: query.id }));
        }
    }, [query?.id, prosumers]);

    useEffect(() => {
        if (prosumers) {
            setChartData(getMockedProsumersChartData(prosumers.total_capacity || 20));
        }
    }, [prosumers]);

    useEffect(() => {
        return () => {
            dispatch(resetProsumersPageState());
        };
    }, []);

    const remove = () => {
        dispatch(deleteProsumersListAsync([query.id!]));
    };
    const headCells = getProsumersForecastTableHeadCells(t);

    if (!prosumers) {
        return <Loading className={style.loading} />;
    }

    return (
        <PageContainer className={[common.pageContainer]}>
            <Box className={style.headerContainer}>
                <Box className={classnames(style.header)}>
                    <PageHeader
                        className={style.title}
                        title={t('title.prosumers')}
                        id={prosumers.id}
                        subtitle={prosumers.name}
                        icon={<ProsumersIcon />}
                    />
                    <Box className={style.subtitle} sx={{ ml: 2 }}>
                        <Box className={style.dot} />
                        <Typography variant="small1"> {t(pageTitle)}</Typography>
                    </Box>
                </Box>

                <Box className={style.toolbarContainer}>
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>
                    </EntityPageToolbar>
                    <TableActionsEntityContainer entityType="forecast" id={''} className={style.actionsWrapper} />
                    {/* <button onClick={() => setIsEdit(!isEdit)}>Edit</button> */}
                </Box>
            </Box>

            <Box className={common.subHeaderContainer}>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.type')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {prosumers.type}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.country')}</Typography>
                    <Flag
                        height="24"
                        width="24"
                        className={classnames('flagIcon', common.ml)}
                        code={prosumers.country}
                    />
                    <Typography variant="small3" className={common.ml}>
                        {prosumers.country}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.location')}</Typography>
                    <Typography
                        variant="small3"
                        className={classnames(common.ml, common.link)}
                        onClick={() => handleClickOpen(MODAL_IDS.ASSET_LOCATION_MODAL)}
                    >
                        <LocationSvg className={classnames(common.locationSVG, common.mr)} />
                        {prosumers.location}
                    </Typography>
                </Box>
                <Box className={classNames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('assetInfo.totalCapacity')}</Typography>
                    <Typography variant="small3" sx={{ ml: 1.5 }}>
                        {prosumers.total_capacity} {'kW'}
                    </Typography>
                </Box>
                <Box className={classNames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('assetInfo.status')}</Typography>
                    <Box sx={{ ml: 1.5 }}>
                        <AssetStatusLabel status={prosumers.status} variant="small3" />
                    </Box>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4" className={common.ml}>
                        {t('tableHead.expiryDate')}
                    </Typography>
                    <Typography variant="small3" className={common.ml}>
                        {getFormattedDate({
                            value: prosumers.created_at,
                            type: DATE_SETTINGS.shortFormat
                        })}
                    </Typography>
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={chartData as any}
                    error={false}
                    fetchMoreFn={() => {}}
                    loading={false}
                    legendOpts={{
                        forecasted: false,
                        measured: false,
                        demand: true,
                        generation: true,
                        aggregated: true
                    }}
                    assetMeasureUnit={UNIT.LOW_ENERGY}
                />
            )}
            {tab === 'table' && (
                <MemoizedGenericTable
                    pager={null}
                    data={mockedProsumersTableData.map((x, index) => ({ ...x, index: index + 1 }))}
                    error={error}
                    loading={loading}
                    success={success}
                    renderFooterContent={renderProsumersForecastFooterContent}
                    headCells={headCells}
                    fetchMoreFn={() => {}}
                    setGetParams={setGetProsumerParams}
                />
            )}

            <AssetLocationModal
                payload={{
                    longitude: prosumers.longitude,
                    latitude: prosumers.latitude,
                    title: prosumers.location,
                    location: prosumers.country
                }}
            />

            <RemoveForecastContainer cb={remove} stateAccessor="deleteForecasts" />
        </PageContainer>
    );
};

export default ProsumersPage;
