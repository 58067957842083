import { FC, ReactElement, useContext } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';

import { ModalContext } from '../../hooks/modalContext';

interface FormModalActionsProps {
    note?: string | ReactElement;
    classname?: string;
    modalId?: string;
    loading?: boolean;
    disabled?: boolean;
    saveLabel: string;
    cancelLabel: string;
    onSubmit?: () => unknown;
    onClose?: () => unknown;
}

const FormModalActions: FC<FormModalActionsProps> = ({
    onSubmit,
    classname = 'FormModalActions',
    note,
    modalId,
    loading = false,
    saveLabel,
    cancelLabel,
    disabled = false,
    onClose
}) => {
    const { handleClose } = useContext(ModalContext);
    const handleCloseBtn = () => {
        if (modalId) {
            handleClose(modalId);
        }
        if (onClose) {
            onClose();
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                marginTop: '20px',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
            className={classname}
        >
            {note ? (
                <Typography variant="formFooterNote" sx={{ position: 'relative' }}>
                    {note}
                </Typography>
            ) : (
                <Box />
            )}
            <Stack direction="row" alignItems="center">
                <Button disableRipple variant="text" sx={{ mr: 3 }} className={'lightFont'} onClick={handleCloseBtn}>
                    {cancelLabel}
                </Button>
                <LoadingButton type="submit" variant="primary" loading={loading} disabled={disabled} onClick={onSubmit}>
                    {saveLabel}
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export { FormModalActions };
