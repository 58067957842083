import * as yup from 'yup';
import { TFunction } from 'i18next';

import { UserFormFields, UserFE } from '../../interfaces/uiv2';
import { PatchUser, PostUser, UserPermissions } from '../../interfaces/apiv2';
import { getPermissionsSchema, transformPermissionsBEtoFE } from '../../utils/user';

export const getUserSchema = (t: TFunction, permissions?: UserPermissions) => {
    const permissionsSchema = permissions ? getPermissionsSchema(permissions) : {};

    return yup
        .object<Record<keyof UserFormFields, yup.AnySchema>>({
            firstName: yup.string().required(t('validations.required')),
            lastName: yup.string().required(t('validations.required')),
            jobTitle: yup.object().nullable(true),
            email: yup.string().required(t('validations.required')),
            client: yup.object().nullable(true),
            superadmin: yup.boolean(),
            guest: yup.boolean(),
            permissions: yup.object(permissionsSchema)
        })
        .required();
};

export const transformUserFormToBody = (form: UserFormFields, authentication: number): PostUser => {
    return {
        authentication,
        email: form.email,
        first_name: form.firstName,
        last_name: form.lastName,
        superadmin: form.superadmin,
        guest: form.guest,
        job_title: form.jobTitle?.id || form.jobTitle?.title || null,
        client_id: form.client?.id || null
    };
};

export const transformUserFEtoUserPatchBody = (user: UserFE): PatchUser => {
    const data: UserFormFields = transformToUserSchema(user);
    const postUser: PostUser = transformUserFormToBody(data, user.authentication);

    return {
        id: user.id,
        body: {
            ...postUser,
            status: user.status,
            id: user.id
        }
    };
};

export const transformUserBodyToUserPatch = (body: PostUser, user: UserFE): PatchUser => {
    return {
        body: {
            ...body,
            id: user.id,
            status: user.status
        },
        id: user.id
    };
};

export const transformToUserSchema = (data: UserFE): UserFormFields => {
    const permissions = transformPermissionsBEtoFE(data.permissions);

    return {
        email: data.email,
        firstName: data.first_name,
        lastName: data.last_name,
        jobTitle: data.job_title,
        client: data.client,
        superadmin: data.superadmin,
        guest: data.guest,
        permissions
    };
};
