import { isEqual } from 'lodash';
import classnames from 'classnames';
import Flag from 'react-world-flags';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import {
    getEVSClusterAsync,
    getEVSClusterForecastDataAsync,
    getEVSClusternHistoricalAsync,
    loadMoreEVSClusterData,
    resetEVSClusterPage,
    setGetEVSClusterParams
} from '../../../stores/EVS';
import { UNIT } from '../../../interfaces/uiv2';
import { TAB } from '../../../interfaces/general';
import { PageContainer } from '../../../layout/PageContainer';
import { TradingView } from '../../../components/TradingView';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ReactComponent as ViewList } from '../../../assets/icons/view_list.svg';
import { ReactComponent as GraphIcon } from '../../../assets/icons/timeline.svg';
import { MemoizedGenericTable } from '../../../components/GenericTableWrapper/GenericTableWrapper';
import { TableActionsEntityContainer } from '../../../components/TableActionsEntityContainer';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../../components/EntityPageToolbar';
import { ReactComponent as ClusterSVG } from '../../../assets/icons/demand/clusters.svg';
import { getTableHeadCells, renderFooterContent } from '../../../components/GenerationTable/helpers';
import common from '../../../common.module.scss';
import style from '../../../features/asset-page/asset-page.module.scss';

const EVSClusterPage = () => {
    const query = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;

    const {
        pager,
        cluster,
        success,
        chartData,
        paginatedData,
        loading: loadingForecast,
        error: errorForecast
    } = useAppSelector((state) => state.evsClusterPage);
    const getParams = useAppSelector((state) => state.evsClusterPage.getParams, isEqual);

    useEffect(() => {
        if (query?.id && !cluster) {
            dispatch(getEVSClusterAsync({ id: query.id }));
        }
    }, [query, cluster]);

    useEffect(() => {
        if (cluster) {
            dispatch(getEVSClusterForecastDataAsync({ cluster }));
            dispatch(getEVSClusternHistoricalAsync({ cluster }));
        }
    }, [cluster]);

    useEffect(() => {
        return () => {
            dispatch(resetEVSClusterPage());
        };
    }, []);

    useEffect(() => {
        if (tab === 'table' && pager?.page !== getParams.page) {
            dispatch(loadMoreEVSClusterData());
        }
    }, [getParams, tab, pager]);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    if (!cluster) {
        return null;
    }

    const headCells = getTableHeadCells({ t, assetMeasureUnit: UNIT.POWER });

    return (
        <PageContainer className={[common.pageContainer]}>
            {/* <Box className={common.wrapper}> */}
            <Box className={style.headerContainer}>
                <Box className={classnames(style.header)}>
                    <PageHeader
                        title={t('title.clusters')}
                        className={classnames(style.align)}
                        id={cluster.id}
                        icon={<ClusterSVG />}
                        subtitle={cluster.name}
                    />

                    <Box className={style.subtitle} sx={{ ml: 2 }}>
                        <Box className={style.dot} />
                        <Typography variant="small1"> {t(pageTitle)}</Typography>
                    </Box>
                </Box>

                <Box className={style.toolbarContainer}>
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>
                    </EntityPageToolbar>
                    <TableActionsEntityContainer
                        entityType="forecast"
                        id={cluster?.id}
                        className={style.actionsWrapper}
                        remove={false}
                    />
                </Box>
            </Box>

            <Box className={common.subHeaderContainer}>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.type')}</Typography>
                    <Typography variant="small3" className={classnames(common.ml, common.flex)}>
                        <ClusterSVG />
                    </Typography>
                    <Typography variant="small3" className={common.ml}>
                        {'Cluster'}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.region')}</Typography>
                    <Typography variant="small3" className={classnames(common.ml, common.alignCenter)}>
                        <Flag
                            height="24"
                            width="24"
                            code={cluster.country_code}
                            style={{ marginRight: '8px' }}
                            className="flagIcon"
                        />
                        {cluster.country_code.toUpperCase()}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('assetInfo.name')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {cluster.name}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('assetInfo.totalChargingStations')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {cluster.totalChargingStations}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('assetInfo.totalLocations')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {cluster.totalLocations}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.createdAt')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {cluster.created_at}
                    </Typography>
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={chartData as any}
                    //error={errorForecast}
                    error={false}
                    fetchMoreFn={() => {}}
                    loading={loadingForecast}
                    legendOpts={{
                        forecasted: true,
                        measured: true
                    }}
                    assetMeasureUnit={UNIT.ENERGY}
                />
            )}
            {tab === 'table' && (
                <MemoizedGenericTable
                    renderFooterContent={renderFooterContent}
                    headCells={headCells}
                    pager={pager}
                    success={success}
                    data={paginatedData}
                    error={errorForecast}
                    loading={loadingForecast}
                    setGetParams={setGetEVSClusterParams}
                />
            )}
        </PageContainer>
    );
};

export default EVSClusterPage;
