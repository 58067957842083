import listUsers from './listUsers';
import createUser from './createUser';

import { api } from '../api';
import { PaginatedApiResponse, User } from '../../interfaces/apiv2';

export const usersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUsers: build.query({
            query: (params) => {
                if (params.search?.trim()) {
                    return {
                        url: '/users',
                        method: 'get',
                        params: { ...params, search: params.search },
                        data: undefined
                    };
                }

                return { url: '/users', method: 'get', params, data: undefined };
            },
            transformResponse: (rawData: PaginatedApiResponse<User>) => {
                return rawData.items;
            }
        }),
        getDeliveryOptionsUsers: build.query({
            query: (params) => {
                if (params.search?.trim()) {
                    return {
                        url: `/electricity/generation/assets/${params.assetId}/access-list`,
                        method: 'get',
                        params: { ...params, search: params.search },
                        data: undefined
                    };
                }

                return {
                    url: `/electricity/generation/assets/${params.assetId}/access-list`,
                    method: 'get',
                    params,
                    data: undefined
                };
            },
            transformResponse: (rawData: User[]) => {
                return rawData;
            }
        })
    })
});

export const { useGetUsersQuery, useGetDeliveryOptionsUsersQuery } = usersApi;

export * from './listUsers';
export * from './createUser';

export default { listUsers, createUser };
