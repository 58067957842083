import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import errorImg from '../../assets/images/error-visual.png';

import style from './style.module.scss';

const FormErrorStep = ({ classname }: { classname?: string }) => {
    const { t } = useTranslation();

    return (
        <Box className={classNames(style.box, classname)}>
            <Box className={style.content}>
                <Typography variant="h1">{t('form.errors.title')}</Typography>
                <Typography variant="h4">{t('form.errors.copy')}</Typography>
            </Box>
            <img src={errorImg} alt="error" />
        </Box>
    );
};

export { FormErrorStep };
