import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NO_VALUE } from '../../utils/config';
import { Box, Typography } from '@mui/material';
import { getAssetIcon } from '../../utils/getAssetIcon';
import { getFormattedCapacityValue } from '../../utils/helpers';
import { ASSET_TYPE, DashboardStaticItem } from '../../interfaces/uiv2';
import { ReactComponent as GenerationSVG } from '../../assets/icons/navigation-items/menu-item-generation.svg';

import style from './style.module.scss';

export const DashboardStaticCard = ({ item }: { item: DashboardStaticItem }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box className={style.itemHeader}>
                <Box className={classnames(style.name, style.black)}>
                    <Typography variant="h7">{t('portfolio')}</Typography>
                </Box>
            </Box>

            <Box className={classnames(style.itemDetails)}>
                <Typography className={classnames(style.element, style.border)} variant="small4">
                    {t('assetInfo.totalCapacity')}
                    <Typography variant="small3" className={style.black} sx={{ ml: 1.5 }}>
                        {getFormattedCapacityValue(item.totalCapacity)}
                    </Typography>
                </Typography>
                <Typography className={classnames(style.element, style.noBorder)} variant="small4">
                    {t('assetInfo.totalAssets')}
                    <Typography variant="small3" className={style.black} sx={{ ml: 1.5 }}>
                        {item.totalAssets || NO_VALUE}
                    </Typography>
                </Typography>
            </Box>

            <Box className={style.itemStaticDetailsWrapper}>
                <Box className={style.itemStaticDetails}>
                    <Box>
                        <Box className={style.itemStaticBox}>
                            {getAssetIcon(ASSET_TYPE.SOLAR)}
                            <Typography variant="h5">{item.totalSolar}</Typography>
                        </Box>
                        <Typography variant="small1">{t('pvAssets')}</Typography>
                    </Box>

                    <Box>
                        <Box className={style.itemStaticBox}>
                            {getAssetIcon(ASSET_TYPE.WIND)}
                            <Typography variant="h5">{item.totalWind}</Typography>
                        </Box>
                        <Typography variant="small1">{t('windAssets')}</Typography>
                    </Box>

                    <Box>
                        <Box className={style.itemStaticBox}>
                            {getAssetIcon(ASSET_TYPE.OTHER)}
                            <Typography variant="h5">{item.totalOthers}</Typography>
                        </Box>
                        <Typography variant="small1">{t('othersAssets')}</Typography>
                    </Box>
                </Box>

                <Box className={style.itemStaticDetails}>
                    <Box>
                        <Box className={style.itemStaticBox}>
                            <GenerationSVG />

                            <Box>
                                <Typography variant="h5">
                                    {getFormattedCapacityValue(item.totalSolarCapacity, '', false)}
                                </Typography>
                                <Typography variant="small1">MW</Typography>
                            </Box>
                        </Box>
                        <Typography variant="small1">{t('pvCapacity')}</Typography>
                    </Box>

                    <Box>
                        <Box className={style.itemStaticBox}>
                            <GenerationSVG />
                            <Box>
                                <Typography variant="h5">
                                    {getFormattedCapacityValue(item.totalWindCapacity, '', false)}
                                </Typography>
                                <Typography variant="small1">MW</Typography>
                            </Box>
                        </Box>
                        <Typography variant="small1">{t('windCapacity')}</Typography>
                    </Box>

                    <Box>
                        <Box className={style.itemStaticBox}>
                            <GenerationSVG />

                            <Box>
                                <Typography variant="h5">
                                    {getFormattedCapacityValue(item.totalOthersCapacity, '', false)}
                                </Typography>
                                <Typography variant="small1">MW</Typography>
                            </Box>
                        </Box>
                        <Typography variant="small1">{t('othersCapacity')}</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
