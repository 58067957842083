import { useEffect } from 'react';

const Cleanup = ({ cb }: { cb: () => void }) => {
    useEffect(() => {
        return cb;
    }, []);
    return null;
};

export default Cleanup;
