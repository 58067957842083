import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const deleteForecastsListAsync = createAsyncThunk(
    'forecasts_list/delete',
    async (ids: string[], { rejectWithValue }) => {
        try {
            await Promise.all(
                ids.map((id) => {
                    return axios.delete(`/electricity/generation/forecasts/delivered/${id}`);
                })
            );
            return null;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const deleteForecasts = createSlice({
    name: 'deleteForecasts',
    initialState,
    reducers: {
        resetDeleteForecastListState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(deleteForecastsListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(deleteForecastsListAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(deleteForecastsListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetDeleteForecastListState } = deleteForecasts.actions;

export default deleteForecasts.reducer;
