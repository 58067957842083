import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import { NO_VALUE_NA } from '../../../utils/config';
import { styleguide, Theme } from '../../../containers/Theme';

export const CircleProgressBar = (props: CircularProgressProps & { value: number }) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                size={32}
                thickness={4}
                variant="determinate"
                sx={{
                    color: styleguide.primaryDisabledColor,
                    position: 'absolute'
                }}
                {...props}
                value={100}
            />
            <CircularProgress
                size={32}
                thickness={4}
                variant="determinate"
                sx={{ color: Theme.palette.success.main }}
                {...props}
                value={!props.value ? 100 : props.value}
            />

            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="verySmallBold" component="div" color="text.secondary">
                    {!props.value ? NO_VALUE_NA : `${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
};
