import { useTranslation } from 'react-i18next';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { MdDeleteOutline, MdOutlineRefresh } from 'react-icons/md';

import { styleguide, Theme } from '../../../containers/Theme';
import { useAppDispatch } from '../../../stores';

interface TableToolbarActionsProps<T> {
    selectedRows: T[];
    deleteActionLabel?: string;
    deleteActionCallback?: () => void;
    setSelectedRows: ActionCreatorWithPayload<T[], string>;
}

export const TableToolbarActions = <T,>({
    selectedRows,
    setSelectedRows,
    deleteActionLabel,
    deleteActionCallback
}: TableToolbarActionsProps<T>) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const clearSelection = () => dispatch(setSelectedRows([]));

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="small2" sx={{ mr: 2, color: Theme.palette.primary.main }}>
                {selectedRows.length} {t('table.general.selected')}
            </Typography>
            <Button
                variant="action"
                disableRipple
                onClick={clearSelection}
                startIcon={
                    <SvgIcon
                        component={MdOutlineRefresh}
                        sx={{ color: styleguide.primaryTextColor, fill: styleguide.primaryColor }}
                    />
                }
            >
                {t('table.general.clearSelection')}
            </Button>
            {deleteActionLabel && deleteActionCallback ? (
                <Button
                    variant="action"
                    disableRipple
                    startIcon={
                        <SvgIcon
                            component={MdDeleteOutline}
                            sx={{ color: styleguide.primaryTextColor, fill: styleguide.primaryColor }}
                        />
                    }
                    onClick={deleteActionCallback}
                    sx={{ ml: 1 }}
                >
                    {deleteActionLabel}
                </Button>
            ) : null}
        </Box>
    );
};
