import classnames from 'classnames';
import { Box, Button, Tooltip } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import style from './style.module.scss';

interface EntityPageToolbarButtonProps<T> {
    tabId: T;
    activeTabId: T;
    handleTabChange: (tabId: T) => void;
    label: string;
    disabled?: boolean;
    customClassName?: string;
}

export const EntityPageToolbarButton = <T,>({
    tabId,
    label,
    children,
    activeTabId,
    handleTabChange,
    disabled,
    customClassName
}: PropsWithChildren<EntityPageToolbarButtonProps<T>>) => {
    const isTabActive = tabId === activeTabId;
    return (
        <Tooltip title={label} placement="top">
            <Button
                disabled={disabled}
                disableRipple
                onClick={() => handleTabChange(tabId)}
                className={classnames(style.button, isTabActive && (customClassName || style.active))}
            >
                {children}
            </Button>
        </Tooltip>
    );
};

export const EntityPageToolbar: FC = ({ children }) => {
    return <Box className={style.toolbarContainer}>{children}</Box>;
};
