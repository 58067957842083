import { Box } from '@mui/material';

import { styleguide, Theme } from '../../containers/Theme';
import { ASSET_STATUS, ChargingStationsFE, GenerationAssetFE } from '../../interfaces/uiv2';
import { ChargingStationStatus } from '../../interfaces/apiv2';

type ForecastBarGraphProps = {
    asset: GenerationAssetFE | ChargingStationsFE;
};

const getDefaultForecast = (capacity_value: number | null) => {
    const capacity = capacity_value || 100;
    return [capacity / 10, capacity / 10, capacity / 10, capacity / 10, capacity / 10, capacity / 10, capacity / 10];
};

const getBarColor = (status: ASSET_STATUS | ChargingStationStatus) => {
    switch (status) {
        case ASSET_STATUS.ACTIVE:
        case ChargingStationStatus.ACTIVE:
            return Theme.palette.primary.main;
        case ASSET_STATUS.DISABLED:
        case ChargingStationStatus.DISABLED:
            return styleguide.primaryDisabledColor;
        default:
            return styleguide.barPendingColor;
    }
};

const ForecastBarGraph = ({ asset: { latest_forecast, capacity_value, status } }: ForecastBarGraphProps) => {
    let forecasts: number[];
    switch (status) {
        case ASSET_STATUS.ACTIVE:
            forecasts = latest_forecast?.length ? latest_forecast.slice(0, 7) : getDefaultForecast(capacity_value);
            break;
        case ASSET_STATUS.PENDING:
            forecasts = getDefaultForecast(capacity_value);
            break;
        case ASSET_STATUS.DISABLED:
            forecasts = getDefaultForecast(capacity_value);
            break;
        default:
            forecasts = getDefaultForecast(capacity_value);
            break;
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', minHeight: 32 }}>
            {forecasts.map((forecast, index) => {
                const MAX_COLUMN_HEIGHT = 40;
                const DEFAULT_HEIGHT = 5;
                const calculatedHeight = Math.ceil(((forecast / 24) * MAX_COLUMN_HEIGHT) / (capacity_value || 100));
                const colHeight = calculatedHeight > MAX_COLUMN_HEIGHT ? MAX_COLUMN_HEIGHT : calculatedHeight;
                const height = status === ASSET_STATUS.ACTIVE ? colHeight : DEFAULT_HEIGHT;
                return (
                    <Box
                        key={index}
                        sx={{
                            height: `${height}px`,
                            width: '12px',
                            marginRight: 0.5,
                            borderRadius: '1px 1px 0px 0px',
                            background: getBarColor(status)
                        }}
                    ></Box>
                );
            })}
        </Box>
    );
};

export default ForecastBarGraph;
