import store from 'store';
import {
    setupCache,
    buildMemoryStorage,
    defaultKeyGenerator,
    type AxiosCacheInstance,
    defaultHeaderInterpreter
} from 'axios-cache-interceptor';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosStatic, AxiosInstance, AxiosRequestConfig, default as defaultAxios } from 'axios';

import { storage } from './config';
import { store as reduxStore } from '../stores';
import { commonOnLogout } from './user';

// const localCache = buildWebStorage(localStorage, 'axios-cache:');
const localCache = buildMemoryStorage();

export const axiosInstance = defaultAxios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

const axios = setupCache(
    // axios instance
    axiosInstance,

    // All options with their default values
    {
        // The storage to save the cache data. There are more available by default.
        //
        // https://axios-cache-interceptor.js.org/#/pages/storages
        storage: localCache,

        // The mechanism to generate a unique key for each request.
        //
        // https://axios-cache-interceptor.js.org/#/pages/request-id
        generateKey: defaultKeyGenerator,

        // The mechanism to interpret headers (when cache.interpretHeader is true).
        //
        // https://axios-cache-interceptor.js.org/#/pages/global-configuration?id=headerinterpreter
        headerInterpreter: defaultHeaderInterpreter,

        // The function that will receive debug information.
        // NOTE: For this to work, you need to enable development mode.
        //
        // https://axios-cache-interceptor.js.org/#/pages/development-mode
        // https://axios-cache-interceptor.js.org/#/pages/global-configuration?id=debug
        debug: undefined,
        ttl: 0
    }
);

const responseInterceptorIds: number[] = [];

export const setResponseInterceptors = (instance: AxiosInstance) => {
    const interceptorId = instance.interceptors.response.use(undefined, (error) => {
        const hasApiKeyHeader = error?.config?.headers?.ApiKey !== undefined;
        if (error?.response?.status === 401 && !hasApiKeyHeader) {
            commonOnLogout(reduxStore.dispatch);
        }

        return Promise.reject(error);
    });

    responseInterceptorIds.push(interceptorId);
};

export const resetInterceptors = (instance: AxiosInstance) => {
    for (const id of responseInterceptorIds) {
        instance.interceptors.response.eject(id);
    }
};

axios.interceptors.request.use((config) => {
    const token = store.get(storage.token);

    if (token) {
        config.headers = {
            ...(config.headers || {})
        };
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

export const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' }
    ): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
            params?: AxiosRequestConfig['params'];
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params }) => {
        try {
            const result = await axios({ url: baseUrl + url, method, data, params });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message
                }
            };
        }
    };

export default axios as AxiosCacheInstance & AxiosStatic;
