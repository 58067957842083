import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import * as yup from 'yup';
import { get } from 'lodash';
import { Controller, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import {
    Box,
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    Stack,
    TextField,
    Typography
} from '@mui/material';

import { MODAL_IDS } from '..';
import { useAppDispatch } from '../../stores';
import { DATE_SETTINGS } from '../../utils/config';
import { getFormattedDate } from '../../utils/helpers';
import { useFormLogic } from '../../hooks/useFormLogic';
import { FormModalActions } from '../../components/FormUtils';
import { ingestMeteoDataAssetAsync } from '../../stores/Assets';
import { GenerationAssetFE, IngestMeteoAssetFormData } from '../../interfaces/uiv2';
import { useGetMeteoSourcesQuery } from '../../stores/Meteo';

import style from './style.module.scss';
import common from '../../common.module.scss';
import { MeteoSources } from '../../interfaces/apiv2';

export const IngestAssetMeteoDataForm: FC<{ asset?: GenerationAssetFE }> = ({ asset }) => {
    const dispatch = useAppDispatch();
    const { t: generalTranslation } = useTranslation();

    const { data: sources = [] } = useGetMeteoSourcesQuery({});

    const chips = ['first_generation_timestamp', 'last_generation_timestamp'];

    const { t } = useTranslation('assets/generation');
    const schema = yup.object({
        start_date: yup.string().required(),
        end_date: yup.string().nullable(),
        sources: yup.array()
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue
    } = useFormLogic<IngestMeteoAssetFormData, IngestMeteoAssetFormData, any, any>({
        schema,
        data: null,
        defaultValues: {
            start_date: asset!.meteo_start_date || '',
            end_date: dayjs().startOf('date'),
            sources: []
        }
    });

    useEffect(() => {
        setValue(
            'sources',
            sources.filter((e) => e.active).map((e) => e.id)
        );
    }, [sources]);

    const handleCheck = (checkedId: string, fieldId: string) => {
        const values = getValues();

        const ids = get(values, fieldId);

        return ids?.includes(checkedId)
            ? ids?.filter((id: string) => id?.toLowerCase() !== checkedId?.toLowerCase())
            : [...(ids ?? []), checkedId];
    };

    const onSubmit = (form: IngestMeteoAssetFormData) => {
        const dates = {
            start_date: dayjs(form.start_date).utc().toISOString(),
            end_date: form.end_date ? dayjs(form.end_date).utc().toISOString() : undefined
        };

        dispatch(ingestMeteoDataAssetAsync({ id: asset!.id, ...dates, sources: form.sources }));
    };

    return (
        <Box className={style.container}>
            <Typography variant="h6">
                {t('ingestMeteoData')} <span className={style.assetName}>{asset?.name}</span>
            </Typography>
            <Box className={common.chipsBox}>
                {chips.map((item) => (
                    <Chip
                        key={item}
                        label={`${t(`form.${item}`)}: ${getFormattedDate({
                            value: asset?.[item as keyof typeof asset],
                            timezone: 'UTC',
                            type: DATE_SETTINGS.shortFormatHoursIncluded
                        })}`}
                        color={asset?.[item as keyof typeof asset] ? 'success' : 'error'}
                    />
                ))}
            </Box>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ p: 2 }}>
                <Box sx={{ mb: 2 }}>
                    <Controller
                        name="start_date"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" required shrink htmlFor={'startDate'}>
                                    {t('form.startDate')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="startDate"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.start_date && (
                                    <FormHelperText error>{t('form.validations.required')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Controller
                        name="end_date"
                        control={control}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" shrink htmlFor={'endDate'}>
                                    {t('form.endDate')}
                                </InputLabel>

                                <DateTimePicker
                                    {...field}
                                    ampm={false}
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params: any) => (
                                        <TextField
                                            id="endDate"
                                            variant="standard"
                                            color="primary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                                {errors.end_date && (
                                    <FormHelperText error>{t('form.validations.required')}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Controller
                        name="sources"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <>
                                <InputLabel variant="standard" shrink>
                                    {t('form.sources')}
                                </InputLabel>
                                <Stack direction="column" spacing={3} alignItems="baseline">
                                    {sources.map((item: MeteoSources) => (
                                        <FormControlLabel
                                            key={item.id}
                                            className={common.mt0}
                                            componentsProps={{ typography: { variant: 'small1' } }}
                                            control={
                                                <Checkbox
                                                    disabled={!item.active}
                                                    name={item.id}
                                                    checked={
                                                        !!field?.value?.find(
                                                            (i) => i?.toLowerCase() === item?.id.toLowerCase()
                                                        )
                                                    }
                                                    onChange={() => field.onChange(handleCheck(item.id, 'sources'))}
                                                />
                                            }
                                            label={item.name}
                                        />
                                    ))}
                                </Stack>

                                {(errors.sources as unknown as FieldError)?.message && (
                                    <FormHelperText error>
                                        {(errors.sources as unknown as FieldError)?.message}
                                    </FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Box>

                <Divider className={style.divider} />

                <FormModalActions
                    loading={false}
                    modalId={MODAL_IDS.INGEST_METEO_DATA_ASSET_MODAL}
                    cancelLabel={generalTranslation('nevermind')}
                    saveLabel={generalTranslation('submit')}
                />
            </Box>
        </Box>
    );
};

export default IngestAssetMeteoDataForm;
