import { Box } from '@mui/material';
import style from './style.module.scss';
import classNames from 'classnames';

export const Dot = ({ classname, isRed = false }: any) => {
    return <Box className={classNames(classname, style.dot, isRed && style.red)} />;
};

export const getUnitOfMeasure = (unit: string) => {
    switch (unit) {
        case 'MAW':
            return 'MW';
        default:
            return unit;
    }
};

export const getFontSize = (width: number) => {
    if (width <= 500) {
        return 14;
    } else if (width > 1100) {
        return 14;
    } else if (width > 500 && width < 720) {
        return 12;
    } else if (width > 720 && width < 800) {
        return 10;
    } else if (width >= 720 && width < 1100) {
        return 8;
    } else {
        return 14;
    }
};

export const getTooltipInfo = (key: string) => {
    switch (key) {
        case 'PreviousDay':
            return 'This accuracy is calculated over the past 24 hours, ending at midnight UTC. It includes real-time data or the latest official measurements up to that point, offering a snapshot of recent performance.';
        case 'PreviousWeek':
            return 'Reflecting precision achieved over the previous seven days, this accuracy metric culminates at midnight UTC on the last day. It provides a broader view of performance over a weekly timespan.;';
        case 'PreviousMonth':
            return 'This figure represents accuracy for the previous calendar month, concluding at midnight UTC on the last day of that month. It offers insights into the month-long consistency and reliability of the measurements.';
        case 'total':
            return 'Indicating overall precision, this metric is calculated from the start of data collection up to the current moment. It provides a comprehensive view of the long-term accuracy and reliability';
        default:
            return 'N/A';
    }
};

export const getPercentage = (total: number, value: number): string => {
    const percentage = ((value / total) * 100).toFixed(2);
    const [integerPart, decimalPart] = percentage.split('.');

    // Ensure the integer part has two digits
    const formattedIntegerPart = integerPart.padStart(2, '0');

    return `${formattedIntegerPart}.${decimalPart}`;
};
