import { isEqual } from 'lodash';
import { MdAdd } from 'react-icons/md';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useState } from 'react';

import {
    initialState,
    setGetParams,
    setSelectedRows,
    getClientsAsync,
    resetListClientsState
} from '../../../../stores/Clients';
import { ClientModal } from '../../../../modals/ClientModal';
import { getTableHeadCells } from './helpers';
import i18next from '../../../../utils/i18next';
import { ClientFE } from '../../../../interfaces/uiv2';
import { MODAL_IDS } from '../../../../modals';
import { ModalContext } from '../../../../hooks/modalContext';
import { useAppDispatch, useAppSelector } from '../../../../stores';
import { PageContainer } from '../../../../layout/PageContainer';
import { transformOrderByToSort } from '../../../../components/BaseTable/GenericTable/helpers';
import { GenericTable, TableSearch, GenericTableToolbar, TableToolbarActions } from '../../../../components/BaseTable';

const ListClients = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const { t: tList } = useTranslation('clients/list');

    const { success, error } = useAppSelector((state) => state.createClient);
    const getParams = useAppSelector((state) => state.listClients.getParams, isEqual);
    const {
        clientsFE: rows,
        pager,
        loading,
        selectedRows,
        success: successFetchingData
    } = useAppSelector((state) => state.listClients);

    const headCells = getTableHeadCells(tList, t);
    const { open, handleClickOpen } = useContext(ModalContext);
    const [activeRow, setActiveRow] = useState<ClientFE | null>(null);
    const resetInit = () => dispatch(getClientsAsync(initialState.getParams));
    const init = useCallback(() => dispatch(getClientsAsync(getParams)), [getParams]);

    useEffect(() => {
        init();
    }, [getParams]);

    useEffect(() => {
        return () => {
            dispatch(resetListClientsState());
        };
    }, []);

    useEffect(() => {
        if (!open[MODAL_IDS.CLIENT_MODAL]) {
            setActiveRow(null);

            if (success || error) {
                if (getParams.search) {
                    init();
                } else {
                    resetInit();
                }
            }
        }
    }, [open[MODAL_IDS.CLIENT_MODAL], success, error]);

    const handleAddNewClient = () => {
        i18next.loadNamespaces('clients/create').then(() => handleClickOpen(MODAL_IDS.CLIENT_MODAL));
    };

    const editClient = (row: ClientFE) => {
        i18next.loadNamespaces('clients/create').then(() => {
            setActiveRow(row);
            handleClickOpen(MODAL_IDS.CLIENT_MODAL);
        });
    };

    const handleEdiClient = (row: ClientFE) => {
        return () => editClient(row);
    };

    return (
        <>
            <PageContainer>
                <GenericTableToolbar tableTitle={tList('tableTitle')}>
                    <Box sx={{ display: selectedRows.length !== 0 ? 'initial' : 'none', mr: 2 }}>
                        <TableToolbarActions selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
                    </Box>

                    <TableSearch setGetParams={setGetParams} />

                    {selectedRows.length === 0 && (
                        <Button disableRipple variant="cta" onClick={handleAddNewClient} sx={{ ml: 1 }}>
                            <MdAdd />
                        </Button>
                    )}
                </GenericTableToolbar>

                <GenericTable
                    rows={rows}
                    pager={pager}
                    loading={loading}
                    success={successFetchingData}
                    isCheckboxEnabled
                    columns={headCells}
                    rowUniqueId={'id'}
                    selectedRows={selectedRows}
                    setGetParams={setGetParams}
                    handleEdit={handleEdiClient}
                    searchParam={getParams.search}
                    setSelectedRows={setSelectedRows}
                    storeSort={transformOrderByToSort(getParams.order_by)}
                />
            </PageContainer>

            <ClientModal client={activeRow} />
        </>
    );
};

export default ListClients;
