import { TLCluster, TLClusterFE, TLConsumer, TLConsumerFE, TLCirculation, TLCirculationFE } from './mock-data';

export const transformClustersDataToFE = (data: TLCluster[]): TLClusterFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};

export const transformConsumersDataToFE = (data: TLConsumer[]): TLConsumerFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};
export const transformCirculationsDataToFE = (data: TLCirculation[]): TLCirculationFE[] => {
    return data.map((e, i) => ({ index: i + 1, ...e }));
};
