import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Box, Tooltip, Typography } from '@mui/material';
import { DATE_SETTINGS } from '../../utils/config';
import { TableCellRenderer } from '../../interfaces/general';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../utils/table';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { DefaultSearchableCell, TableActions } from '../../components/BaseTable';
import {
    ClustersDataFE,
    ConsumersDataFE,
    GasConsumersDataFE,
    ProfilesDataFE,
    GasProfileDataFE,
    GasClustersDataFE
} from '../../interfaces/uiv2';

import { ReactComponent as ConsumersIcon } from '../../assets/icons/demand/consumers.svg';
import { ReactComponent as SegmentsIcon } from '../../assets/icons/demand/segments.svg';
import { ReactComponent as ProfilesIcon } from '../../assets/icons/demand/profiles.svg';
import { ReactComponent as ClustersIcon } from '../../assets/icons/demand/clusters.svg';
import { MdOutlineWarningAmber } from 'react-icons/md';

import style from './style.module.scss';
import common from '../../common.module.scss';
import classNames from 'classnames';

/* electricity header */
export const getConsumersHeadCells = (tList: TFunction): TableCellRenderer<ConsumersDataFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 70,
            label: tList('tableHead.type'),
            value: () => {
                return <ConsumersIcon />;
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.name}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },
        {
            id: 'location',
            label: tList('tableHead.location'),
            value: (row, searchValue, onClick, handlers) => {
                const value = row.location || '';
                const handler = handlers?.['location'];

                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={value}
                        className={style.active}
                        searchValue={searchValue}
                        onClick={callback}
                    />
                );
            },
            sort: true
        },
        {
            id: 'location_type',
            label: tList('tableHead.locationType'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.location_type} searchValue={searchValue} />;
            },
            sort: true
        },
        {
            id: 'industry',
            label: tList('tableHead.industry'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.industry} searchValue={searchValue} />;
            },
            sort: true
        },
        {
            id: 'profile',
            label: tList('tableHead.profile'),
            justifyContent: 'center',
            value: (row, searchValue, onClick, handlers) => {
                if (row.profile) {
                    const handler = handlers?.['profile'];
                    const callback = handler ? handler(row) : undefined;
                    return (
                        <DefaultSearchableCell
                            value={row.profile?.name}
                            className={style.active}
                            searchValue={searchValue}
                            isLink
                            linkUrl={callback()}
                        />
                    );
                } else {
                    return (
                        <Tooltip title={tList('missingProfile')} placement="top" enterDelay={25} enterTouchDelay={25}>
                            <Typography variant="small4" className={classNames(common.hover, common.warningIcon)}>
                                <MdOutlineWarningAmber className={common.warning} /> {row.profile_name}
                            </Typography>
                        </Tooltip>
                    );
                }
            },
            sort: false
        },
        {
            id: 'voltage_type',
            label: tList('tableHead.voltage'),
            justifyContent: 'center',
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.voltage_type} searchValue={searchValue} />;
            },
            sort: true
        },
        {
            id: 'dso',
            label: tList('tableHead.dso'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row?.dso?.name} searchValue={searchValue} />;
            },
            sort: true,
            sort_id: 'dso.name'
        },

        {
            id: 'created_at',
            label: tList('tableHead.createdAt'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.date_added;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'expiryDate',
            label: tList('tableHead.expiryDate'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.expiry_date;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'addToVPP',
                                    label: 'table.general.addToVPP',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }
                            ]}
                        />
                    </Box>
                );
            }
        }
    ];
};
export const getProfilesHeadCells = (tList: TFunction): TableCellRenderer<ProfilesDataFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 70,
            label: tList('tableHead.type'),
            value: () => {
                return <ProfilesIcon />;
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.name}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },

        {
            id: 'description',
            label: tList('tableHead.description'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.description} searchValue={searchValue} />;
            },
            sort: true
        },
        {
            id: 'profiling',
            label: tList('tableHead.profiling'),
            justifyContent: 'center',
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['formula'];
                const callback = handler ? handler(row) : undefined;

                return (
                    <DefaultSearchableCell
                        value={`Profile Formula ${row.index}`}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: false
        },
        {
            id: 'total_consumers',
            label: tList('tableHead.total_consumers'),
            justifyContent: 'center',
            value: (row) => {
                return <Typography variant="small4">{row.total_consumers}</Typography>;
            },
            sort: true
        },
        {
            id: 'created_at',
            label: tList('tableHead.createdAt'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.date_added;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'updated_at',
            label: tList('tableHead.updatedAt'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.updated_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: (row, searchValue, onClick, handlers) => {
                const importProfileFormula = handlers?.['importProfileFormula'];
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'addToVPP',
                                    label: 'table.general.addToVPP',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'importProfileFormula',
                                    label: 'table.general.importProfileFormula',
                                    disabled: false,
                                    callback: importProfileFormula
                                }
                            ]}
                        />
                    </Box>
                );
            }
        }
    ];
};
export const getClustersHeadCells = (tList: TFunction): TableCellRenderer<ClustersDataFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 70,
            label: tList('tableHead.type'),
            value: () => {
                return <ClustersIcon />;
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.name}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },

        {
            id: 'total_consumers',
            label: tList('tableHead.total_consumers'),
            justifyContent: 'center',
            value: (row) => {
                return <Typography variant="small4">{row.total_consumers}</Typography>;
            },
            sort: true
        },
        {
            id: 'created_at',
            label: tList('tableHead.createdAt'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.created_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'updated_at',
            label: tList('tableHead.updatedAt'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.updated_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'addToVPP',
                                    label: 'table.general.addToVPP',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }
                            ]}
                        />
                    </Box>
                );
            }
        }
    ];
};
export const getSegmentsHeadCells = (tList: TFunction): TableCellRenderer<ClustersDataFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 70,
            label: tList('tableHead.type'),
            value: () => {
                return <SegmentsIcon />;
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.name}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },

        {
            id: 'total_consumers',
            label: tList('tableHead.total_consumers'),
            justifyContent: 'center',
            value: (row) => {
                return <Typography variant="small4">{row.total_consumers}</Typography>;
            },
            sort: true
        },
        {
            id: 'created_at',
            label: tList('tableHead.createdAt'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.created_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'updated_at',
            label: tList('tableHead.updatedAt'),
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.updated_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={[
                                {
                                    id: 'addToVPP',
                                    label: 'table.general.addToVPP',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }
                            ]}
                        />
                    </Box>
                );
            }
        }
    ];
};
/* gas headers */
export const getGasConsumersHeadCells = (tList: TFunction): TableCellRenderer<GasConsumersDataFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.pod'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.name}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },
        {
            id: 'location',
            label: tList('tableHead.address'),
            value: (row, searchValue, onClick, handlers) => {
                const value = row.location || '';
                const handler = handlers?.['location'];

                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={value}
                        className={style.active}
                        searchValue={searchValue}
                        onClick={callback}
                    />
                );
            },
            sort: true
        },
        {
            id: 'type',
            label: tList('tableHead.type'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.type || ''} searchValue={searchValue} />;
            },
            sort: true
        },
        {
            id: 'profile',
            label: tList('tableHead.profile'),
            value: (row, searchValue) => {
                if (row.profile) {
                    return <DefaultSearchableCell value={row.profile?.name} searchValue={searchValue} />;
                } else {
                    return (
                        <div>
                            <Tooltip
                                title={tList('missingProfile')}
                                placement="top"
                                enterDelay={25}
                                enterTouchDelay={25}
                            >
                                <Typography variant="small4" className={classNames(common.hover, common.warningIcon)}>
                                    <MdOutlineWarningAmber className={common.warning} /> {row.profile_name}
                                </Typography>
                            </Tooltip>
                        </div>
                    );
                }
            },
            sort: false
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={
                                [
                                    /* {
                                    id: 'addToVPP',
                                    label: 'table.general.addToVPP',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'addToDashboard',
                                    label: 'table.general.addToDashboard',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }*/
                                ]
                            }
                        />
                    </Box>
                );
            }
        }
    ];
};
export const getGasProfilesHeadCells = (tList: TFunction): TableCellRenderer<GasProfileDataFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.name} searchValue={searchValue} />;
            },
            sort: true
        },
        {
            id: 'description',
            label: tList('tableHead.description'),
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.description} searchValue={searchValue} />;
            },
            sort: true
        },
        {
            id: 'consumers',
            label: tList('tableHead.totalConsumers'),
            justifyContent: 'center',
            value: (row, searchValue) => {
                return <DefaultSearchableCell value={row.total_consumers.toString()} searchValue={searchValue} />;
            },
            sort: true
        },
        {
            id: 'created_at',
            maxWidth: '120px',
            label: tList('tableHead.createdAt'),
            justifyContent: 'center',
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.created_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={
                                [
                                    /*TODO: add EDIT */
                                    /*{
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }*/
                                ]
                            }
                        />
                    </Box>
                );
            }
        }
    ];
};
export const getGasClustersHeadCells = (tList: TFunction): TableCellRenderer<GasClustersDataFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'name',
            label: tList('tableHead.name'),
            maxWidth: 250,
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;
                return (
                    <DefaultSearchableCell
                        value={row.name}
                        className={style.active}
                        searchValue={searchValue}
                        isLink
                        linkUrl={callback()}
                    />
                );
            },
            sort: true
        },

        {
            id: 'pods',
            label: tList('tableHead.pods'),
            justifyContent: 'start',
            value: () => {
                //TODO: use row total_consumers
                return <Typography variant="small4">94136</Typography>;
            },
            sort: true
        },
        {
            id: 'created_at',
            label: tList('tableHead.createdAt'),
            maxWidth: 200,
            justifyContent: 'center',
            sort: true,
            value: (row, searchValue) => {
                const rawValue = row.created_at;
                const date =
                    rawValue && dayjs(rawValue).isValid()
                        ? dayjs(rawValue).format(DATE_SETTINGS.shortFormat)
                        : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'actions',
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: tList('actions'),
            value: () => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions
                            className={style.editActionStyling}
                            actions={
                                [
                                    /*{
                                    id: 'addToVPP',
                                    label: 'table.general.addToVPP',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'addToDashboard',
                                    label: 'table.general.addToDashboard',
                                    disabled: false,
                                    callback: () => {}
                                },
                                {
                                    id: 'editInfo',
                                    label: 'table.general.editInfo',
                                    disabled: false,
                                    callback: () => {}
                                }*/
                                ]
                            }
                        />
                    </Box>
                );
            }
        }
    ];
};
