import classnames from 'classnames';
import { Layout } from 'react-grid-layout';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, SvgIcon, Typography } from '@mui/material';

import { NO_VALUE } from '../../utils/config';
import { getLinesColor } from '../../utils/dashboard';
import { CardHeaderActions } from './CardHeaderActions';
import { transformLatestForecastData } from './helpers';
import { DashboardItem, DASHBOARD_CHART_TYPE } from '../../interfaces/apiv2';
import { getDashboardItemType, getDemandIcon, getDemandRoute, getFormattedDate } from '../../utils/helpers';
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryLine,
    VictoryPie,
    VictoryTooltip,
    VictoryVoronoiContainer
} from 'victory';

import style from './style.module.scss';

export const DashboardDemandCard = ({
    l,
    item,
    changeHeight,
    deleteDashboardItem
}: {
    l: Layout;
    item: DashboardItem;
    changeHeight: (item: string) => void;
    deleteDashboardItem: (item: string) => void;
}) => {
    const { t } = useTranslation();
    const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });

    const graphRef = useCallback((node) => {
        if (node !== null) {
            setBoundingRect(node.getBoundingClientRect());
        }
    }, []);

    const activeKey = getDashboardItemType(item);
    const itemInfo = item[activeKey as keyof typeof item];

    const getItemPageUrl = () => {
        return getDemandRoute(activeKey, itemInfo.id);
    };

    if (!itemInfo) {
        return null;
    }

    return (
        <>
            <Box className={style.itemHeader}>
                <Box className={style.name}>
                    <SvgIcon component={getDemandIcon(activeKey)} />
                    <Link to={getItemPageUrl()}>
                        <Typography variant="h7" className="dragDisable">
                            {itemInfo.name}
                        </Typography>
                    </Link>
                </Box>
                <CardHeaderActions
                    l={l}
                    changeHeight={changeHeight}
                    deleteDashboardItem={deleteDashboardItem}
                    item={{ name: itemInfo.name, type: activeKey }}
                />
            </Box>

            <Box className={classnames(style.itemDetails, l.h === 1 ? style.noBorder : '')}>
                <Typography className={classnames(style.element, style.border)} variant="small4">
                    {t('tableHead.createdAt')}
                    <Typography variant="small3" className={style.black} sx={{ ml: 1.5 }}>
                        {getFormattedDate({ value: itemInfo.created_at, fallback: NO_VALUE })}
                    </Typography>
                </Typography>

                {l.w > 4 ? (
                    <Typography className={classnames(style.element, style.border)} variant="small4">
                        {t('tableHead.segments')}
                        <Typography variant="small3" className={style.black} sx={{ ml: 1.5 }}>
                            {289}
                        </Typography>
                    </Typography>
                ) : null}

                <Typography className={classnames(style.element, l.w > 4 ? style.border : '')} variant="small4">
                    {t('tableHead.updatedAt')}
                    <Typography variant="small3" className={style.black} sx={{ ml: 1.5 }}>
                        {getFormattedDate({ value: itemInfo.updated_at, fallback: NO_VALUE })}
                    </Typography>
                </Typography>

                {l.w > 4 ? (
                    <Typography className={style.element} variant="small4">
                        {t('tableHead.total_consumers')}
                        <Typography variant="small3" className={style.black} sx={{ ml: 1.5 }}>
                            {156}
                        </Typography>
                    </Typography>
                ) : null}
            </Box>

            {l.h === 3 && itemInfo.latest_forecast?.length ? (
                <Box className={style.itemChartContainer}>
                    <Box
                        className={classnames(
                            style.itemChart,
                            l.w === 12 && style.p12,
                            l.w === 8 && style.p12,
                            item.chart_type === DASHBOARD_CHART_TYPE[1] && style.lineChart
                        )}
                        ref={graphRef}
                    >
                        {item.chart_type === DASHBOARD_CHART_TYPE[0] ? (
                            <VictoryChart height={boundingRect.height} width={boundingRect.width}>
                                <VictoryBar
                                    style={{
                                        data: { fill: 'url(#barGradient)', strokeLinejoin: 'round' },
                                        labels: { fill: '#3745F2' }
                                    }}
                                    barRatio={1.2}
                                    cornerRadius={{ topLeft: 4, topRight: 4 }}
                                    x={'date'}
                                    y={'value'}
                                    data={transformLatestForecastData(itemInfo.latest_forecast)}
                                    labels={({ datum }) => datum.value}
                                />
                                <VictoryAxis
                                    offsetY={50}
                                    crossAxis={false}
                                    style={{
                                        axis: {
                                            stroke: '#D5E3FF'
                                        },
                                        tickLabels: {
                                            fill: '#828282'
                                        }
                                    }}
                                />
                            </VictoryChart>
                        ) : null}

                        {item.chart_type === DASHBOARD_CHART_TYPE[1] ? (
                            <VictoryChart
                                height={boundingRect.height}
                                width={boundingRect.width}
                                containerComponent={
                                    <VictoryVoronoiContainer
                                        labels={({ datum }) => datum.value}
                                        labelComponent={
                                            <VictoryTooltip
                                                cornerRadius={8}
                                                flyoutStyle={{
                                                    stroke: '#d5e3ff',
                                                    fill: 'white'
                                                }}
                                            />
                                        }
                                    />
                                }
                            >
                                <VictoryLine
                                    style={{
                                        data: { stroke: getLinesColor() },
                                        parent: { border: '1px solid #ccc' },
                                        labels: { fill: '#6a9bff' }
                                    }}
                                    /*domainPadding={{ x: [0, -20], y: 0 }}*/
                                    data={transformLatestForecastData(itemInfo.latest_forecast)}
                                    x={'date'}
                                    y={'value'}
                                />
                                <VictoryAxis
                                    style={{
                                        axis: {
                                            stroke: '#D5E3FF'
                                        },
                                        tickLabels: {
                                            fill: '#828282'
                                        }
                                    }}
                                />

                                <VictoryAxis
                                    dependentAxis
                                    orientation="right"
                                    style={{
                                        axis: {
                                            stroke: 'transparent'
                                        },
                                        tickLabels: {
                                            fill: '#828282'
                                        },
                                        grid: { stroke: '#D5E3FF', strokeWidth: 0.5 }
                                    }}
                                />
                            </VictoryChart>
                        ) : null}

                        {item.chart_type === DASHBOARD_CHART_TYPE[2] ? (
                            <VictoryPie
                                colorScale={['#88DEC1', '#9C80F1', '#80D5F8']}
                                data={[
                                    { x: 'LV', y: 38 },
                                    { x: 'HV', y: 12 },
                                    { x: 'MV', y: 50 }
                                ]}
                            />
                        ) : null}
                    </Box>
                </Box>
            ) : null}
        </>
    );
};
