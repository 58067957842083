import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, SvgIcon, Typography } from '@mui/material';
import { DashboardDemandStaticItem } from '../../interfaces/uiv2';

import { ReactComponent as ConsumersIcon } from '../../assets/icons/demand/consumers.svg';
import { ReactComponent as ClustersIcon } from '../../assets/icons/demand/clusters.svg';
import { ReactComponent as SegmentsIcon } from '../../assets/icons/demand/segments.svg';
import { ReactComponent as IndustrialIcon } from '../../assets/icons/demand/factory.svg';
import { ReactComponent as CommercialIcon } from '../../assets/icons/demand/commercial.svg';
import { ReactComponent as HouseholdIcon } from '../../assets/icons/demand/household.svg';

import style from './style.module.scss';

export const DashboardDemandStaticCard = ({ item }: { item: DashboardDemandStaticItem }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box className={style.itemHeader}>
                <Box className={classnames(style.name, style.black)}>
                    <Typography variant="h7">{t('portfolio')}</Typography>
                </Box>
            </Box>

            <Box className={style.itemStaticDetailsWrapper}>
                <Box className={style.itemStaticDetails}>
                    <Box>
                        <Box className={style.itemStaticBox}>
                            <SvgIcon component={ConsumersIcon} />
                            <Typography variant="h5">{item.totalConsumers}</Typography>
                        </Box>
                        <Typography variant="small1">{t('dashboardDemand.totalConsumers')}</Typography>
                    </Box>

                    <Box>
                        <Box className={style.itemStaticBox}>
                            <SvgIcon component={ClustersIcon} />
                            <Typography variant="h5">{item.consumerClusters}k</Typography>
                        </Box>
                        <Typography variant="small1">{t('dashboardDemand.consumerClusters')}</Typography>
                    </Box>

                    <Box>
                        <Box className={style.itemStaticBox}>
                            <SvgIcon component={SegmentsIcon} />
                            <Typography variant="h5">{item.businessSegments}</Typography>
                        </Box>
                        <Typography variant="small1">{t('dashboardDemand.businessSegments')}</Typography>
                    </Box>
                </Box>

                <Box className={style.itemStaticDetails}>
                    <Box>
                        <Box className={style.itemStaticBox}>
                            <IndustrialIcon />

                            <Box>
                                <Typography variant="h5">{item.industrialConsumers}</Typography>
                                {/* <Typography variant="small1">MW</Typography> */}
                            </Box>
                        </Box>
                        <Typography variant="small1">{t('dashboardDemand.industrialConsumers')}</Typography>
                    </Box>

                    <Box>
                        <Box className={style.itemStaticBox}>
                            <CommercialIcon />
                            <Box>
                                <Typography variant="h5">{item.commercialConsumers}k</Typography>
                                {/* <Typography variant="small1">k</Typography> */}
                            </Box>
                        </Box>
                        <Typography variant="small1">{t('dashboardDemand.commercialConsumers')}</Typography>
                    </Box>

                    <Box>
                        <Box className={style.itemStaticBox}>
                            <HouseholdIcon />

                            <Box>
                                <Typography variant="h5">{item.householdConsumers}</Typography>
                                {/* <Typography variant="small1">MW</Typography> */}
                            </Box>
                        </Box>
                        <Typography variant="small1">{t('dashboardDemand.householdConsumers')}</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
