import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ASSET_STATUS } from '../../interfaces/uiv2';
import { getBackgroundColorPerStatus } from '../../features/admin-overview/Users/ListUsers/helpers';
import { ChargingStationStatus } from '../../interfaces/apiv2';

type AssetStatusLabelProps = {
    status: ASSET_STATUS | ChargingStationStatus;
    variant?: string;
    customTextColorEnabled?: boolean;
};

const AssetStatusLabel = ({ status, variant, customTextColorEnabled = true }: AssetStatusLabelProps) => {
    const { t } = useTranslation('assets/generation');
    let text;
    //TODO: MAKE THE status to uppercase and same for ASSET_STATUS

    switch (status) {
        case ASSET_STATUS.PENDING:
        case ChargingStationStatus.PENDING:
            text = t('Pending');
            break;
        case ASSET_STATUS.COMPLETED:
            text = t('Completed');
            break;
        case ASSET_STATUS.ACTIVE:
        case ChargingStationStatus.ACTIVE:
            text = t('Active');
            break;
        case ASSET_STATUS.DISABLED:
        case ChargingStationStatus.DISABLED:
            text = t('Disabled');
            break;
        default:
            text = t('Pending');
            break;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    width: '8px',
                    height: '8px',
                    marginRight: '8px',
                    borderRadius: '50%',
                    background: getBackgroundColorPerStatus(status)
                }}
            ></Box>
            <Typography
                variant={(variant as any) || 'small4'}
                sx={{
                    color: customTextColorEnabled
                        ? status === ASSET_STATUS.PENDING
                            ? '#828282 !important'
                            : '#323232 !important'
                        : ''
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};

export default AssetStatusLabel;
