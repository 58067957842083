import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { GetParams } from '../../interfaces/general';
import ToastifyType from '../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { GenerationAssetFE } from '../../interfaces/uiv2';
import { transformGenerationAssetAPItoFE } from './helpers';
import { getQueryParamsFromObject } from '../../utils/table';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DefaultInitialState, DEFAULT_GET_PARAMS_VALUE } from '../../interfaces/redux';
import { ApiPaginationInfo, GenerationAsset, PaginatedApiResponse } from '../../interfaces/apiv2';

interface ListAssetsGetParams extends GetParams {
    tl?: boolean;
}

interface ListAssetsState extends DefaultInitialState {
    assets: GenerationAsset[];
    assetsFE: GenerationAssetFE[];
    searchParam: string | null;
    pager: ApiPaginationInfo | null;
    getParams: ListAssetsGetParams;
    selectedRows: GenerationAssetFE[];
}

const initialState: ListAssetsState = {
    loading: false,
    error: false,
    success: false,
    assets: [],
    assetsFE: [],
    searchParam: null,
    pager: null,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    selectedRows: []
};

export const getAssetsAsync = createAsyncThunk(
    'assets/list',
    async ({ ...rest }: ListAssetsGetParams, { rejectWithValue }) => {
        try {
            const endpoint = '/electricity/generation/assets';
            const queryParams = getQueryParamsFromObject({ ...rest });

            const response = await axios.get<PaginatedApiResponse<GenerationAsset>>(`${endpoint}${queryParams}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listAssets = createSlice({
    name: 'listAssets',
    initialState,
    reducers: {
        resetListAssetsState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setGetExplicitParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = payload;
        },
        setSelectedRows: (state, { payload }: PayloadAction<GenerationAssetFE[]>) => {
            state.selectedRows = payload;
        },
        updateById: (state, { payload }: PayloadAction<any>) => {
            // const [assetFE] = transformer([payload]);
            state.assets = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAssetsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getAssetsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.assets = items;
            } else {
                state.assets = [...current(state).assets, ...items];
            }
            state.assetsFE = transformGenerationAssetAPItoFE(current(state).assets);
        });
        builder.addCase(getAssetsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetListAssetsState, setGetParams, updateById, setSelectedRows, setGetExplicitParams } =
    listAssets.actions;

export default listAssets.reducer;
