import axios from '../../utils/axios';
import { DefaultInitialState } from '../../interfaces/redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PostDeliverForecastBody } from '../../interfaces/uiv2';
import { getQueryParamsFromObject } from '../../utils/table';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const deliverForecastAsync = createAsyncThunk(
    'assets/deliverForecastAsync',
    async (values: PostDeliverForecastBody, { rejectWithValue }) => {
        try {
            const query = getQueryParamsFromObject({ forecast_type: values.type });
            const response = await axios.post(`/developer/forecasts/deliver${query}`, {
                from_date: values.start_date,
                to_date: values.end_date,
                emails: values.emails,
                asset_id: values.assetId
            });

            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
    }
);

export const deliverForecast = createSlice({
    name: 'deliverForecast',
    initialState,
    reducers: {
        resetDeliverForecastState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(deliverForecastAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(deliverForecastAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(deliverForecastAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
    }
});

export const { resetDeliverForecastState } = deliverForecast.actions;

export default deliverForecast.reducer;
