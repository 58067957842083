import { TFunction } from 'i18next';
import * as yup from 'yup';
import { AccountSettingsFormPayload } from '../../interfaces/uiv2';
import { User } from '../../interfaces/apiv2';

export const getAccountSettingsSchema = (t: TFunction) => {
    return yup
        .object<Record<keyof AccountSettingsFormPayload, yup.AnySchema>>({
            first_name: yup.string().required(t('validations.required')),
            last_name: yup.string().required(t('validations.required')),
            email: yup.string().email().required(t('validations.required'))
        })
        .required();
};

export const transformUserAccountSettingsToFormData = (user: User) => {
    const mapped: any = {};
    type userKey = keyof User;
    Object.keys(user).forEach((property) => {
        if (user[property as userKey]) {
            mapped[property] = user[property as userKey];
        } else {
            mapped[property] = '';
        }
    });

    return mapped;
};
