import { DefaultEntityTimestamps, ProjectsData } from '.';

export interface Dso extends DefaultEntityTimestamps {
    name: string;
    id: string;
}

// extend DefaultEntity for the real one
export interface Consumer extends DefaultEntityTimestamps {
    id: string;
    name: string;
    type: string;
    industry: string;
    voltage_type: string;
    expiry_date: string;
    location_type: string;
    dso: Dso;
    profile: Profile;
    profile_name?: string;
    location: string;
    longitude: number;
    latitude: number;
    external_id: string;
    date_added?: string;
    //
    consumer_forecast: number;
    country?: string;
    dso_name?: string;
}

export interface Profile extends DefaultEntityTimestamps {
    dso: Dso;
    country: string;
    profiling: string;
    // actual data
    description: string;
    dso_id: string;
    external_id: string;
    name: string;
    id: string;
    project_id: string;
    date_added?: string;
    total_consumers: number;
    project: ProjectsData;
    //TODO: TO BE DEFINED WHEN IMPLEMENTED
    type?: string;
}

export interface Cluster extends DefaultEntityTimestamps {
    total_consumers: number;
    id: string;
    name: string;
    location: string;
    country?: string;
    latitude: number;
    longitude: number;
    type: 'Segment' | 'Cluster';
    voltage_type: string;
}

export interface PostGasProfile {
    name: string;
    description: string;
    external_id: null | string;
}

export interface GasProfile extends DefaultEntityTimestamps {
    name: string;
    description: string;
    external_id: null | string;
    dso_id: string;
    date_added?: string;
    last_updated?: string;
    project_id: string;
    id: string;
    total_consumers: number;
    dso: GasDSO;
    project: ProjectsData;
}

enum GasConsumerType {
    CAS = 'Casnic',
    NCAS = 'Non-Casnic',
    ASOS = 'Asociatii'
}

export interface GasConsumer extends DefaultEntityTimestamps {
    name: string;
    location?: string;
    latitude?: number;
    longitude?: number;
    country?: string;
    location_type?: string;
    external_id: string;
    type: GasConsumerType;
    expiry_date?: string;
    date_added?: string;
    id: string;
    dso: GasDSO;
    profile: GasProfile;
    profile_name?: string;
    latest_forecasts?: number[];
}

export interface GasDSO extends DefaultEntityTimestamps {
    name: string;
    id: string;
    project: ProjectsData;
}

export interface GasCluster extends DefaultEntityTimestamps {
    name: string;
    external_id: string;
    location: string;
    latitude: number;
    longitude: number;
    country: string;
    id: string;
    total_consumers: number;
}
