export type Country = 'RO' | 'BG' | 'MD' | 'GR' | 'AT' | 'DE';

export const defaultLegend = {
    B01: 'Biomass',
    B02: 'Fossil Brown coal/Lignite',
    B03: 'Fossil Coal-derived gas',
    B04: 'Fossil Gas',
    B05: 'Fossil Hard coal',
    B06: 'Fossil Oil',
    B07: 'Fossil Oil shale',
    B08: 'Fossil Peat',
    B09: 'Geothermal',
    B10: 'Hydro Pumped Storage',
    B11: 'Hydro Run-of-river and poundage',
    B12: 'Hydro Water Reservoir',
    B13: 'Marine',
    B14: 'Nuclear',
    B15: 'Other renewable',
    B16: 'Solar',
    B17: 'Waste',
    B18: 'Wind Offshore',
    B19: 'Wind Onshore',
    B20: 'Other'
};

export const colorScaleCodes: { [key: string]: string } = {
    B01: '#8F993E', // earthy green //Warm Brown: '#A67C52'
    B02: '#8B4513', // deep brown
    B03: '#404040', // deep charcoal
    B04: '#004080', // deep blue
    B05: '#333333', // coal black
    B06: '#1C1C1C', // deep oil black
    B07: '#6B4226', // shale brown
    B08: '#8B735B', // peat brown
    B09: '#4CAF50', // geo green
    B10: '#2196F3', // hidro blue
    B11: '#4CAF50', // riverine green
    B12: '#2196F3', // reservoir blue
    B13: '#00796B', // marine blue
    B14: '#33CC33', // Mutated Green
    B15: '#FFA500', // Screamin' Green ?
    B16: '#FFD600', // solar yellow
    B17: '#4CAF50', // waste green
    B18: '#00BCD4', // offshore turqoise
    B19: '#4CAF50', // onshore green
    B20: '#757575' // neutral grey
};

// Function to convert unit abbreviation
export const getUnitOfMeasure = (unit: string): string => (unit === 'MAW' ? 'MW' : unit);

// Function to determine font size based on width
export const getFontSize = (width: number): number => {
    if (width <= 500) return 14;
    if (width >= 1100) return 14;
    if (width < 720) return 12;
    if (width < 800) return 10;
    return 8;
};

// Function to calculate percentage
export const getPercentage = (total: number, value: number): string => {
    const percentage = ((value / total) * 100).toFixed(2);
    return percentage.padStart(6, '0');
};
