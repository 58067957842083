import { FC } from 'react';
import { DialogContent } from '@mui/material';

import TrainAssetForm from './TrainAssetForm';
import { MODAL_IDS } from '..';
import Cleanup from '../../components/Cleanup/Cleanup';
import { GenerationAssetFE } from '../../interfaces/uiv2';
import { resetTrainAssetState } from '../../stores/Assets';
import { useAppDispatch, useAppSelector } from '../../stores';
import { FormModal } from '../../components/BaseModal/FormModal';
import { FormErrorStep, FormSuccessStep } from '../../components/FormUtils';

import style from './style.module.scss';

export const TrainAssetModal: FC<{ asset?: GenerationAssetFE }> = ({ asset }) => {
    const dispatch = useAppDispatch();

    const { success, error } = useAppSelector((state) => state.trainAsset);

    const handleCleanup = () => {
        dispatch(resetTrainAssetState());
    };

    return (
        <FormModal id={MODAL_IDS.TRAIN_ASSET_MODAL} fullWidth={false} maxWidth="lg">
            <DialogContent className={style.modalContainer}>
                {!(success || error) ? <TrainAssetForm asset={asset} /> : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep /> : null}
                <Cleanup cb={handleCleanup} />
            </DialogContent>
        </FormModal>
    );
};
