import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Badge, Box, ClickAwayListener, Tooltip, Typography } from '@mui/material';

import { MenuItems } from '../helpers';
import { MODAL_IDS } from '../../../modals';
import { SwitchModule } from './SwitchModule';
import { APP_MODULES } from '../../../interfaces/uiv2';
import { ModalContext } from '../../../hooks/modalContext';
import { ProjectsData, User } from '../../../interfaces/apiv2';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { getNotificationsAcknowledgedListAsync } from '../../../stores/Notifications';
import { ReactComponent as DotsMenuIcon } from '../../../assets/icons/navigation-items/dots-menu.svg';

import style from '../style.module.scss';

export const DotsMenu = ({
    user,
    menuItems,
    activeModule,
    projects
}: {
    user: User;
    menuItems: MenuItems[];
    activeModule: APP_MODULES;
    projects: ProjectsData[];
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const { handleClickOpen } = useContext(ModalContext);
    const [activeProject, setActiveProject] = useState<ProjectsData | null>(null);

    const { markNotifications, notificationsAcknowledged } = useAppSelector((state) => state.listNotifications);

    useEffect(() => {
        dispatch(getNotificationsAcknowledgedListAsync());
    }, [markNotifications]);

    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipToggle = () => {
        setOpen(!open);
    };

    const goTo = ({ disabled, projectMetadata, path }: MenuItems) => {
        if (disabled) {
            return;
        }

        if (projectMetadata) {
            const wrongProject =
                projectMetadata.module !== activeProject?.module || projectMetadata.type !== activeProject?.type;

            const matchyProjects = projects.filter(
                (project) => project.module === projectMetadata.module && project.type === projectMetadata.type
            );

            if (activeProject && !wrongProject) {
                navigate(path.replace(':projectId', activeProject.id));
                return;
            }

            if (matchyProjects.length === 1) {
                navigate(path.replace(':projectId', matchyProjects[0].id));
                setActiveProject(matchyProjects[0]);
            }

            if (matchyProjects.length > 1) {
                if (!activeProject || wrongProject) {
                    handleClickOpen(MODAL_IDS.DEMAND_MODAL, {
                        path,
                        projects: matchyProjects,
                        projectMetadata,
                        callback: (project: ProjectsData) => {
                            setActiveProject(project);
                        }
                    });
                    return;
                }
            }

            return;
        }

        navigate(path);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Box className={style.wrapper_dots}>
                <Tooltip
                    PopperProps={{
                        disablePortal: true
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    arrow
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        <Box className={style.tooltipContainer}>
                            {menuItems.map((menuItem) => {
                                return (
                                    <Box
                                        className={classNames(style.row, menuItem.disabled && style.disabled)}
                                        key={menuItem.label}
                                        onClick={handleTooltipClose}
                                    >
                                        <Box onClick={() => goTo(menuItem)} className={style.dotsMenuBox}>
                                            <Box className={style.icon}>{menuItem.icon}</Box>

                                            <Typography className={style.label} variant="small4">
                                                {t(menuItem.label)}
                                            </Typography>
                                            {menuItem.label === 'header.notifications' && (
                                                <Badge
                                                    color="primary"
                                                    className={style.badge}
                                                    badgeContent={notificationsAcknowledged}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                );
                            })}
                            <SwitchModule
                                user={user}
                                handleTooltipClose={handleTooltipClose}
                                activeModule={activeModule}
                            />
                        </Box>
                    }
                >
                    <Box className={classNames(style.tooltip, open && style.open)} onClick={handleTooltipToggle}>
                        <DotsMenuIcon className={style.menuBtn} />
                    </Box>
                </Tooltip>
            </Box>
        </ClickAwayListener>
    );
};
