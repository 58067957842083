import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Responsive, WidthProvider } from 'react-grid-layout';

import {
    mockedClustersData,
    mockedProfilesData,
    mockedSegmentsData,
    mockedConsumersData
} from '../../stores/Demand/mocked-data';
import Loading from '../../layout/Loading';
import { DashboardItem } from '../../interfaces/apiv2';
import { PageContainer } from '../../layout/PageContainer';
import { DashboardDemandStaticItem } from '../../interfaces/uiv2';
import { DashboardDemandCard } from '../../features/generation-dashboard-view/DashboardDemandCard';
import { DashboardDemandStaticCard } from '../../features/generation-dashboard-view/DashboardDemandStaticCard';

import style from '../../features/generation-dashboard-view/style.module.scss';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DemandDashboard = () => {
    const { t } = useTranslation();

    const dashboard = {
        layout: {
            lg: [
                {
                    h: 3,
                    i: 'static',
                    w: 4,
                    x: 0,
                    y: 0,
                    static: true
                },
                {
                    h: 3,
                    i: mockedProfilesData[0].id,
                    w: 4,
                    x: 4,
                    y: 0
                },
                {
                    h: 3,
                    i: mockedSegmentsData[0].id,
                    w: 4,
                    x: 8,
                    y: 0
                },
                {
                    h: 3,
                    i: mockedConsumersData[0].id,
                    w: 4,
                    x: 0,
                    y: 3
                },
                {
                    h: 3,
                    i: mockedClustersData[0].id,
                    w: 8,
                    x: 4,
                    y: 3
                }
            ]
        }
    };

    const items = [
        {
            id: mockedProfilesData[0].id,
            dashboard_id: 'demand-dashboard-uuid',
            size: 1,
            chart_type: 'Bar',
            profile_id: mockedProfilesData[0].id,
            profile: mockedProfilesData[0]
        },
        {
            id: mockedSegmentsData[0].id,
            dashboard_id: 'demand-dashboard-uuid',
            size: 1,
            chart_type: 'Line',
            segment_id: mockedSegmentsData[0].id,
            segment: mockedSegmentsData[0]
        },
        {
            id: mockedConsumersData[0].id,
            dashboard_id: 'demand-dashboard-uuid',
            size: 1,
            chart_type: 'Pie',
            consumer_id: mockedConsumersData[0].id,
            consumer: mockedConsumersData[0]
        },
        {
            id: mockedClustersData[0].id,
            dashboard_id: 'demand-dashboard-uuid',
            size: 2,
            chart_type: 'Line',
            cluster_id: mockedClustersData[0].id,
            cluster: mockedClustersData[0]
        }
    ] as unknown as DashboardItem[];

    const staticCard = {
        totalConsumers: 289,
        consumerClusters: 187,
        businessSegments: 891,

        industrialConsumers: 267,
        commercialConsumers: 154,
        householdConsumers: 878
    } as DashboardDemandStaticItem;

    const isLoading = false;

    const generateDOM = useCallback(() => {
        return _.map(dashboard?.layout?.lg || [], function (l) {
            if (l.i === 'static' && staticCard) {
                return (
                    <Box key={l.i} className={style.itemWrapper}>
                        <DashboardDemandStaticCard item={staticCard} />
                    </Box>
                );
            }
            const item = items.find((m) => m.id === l.i);

            if (!item) {
                return null;
            }

            return (
                <Box key={l.i} className={style.itemWrapper}>
                    <DashboardDemandCard l={l} item={item} changeHeight={() => {}} deleteDashboardItem={() => {}} />
                </Box>
            );
        });
    }, [dashboard, items]);

    const onLayoutChange = () => {};
    const onBreakpointChange = () => {};

    return (
        <PageContainer className={[style.container]}>
            <svg style={{ height: 0, width: 0, position: 'absolute', top: 0, left: 0 }}>
                <defs>
                    <linearGradient id="barGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#D5E3FF" />
                        <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                </defs>
            </svg>
            <Box className={style.header}>
                <Typography variant="h6" component="span">
                    {t('dashboard')}
                </Typography>
                <Typography variant="verySmallBold" component="span" className={style.beta}>
                    {t('beta')}
                </Typography>
            </Box>
            {isLoading ? (
                <Loading />
            ) : (
                <Box className={style.grid}>
                    {dashboard?.layout?.lg?.length ? (
                        <ResponsiveReactGridLayout
                            draggableCancel=".dragDisable"
                            rowHeight={110}
                            isBounded={true}
                            margin={[20, 20]}
                            resizeHandles={[]}
                            isResizable={false}
                            allowOverlap={false}
                            useCSSTransforms={true}
                            preventCollision={false}
                            compactType={null}
                            measureBeforeMount={false}
                            layouts={dashboard?.layout}
                            onLayoutChange={onLayoutChange}
                            onBreakpointChange={onBreakpointChange}
                            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                            breakpoints={{ lg: 1800, md: 1800, sm: 1800, xs: 1800, xxs: 1800 }}
                        >
                            {generateDOM()}
                        </ResponsiveReactGridLayout>
                    ) : null}
                </Box>
            )}
        </PageContainer>
    );
};

export default DemandDashboard;
