import { CSSProperties, FC } from 'react';
import style from './style.module.scss';

import { icons } from './helpers';
import { toFixed } from '../../utils/helpers';
import { ReactComponent as ChargingStationsIcon } from '../../assets/icons/navigation-items/menu-item-charging-stations.svg';
interface TooltipProps {
    properties: any;
}

const Tooltip: FC<TooltipProps> = ({ properties }) => {
    const { name, meta, entity_type, latitude, longitude, client_name } = properties.data;
    const CurrentIcon = entity_type ? icons[entity_type] : ChargingStationsIcon;

    return (
        <div className={style.tooltip}>
            <div className={style.header}>
                <CurrentIcon />
                {name !== undefined ? (
                    <span className={style.id}>ID {name.split('#')[1]}</span>
                ) : (
                    <span className={style.id}>ID {(latitude * longitude).toFixed()}</span>
                )}

                <span className={style.charge}>{client_name}</span>
            </div>
            <div className={style.content}>
                {name !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Name</span>
                        <span className={style.info}>{name}</span>
                    </div>
                )}
                {meta?.azimuth !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Azimuth</span>
                        <span className={style.info}>{meta?.azimuth}</span>
                    </div>
                )}
                {meta?.installation_type !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Installation type</span>
                        <span className={style.info}>{meta?.installation_type}</span>
                    </div>
                )}
                {meta?.hub_height !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Hub height</span>
                        <span className={style.info}>{meta?.hub_height} (m)</span>
                    </div>
                )}
                {meta?.turbines !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Turbines</span>
                        <span className={style.info}>{meta?.turbines}</span>
                    </div>
                )}
                {meta?.wind_tracking !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Wind tracking</span>
                        <span className={style.info}>{meta?.wind_tracking ? 'Yes' : 'No'}</span>
                    </div>
                )}
                {meta?.solar_tracking !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Solar tracking</span>
                        <span className={style.info}>{meta?.solar_tracking ? 'Yes' : 'No'}</span>
                    </div>
                )}
                {meta?.tilt_angle !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Tilt angle</span>
                        <span className={style.info}>{meta?.tilt_angle}°</span>
                    </div>
                )}
                {meta?.minute_interval !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Minute interval</span>
                        <span className={style.info}>{meta?.minute_interval}</span>
                    </div>
                )}
                {properties.data?.location_type !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Location type</span>
                        <span className={style.info}>{properties.data?.location_type}</span>
                    </div>
                )}
                {properties.data?.industry !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Industry</span>
                        <span className={style.info}>{properties.data?.industry}</span>
                    </div>
                )}
                {properties.data?.voltage_type !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Voltage type</span>
                        <span className={style.info}>{properties.data?.voltage_type}</span>
                    </div>
                )}
                {properties.data?.consumer_forecast !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Consumer forecast</span>
                        <span className={style.info}>{toFixed(properties.data?.consumer_forecast / 1000, 2)} kWh</span>
                    </div>
                )}
                {properties.data?.chargingSpeed !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Charging speed</span>
                        <span className={style.info}>{properties.data?.chargingSpeed}</span>
                    </div>
                )}
                {properties.data?.lastCharge !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Last charge</span>
                        <span className={style.info}>{properties.data?.lastCharge}</span>
                    </div>
                )}
                {properties.data?.nextPlannedCharge !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Next planned charge</span>
                        <span className={style.info}>{properties.data?.nextPlannedCharge}</span>
                    </div>
                )}
                {properties.data?.evCharging !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>EV charging</span>
                        <span className={style.info}>{properties.data?.evCharging}</span>
                    </div>
                )}
                {properties.data?.comissionDate !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Comission date</span>
                        <span className={style.info}>{properties.data?.comissionDate}</span>
                    </div>
                )}
                {properties.data?.totalCharge !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Total charge</span>
                        <span className={style.info}>{properties.data?.totalCharge}</span>
                    </div>
                )}
                {properties.data?.nextMaintenance !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Next maintenance</span>
                        <span className={style.info}>{properties.data?.nextMaintenance}</span>
                    </div>
                )}
                {properties.data?.assignedTeam !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Assigned team</span>
                        <span className={style.info}>{properties.data?.assignedTeam}</span>
                    </div>
                )}
                {properties.data?.storage !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Storage</span>
                        <span className={style.info}>{properties.data?.storage} kW </span>
                    </div>
                )}
                {properties.data?.storagePercentage !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Storage Percentage</span>
                        <span className={style.info}>{properties.data?.storagePercentage}%</span>
                    </div>
                )}
                {properties.data?.accuracy !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Accuracy</span>
                        <span className={style.info}>{properties.data?.accuracy * 100}%</span>
                    </div>
                )}
                {properties.data?.demand !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Demand</span>
                        <span className={style.info}>{properties.data?.demand}</span>
                    </div>
                )}
                {properties.data?.total_capacity !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Total Capacity</span>
                        <span className={style.info}>{properties.data?.total_capacity} kW</span>
                    </div>
                )}
                {properties.data?.stats?.avg_cp_connectivity_ratio !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Average conneciton ratio</span>
                        <span className={style.info}>{properties.data?.stats?.avg_cp_connectivity_ratio * 100}%</span>
                    </div>
                )}
                {properties.data?.stats?.count_cp_all !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Charging points (All)</span>
                        <span className={style.info}>{properties.data?.stats?.count_cp_all}</span>
                    </div>
                )}
                {properties.data?.stats?.count_cp_ac !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Charging points (AC)</span>
                        <span className={style.info}>{properties.data?.stats?.count_cp_ac}</span>
                    </div>
                )}
                {properties.data?.stats?.count_cp_dc !== undefined && (
                    <div className={style.item}>
                        <span className={style.itemTitle}>Charging points (DC)</span>
                        <span className={style.info}>{properties.data?.stats?.count_cp_dc}</span>
                    </div>
                )}
            </div>
            {properties.data.entity_type === 'ChargingStation' && (
                <div className={style.footer}>
                    <Semicircle
                        value={properties.data?.financialIndicator}
                        color="#6A9BFF"
                        label="Financial indicator"
                    />
                    <Semicircle
                        value={properties.data?.operationalIndicator}
                        color="#6A9BFF"
                        label="Operational indicator"
                    />
                    <Semicircle value={properties.data?.esg} color="#6FD8B2" label="ESG" />
                </div>
            )}
        </div>
    );
};

const Semicircle: FC<{ value: number; color: string; label: string }> = ({ value, color, label }) => (
    <div className={style.semicircleContainer}>
        <div
            className={style.semicircle}
            role="progressbar"
            style={{ '--value': value, '--primary': color } as CSSProperties}
        />
        <span>{label}</span>
    </div>
);

export default Tooltip;
