import { TFunction } from 'i18next';
import Flag from 'react-world-flags';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { Box, IconButton, Typography } from '@mui/material';

import { Theme } from '../../../containers/Theme';
import { hasAccessTo } from '../../../utils/user';
import { getAssetIcon } from '../../../utils/getAssetIcon';
import { TableCellRenderer } from '../../../interfaces/general';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../../utils/table';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ASSET_STATUS, GenerationAssetFE, UserFE } from '../../../interfaces/uiv2';
import { ReactComponent as LocationSvg } from '../../../assets/icons/location.svg';
import { DefaultSearchableCell, TableActions } from '../../../components/BaseTable';
import ForecastBarGraph from '../../../components/ForecastBarGraph/ForecastBarGraph';
import AssetStatusLabel from '../../../components/AssetStatusLabel/AssetStatusLabel';
import { ReactComponent as TooltipInfoSvg } from '../../../assets/icons/tooltip-info.svg';
import { CircleProgressBar } from '../../../components/BaseTable/CustomCells/CircleProgressBar';
import { getFormattedCapacityValue, getFormattedDate } from '../../../utils/helpers';

import style from './style.module.scss';
import common from '../../../common.module.scss';

type IParams = {
    [key in COLUMN_ID]?: false;
};

export const filterTableHeadCells = (params: IParams, headCells: TableCellRenderer<GenerationAssetFE>[]) => {
    const hiddenColumns = Object.keys(params);
    return headCells.filter((cell) => {
        return !hiddenColumns.includes(cell.id);
    });
};

enum COLUMN_ID {
    INDEX = 'index',
    TYPE = 'type',
    NAME = 'name',
    COUNTRY = 'country',
    LOCATION = 'location',
    TOTAL_CAPACITY = 'capacity_value',
    LATEST_FORECAST = 'latest_forecast',
    STATUS = 'status',
    EXPIRY_DATE = 'expiryDate',
    ACCURACY = 'accuracy',
    ACTIONS = 'actions',
    TECH_DETAILS = 'tech_details',
    CLIENT_NAME = 'client_name',
    ASSET_TRAINED = 'asset_trained'
}

interface AssetListHeadCellsProps {
    tList: TFunction;
    t: TFunction;
    isAdmin?: boolean;
    user?: UserFE;
}
export const getTableHeadCells = ({
    tList,
    t,
    isAdmin,
    user
}: AssetListHeadCellsProps): TableCellRenderer<GenerationAssetFE>[] => {
    let defaultColumns: TableCellRenderer<GenerationAssetFE>[] = [
        {
            id: COLUMN_ID.INDEX,
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: COLUMN_ID.TYPE,
            maxWidth: 75,
            label: tList('tableHead.type'),
            value: (row) => {
                return getAssetIcon(row.type);
            },
            sort: false
        },
        {
            id: COLUMN_ID.NAME,
            label: tList('tableHead.name'),
            sort: true,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.name;
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;

                return <DefaultSearchableCell value={value} searchValue={searchValue} isLink linkUrl={callback()} />;
            }
        },
        {
            id: COLUMN_ID.COUNTRY,
            maxWidth: '10%',
            justifyContent: 'center',
            label: tList('tableHead.country'),
            value: (row, searchValue) => {
                const country = row.country_code?.toUpperCase() || row.country?.toUpperCase() || '';
                return (
                    <DefaultSearchableCell value={country} searchValue={searchValue}>
                        <Flag
                            height="24"
                            width="24"
                            code={country}
                            style={{ marginRight: '8px' }}
                            className="flagIcon"
                        />
                    </DefaultSearchableCell>
                );
            },
            sort: false
        },
        {
            id: COLUMN_ID.LOCATION,
            label: tList('tableHead.location'),
            sort: false,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.location || '';
                const handler = handlers?.['location'];

                const callback = handler ? handler(row) : undefined;

                return (
                    <>
                        <DefaultSearchableCell
                            value={value}
                            searchValue={searchValue}
                            onClick={callback}
                            hyperlinkColor={`${Theme.palette.primary.main} !important`}
                        >
                            <Box>
                                <LocationSvg className={common.locationSVG} />
                            </Box>
                        </DefaultSearchableCell>
                    </>
                );
            }
        },
        {
            id: COLUMN_ID.TOTAL_CAPACITY,
            label: tList('tableHead.totalCapacity'),
            justifyContent: 'center',
            sort: false,
            value: (row, searchValue) => {
                const value =
                    row.type === 'Prosumer'
                        ? `${row.total_capacity} kW`
                        : getFormattedCapacityValue(row.capacity_value);
                return <DefaultSearchableCell value={value} searchValue={searchValue} />;
            }
        },
        {
            id: COLUMN_ID.LATEST_FORECAST,
            label: tList('tableHead.latestForecasts'),
            sort: false,
            value: (row) => <ForecastBarGraph asset={row} />
        },
        {
            id: COLUMN_ID.STATUS,
            maxWidth: '10%',
            justifyContent: 'start',
            label: tList('tableHead.status'),
            sort: true,
            value: (row) => <AssetStatusLabel status={row.status} />
        },
        {
            id: COLUMN_ID.EXPIRY_DATE,
            label: tList('tableHead.expiryDate'),
            sort: false,
            justifyContent: 'center',
            value: (row, searchValue) => {
                const value = getFormattedDate({ value: row.expiry_date });

                if (value) {
                    return <DefaultSearchableCell value={value} searchValue={searchValue} />;
                }

                return <Typography variant="small4">{DEFAULT_EMPTY_CELL_VALUE}</Typography>;
            }
        },
        {
            id: COLUMN_ID.ACCURACY,
            label: tList('tableHead.accuracy'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const value = (+row.accuracy || 0) * 100;
                return <CircleProgressBar value={value} />;
            }
        },
        {
            id: COLUMN_ID.ACTIONS,
            sort: false,
            maxWidth: 70,
            justifyContent: 'center',
            label: t('actions'),
            value: (row, searchValue, onClick, handlers) => {
                const edit = handlers?.['edit'];
                const editPermissions = handlers?.['editPermissions'];
                const editDelivery = handlers?.['editDelivery'];
                const trainAsset = handlers?.['trainAsset'];
                const ingestAssetMeteoData = handlers?.['ingestAssetMeteoData'];
                const runForecast = handlers?.['runForecast'];
                const deliverForecast = handlers?.['deliverForecast'];
                const addToVpp = handlers?.['addToVpp'];
                const isTrainingAvailable = user && hasAccessTo(user, ['Admin.TrainingAssets.CREATE']);
                const isCreatingForecastAvailable = user && hasAccessTo(user, ['Admin.TrainingAssets.CREATE']);
                const hasDeliverForecast = user && user.superadmin;

                const actions = isAdmin
                    ? [
                          {
                              id: 'editInfo',
                              label: 'table.general.editInfo',
                              disabled: false,
                              link: true,
                              url: edit?.(row)(),
                              callback: () => edit
                          },
                          {
                              id: 'editPermissions',
                              label: 'table.general.editPermissions',
                              disabled: false,
                              callback: () => {
                                  editPermissions(row)();
                              }
                          },
                          {
                              id: 'editDelivery',
                              label: 'table.general.editDelivery',
                              disabled: false,
                              link: true,
                              url: editDelivery?.(row)(),
                              callback: () => edit
                          }
                      ]
                    : [];
                if (isTrainingAvailable) {
                    actions.push(
                        ...[
                            {
                                id: 'ingestMeteoData',
                                label: 'table.general.ingestMeteoData',
                                disabled: row.status === ASSET_STATUS.DISABLED,
                                callback: () => {
                                    ingestAssetMeteoData(row)();
                                }
                            },
                            {
                                id: 'train',
                                label: 'table.general.train',
                                disabled: row.status === ASSET_STATUS.DISABLED,
                                callback: () => {
                                    trainAsset(row)();
                                }
                            }
                        ]
                    );
                }
                if (isCreatingForecastAvailable) {
                    actions.push({
                        id: 'runForecast',
                        label: 'table.general.runForecast',
                        disabled: row.status === ASSET_STATUS.DISABLED,
                        callback: () => {
                            runForecast(row)();
                        }
                    });
                }
                if (hasDeliverForecast) {
                    actions.push({
                        id: 'deliverForecast',
                        label: 'table.general.deliverForecast',
                        disabled: row.status === ASSET_STATUS.DISABLED,
                        callback: () => {
                            deliverForecast(row)();
                        }
                    });
                }
                if (addToVpp) {
                    actions.push({
                        id: 'addToVPP',
                        label: 'table.general.addToVPP',
                        disabled: false,
                        callback: () => {
                            addToVpp(row);
                        }
                    });
                }
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableActions actions={actions} className={style.editActionStyling} />
                    </Box>
                );
            }
        }
    ];

    if (user?.superadmin) {
        const assetIsTrainedColumn = {
            id: COLUMN_ID.ASSET_TRAINED,
            maxWidth: 50,
            label: tList('tableHead.trained'),
            value: (row) => {
                return (
                    <IconButton>
                        {row.generation_forecast_models ? (
                            <CloudDoneIcon className={style.on} />
                        ) : (
                            <CloudOffIcon className={style.off} />
                        )}
                    </IconButton>
                );
            },
            sort: false
        } as TableCellRenderer<GenerationAssetFE>;

        const assetTechDetailsColumn = {
            id: COLUMN_ID.TECH_DETAILS,
            maxWidth: 50,
            label: tList('tableHead.info'),
            value: (row, searchValue, onClick, handlers) => {
                const handler = handlers?.['techDetails'];
                return (
                    <IconButton onClick={handler(row)}>
                        <TooltipInfoSvg />
                    </IconButton>
                );
            },
            sort: false
        } as TableCellRenderer<GenerationAssetFE>;

        defaultColumns = [
            ...defaultColumns.slice(0, defaultColumns.length - 1),
            assetTechDetailsColumn,
            assetIsTrainedColumn,
            ...defaultColumns.slice(defaultColumns.length - 1)
        ];

        defaultColumns = [
            ...defaultColumns.slice(0, 2),

            {
                id: COLUMN_ID.CLIENT_NAME,
                maxWidth: 75,
                label: tList('tableHead.clientName'),
                sort_id: 'client.name',
                value: (row) => {
                    return <Typography variant="small4">{row.client.name}</Typography>;
                },
                sort: true
            },
            ...defaultColumns.slice(2)
        ];
    }

    return defaultColumns;
};
