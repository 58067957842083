export enum MODAL_IDS {
    EDIT_VPP = 'editVPP',
    CREATE_VPP = 'createVPP',
    USER_MODAL = 'userModalId',
    CLIENT_MODAL = 'clientModalId',
    ASSET_LOCATION_MODAL = 'assetLocationId',
    CREATE_ASSET_MODAL = 'createAssetModalId',
    CONFIRMATION_MODAL = 'CONFIRMATION_MODAL',
    CREATE_REPORT_MODAL = 'CREATE_REPORT_MODAL',
    CREATE_EVS_REPORT_MODAL = 'CREATE_EVS_REPORT_MODAL',
    CLIENT_SUPPORT_MODAL = 'CLIENT_SUPPORT_MODAL',
    ASSET_PERMISSIONS_MODAL = 'assetPermissionsId',
    ACCOUNT_SETTINGS_MODAL = 'ACCOUNT_SETTINGS_MODAL',
    PAGE_UNDER_DEVELOPMENT_MODAL = 'PAGE_UNDER_DEVELOPMENT_MODAL',
    DELETE_VPP_CONFIRMATION_MODAL = 'DELETE_VPP_CONFIRMATION_MODAL',
    DELETE_CARD_FROM_DASHBOARD_MODAL = 'DELETE_CARD_FROM_DASHBOARD_MODAL',
    ADD_TO_VPP_MODAL = 'ADD_TO_VPP',
    TRAIN_ASSET_MODAL = 'TRAIN_ASSET_MODAL',
    RUN_FORECAST_MODAL = 'RUN_FORECAST_MODAL',
    DELIVER_FORECAST_MODAL = 'DELIVER_FORECAST_MODAL',
    INGEST_METEO_DATA_ASSET_MODAL = 'INGEST_METEO_DATA_ASSET_MODAL',
    ASSET_TECHNICAL_DETAILS_MODAL = 'ASSET_TECHNICAL_DETAILS_MODAL',
    ADMIN_ADD_NEW_PROJECT = 'ADMIN_ADD_NEW_PROJECT',
    ADMIN_EDIT_PROJECT = 'ADMIN_EDIT_PROJECT',
    DEMAND_MODAL = 'DEMAND_MODAL',
    IMPORT_FILE_MODAL = 'IMPORT_FILE_MODAL',
    ADD_LOCATION_COORDINATES = 'ADD_LOCATION_COORDINATES',
    EDIT_LOCATION_COORDINATES = 'EDIT_LOCATION_COORDINATES',
    INGEST_LOCATION_COORDINATES = 'INGEST_LOCATION_COORDINATES',
    INGEST_MULTIPLE_LOCATION_COORDINATES = 'INGEST_MULTIPLE_LOCATION_COORDINATES',
    ADD_GAS_PROFILE_MODAL = 'ADD_GAS_PROFILE_MODAL',
    ADD_GAS_REPORT_MODAL = 'ADD_GAS_REPORT_MODAL'
}
