import { LineData } from 'lightweight-charts';

import { Forecast } from '../../interfaces/apiv2';
import { FORECAST_TYPE, ReportDataFE, ReportFE } from '../../interfaces/uiv2';
import { sortForecastData, transformForecastToView } from '../../utils/forecast';
import { GenerationAsset, Report, ReportData, ReportDataType } from '../../interfaces/apiv2';

export const transformReportsAPItoFE = (reports: Report[]): ReportFE[] => {
    return reports.map((r, i) => ({ index: i + 1, ...r }));
};

export const transformReportDataToFE = (data: ReportData): ReportDataFE[] => {
    const result: any[] = [];
    Object.keys(data).forEach((label) => {
        Object.keys(data[label as ReportDataType]).forEach((value, index) => {
            if (result.length < index + 1) {
                result.push({
                    index: index + 1,
                    timestamp: +value,
                    [label as ReportDataType]:
                        label === 'error'
                            ? Math.abs(data[label as ReportDataType][value])
                            : data[label as ReportDataType][value]
                });
            } else {
                result[index][label as ReportDataType] =
                    label === 'error'
                        ? Math.abs(data[label as ReportDataType][value])
                        : data[label as ReportDataType][value];
            }
        });
    });

    return result;
};

export const transformReportDataToChart = (data: ReportDataFE[], asset?: GenerationAsset): LineData[][] => {
    const forecastData = transformForecastToView(
        data.map((item) => ({ timestamp: item.timestamp, value: item.forecasted } as unknown as Forecast)),
        FORECAST_TYPE.ELECTRICITY,
        asset?.country_code
    );
    const measuredData = transformForecastToView(
        data.map((item) => ({ timestamp: item.timestamp, value: item.measured } as unknown as Forecast)),
        FORECAST_TYPE.ELECTRICITY,
        asset?.country_code
    );

    return [sortForecastData(forecastData), sortForecastData(measuredData)];
};
