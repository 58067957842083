import { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { PostClientBody, PatchClientBody } from '../../interfaces/uiv2';
import { Client, PostClient, PatchClient } from '../../interfaces/apiv2';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const createClientAsync = createAsyncThunk(
    'clients/create',
    async (body: PostClientBody, { rejectWithValue }) => {
        try {
            const response = await axios.post<Client, AxiosResponse<Client>, PostClient>('/clients', body);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const updateClientAsync = createAsyncThunk(
    'clients/update',
    async ({ body, id }: PatchClientBody, { rejectWithValue }) => {
        try {
            const response = await axios.patch<Client, AxiosResponse<Client>, PatchClient['body']>(
                `/clients/${id}`,
                body
            );

            // const [clientFE] = transformer([response.data]);
            // dispatch(updateById(clientFE));

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const createClient = createSlice({
    name: 'createClient',
    initialState,
    reducers: {
        resetCreateClientState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createClientAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(createClientAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(createClientAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });

        builder.addCase(updateClientAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(updateClientAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(updateClientAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
    }
});

export const { resetCreateClientState } = createClient.actions;

export default createClient.reducer;
