import { isEqual } from 'lodash';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import {
    loadMore,
    setGetParams,
    getForecastAsync,
    resetForecastPage,
    getForecastDataAsync,
    deleteForecastsListAsync
} from '../../stores/Forecasts';
import { TAB } from '../../interfaces/general';
import { getFormattedDate } from '../../utils/helpers';
import { getAssetIcon } from '../../utils/getAssetIcon';
import { PageContainer } from '../../layout/PageContainer';
import { TradingView } from '../../components/TradingView';
import { useAppDispatch, useAppSelector } from '../../stores';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { DownloadExtension } from '../../components/DownloadButton';
import { DATE_SETTINGS, NO_VALUE, ROUTES } from '../../utils/config';
import { ReactComponent as ViewList } from '../../assets/icons/view_list.svg';
import { ReactComponent as GraphIcon } from '../../assets/icons/timeline.svg';
import { RemoveForecastContainer } from '../../components/RemoveEntityContainer';
import { TableActionsEntityContainer } from '../../components/TableActionsEntityContainer';
import { MemoizedGenericTable } from '../../components/GenericTableWrapper/GenericTableWrapper';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../components/EntityPageToolbar';
import { getTableHeadCells, renderFooterContent } from '../../components/GenerationTable/helpers';
import { ReactComponent as ForecastsSVG } from '../../assets/icons/navigation-items/dots-menu-forecasts.svg';

import common from './../../common.module.scss';
import style from '../../features/asset-page/asset-page.module.scss';
import { formatAssetMeasureUnit } from '../../utils/forecast';

const ForecastPage = () => {
    const query = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `assetPageTitle.${tab}`;

    const {
        forecast,
        pager,
        paginatedData,
        chartData,
        loading: loadingForecast,
        error: errorForecast,
        success
    } = useAppSelector((state) => state.forecastPage);
    const getParams = useAppSelector((state) => state.forecastPage.getParams, isEqual);

    useEffect(() => {
        return () => {
            dispatch(resetForecastPage());
        };
    }, []);

    useEffect(() => {
        if (query?.forecastId && !forecast) {
            dispatch(getForecastAsync({ id: query.forecastId }));
        }
    }, [query, forecast]);

    useEffect(() => {
        if (forecast) {
            dispatch(getForecastDataAsync({ forecast }));
        }
    }, [forecast]);

    useEffect(() => {
        if (tab === 'table' && pager?.page !== getParams.page) {
            dispatch(loadMore());
        }
    }, [getParams, tab, pager]);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    const getAssetPageUrl = () => {
        return `${ROUTES.AI_GENERATION_ASSETS_PAGE.replace(':id', forecast!.forecast_settings.asset.id)}`;
    };

    if (!forecast) {
        return null;
    }

    const headCells = getTableHeadCells({ t, assetMeasureUnit: forecast.forecast_settings.asset.measure_unit });

    const remove = () => {
        dispatch(deleteForecastsListAsync([forecast.id]));
    };

    const assetUnit = formatAssetMeasureUnit(
        forecast.forecast_settings.asset.measure_unit,
        forecast.forecast_settings.asset.capacity_value || 0
    );

    return (
        <PageContainer className={[common.pageContainer]}>
            {/* <Box className={common.wrapper}> */}
            <Box className={style.headerContainer}>
                <Box className={classnames(style.header)}>
                    <PageHeader
                        title={t('title.forecasts')}
                        className={classnames(style.primary, style.align)}
                        id={forecast.id}
                        icon={<ForecastsSVG />}
                        subtitle={forecast.forecast_settings.asset.name}
                    />
                    <Box className={style.subtitle} sx={{ ml: 2 }}>
                        <Box className={style.dot} />
                        <Typography variant="small1"> {t(pageTitle)}</Typography>
                    </Box>
                </Box>

                <Box className={style.toolbarContainer}>
                    <EntityPageToolbar>
                        <EntityPageToolbarButton
                            tabId={TAB.CHART}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.chart')}
                        >
                            <GraphIcon className={style.chartIcon} />
                        </EntityPageToolbarButton>
                        <EntityPageToolbarButton
                            tabId={TAB.TABLE}
                            activeTabId={tab}
                            handleTabChange={handleTabChange}
                            label={t('assetPageTitle.table')}
                        >
                            <ViewList />
                        </EntityPageToolbarButton>
                    </EntityPageToolbar>
                    <TableActionsEntityContainer
                        entityType="forecast"
                        id={forecast.id}
                        className={style.actionsWrapper}
                        defaultActions={[DownloadExtension.Csv, DownloadExtension.Excel, DownloadExtension.Pdf]}
                    />
                </Box>
            </Box>

            <Box className={common.subHeaderContainer}>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.type')}</Typography>
                    <Box className={common.assetTypeBox}>
                        {getAssetIcon(forecast?.forecast_settings.asset.type, 'm-r-1')}
                        <Typography variant="small3">
                            {t(`assetTypes.${forecast?.forecast_settings.asset.type}`)}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4" sx={{ mr: 1.5 }}>
                        {t('tableHead.asset')}
                    </Typography>
                    <Link to={getAssetPageUrl()}>
                        <Typography className={common.primary} variant="small3">
                            {forecast.forecast_settings.asset?.name}
                        </Typography>
                    </Link>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.forecast_type')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {forecast?.forecast_delivery_interval.name
                            ? t(forecast?.forecast_delivery_interval.name)
                            : NO_VALUE}
                    </Typography>
                </Box>
                <Box className={classnames(common.subHeaderElement, common.border)}>
                    <Typography variant="small4">{t('tableHead.from')}</Typography>
                    <Typography variant="small3" className={common.ml}>
                        {getFormattedDate({
                            value: forecast.from_date,
                            type: DATE_SETTINGS.shortV2FormatHoursIncluded,
                            timezone: forecast.forecast_settings.asset.timezone
                        })}
                    </Typography>
                    <Typography variant="small4" className={common.ml}>
                        {t('tableHead.to')}
                    </Typography>
                    <Typography variant="small3" className={common.ml}>
                        {getFormattedDate({
                            value: forecast.to_date,
                            type: DATE_SETTINGS.shortV2FormatHoursIncluded,
                            timezone: forecast.forecast_settings.asset.timezone
                        })}
                    </Typography>
                </Box>
            </Box>

            {tab === 'chart' && (
                <TradingView
                    data={chartData}
                    error={errorForecast}
                    fetchMoreFn={() => {}}
                    loading={loadingForecast}
                    legendOpts={{
                        forecasted: true,
                        measured: false
                    }}
                    assetMeasureUnit={assetUnit}
                />
            )}
            {tab === 'table' && (
                <MemoizedGenericTable
                    renderFooterContent={renderFooterContent}
                    headCells={headCells}
                    pager={pager}
                    data={paginatedData}
                    error={errorForecast}
                    loading={loadingForecast}
                    success={success}
                    setGetParams={setGetParams}
                />
            )}
            {/* </Box> */}

            <RemoveForecastContainer cb={remove} stateAccessor="deleteForecasts" />
        </PageContainer>
    );
};

export default ForecastPage;
