import { LineData } from 'lightweight-charts';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo } from '../../interfaces/apiv2';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';
import { transformForecastToTable, transformForecastToView } from '../../utils/forecast';
import { staticTLClusters, TLCluster } from './mock-data';
import { FORECAST_TYPE, ForecastDataGetParams, ForecastFE } from '../../interfaces/uiv2';
import { mockRequest } from '../../utils/helpers';

interface ITLClusterPage extends DefaultInitialState {
    cluster: TLCluster | null;
    data: ForecastFE[];
    paginatedData: ForecastFE[];
    chartData: LineData[][];
    pager: ApiPaginationInfo | null;
    getParams: ForecastDataGetParams;
}

const initialState: ITLClusterPage = {
    cluster: null,
    data: [],
    paginatedData: [],
    chartData: [[], []],
    loading: false,
    error: false,
    success: false,
    getParams: {
        ...DEFAULT_GET_PARAMS_VALUE,
        size: PAGINATION_SETTINGS.default_fe_page_size,
        // order_by: ['-created_on']
        order_by: undefined
    },
    pager: null
};

export const getTLClusterAsync = createAsyncThunk(
    'cluster/show',
    async (values: { id: string }, { rejectWithValue }) => {
        try {
            /*const response = await axios.get<TLCluster>(`/cluster/${values.id}`);

          return response.data;*/
            const cluster = staticTLClusters.find((e) => e.id === values.id);
            const data = await mockRequest(250, cluster);
            return data as TLCluster;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const getTLClusterDataAsync = createAsyncThunk(
    'cluster_data/get',
    async ({ cluster }: { cluster: TLCluster }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ForecastFE[]>(`/cluster/${cluster.id}/forecast`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const clusterPage = createSlice({
    name: 'clusterPage',
    initialState,
    reducers: {
        resetTLClusterPage: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<ForecastDataGetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setActiveTLCluster: (state, { payload }: PayloadAction<TLCluster | null>) => {
            state.cluster = payload;
        },
        loadMore: (state) => {
            const { data, pager, getParams } = current(state);
            state.pager = {
                ...pager!,
                page: getParams.page || PAGINATION_SETTINGS.default_start_page,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: data.length
            };
            state.paginatedData = data.slice(
                0,
                ((getParams.page || PAGINATION_SETTINGS.default_start_page) + 1) *
                    (getParams.size || PAGINATION_SETTINGS.default_fe_page_size)
            );
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTLClusterDataAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getTLClusterDataAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.data = transformForecastToTable(payload as any);
            state.pager = {
                ...current(state).pager!,
                page: 0,
                size: PAGINATION_SETTINGS.default_fe_page_size,
                total: current(state).data.length
            };
            state.paginatedData = current(state).data.slice(0, PAGINATION_SETTINGS.default_fe_page_size);
            state.chartData = [transformForecastToView(payload as any, FORECAST_TYPE.ELECTRICITY), []];
        });
        builder.addCase(getTLClusterDataAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            //toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
        builder.addCase(getTLClusterAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getTLClusterAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.cluster = payload;
        });
        builder.addCase(getTLClusterAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            //toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const {
    resetTLClusterPage,
    setActiveTLCluster,
    setGetParams: setGetTLClusterParams,
    loadMore: loadMoreTLClusterData
} = clusterPage.actions;

export default clusterPage.reducer;
