import get from 'lodash/get';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '../../utils/config';
import { BaseRouteProps } from './interfaces';
import { useAppSelector } from '../../stores';

const PermissionRoute = ({ redirectPath = ROUTES.FORBIDDEN, requiredPermissions, children }: BaseRouteProps) => {
    const { user } = useAppSelector((state) => state.login);

    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }

    if (requiredPermissions?.length) {
        const values = requiredPermissions.some((p) => get(user.permissions, p)?.length);

        if (!values) {
            return <Navigate to={redirectPath} replace />;
        }
    }

    return children;
};

export default PermissionRoute;
