import store from 'store';
import { Navigate } from 'react-router-dom';

import { BaseRouteProps } from './interfaces';
import { ROUTES, storage } from '../../utils/config';

const PublicRoute = ({ redirectPath = ROUTES.INDEX, children }: BaseRouteProps) => {
    const user = store.get(storage.token);

    if (user) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
};

export default PublicRoute;
