import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Responsive, WidthProvider } from 'react-grid-layout';

import Loading from '../../layout/Loading';
import { ProsumerAssetCard } from './ProsumerAssetCard';
import { PageContainer } from '../../layout/PageContainer';
import { DashboardStaticCard, DashboardVppCard } from '../../features/generation-dashboard-view';

import style from '../../features/generation-dashboard-view/style.module.scss';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const ProsumersDashboard = () => {
    const { t } = useTranslation();

    const dashboard = {
        type: 'Generation',
        layout: {
            lg: [
                {
                    h: 3,
                    i: 'static',
                    w: 4,
                    x: 0,
                    y: 0,
                    moved: false,
                    static: true
                },
                {
                    h: 3,
                    i: 'f2aca578-54b6-41fb-9cc3-e5dc78b8d73e',
                    w: 4,
                    x: 4,
                    y: 0
                },
                {
                    h: 3,
                    i: 'a9881dba-bdd9-4e6c-9b0d-20497b82d818',
                    w: 4,
                    x: 8,
                    y: 0
                },
                {
                    h: 3,
                    i: 'a951faad-245d-4f6b-890f-0bf689f88215',
                    w: 4,
                    x: 0,
                    y: 3
                },
                {
                    h: 3,
                    i: '4c83a24a-07ef-420a-802b-bf966a27aa01',
                    w: 4,
                    x: 4,
                    y: 3
                }
            ]
        },
        id: '0024a196-6ffe-4e2c-8820-a8db27c82c18',
        user_id: '928c99ed-8983-4f01-86c1-5f1ced122f6e',
        created_at: '2023-01-31T14:00:23.601275',
        updated_at: '2023-04-03T13:36:48.230320'
    };

    const staticCard = {
        totalSolar: 13,
        totalWind: 14,
        totalOthers: 18,
        totalSolarCapacity: 233584,
        totalWindCapacity: 921916,
        totalOthersCapacity: 433584,
        totalCapacity: 1155500,
        totalAssets: 27
    };

    const items = [
        {
            size: 1,
            chart_type: 'Bar',
            id: 'f2aca578-54b6-41fb-9cc3-e5dc78b8d73e',
            dashboard_id: '0024a196-6ffe-4e2c-8820-a8db27c82c18',
            asset: {
                client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
                name: 'Sunpark Constanta',
                latitude: 46.6087,
                longitude: 24.71,
                timezone: 'Europe/Bucharest',
                location: 'Icland',
                country_code: 'ro',
                type: 'Solar',
                status: 'Active',
                meta: {},
                installation_date: null,
                expiry_date: null,
                training_start: '2021-01-01T00:00:00',
                meteo_start_date: null,
                manufacturer: '',
                realtime_data: false,
                id: '57d48994-94b1-4596-99dc-972f7179643c',
                first_generation_timestamp: '2020-12-31T22:00:00',
                first_forecast_timestamp: '2022-07-27T12:00:00',
                last_generation_timestamp: '2023-02-28T21:45:00',
                last_forecast_timestamp: '2023-04-13T07:00:00',
                accuracy: 0,
                latest_forecast: [
                    884.3458935022354, 2367.882103085518, 1284.0466339588165, 1884.21089124679565, 3092.973675966263,
                    3771.073772609234, 3679.4591096639633
                ],
                measured_data: [
                    784.3458935022354, 2267.882103085518, 1184.0466339588165, 1784.21089124679565, 2992.973675966263,
                    3671.073772609234, 3579.4591096639633
                ],
                capacity_value: 987,
                measure_unit: 'Energy',
                created_at: '2023-01-31T08:40:06.082070',
                updated_at: '2023-01-31T08:40:06.082072',
                deleted_at: null,
                client: {
                    id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
                    name: 'OGRE'
                },
                generation_forecast_models: {
                    created_at: '2023-02-06T14:59:02.452289'
                }
            },
            vpp: null,
            created_at: '2023-04-03T13:35:00.894104'
        },
        {
            size: 1,
            chart_type: 'Line',
            id: 'a9881dba-bdd9-4e6c-9b0d-20497b82d818',
            dashboard_id: '0024a196-6ffe-4e2c-8820-a8db27c82c18',
            asset: {
                client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
                name: '011ADF038090',
                latitude: 46.6087,
                longitude: 24.71,
                timezone: 'Europe/Bucharest',
                location: 'Icland',
                country_code: 'ro',
                type: 'Prosumer',
                status: 'Active',
                meta: {},
                installation_date: null,
                expiry_date: null,
                training_start: '2021-01-01T00:00:00',
                meteo_start_date: null,
                manufacturer: '',
                realtime_data: false,
                id: '57d48994-94b1-4596-99dc-972f7179643c',
                first_generation_timestamp: '2020-12-31T22:00:00',
                first_forecast_timestamp: '2022-07-27T12:00:00',
                last_generation_timestamp: '2023-02-28T21:45:00',
                last_forecast_timestamp: '2023-04-13T07:00:00',
                accuracy: 0,
                latest_forecast: [
                    884.3458935022354, 2367.882103085518, 1284.0466339588165, 1884.21089124679565, 3092.973675966263,
                    3771.073772609234, 3679.4591096639633
                ],
                measured_data: [
                    584.3458935022354, 2067.882103085518, 884.0466339588165, 1484.21089124679565, 2792.973675966263,
                    3471.073772609234, 3179.4591096639633
                ],
                capacity_value: 987,
                measure_unit: 'Energy',
                created_at: '2023-01-31T08:40:06.082070',
                updated_at: '2023-01-31T08:40:06.082072',
                deleted_at: null,
                client: {
                    id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
                    name: 'OGRE'
                },
                generation_forecast_models: {
                    created_at: '2023-02-06T14:59:02.452289'
                }
            },
            vpp: null,
            created_at: '2023-04-03T13:35:05.635974'
        },
        {
            size: 1,
            chart_type: 'Bar',
            id: 'a951faad-245d-4f6b-890f-0bf689f88215',
            dashboard_id: '0024a196-6ffe-4e2c-8820-a8db27c82c18',
            asset: {
                client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
                name: 'Fantanele park',
                latitude: 46.6087,
                longitude: 24.71,
                timezone: 'Europe/Bucharest',
                location: 'Icland',
                country_code: 'ro',
                type: 'Wind',
                status: 'Active',
                meta: {},
                installation_date: null,
                expiry_date: null,
                training_start: '2021-01-01T00:00:00',
                meteo_start_date: null,
                manufacturer: '',
                realtime_data: false,
                id: '57d48994-94b1-4596-99dc-972f7179643c',
                first_generation_timestamp: '2020-12-31T22:00:00',
                first_forecast_timestamp: '2022-07-27T12:00:00',
                last_generation_timestamp: '2023-02-28T21:45:00',
                last_forecast_timestamp: '2023-04-13T07:00:00',
                accuracy: 0,
                latest_forecast: [
                    584.3458935022354, 1267.882103085518, 1784.0466339588165, 784.21089124679565, 892.973675966263,
                    671.073772609234, 879.4591096639633
                ],
                measured_data: [
                    584.3458935022354, 2067.882103085518, 984.0466339588165, 1784.21089124679565, 2992.973675966263,
                    671.073772609234, 879.4591096639633
                ],
                capacity_value: 987,
                measure_unit: 'Energy',
                created_at: '2023-01-31T08:40:06.082070',
                updated_at: '2023-01-31T08:40:06.082072',
                deleted_at: null,
                client: {
                    id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
                    name: 'OGRE'
                },
                generation_forecast_models: {
                    created_at: '2023-02-06T14:59:02.452289'
                }
            },
            vpp: null,
            created_at: '2023-04-03T13:36:34.220946'
        },
        {
            size: 1,
            chart_type: 'Bar',
            id: '4c83a24a-07ef-420a-802b-bf966a27aa01',
            dashboard_id: '0024a196-6ffe-4e2c-8820-a8db27c82c18',
            asset: {
                client_id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
                name: 'Prosumers Bucharest',
                latitude: 46.6087,
                longitude: 24.71,
                timezone: 'Europe/Bucharest',
                location: 'Icland',
                country_code: 'ro',
                type: 'VPP',
                status: 'Active',
                meta: {},
                installation_date: null,
                expiry_date: null,
                training_start: '2021-01-01T00:00:00',
                meteo_start_date: null,
                manufacturer: '',
                realtime_data: false,
                id: '57d48994-94b1-4596-99dc-972f7179643c',
                first_generation_timestamp: '2020-12-31T22:00:00',
                first_forecast_timestamp: '2022-07-27T12:00:00',
                last_generation_timestamp: '2023-02-28T21:45:00',
                last_forecast_timestamp: '2023-04-13T07:00:00',
                accuracy: 0,
                latest_forecast: [
                    484.3458935022354, 667.882103085518, 984.0466339588165, 1484.21089124679565, 1092.973675966263,
                    771.073772609234, 1479.4591096639633
                ],
                measured_data: [
                    784.3458935022354, 2267.882103085518, 1184.0466339588165, 1784.21089124679565, 2992.973675966263,
                    3671.073772609234, 3579.4591096639633
                ],
                capacity_value: 987,
                measure_unit: 'Energy',
                created_at: '2023-01-31T08:40:06.082070',
                updated_at: '2023-01-31T08:40:06.082072',
                deleted_at: null,
                client: {
                    id: '77f8ec5a-7e8e-4dc4-84b5-bc1f0bacba25',
                    name: 'OGRE'
                },
                generation_forecast_models: {
                    created_at: '2023-02-06T14:59:02.452289'
                }
            },
            vpp: null,
            created_at: '2023-04-03T13:36:38.030736'
        }
    ];

    const isLoading = false;

    const deleteDashboardItem = () => {
        // handle delete
    };

    const changeHeight = () => {
        // handle height change
    };

    const generateDOM = useCallback(() => {
        return _.map(dashboard?.layout?.lg || [], function (l) {
            if (l.i === 'static' && staticCard) {
                return (
                    <Box key={l.i} className={style.itemWrapper}>
                        <DashboardStaticCard item={staticCard as any} />
                    </Box>
                );
            }

            const item = items.find((m) => m.id === l.i);

            if (!item) {
                return null;
            }

            if (item.asset && Object.keys(item.asset).length !== 0) {
                return (
                    <Box key={l.i} className={style.itemWrapper}>
                        <ProsumerAssetCard
                            l={l}
                            item={item as any}
                            asset={item.asset as any}
                            changeHeight={changeHeight}
                            deleteDashboardItem={deleteDashboardItem}
                        />
                    </Box>
                );
            }

            if (item.vpp && Object.keys(item.vpp).length !== 0) {
                return (
                    <Box key={l.i} className={style.itemWrapper}>
                        <DashboardVppCard
                            l={l}
                            item={item as any}
                            vpp={item.vpp}
                            changeHeight={changeHeight}
                            deleteDashboardItem={deleteDashboardItem}
                        />
                    </Box>
                );
            }
        });
    }, [dashboard, items, staticCard]);

    const onLayoutChange = () => {};
    const onBreakpointChange = () => {};

    return (
        <PageContainer className={[style.container]}>
            <svg style={{ height: 0, width: 0, position: 'absolute', top: 0, left: 0 }}>
                <defs>
                    <linearGradient id="barGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#D5E3FF" />
                        <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                </defs>
            </svg>
            <Box className={style.header}>
                <Typography variant="h6" component="span">
                    {t('dashboard')}
                </Typography>
                <Typography variant="verySmallBold" component="span" className={style.beta}>
                    {t('beta')}
                </Typography>
            </Box>
            {isLoading ? (
                <Loading />
            ) : (
                <Box className={style.grid}>
                    {dashboard?.layout?.lg?.length ? (
                        <ResponsiveReactGridLayout
                            draggableCancel=".dragDisable"
                            rowHeight={110}
                            isBounded={true}
                            margin={[20, 20]}
                            resizeHandles={[]}
                            isResizable={false}
                            allowOverlap={false}
                            useCSSTransforms={true}
                            preventCollision={false}
                            compactType={null}
                            measureBeforeMount={false}
                            layouts={dashboard?.layout}
                            onLayoutChange={onLayoutChange}
                            onBreakpointChange={onBreakpointChange}
                            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                            breakpoints={{ lg: 1800, md: 1800, sm: 1800, xs: 1800, xxs: 1800 }}
                        >
                            {generateDOM()}
                        </ResponsiveReactGridLayout>
                    ) : null}
                </Box>
            )}
        </PageContainer>
    );
};

export default ProsumersDashboard;
