import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, DialogContent, Typography } from '@mui/material';

import { MODAL_IDS } from '..';
import { DATE_SETTINGS } from '../../utils/config';
import { BaseModal } from '../../components/BaseModal';
import Cleanup from '../../components/Cleanup/Cleanup';
import { getFormattedDate } from '../../utils/helpers';
import { GenerationAssetFE } from '../../interfaces/uiv2';
import { useAppDispatch, useAppSelector } from '../../stores';
import AssetTechnicalDetailsForm from './AssetTechnicalDetailsForm';
import { FormErrorStep, FormSuccessStep } from '../../components/FormUtils';
import { resetUpdateAssetTechnicalDetailsState } from '../../stores/Assets';

import style from './style.module.scss';
import common from '../../common.module.scss';

export const AssetTechnicalDetailsModal: FC<{ asset?: GenerationAssetFE }> = ({ asset }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('assets/generation');

    const { success, error } = useAppSelector((state) => state.updateAssetTechnicalDetails);

    const handleCleanup = () => dispatch(resetUpdateAssetTechnicalDetailsState());

    if (!asset) {
        return null;
    }

    const chips = [
        'first_forecast_timestamp',
        'first_generation_timestamp',
        'last_forecast_timestamp',
        'last_generation_timestamp',
        'meteo_start_date',
        'training_start'
    ];

    if (asset.generation_forecast_models) {
        chips.push('generation_forecast_models_created_at');
    }

    return (
        <BaseModal id={MODAL_IDS.ASSET_TECHNICAL_DETAILS_MODAL} fullWidth={false} maxWidth="lg">
            <DialogContent className={style.modalContainer}>
                <Typography variant="h6">
                    {t('assetTechnicalDetails')} <span className={style.assetName}>{asset?.name}</span>
                </Typography>
                <Box className={common.chipsBox}>
                    {chips.map((item) => (
                        <Chip
                            key={item}
                            label={`${t(`form.${item}`)}: ${getFormattedDate({
                                value: asset?.[item as keyof typeof asset],
                                timezone: 'UTC',
                                type: DATE_SETTINGS.shortFormatHoursIncluded
                            })}`}
                            color={asset?.[item as keyof typeof asset] ? 'success' : 'error'}
                        />
                    ))}
                </Box>

                {!(success || error) ? <AssetTechnicalDetailsForm asset={asset} /> : null}
                {success ? <FormSuccessStep /> : null}
                {error ? <FormErrorStep classname={'autoWidth'} /> : null}
                <Cleanup cb={handleCleanup} />
            </DialogContent>
        </BaseModal>
    );
};
