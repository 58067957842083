import { get } from 'lodash';
import { useEffect } from 'react';
import { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Path, PathValue, UnpackNestedValue, useForm } from 'react-hook-form';

const useFormLogic = <T extends { [key: string]: any }, X, Z extends Path<X>, Y extends { [key: string]: any }>({
    schema,
    data,
    transformFn,
    transformOpts,
    context,
    defaultValues
}: {
    schema: AnyObjectSchema;
    data: T | null;
    transformFn?: (param: any, opts?: any) => Y;
    transformOpts?: any;
    context?: any;
    defaultValues?: any;
}) => {
    const { control, handleSubmit, setError, clearErrors, setValue, watch, reset, getValues, trigger, formState } =
        useForm<X>({
            resolver: yupResolver(schema),
            context: context ?? {},
            defaultValues: defaultValues ?? {}
        });

    useEffect(() => {
        if (data) {
            for (const key of Object.keys(schema.fields)) {
                const dataForm = transformFn ? transformFn(data, transformOpts) : data;
                const value = get(dataForm, key, '') as unknown as UnpackNestedValue<PathValue<X, Z>>;
                setValue(key as Z, value);
            }
        }
    }, [data, transformOpts]);

    return {
        control,
        watch,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        reset,
        getValues,
        trigger,
        formState
    };
};

export { useFormLogic };
