import { User } from '../../interfaces/apiv2';
import { UserFE } from '../../interfaces/uiv2';
import { getActiveModulesFromPermissions } from '../../utils/permissions';

export const transformUsersAPIToFE = (users: User[]): UserFE[] => {
    return users.map((user, index) => {
        const activatedModules = getActiveModulesFromPermissions(user.permissions, user);

        return {
            ...user,
            index: index + 1,
            activatedModules
        };
    });
};
