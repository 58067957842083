import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import i18next from '../../utils/i18next';
import { PostReport } from '../../interfaces/apiv2';
import ToastifyType from '../../utils/toastify-config';
import { DefaultInitialState } from '../../interfaces/redux';
import { commonReduxErrorHandler } from '../../utils/error-handling';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const postReportAsync = createAsyncThunk('reports/post', async (payload: PostReport, { rejectWithValue }) => {
    try {
        const response = await axios.post('/electricity/generation/assets/reports', payload);

        return response.data;
    } catch (e) {
        return rejectWithValue(commonReduxErrorHandler(e));
    }
});

export const createReports = createSlice({
    name: 'createReports',
    initialState,
    reducers: {
        resetCreateReportsState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(postReportAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(postReportAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(postReportAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetCreateReportsState } = createReports.actions;

export default createReports.reducer;
