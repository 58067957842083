import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import i18next from '../../utils/i18next';
import { MarketFE } from '../../interfaces/uiv2';
import { mockRequest } from '../../utils/helpers';
import { transformMarketsAPIToFE } from './helpers';
import { GetParams } from '../../interfaces/general';
import ToastifyType from '../../utils/toastify-config';
import { PAGINATION_SETTINGS } from '../../utils/config';
import { ApiPaginationInfo } from '../../interfaces/apiv2';
// import { getQueryParamsFromObject } from '../../utils/table';
import { mockMarketsList } from './mockData';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { PaginatedApiResponse, Market } from '../../interfaces/apiv2';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../interfaces/redux';

interface ListMarketsState extends DefaultInitialState {
    markets: Market[];
    marketsFE: MarketFE[];
    searchParam: string | null;
    pager: ApiPaginationInfo | null;
    getParams: GetParams;
    selectedMarkets: MarketFE[];
}

const initialState: ListMarketsState = {
    loading: false,
    error: false,
    success: false,
    markets: [],
    marketsFE: [],
    searchParam: null,
    pager: null,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    selectedMarkets: []
};

export const getMarketsListAsync = createAsyncThunk(
    'markets/list',
    async (values: GetParams = {}, { rejectWithValue }) => {
        try {
            // const endpoint = '/markets';
            // const queryParams = getQueryParamsFromObject({ ...values });
            // const response = await axios.get<PaginatedApiResponse<Market>>(`${endpoint}${queryParams}`);
            // return response.data;

            const response = mockMarketsList;
            const data = await mockRequest(250, response, values);

            return {
                items: data,
                page: 1,
                pager: null
            } as unknown as PaginatedApiResponse<Market>;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listMarkets = createSlice({
    name: 'listMarkets',
    initialState,
    reducers: {
        resetListMarketState: () => initialState,
        setGetParamsMarkets: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedMarkets: (state, { payload }: PayloadAction<MarketFE[]>) => {
            state.selectedMarkets = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMarketsListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getMarketsListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.markets = items;
            } else {
                state.markets = [...current(state).markets, ...items];
            }

            state.marketsFE = transformMarketsAPIToFE(current(state).markets);
        });
        builder.addCase(getMarketsListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const { resetListMarketState, setGetParamsMarkets, setSelectedMarkets } = listMarkets.actions;

export default listMarkets.reducer;
