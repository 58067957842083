import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';
import i18next from '../../../utils/i18next';
import { GetParams } from '../../../interfaces/general';
import ToastifyType from '../../../utils/toastify-config';
import { transformAssetLocationsAPItoFE } from '../helpers';
import { getQueryParamsFromObject } from '../../../utils/table';
import { commonReduxErrorHandler } from '../../../utils/error-handling';
import { ApiPaginationInfo } from '../../../interfaces/apiv2';
import { DefaultInitialState, DEFAULT_GET_PARAMS_VALUE } from '../../../interfaces/redux';
import { AssetLocation } from '../../../interfaces/apiv2';
import { AssetLocationFE } from '../../../interfaces/uiv2';

interface ListAssetLocationsState extends DefaultInitialState {
    locations: AssetLocation[];
    locationsFE: AssetLocationFE[];
    pager: ApiPaginationInfo | null;
    getParams: GetParams;
    selectedRows: AssetLocationFE[];
}

const initialState: ListAssetLocationsState = {
    loading: false,
    error: false,
    success: false,
    locations: [],
    locationsFE: [],
    pager: null,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    selectedRows: []
};

export const getAssetLocationsAsync = createAsyncThunk(
    'assets/list-locations',
    async ({ assetId, getParams }: { getParams: GetParams; assetId: string }, { rejectWithValue }) => {
        try {
            const endpoint = `/electricity/generation/assets/${assetId}/locations`;

            const queryParams = getQueryParamsFromObject({ ...getParams });

            const response = await axios.get<AssetLocation[]>(`${endpoint}${queryParams}`);

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listAssetLocations = createSlice({
    name: 'ListAssetLocations',
    initialState,
    reducers: {
        resetListAssetLocationsState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<AssetLocationFE[]>) => {
            state.selectedRows = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAssetLocationsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getAssetLocationsAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            state.locations = payload;
            state.locationsFE = transformAssetLocationsAPItoFE(current(state).locations);
        });
        builder.addCase(getAssetLocationsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const {
    resetListAssetLocationsState,
    setGetParams: setGetAssetLocationsParams,
    setSelectedRows: setSelectedLocationsRows
} = listAssetLocations.actions;

export default listAssetLocations.reducer;
