import axios from '../../utils/axios';
import { DefaultInitialState } from '../../interfaces/redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { commonReduxErrorHandler } from '../../utils/error-handling';
import { PERMISSION_TYPE } from './listPermissions';

const initialState: DefaultInitialState = {
    loading: false,
    error: false,
    success: false
};

export const updatePermissionsAsync = createAsyncThunk(
    'updatePermissions',
    async (data: { users: string[]; assetId: string; type: PERMISSION_TYPE }, { rejectWithValue }) => {
        try {
            const endpoint =
                data.type === PERMISSION_TYPE.ASSET
                    ? `/electricity/generation/assets/${data.assetId}/allow`
                    : `/projects/${data.assetId}/allow`;
            await Promise.all(
                data.users.map((user_id: string) => {
                    return axios.put(endpoint, { user_id });
                })
            );
            return null;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const updatePermissions = createSlice({
    name: 'updatePermissions',
    initialState,
    reducers: {
        resetPermissionState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(updatePermissionsAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(updatePermissionsAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });
        builder.addCase(updatePermissionsAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        });
    }
});

export const { resetPermissionState } = updatePermissions.actions;

export default updatePermissions.reducer;
