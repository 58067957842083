import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { Avatar, Divider, InputLabel, TextareaAutosize, Typography } from '@mui/material';
import { stringAvatar } from '../../utils/table';
import { MODAL_IDS } from '..';
import { useAppDispatch, useAppSelector } from '../../stores';
import { FormErrorStep, FormModalActions, FormSuccessStep } from '../../components/FormUtils';
import { postSupportFormAsync, resetCreateSupportForm } from '../../stores/UserSupportForm';

import style from './style.module.scss';

const SupportForm = () => {
    const { t: generalTranslation } = useTranslation();
    const { t } = useTranslation('components/support-modal');
    const dispatch = useAppDispatch();

    const { error, loading, success } = useAppSelector((state) => state.userSupportForm);
    const { user } = useAppSelector((state) => state.login);
    const [message, setMessage] = useState('');

    const handleSubmit = () => {
        dispatch(postSupportFormAsync({ message }));
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    };

    useEffect(() => {
        return () => {
            dispatch(resetCreateSupportForm());
        };
    }, []);

    const fullName = [user?.first_name, user?.last_name].filter((x) => x).join(' ');

    return (
        <Box className={style.box}>
            {!(success || error) ? (
                <>
                    <Typography variant="h6">{t('help')}</Typography>
                    <InputLabel variant="standard" shrink htmlFor={'name'}>
                        {t('nameLabel')}
                    </InputLabel>
                    <Box className={style.nameRow}>
                        <Avatar variant="userAccount" className={style.avatar} {...stringAvatar(fullName)} />
                        <Typography variant="small1">{fullName}</Typography>
                    </Box>
                    <InputLabel variant="standard" required shrink htmlFor={'message'}>
                        {t('inputLabel')}
                    </InputLabel>
                    <TextareaAutosize
                        aria-label="form-message"
                        minRows={8}
                        style={{ width: 455 }}
                        value={message}
                        onChange={handleChange}
                    />

                    <Divider className={style.line} />

                    <FormModalActions
                        loading={loading}
                        disabled={!message.length}
                        modalId={MODAL_IDS.CLIENT_SUPPORT_MODAL}
                        cancelLabel={generalTranslation('nevermind')}
                        saveLabel={generalTranslation('send')}
                        onSubmit={handleSubmit}
                    />
                </>
            ) : null}
            {success ? <FormSuccessStep /> : null}
            {error ? <FormErrorStep /> : null}
        </Box>
    );
};

export default SupportForm;
