import { ImportedFile, ProjectsData } from '../../interfaces/apiv2';
import { ImportedFileFE, ProjectsDataFE } from '../../interfaces/uiv2';

export const transformProjectsDataToFE = (data: ProjectsData[]): ProjectsDataFE[] => {
    return data.map((item, index) => ({
        ...item,
        index: index + 1
    }));
};
export const transformImportedFilesToFE = (data: ImportedFile[]): ImportedFileFE[] => {
    return data.map((item, index) => ({
        ...item,
        index: index + 1
    }));
};
