import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import i18next from '../../../../utils/i18next';
import ToastifyType from '../../../../utils/toastify-config';
import { DEFAULT_GET_PARAMS_VALUE, DefaultInitialState } from '../../../../interfaces/redux';
import { ApiPaginationInfo, PaginatedApiResponse, GasProfile } from '../../../../interfaces/apiv2';
import { GasProfileDataFE } from '../../../../interfaces/uiv2';
import { commonReduxErrorHandler } from '../../../../utils/error-handling';
import { transformGasProfilesDataToFE } from '../../helpers';
import { GetParams } from '../../../../interfaces/general';
import { getQueryParamsFromObject } from '../../../../utils/table';
import { PAGINATION_SETTINGS } from '../../../../utils/config';
import axios from '../../../../utils/axios';

interface ListProfilesState extends DefaultInitialState {
    profiles: GasProfile[];
    profilesFE: GasProfileDataFE[];
    selectedRows: GasProfileDataFE[];
    getParams: GetParams;
    pager: ApiPaginationInfo | null;
}

const initialState: ListProfilesState = {
    profiles: [],
    profilesFE: [],
    selectedRows: [],
    loading: false,
    error: false,
    success: false,
    getParams: DEFAULT_GET_PARAMS_VALUE,
    pager: null
};

export const getGasProfilesListAsync = createAsyncThunk(
    'gas-profiles/list',
    async ({ projectId, getParams }: { getParams: GetParams; projectId: string }, { rejectWithValue }) => {
        try {
            const queryParams = getQueryParamsFromObject({ ...getParams });
            const response = await axios.get<PaginatedApiResponse<GasProfile>>(
                `/gas/demand/${projectId}/profiles${queryParams}`
            );
            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const importGasProfilesAsync = createAsyncThunk(
    'gas-profiles/import',
    async ({ projectId, file }: { projectId: string; file: File }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(`/gas/demand/${projectId}/profiles/import`, formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const importGasProfileFormulaAsync = createAsyncThunk(
    'gas-profiles/import-formula',
    async (
        { projectId, file, profileId }: { projectId: string; profileId: string; file: File },
        { rejectWithValue }
    ) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(`/gas/demand/${projectId}/profiles/${profileId}/upload`, formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    }
);

export const listGasProfiles = createSlice({
    name: 'listGasProfiles',
    initialState,
    reducers: {
        resetListGasProfilesState: () => initialState,
        setGetParams: (state, { payload }: PayloadAction<GetParams>) => {
            state.getParams = {
                ...(current(state).getParams || {}),
                ...payload
            };
        },
        setSelectedRows: (state, { payload }: PayloadAction<GasProfileDataFE[]>) => {
            state.selectedRows = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getGasProfilesListAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(getGasProfilesListAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            const { items, ...rest } = payload;
            state.pager = rest;

            if (payload?.page === PAGINATION_SETTINGS.default_start_page) {
                state.profiles = items;
            } else {
                state.profiles = [...current(state).profiles, ...items];
            }
            state.profilesFE = transformGasProfilesDataToFE(current(state).profiles);
        });
        builder.addCase(getGasProfilesListAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(importGasProfilesAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(importGasProfilesAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(importGasProfilesAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });

        builder.addCase(importGasProfileFormulaAsync.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        });
        builder.addCase(importGasProfileFormulaAsync.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            toast.success(i18next.t('commonSuccessMessage'), ToastifyType.success);
        });
        builder.addCase(importGasProfileFormulaAsync.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
        });
    }
});

export const {
    resetListGasProfilesState,
    setGetParams: setGetGasProfilesParams,
    setSelectedRows: setSelectedRowsGasProfiles
} = listGasProfiles.actions;

export default listGasProfiles.reducer;
