import { DialogContent } from '@mui/material';
import { CloseModalButton } from '../../components/BaseModal/CloseModalButton';
import { MODAL_IDS } from '..';
import { BaseModal } from '../../components/BaseModal';
import SupportForm from './SupportForm';

const ClientSupportModal = () => {
    return (
        <BaseModal maxWidth="sm" fullWidth={false} closeOnBackdropClick id={MODAL_IDS.CLIENT_SUPPORT_MODAL}>
            <CloseModalButton id={MODAL_IDS.CLIENT_SUPPORT_MODAL} />
            <DialogContent>
                <SupportForm />
            </DialogContent>
        </BaseModal>
    );
};

export { ClientSupportModal };
