import {
    Box,
    Grid,
    Input,
    Stack,
    Divider,
    TextField,
    InputLabel,
    Typography,
    Autocomplete,
    FormHelperText
} from '@mui/material';
import classnames from 'classnames';
import Flag from 'react-world-flags';
import { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { MdKeyboardArrowDown } from 'react-icons/md';
import MuiPhoneNumber from 'material-ui-phone-number';

import {
    getClientSchema,
    transformClientFormToBody,
    transformToClientSchema,
    transformClientBodyToClientPatch
} from './helpers';
import { MODAL_IDS } from '..';
import { useFormLogic } from '../../hooks/useFormLogic';
import { useDrawerMode } from '../../hooks/useDrawerMode';
import { useAppDispatch, useAppSelector } from '../../stores';
import { AppPermissions } from '../../components/AppPermissions';
import { transformPermissionsToApiFormat } from '../../utils/user';
import { ClientFE, ClientFormFields } from '../../interfaces/uiv2';
import { COUNTRIES_LIST, CountryItem } from '../../utils/countriesList';
import { FormErrorStep, FormModalActions, FormSuccessStep } from '../../components/FormUtils';
import { createClientAsync, resetCreateClientState, updateClientAsync } from '../../stores/Clients';

import style from './style.module.scss';

const ClientForm: FC<{ client: ClientFE | null }> = ({ client }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('clients/create');
    const { t: generalTranslation } = useTranslation();
    const { isEdit } = useDrawerMode({ data: client });

    const { user } = useAppSelector((state) => state.login);
    const { loading, success, error } = useAppSelector((state) => state.createClient);

    const schema = getClientSchema(t);

    useEffect(() => {
        return () => {
            dispatch(resetCreateClientState());
        };
    }, []);

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useFormLogic<ClientFE, ClientFormFields, keyof typeof schema.fields, ClientFormFields>({
        schema,
        data: client,
        transformFn: transformToClientSchema
    });

    const onSubmit = (form: ClientFormFields) => {
        const mask = transformPermissionsToApiFormat(form.permissions, user!.permissions);
        const body = transformClientFormToBody(form, mask);

        if (isEdit) {
            const patchBody = transformClientBodyToClientPatch(body, client!);
            dispatch(updateClientAsync(patchBody));
            return;
        }

        dispatch(createClientAsync(body));
    };

    return (
        <Box className={style.container}>
            {!(success || error) ? (
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction={'row'} columnGap={4}>
                        <Box sx={{ flex: 0.75 }}>
                            <Typography variant="h6">{!isEdit ? t('title') : t('editTitle')}</Typography>

                            <Typography variant="body1" sx={{ mt: 3, mb: 2.5 }}>
                                {t('companyTitle')}
                            </Typography>

                            <Box className={style.item}>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <>
                                            <InputLabel
                                                variant="standard"
                                                required
                                                shrink
                                                htmlFor={t('form.companyName')}
                                            >
                                                {t('form.companyName')}
                                            </InputLabel>

                                            <Input
                                                {...field}
                                                fullWidth
                                                id={t('form.companyName')}
                                                placeholder={t('form.companyName')}
                                                endAdornment={
                                                    isEdit ? <EditIcon sx={{ fontSize: 12 }} color="info" /> : null
                                                }
                                            />

                                            {errors.name && (
                                                <FormHelperText error>{errors.name.message}</FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </Box>

                            <Box>
                                <Controller
                                    name="country"
                                    control={control}
                                    defaultValue={null as unknown as CountryItem}
                                    render={({ field }) => {
                                        return (
                                            <>
                                                <InputLabel variant="standard" required shrink htmlFor="country">
                                                    {t('form.companyCountry')}
                                                </InputLabel>

                                                <Autocomplete
                                                    value={field?.value}
                                                    id="country"
                                                    onChange={(e, v) => {
                                                        field.onChange(v);
                                                        return v;
                                                    }}
                                                    autoHighlight
                                                    disableClearable
                                                    options={COUNTRIES_LIST}
                                                    popupIcon={<MdKeyboardArrowDown color="#6A9BFF" />}
                                                    noOptionsText={generalTranslation('select.noOptions')}
                                                    getOptionLabel={(option) => option.label}
                                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                                    renderOption={(props, country) => (
                                                        <Box {...props} component="li">
                                                            <Flag
                                                                width="24"
                                                                height="24"
                                                                code={country.code}
                                                                className={classnames('flagIcon', 'm-r-1')}
                                                            />
                                                            {country.label}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: field.value?.code ? (
                                                                    <Flag
                                                                        width="24"
                                                                        height="24"
                                                                        code={field.value.code}
                                                                        className={classnames('flagIcon', 'm-r-1')}
                                                                    />
                                                                ) : null
                                                            }}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                placeholder: t('form.companyCountry')
                                                            }}
                                                        />
                                                    )}
                                                />

                                                {errors.country && (
                                                    <FormHelperText error>
                                                        {/* todo: fix this 👇 (types) */}
                                                        {(errors.country as { [key: string]: string })?.message}
                                                    </FormHelperText>
                                                )}
                                            </>
                                        );
                                    }}
                                />
                            </Box>

                            <Typography variant="body1" sx={{ mt: 3, mb: 3 }}>
                                {t('companyContactTitle')}
                            </Typography>

                            <Grid container spacing={2} className={style.item}>
                                <Grid item xs={6}>
                                    <Box>
                                        <Controller
                                            name="contact.first_name"
                                            control={control}
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <>
                                                    <InputLabel
                                                        variant="standard"
                                                        required
                                                        shrink
                                                        htmlFor={t('form.firstName')}
                                                    >
                                                        {t('form.firstName')}
                                                    </InputLabel>

                                                    <Input
                                                        {...field}
                                                        fullWidth
                                                        id={t('form.firstName')}
                                                        placeholder={t('form.firstName')}
                                                        endAdornment={
                                                            isEdit ? (
                                                                <EditIcon sx={{ fontSize: 12 }} color="info" />
                                                            ) : null
                                                        }
                                                    />

                                                    {errors.contact?.first_name && (
                                                        <FormHelperText error>
                                                            {errors.contact?.first_name?.message}
                                                        </FormHelperText>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box>
                                        <Controller
                                            name="contact.last_name"
                                            control={control}
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <>
                                                    <InputLabel
                                                        variant="standard"
                                                        required
                                                        shrink
                                                        htmlFor={t('form.lastName')}
                                                    >
                                                        {t('form.lastName')}
                                                    </InputLabel>

                                                    <Input
                                                        {...field}
                                                        id={t('form.lastName')}
                                                        fullWidth
                                                        placeholder={t('form.lastName')}
                                                        endAdornment={
                                                            isEdit ? (
                                                                <EditIcon sx={{ fontSize: 12 }} color="info" />
                                                            ) : null
                                                        }
                                                    />

                                                    {errors.contact?.last_name && (
                                                        <FormHelperText error>
                                                            {errors.contact?.last_name?.message}
                                                        </FormHelperText>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box className={style.item}>
                                <Controller
                                    name="contact.phone_number"
                                    control={control}
                                    defaultValue={''}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputLabel
                                                variant="standard"
                                                required
                                                shrink
                                                htmlFor={t('form.phoneNumber')}
                                            >
                                                {t('form.phoneNumber')}
                                            </InputLabel>

                                            <MuiPhoneNumber
                                                value={value}
                                                defaultCountry={'ro'}
                                                disableAreaCodes={true}
                                                onChange={(newValue) => {
                                                    onChange(newValue);
                                                    return newValue;
                                                }}
                                                inputClass={style.phoneNumberInput}
                                                dropdownClass={style.languageSelectorDropdown}
                                            />

                                            {errors.contact?.phone_number && (
                                                <FormHelperText error>
                                                    {errors.contact?.phone_number?.message}
                                                </FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </Box>

                            <Box className={style.item}>
                                <Controller
                                    name="contact.email"
                                    control={control}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <>
                                            <InputLabel variant="standard" required shrink htmlFor={t('form.email')}>
                                                {t('form.email')}
                                            </InputLabel>

                                            <Input
                                                {...field}
                                                fullWidth
                                                id={t('form.email')}
                                                placeholder={t('form.email')}
                                                endAdornment={
                                                    isEdit ? <EditIcon sx={{ fontSize: 12 }} color="info" /> : null
                                                }
                                            />

                                            {errors.contact?.email && (
                                                <FormHelperText error>{errors.contact?.email?.message}</FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </Box>
                        </Box>

                        <Box sx={{ flex: 0.75 }}>
                            <Typography variant="body1" className={style.secondaryTitle}>
                                {t('stepTwo.secondaryTitle')}
                            </Typography>

                            <AppPermissions control={control} permissions={user!.permissions} />
                        </Box>
                    </Stack>

                    <Divider />

                    <FormModalActions
                        loading={loading}
                        modalId={MODAL_IDS.CLIENT_MODAL}
                        note={generalTranslation('mandatoryFields')}
                        cancelLabel={generalTranslation('nevermind')}
                        saveLabel={!isEdit ? t('form.submit') : t('form.editSubmit')}
                    />
                </Box>
            ) : null}

            {success ? <FormSuccessStep /> : null}
            {error ? <FormErrorStep /> : null}
        </Box>
    );
};

export { ClientForm };
