import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../utils/config';
import { APP_MODULES } from '../../../interfaces/uiv2';
import { ReactComponent as IconLogo } from '../../../assets/images/ogre-symbol.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/navigation-items/menu-arrow.svg';
import { ReactComponent as AILogo } from '../../../assets/icons/navigation-items/ai-module-logo.svg';
import { ReactComponent as AIGasLogo } from '../../../assets/icons/navigation-items/ai-module-gas-logo.svg';
import { ReactComponent as AdminLogo } from '../../../assets/icons/navigation-items/admin-module-logo.svg';

import style from '../style.module.scss';

export const LogoMenu = ({ activeModule }: { activeModule: APP_MODULES }) => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        if (activeModule === APP_MODULES.AI) {
            navigate(ROUTES.AI_DASHBOARD);
        }

        navigate(ROUTES.ADMIN_GENERATION_ASSETS);
    };

    return (
        <Box className={style.wrapper_logo}>
            <IconLogo className={style.logo} onClick={handleRedirect} />
            <MenuIcon className={style.arrow} />
            <Box className={style.module}>
                {activeModule === APP_MODULES.ADMIN ? <AdminLogo className={style.menuIcon} /> : null}
                {activeModule === APP_MODULES.AI ? <AILogo className={style.menuIcon} /> : null}
                {activeModule === APP_MODULES.AI_GAS ? <AIGasLogo className={style.menuIcon} /> : null}
            </Box>
        </Box>
    );
};
